import React, {useEffect, useState} from 'react';
import classes from './MatchesDetails.module.scss';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';

const MatchesDetailsTtpMatrix = (props) => {
  const {t} = useTranslation();
  const [ttpEvents, setTtpEvents] = useState([]);
  const [ttpEventsWithResult, setTtpEventsWithResult] = useState([]);
  const [ttpCategories, setTtpCategories] = useState([]);
  const {events, eventCategories, eventRecords} = props.data;

  useEffect(() => {
    const categories = eventCategories.map(c => ({...c, open: false}));
    setTtpCategories(categories);
  }, [eventCategories])

  useEffect(() => {
    if (events?.length === 0)
      return;

    const filteredEvents = events.filter(f => f.isTtp);
    setTtpEvents(filteredEvents.sort(e => e.order));

    const eventsWithResult = filteredEvents.filter(f => f.haveSwitch === true).map(e => e.id);
    setTtpEventsWithResult(eventsWithResult);
  }, [events]);

  const headerCategory = (category) => {
    const eventsInCategory = ttpEvents.filter(f => f.categoryId === category.id);
    const categoryLength = category.open ? eventsInCategory.length + 1 : 1;

    return (
        <div key={`ttp-matrix-${category.id}`} className={classes.matrixCategory} style={{gridTemplateColumns: `repeat(${categoryLength}, 65px)`}}>
          <div className={classes.event} onClick={() => onCategoryClick(category)}><b>{category.name}</b></div>
          {category.open && eventsInCategory.map(e => <div key={`ttp-matrix-${e.id}`} className={cn(classes.eventHeader, classes.event)}>{e.name}</div>)}
        </div>);
  }

  const onCategoryClick = (category) => {
    const newCategories = [...ttpCategories];
    const selected = newCategories.find(f => f.id === category.id);
    selected.open = !selected.open;
    setTtpCategories(newCategories);
  }

  const rowCategory = (category, player) => {
    const eventsInCategory = ttpEvents.filter(f => f.categoryId === category.id);
    const categoryLength = category.open ? eventsInCategory.length + 1 : 1;

    return (
        <div key={`ttp-matrix-${player.playerId}-${category.id}`} className={classes.matrixCategory} style={{gridTemplateColumns: `repeat(${categoryLength}, 65px)`}}>
          <div className={classes.category} onClick={() => onCategoryClick(category)}>{calcEventsCategory(player, category)}</div>
          {category.open && eventsInCategory.map(e => <div key={`ttp-matrix-${e.id}`}>{calcEvents(player, e)}</div>)}
        </div>);
  }

  const calcEvents = (player, event) => {
    let eventsCount = eventRecords.filter(f => f.playerId === player.playerId && f.gameDataEventId === event.id).length;
    if (event.haveSwitch === true) {
      const positiveEventsCount = eventRecords.filter(f => f.playerId === player.playerId && f.gameDataEventId === event.id && f.result === true).length;
      eventsCount += " / " + positiveEventsCount;
    }
    return eventsCount !== 0 && eventsCount !== "0 / 0" ? eventsCount : "-";
  }

  const calcEventsCategory = (player, category) => {
    const eventsInCategory = ttpEvents.filter(f => f.categoryId === category.id).map(e => e.id);
    const eventsCount = eventRecords.filter(f => f.playerId === player.playerId && eventsInCategory.indexOf(f.gameDataEventId) !== -1).length;
    return eventsCount > 0 ? eventsCount : "-";
  }

  const calcTtpByPlayer = (player) => {
    const eventsCount = eventRecords.filter(f => f.playerId === player.playerId).length;
    return eventsCount > 0 ? eventsCount : "-";
  }

  const calcTtpByPlayerPositive = (player) => {
    const eventsCount = eventRecords.filter(f => f.playerId === player.playerId && ttpEventsWithResult.indexOf(f.gameDataEventId) && f.result === true).length;
    return eventsCount > 0 ? eventsCount : "-";
  }

  const calcTtpByPlayerNegative = (player) => {
    const eventsCount = eventRecords.filter(f => f.playerId === player.playerId && ttpEventsWithResult.indexOf(f.gameDataEventId) && f.result === false).length;
    return eventsCount > 0 ? eventsCount : "-";
  }

  const calcTtpByPlayerPercent = (player) => {
    const positiveEventsCount = eventRecords.filter(f => f.playerId === player.playerId && ttpEventsWithResult.indexOf(f.gameDataEventId) && f.result === true).length;
    const negativeEventsCount = eventRecords.filter(f => f.playerId === player.playerId && ttpEventsWithResult.indexOf(f.gameDataEventId) && f.result === false).length;
    const eventsCount = positiveEventsCount + negativeEventsCount;
    const percent = (negativeEventsCount/eventsCount).toFixed(2)*100;

    return eventsCount > 0 && !isNaN(percent) ? percent + "%" : "-";
  }

  return (
      <div  className={classes.matrix}>
        <div className={cn(classes.matrixRow, classes.matrixHeader)} >
          <div className={classes.leftPanel}>&nbsp;</div>
          <div className={classes.matrixCells} style={{gridTemplateColumns: `150px repeat(${ttpCategories.length}, auto) 65px 65px 65px 65px 1fr`}}>
            <div className={classes.leftPanelSpacer}></div>
            {ttpCategories.map(c => headerCategory(c))}
            <div className={classes.event}>{t("TTP")}</div>
            <div className={classes.event}>{t("TTP+")}</div>
            <div className={classes.event}>{t("TTP-")}</div>
            <div className={classes.event}>{t("Defect %")}</div>
          </div>
        </div>


        {props.data.players.map(p =>
          <div key={`ttp-matrix-${p.playerId}`} className={classes.matrixRow}>
            <div className={classes.leftPanel}>{p.playerName} ({p.teamNumber})</div>
            <div className={classes.matrixCells} style={{gridTemplateColumns: `150px repeat(${ttpCategories.length}, auto) 65px 65px 65px 65px 1fr`}}>
              <div className={classes.leftPanelSpacer}></div>
              {ttpCategories.map(c => rowCategory(c, p))}
              {/*{ttpEvents.map(e => {*/}
              {/*  return (<div key={`ttp-matrix-${p.playerId}-${e.id}`}>-</div>);*/}
              {/*})}*/}
              <div className={classes.border}>{calcTtpByPlayer(p)}</div>
              <div>{calcTtpByPlayerPositive(p)}</div>
              <div>{calcTtpByPlayerNegative(p)}</div>
              <div>{calcTtpByPlayerPercent(p)}</div>
            </div>
          </div>
        )}

      </div>
  );
};

MatchesDetailsTtpMatrix.defaultProps = {
  data: {}
};

export default MatchesDetailsTtpMatrix;