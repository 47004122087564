import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import Table from 'components/UI/Table/Table';
import classes from 'containers/SysAdmin/ClubGroups/ClubGroup.module.scss';
import Subtext from 'components/UI/Table/Subtext/Subtext';
import {useDeleteGroup, useGetGroups} from 'apollo/queries/GroupQueries';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import ClubGroupForm from 'containers/SysAdmin/ClubGroups/forms/ClubGroupForm';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';

const ClubGroupSubgroups = (props) => {
	const {data, refetch} = props;

	const {t} = useTranslation();
	const history = useNavigate();

	const [deleteGroup] = useDeleteGroup();

	const [modalData, setModalData, initState] = ModalState();

	const onComplete = async () => {
		setModalData(initState);
		await refetch();
	};

	const onClick = (data) => {
		history(`/system/club-group-${data.groupId}`);
	};

	const onSettings = () => {
			return [
				{label: 'Edit group', id: '1', action: onEditGroup},
				{label: 'Delete group', id: '2', action: onDeleteGroup},
			];
	};

	const onEditGroup = (data) => {
		const _data = {
			...initState,
			title: t('Add group'),
			form: 'group',
			styles: {
				maxWidth: '700px',
			},
			defaultValues: {
				...data
			},
			onComplete: onComplete,
			onDelete: () => onDeleteGroup(data),
			open: true
		};

		setModalData(_data);
	};

	const onDeleteGroup = async (data) => {
		setModalData(initState);
		await deleteGroup({variables: {input: {id: data.groupId}}});
		await refetch();
	};

	console.log(data);

	return (
		<>
			<Table
				data={data.group?.subgroups ?? []}
				access={[SystemRoles.SysAdmin, SystemRoles.ClubAdmin]}
				columns={[
					{
						Header: '',
						accessor: 'logo',
						className: classes.rowLogo,
						Cell: (cellData) => <div style={{cursor: 'pointer'}}>
							<img src={cellData.value ?? "/assets/images/emptyLogo.svg"} alt={cellData.row.original.name}/></div>
					},
					{
						Header: t('Group name'),
						accessor: 'name',
						Cell: (cellData) => <div style={{cursor: 'pointer'}}>
							{cellData.value}
							<Subtext>{cellData.row.original.description}</Subtext>
						</div>
					},
					{
						Header: t('Clubs'),
						accessor: 'clubsCount',
						className: classes.stat,
						Cell: (cellData) => <div style={{cursor: 'pointer'}}>
							{cellData.row.original.stat.clubs ?? 0}
						</div>
					},
					{
						Header: t('Teams'),
						accessor: 'teamsCount',
						className: classes.stat,
						Cell: (cellData) => <div style={{cursor: 'pointer'}}>
							{cellData.row.original.stat.teams ?? 0}
						</div>
					},
					{
						Header: t('Coaches'),
						accessor: 'coachesCount',
						className: classes.stat,
						Cell: (cellData) => <div style={{cursor: 'pointer'}}>
							{cellData.row.original.stat.coaches ?? 0}
						</div>
					},
					{
						Header: t('Players'),
						accessor: 'playersCount',
						className: classes.stat,
						Cell: (cellData) => <div style={{cursor: 'pointer'}}>
							{cellData.row.original.stat.players ?? 0}
						</div>
					},
				]}
				onSettings={onSettings}
				onRowClick={onClick}/>

      <ModalWindow2 {...modalData}>
        <ClubGroupForm {...modalData} name="group" />
      </ModalWindow2>
		</>
	);
};

ClubGroupSubgroups.defaultProps = {};

export default ClubGroupSubgroups;
