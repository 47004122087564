export const getClubFormat = (data) => {
	const types = {
		FOOTBALL_FEDERATION: "Federation",
		FOOTBALL_ACADEMY: "Academy",
		SPORT_SCHOOL: "School",
		COMMERCIAL_CLUB: "Private Club",
		OTHER: "Other",
		NONE: "Club",
	};

	return types[data];
}
