import React, {useEffect, useRef, useState} from 'react';
import {FabricJSCanvas, useFabricJSEditor} from 'fabricjs-react';
import {fabric} from 'fabric';
import classes from './GoalMap.module.scss';

const GoalMap = (props) => {
    const {teamGoalMapStatistic} = props.data;

    const { editor, onReady } = useFabricJSEditor();
    const map = useRef(null);

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (!editor)
            return;

        if (!loaded) {
            setLoaded(true);
            loading();
        }

    }, [fabric, editor]);

    const loading = () => {
        const scale = Math.round(map.current.offsetWidth / 230);
        const x = 230 * scale;
        const y = 144 * scale;

        editor.canvas.setWidth(x);
        editor.canvas.setHeight(y);

        const height = map.current.offsetWidth / x * y;
        map.current.style.height = height + "px";

        fabric.loadSVGFromURL("/assets/images/fieldHorizontal.svg", function(objects, options) {
            const obj = fabric.util.groupSVGElements(objects, options);
            obj.scaleToWidth(x);
            obj.scaleToHeight(y);
            obj.selectable = false;
            obj.evented = false;
            editor.canvas.add(obj);
            obj.sendToBack();

        });

        teamGoalMapStatistic.forEach((mark) => {
            const data = JSON.parse(mark.data);

            if (!data?.x) {
                return;
            }

            addMark({x: data.x * scale, y: data.y * scale, r: 5 * scale / 2});
        });

        editor.canvas.renderAll();
    }

    const addMark = (mark) => {
        const circle = new fabric.Circle({
            radius: mark.r,
            left: mark.x,
            top: mark.y,
            fill: '#FFC31F',
            stroke: '#ff9800',
            originX: 'center',
            originY: 'center',
            selectable: false,
            evented: false
        });

        editor.canvas.add(circle);
    }

    return (
        <>
            <div ref={map} >
                <FabricJSCanvas className={classes.mapCanvas} onReady={onReady} />
            </div>

            {/*<svg id="goal-map-chart"*/}
            {/*     xmlns="http://www.w3.org/2000/svg"*/}
            {/*     viewBox="0 0 230 144" stroke="white" version="1.1" style={{width: "100%"}}>*/}
            {/*    <g transform="matrix(0.2,0,0,0.2,0,0)" id="map">*/}
            {/*        <rect width="1150" height="720" fill="#3BCE9D" id="rect2" />*/}
            {/*        <path d="M 575 20 L 50 20 L 50 700 L 1100 700 L 1100 20 L 575 20 L 575 700 Z" stroke="white" strokeWidth="2" fill="#3BCE9D" id="path4" />*/}
            {/*        <circle cx="575" cy="360" r="91.5" stroke="white" strokeWidth="2" fillOpacity="0" id="circle6" />*/}
            {/*        <path d="M 50 324.4 L 40 324.4 L 40 396.6 L 50 396.6 Z" stroke="white" strokeWidth="2" fillOpacity="0" id="path14" />*/}
            {/*        <path d="M 1100 324.4 L 1110 324.4 L 1110 396.6 L 1100 396.6 Z" stroke="white" strokeWidth="2" fillOpacity="0" id="path16" />*/}
            {/*        <path d="M 50 159.4 L 215 159.4 L 215 561.6 L 50 561.6 Z" stroke="white" strokeWidth="2" fillOpacity="0" id="path22" />*/}
            {/*        <path d="M 1100 159.4 L 935 159.4 L 935 561.6 L 1100 561.6 Z" stroke="white" strokeWidth="2" fillOpacity="0" id="path24" />*/}
            {/*        <path d="M 215 286.875 C 263.667 323.479 263.667 396.521 215 433.125 Z" stroke="white" strokeWidth="2" fill="#3BCE9D" id="path26" />*/}
            {/*        <path d="M 935 286.875 C 886.333 323.479 886.333 396.521 935 433.125 Z" stroke="white" strokeWidth="2" fill="#3BCE9D" id="path28" />*/}
            {/*        <circle cx="575" cy="360" r="2" stroke="white" fill="white" id="circle8" />*/}
            {/*        <circle cx="160" cy="360" r="2" stroke="white" fill="white" id="circle10" />*/}
            {/*        <circle cx="990" cy="360" r="2" stroke="white" fill="white" id="circle12" />*/}
            {/*        <path d="M 50 269.4 L 105 269.4 L 105 451.6 L 50 451.6 Z" stroke="white" strokeWidth="2" fillOpacity="0" id="path18" />*/}
            {/*        <path d="M 1100 269.4 L 1045 269.4 L 1045 451.6 L 1100 451.6 Z" stroke="white" strokeWidth="2" fillOpacity="0" id="path20" />*/}
            {/*        <path d="M 50 30 C 55.523 30 60 25.523 60 20 L 50 20 Z" stroke="white" strokeWidth="2" fillOpacity="0" id="path30" />*/}
            {/*        <path d="M 60 700 C 60 694.477 55.523 690 50 690 L 50 700 Z" stroke="white" strokeWidth="2" fillOpacity="0" id="path32" />*/}
            {/*        <path d="M 1100 690 C 1094.477 690 1090 694.477 1090 700 L 1100 700 Z" stroke="white" strokeWidth="2" fillOpacity="0" id="path34" />*/}
            {/*        <path d="M 1090 20 C 1090 25.523 1094.477 30 1100 30 L 1100 20 Z" stroke="white" strokeWidth="2" fillOpacity="0" id="path36" />*/}
            {/*    </g>*/}



            {/*    /!*@foreach (var goal in Model)*!/*/}
            {/*    /!*{*!/*/}
            {/*    /!*    var px = goal.FieldRotatedInd ? 220 - goal.PositionX : goal.PositionX;*!/*/}
            {/*    /!*    var py = goal.FieldRotatedInd ? 132 - goal.PositionY : goal.PositionY;*!/*/}

            {/*    /!*    var assX = goal.FieldRotatedInd ? 220 - goal.AssistX : goal.AssistX;*!/*/}
            {/*    /!*    var assY = goal.FieldRotatedInd ? 132 - goal.AssistY : goal.AssistY;*!/*/}


            {/*    /!*    if ((goal.GameEventType & (GameEventType.Asts | GameEventType.GoalConceded)) != GameEventType.None)*!/*/}
            {/*    /!*{*!/*/}
            {/*    /!*    if (assX != null && assY != null)*!/*/}
            {/*    /!*{*!/*/}
            {/*    /!*    <line x1="@(px + 1.5)" y1="@(py + 1.5)"*!/*/}
            {/*    /!*    x2="@(assX + 1.5)" y2="@(assY + 1.5)"*!/*/}
            {/*    /!*    class="line" />*!/*/}
            {/*    /!*    <g transform="matrix(1 0 0 1 @assX @assY)">*!/*/}
            {/*    /!*    <ellipse cx="1.5" cy="1.5" rx="2" ry="2" class="Goals"></ellipse>*!/*/}
            {/*    /!*    </g>*!/*/}
            {/*    /!*}*!/*/}
            {/*    /!*    <g transform="matrix(1 0 0 1 @px @py)" data-id="@goal.GameStatEventId">*!/*/}
            {/*    /!*    <ellipse cx="1.5" cy="1.5" rx="2" ry="2" class="@goal.GameEventType.ToString()"></ellipse>*!/*/}
            {/*    /!*    </g>*!/*/}
            {/*    /!*}*!/*/}
            {/*    /!*    else*!/*/}
            {/*    /!*{*!/*/}
            {/*    /!*    <g transform="matrix(1 0 0 1 @px @py)" data-id="@goal.GameStatEventId">*!/*/}
            {/*    /!*    <ellipse cx="1.5" cy="1.5" rx="2" ry="2" class="@goal.GameEventType.ToString()"></ellipse>*!/*/}
            {/*    /!*    </g>*!/*/}
            {/*    /!*}*!/*/}
            {/*    /!*}*!/*/}
            {/*</svg>*/}
        </>
    );
};

GoalMap.defaultProps = {};

export default GoalMap;
