import React, {useState} from 'react';
import Textarea from '../../../../components/UI/Textarea/Textarea';
import {useTranslation} from 'react-i18next';
import {useSuggestTranslation} from '../../../../apollo/queries/SettingQueries';
import {notHaveErrors, validateFields, validateForm} from '../../../../utils/form';
import * as yup from 'yup';
import Toast, {toastMessage} from '../../../../components/UI/Toast/Toast';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from '../../../../apollo/cashe';

const SuggestTranslationForm = (props) => {
    const {defaultValues, setLoading, onComplete} = props;

    const _systemData = {
        userType: 15,
        clubId: null,
        teamId: null,
        playerId: null,
        language: "en",
        userId: 111,
        userName: "Admin"
    }

    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const userId = _systemData.userId;
    const userName = _systemData.userName;

    const [values, setValues] = useState(defaultValues);
    const [errors, setErrors] = useState({});

    const[suggestTranslation] = useSuggestTranslation()

    const fieldChanged = (event) => {
        const newValues = {...values};
        newValues[event.target.name] = event.target.value;

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });

        setValues(newValues);
    };

    const validationRules = {
        value: yup.string().required(t("Require")),
    };

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors
    };

    const formSubmitted = (e) => {
        e.preventDefault();
        setLoading(true);
        validateForm(values, validationRules, (errors) => {
            if (notHaveErrors(errors)) {
                saveData();
            } else {
                setLoading(false);
                setErrors(errors);
            }
        });
    };

    const saveData = () => {
        const variables = {
            input: {
                userId: userId,
                userName: userName,
                clubId: navState.clubId ?? "",
                culture: navState.language,
                tag: values.code,
                value: values.value,
                url: window.location.href,
            }
        };

        suggestTranslation({variables: variables}).then(result => {
            onComplete();
            toastMessage(true, t("Suggested translation saved. Thank you!"));
        });
    }

    return (
        <>
            <form id={props.id} onSubmit={formSubmitted}>
                <Textarea
                    name="code"
                    label={t("Tag")}
                    disabled={true}
                    {...regField}
                />

                <Textarea
                    name="value"
                    height="10spo0px"
                    label={t("Translate")}
                    {...regField}
                />
            </form>

            <Toast />
        </>
    );
};

SuggestTranslationForm.defaultProps = {};

export default SuggestTranslationForm;
