import React, {useEffect, useState} from 'react';
import cn from 'classnames';
import classes from './Attendance.module.scss';
import Submenu from 'components/Navigation/Submenu/Submenu';
import Panel from 'components/UI/Panel/Panel';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar, systemDataVar} from 'apollo/cashe';
import DatePicker from 'components/UI/DatePicker/DatePicker';
import {firstDayOfMonth, getDate, lastDayOfMonth, showDateHours, today} from 'utils/date';
import {useSaveTrainingPlayerStatus, useTrainingsAttendance} from 'apollo/queries/TrainingQueries';
import Matrix from 'components/UI/Matrix/Matrix';
import Dropdown from 'components/UI/Table/DropDown/Dropdown';
import Button from 'components/UI/Button/Button';

const AttendanceTrainings = (props) => {
    const {attendance, health} = props;

    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);
    const systemData = useReactiveVar(systemDataVar);
    const inTrainingStatus = ['PRESENT', 'WAS_LATE'];

    const [matrixData, setMatrixData] = useState([]);
    const [columns, setColumns] = useState("170px repeat(4, 90px) 100px 1fr");

    const [values, setValues] = useState({
        startDate: firstDayOfMonth(),
        endDate: lastDayOfMonth()
    });

    const [getTrainingsAttendance, trainingsAttendanceData] = useTrainingsAttendance();
    const [saveTrainingPlayerStatus] = useSaveTrainingPlayerStatus();

    useEffect(() => {
        if (!navState.clubId) return;

        getTrainingsAttendance({ variables: {
            "clubId": navState.clubId,
            "teamId": navState.teamId,
            "where": {
                "start": {"gte": values.startDate},
                "end": {"lte": values.endDate}
            }
        }});
    }, [navState, values]);

    useEffect(() => {
        if(!trainingsAttendanceData.data) return;

        const {data} = trainingsAttendanceData;

        let _matrixData = [];
        const _header = {
            rowId: "header0",
            cells: [{ columnId: "column-name", cellName: t("Player")}]
        };

        const _columns = data.trainings.items.map(e => (
          {columnId: e.trainingId, cellName: showDateHours(e.start, systemData.settings.general)}
        ));

        _header.cells = _header.cells.concat(_columns);
        _header.cells.push({ columnId: "column-rating", cellName: t("Rating")});
        _header.cells.push({ columnId: "column-before", cellName: t("Before")});
        _header.cells.push({ columnId: "column-after", cellName: t("After")});
        _header.cells.push({ columnId: "column-attendance", cellName: t("Attendance")});
        _matrixData.push(_header);

        const _rows = data.players.items.map(p => {
           const _cells = data.trainings.items.map(e => {

               const player = e.players.find(f => f.playerId === p.playerId);
               const happened = getDate(e.start) <= today();

               return {
                   columnId: e.trainingId,
                   cellName: player && happened ? <Dropdown name="status" mode="iconWithoutText"
                                       onChange={data => onChangeValue(player, data)}
                                                options={attendance} value={player?.status} /> : "-",
                   healthAfter: getHealthIcon(player?.healthAfter),
                   healthBefore: getHealthIcon(player?.healthBefore),
                   rating: player?.rating ?? "",
                   status: player?.status ?? "",
                   happened: happened
               }
            });

           const _happened = _cells.filter(f => f.happened);
           const rating = Math.round(_happened.reduce((sum, a) => sum + a.rating ?? 0, 0) / _happened.length) ?? 0;
           const attendanceSum = _happened.reduce((sum, a) => inTrainingStatus.includes(a.status) ? sum + 1 : sum, 0);
           const percent = attendanceSum !== 0 ? Math.round( attendanceSum / _happened.length * 100) : 0;

            const _row = {
                rowId: p.playerId,
                cells: [
                    { columnId: p.playerId, cellName: `${p.fullName} (${p.teams[0]?.teamNumber})`},
                    ..._cells,
                    { columnId: "column-rating", cellName: rating !== 0 && !isNaN(rating) ? rating : ""},
                    { columnId: "column-before", cellName: _happened.length ? _cells[0]?.healthBefore ?? "" : ""},
                    { columnId: "column-after", cellName:  _happened.length ? _cells[_cells.length - 1]?.healthAfter ?? "" : ""},
                    { columnId: "column-attendance", cellName:  `${attendanceSum}/${_happened.length} (${percent}%)`}
                ]
            };

            setColumns(`170px repeat(${_cells.length + 3}, 90px) 120px 1fr`);
            return _row;
        });

        _matrixData = _matrixData.concat(_rows);

        setMatrixData(_matrixData);

    }, [trainingsAttendanceData.data]);

    const onChangeValue = (player, data) => {
        const variables = {
            input: {
                teamId: navState.teamId,
                playerTrainingId: player.playerTrainingId,
                status: data.target.value
            }
        }

        saveTrainingPlayerStatus({variables: variables}).then(result => {
            trainingsAttendanceData.refetch();
        });
    }

    const getHealthIcon = (val) => {
        const  status = health.find(x => x.value === val);
        return status?.icon ? <span dangerouslySetInnerHTML={{__html: status?.icon}}></span> : "";
    }

    const actions = () => {
        return (
            <Button type="primary" style={{margin: 0}}>
                <span><i className="icon-Send"></i> {t("Share")}</span>
            </Button>
        )
    }

    const onFilter = (e) => {
        const newFilters = {...values};
        newFilters[e.target.name] = e.target.value;
        setValues(newFilters);
    }

    const filters = () => {
        return (
            <>
                <DatePicker
                    name="startDate"
                    onChange={onFilter}
                    placeholder={t("Date from")}
                    value={values}
                />

                <DatePicker
                    name="endDate"
                    onChange={onFilter}
                    placeholder={t("Date till")}
                    value={values}
                />
            </>
        )
    }

    if (trainingsAttendanceData.loading) return (<div>{t("Loading...")}</div>);
    if (trainingsAttendanceData.error) return (<div>{t("Error...")}</div>);

    return (
        <>
            <div className={cn("page-header", classes.attendance)}>
                <div className={classes.name}>{navState.teamName}</div>
                <h2>{t('Attendance trainings')}</h2>
                <Submenu />
            </div>
            <div className="page-content">
                <Panel border={false} action={actions} filters={filters} />

                <Matrix data={matrixData} columns={columns} />
            </div>
        </>
    );
};

AttendanceTrainings.defaultProps = {
    attendance: [
        {label: "Present", icon: "<i class=\"icon-Check-Square icon-green\"></i>", value: "PRESENT"},
        {label: "Was late", icon: "<i class=\"icon-Time-Square icon-yellow\"></i>", value: "WAS_LATE"},
        {label: "Ill", icon: "<i class=\"icon-medicine icon-blue\"></i>", value: "ILL"},
        {label: "Excused", icon: "<i class=\"icon-Document icon-blue\"></i>", value: "EXCUSED"},
        {label: "Unexcused", icon: "<i class=\"icon-Close-Square icon-red\"></i>", value: "UNEXCUSED"},
    ],
    health: [
        {label: "Good", icon: "<span class=\"icon-smHappy\"><span class=\"path1\"></span><span class=\"path2\"></span><span class=\"path3\"></span><span class=\"path4\"></span><span class=\"path5\"></span><span class=\"path6\"></span></span>", value: "GOOD"},
        {label: "Average", icon: "<span class=\"icon-smConfused\"><span class=\"path1\"></span><span class=\"path2\"></span><span class=\"path3\"></span><span class=\"path4\"></span><span class=\"path5\"></span><span class=\"path6\"></span></span>", value: "AVERAGE"},
        {label: "Bad", icon: "<span class=\"icon-smSad\"><span class=\"path1\"></span><span class=\"path2\"></span><span class=\"path3\"></span><span class=\"path4\"></span><span class=\"path5\"></span><span class=\"path6\"></span><span class=\"path7\"></span><span class=\"path8\"></span><span class=\"path9\"></span></span>", value: "BAD"},
    ],
};

export default AttendanceTrainings;
