import React, {useEffect, useState} from 'react';
import {useHighlightsAllLazy} from 'apollo/queries/GameQueries';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import VideoMatrix from '../MatchesDetails/VideoMatrix';

const MatchesVideoMatrix = (props) => {
    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const [players, setPlayers] = useState([]);

    const [getHighlightsAllLazy, highlightsAllData]  = useHighlightsAllLazy();

    useEffect(() => {
        if (!navState.clubId || !navState.teamId) {
            return;
        }

        const variables = {
            clubId: navState.clubId,
            teamId: navState.teamId,
            type: "GAME",
        }

        getHighlightsAllLazy({variables: variables});
    }, [navState]);

    useEffect(() => {
        if (!highlightsAllData.data) {
            return;
        }

        const _players = highlightsAllData.data.players.items.map(x => ({
            playerId: x.playerId,
            playerName: x.fullName,
            teamNumber: x.teams[0]?.teamNumber ?? ""
        }));
        setPlayers(_players);
    }, [highlightsAllData.data]);

    if (highlightsAllData.loading) {
        return (<>Loading</>);
    }

    if (highlightsAllData.error) {
        return (<>Error</>);
    }

    console.log(highlightsAllData.data);

    if (highlightsAllData.data && players.length > 0) {
        return (
            <VideoMatrix data={{
                eventRecords: highlightsAllData.data.highlightsAll,
                events: highlightsAllData.data.dataEvents,
                players: players
            }}/>
        );
    }

    return (<></>);
};

MatchesVideoMatrix.defaultProps = {};

export default MatchesVideoMatrix;
