import React, {PropTypes, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {notHaveErrors, validateFields, validateForm} from 'utils/form';
import * as yup from 'yup';
import Select from 'components/UI/Select/Select';
import classes from '../MatchesDetails.module.scss';
import {createGuid} from 'utils/utils';
import {useSaveDataEventRecord} from 'apollo/queries/GameQueries';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import TimePicker3 from 'components/UI/DatePicker/TimePicker3';
import './GoalForm.css';
/* global $ */

const GoalForm = (props) => {
  const [values, setValues] = useState({...props.values });
  const [errors, setErrors] = useState({});
  const [teamA, setTeamA] = useState(props.formData.ownerTeamName);
  const [teamB, setTeamB] = useState(props.formData.otherTeamName);
  const [rotate, setRotate] = useState(false);
  const [map, setMap] = useState(props.values?.positionX !== undefined);
  const [goal, setGoal] = useState({x: props.values?.positionX ?? 180, y: props.values?.positionY ?? 60});
  const [assist, setAssist] = useState({x:170, y:90});

  const navState = useReactiveVar(naviDataVar);
  const {t} = useTranslation();

  const [saveDataEventRecord] = useSaveDataEventRecord();

  const fieldChanged = (event) => {
    const newValues = { ...values };
    newValues[event.target.name] = event.target.value

    validateFields(newValues, validationRules, (errors) => {
      setErrors(errors);
    });
    setValues(newValues);
  }

  const onRotate = () => {
    setRotate(!rotate);
    const leftTeam = teamA;
    setTeamA(teamB);
    setTeamB(leftTeam);
    setMap(true);
  }

  const validationRules = {
    playerId: yup.string().required(t("Require")),
    assistantId: yup.string().notOneOf([yup.ref('playerId')],t("Mast be unique")),
    minutes: yup.string().required(t("Require"))
  }

  useEffect(() => {
    const newValues = {...values};
    newValues.playerId = newValues.playerId ?? props.playerId;
    newValues.assistantId = newValues.assistantId ?? "";

    setValues(newValues);
  }, [props.values]);

  useEffect(() => {
    const $svg = $("#goal-position-chart");
    placePlayer($svg, "g-goal", "None", "G", goal.x, goal.y, "goal");
    placePlayer($svg, "g-assist", "None", "A", 170, 90, "assist");
  }, []);

  const formSubmitted = (e) => {
    e.preventDefault();
    validateForm(values, validationRules, (errors) => {
      if (notHaveErrors(errors))
        saveData();
      else
        setErrors(errors);
    });
  }

  const saveData = () => {
    const eventId = values?.id ?? createGuid();

    saveDataEventRecord({variables: goalData(eventId)}).then(goalResult => {
      if (values.assistantId !== "") {
        saveDataEventRecord({variables: assistantData(eventId)}).then(assistResult => {
          props.onComplete(assistResult);
        });
      } else {
        props.onComplete(goalResult);
      }
    });
  }

  const goalData = (eventId) => {
    const variables = {
      input: {
        id: eventId,
        clubId: navState.clubId,
        teamId: navState.teamId,
        gameId: props.gameId,
        eventType: "TEAM_EVENT",
        gameDataEventId: props.eventId,
        playerId: props.playerId,
        time: + values.minutes,
        duration: 10,
      }
    };

    if (map) {
      variables.input.data = JSON.stringify({
        x: goal.x,
        y: goal.y,
      });
    }

    return variables;
  }

  const assistantData = (eventId) => {
    const data = {parentEventId: eventId};

    if (map) {
      data.x = assist.x;
      data.y = assist.y;
    }

    const variables = {
      input: {
        id: createGuid(),
        clubId: navState.clubId,
        teamId: navState.teamId,
        gameId: props.gameId,
        eventType: "TEAM_EVENT",
        gameDataEventId: 'D9A7E14C-9354-4231-A19E-84F099990C4C',
        playerId: values.assistantId,
        time: + values.minutes,
        duration: 10,
        data: JSON.stringify(data)
      }
    };

    return variables;
  }

  //legacy code
  const players = props.formData.players.map(x => ({label: x.playerName, value: x.playerId}));
  const assistant = [{label:" - ", value: ""}, ...players];

  let selectedElement = 0;
  let currentX = 0;
  let currentY = 0;
  let currentMatrix = 0;
  let height = 100;
  let width = 100;
  let elemHeight = 14;
  let elemWidth = 14;
  let xRatio = 1;
  let yRatio = 1;
  let svg = 0;
  let viewBox;
  let blockScrollBehindModal = false;
  let positions = [];

  var selectSvgElement = function (evt) {
    /*-- Appropriate object selection on screen or display --*/
    var target = undefined;
    if (evt.type === "touchstart") {
      evt = evt.originalEvent.touches[0];
      target = evt.target.parentNode;
    }
    else
      target = evt.currentTarget;
    var $target = $(target);
    var $svg = $target.closest("svg");
    svg = $svg[0];
    viewBox = $svg[0].getAttributeNS(null, "viewBox").split(' ');
    width = $svg.width();
    height = $svg.height();
    viewBox[0] = parseInt(viewBox[0]);
    viewBox[1] = parseInt(viewBox[1]);
    viewBox[2] = parseInt(viewBox[2]);
    viewBox[3] = parseInt(viewBox[3]);
    xRatio = viewBox[2] / width;
    yRatio = viewBox[3] / height;

    selectedElement = target;
    currentX = evt.clientX;
    currentY = evt.clientY;
    currentMatrix = selectedElement.getAttributeNS(null, "transform").slice(7, -1).split(' ');
    for (var i = 0; i < currentMatrix.length; i++) {
      currentMatrix[i] = parseFloat(currentMatrix[i]);
    }
    $svg.on("touchstart.positions", function() {
      blockScrollBehindModal = true;
    });
    $svg.on("mousemove.positions touchmove.positions", moveSvgElement);
    $svg.on("mouseout.positions ", function () {
      if (!$svg.is(":hover")) {
        deselectSvgElement();
      }
    });
    $svg.on("mouseup.positions touchend.positions", deselectSvgElement);
    $svg.on("touchend.positions", function() {
      blockScrollBehindModal = false;
    });
    $target.appendTo($target.parent());

    const selected = {
      id: "player",
      x: currentX,
      y: currentY,
      viewBox: [
        parseInt(viewBox[0]),
        parseInt(viewBox[1]),
        parseInt(viewBox[2]),
        parseInt(viewBox[3]),
      ],
      width: width,
      height: height,
      xRatio: xRatio,
      yRatio: yRatio,
      matrix: currentMatrix,
      target: target
    };
  };

  var moveSvgElement = function (evt) {
    /*-- Preventing scrolling behind layers while moving player position --*/
    if (blockScrollBehindModal)
      evt.preventDefault();
    if (evt.type === "touchmove")
      evt = evt.originalEvent.touches[0];
    const dx = evt.clientX - currentX;
    const dy = evt.clientY - currentY;
    currentMatrix[4] = currentMatrix[4] || currentX;
    currentMatrix[5] = currentMatrix[5] || currentY;
    currentMatrix[4] += dx * xRatio;
    currentMatrix[5] += dy * yRatio;
    currentMatrix[4] = Math.min(Math.max(currentMatrix[4], viewBox[0] + 2), viewBox[2] - elemWidth + 1);
    currentMatrix[5] = Math.min(Math.max(currentMatrix[5], viewBox[1] + 2), viewBox[3] - elemHeight + 1);
    const newMatrix = "matrix(" + currentMatrix.join(' ') + ")";
    selectedElement.setAttributeNS(null, "transform", newMatrix);
    currentX = evt.clientX;
    currentY = evt.clientY;
    var x = currentMatrix[4].toFixed(), y = currentMatrix[5].toFixed();
    var id = $(selectedElement).attr("data-id");
    var $form = $(selectedElement).closest("form");



    if (id === "g-goal") {
      setGoal({x: +x, y: +y});
    } else if (id === "g-assist") {
      setAssist({x: +x, y: +y});
    }
  };

  var deselectSvgElement = function () {
    if (selectedElement !== 0) {
      $(svg)
          .unbind("mousemove.positions touchmove")
          .unbind("mouseup.positions touchend")
          .unbind("onmouseout.positions");
      var $tr = $(svg).closest("form")
          .find("input[value='" + $(selectedElement).attr("data-id") + "']")
          .closest("tr");
      setTimeout(function () {
        $tr.find("select[name$='Position']").removeClass("blink-select");
      }, 1000);
      selectedElement = 0;
    }
  };

  var placePlayer = function ($svg, id, position, title, x, y, type) {
    x = x || positions[position].x;
    y = y || positions[position].y;

    var g = document.createElementNS("http://www.w3.org/2000/svg", 'g');
    var elipse = document.createElementNS("http://www.w3.org/2000/svg", 'ellipse');
    g.appendChild(elipse);


    var text = document.createElementNS("http://www.w3.org/2000/svg", 'text');
    $(text).attr("x", 5).attr("y", 7);
    var textNode = document.createTextNode(title);
    text.appendChild(textNode);
    g.appendChild(text);

    $svg[0].appendChild(g);
    var $elipse = $(elipse).attr("cx", 5).attr("cy", 5).attr("rx", 7).attr("ry", 7);
    var $g = $(g).attr("data-id", id)
        .attr("transform", "matrix(1, 0, 0, 1, " + x + ", " + y + ")")
        .addClass("draggable")
        .on("mousedown.positions touchstart", selectSvgElement);
    if (!type) {
      $g.addClass("blink-ellipse");
      setTimeout(function () { $(g).removeClass("blink-ellipse") }, 2000);
    } else {
      $elipse.addClass(type);
    }
  };

  return (
      <>
        <form id={props.id} onSubmit={formSubmitted}>
          <Select
              name="playerId"
              label={t('Player')}
              value={values}
              onChange={(e) => fieldChanged(e)}
              options={players}
              error={errors} />

          <Select
              name="assistantId"
              label={t('Adssistant')}
              value={values}
              onChange={(e) => fieldChanged(e)}
              options={assistant}
              error={errors} />

          <TimePicker3
              name="minutes"
              label={t('Minutes')}
              value={values}
              isSportTimer={true}
              onChange={(e) => fieldChanged(e)}
              format="m.ss"
              error={errors}
          />

          <div className={classes.mapHeader}>
            <div>{teamA}</div>
            <div><div className={classes.rotate} onClick={onRotate}><i className="icon-refresh icon-blue" /></div></div>
            <div>{teamB}</div>
          </div>

          <div className="notify-handler">
            {!map && <div className="notify" style={{zIndex: "auto"}} onClick={() => setMap(true)}>
              <p>
                Нажмите сюда, что-бы настроить расстановку игроков
              </p>
            </div>}
            <svg id="goal-position-chart"
                 className={values.assistantId !== "" ? classes.showAssistant : classes.hideAssistant}
                 xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 230 144" stroke="white" version="1.1" style={{width: "100%"}}>
              <g transform="matrix(0.2,0,0,0.2,0,0)">
                <rect width="1150" height="720" fill="green" id="rect2" />
                <path d="M 575 20 L 50 20 L 50 700 L 1100 700 L 1100 20 L 575 20 L 575 700 Z" stroke="white" strokeWidth="2" fill="green" id="path4" />
                <circle cx="575" cy="360" r="91.5" stroke="white" strokeWidth="2" fillOpacity="0" id="circle6" />
                <path d="M 50 324.4 L 40 324.4 L 40 396.6 L 50 396.6 Z" stroke="white" strokeWidth="2" fillOpacity="0" id="path14" />
                <path d="M 1100 324.4 L 1110 324.4 L 1110 396.6 L 1100 396.6 Z" stroke="white" strokeWidth="2" fillOpacity="0" id="path16" />
                <path d="M 50 159.4 L 215 159.4 L 215 561.6 L 50 561.6 Z" stroke="white" strokeWidth="2" fillOpacity="0" id="path22" />
                <path d="M 1100 159.4 L 935 159.4 L 935 561.6 L 1100 561.6 Z" stroke="white" strokeWidth="2" fillOpacity="0" id="path24" />
                <path d="M 215 286.875 C 263.667 323.479 263.667 396.521 215 433.125 Z" stroke="white" strokeWidth="2" fill="green" id="path26" />
                <path d="M 935 286.875 C 886.333 323.479 886.333 396.521 935 433.125 Z" stroke="white" strokeWidth="2" fill="green" id="path28" />
                <circle cx="575" cy="360" r="2" stroke="white" fill="white" id="circle8" />
                <circle cx="160" cy="360" r="2" stroke="white" fill="white" id="circle10" />
                <circle cx="990" cy="360" r="2" stroke="white" fill="white" id="circle12" />
                <path d="M 50 269.4 L 105 269.4 L 105 451.6 L 50 451.6 Z" stroke="white" strokeWidth="2" fillOpacity="0" id="path18" />
                <path d="M 1100 269.4 L 1045 269.4 L 1045 451.6 L 1100 451.6 Z" stroke="white" strokeWidth="2" fillOpacity="0" id="path20" />
                <path d="M 50 30 C 55.523 30 60 25.523 60 20 L 50 20 Z" stroke="white" strokeWidth="2" fillOpacity="0" id="path30" />
                <path d="M 60 700 C 60 694.477 55.523 690 50 690 L 50 700 Z" stroke="white" strokeWidth="2" fillOpacity="0" id="path32" />
                <path d="M 1100 690 C 1094.477 690 1090 694.477 1090 700 L 1100 700 Z" stroke="white" strokeWidth="2" fillOpacity="0" id="path34" />
                <path d="M 1090 20 C 1090 25.523 1094.477 30 1100 30 L 1100 20 Z" stroke="white" strokeWidth="2" fillOpacity="0" id="path36" />
              </g>
            </svg>
            <div className={map ? classes.removeLayout : classes.hideRemoveLayout} onClick={() => setMap(false)}>{t("Remove players layout")}</div>
          </div>
        </form>
      </>
  );
};

GoalForm.propTypes = {};
GoalForm.defaultProps = {
  values: {},
  formData: {
    players: [],
    ownerTeamName: "",
    otherTeamName: "",
  },
  onComplete: () => {}
};

export default GoalForm;
