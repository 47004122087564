import React from 'react';
import classes from './MatchesDetails.module.scss';
import {useTranslation} from 'react-i18next';
import PlayVideo from './forms/PlayVideo';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import Pagination from 'components/UI/Pagination/Pagination';

const VideoList = (props) => {
    const {items, totalCount} = props.data;

    const {t} = useTranslation();

    const [modalData, setModalData, initState] = ModalState();

    const onVideoClick = (data) => {
        if (Array.isArray(data))
            data = data[0];

        const {playerName, title, link, time} = data;

        const _data = {
            ...initState,
            form: "video",
            title: playerName ? `${playerName}, ${title}` : title,
            buttons: {
                hideSaveButton: true
            },
            defaultValues: {
                video: link,
                time: time
            },
            open: true
        };

        setModalData(_data);
    }

    if (items.length === 0)
        return (<div className={classes.empty}>{t("No data to display.")}</div>);

    return (
        <div>
            <div className={classes.videoList}>
                {items.filter(v => v.videoId !== null).filter(x => x.image !== undefined).map(video => {
                    const title = video.playerName ? `${video.playerName}, ${video.title}`: video.title;
                    return (
                        <div key={`clip-${video.id}`} onClick={() => onVideoClick(video)}>
                            <div>
                                <img src={video.image} alt={title} />
                            </div>
                            <div>{title}</div>
                        </div>
                    )
                })}
            </div>

            {props.totalCount
            ? <Pagination onPage={props.onPage} page={props.skip} count={totalCount} onPageChange={(p) => props.setSkip(p.selected)}/>
            : undefined}

            <ModalWindow2 {...modalData}>
                    <PlayVideo {...modalData} />
            </ModalWindow2>
        </div>
    );
};

VideoList.propTypes = {};
VideoList.defaultProps = {
    onPage: 10,
    data: {}
};

export default VideoList;
