import {gql, useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {TRAINING_FIELDS} from './TrainingQueries';

const GET_GAMES_PAGE = gql`
    query GamePage($teamId: Uuid!, $today: DateTime) {
        itemBeforeToday: games(
            teamId: $teamId
            where: { date: { gte: $today } }
        ) {
            totalCount
        }
    }`;
export const useGamesPage = () => useLazyQuery(GET_GAMES_PAGE);

const GET_GAMES = gql`
    query Matches($teamId: Uuid!, $where: GameTypeFilterInput!, $skip: Int, $take: Int) {
      games(teamId: $teamId, where: $where, order: { date: ASC }, skip: $skip, take: $take) {
        totalCount
        items {
          gameId
          scope
          name
          date
          duration
          periodCount
          ownerTeamId
          ownerTeamName
          resultLeft
          resultRight
          otherTeamName
          otherTeamId
          isVideoUploaded
          location
          awayMatch
          matchNotes
        }
      }
    }`;
export const useGames = () => useLazyQuery(GET_GAMES);


const GET_NEW_GAME_OPTIONS = gql`
    query MatchesOptions($clubId: String!, $teamId: Uuid!) {
        competitions:championships(teamId: $teamId, take: 200) {
            items {
                championshipId
                name
                country
                season
                startDate
            }
        }

        opponents(teamId: $teamId, take: 200) {
            items {
                id
                name
            }
        }

        venues(clubId: $clubId, take: 200, where:{showOnGames:{eq:true}}) {
            items {
                venueId
                name
            }
        }

        players(clubId: $clubId, where: {teams: {some: {team: {teamId: {eq: $teamId}}}}}, skip:0, take:100) {
            items {
                playerId
                fullName
                teams {
                    teamNumber
                    position
                }
            }
        }

        otherSettings(where:{groupName: {eq: "Country"}} ) {
          key
          value
        }
    }`;
export const useGameOptions = () => useLazyQuery(GET_NEW_GAME_OPTIONS);

const GET_GAME_DETAILS = gql`
    query GameDetails($teamId: Uuid!, $clubId: String!, $where:GameTypeFilterInput!) {
      games(teamId: $teamId, clubId: $clubId, where: $where) {
        items {
          gameId
          scope
          name
          date
          duration
          periodCount
          ownerTeamId
          ownerTeamName
          resultLeft
          resultRight
          otherTeamId
          otherTeamName
          isVideoUploaded
          awayMatch
          location
          championshipId
          matchNotes
          tacticalScheme
          players {
            playerId
            playerName
            teamNumber
            captain
            position
            positionX
            positionY
            status
            goalkeeper
            playTime
            goals
            goalConceded
            saves
            pens
            penaltyFaced
            penaltySaved
            asts
            yellowCards
            redCards
            rating
            substitute
          }
          notSelectedPlayers {
            playerId
            playerName
            teamNumber
          }
          videos {
            videoId
            name
            size
            contentType
            link
            store
            userName
            createdTime
            events {
                videoEventId
                category
                name
                time
                duration
                playerId
                playerName
            }
          }
          events {
            id
            categoryId
            parentId
            order
            name
            teamId
            isVideo
            isTtp
            isSystem
            haveSwitch
            forAllTeams
          }
          eventCategories {
            id
            name
            isSystem
            order
          }
          eventRecords {
            id
            eventType
            gameDataEventId
            videoId
            link
            image
            playerId
            time
            duration
            data
            result
            title
            playerName
          }
          eventRecordsWithoutVideo {
              id
              eventType
              gameDataEventId
              videoId
              link
              image
              playerId
              time
              duration
              data
              result
              title
              playerName
          }
        }
      }
    }`;
export const useGameDetails = () => useLazyQuery(GET_GAME_DETAILS);

const GET_GAME_EVENT = gql`
    query GameEvent($gameId: Uuid!, $playerId: Uuid!, $event: GameEventValueType!, $skip: Int!) {
        gameEvent(gameId: $gameId, playerId: $playerId, gameEvent: $event, skip: $skip) {
            totalCount
            items {
                gameStatEventId
                eventType
                playerId
                playerName
                minutes
                missed
                positionX
                positionY
                linkedEventId
                rotateFieldInd
                linkedEvent {
                    gameStatEventId
                    eventType
                    playerId
                    playerName
                    minutes
                    missed
                    positionX
                    positionY
                    linkedEventId
                    rotateFieldInd
                }
            }
        }
    }`;
export const useGameEventList = (variables) => useQuery(GET_GAME_EVENT, {variables: variables});


const SAVE_GAME = gql`
    mutation SaveGame($input: GameInput!) {
        saveGame(input: $input)
    }`;
export const useSaveGame = () => useMutation(SAVE_GAME);


const SAVE_GAME_STAT = gql`
    mutation SaveGameStat($input: GameEventInput!) {
      saveGameEvent(input: $input)
    }`;
export const useSaveGameStat = () => useMutation(SAVE_GAME_STAT);


const SAVE_DATA_EVENT = gql`
    mutation SaveDataEvent($input: GameDataEventInput!) {
        saveDataEvent(input: $input)
    }`;
export const useSaveDataEvent = () => useMutation(SAVE_DATA_EVENT);


const DELETE_DATA_EVENT = gql`
    mutation DeleteDataEvent($input: DeleteInput!) {
        deleteDataEvent(input: $input)
    }`;
export const useDeleteDataEvent = () => useMutation(DELETE_DATA_EVENT);


const SAVE_DATA_EVENT_CATEGORY = gql`
    mutation SaveDataEventCategory($input: GameDataEventCategoryInput!) {
        saveDataEventCategory(input: $input)
    }`;
export const useSaveDataEventCategory = () => useMutation(SAVE_DATA_EVENT_CATEGORY);

const DELETE_VIDEO_SUBACTION = gql`
    mutation DeleteVideoSubaction($input: DeleteInput!) {
        deleteVideoSubaction(input: $input)
    }`;
export const useDeleteVideoSubaction = () => useMutation(DELETE_VIDEO_SUBACTION);

const DELETE_GAME_VIDEO = gql`
    mutation DeleteGameVide($input: DeleteInput!) {
      deleteGameVideo(input:$input) 
    }`;
export const useDeleteGameVideo = () => useMutation(DELETE_GAME_VIDEO);

const ADD_GAME_VIDEO_LINK = gql`
    mutation AddVideoLink($input: VideoLinkTypeInput!) {
      addGameVideoLink(input: $input)
    }`;
export const useAddGameVideoLink = () => useMutation(ADD_GAME_VIDEO_LINK);

const ADD_DATA_EVENT_RECORD = gql`
    mutation AddDataEventRecord($input: GameDataEventRecordInput!) {
        saveDataEventRecord(input: $input)
    }`;
export const useSaveDataEventRecord = () => useMutation(ADD_DATA_EVENT_RECORD);

const DELETE_DATA_EVENT_RECORD = gql`
    mutation DeleteDataEventRecord($input: DeleteInput!) {
      deleteDataEventRecord(input:$input) 
    }`;
export const useDeleteDataEventRecord = () => useMutation(DELETE_DATA_EVENT_RECORD);


const ADD_GAME_PLAYER = gql`
    mutation AddGamePlayer($input: AddGamePlayerInput!) {
      addGamePlayer(input:$input) 
    }`;
export const useAddGamePlayer = () => useMutation(ADD_GAME_PLAYER);

const UPDATE_GAME_PLAYER = gql`
    mutation UpdateGamePlayer($input: UpdateGamePlayerInput!) {
      updateGamePlayer(input:$input) 
    }`;
export const useUpdateGamePlayer = () => useMutation(UPDATE_GAME_PLAYER);

const DELETE_GAME_PLAYER = gql`
    mutation DeleteGamePlayer($input: DeleteGamePlayerInput!) {
      deleteGamePlayer(input:$input) 
    }`;
export const useDeleteGamePlayer = () => useMutation(DELETE_GAME_PLAYER);


const COPY_GAME = gql`
  mutation CopyGame($input: GameInput!) {
    copyGame(input: $input)
  }
`;

export const useCopyGame = () => useMutation(COPY_GAME);


const DELETE_GAME = gql`
  mutation DeleteGame($input: DeleteInput) {
    deleteGame(input: $input)
  }
`;

export const useDeleteGame = () => useMutation(DELETE_GAME);


const GET_OPPONENTS = gql`
    query Opponents($teamId: Uuid!, $take: Int, $skip: Int) {
      opponents(teamId: $teamId, take: $take, skip: $skip) {
        totalCount
        items {
            id
            name
            games
            wins
            losses
            draws
            goals
        }
      }
    }`;
export const useOpponents= (variables) => useQuery(GET_OPPONENTS, {variables: variables});

const ADD_OPPONENT = gql`
    mutation SaveOpponent($input: OpponentInput!) {
      saveOpponent(input:$input) 
    }`;
export const useSaveOpponent = (variables) => useMutation(ADD_OPPONENT, {refetchQueries: [{ query: GET_OPPONENTS, variables: variables }]});

const DELETE_OPPONENT = gql`
    mutation DeleteOpponent($input: DeleteInput!) {
      deleteOpponent(input:$input) 
    }`;
export const useDeleteOpponent = (variables) => useMutation(DELETE_OPPONENT, {refetchQueries: [{ query: GET_OPPONENTS, variables: variables }]});

const GET_COMPETITIONS = gql`
    query GetCompetitions($teamId: Uuid!, $take: Int, $skip: Int) {
        competitions:championships(teamId: $teamId, take: $take, skip: $skip) {
            totalCount
            items {
                id:championshipId
                name
                shortName
                competitionType
                season
                country
                startDate
                endDate
                achievement
                logo
                logoUploadDate
            }
        }
        otherSettings(where:{groupName: {eq: "Country"}} ) {
            key
            value
        }
    }`;
export const useCompetitions = (variables) => useQuery(GET_COMPETITIONS, {variables: variables});

const ADD_COMPETITION = gql`
    mutation SaveCompetition($input: ChampionshipInput!) {
      saveChampionship(input:$input) 
    }`;
export const useSaveCompetition = (variables) => useMutation(ADD_COMPETITION, {refetchQueries: [{ query: GET_COMPETITIONS, variables: variables }]});

const DELETE_COMPETITION = gql`
    mutation DeleteCompetition($input: DeleteInput!) {
      deleteChampionship(input:$input) 
    }`;
export const useDeleteCompetition = (variables) => useMutation(DELETE_COMPETITION, {refetchQueries: [{ query: GET_COMPETITIONS, variables: variables }]});

const GET_VENUES = gql`
    query GetVenues($clubId: String!, $take: Int, $skip: Int) {
    venues(clubId: $clubId, take: $take, skip: $skip) {
        totalCount
        items {
            venueId
            name
            belongsToClub
            showOnTrainings
            showOnGames
        }
    }
}
`;
export const useVenues = (variables) => useQuery(GET_VENUES, {variables: variables});

const ADD_VENUE = gql`
    mutation SaveVenue($input:VenueInput!) {
      saveVenue(input:$input) 
    }`;
export const useSaveVenue = (variables) => useMutation(ADD_VENUE, {refetchQueries: [{ query: GET_VENUES, variables: variables }]});

const DELETE_VENUE = gql`
    mutation DeleteVenue($input: DeleteInput!) {
      deleteVenue(input:$input) 
    }`;
export const useDeleteVenue = (variables) => useMutation(DELETE_VENUE, {refetchQueries: [{ query: GET_VENUES, variables: variables }]});


export const GET_GAME_ATTENDANCE = gql`
    query GetGamesAttendance($clubId: String!, $teamId: Uuid!, $where: GameTypeFilterInput) {
        games(clubId: $clubId, teamId: $teamId, where: $where) {
            items {
                gameId
                date
                name
                ownerTeamName
                otherTeamName
                players {
                    playerId
                    playerName
                    teamNumber
                    status
                    rating
                }
            }
        }

        players(clubId:$clubId, where: {teams: {some: {team: {teamId: {eq: $teamId}}}}}, skip:0, take:100) {
                totalCount
                items {
                    playerId
                    fullName
                    dateOfBirth
                    licenceNr
                    healthCheckNextDate
                    teams {
                        teamMemberId
                        teamNumber
                        group
                        position
                    }
                }
            }
    }`;
export const useGamesAttendance = () => useLazyQuery(GET_GAME_ATTENDANCE);

export const GET_TEAM_ATTENDANCE_STATISTIC = gql`
    query TeamAttendanceStatistic($clubId: String!, $teamId: Uuid!, $statisticType:TeamStatisticFlag!, $from: DateTime! $till: DateTime!) {
        teamAttendanceStatistic(teamId: $teamId, statisticType:$statisticType, from: $from, till: $till) {
            playerId
            present
            wasLate
            ill
            excused
            unexcused
        }

        players(clubId:$clubId, where: {teams: {some: {team: {teamId: {eq: $teamId}}}}}, skip:0, take:100) {
            totalCount
            items {
                playerId
                fullName
                dateOfBirth
                licenceNr
                healthCheckNextDate
                teams {
                    teamMemberId
                    teamNumber
                    group
                    position
                }
            }
        }
    }`;
export const useTeamAttendanceStatistic = () => useLazyQuery(GET_TEAM_ATTENDANCE_STATISTIC);



const GET_VIDEO_TTD_EVENTS = gql`
    query GetVideoTtdEvents($clubId: String) {
        dataEventCategories(clubId:$clubId) {
            id
            name
            isSystem
            order
        }
        
        dataEvents(clubId:$clubId)  {
            id
            categoryId
            parentId
            order
            name
            teamId
            isVideo
            isTtp
            isSystem
            haveSwitch
            forAllTeams
        }
    }
`;
export const useVideoTtdEvents = (variables) => useQuery(GET_VIDEO_TTD_EVENTS, {variables: variables});

const GET_VIDEOS = gql`
    query GetVideos (
        $clubId: String!
        $teamId: Uuid!
        $type: VideoScopeFlag!
        $where: GameVideoTypeFilterInput
        $skip: Int!
    ) {
        videos(
            input: { clubId: $clubId, teamId: $teamId, type: $type }
            where: $where
            skip: $skip
        ) {
            totalCount
            items {
                id: videoId
                title
                link
                image
                scope
            }
        }
    }
`;
export const useVideosLazy = () => useLazyQuery(GET_VIDEOS);

const GET_HIGHLIGHTS = gql`
    query GetHighlights(
        $clubId: String!
        $teamId: Uuid!
        $type: VideoScopeFlag!
        $where: GameDataEventRecordTypeFilterInput
        $skip: Int!
    ) {
        players(
            clubId: $clubId
            where: { teams: { some: { teamId: { eq: $teamId } } } }
            take: 100
        ) {
            items {
                playerId
                fullName
            }
        }

        dataEvents(clubId:$clubId)  {
            id
            categoryId
            parentId
            order
            name
            teamId
            isVideo
            isTtp
            isSystem
            haveSwitch
            forAllTeams
        }

        highlights(
            input: { clubId: $clubId, teamId: $teamId, type: $type }
            where: $where
            skip: $skip
            take: 20
        ) {
            totalCount
            items {
                id
                title
                playerName
                videoId
                link
                image
                time
            }
        }
    }
`;
export const useHighlightsLazy = () => useLazyQuery(GET_HIGHLIGHTS);


const GET_HIGHLIGHTS_ALL = gql`
    query GetHighlights(
        $clubId: String!
        $teamId: Uuid!
        $type: VideoScopeFlag!
    ) {
        players(
            clubId: $clubId
            where: { teams: { some: { teamId: { eq: $teamId } } } }
            take: 100
        ) {
            items {
                playerId
                fullName
                teams(where: {teamId: {eq: $teamId}}) {
                    teamNumber
                }
            }
        }

        dataEvents(clubId:$clubId)  {
            id
            categoryId
            parentId
            order
            name
            teamId
            isVideo
            isTtp
            isSystem
            haveSwitch
            forAllTeams
        }
        

        highlightsAll(
            input: { clubId: $clubId, teamId: $teamId, type: $type }
        ) {
            id
            videoId
            title
            playerName
        }
    }
`;
export const useHighlightsAllLazy = () => useLazyQuery(GET_HIGHLIGHTS_ALL);


const SAVE_GAME_DETAILS = gql`
    mutation SaveGameDetails($input:GameDetailsInput!) {
        saveGameDetails(input:$input)
    }`;
export const useSaveGameDetails = () => useMutation(SAVE_GAME_DETAILS);


const ADD_GAME_VIDEO = gql`
    mutation AddGameVideo($input:GameVideoInput!) {
        addGameVideo(input:$input)
    }`;
export const useAddGameVideo = () => useMutation(ADD_GAME_VIDEO);
