import React, {useRef, useState} from 'react';
import Panel from 'components/UI/Panel/Panel';
import Button from 'components/UI/Button/Button';
import {useTranslation} from 'react-i18next';
import DropdownButton from 'components/UI/DropdownButton/DropdownButton';
import {useParams} from 'react-router-dom';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import Toast, {toastMessage} from 'components/UI/Toast/Toast';
import DateTime from 'components/UI/Table/DateTime/DateTime';
import classes from './TrainingDetails.module.scss';
import Subtext from 'components/UI/Table/Subtext/Subtext';
import Table from 'components/UI/Table/Table';
import SwitchComponents from 'components/System/SwitchComponents';
import PlayVideo from '../MatchesDetails/forms/PlayVideo';
import StreamForm from './forms/StreamForm';
import {useAddTrainingVideo, useTrainingGameVideoLink} from 'apollo/queries/TrainingQueries';
import FileUpload2 from 'components/UI/FileUpload/FileUpload2';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';
import CanAccess from 'components/Navigation/CanAccess/CanAccess';

const TrainingDetailsVideo = (props) => {
    const {data, refetch} = props;

    const {t} = useTranslation();
    const {trainingId} = useParams();
    const fileUploadRef = useRef();
    const navState = useReactiveVar(naviDataVar);

    const [selectedRows, setSelectedRows] = useState([]);

    const [modalData, setModalData, initState] = ModalState();

    const [addTrainingGameVideoLink] = useTrainingGameVideoLink();
    const [addTrainingVideo] = useAddTrainingVideo();

    const meta = {
        type: 'MATCH_VIDEO',
    }

    const videoActions = () => (
      <CanAccess users={[SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin]}>
            <DropdownButton type="primary" options={props.options} onClick={onAddFile}>
                <span><i className="icon-Plus-Square"></i> {t('Add video')}</span>
            </DropdownButton>

            {selectedRows.length > 0 && <Button type="secondary" style={{margin: 0}} onClick={onDeleteClick}>
                <span><i className="icon-Plus-Square"></i> {t('Delete')}</span>
            </Button>}
      </CanAccess>
    );

    const onAddFile = (store) => {
        if (store.value === "yoplaydo" && fileUploadRef.current) {
            fileUploadRef.current.onOpen();
        } else {

            const data = {
                ...initState,
                form: "youtube",
                title: t('Add video from streaming service'),
                store: store.value,
                onComplete: onComplete,
                open: true
            };

            setModalData(data);
        }
    };

    const onComplete = (variables) => {
        variables.input.id = trainingId;
        addTrainingGameVideoLink({variables}).then(() => {
            refetch();
        });
        setModalData(initState);
    }

    const onUpload = async (result, meta) => {
        const variables = {
            input : {
                teamId: navState.teamId,
                clubId: navState.clubId,
                trainingVideoId: result.fileId,
                trainingId: trainingId,
                name: meta.name,
                contentType: meta.type,
                size: meta.size,
                type: 0,
            }
        };

        await addTrainingVideo({variables});
        refetch();
    };

    const onDeleteClick = () => {
        selectedRows.forEach(row => {
            const variables = {
                input: {
                    id: row.videoId,
                    clubId: navState.clubId,
                    teamId: navState.teamId,
                }
            };
        });
    }

    const onVideoClick = (data) => {
        const {name, link} = data;

        const _data = {
            ...initState,
            form: "video",
            title: name,
            defaultValues: {
                video: link
            },
            open: true
        };

        setModalData(_data);
    }

    const getStore = (store) => {
        switch (store) {
            case "YOPLAYDO":
                return "YoPlayDo";
            case "YOUTUBE":
                return "YouTube";
            case "FACEBOOK":
                return "Facebook";
            case "VIMEO":
                return "Vimeo";
            case "TWITCH":
                return "Twitch";
            case "STREAMABLE":
                return "Streamable";
            case "WISTIA":
                return "Wistia";
            case "DAILYMOTION":
                return "DailyMotion";
            case "VIDYARD":
                return "Vidyard";
            default:
                return store;
        }
    }

    return (
        <>
            <Panel border={false} action={videoActions} >
                <br/>
            </Panel>

            <CanAccess users={[SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin]}>
                <FileUpload2 ref={fileUploadRef} meta={meta} onUpload={onUpload} mode="video" />
            </CanAccess>

            <br/>

            <Table
                data={data.trainings.items[0].videos}
                access={[SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin]}
                center="true"
                columns={[
                    {
                        Header: t('Uploaded'),
                        accessor: 'createdTime',
                        Cell: cellData => <DateTime data={cellData.value}/>
                    },
                    {
                        Header: t('File'),
                        accessor: 'name',
                        Cell: cellData => <div className={classes.fileName}><span className="icon-videoPlay"></span><span>{cellData.value}</span></div>
                    },
                    {
                        Header: t('Store'),
                        accessor: 'store',
                        Cell: cellData => <div>
                            {getStore(cellData.value)}
                            {cellData.value === "YOPLAYDO" && <Subtext>{cellData.row.original.size}</Subtext>}
                        </div>
                    },
                    {
                        Header: t('User'),
                        accessor: 'userName',
                        //Cell: cellData => <DateTime data={cellData.value}/>
                    }
                ]}
                onSelectClick={(x) => setSelectedRows(x)}
                onRowClick={onVideoClick}
            />

            <ModalWindow2 {...modalData}>
                <SwitchComponents active={modalData.form}>
                    <StreamForm {...modalData} name="youtube" />
                    <PlayVideo {...modalData} name="video" />
                </SwitchComponents>
            </ModalWindow2>


            <Toast />
        </>
    );
};

TrainingDetailsVideo.defaultProps = {
    options: [
        {label: "From YoPlayDo", value: "yoplaydo"},
        {label: "From YouTube", value: "youtube"},
        {label: "From Facebook", value: "facebook"},
        {label: "From Vimeo", value: "vimeo"},
        {label: "From Twitch", value: "twitch"},
        {label: "From Streamable", value: "streamable"},
        {label: "From Wistia", value: "wistia"},
        {label: "From DailyMotion", value: "dailymotion"},
        {label: "From Vidyard", value: "vidyard"},
    ]
};

export default TrainingDetailsVideo;
