import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import {useTranslation} from 'react-i18next';
import {usePlayerAnthropometricHistory} from 'apollo/queries/PlayerQueries';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const AnthropometricChart = (props) => {
    const {t} = useTranslation();

    const {playerId, metricId} = props.defaultValues;

    const {data, loading, error} = usePlayerAnthropometricHistory({
        playerId,
        metricId
    });

    const options = {
        scale: {
            beginAtZero: true,
            min: 0,
            max: Math.max(...data?.playerAnthropometricHistory.map(x => x.value) ?? [1,10]),
        },

        plugins: {
            legend: {
                display: false
            }
        },

    };

    const chartData = {
        labels: data?.playerAnthropometricHistory.map(x => x.label) ?? [],
        datasets: [
            {
                label: 'Dataset',
                data: data?.playerAnthropometricHistory.map(x => x.value) ?? [],
                borderColor: "rgb(255, 99, 132)",
                backgroundColor: "rgba(255, 99, 132, 0.5)"
            }
        ],
    };

    if (loading) return (<div>{t("Loading...")}</div>);
    if (error) return (<div>{t("Error...")}</div>);

    return (
      <Line options={options} data={chartData} />
    );
};

AnthropometricChart.defaultProps = {};

export default AnthropometricChart;
