import React, {useState} from 'react';
import Panel from '../../../components/UI/Panel/Panel';
import Button from '../../../components/UI/Button/Button';
import {useTranslation} from 'react-i18next';
import Subtext from '../../../components/UI/Table/Subtext/Subtext';
import ModalWindow from '../../../components/UI/ModalWindow/ModalWindow';
import EventForm from './forms/EventForm';
import {useDeleteDataEvent} from '../../../apollo/queries/GameQueries';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from '../../../apollo/cashe';
import classes from './MatchesDetailsVideoDataEvents.module.scss';

const MatchesDetailsVideoDataEvents = (props) => {
    const {dataEventCategories:eventCategories, dataEvents:events} = props.data;
    const {t} = useTranslation();

    const [modalData, setModalData] = useState({
        open: false,
        formId: `form-${Math.random()}`,
        title: t('Add event'),
    });

    const navState = useReactiveVar(naviDataVar);
    const [deleteDataEvent] = useDeleteDataEvent();

    const addActivity = () => (
        <Button type="primary" style={{margin: 0}} onClick={onAddEvent}>
            <span><i className="icon-Plus-Square"></i> {t('Add event')}</span>
        </Button>
    );

    const onAddEvent = () => {
        setModalData({...modalData, open: true, title: t('Add event'), defaultValues: null});
    };

    const onEventClick = (data) => {
        setModalData({...modalData, open: true, title: t('Update event'), defaultValues: data});
    };

    const onComplete = () => {
        props.refetch();
        setModalData({...modalData, open: false});
    }

    const onDelete = () => {
        const variables = {
            input: {
                id: modalData.defaultValues?.id,
                clubId: navState.clubId,
                teamId: navState.teamId,
            }
        };

        deleteDataEvent({variables: variables}).then((result) => {
            if (result) props.refetch();
            setModalData({...modalData, open: false});
        });
    }

    const printEvent = (event, subCounter = 0) => {
        const subEvents = events.filter(fe => fe.parentId === event.id);
        if (subEvents) {

            const subs = [];
            for (let i = 0; i < subCounter; i++) { subs.push(">"); }

            return (
                <div key={event.id}>
                    <div onClick={() => onEventClick(event)} className={classes.eventItem}>
                        <div>
                            {subs}
                            {subs && <span>&nbsp;</span>}
                            {event.name}
                            <Subtext>{t('Type')}: {event.isSystem ? t('system') : t('editable')}</Subtext>
                        </div>
                        <div>{event.isVideo ? t('Yes') : t('No')}</div>
                        <div>{event.isTtp ? t('Yes') : t('No')}</div>
                        {/*<div>{event.forAllTeams ? t('Yes') : t('No')}</div>*/}
                        <div>{event.order}</div>
                    </div>
                    {subEvents.map(subEvent => printEvent(subEvent, subCounter + 1))}
                </div>
            );
        } else {
            return (<div key={event.id} onClick={() => onEventClick(event)}  className={classes.eventItem}>
                <div>
                    {event.name}
                    <Subtext>{t('Type')}: {event.isSystem ? t('system') : t('editable')}</Subtext>
                </div>
                <div>{event.isVideo ? t('Yes') : t('No')}</div>
                <div>{event.isTtp ? t('Yes') : t('No')}</div>
                <div>{event.order}</div>
            </div>);
        }
    }

    return (
        <>
            <div onClick={props.onBack} className={[classes.title, classes.link].join(" ")}>
                <i className={["icon-Arrow---Up", classes.backButton, "backArrow"].join(" ")}></i>
                {t("Video categories")}
            </div>

            <Panel border={false} action={addActivity}>
                <br/>
            </Panel>

            {eventCategories.map(category => {
                const eventList = events.filter(e => e.categoryId === category.id && e.parentId === '00000000000000000000000000000000');

                return (
                    <div key={category.id}>
                        <h3>{category.name}</h3>
                        <div className={classes.eventTitles}>
                            <div>{t('Activity')}</div>
                            <div>{t('Video')}</div>
                            <div>{t('TTP')}</div>
                            {/*<div>{t('All teams')}</div>*/}
                            <div>{t('Order')}</div>
                        </div>
                        <div className={classes.eventGroup}>

                            {eventList.length !== 0
                                ? eventList.map(event => printEvent(event))
                                : <div className={classes.empty}>{t("No data to display.")}</div>
                            }
                        </div>
                    </div>
                );
            })}

            {modalData.open && <ModalWindow
                title={modalData.title}
                formSubmitId={modalData.formId}
                onCloseClick={() => setModalData({...modalData, open: false})}
                onDeleteClick={modalData.defaultValues?.canDelete === true ? onDelete : undefined}
            >
                <EventForm formId={modalData.formId} parents={props.data.events} categories={props.data.eventCategories} defaultValues={modalData.defaultValues} onComplete={onComplete} />
            </ModalWindow>}
        </>
    );
};

MatchesDetailsVideoDataEvents.propTypes = {};
MatchesDetailsVideoDataEvents.defaultProps = {
    refetch: () => {},
    data: {
        events: []
    }
};

export default MatchesDetailsVideoDataEvents;
