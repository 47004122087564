import React, {useEffect} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useAuth} from 'react-oidc-context';

const AfterLogin = (props) => {
    const auth = useAuth();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (auth.isAuthenticated) {
            const returnUrl = searchParams.get('returnUrl');

            if (returnUrl) {
                navigate(returnUrl);
            }

            navigate("/select-profile");
        }
    }, [auth]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            Please wait...
        </>
    );
};

AfterLogin.defaultProps = {};

export default AfterLogin;
