import React from 'react';
import {useTranslation} from 'react-i18next';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import {useDeletePlayerHealthConditions, usePlayerHealthConditionHistory} from 'apollo/queries/PlayerQueries';
import Table from 'components/UI/Table/Table';
import DateTime from 'components/UI/Table/DateTime/DateTime';
import classes from './HealthConditionView.module.scss';
import HealthConditionForm from 'containers/Club/ClubMedicine/form/HealthConditionForm';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import Submenu from 'components/Navigation/Submenu/Submenu';
import BodyMap from 'components/UI/BodyMap/BodyMap';

const HealthConditionView = (props) => {
    const {playerId, fullName, gender} = props.defaultValues

    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const [page, setPage] = React.useState(1);

    const [modalData, setModalData, initState] = ModalState();

    const {data, loading, error, refetch} = usePlayerHealthConditionHistory({playerId});
    const [deletePlayerHealthConditions] = useDeletePlayerHealthConditions();

    const onRowClick = (row) => {
        const modalData = {
            ...initState,
            form: 'healthCondition',
            title: t('Edit health condition'),
            styles: {
              maxWidth: '700px',
            },
            defaultValues: {
              playerId,
              fullName,
              playerHealthConditionId: row.playerHealthConditionId,
              date: row.date,
              healthCondition: row.healthCondition,
              severityOfInjury: row.severityOfInjury,
              positionX: row.positionX,
              positionY: row.positionY,
              notes: row.notes,
            },
            onComplete: onComplete,
            onDelete: () => onDelete(row.playerHealthConditionId),
            open: true,
        };

        setModalData(modalData);
    }

    const onComplete = () => {
      setModalData(initState);
      refetch().then();
    }

    const onDelete = (playerHealthConditionId) => {
      const variables = {
        input: {
          id: playerHealthConditionId,
          playerId,
          clubId: navState.clubId
        }
      };

      deletePlayerHealthConditions({variables}).then(() => {
        setModalData(initState);
        refetch().then();
      });
    }

    const onPageChanged = (data) => {
      setPage(+data);
    }

    if (loading) return (<div>Loading...</div>);
    if (error) return (<div>Error...</div>);

    return (
      <>
        <Submenu default={page} data={props.pageNames} onFilter={onPageChanged} className={classes.submenu} />

        {page === 1 ? (
          <Table
            data={data.playerHealthConditionHistory}
            columns={[
                {
                    Header: t('Date'),
                    accessor: 'date',
                    Cell: (cellData) => {
                        return (
                          <DateTime data={cellData.value} showTime={false} />
                        );
                    }
                },
                {
                    Header: 'Status',
                    accessor: 'healthCondition',
                    className: classes.status,
                    Cell: (cellData) => {
                        return (
                          cellData.value.toLowerCase()
                        );
                    }
                },
                {
                    Header: t('Comment'),
                    className: classes.hide,
                    accessor: 'notes',
                },
            ]}
            onRowClick={onRowClick}/>
          ) : (
            <BodyMap
              gender={gender}
              points={data.playerHealthConditionHistory.filter(x => x.healthCondition === "INJURY").map(x => ({
                id: x.playerHealthConditionId,
                x: x.positionX,
                y: x.positionY,
              })) ?? []}
              readOnly={true}
            />
          )}

          <ModalWindow2 {...modalData}>
              <HealthConditionForm
                {...modalData}
                name="healthCondition"
              />
          </ModalWindow2>
      </>
    );
};

HealthConditionView.defaultProps = {
  pageNames: {
    1: "Data",
    2: "Chart",
  },
};

export default HealthConditionView;
