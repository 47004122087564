import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import classes from './BasicExerciseView.module.scss';
import Input from 'components/UI/Table/Input/Input';
import {validateFields} from 'utils/form';

const BasicTestView = (props) => {
    const {defaultData, players, onChange} = props;
    const {image, scheme, name, unitOfMeasure, systemOfMeasurement, attempts, rhythm, coefficient, level, description, tools} = defaultData;

    const {t} = useTranslation();

    const [values, setValues] = useState(defaultData);
    const fieldChanged = (event) => {
        const newValues = {...values};
        newValues[event.target.name] = event.target.value;
        setValues(newValues);
        onChange(newValues);
    };

    const imageSrc = () => {
        return image ? image : scheme?.image ? scheme?.image : "/assets/images/exercises/no-image.jpg";
    }

    return (
        <div>
            <div className={classes.basicView}>
                <div className={classes.left}>
                    <img className={classes.image} src={imageSrc()}
                         style={!image ? {backgroundImage: `url(${scheme?.background})`}: undefined} alt=""/>
                </div>
                <div className={classes.right}>
                    <h4>{name}</h4>
                    <div className={classes.info}>
                        <div>
                            <span>{t("Unit")}</span>: {unitOfMeasure ? t(unitOfMeasure) : "-"}
                        </div>
                        <div>
                            <span>{t("System")}</span>: {systemOfMeasurement ? t(systemOfMeasurement) : "-"}
                        </div>
                        <div>
                            <span>{t("Attempts")}</span>: {attempts ? attempts : "-"}
                        </div>
                    </div>

                    {description ? <div>
                        <h5>{t('Description')}</h5>
                        {description ?? t("No description.")}
                    </div> : undefined }

                    <h5>{t('Results')}</h5>
                    <div className={classes.results}>
                        {players.map(x => (
                          <div key={x.playerId} style={{gridTemplateColumns: `150px repeat(${attempts}, 80px)`}}>
                              <span>{x.playerName}</span>
                              {[...Array(+attempts)].map((a,i) => {
                                  const attemptName = `result_${x.playerId}_${i}`;
                                  return (
                                    <Input
                                      key={attemptName}
                                      name={attemptName}
                                      value={values[attemptName]}
                                      onBlur={fieldChanged}
                                    />
                                  )
                              })}
                          </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

BasicTestView.defaultProps = {};

export default BasicTestView;
