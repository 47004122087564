
export const findChildrenInObject = (obj, id) => {
    if (obj.id === id)
        return obj;

    if (!obj.children)
        return;

    let result;

    for (let i = 0 ; i < obj.children.length; i++) {
        const item = obj.children[i];
        if (item.id === id) {
            result = item;
            break;
        } else {
            result = findChildrenInObject(item, id);
            if (result) break;
        }
    }

    return result;
}

export const findParentInObject = (obj, id) => {
    if (obj.id === id || !obj.children)
        return;

    let result;

    for (let i = 0 ; i < obj.children.length; i++) {
        const item = obj.children[i];
        if (item.id === id) {
            result = obj;
            break;
        } else {
            result = findParentInObject(item, id);
            if (result) break;
        }
    }

    return result;
}

export const mergeArray = (a, b, p) => a.filter(aa => !b.find(bb => aa[p] === bb[p])).concat(b);

export const labelValueArrayToObject = (arr) => {
   return arr.reduce((a, v) => ({ ...a, [v.value]: v.label}), {});
}


export const newId = () => (
    Math.random().toString(36).substr(2, 5)
);

export const objectToLabelValueArray = (obj) => {
    const result = [];

    for (const [key, value] of Object.entries(obj)) {
        result.push({label: key, value: value});
    }

    return result;
}

export function isObjectEmpty(obj) {
    return (
        Object.keys(obj).length === 0 &&
        Object.getOwnPropertySymbols(obj).length === 0 &&
        obj.constructor === Object
    );
}

export const stripTrailingSlash = (str) => {
    if (str.length === 0)
        return str;

    if(str.substr(-1) === '/')
        return str.substr(0, str.length - 1);

    return str;
}

export const createGuid = () => {
    function S4() {
        return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
    }
    return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0,3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();
}


export const UuidTOGuid = (str) => {
    const regexValue = /(\w{8})(\w{4})(\w{4})(\w{4})(\w{12})/;
    return str.replace(regexValue, '$1-$2-$3-$4-$5');
}

export const GuidTOUuid = (str) => {
    return str ? str.toLowerCase().replaceAll("-", "") : "";
}

export const isEmpty = (str) => {
    if (str === null || str === undefined) return null;
    return str.length > 0 ? str : null;
}

export const isNotEmpty = (str) => {
    if (str === null || str === undefined) return false;
    return str.length > 0;
}

export const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
}

export const TextAnnouncement = (str) => {
    if (!str) return str;
    const maxSymbols = 200;
    return str.length > maxSymbols ? str.substr(0, maxSymbols) + "..." : str;
}

export const screenWidth = () => {
    return window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth
        || 0;
}

export const deepMergeFlatten = (obj1, obj2) => {
    const keys = Object.keys(obj2)
    let nextObj = { ...obj1 }

    for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i]
        const value = obj2[key]

        if (key === '__typename') {
            continue;
        }

        if (typeof value === "object" && value !== null && Array.isArray(value) === false) {
            nextObj = { ...nextObj, [key]:deepMergeFlatten(nextObj[key], value) }
        } else if (value !== null) {
            nextObj = { ...nextObj, [key]: value }
        }
    }

    return nextObj
}

export const getObjectIndexByKeyInArray = (_array,  _key) => {
    for (const obj of _array) {
        if (_key in obj) {
            return obj;
        }
    }

    return null;
}
