import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import {usePlayer} from 'apollo/queries/PlayerQueries';
import Submenu from 'components/Navigation/Submenu/Submenu';
import classes from './Player.module.scss';
import {PlayerPositions} from 'containers/Team/MatchesDetails/Settings';
import Group from 'components/UI/Group/Group';
import PlayerHeader from './PlayerHeader';
import PlayerChart from './chart/PlayerChart';
import {useParams} from 'react-router-dom';
import AnthropometryHealth from 'containers/Player/Player/AnthropometryHealth';

const Player = () => {
    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);
    const {clubId, playerId} = useParams();

    const {loading, error, data, refetch} = usePlayer({
        clubId: navState.clubId ?? clubId,
        playerId: navState.playerId ?? playerId,
    });

    const Chart = ({data}) => {
        const _data = {
            labels: data.skills.map(x => x.name),
            data: data.skills.map(x => x.summary),
        }

        return (
            <Group title={t("Attributes")}>
                <div className={classes.chart}>
                    <PlayerChart data={_data} />
                </div>
            </Group>
        );
    }

    const Teams = ({data}) => {
        const {teams} = data;
        return (
            <Group title="Teams">
                {teams.map(teamData => {
                    const {teamNumber, position, group, team} = teamData;
                    const playerPosition = PlayerPositions().find(x => x.value === position);
                    const coaches = team.trainers.map(x => x.fullName).join(', ');
                    return (
                        <ul key={`${team.name}-${teamNumber}`}>
                            <li><span className={classes.groupTitle}>{team.name}</span></li>
                            {group ? <li className={classes.column12}><span>{t("Group")}:</span> <span>{group}</span></li> : undefined }
                            <li className={classes.column12}><span>{t("Team number")}:</span> <span>{teamNumber}</span></li>
                            {playerPosition ? <li className={classes.column12}><span>{t("Position")}:</span> <span>{playerPosition.label}</span></li> : undefined }
                            <li className={classes.column12}><span>{t("Coaches")}:</span> <span>{coaches}</span></li>
                        </ul>
                    );
                })}
            </Group>
        );
    }

    const Contacts = ({data}) => {
        const {phoneNumber, email, addressLine, parents} = data;

        return (
            <Group title="Contacts">
                <ul>
                    <li className={classes.column12}><span>{t("Phone")}:</span> {phoneNumber ? <a href={`phone:${phoneNumber}`}>{phoneNumber}</a> : undefined }</li>
                    <li className={classes.column12}><span>{t("E-mail")}:</span> {email ? <a href={`phone:${email}`}>{email}</a> : undefined }</li>
                    <li className={classes.column12}><span>{t("Address")}:</span> <span>{addressLine ?? "-"}</span></li>
                </ul>

                {parents.map(parent => {
                    const {relationType, fullName, phoneNumber} = parent;
                    return (
                        <ul key={`${relationType}-${fullName}`}>
                            <li className={classes.column12}><span>{t(relationType)}:</span> <span>{fullName}</span></li>
                            <li className={classes.column12}><span>{t("Phone")}:</span> {phoneNumber ? <a href={`phone:${phoneNumber}`}>{phoneNumber}</a> : undefined }</li>
                            <li className={classes.column12}><span>{t("E-mail")}:</span> {email ? <a href={`phone:${email}`}>{email}</a> : undefined }</li>
                        </ul>
                    );
                })}
            </Group>
        );
    }

    if (loading) return (<div>Loading...</div>);
    if (error) return (<div>Error...</div>);

    return (
        <>
            <div className="page-header">
                <PlayerHeader />
                <Submenu/>
            </div>
            <div className="page-content">
                <div className={classes.list}>
                    <Chart data={data.players.items[0]} />
                    <AnthropometryHealth data={data.players.items[0]} refetch={refetch} />
                    <Teams data={data.players.items[0]} />
                    <Contacts data={data.players.items[0]} />
                </div>
            </div>
        </>
    );
};

Player.defaultProps = {
    teamFilter: [
        {label: "All teams", value: "all"}
    ]
};

export default Player;
