import React from 'react';

export class Printer extends React.PureComponent {
    render() {
        return (
            <>
                {this.props.children}
            </>
        );
    }
}
