import React from 'react';
import classes from './ChartTable.module.scss';
import Button from 'components/UI/Button/Button';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import ScoreSkillsForm from 'containers/Player/PlayerSkills/forms/ScoreSkillsForm';
import {useTranslation} from 'react-i18next';
import SwitchComponents from 'components/System/SwitchComponents';
import SkillStat from 'containers/Player/PlayerSkills/forms/SkillStat';
import ViewSkillsStat from 'containers/Player/PlayerSkills/forms/ViewSkillsStat';
import {timestampAsDate} from 'utils/date';
import CanAccess from 'components/Navigation/CanAccess/CanAccess';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';


const ChartTable = (props) => {
    const {data, title} = props;

    const {t} = useTranslation();

    const [modalData, setModalData, initState] = ModalState();

    const onScore = () => {
        const _data = {
            ...initState,
            form: "scoreSkill",
            title: t("Score skills"),
            onComplete: onComplete,
            skills: data,
            open: true
        }

        setModalData(_data);
    };

    const onView = () => {
        const datasets = [];
        data.forEach((skill) => {
            skill.values.forEach((x, index) => {
                if (datasets[index] === undefined) {
                    datasets.push({
                        data: [],
                        date: x.date,
                        backgroundColor: 'rgba(255, 99, 132, 0.2)',
                        borderColor: 'rgba(255, 99, 132, 1)',
                        borderWidth: 1,
                    });
                }
                datasets[index]["data"].push(x.value);
            });
        });

        const _values = {
            labels: data.map(x =>x.name),
            datasets: datasets,
        }

        const _data = {
            ...initState,
            form: "viewSkill",
            title: t(title),
            buttons: {
                hideSaveButton: true,
            },
            onComplete: onComplete,
            values: _values,
            open: true
        }

        setModalData(_data);
    };

    const onStat = (skill) => {
        const values = {
            labels: skill.values.map(x => timestampAsDate(x.date)),
            data: skill.values.map(x => x.value),
        }

        const _data = {
            ...initState,
            form: "skillStat",
            title: skill.isSystem ? t(skill.name) : skill.name,
            buttons: {
                hideSaveButton: true,
            },
            onComplete: setModalData(initState),
            values: values,
            open: true
        }

        setModalData(_data);
    };

    const onComplete = () => {
        setModalData(initState);
        props.refetch();
    }

    const analyze = (skill) => {
        if (skill.prevValue < skill.lastValue) {
            return <i className={classes.up}>&#129045;</i>;
        }

        if (skill.prevValue > skill.lastValue) {
            return <i className={classes.down}>&#129047;</i>;
        }

        return <i></i>;
    }

    return (
        <div>
            {data.map(x => (
                <div key={`row-${x.skillId}`} className={classes.row}>
                    <span>{x.isSystem ? t(x.name) : x.name}</span>
                    <span>{analyze(x)} {x.lastValue}</span>
                    <i onClick={() => onStat(x)} className="icon-Chart"></i>
                </div>
            ))}

            <div className={classes.edit}>
                <CanAccess users={[SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin]}>
                    <Button type="second" onClick={onScore}>{t("Score skills")}</Button>
                </CanAccess>
                {data[0]?.values.length > 0 ? <Button type="second" onClick={onView}>{t("View stat")}</Button> : undefined}
            </div>

            <ModalWindow2 {...modalData}>
                <SwitchComponents active={modalData.form}>
                    <SkillStat {...modalData} name="skillStat" />
                    <ScoreSkillsForm {...modalData} name="scoreSkill" />
                    <ViewSkillsStat {...modalData} name="viewSkill" />
                </SwitchComponents>
            </ModalWindow2>
        </div>
    );
};

ChartTable.defaultProps = {};

export default ChartTable;
