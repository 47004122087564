import React from 'react';
import {Chart as ChartJS, CategoryScale, PointElement, LinearScale, BarElement, Title, Tooltip, Legend} from 'chart.js';
import { Bubble } from 'react-chartjs-2';
import {useTranslation} from 'react-i18next';

ChartJS.register(
    CategoryScale,
    PointElement,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const GoalChart = (props) => {
    const {t} = useTranslation();
    const {xMin, xMax, yMin, yMax, data: jsonData} = props.data;
    const chartData = JSON.parse(jsonData);

    const options = {
        scales: {
            x: {
                suggestedMin: xMin,
                suggestedMax: xMax
            },
            y: {
                beginAtZero: true,
                suggestedMin: yMin,
                suggestedMax: yMax
            }
        },
        plugins: {
            title: {
                display: true,
                text: t('Goal statistics'),
            },
            tooltip: {
                callbacks: {
                    label: function(context) {
                        let label = context.dataset.label || '';

                        if (label) {
                            label += ': ';
                        }

                        if (context.parsed.y !== null) {
                            label += context.parsed.y
                        }
                        return label;
                    }
                }
            },
            legend: {
                display: true,
                position: "bottom",
            }
        }
    };

     const data = {
        datasets: [
            {
                label: t('Goals'),
                data: chartData[0],
                backgroundColor: "rgba(255, 99, 132, 0.5)"
            },
            {
                label: t('Goal conceded'),
                data: chartData[1],
                backgroundColor: "rgba(53, 162, 235, 0.5)"
            }
        ]
    };

    return (
        <Bubble options={options} data={data} />
    );
};

GoalChart.defaultProps = {};

export default GoalChart;
