import React from 'react';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';
import CanAccess from 'components/Navigation/CanAccess/CanAccess';
import {useTranslation} from 'react-i18next';
import classes from './UploadTeamForm.module.scss';
import FileUpload from 'components/UI/FileUpload/FileUpload';
import {toastMessage} from 'components/UI/Toast/Toast';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import {useValidateUploadTeam} from 'apollo/queries/FileQueries';

const UploadTeamForm = (props) => {
	const {setLoading, onComplete} = props;
	const {t} = useTranslation();
	const navState = useReactiveVar(naviDataVar);

	const [validateUploadTeam] = useValidateUploadTeam();
	const onUpload = (files) => {
		setLoading(true);

		const variables = {
			file: files[0],
			clubId: navState.clubId,
			teamId: navState.teamId,
		};

		validateUploadTeam({variables: variables}).then(({data}) => {
			onComplete(data.validateUploadTeam);
			// if (data.validateUploadTeam !== true) {
			// 	toastMessage(false, t("Something going wrong, file not load."));
			// } else {
			// 	onComplete();
			// }
		}).catch((e) => {
			toastMessage(false, t("Something going wrong, file not load."));
		});

		setLoading(false);
	};

  return (
      <div>
				<ul className={classes.list}>
					<li>{t('Complete this file - ')} <a href="/assets/files/upload-players.xlsx">{t('download')}</a>.</li>
					<li>{t('Upload completed file back.')}</li>
				</ul>
        <CanAccess users={[SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin]}>
	        <FileUpload onUpload={(files) => onUpload(files)} mode="xls"/>
        </CanAccess>
	      <br />
      </div>
  );
};

UploadTeamForm.defaultProps = {};

export default UploadTeamForm;
