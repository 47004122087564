import React, {useEffect, useState} from 'react';
import Table from 'components/UI/Table/Table';
import Dropdown from 'components/UI/Table/DropDown/Dropdown';
import classes from './MatchesDetails.module.scss';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import SwitchComponents from 'components/System/SwitchComponents';
import MinutesForm from './forms/MinutesForm';
import GoalForm from './forms/GoalForm';
import ListForm from './forms/ListForm';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import {useDeleteDataEventRecord, useUpdateGamePlayer} from 'apollo/queries/GameQueries';
import {inThisWeek, showNumberAsTime, toMSS} from 'utils/date';
import CardForm from './forms/CardForm';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import {loadLegacyScripts} from 'containers/Club/ExerciseLibrary/Settings';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';

const MatchesDetailsPlayers = (props) => {
    const {t} = useTranslation();
    const {gameId} = useParams();
    const navState = useReactiveVar(naviDataVar);

    const [updateGamePlayer] = useUpdateGamePlayer();
    const [deleteDataEventRecord] = useDeleteDataEventRecord();

    const [modalData, setModalData, initState] = ModalState();

    useEffect(() => {
        loadLegacyScripts();
    }, []);

    const sortTeam = (a, b) => {

        if (!a.substitute && b.substitute) {
            return -1;
        }

        if (a.substitute && !b.substitute) {
            return 1;
        }

        return 0;
    }

    const onAttendanceChange = (data, event) => {
        const {playerId} = data;
        const attendance = event.target.value;

        const variables = {
            input: {
                clubId: navState.clubId,
                teamId: navState.teamId,
                gameId: gameId,
                playerId: playerId,
                attendance: attendance,
            }
        }

        updateGamePlayer({variables: variables}).then(result => {
            props.refetch();
        });
    }

    const onRatingChange = (data, event) => {
        const {playerId} = data;
        const rating = event.target.value;

        const variables = {
            input: {
                clubId: navState.clubId,
                teamId: navState.teamId,
                gameId: gameId,
                playerId: playerId,
                rating: rating,
            }
        }

        updateGamePlayer({variables: variables}).then(result => {
            props.refetch();
        });
    }

    const onRowCheck = (data) => {
        if (data.substitute) {
            return {className:"reserve"};
        }

        return {className:"mainTeam"};
    }

    const getEventId = (cellId) => {
        switch (cellId) {
            case "playTime":
                return {
                    add: 'playTime',
                    addTitle: 'Set play time',
                    editTitle: 'Set play time',
                }
                break;
            case "goals":
                return {
                    id: 'b74d00928cc34c7ca7b5d9af357da03f',
                    add: 'goals',
                    addTitle: 'Add goal',
                    editTitle: 'Edit goal',
                    list: 'goalsList',
                    listTitle: 'Goals list',
                }
                break;
            case "goalConceded":
                return {
                    id: 'd4907f981d674e158b45ce851b4aeb83',
                    add: 'goalConceded',
                    addTitle: 'Add goal conceded',
                    editTitle: 'Edit goal conceded',
                    list: 'goalConcededList',
                    listTitle: 'Goal conceded list',
                }
                break;
            case "saves":
                return {
                    id: 'd5fd2df901924013ad0df90830edd0fa',
                    add: 'saves',
                    addTitle: 'Add saves',
                    editTitle: 'Edit saves',
                    list: 'savesList',
                    listTitle: 'Saves list',
                }
                break;
            case "pens":
            case "penaltyFaced":
            case "penaltySaved":
                return {
                    id: 'ca8e439ba3a044c9ae024868eda919b9',
                    add: 'pens',
                    addTitle: 'Add penalty',
                    editTitle: 'Edit penalty',
                    list: 'pensList',
                    listTitle: 'Penalty list',
                }
                break;
            case "yellowCards":
                return {
                    id: '49ab55cb11bb4ced8faa8db9450164e4',
                    add: 'yellowCard',
                    addTitle: 'Add yellow card',
                    editTitle: 'Edit yellow card',
                    list: 'yellowCardList',
                    listTitle: 'Yellow cards list',
                }
                break;
            case "redCards":
                return {
                    id: 'd3585b9303744b5b9902f197df372f4d',
                    add: 'redCard',
                    addTitle: 'Add red card',
                    editTitle: 'Edit red card',
                    list: 'redCardList',
                    listTitle: 'Red cards list',
                }
                break;
            case "asts":
                return {
                    id: 'd9a7e14c93544231a19e84f099990c4c',
                    add: 'goals',
                    addTitle: 'Add goal',
                    editTitle: 'Edit goal',
                    list: 'astsList',
                    listTitle: 'Asts list',
                }
                break;
        }
    }

    const onClick = ({cellData, values = null, formData = null, list = false}) => {
        const {playerId} = cellData.row.original;
        const cellId = cellData.cell.column.id;
        const eventData = getEventId(cellId);
        const event = props.data.events.find(f => f.id === eventData.id);

        const modal = {
            ...initState,
            title: values === null ? eventData.addTitle : eventData.editTitle,
            form: eventData.add,
            access: [SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
            playerId: playerId,
            gameId: gameId,
            eventId: eventData.id,
            event: event,
            values: values,
            formData: formData,
            onComplete: onCloseModal,
            open: true
        };

        if (values?.id !== undefined) {
            modal.onDelete = () => onDeleteEvent(values.id);
        }

        if (list) {
            modal.form = eventData.list;
            modal.title = eventData.listTitle;
            modal.data = props.data.eventRecordsWithoutVideo.filter(f => f.gameDataEventId === eventData.id && f.playerId === playerId);
            modal.cellData = cellData;
            modal.onComplete = onClick;
            modal.closeButton = true;
        }

        setModalData(modal);
    }

    const onCloseModal = () => {
        setModalData(initState);
        props.refetch();
    }

    const onDeleteEvent = (id) => {
        const variables = {
            input: {
                id: id,
                clubId: navState.clubId,
                teamId: navState.teamId,
            }
        };

        deleteDataEventRecord({variables: variables}).then(result => {
            setModalData(initState);
            props.refetch();
        });
    }

    const rowClassName = (row) => {
        return row.substitute === false ? classes.mainTeam : undefined;
    }

    return (
        <>
            <h3>{t('Players')}</h3>

            <Table
                className={classes.playerTable}
                data={props.data.players.filter(x => x.goalkeeper === false).sort(sortTeam)}
                onRowCheck={onRowCheck}
                access={[SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin]}
                columns={[
                    {
                        Header: t('Player'),
                        accessor: 'playerName',
                        Cell: (cellData) => <>{cellData.value} ({cellData.row.original.teamNumber})</>
                    },
                    {
                        Header: t('Attendance'),
                        accessor: 'status',
                        Cell: (cellData) => <Dropdown name="attendance" mode="iconWithoutText" access={cellData.cell.access}
                                                      onChange={e => onAttendanceChange(cellData.row.original, e)}
                                                      options={props.attendance} value={cellData.value} />
                    },
                    {
                        Header: t('MP'),
                        accessor: 'playTime',
                        Cell: (cellData) =>
                            <div className={classes.editors} onClick={() =>
                                onClick({cellData, values: {playTime: cellData.value}})}>
                                {cellData.value ? <span>{showNumberAsTime(cellData.value)}</span> : <span>-</span> }
                            </div>
                    },
                    {
                        Header: t('G'),
                        accessor: 'goals',
                        Cell: (cellData) =>
                            <div className={classes.editors}>
                                {cellData.value ? <span onClick={() =>
                                    onClick({cellData, formData: props.data, list: true})}>{cellData.value}</span> : <span>-</span> }
                                <i onClick={() => onClick({cellData, formData: props.data})} className="icon-Plus-Square icon-blue"></i>
                            </div>
                    },
                    {
                        Header: t('P'),
                        accessor: 'pens',
                        Cell: (cellData) =>
                            <div className={classes.editors}>
                                {cellData.value ? <span onClick={() => onClick({cellData, list: true})}>{cellData.value}</span> : <span>-</span> }
                                <i onClick={() => onClick({cellData})} className="icon-Plus-Square icon-blue"></i>
                            </div>
                    },
                    {
                        Header: t('A'),
                        accessor: 'asts',
                        Cell: (cellData) => <div className={classes.editors}>
                            {cellData.value ? <span onClick={() =>
                                onClick({cellData, formData: props.data, list: true})}>{cellData.value}</span> : <span>-</span> }
                        </div>
                    },
                    {
                        Header: t('YC'),
                        accessor: 'yellowCards',
                        Cell: (cellData) =>
                            <div className={classes.editors}>
                                {cellData.value ? <span onClick={() => onClick({cellData, list: true})}>{cellData.value}</span> : <span>-</span> }
                                <i onClick={() => onClick({cellData})} className="icon-Plus-Square icon-blue"></i>
                            </div>
                    },
                    {
                        Header: t('RC'),
                        accessor: 'redCards',
                        Cell: (cellData) =>
                            <div className={classes.editors}>
                                {cellData.value ? <span onClick={() => onClick({cellData, list: true})}>{cellData.value}</span> : <span>-</span> }
                                <i onClick={() => onClick({cellData})} className="icon-Plus-Square icon-blue"></i>
                            </div>
                    },
                    {
                        Header: t('PPR'),
                        accessor: 'rating',
                        Cell: (cellData) => <div className={classes.rating}><Dropdown name="rating" options={props.rating}
                                                      onChange={e => onRatingChange(cellData.row.original, e)}
                                                      access={cellData.cell.access} value={cellData.value}/></div>
                    },
                ]}
                onRowClick={() => {}}
                rowClassName={rowClassName}
            />

            <h3>{t('Goalkeepers')}</h3>

            <Table
                data={props.data.players.filter(x => x.goalkeeper === true).sort(sortTeam)}
                access={[SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin]}
                columns={[
                    {
                        Header: t('Player'),
                        accessor: 'playerName',
                        Cell: (cellData) => <>{cellData.value} ({cellData.row.original.teamNumber})</>
                    },
                    {
                        Header: t('Attendance'),
                        accessor: 'status',
                        Cell: (cellData) => <Dropdown name="attendance" mode="iconWithoutText"  access={cellData.cell.access}
                                                      onChange={e => onAttendanceChange(cellData.row.original, e)}
                                                      options={props.attendance} value={cellData.value} />
                    },
                    {
                        Header: t('MP'),
                        accessor: 'playTime',
                        Cell: (cellData) =>
                            <div className={classes.editors} onClick={() => onClick({cellData, values: {playTime: cellData.value}})}>
                                {cellData.value ? <span>{toMSS(cellData.value)}</span> : <span>-</span> }
                            </div>
                    },
                    {
                        Header: t('G'),
                        accessor: 'goals',
                        Cell: (cellData) =>
                            <div className={classes.editors}>
                                {cellData.value ? <span onClick={() =>
                                    onClick({cellData, formData: props.data, list: true})}>{cellData.value}</span> : <span>-</span> }
                                <i onClick={() => onClick({cellData, formData: props.data})} className="icon-Plus-Square icon-blue"></i>
                            </div>
                    },
                    {
                        Header: t('GC'),
                        accessor: 'goalConceded',
                        Cell: (cellData) =>
                            <div className={classes.editors}>
                                {cellData.value ? <span onClick={() => onClick({cellData, list: true})}>{cellData.value}</span> : <span>-</span> }
                                <i onClick={() => onClick({cellData})} className="icon-Plus-Square icon-blue"></i>
                            </div>
                    },
                    {
                        Header: t('S'),
                        accessor: 'saves',
                        Cell: (cellData) =>
                            <div className={classes.editors}>
                                {cellData.value ? <span onClick={() => onClick({cellData, list: true})}>{cellData.value}</span> : <span>-</span> }
                                <i onClick={() => onClick({cellData})} className="icon-Plus-Square icon-blue"></i>
                            </div>
                    },
                    {
                        Header: t('PF'),
                        accessor: 'penaltyFaced',
                        Cell: (cellData) =>
                            <div className={classes.editors}>
                                {cellData.value ? <span onClick={() => onClick({cellData, list: true})}>{cellData.value}</span> : <span>-</span> }
                                <i onClick={() => onClick({cellData})} className="icon-Plus-Square icon-blue"></i>
                            </div>
                    },
                    {
                        Header: t('PS'),
                        accessor: 'penaltySaved',
                        Cell: (cellData) =>
                            <div className={classes.editors}>
                                {cellData.value ? <span onClick={() => onClick({cellData, list: true})}>{cellData.value}</span> : <span>-</span> }
                                <i onClick={() => onClick({cellData})} className="icon-Plus-Square icon-blue"></i>
                            </div>
                    },
                    {
                        Header: t('YC'),
                        accessor: 'yellowCards',
                        Cell: (cellData) =>
                            <div className={classes.editors}>
                                {cellData.value ? <span onClick={() => onClick({cellData, list: true})}>{cellData.value}</span> : <span>-</span> }
                                <i onClick={() => onClick({cellData})} className="icon-Plus-Square icon-blue"></i>
                            </div>
                    },
                    {
                        Header: t('RC'),
                        accessor: 'redCards',
                        Cell: (cellData) =>
                            <div className={classes.editors}>
                                {cellData.value ? <span onClick={() => onClick({cellData, list: true})}>{cellData.value}</span> : <span>-</span> }
                                <i onClick={() => onClick({cellData})} className="icon-Plus-Square icon-blue"></i>
                            </div>
                    },
                    {
                        Header: t('PPR'),
                        accessor: 'rating',
                        Cell: (cellData) => <div className={classes.rating}><Dropdown name="rating" options={props.rating}
                                                      onChange={e => onRatingChange(cellData.row.original, e)}
                                                      access={cellData.cell.access} value={cellData.value}/></div>
                    },
                ]}
                onRowClick={() => {}}
                rowClassName={rowClassName}
            />

            <div className={classes.legenda}>
                {t("MP - Minutes played; G - Goals scored; P - Penalties scored; A - Assisted; YC - Yellow card; RC - Red card; PPR - Player performance rate; G - Goals; GC - Goals conceded; S - Saves; PF - Penalties faced; PS - Penalties saved;")}
            </div>

            <ModalWindow2 {...modalData}>
                <SwitchComponents active={modalData.form}>
                    <MinutesForm {...modalData} name="playTime"/>
                    <GoalForm {...modalData} name="goals"/>
                    <ListForm {...modalData} name="goalsList"/>
                    <CardForm {...modalData} name="goalConceded"/>
                    <ListForm {...modalData} name="goalConcededList"/>
                    <CardForm {...modalData} name="saves"/>
                    <ListForm {...modalData} name="savesList"/>
                    <CardForm {...modalData} name="pens"/>
                    <ListForm {...modalData} name="pensList"/>
                    <ListForm {...modalData} name="astsList"/>
                    <CardForm {...modalData} name="yellowCard"/>
                    <ListForm {...modalData} name="yellowCardList"/>
                    <CardForm {...modalData} name="redCard"/>
                    <ListForm {...modalData} name="redCardList"/>
                </SwitchComponents>
            </ModalWindow2>
        </>
    );
};

MatchesDetailsPlayers.propTypes = {};
MatchesDetailsPlayers.defaultProps = {
    data: {
        players: []
    },
    attendance: [
        {label: 'Attended', icon: '<i class="icon-Check-Square icon-green"></i>', value: 'PRESENT'},
        {label: 'Was late', icon: '<i class="icon-Time-Square icon-yellow"></i>', value: 'WAS_LATE'},
        {label: 'Sick', icon: '<i class="icon-medicine icon-blue"></i>', value: 'ILL'},
        {label: 'Was absent for legitimate reason +', icon: '<i class="icon-Document icon-blue"></i>', value: 'EXCUSED'},
        {label: 'Not attended', icon: '<i class="icon-Close-Square icon-red"></i>', value: 'UNEXCUSED'},
    ],
    rating: [
        {label: '1 / 10', value: 0},
        {label: '2 / 10', value: 1},
        {label: '3 / 10', value: 1.5},
        {label: '4 / 10', value: 2},
        {label: '5 / 10', value: 2.5},
        {label: '6 / 10', value: 3},
        {label: '7 / 10', value: 3.5},
        {label: '8 / 10', value: 4},
        {label: '9 / 10', value: 4.5},
        {label: '10 / 10', value: 5}
    ],
    refetch: () => {
    },
};

export default MatchesDetailsPlayers;
