import React, {useEffect, useState} from 'react';
import classes from 'containers/Club/TeamList/forms/TeamForm.module.scss';
import Input from 'components/UI/Input/Input';
import Switch from 'components/UI/Switch/Switch';
import ImageUpload from 'components/UI/ImageUpload/ImageUpload';
import {useTranslation} from 'react-i18next';
import {notHaveErrors, validateFields, validateForm} from 'utils/form';
import * as yup from 'yup';
import Textarea from 'components/UI/Textarea/Textarea';
import {useSaveGroup} from 'apollo/queries/GroupQueries';
import {createGuid} from 'utils/utils';
import {useParams} from 'react-router-dom';
import {useAuth} from 'react-oidc-context';
import {fileUploader} from 'utils/upload';

const ClubGroupForm = (props) => {
    const {setLoading} = props;

    const {t} = useTranslation();
    const auth = useAuth();
    const {groupId} = useParams();

    const [image, setImage] = useState(null);
    const [errors, setErrors] = useState({});
    const [values, setValues] = useState(props.defaultValues);

    const [saveGroup] = useSaveGroup();

    const fieldChanged = (event) => {
        const newValues = { ...values };
        newValues[event.target.name] = event.target.value;

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });

        if (event.target.file !== undefined) {
            newValues[event.target.name+"File"] = event.target.file;
        }
        setValues(newValues);
    }

    const validationRules = {
        name: yup.string().required(t("required")),
    }

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors
    };

    const onImageChanged = (e) => {
        setImage(e);
    }

    useEffect(() => {
        if (!image) return;
        fieldChanged(image);
    }, [image]);

    const formSubmitted = async (e) => {
        e.preventDefault();
        setLoading(true);
        validateForm(values, validationRules, (errors) => {
            if (notHaveErrors(errors)) {
                saveData();
            } else {
                setLoading(false);
                setErrors(errors);
            }
        });
    };

    const saveData = async () => {
        const variables = {
            input: {
                groupId: values.groupId ?? createGuid(),
                parentGroupId: values.parentGroupId ?? groupId ?? null,
                name: values.name,
                description: values.description,
                haveClub: values.haveClub
            }
        }

        if (values.logoFile !== undefined) {
            const fileData = {
                clubId: "system",
                fileId: variables.input.groupId
            }

            if (await fileUploader(values.logoFile, fileData, auth)) {
                variables.input.logoUploadDate = new Date().toISOString();
            }
        }

        await saveGroup({variables: variables});
        props.onComplete();
    };

    return (
      <>
          <form id={props.id} onSubmit={formSubmitted}>
              <div className={classes.columns21}>
                  <div>
                      <Input
                        name="name"
                        label={t('Name')}
                        {...regField}
                      />

                      <Textarea
                        name="description"
                        label={t('Description')}
                        {...regField}
                      />

                      <Switch
                        fullSize={true}
                        name="haveClub"
                        label={t('Have club')}
                        {...regField}
                      />

                  </div>
                  <div>
                      <ImageUpload
                        name="logo"
                        label={t('Group logo')}
                        text="Add group logo"
                        {...regField}
                        onChange={onImageChanged}
                      />
                  </div>
              </div>
          </form>
      </>
    );
};

ClubGroupForm.defaultProps = {};

export default ClubGroupForm;
