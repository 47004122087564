import React from 'react'
import classes from './ErrorMessage.module.scss'

const ErrorMessage = props => {
    return (
        <React.Fragment>
            {props.message
                ? <div className={classes.ErrorMessage}> { props.message }</div>
                : null
        }
        </React.Fragment>
    )
}

export default ErrorMessage