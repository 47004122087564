import React, {useState} from 'react';
import Select from 'components/UI/Select/Select';
import {useTranslation} from 'react-i18next';
import {notHaveErrors, validateFields, validateForm} from 'utils/form';
import * as yup from 'yup';
import {createGuid} from 'utils/utils';
import {useSaveDataEventRecord} from 'apollo/queries/GameQueries';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import {useParams} from 'react-router-dom';
import {DataEvents} from '../Settings';
import TimePicker3 from 'components/UI/DatePicker/TimePicker3';

const SubstitutionForm = (props) => {
    const {players} = props;
    const {gameId} = useParams();
    const navState = useReactiveVar(naviDataVar);
    const {t} = useTranslation();

    const [values, setValues] = useState(props.defaultValues);
    const [errors, setErrors] = useState({});

    const [saveDataEventRecord] = useSaveDataEventRecord();

    const fieldChanged = (event) => {
        const newValues = { ...values };
        newValues[event.target.name] = event.target.value

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });
        setValues(newValues);
    }

    const validationRules = {
        playerInId: yup.string().required(t("Require")),
        playerOutId: yup.string().notOneOf([yup.ref('playerInId')],t("Mast be unique")),
        minutes: yup.string().required(t("Require"))
    }

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors
    };

    const formSubmitted = async (e) => {
        e.preventDefault();
        validateForm(values, validationRules, (errors) => {
            if (notHaveErrors(errors))
                saveData();
            else
                setErrors(errors);
        });
    }

    const saveData = async () => {
        const variables = {
            input: {
                id: values.id ?? createGuid(),
                clubId: navState.clubId,
                teamId: navState.teamId,
                gameId: gameId,
                eventType: "TEAM_EVENT",
                gameDataEventId: DataEvents.Substitution,
                time: parseFloat(values.minutes),
                duration: 10,
                data: JSON.stringify({
                    in: values.playerInId,
                    out: values.playerOutId,
                })
            }
        };

        await saveDataEventRecord({variables: variables});
        props.onComplete();
    }

    return (
        <>
            <form id={props.id} onSubmit={formSubmitted}>
                <Select
                    name="playerInId"
                    label={t('Player in')}
                    options={players}
                    {...regField} />

                <Select
                    name="playerOutId"
                    label={t('Player out')}
                    options={players}
                    {...regField} />

                <TimePicker3
                    name="minutes"
                    isSportTimer={true}
                    label={t('Minutes')}
                    format="m.ss"
                    {...regField} />
            </form>
        </>
    );
};

SubstitutionForm.defaultProps = {
    values: {},
    players: [],
    onComplete: () => {}
};

export default SubstitutionForm;
