import React, {useState} from 'react';
import classes from './WeakSelect.module.scss';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';

const WeakSelect = (props) => {
    const {name, label, days, onChange, dissabled} = props;

    const {t} = useTranslation();
    const htmlFor = props.id || `switch-${Math.random()}`;
    const [items, setItems] = useState(days);

    const onSelect = (item) => {
        const newItems = [...items];
        const selectedItem = items.find(x => x.name === item.name);
        selectedItem.selected = !selectedItem.selected;
        setItems(newItems);

        const result = {
            target: {
                name:  name,
                value: newItems.map(x => x.selected).join(',')
            }
        };
        onChange(result);
    };

    return (
        <div className={classes.WeakSelect}>
            {(label) && <label htmlFor={htmlFor}>{label}</label>}
            <ul>
                {items.map(x => <li key={x.name}
                                    className={cn({[classes.selected]: x.selected})}
                                    onClick={() => onSelect(x)}>{t(x.name)}</li>)}
            </ul>
        </div>
    );
};

WeakSelect.propTypes = {};
WeakSelect.defaultProps = {
    days: [
        {
            name: 'Mo',
            selected: false
        },
        {
            name: 'Tu',
            selected: false
        },
        {
            name: 'We',
            selected: false
        },
        {
            name: 'Th',
            selected: false
        },
        {
            name: 'Fr',
            selected: false
        },
        {
            name: 'Sa',
            selected: false
        },
        {
            name: 'Su',
            selected: false
        }
    ]
};

export default WeakSelect;
