import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import classes from './FileUpload.module.scss';
import {toastMessage} from '../Toast/Toast';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';

const FileUpload = forwardRef((props, ref) => {
    const {t} = useTranslation();
    const [accept, setAccept] = useState('image/jpeg, image/png, image/gif');

    const onDrop = useCallback(acceptedFiles => {
        if (acceptedFiles.length === 0) {
            toastMessage(false, t("Incompatible file type."));
        } else {
            props.onUpload(acceptedFiles);
        }
    }, []);

    const {getRootProps, getInputProps, isDragActive, open} = useDropzone({onDrop, accept: accept});

    useEffect(() => {
        switch (props.mode) {
            case "video":
                setAccept('video/mp4');
                break;
            case "images":
                setAccept('image/jpeg, image/png, image/gif');
                break;
            case "xls":
                setAccept('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
                break;
            case "all":
                setAccept('image/jpeg, image/png, image/gif, application/msword,' +
                    ' application/vnd.openxmlformats-officedocument.wordprocessingml.document,' +
                    ' application/vnd.oasis.opendocument.text, application/pdf, application/vnd.ms-powerpoint,' +
                    ' application/vnd.openxmlformats-officedocument.presentationml.presentation, text/plain');
                break;
        }
    }, []);

    useImperativeHandle(ref, () => ({
        onOpen() {
            open();
        }
    }));

    return (
        <div {...getRootProps()} className={cn(classes.fileUpload, {[classes.hide]: props.hide})}>
            <input {...getInputProps()} />
            {
                isDragActive ?
                    <p>Drop the files here ...</p> :
                    <p>Drag 'n' drop some files here, or click to select files</p>
            }
        </div>
    );
});

FileUpload.defaultProps = {
    onUpload: (files) => {},
    mode: "images",
    hide: false
};

export default FileUpload;
