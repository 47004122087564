import React, {useEffect} from 'react';
import './ExerciseEditor.scss';

/* global $ */
/* global graphicEditor */

const editorHtmlCode = `

<link href="/content/bootstrap/bootstrap.min.css" rel="stylesheet"/>
<link href="/content/bootstrap-slider.css" rel="stylesheet"/>
<link href="/content/bootstrap-colorpicker/css/bootstrap-colorpicker.css" rel="stylesheet"/>

<script type="text/javascript" charset="utf-8">
  window.ypd = window.ypd || {};
</script>
    
<div id="obj-14-body" class="scheme-editor hide-info-panel">
   
    <div class="custom-strip">
        <div class="right-block light-gray no-controls">
            <div class="content">
                
                <div class="tool-panel">
                    <div class="tool-panel-container">
                        <div class="action-tools btn btn-del" data-action="delete-objects" style="display: none">
                            <span class="icon-Delete"></span>
                        </div>
                        <div class="action-tools btn btn-del" data-action="copy-objects" style="display: none">
                            <span class="icon-Players"></span>
                        </div>
                        <div class="action-tools ypd-size-select btn-group" style="display: none">
                            <label data-scale="xs" class="btn btn-default">XS</label>
                            <label data-scale="s" class="btn btn-default">S</label>
                            <label data-scale="m" class="btn btn-default">M</label>
                            <label data-scale="l" class="btn btn-default">L</label>
                            <label data-scale="xl" class="btn btn-default">XL</label>
                        </div>
                        <div class="action-tools ypd-color-select" style="display: none">              
                            <input class="form-control color-block" id="obj-14-element_color" name="element-color" type="text" value="" />
                        </div>
                    </div>
                </div>
                <div class="field">
                    <canvas></canvas>
                </div>
            </div>
        </div>
        <div class="left-block">
            <div class="content">
                <div class="tab-content fullsize">
                    <style id="kit-style"></style>
                   
                    <div class="overflowen fullsize grap-element-select">
                    <div class="kit-designer Players" style="display: none">
                            <div class="field-group">
                                <div>
                                    <input class="form-control color-block" id="obj-14-player_tshirt_color" name="player-tshirt-color" type="text" value="" />
                                </div>
                                <div>
                                    <input class="form-control color-block" id="obj-14-player_body_color" name="player-body-color" type="text" value="" />
                                </div>
                                <div>
                                    <input class="form-control color-block" id="obj-14-player_shorts_color" name="player-shorts-color" type="text" value="" />
                                </div>
                                <div style="display: none">
                                    <input class="form-control color-block" id="obj-14-player_shoes_color" name="player-shoes-color" type="text" value="" />
                                </div>
                            </div>
                        </div>
                        <div class="element-list">
                        </div>
                        <div class="legend-bar hidden">
                            <h3>Редактировать легенду линий</h3>
                        </div>
                        
                        <div class="kit-designer Goalkeepers" style="display: none">
                            <div class="field-group">
                                <div>
                                    <label class="col-sm-10">Тело вратаря</label>
                                    
<input class="form-control color-block" id="obj-14-gk_body_color" name="gk-body-color" type="text" value="" />


                                </div>
                                <div>
                                    <label class="col-sm-10">Футболка вратаря</label>
                                    
<input class="form-control color-block" id="obj-14-gk_tshirt_color" name="gk-tshirt-color" type="text" value="" />


                                </div>
                                <div>
                                    <label class="col-sm-10">Перчатки вратаря</label>
                                    
<input class="form-control color-block" id="obj-14-gk_gloves_color" name="gk-gloves-color" type="text" value="" />


                                </div>
                                <div>
                                    <label class="col-sm-10">Шорты вратаря</label>
                                    
<input class="form-control color-block" id="obj-14-gk_shorts_color" name="gk-shorts-color" type="text" value="" />


                                </div>
                                <div>
                                    <label class="col-sm-10">Обувь вратаря</label>
                                    
<input class="form-control color-block" id="obj-14-gk_shoes_color" name="gk-shoes-color" type="text" value="" />


                                </div>
                            </div>
                        </div>
                        <div class="animation-controls" style="display: none;">
                            <div class="controls">
                                <a href="#" class="btn btn-default" data-action="play"></a>
                                <div class="timePerFrame">
                                    <label for="obj-14-frame-length-amount">Frame time:</label>
                                    <span id="obj-14-frame-length-amount" class="frame-length-amount"></span><br />
                                    <input name="FrameLength" id="obj-14-frame-length" data-slider-id="frame-length-slider" type="text" data-slider-min="1"
                                           data-slider-max="5" data-slider-step="0.1" data-slider-tooltip="hide" data-slider-value="1" />
                                </div>
                            </div>

                            <div id="frames">
                                <ul class="frame-list">
                                    <li data-frame="0">
                                        <span>Frame 1</span>
                                    </li>
                                </ul>
                                <a href="#" data-action="add-frame" class="btn btn-default">
                                    Add frame
                                </a>
                               

    <div id="video-panel" data-container-url="/GraphicEditor/UploadVideo/779ca937-9cc8-46d8-87fe-40f5804753ab" data-search-params="">
    

    <script id="template-scheme-video-upload" type="text/x-tmpl">

        <tr class="template-upload fade-in">
            <td>
                <span class="name">{%=o.Name%}</span>
            </td>
            <td>
                <strong class="error text-danger"></strong>
                <p class="size">Processing...</p>
            </td>
            <td>
                <div class="progress progress-striped active" role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="0">
                    <div class="progress-bar progress-bar-success" style="width:0%;"></div>
                </div>
            </td>
            <td>
                <a data-action="cancel"><i class="\tglyphicon glyphicon-remove-circle"></i>Cancel</a>
            </td>
        </tr>
    </script>
        <!-- The template to display files available for download -->
    <script id="template-scheme-video-download" type="text/x-tmpl">
        {%
        var uid = globs.common.getUniqueId();
        if (o.error) {
        %}
        <tr id="{%=uid%}" class="template-download fade-in">
            <td class="alert-error">{%=o.Name ||"Internal error occured!"%}</td>
        </tr>
        {% } else{ %}
        <tr id="{%=uid%}" class="template-download fade-in" data-file-id="{%=(o.FileId)%}">
            <td>
                <form action="/GraphicEditor/UpdateVideoName"
                      id="{%=uid%}" enctype="multipart/form-data" class="cell-edit" role="form">
                    <input type="hidden" name="target-element-id" value="{%=uid%}" />
                    <input type="hidden" name="GraphicSchemeVideoId" value="{%=(o.FileId)%}" />
                    <input type="hidden" value="{%=o.Name%}" id="{%=uid%}__FileName" name="FileName" />
                    <span id="{%=uid%}__FileName-label">{%=o.Name%}</span>
                </form>
                {% if (o.Error) { %}
                <div><span class="label label-danger">Error</span> {%=o.Error%}</div>
                {% } %}
            </td>
            <td>{%=o.CreatedByName%}</td>
            <td>{%=o.CreatedTimeStr%}</td>
            <td>
                <a data-href="#" data-edit-field="#{%=uid%}__FileName">
                    <i class="glyphicon glyphicon-edit"></i>
                </a>
                <a data-href="/GraphicEditor/Video/{%=o.FileId%}" data-scope="modal-dialog">
                    <i class="glyphicon glyphicon-play"></i>
                </a>
                <a href="{%=o.Url%}" download="{%=o.Name%}" title="Download">
                    <i class="glyphicon glyphicon-download"></i>
                </a>
                <a data-href="/GraphicEditor/DeleteVideo/{%=o.FileId%}"
                   data-confirm="Are you sure want to delete video?"
                   data-scope="body-action"
                   data-method="POST"
                   data-target="#video-panel">
                    <i class="glyphicon glyphicon-trash"></i>
                </a>
            </td>
            <{%=''%}script type='text/javascript'>$('#{%=uid%}').registerFileOnForm({%#JSON.stringify(o)%},true);$('#{%=uid%} a[data-edit-field]').inlineEditField({submit:true});<{%=''%}/script>
        </tr>
        {% } %}
    </script>
   </div>                             
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
            <div class="tabs">
                    <div data-action="select-category" data-category="Players" class="active">
                        <span class="icon-t-shirt"></span>
                    </div>
                    <div data-action="select-category" data-category="Equipment">
                        <span class="icon-football"></span>
                    </div>
                    <div data-action="select-category" data-category="Lines">
                        <span class="icon-Arrow---Right"></span>
                    </div>
                    <div data-action="select-category" data-category="Text">
                        <span class="icon-Chat"></span>
                    </div>
                    <div data-action="select-category" data-category="Background">
                        <span class="icon-Category"></span>
                    </div>
                    <div data-action="select-category" data-category="AnimationControls">
                        <span class="icon-Camera"></span>
                    </div>
            </div>
        </div>
        
        <script id="kit-style-template" type="text/x-tmpl">
            {% if(o.kit == "officials") { %}
            .scheme-editor .element.player,.scheme-editor .element.goalkeeper,.goalkeeper-kit { display:none !important; }
            {% } else{ %}
            .scheme-editor .element.referee { display:none !important; }
            {% } %}
            .scheme-editor .element .stroke { stroke: #2B2A29; stroke-width: 12.8119 }
            .scheme-editor .element #body { fill: {%=o.player.body%}; }
            .scheme-editor .element #shorts { fill: {%=o.player.shorts%}; }
            .scheme-editor .element #tshirt { fill: {%=o.player.tshirt%}; }
            .scheme-editor .element #shoes { fill: {%=o.player.shoes%}; }
            .scheme-editor .element #gloves { fill: {%=o.player.gloves%}; }
            .scheme-editor .goalkeeper #body { fill: {%=o.goalkeeper.body%}; }
            .scheme-editor .goalkeeper #shorts { fill: {%=o.goalkeeper.shorts%}; }
            .scheme-editor .goalkeeper #tshirt { fill: {%=o.goalkeeper.tshirt%}; }
            .scheme-editor .goalkeeper #shoes { fill: {%=o.goalkeeper.shoes%}; }
            .scheme-editor .goalkeeper #gloves { fill: {%=o.goalkeeper.gloves%}; }
        
        </script>
    </div>
</div>
`;

const ExerciseEditor = (props) => {
    const {defaultValues, onComplete} = props;

    const {background} = defaultValues;
    let {elementList, frameList, kitData} = defaultValues;

    console.log(elementList);

    elementList = elementList ? JSON.parse(elementList) : null;
    frameList = frameList? JSON.parse(frameList) : null;
    kitData = kitData? JSON.parse(kitData) : null;


    useEffect(() => {
        $(function () {

            $('#obj-14-player_tshirt_color').colorpicker().on('changeColor.colorpicker', function (event) {
                $(this).css('background', event.color.toHex());
            }).on('remove', function () {
                $('#obj-14-player_tshirt_color').colorpicker('destroy');
            });

            $('#obj-14-player_body_color').colorpicker().on('changeColor.colorpicker', function (event) {
                $(this).css('background', event.color.toHex());
            }).on('remove', function () {
                $('#obj-14-player_body_color').colorpicker('destroy');
            });

            $('#obj-14-player_shorts_color').colorpicker().on('changeColor.colorpicker', function (event) {
                $(this).css('background', event.color.toHex());
            }).on('remove', function () {
                $('#obj-14-player_shorts_color').colorpicker('destroy');
            });

            $('#obj-14-player_shoes_color').colorpicker().on('changeColor.colorpicker', function (event) {
                $(this).css('background', event.color.toHex());
            }).on('remove', function () {
                $('#obj-14-player_shoes_color').colorpicker('destroy');
            });

            $('#obj-14-gk_body_color').colorpicker().on('changeColor.colorpicker', function (event) {
                $(this).css('background', event.color.toHex());
            }).on('remove', function () {
                $('#obj-14-gk_body_color').colorpicker('destroy');
            });

            $('#obj-14-gk_tshirt_color').colorpicker().on('changeColor.colorpicker', function (event) {
                $(this).css('background', event.color.toHex());
            }).on('remove', function () {
                $('#obj-14-gk_tshirt_color').colorpicker('destroy');
            });

            $('#obj-14-gk_gloves_color').colorpicker().on('changeColor.colorpicker', function (event) {
                $(this).css('background', event.color.toHex());
            }).on('remove', function () {
                $('#obj-14-gk_gloves_color').colorpicker('destroy');
            });

            $('#obj-14-gk_shorts_color').colorpicker().on('changeColor.colorpicker', function (event) {
                $(this).css('background', event.color.toHex());
            }).on('remove', function () {
                $('#obj-14-gk_shorts_color').colorpicker('destroy');
            });

            $('#obj-14-gk_shoes_color').colorpicker().on('changeColor.colorpicker', function (event) {
                $(this).css('background', event.color.toHex());
            }).on('remove', function () {
                $('#obj-14-gk_shoes_color').colorpicker('destroy');
            });

            $('#obj-14-element_color').colorpicker().on('changeColor.colorpicker', function (event) {
                $(this).css('background', event.color.toHex());
            }).on('remove', function () {
                $('#obj-14-element_color').colorpicker('destroy');
            });

            $('#obj-14-Visibility-container input[type="hidden"]').val($('#obj-14-Visibility-container input[type=\'radio\']:checked').val());
            $('#obj-14-Visibility-container').enumSwitch('False');

            $('#obj-14-body').graphicEditor({
                data: {
                    'graphicSchemeId': '00000000-0000-0000-0000-000000000000',
                    'name': null,
                    'description': null,
                    'background': background,
                    'country': null,
                    'clubId': null,
                    'language': 'ru',
                    'category': 'None',
                    'subCategory': null,
                    'complexity': 'None',
                    'visibility': 'My',
                    'duration': 0,
                    'kitSet': kitData,
                    'imageData': null,
                    'selectEvent': null,
                    'createdBy': null,
                    'updatedTime': null,
                    'createdTime': null,
                    'elementList': elementList,
                    'legendList': [],
                    'frameList': frameList,
                    'delete': null,
                    'hasVideo': false,
                    'isNew': false
                },
            });
        });
    }, []);

    const formSubmitted = (e) => {
        e.preventDefault();

       const data = graphicEditor.onSave();

        const result = {
            scheme: {
                background: data.background,
                schemeData: data.imageData,
                elementList: JSON.stringify(data.elementList),
                frameList: JSON.stringify(data.frameList),
                kitData: JSON.stringify(data.kitSet),
            }
        };

        onComplete(result);
    }

    return (
        <>
            <div id="exerciseEditorV2" dangerouslySetInnerHTML={{__html: editorHtmlCode}}/>
            <form id={props.id} onSubmit={formSubmitted}></form>
        </>
    );
};

ExerciseEditor.defaultProps = {};

export default ExerciseEditor;
