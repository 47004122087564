import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from '../../../../apollo/cashe';
import {notHaveErrors, validateFields, validateForm} from '../../../../utils/form';
import * as yup from 'yup';
import {createGuid} from '../../../../utils/utils';
import Input from '../../../../components/UI/Input/Input';
import Select from '../../../../components/UI/Select/Select';
import DatePicker from '../../../../components/UI/DatePicker/DatePicker';
import {useEditTrainerTeamLink} from '../../../../apollo/queries/TrainerQueries';

const TeamCoachForm = (props) => {
    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);
    const [values, setValues] = useState(props.defaultValues);
    const [errors, setErrors] = useState({});

    const [editTrainerTeamLink] = useEditTrainerTeamLink();

    const fieldChanged = (event) => {
        const newValues = { ...values };
        newValues[event.target.name] = event.target.value

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });
        setValues(newValues);
    }

    const validationRules = {
        type: yup.string().required(t("Require")),
        startDate: yup.string().required(t("Require"))
    }

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors
    };

    const formSubmitted = (e) => {
        e.preventDefault();
        validateForm(values, validationRules, (errors) => {
            if (notHaveErrors(errors))
                saveData();
            else
                setErrors(errors);
        });
    }

    const saveData = () => {
        const variables = {
            input: {
                clubId: navState.clubId,
                teamId: navState.teamId,
                teamTrainerId: values.teamTrainerId,
                name: values.name,
                startDate: values.startDate,
                endDate: values.endDate,
            }
        };

        editTrainerTeamLink({variables: variables}).then(result => {
            props.onComplete();
        });
    }

    return (
        <>
            <form id={props.id} onSubmit={formSubmitted}>
                <Input
                    name="fullName"
                    label={t('Coach')}
                    disabled={true}
                    {...regField}
                />

                <Input
                    name="teamName"
                    label={t('Team')}
                    disabled={true}
                    {...regField}
                />

                <Select
                    name="type"
                    {...regField}
                    options={props.roles}
                    label={t('Role')}
                />

                <DatePicker
                    name="startDate"
                    label={t('Start date')}
                    {...regField}
                />

                <DatePicker
                    name="endDate"
                    label={t('End date')}
                    {...regField}
                />
            </form>
        </>
    );
};

TeamCoachForm.defaultProps = {
    roles: [
        {label: "Coach", value: "TRAINER"},
        {label: "Assistant", value: "ASSISTANT"}
    ]
};

export default TeamCoachForm;
