import React, {useEffect, useState} from 'react';
import Submenu from "components/Navigation/Submenu/Submenu";
import classes from "./Access.module.scss";
import {useTranslation} from "react-i18next";
import Table from "components/UI/Table/Table";
import Subtext from "components/UI/Table/Subtext/Subtext";
import Pagination from "components/UI/Pagination/Pagination";
import cn from 'classnames';
import Panel from "components/UI/Panel/Panel";
import Select from "components/UI/Select/Select";
import Search from "components/UI/Panel/Search/Search";
import AccessForm from "./forms/AccessForm";
import Button from "components/UI/Button/Button";
import {toastMessage} from 'components/UI/Toast/Toast';
import {useUsers} from 'apollo/queries/PermissionsQueries';
import SwitchComponents from 'components/System/SwitchComponents';
import RemoveAccessForm from './forms/RemoveAccessForm';
import {naviDataVar} from 'apollo/cashe';
import {useReactiveVar} from '@apollo/client';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import NewPlayerForm from '../../Team/Team/forms/NewPlayerForm';
import {usePlayerLazy} from 'apollo/queries/PlayerQueries';

const PlayerAccess = (props) => {

    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const [search, setSearch] = useState('');
    const [skip, setSkip] = useState(0);
    const [filters, setFilters] = useState({status: "all", parent: "all", staff: "all"});

    const [modalData, setModalData, initState] = ModalState();

    const [getUserList, userData] = useUsers();
    const [getPlayerLazy, playerData] = usePlayerLazy();

    const getStatus = (data) => {
        switch (data) {
            case "ACTIVE":
                return t("Invited");
            case "INVITED":
                return t("Not accepted");
            default:
                return t("Not invited");
        }
    }

    useEffect(() => {
        if (!navState.clubId) {
            return;
        }

        const variables = {
            clubId: navState.clubId,
            where: {
                "roles": { "some": {"name": {"in": ["Player"]}}},
                "fullName": {"contains": search}
            },
            skip: skip * 10,
            take: 10
        };

        if (props.accessType === "team") {
            variables.teamId = navState.teamId;
        }

        if (filters.status !== "all")
            variables.where.invite = {"eq": filters.status};

        getUserList({variables: variables});
    }, [navState, search, skip, filters]);

    useEffect(() => {
        if(!playerData.data) {
            return;
        }

        const _data = {
            ...initState,
            title: t("Edit player"),
            form: "player",
            pages: {
                page: 0,
                maxPages: 3,
            },
            styles: {
                maxWidth: "700px",
            },
            defaultValues: {
                ...playerData.data.players.items[0]
            },
            onComplete: onComplete,
            onDeleteClick: () => onDelete(playerData.data.players.items[0].playerId),
            open: true
        };

        setModalData(_data);
    }, [playerData]);

    const onSearch = (e) => {
        setSkip(0);
        setSearch(e.target.value);
    }

    const onFilter = (e) => {
        const newFilters = {...filters};
        newFilters[e.target.name] = e.target.value;
        setFilters(newFilters);
    }

    const onAddPlayer = () => {
        const data = {
            ...initState,
            title: t("Add new player"),
            form: "player",
            pages: {
                page: 0,
                maxPages: 3,
            },
            styles: {
                maxWidth: "700px",
            },
            onComplete: onComplete,
            open: true
        };

        setModalData(data);
    }

    const onEditPlayer = (data) => {
        getPlayerLazy({
            variables: {
                clubId: navState.clubId,
                playerId: data.id,
            }
        });
    }

    // const onPageChange = (page) => {
    //     const currentPage = +page;
    //     setModalData({...modalData, page: currentPage});
    // }

    const onComplete = (data) => {
        userData.refetch();
        setModalData(initState);
        toastMessage(data.result, data.message);
    };

    const onDelete = (id) => {
        // setModalData(initState);
        // userData.refetch();
    };

    const actions = () => (
        <Button type="primary" style={{margin: 0}} onClick={onAddPlayer}>
            <span><i className="icon-Plus-Square"></i> {t("Add player")}</span>
        </Button>
    );

    const panelFilters = () => (
        <>
            <Select
                small="small"
                name="status"
                value={filters}
                onChange={onFilter}
                options={props.status}
                placeholder={t("Status")} />
        </>
    );

    const panelSearch = () => (
        <Search onSearch={onSearch} placeholder={t("Search player...")} />
    );

    const onInvite = (data) => {
        const _data = {
            ...initState,
            title: t("Send invite"),
            form: "invite",
            defaultValues: {
                id: data.id,
                name: data.fullName,
                email: data.email ?? "",
                roles: data.roles.map(x => x.roleId)
            },
            onComplete: onComplete,
            open: true
        };

        setModalData(_data);
    }

    const onReInvite = (data) => {
        const _data = {
            ...initState,
            title: t("Send re-invite"),
            form: "invite",
            defaultValues: {
                id: data.id,
                name: data.fullName,
                email: data.email ?? "",
                roles: data.roles.map(x => x.roleId)
            },
            onComplete: onComplete,
            open: true
        };

        setModalData(_data);
    }

    const onRemove = (data) => {
        const _data = {
            ...initState,
            title: t("Remove access"),
            form: "remove",
            defaultValues: {
                id: data.id,
                name: data.fullName,
                email: data.email ?? "",
                roles: data.roles.map(x => x.roleId)
            },
            onComplete: onComplete,
            open: true
        };

        setModalData(_data);
    }

    // const onMassInviteClick = () => {
    //     if(userData?.data?.users?.items?.length > 0) {
    //         const modal = {
    //             query: queryData,
    //             title: t("Mass invite"),
    //             open: true,
    //             formId: setMassInviteModalData.formId
    //         };
    //
    //         setMassInviteModalData(modal);
    //     }
    // }

    return (
        <>
            <div className="page-header">
                <h2>{props.accessType === "club" ? t('Club access') : t('Team access')}</h2>
                <Submenu />
            </div>
            <div className="page-content">
                <Panel border={false} action={actions} filters={panelFilters} search={panelSearch}>
                    <br />
                </Panel>

                {userData.loading && <>Loading...</>}
                {userData.error && <>Error...</>}
                {userData.data &&
                    <>
                        <Table
                            data={userData.data.users.items}
                            columns={[
                                {
                                    Header: t('User name'),
                                    accessor: 'fullName',
                                    Cell: (cellData) => <div onClick={() => onEditPlayer(cellData.row.original)} style={{cursor: 'pointer'}}>
                                        {cellData.value}
                                        <Subtext>{t("Role")}: {cellData.row.original.roles.map((x,index) => (
                                            <span key={`${cellData.row.original.id}-${index}`} >{index ? ", " : undefined} {t(x.name)}</span>
                                        ))}</Subtext>

                                    </div>
                                },
                                {
                                    Header: t('E-mail'),
                                    className: classes.hide,
                                    accessor: 'email',
                                    Cell: (cellData) => <div>
                                        <a href="mailto:{cellData.value}">{cellData.value}</a>
                                        {cellData.row.original.phone && <Subtext>{t("Phone")}: <a href="phone:{cellData.row.original.phone}">
                                            {cellData.row.original.phone}</a></Subtext>}
                                    </div>
                                },
                                {
                                    Header: t('Status'),
                                    accessor: 'invite',
                                    className: cn(classes.buttons, classes.hide),
                                    Cell: (cellData) => {
                                        const {lastLogin} = cellData.row.original;
                                        return (<div>
                                            {getStatus(cellData.value)}
                                            {lastLogin && <Subtext>{t("Last login")}: {lastLogin}</Subtext>}
                                        </div>);
                                    }
                                },
                                {
                                    Header: t('Action'),
                                    className: classes.buttons,
                                    accessor: 'action',
                                    Cell: (cellData) => {
                                        const {invite} = cellData.row.original;
                                        return (
                                            <>
                                                {invite === "NONE" ? <Button type="primary"
                                                                             onClick={() => onInvite(cellData.row.original)} style={{width: "100px"}}>{t("Invite")}</Button> : undefined}

                                                {invite === "INVITED" ? <Button type="warning"
                                                                                onClick={() => onReInvite(cellData.row.original)} style={{width: "100px"}}>{t("Re-invite")}</Button> : undefined}

                                                {invite === "ACTIVE" ? <Button type="danger"
                                                                               onClick={() => onRemove(cellData.row.original)} style={{width: "100px"}}>{t("Remove")}</Button> : undefined}
                                            </>
                                        );
                                    }
                                }
                            ]}
                            onRowClick={() => {}}/>

                        <Pagination page={skip} count={userData.data.users.totalCount}
                                    onPageChange={(p) => setSkip(p.selected)}/>
                    </>
                }
            </div>


            <ModalWindow2 {...modalData}>
                <SwitchComponents active={modalData.form}>
                    <NewPlayerForm {...modalData} name="player" />
                    <AccessForm {...modalData} name="invite" />
                    <RemoveAccessForm {...modalData} name="remove" />
                </SwitchComponents>
            </ModalWindow2>
        </>
    );
};

PlayerAccess.defaultProps = {
    status: [
        {label: "All", value: "all" },
        {label: "Not invited", value: "NONE" },
        {label: "Not accepted", value: "INVITED" },
        {label: "Invited", value: "ACTIVE" }
    ]
}
export default PlayerAccess;
