import React, {useState, useEffect} from 'react';
import {Outlet} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import classes from './RegistrationLayout.module.scss';
import cn from 'classnames';

const RegistrationLayout = (props) => {
    const {t} = useTranslation();
    const [type, setType] = useState("default");

    useEffect(() => {
        if (window.innerWidth < 800) {
            setType("default");
        }
    }, [window.innerWidth]);

    const LeftColumn = () => (
        <div className={classes.left}>
            <div className={classes.header}>
                <a href={process.env.REACT_APP_REVIEW_URL}>
                    <img src="/assets/images/logo.svg" width="94" height="62" title="YoPlayDo" alt="YoPlayDo" />
                </a>
                <div className={classes.subtitle}>
                    {t("Youth Football Data and Statistics Management Software")}
                </div>
            </div>
            <div className={classes.copy}>
                <a href={process.env.REACT_APP_REVIEW_URL}>YoPlayDo</a> 2022. All rights reserved.
            </div>
        </div>
    );

    const MainColumn = () => (
        <div className={classes.main}>
            <a className={classes.header2} href={process.env.REACT_APP_REVIEW_URL}>
                <img src="/assets/images/logo.svg" className={classes.logo} width="94" height="62" title="YoPlayDo" alt="YoPlayDo" />
            </a>
            <div className={classes.form}>
                <Outlet context={[setType]} />
            </div>
            <div className={classes.footer2}>
                <a href={process.env.REACT_APP_REVIEW_URL}>YoPlayDo</a> {new Date().getFullYear()}. All rights reserved.
            </div>
        </div>
    );

    const RightColumn = () => (
        <div className={classes.right}>
            <div className={classes.empty}></div>
            <ul className={classes.links}>
                <li><a href={`${process.env.REACT_APP_REVIEW_URL}/terms-and-conditions`}>{t("Terms & Conditions")}</a>
                </li>
                <li><a href={`${process.env.REACT_APP_REVIEW_URL}/privacy-policy`}>{t("Privacy policy")}</a></li>
            </ul>
        </div>
    );

    return (
        <div className={cn(classes.registration, type)}>
            <div className={classes.bg}></div>

            <div className={classes.area}>
                <LeftColumn />
                {type !== "right"
                    ? <>
                        <MainColumn />
                        <RightColumn />
                    </>
                    : <>
                        <RightColumn />
                        <MainColumn />
                    </>
                }
            </div>
        </div>
    );
};

RegistrationLayout.defaultProps = {};

export default RegistrationLayout;
