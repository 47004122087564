import React from 'react';
import {useTranslation} from 'react-i18next';
import classes from 'containers/SysAdmin/ClubGroups/ClubGroup.module.scss';
import Subtext from 'components/UI/Table/Subtext/Subtext';
import Table from 'components/UI/Table/Table';
import {useNavigate} from 'react-router-dom';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import NewClubForm from 'containers/Club/TeamList/forms/NewClubForm';
import {useDeleteClubToGroup} from 'apollo/queries/GroupQueries';
import {getClubFormat} from 'containers/SysAdmin/ClubList/ClubFormats';
import DateTime from 'components/UI/Table/DateTime/DateTime';
import cn from 'classnames';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';

const ClubGroupClubs = (props) => {
	const {data, refetch} = props;

	const {t} = useTranslation();
	const history = useNavigate();

	const [modalData, setModalData, initState] = ModalState();

	const [deleteClubToGroup] = useDeleteClubToGroup();

	const onClick = (data) => {
		history(`/club-${data.clubId}`);
	};

  const onSettings = () => {
		  return [
			  {label: 'Edit club', id: '1', action: onEditClub},
			  {label: 'Delete from group', id: '2', action: onDeleteClub},
		  ];
  };

	const onEditClub = (data) => {
		const _data = {
			...initState,
			title: t('Edit club'),
			form: 'club',
			styles: {
				maxWidth: "700px",
			},
			defaultValues: {
				...data,
			},
			onComplete: onComplete,
			open: true
		}

		setModalData(_data);
	}

	const onComplete = async () => {
		setModalData(initState);
		await refetch();
	}

	const onDeleteClub = async (_data) => {
		await deleteClubToGroup({variables: {input: {groupId: data.group.groupId, clubIds: [_data.clubId]}}});
		await refetch();
	}

	return (
		<>
			<Table
				data={data.group?.clubs ?? []}
				access={[SystemRoles.SysAdmin, SystemRoles.ClubAdmin]}
				columns={[
					{
						Header: '',
						accessor: 'logo',
						className: classes.rowLogo,
						Cell: (cellData) => <div style={{cursor: 'pointer'}}>
              <img src={cellData.value ?? "/assets/images/emptyLogo.svg"} alt={cellData.row.original.name}/></div>
					},
					{
						Header: t('Club name'),
						accessor: 'name',
						Cell: (cellData) => <div style={{cursor: 'pointer'}}>
							{cellData.value}
							<Subtext>{t("Type")}: {t(getClubFormat(cellData.row.original.clubFormat))}</Subtext>
						</div>
					},
					{
						Header: t('Country'),
						accessor: 'country',
						className: classes.hide,
						Cell: (cellData) => <div>{cellData.value}
							<Subtext>{t("Address")}: {cellData.row.original.city ? cellData.row.original.city : "-"}</Subtext></div>
					},
					{
						Header: t('Last activity'),
						className: classes.hide,
						accessor: 'lastLoginDate',
						Cell: (cellData) => <div className={classes.hide}><DateTime data={cellData.value}/></div>
					},
					{
						Header: t('Teams'),
						accessor: 'teamCount',
						className: cn(classes.stat, classes.hide),
						Cell: (cellData) => <div style={{cursor: 'pointer'}}>
							{cellData.value}
						</div>
					},
          {
            Header: t('Coaches'),
            accessor: 'trainerCount',
	          className: classes.stat,
            Cell: (cellData) => <div style={{cursor: 'pointer'}}>
              {cellData.value}
            </div>
          },
					{
						Header: t('Players'),
						accessor: 'playerCount',
						className: classes.stat,
						Cell: (cellData) => <div style={{cursor: 'pointer'}}>
							{cellData.value}
						</div>
					},
				]}
				onSettings={onSettings}
				onRowClick={onClick}/>

			<ModalWindow2 {...modalData}>
				<NewClubForm {...modalData} name="club" />
			</ModalWindow2>
		</>
	);
};

ClubGroupClubs.defaultProps = {};

export default ClubGroupClubs;
