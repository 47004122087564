import React, {useState} from 'react';
import {notHaveErrors, validateFields, validateForm} from '../../../../utils/form';
import * as yup from 'yup';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from '../../../../apollo/cashe';
import {createGuid} from '../../../../utils/utils';
import Input from '../../../../components/UI/Input/Input';
import Switch from '../../../../components/UI/Switch/Switch';
import {useSaveVenue} from '../../../../apollo/queries/GameQueries';

const VenueForm = (props) => {
    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);
    const [values, setValues] = useState(props.defaultValues);
    const [errors, setErrors] = useState({});

    const [saveVenue] = useSaveVenue(props.queryVariables);

    const fieldChanged = (event) => {
        const newValues = { ...values };
        newValues[event.target.name] = event.target.value

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });
        setValues(newValues);
    }

    const validationRules = {
        name: yup.string().required(t("Require")),
    }

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors
    };

    const formSubmitted = (e) => {
        e.preventDefault();
        validateForm(values, validationRules, (errors) => {
            if (notHaveErrors(errors))
                saveData();
            else
                setErrors(errors);
        });
    }

    const saveData = () => {
        const variables = {
            input: {
                venueId: values?.venueId ?? createGuid(),
                clubId: navState.clubId,
                name: values.name,
                belongsToClub: values.belongsToClub,
                showOnTrainings: values.showOnTrainings,
                showOnGames: values.showOnGames,
            }
        };

        saveVenue({variables: variables}).then(result => {
            props.onComplete();
        });
    }

        return (
        <>
            <form id={props.id} onSubmit={formSubmitted}>
                <Input
                    name="name"
                    label={t('Name')}
                    {...regField}
                />

                <Switch
                    {...regField}
                    fullSize={true}
                    name="belongsToClub"
                    label={t('Belongs to club')}
                />

                <Switch
                    {...regField}
                    fullSize={true}
                    name="showOnGames"
                    label={t('Show in games')}
                />

                <Switch
                    {...regField}
                    fullSize={true}
                    name="showOnTrainings"
                    label={t('Show in trainings')}
                />
            </form>
        </>
    );
};

VenueForm.defaultProps = {
    defaultValues: {
        belongsToClub: true,
        showOnGames: true,
        showOnTrainings: true
    }
};

export default VenueForm;
