import React, {useEffect, useState} from 'react';
import classes from './ExerciseForm.module.scss';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';
import Button from '../../../../../components/UI/Button/Button';
import Input from '../../../../../components/UI/Input/Input';
import {notHaveErrors, validateForm} from '../../../../../utils/form';
import * as yup from 'yup';
import {useExerciseTag, useSaveExerciseTag} from '../../../../../apollo/queries/ExerciseQueries';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from '../../../../../apollo/cashe';

const PageFour = (props) => {
    const {values, setValues, errors, setErrors, regField} = props;

    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const [tagList, setTagList] = useState(values.tagList);

    const {data} = useExerciseTag( {clubId: navState.clubId });
    const [saveExerciseTag] = useSaveExerciseTag(navState.clubId);

    useEffect(() => {
        if (!data) return;

        const {exerciseTags} = data;
        const userTags = exerciseTags.filter(x => !tagList.find(t => t.tag === x.tag)).map(item => ({id: item.id, tag: item.tag, selected: false}));
        const _tagList = tagList.concat(userTags);

        setTagList(_tagList);
        setValues({...values, tagList: _tagList});
    }, [data]);

    const addTagSubmitted = (e) => {
        e.preventDefault();
        validateForm(values, {tag: yup.string().required(t("required"))}, (errors) => {
            if (notHaveErrors(errors))
                saveTagData();
            else
                setErrors(errors);
        });
    }

    const saveTagData = () => {
        const variables = {
            input: {
                clubId: navState.clubId,
                tag: values.tag
            }
        }

        saveExerciseTag({variables: variables})
        .then((tag) => {
            const newValues = {...values};
            newValues.tag = "";

            const newTag = tag?.data?.saveExerciseTag ?? null;
            if (newTag != null) {
                newTag.selected = true;
                const _tagList = [...tagList, newTag];
                setTagList(_tagList);
                newValues.tagList = _tagList;
            }

            setValues(newValues);
        }).catch((data) => {
            setErrors({...errors, tag: "Tag already exist"});
        });
    }

    console.log(values);

    const onTagSelect = (selected) => {
        const newTags = [...values.tagList];
        const tag = newTags.find(x => x.id === selected.id);
        tag.selected = !tag.selected;
        setValues({...values, tagList: newTags});
    }

    return (
        <>
            <div className="message">{t('Select one or more tags.')}</div>
            <div className={classes.addTag}>
                <Input
                    name="tag"
                    label={t('Add new tag')}
                    {...regField} />
                <span className={classes.addTagButton}>
                 <Button type="primary" onClick={addTagSubmitted}>{t('Add')}</Button>
             </span>
            </div>

            <div>
                {tagList.map(x => (<span key={x.id} onClick={() => onTagSelect(x)}
                      className={cn(classes.tag, {[classes.selectedTag]: x.selected})}>{x.tag}</span>))}
            </div>
        </>
    );
};

PageFour.defaultProps = {};

export default PageFour;
