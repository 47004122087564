import React, {useEffect, useState} from 'react';
import {haveDefaultValue, haveError} from '../../../utils/form';
import style from './RadioList.module.scss';
import cn from 'classnames';
import {useTranslation} from 'react-i18next';

const RadioList = (props) => {
    const {name, options, inline} = props;
    const {t} = useTranslation();
    const error = haveError(props);
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        const defaultValue = haveDefaultValue(props);
        if (defaultValue) {
            setSelected(defaultValue);
        }
    }, [props]);

    const onChange = (e) => {
        props.onChange({
            target: {
                name:  name,
                value: e.target.value
            }
        });
    }

    const Item = ({data}) => {
        const htmlFor = `checkbox-${Math.random()}`;
        return (
            <div className={cn({[style.inline]:inline})}>
                <div className={cn(style.item, {[style.invalid]:error !== null})}>
                    <input
                        id={htmlFor} type="radio"
                        name={name}
                        value={data.value}
                        checked={selected === data.value}
                        onChange={onChange}
                    />
                    <label  htmlFor={htmlFor}>{t(data.label)}</label>
                </div>
            </div>
        );
    }

    return (
        <div className={style.radioList}>
            {options.map(x => <Item key={`${name}-${x.value}`} data={x} />)}
        </div>
    );
};

RadioList.defaultProps = {
    name: "radioListName",
    options: [],
    onChange: (data) => {
        console.log(data);
    },
    inline: false
};

export default RadioList;
