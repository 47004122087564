import React, {useState} from 'react';
import Button from "components/UI/Button/Button";
import Panel from "components/UI/Panel/Panel";
import Table from "components/UI/Table/Table";
import Dropdown from "components/UI/Table/DropDown/Dropdown";
import classes from "./TrainingDetails.module.scss";
import {useTranslation} from "react-i18next";
import AddPlayerForm from './forms/AddPlayersForm';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import {
    useAddTrainingPlayers,
    useDeleteTrainingPlayers,
    useSaveTrainingPlayerStatus
} from 'apollo/queries/TrainingQueries';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import CommentForm from './forms/CommentForm';
import SwitchComponents from 'components/System/SwitchComponents';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';
import CanAccess from 'components/Navigation/CanAccess/CanAccess';

const TrainingDetailsPlayers = (props) => {
    const {attendance, health, score, data, refetch} = props;

    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const [selectedRows, setSelectedRows] = useState([]);

    const [modalData, setModalData, initState] = ModalState();

    const [addTrainingPlayers] = useAddTrainingPlayers();
    const [saveTrainingPlayerStatus] = useSaveTrainingPlayerStatus();
    const [deleteTrainingPlayers] = useDeleteTrainingPlayers();

    const onAddPlayers = () => {
        const {players} = data.trainings?.items[0] ?? {};

        const _data = {
            ...initState,
            form: "player",
            title: t("Add players"),
            existedPlayersIds : players.map(p => p.playerId),
            onAddPlayers: onComplete,
            open: true
        }

        setModalData(_data);
    }

    const onComplete = (players) => {
        setModalData(initState);

        const {trainingId} = data.trainings?.items[0] ?? {};

        if (trainingId && players.length === 0)
            return;

        const variables = {
            input: {
                trainingId: trainingId,
                clubId: navState.clubId,
                teamId: navState.teamId,
                playerIds: players.map(x => x.playerId)
            }
        };

        addTrainingPlayers({variables: variables}).then(result => {
            refetch();
        });
    }

    const onStatusChanged = (status, player) => {
        const {name, value} = status.target;
        const {playerTrainingId} = player.row.original;

        const variables = {
            input: {
                teamId: navState.teamId
            }
        };
        variables.input["playerTrainingId"] = playerTrainingId;
        variables.input[name] = value;

        saveTrainingPlayerStatus({variables: variables}).then(result => {
            refetch();
        });
    }

    const onComment = (player) => {
        const {playerTrainingId, playerName, comments} = player.row.original;

        const _data = {
            ...initState,
            form: "comment",
            title : t("Comment"),
            access: [SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
            defaultValues: {
                playerTrainingId: playerTrainingId,
                comments: comments
            },
            onComplete: onCommentChanged,
            open: true
        }

        setModalData(_data);
    }

    const onCommentChanged = () => {
        setModalData(initState);
        refetch();
    }

    const onSelectClick = (rows) => {
        setSelectedRows(rows);
    }

    const prepareComment = (comment) => comment
        ? comment.length <= 15
            ? comment
            : comment.substring(1, 15) + "..."
        : "...";

    const onDeleteClick = () => {
        if (!selectedRows.length) return;

        const {trainingId} = data.trainings?.items[0] ?? {};

        const variables = {
            input: {
                trainingId: trainingId,
                playerTrainingIds: selectedRows.map(x => x.playerTrainingId)
            }
        };

        deleteTrainingPlayers({variables: variables}).then(result => {
            refetch();
        });
    }

    const playersActions = () => (
      <CanAccess users={[SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin]}>
            <Button type="primary" style={{margin: 0}} onClick={onAddPlayers}>
                <span><i className="icon-Plus-Square"></i> {t("Add players")}</span>
            </Button>

            {selectedRows.length > 0 ?
                <Button type="secondary" style={{margin: 0}} onClick={onDeleteClick}>
                    <span>{t('Delete')}</span>
                </Button> : undefined
            }
      </CanAccess>
    );


    return(
        <>
            <Panel border={false} action={playersActions} >
                <br/>
            </Panel>

            <Table
                data={data.trainings?.items[0].players}
                access={[SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin]}
                onSelectClick={onSelectClick}
                onRowClick={() => {}}
                columns={[
                    {
                        Header: t('Player'),
                        accessor: 'playerName',
                    },
                    {
                        Header: t('Attendance'),
                        accessor: 'status',
                        Cell: (cellData) => <Dropdown name="status" mode="iconWithoutText" access={cellData.cell.access}
                                                      onChange={data => onStatusChanged(data, cellData)} options={attendance} value={cellData.value} />
                    },
                    {
                        Header: t('Before'),
                        accessor: 'healthBefore',
                        className: classes.health,
                        Cell: (cellData) => <Dropdown name="healthBefore" mode="iconWithoutText" access={cellData.cell.access}
                                                      onChange={data => onStatusChanged(data, cellData)}  options={health} value={cellData.value} />

                    },
                    {
                        Header: t('After'),
                        accessor: 'healthAfter',
                        className: classes.health,
                        Cell: (cellData) => <Dropdown name="healthAfter" mode="iconWithoutText" access={cellData.cell.access}
                                                      onChange={data => onStatusChanged(data, cellData)} options={health} value={cellData.value} />
                    },
                    {
                        Header: t('Comment'),
                        accessor: 'comments',
                        Cell: (cellData) => <span className={classes.comment} onClick={() => onComment(cellData)}>
                                {prepareComment(cellData.value)}</span>
                    },
                    {
                        Header: t('Score'),
                        accessor: 'rating',
                        className: classes.score,
                        Cell: (cellData) => <Dropdown name="rating" onChange={data => onStatusChanged(data, cellData)}
                                                      access={cellData.cell.access} options={score} value={cellData.value} />
                    },
                ]}
            />

            <ModalWindow2 {...modalData}>
                <SwitchComponents active={modalData.form}>
                    <CommentForm {...modalData} name="comment" />
                    <AddPlayerForm {...modalData} name="player" />
                </SwitchComponents>
            </ModalWindow2>
        </>
    );
}

TrainingDetailsPlayers.defaultProps = {
    attendance: [
        {label: "Present", icon: "<i class=\"icon-Check-Square icon-green\"></i>", value: "PRESENT"},
        {label: "Was late", icon: "<i class=\"icon-Time-Square icon-yellow\"></i>", value: "WAS_LATE"},
        {label: "Ill", icon: "<i class=\"icon-medicine icon-blue\"></i>", value: "ILL"},
        {label: "Excused", icon: "<i class=\"icon-Document icon-blue\"></i>", value: "EXCUSED"},
        {label: "Unexcused", icon: "<i class=\"icon-Close-Square icon-red\"></i>", value: "UNEXCUSED"},
    ],
    health: [
        {label: "Good", icon: "<span class=\"icon-smHappy\"><span class=\"path1\"></span><span class=\"path2\"></span><span class=\"path3\"></span><span class=\"path4\"></span><span class=\"path5\"></span><span class=\"path6\"></span></span>", value: "GOOD"},
        {label: "Average", icon: "<span class=\"icon-smConfused\"><span class=\"path1\"></span><span class=\"path2\"></span><span class=\"path3\"></span><span class=\"path4\"></span><span class=\"path5\"></span><span class=\"path6\"></span></span>", value: "AVERAGE"},
        {label: "Bad", icon: "<span class=\"icon-smSad\"><span class=\"path1\"></span><span class=\"path2\"></span><span class=\"path3\"></span><span class=\"path4\"></span><span class=\"path5\"></span><span class=\"path6\"></span><span class=\"path7\"></span><span class=\"path8\"></span><span class=\"path9\"></span></span>", value: "BAD"},
    ],
    score: [
        {label: "1 / 10", value: 1},
        {label: "2 / 10", value: 2},
        {label: "3 / 10", value: 3},
        {label: "4 / 10", value: 4},
        {label: "5 / 10", value: 5},
        {label: "6 / 10", value: 6},
        {label: "7 / 10", value: 7},
        {label: "8 / 10", value: 8},
        {label: "9 / 10", value: 9},
        {label: "10 / 10", value: 10}
    ]
};

export default TrainingDetailsPlayers;
