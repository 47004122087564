import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from '../../../apollo/cashe';
import classes from '../Settings.module.scss';
import SettingsSubmenu from '../../../components/UI/SettingsSubmenu/SettingsSubmenu';
import SkillCategories from './SkillCategories';
import SkillList from './SkillList';
import {useSkillsAndCategories} from '../../../apollo/queries/PlayerQueries';

const Skills = (props) => {
    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const [tab, setTab] = useState(null);

    const {loading, error, data, refetch} = useSkillsAndCategories({clubId: navState.clubId});

    useEffect(() => {
        setTab(null);
    }, [props.rerender]);

    if (loading) {
        return (<div>{t("Loading")}</div>);
    }

    if (error) {
        return (<div>{t("Error")}</div>);
    }

    if (tab === "categories") {
        return (<SkillCategories onBack={() => setTab(null)} data={data} refetch={refetch}/>);
    }

    if (tab === "skills") {
        return (<SkillList onBack={() => setTab(null)} data={data} refetch={refetch}/>);
    }

    return (<>
        <div onClick={props.onBack} className={[classes.title, classes.link].join(" ")}>
            <i className={["icon-Arrow---Up", classes.backButton, "backArrow"].join(" ")}></i>
            {t("Skills")}
        </div>

        <SettingsSubmenu menu={props.tabs} onClick={x => setTab(x)} />
    </>);
};

Skills.defaultProps = {
    tabs: [
        {id: "categories", name: "Categories"},
        {id: "skills", name: "Skills"},
    ],
};

export default Skills;
