import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import {useCompetitions, useDeleteCompetition} from 'apollo/queries/GameQueries';
import Button from 'components/UI/Button/Button';
import cn from 'classnames';
import classes from './Competitions.module.scss';
import Submenu from 'components/Navigation/Submenu/Submenu';
import Panel from 'components/UI/Panel/Panel';
import Table from 'components/UI/Table/Table';
import Pagination from 'components/UI/Pagination/Pagination';
import Subtext from 'components/UI/Table/Subtext/Subtext';
import DateTime from 'components/UI/Table/DateTime/DateTime';
import CompetitionForm from './forms/CompetitionForm';
import {competitionSettings} from './Settings';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';

const Competitions = (props) => {
    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);
    const onPage = 10;

    const [skip, setSkip] = useState(0);
    const [countries, setCountries] = useState([]);

    const queryVariables = {
        teamId: navState.teamId,
        take: onPage,
        skip: skip * onPage,
    };

    const {data, loading, error, refetch} = useCompetitions(queryVariables);
    const [deleteCompetition] = useDeleteCompetition(queryVariables);

    const [modalData, setModalData, initState] = ModalState();

    useEffect(() => {
        if (!data) {
            return;
        }

        const _countries = data?.otherSettings.map(x => ({label: x.value, value: x.key}));
        setCountries(_countries);
    }, [data]);

    const actions = () => {
        return (
            <Button type="primary" style={{margin: 0}} onClick={onAddCompetition}>
                <span><i className="icon-Plus-Square"></i> {t('Add competition')}</span>
            </Button>
        );
    };

    const onAddCompetition = () => {
        const _data = {
            ...initState,
            title: t("Add competition"),
            form: "competition",
            styles: {
                maxWidth: "650px",
            },
            countries: countries,
            onComplete: onComplete,
            open: true
        }

        setModalData(_data);
    }

    const onRowClick = (row) => {
        const _data = {
            ...initState,
            title: t("Edit competition"),
            form: "competition",
            styles: {
                maxWidth: "650px",
            },
            defaultValues: {
                ...row
            },
            countries: countries,
            onComplete: onComplete,
            onDelete: () => onDeleteClick(row),
            open: true
        }

        setModalData(_data);
    }

    const onComplete = async () => {
        setModalData(initState);
        await refetch();
    }

    const getCountry = (code) => {
        const item = data?.otherSettings?.find(f => f.key === code);
        return item?.value;
    }

    const getCompetitionType = (code) => {
        const item = competitionSettings.type.find(f => f.value === code);
        return item?.label;
    }

    const getAchievement = (code) => {
        const item = competitionSettings.achievements.find(f => f.value === code);
        return item?.label;
    }

    const onDeleteClick = async (row) => {
        const variables = {
            input: {
                id: row.id,
                teamId: navState.teamId,
                clubId: navState.clubId
            }
        };

        await deleteCompetition({variables: variables});
        setModalData(initState);
        refetch();
    }

    return (
        <>
            <div className={cn('page-header', classes.competitions)}>
                <div className={classes.name}>{navState.teamName}</div>
                <h2>{t('Competitions')}</h2>
                <Submenu/>
            </div>
            <div className="page-content">
                <Panel border={false} action={actions}/>

                {loading && <>Loading...</>}
                {error && <>Error...</>}
                {data && <>
                    <Table
                        data={data.competitions.items}
                        center={true}
                        columns={[
                            {
                                Header: t('Competition'),
                                accessor: 'name',
                                Cell: (cellData) => <>
                                    {cellData.value}
                                    <Subtext> {getCountry(cellData.row.original.country)}, {t(getCompetitionType(cellData.row.original.competitionType))}</Subtext>
                                </>
                            },
                            {
                                Header: t('Season'),
                                accessor: 'season',
                            },
                            {
                                Header: t('Dates'),
                                accessor: 'startDate',
                                Cell: (cellData) => <><DateTime data={cellData.value} showTime={false} />
                                    <Subtext> <DateTime data={cellData.row.original.endDate} showTime={false} /></Subtext></>
                            },
                            {
                                Header: t('Achievement'),
                                accessor: 'achievement',
                                Cell: (cellData) => getAchievement(cellData.value)
                            },
                        ]}
                        onRowClick={onRowClick}/>

                    <Pagination page={skip} count={data.competitions.totalCount} onPage={onPage}
                                onPageChange={(p) => setSkip(p.selected)}/>
                </>}
            </div>

            <ModalWindow2 {...modalData}>
                <CompetitionForm {...modalData} name="competition" />
            </ModalWindow2>
        </>
    );
};

Competitions.defaultProps = {};

export default Competitions;
