import React, {useEffect} from 'react';
import {useMetricData} from '../../../apollo/queries/ExerciseQueries';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from '../../../apollo/cashe';
import classes from './TestLibrary.module.scss';
import Subtext from '../../../components/UI/Table/Subtext/Subtext';
import Button from '../../../components/UI/Button/Button';

const TestLibrary = (props) => {
    const {action, defaultValues, onComplete} = props;

    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const [getMetricData, metricData] = useMetricData();

    useEffect(() => {
        if (!navState.clubId)
            return;

        const variables = {
            clubId: navState.clubId
        };

        getMetricData({variables: variables});
    }, [navState]);

    const MetricCategory = () => {
        const {metricCategories} = metricData.data;

        return (
            <>
                {metricCategories.items.map(category => (
                    <div key={category.id} className={classes.category}>
                        <h3>{category.name}</h3>
                        <div className={classes.eventTitles}>
                            <div>{t('Test')}</div>
                            <div>{t('Measure')}</div>
                            <div>{t('Action')}</div>
                        </div>
                        <div className={classes.eventGroup}>
                            <Metrics category={category} />
                        </div>
                    </div>
                ))}
            </>
        );
    }

    const Metrics = ({category}) => {
        const {metrics} = metricData.data;
        const rows = metrics.items.filter(e => e.metricCategoryId === category.id);

        return rows.length > 0
            ? rows.map(metric => <Row key={`metric-${metric.metricId}`} metric={metric} />)
            : <div className={classes.empty}>{t("No data to display.")}</div>;
    }

    const Row = ({metric}) => (
        <div onClick={() => onRowClick(metric)}  className={classes.eventItem}>
            <div>{metric.name}</div>
            <div>
                {t(metric.unitOfMeasure)}
                <Subtext>{t(metric.systemOfMeasurement)}</Subtext>
            </div>
            <div><Button onClick={() => onSelectTest(metric)} type="primary" style={{margin: "0"}}>{t("Select")}</Button></div>
        </div>
    );

    const onSelectTest = (data) => {
        const newData = {...data, ...defaultValues};
        onComplete(newData);
    }

    const onRowClick = () => {

    }

    return (
        <>
            {metricData.loading && <>Loading...</>}
            {metricData.error && <>Error...</>}
            {metricData.data && <MetricCategory />}
            <br />
        </>
    );
};

TestLibrary.defaultProps = {};

export default TestLibrary;
