import React, {useEffect, useState} from 'react';
import classes from '../VideoEditor.module.scss';
import cn from 'classnames';
import ColorPicker from 'components/UI/ColorPicker/ClolorPicker';
import Button from 'components/UI/Button/Button';
import {useTranslation} from 'react-i18next';
import {fabric} from 'fabric';

const DrawForm = (props) => {
    const [tool, setTool] = useState("pointer");
    const [objectSelected, setObjectSelected] = useState(null);


    let obj = null;
    let drawMode = false;
    let selectionCleared = false;
    let itemsArray = [];
    let canDraw = false;

    fabric.LineArrow = fabric.util.createClass(fabric.Line, {

        type: 'lineArrow',

        initialize: function(element, options) {
            options || (options = {});
            this.callSuper('initialize', element, options);
        },

        toObject: function() {
            return fabric.util.object.extend(this.callSuper('toObject'));
        },

        _render: function(ctx) {
            this.callSuper('_render', ctx);

            // do not render if width/height are zeros or object is not visible
            if (this.width === 0 || this.height === 0 || !this.visible) return;

            ctx.save();

            var xDiff = this.x2 - this.x1;
            var yDiff = this.y2 - this.y1;
            var angle = Math.atan2(yDiff, xDiff);
            ctx.translate((this.x2 - this.x1) / 2, (this.y2 - this.y1) / 2);
            ctx.rotate(angle);
            ctx.beginPath();
            //move 10px in front of line to start the arrow so it does not have the square line end showing in front (0,0)
            ctx.moveTo(10, 0);
            ctx.lineTo(-10, 5);
            ctx.lineTo(-10, -5);
            ctx.closePath();
            ctx.fillStyle = this.stroke;
            ctx.fill();

            ctx.restore();

        }
    });

    useEffect(() => {
        canDraw = true;

        props.canvas.forEachObject(object => {
            object.selectable = true;
            object.evented = true;
        });

        return () => {
            canDraw = false;

            props.canvas.forEachObject(object => {
                object.selectable = false;
                object.evented = false;
            });
        };
    }, []);


    useEffect(() => {
        document.addEventListener("keydown", onDelete);
        return () => {
            document.addEventListener("keydown", onDelete);
        };
    }, []);

    useEffect(() => {
        if (props.canvas === {})
            return;

        props.canvas.on('mouse:down', function(o){
            if (!canDraw) {
                return;
            }

            if (selectionCleared) {
                selectionCleared = false;
                return;
            }

            if (props.canvas.getActiveObject() !== undefined && props.canvas.getActiveObject() !== null) {
                return;
            }

            let pointer = props.canvas.getPointer(o.e);
            let points = [pointer.x, pointer.y, pointer.x, pointer.y];

            if (!drawMode) {
                const newObj = getTool(points);
                if (newObj !== null && newObj !== undefined) {
                    obj = newObj;
                }

                if (obj === null || obj === undefined) {
                    return;
                }

                drawMode = obj.stopOnNextClick || obj.stopOnDoubleClick;
                itemsArray.push({x: pointer.x, y: pointer.y});

                props.canvas.add(obj);
                props.canvas.renderAll();
                obj.setCoords();
            } else {
                itemsArray.push({x: pointer.x, y: pointer.y});
            }
        });

        props.canvas.on('mouse:move', function(o){
            if (drawMode && obj.stopOnNextClick) {
                let pointer = props.canvas.getPointer(o.e);
                if (props.canvas.toolMode === "curve") {
                    const rd =  pointer.x - obj.left;
                    obj.set({radius: rd > 10 ? rd : 10 });
                } else {
                    obj.set({x2: pointer.x, y2: pointer.y});
                }
                obj.setCoords();
                props.canvas.renderAll();
            }

            if (drawMode && obj.stopOnDoubleClick) {
                let pointer = props.canvas.getPointer(o.e);
                props.canvas.remove(obj);
                props.canvas.renderAll();
                obj = getTool([{x:pointer.x, y: pointer.y}]);
                props.canvas.add(obj);
                obj.setCoords();
                props.canvas.renderAll();
            }
        });

        props.canvas.on('mouse:up', function(o){
            if (!drawMode)
                return;

            if (obj.stopOnClick === true) {
                drawMode = false;
                itemsArray = [];
                obj = null;
            }

            if (obj.stopOnNextClick === true && itemsArray.length > 1) {
                drawMode = false;
                itemsArray = [];
                obj = null;
            }
        });

        props.canvas.on('selection:created', function(o) {
            setObjectSelected(props.canvas.getActiveObject());
            drawMode = false;
            itemsArray = [];
            obj = props.canvas.getActiveObject();
        });

        props.canvas.on('selection:updated', function(o) {
            setObjectSelected(props.canvas.getActiveObject());
            drawMode = false;
            itemsArray = [];
            obj = props.canvas.getActiveObject();
        });

        props.canvas.on('selection:cleared', function(o) {
            setObjectSelected(null);
            selectionCleared = true;
        });

        props.canvas.on('mouse:dblclick', function(o) {
            if (obj?.stopOnDoubleClick === true) {
                props.canvas.remove(obj);
                props.canvas.renderAll();
                obj = getTool([]);
                obj.selectable = true;
                props.canvas.add(obj);
                obj.setCoords();
                props.canvas.renderAll();

                drawMode = false;
                itemsArray = [];
                obj = null;
            }
        });

    }, []);

    const getTool = (points) => {
        props.canvas.selection = false;
        props.canvas.isDrawingMode = false;

        props.canvas.forEachObject(function(object){
            object.selectable = false;
        });

        switch (props.canvas.toolMode) {
            case "pencil":
                props.canvas.isDrawingMode = true;
                return null;

            case "spotlight":
                return fabric.loadSVGFromURL("/assets/images/video/spotlight.svg",function(objects, options) {
                    const svgData = fabric.util.groupSVGElements(objects, options);
                    svgData.top = points[1] - svgData.height + 40;
                    svgData.left = points[0] - svgData.width / 2;
                    svgData.opacity = 0.5;
                    svgData.stopOnClick = true;
                    svgData.fill = props.canvas.freeDrawingBrush.color;
                    svgData.stroke = props.canvas.freeDrawingBrush.color;
                    svgData.canChangeColor = true;
                    props.canvas.add(svgData);
                    props.canvas.renderAll();
                    svgData.setCoords();
                });

            case "ellipse" :
                return fabric.loadSVGFromURL("/assets/images/video/ellipse.svg",function(objects, options) {
                    const svgData = fabric.util.groupSVGElements(objects, options);
                    svgData.top = points[1] - svgData.height + 40;
                    svgData.left = points[0] - svgData.width / 2;
                    svgData.opacity = 0.5;
                    svgData.stopOnClick = true;
                    svgData.fill = props.canvas.freeDrawingBrush.color;
                    svgData.stroke = props.canvas.freeDrawingBrush.color;
                    svgData.canChangeColor = true;
                    props.canvas.add(svgData);
                    props.canvas.renderAll();
                    svgData.setCoords();
                });

            case "polygon":
                return new fabric.Polygon(itemsArray.concat(points), {
                    strokeWidth: 3,
                    stroke: props.canvas.freeDrawingBrush.color,
                    fill: props.canvas.freeDrawingBrush.color,
                    stopOnDoubleClick: true,
                    canChangeColor: true,
                    opacity: 0.5,
                    selectable: false,
                });

            case "line":
                return new fabric.Line(points, {
                    strokeWidth: 3,
                    stroke: props.canvas.freeDrawingBrush.color,
                    originX: 'center',
                    originY: 'center',
                    stopOnNextClick: true,
                    stopOnDoubleClick: false,
                    canChangeColor: true,
                });

            case "curve":
                return  new fabric.Circle({
                    left: points[0],
                    top: points[1],
                    originX: 'left',
                    originY: 'top',
                    radius: 10,
                    startAngle: Math.PI,
                    endAngle: 0,
                    fill: '',
                    stroke: props.canvas.freeDrawingBrush.color,
                    strokeWidth:3,
                    stopOnNextClick: true,
                    canChangeColor: true,
                });
                // return  new fabric.Path('M 100 100 Q 200, 0, 300, 100', {
                //     fill: '',
                //     strokeWidth: 3,
                //     stroke: 'red',
                //     stopOnNextClick: true,
                //     originX: 'center',
                //     originY: 'center',
                //     top: points[1] - 40,
                //     left: points[0] + 100,
                //     width: 10,
                //     height: 10
                // });

            case "arrow":
                return new fabric.LineArrow(points, {
                    strokeWidth: 3,
                    fill: props.canvas.freeDrawingBrush.color,
                    stroke: props.canvas.freeDrawingBrush.color,
                    originX: 'center',
                    originY: 'center',
                    selectable: true,
                    stopOnNextClick: true,
                    stopOnDoubleClick: false,
                    canChangeColor: true,
                });

            case "cross":
                return fabric.loadSVGFromURL("/assets/images/video/cross.svg",function(objects, options) {
                    const svgData = fabric.util.groupSVGElements(objects, options);
                    svgData.top = points[1] - svgData.height / 2;
                    svgData.left = points[0] - svgData.width / 2;
                    svgData.opacity = 0.5;
                    svgData.stopOnClick = true;
                    svgData.canChangeColor = true;
                    svgData.fill = props.canvas.freeDrawingBrush.color;
                    svgData.stroke = props.canvas.freeDrawingBrush.color;
                    props.canvas.add(svgData);
                    props.canvas.renderAll();
                    svgData.setCoords();
                });

            case "text":
                return new fabric.IText('Text', {
                    left: points[0],
                    top: points[1],
                    fontFamily: 'arial',
                    fill: props.canvas.freeDrawingBrush.color,
                    stroke: props.canvas.freeDrawingBrush.color,
                    fontSize: 20,
                    stopOnClick: true,
                    stopOnDoubleClick: false,
                    canChangeColor: true,
                });

            default:
                return null;
        }
    }

    const onPointer = () => {
        if (props.canvas === {})
            return;
        props.canvas.toolMode = "pointer";
        setTool(props.canvas.toolMode);

        props.canvas.forEachObject(function(object){
            object.selectable = true;
        });
    }

    const onPencil = () => {
        if (props.canvas === {})
            return;
        props.canvas.toolMode = "pencil";
        setTool(props.canvas.toolMode);
    }

    const onSpotlight = () => {
        if (props.canvas === {})
            return;
        props.canvas.toolMode = "spotlight";
        setTool(props.canvas.toolMode);
    }

    const onEllipse = () => {
        if (props.canvas === {})
            return;
        props.canvas.toolMode = "ellipse";
        setTool(props.canvas.toolMode);
    }

    const onPolygon = () => {
        if (props.canvas === {})
            return;
        props.canvas.toolMode = "polygon";
        setTool(props.canvas.toolMode);
    }

    const onLine = () => {
        if (props.canvas === {})
            return;
        props.canvas.toolMode = "line";
        setTool(props.canvas.toolMode);
    }

    const onCurve = () => {
        if (props.canvas === {})
            return;
        props.canvas.toolMode = "curve";
        setTool(props.canvas.toolMode);
    }

    const onArrow = () => {
        if (props.canvas === {})
            return;
        props.canvas.toolMode = "arrow";
        setTool(props.canvas.toolMode);
    }

    const onCross = () => {
        if (props.canvas === {})
            return;
        props.canvas.toolMode = "cross";
        setTool(props.canvas.toolMode);
    }

    const onText = () => {
        if (props.canvas === {})
            return;
        props.canvas.toolMode = "text";
        setTool(props.canvas.toolMode);
    }

    const onDelete = () => {
        objectSelected &&
        props.canvas.remove(props.canvas.getActiveObject());
    }

    const onColorChange = (newColor) => {
        props.canvas.freeDrawingBrush.color = newColor;

        if (objectSelected?.fill)
            objectSelected.set({fill: newColor});

        if (objectSelected?.stroke)
            objectSelected.set({stroke: newColor});

        props.canvas.renderAll();
    }


    return (
        <>
            <div className={classes.drawToolbar}>
                <div className={cn({[classes.selected]:tool === "pointer"})} onClick={onPointer}>
                    <span className="icon-cursor"></span>
                </div>
                <div className={cn({[classes.selected]:tool === "pencil"})} onClick={onPencil}>
                    <span className="icon-edit1"></span>
                </div>
                <div className={cn({[classes.selected]:tool === "spotlight"})} onClick={onSpotlight}>
                    <span className="icon-spotlights"></span>
                </div>
                <div className={cn({[classes.selected]:tool === "ellipse"})} onClick={onEllipse}>
                    <span className="icon-ellipses"></span>
                </div>
                <div className={cn({[classes.selected]:tool === "polygon"})} onClick={onPolygon}>
                    <span className="icon-polygon"></span>
                </div>
                <div className={cn({[classes.selected]:tool === "line"})} onClick={onLine}>
                    <span className="icon-line"></span>
                </div>
                <div className={cn({[classes.selected]:tool === "curve"})} onClick={onCurve}>
                    <span className="icon-curve"></span>
                </div>
                <div className={cn({[classes.selected]:tool === "arrow"})} onClick={onArrow}>
                    <span className="icon-arrow"></span>
                </div>
                <div className={cn({[classes.selected]:tool === "cross"})} onClick={onCross}>
                    <span className="icon-crosses"></span>
                </div>
                <div className={cn({[classes.selected]:tool === "text"})} onClick={onText}>
                    <span className="icon-texts"></span>
                </div>
                <div onClick={onDelete}>
                    <span className={cn("icon-Delete", {[classes.disabled]:objectSelected === null})}></span>
                </div>
                <div>
                    <ColorPicker
                        onChange={onColorChange}
                        color={objectSelected?.stroke || props.canvas.freeDrawingBrush.color}
                        buttonClass={cn([classes.color])}
                    />
                </div>
            </div>
            <div className={classes.actions}>
                <Button type="primary" onClick={props.onSave}>Save</Button>
                <Button type="second" onClick={props.onClear}>Clear</Button>
            </div>
        </>
    );
};

DrawForm.propTypes = {};
DrawForm.defaultProps = {
    canvas: {},
    onSave: () => {},
    onClear: () => {},
    onClose: () => {}
};

export default DrawForm;
