import React from 'react';
import {
    Chart as ChartJS,
    PointElement,
    Tooltip,
    Legend,
    RadialLinearScale, LineElement, Filler
} from 'chart.js';
import {Radar} from 'react-chartjs-2';

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);

const ViewSkillsChart = (props) => {
    const {labels, datasets} = props.data;

    const options = {
        scale: {
            beginAtZero: true,
            min: 0,
            max: 10,
            step: 2
        },

        plugins: {
            legend: {
                display: false
            }
        }
    };

    const chartData = {
        labels: labels,
        datasets: datasets
    };

    return (
        <Radar options={options} data={chartData} />
    );
};

ViewSkillsChart.defaultProps = {};

export default ViewSkillsChart;
