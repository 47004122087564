import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSettings} from 'apollo/queries/SettingQueries';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar, systemDataVar} from 'apollo/cashe';
import {deepMergeFlatten} from 'utils/utils';
import classes from './SelectProfile.module.scss';
import Button from 'components/UI/Button/Button';
import {useAuth} from 'react-oidc-context';
import {useSaveSelectedRole} from 'apollo/queries/PermissionsQueries';
import {GetRootPage} from 'components/Navigation/CanAccess/roles';

const SelectProfile = () => {
    const {t} = useTranslation();
    const auth = useAuth();

    const {data, loading, error} = useSettings({fetchPolicy: 'network-only'});

    const systemData = useReactiveVar(systemDataVar);
    const naviData = useReactiveVar(naviDataVar);

    const [saveSelectedRole] = useSaveSelectedRole();

    useEffect(() => {
        if (data) {
            const newSystemData = deepMergeFlatten(systemData, data);
            newSystemData.ready = true;
            systemDataVar(newSystemData);

            const loader = document.querySelector(".preloader");
            loader.classList.add("loader-hide");
        }
    }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeServer = () => {
        window.location.href = process.env.REACT_APP_AUTHORITY;
    }

    const onSelectProfile = (role) => {
        const variables = {
            input: {
                roleId: role.roleId,
                clubId: role.clubId
            }
        }

        saveSelectedRole({variables: variables}).then(result => {
            if (result.data.saveSelectedRole) {
                const newSystemData = {...systemData};
                newSystemData.user.userRole = role;
                systemDataVar(newSystemData);

                const newNaviData = {...naviData};
                newNaviData.groupId = role.clubId;
                newNaviData.clubId = role.clubId;
                newNaviData.playerId =  role.objectId;
                naviDataVar(newNaviData);

                window.location.href = GetRootPage();
            }
        });
    }

    const onLogout = () => {
        auth.signoutRedirect().then(() => {});
    }

    if (loading) {
        return <div>{t("Loading...")}</div>
    }

    if (error) {
        return <div>{t("Error...")}</div>
    }

    return (
        <div>
            <h1 className={classes.h1}>{t("Select appropriate account")}</h1>

            {data && data.user.roles.length === 0
                ? <div>
                    <div className={classes.noRoles}>{t("No roles assigned to you on this server. Please contact club administrator.")}</div>
                    <Button type="second" onClick={onChangeServer} className={classes.changeServer}>{t("Change server")}</Button>
                </div>
                : undefined}

            {data ? data.user.roles.map(role =>
                <Button key={`${role.clubId}-${role.roleId}`} onClick={() => onSelectProfile(role)} type="second" className={classes.role}>
                    <div>
                        <img src={role.clubLogo} alt={role.clubName}/>
                    </div>
                    <div>
                        <h2>{role.name}</h2>
                        <div>{role.clubName}</div>
                    </div>
                </Button>) : undefined}

            <Button type="second" onClick={onLogout} className={classes.logout}>{t("Logout")}</Button>
        </div>
    );
};

SelectProfile.defaultProps = {};

export default SelectProfile;
