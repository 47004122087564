import React from 'react';
import Group from 'components/UI/Group/Group';
import classes from 'containers/Player/Player/Player.module.scss';
import {showDate} from 'utils/date';
import {useTranslation} from 'react-i18next';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import SwitchComponents from 'components/System/SwitchComponents';
import HealthCheckForm from 'containers/Club/ClubMedicine/form/HealthCheckForm';
import HealthConditionForm from 'containers/Club/ClubMedicine/form/HealthConditionForm';
import AnthropometricForm from 'containers/Club/ClubMedicine/form/AnthropometricForm';
import HealthCheckView from 'containers/Player/Player/view/HealthCheckView';
import HealthConditionView from 'containers/Player/Player/view/HealthConditionView';
import AnthropometricChart from 'containers/Player/Player/chart/AnthropometricChart';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';
import CanAccess from 'components/Navigation/CanAccess/CanAccess';

const AnthropometryHealth = (props) => {
	const {data, refetch} = props;
	const {playerId, fullName, gender, healthCheckNextDate, healthCondition, bodyMetrics} = data;

	const {t} = useTranslation();

	const [modalData, setModalData, initState] = ModalState();

	const onHealthCheck = () => {
		const modalData = {
			...initState,
			form: 'healthCheck',
			title: t('Add health check'),
			defaultValues: {
				playerId,
				fullName,
			},
			onComplete: onComplete,
			open: true,
		};

		setModalData(modalData);
	};

	const onHealthCheckView = () => {
		const modalData = {
			...initState,
			form: 'healthCheckView',
			title: t('Health check history'),
			styles: {
				maxWidth: '700px',
			},
			buttons: {
				hideSaveButton: true,
			},
			defaultValues: {
				playerId,
				fullName,
			},
			onClose: onComplete,
			open: true,
		};

		setModalData(modalData);
	};

	const onHealthCondition = () => {
		const modalData = {
			...initState,
			form: 'healthCondition',
			title: t('Update health condition'),
			styles: {
				maxWidth: '700px',
			},
			defaultValues: {
				playerId,
				fullName,
				gender,
			},
			onComplete: onComplete,
			open: true,
		};

		setModalData(modalData);
	};

	const onHealthConditionView = () => {
		const modalData = {
			...initState,
			form: 'healthConditionView',
			title: t('Health condition history'),
			styles: {
				maxWidth: '700px',
			},
			buttons: {
				hideSaveButton: true,
			},
			defaultValues: {
				playerId,
				fullName,
				gender
			},
			onClose: onComplete,
			open: true,
		};

		setModalData(modalData);
	};

	const onAnthropometric = () => {
		const modalData = {
			...initState,
			form: 'anthropometric',
			title: t('Add anthropometric data'),
			defaultValues: {
				playerId,
			},
			onComplete: onComplete,
			open: true,
		};

		setModalData(modalData);
	};

	const onAnthropometricChart = (metricId, name) => {
		const modalData = {
			...initState,
			form: 'anthropometricChart',
			title: t('Anthropometric chart') + ': ' + t(name),
			styles: {
				maxWidth: '700px',
			},
			buttons: {
				hideSaveButton: true,
			},
			defaultValues: {
				playerId,
				metricId
			},
			onComplete: onComplete,
			open: true,
		};

		setModalData(modalData);
	};

	const onComplete = () => {
		setModalData(initState);
		refetch().then(() => {});
	}

	return (
		<>
			<Group title="Anthropometry / Health">
				<ul>
					<li className={classes.column1148}>
						<span>{t('Next medical check')}:</span>
						<span>{healthCheckNextDate ? showDate(healthCheckNextDate) : t('N/A')}</span>
						<span>
							<span onClick={onHealthCheckView}>
                <i className="icon-Chart"/>
              </span>
							<CanAccess users={[SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin]}>
	              <span onClick={onHealthCheck}>
	                <i className="icon-Plus-Square"/>
	              </span>
							</CanAccess>
            </span>
					</li>
					<li className={classes.column1148}>
						<span>{t('Health condition')}:</span> <span>{t(healthCondition.toLowerCase())}</span>
						<span>
              <span onClick={onHealthConditionView}>
                <i className="icon-Chart"/>
              </span>
							<CanAccess users={[SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin]}>
	              <span onClick={onHealthCondition}>
	                <i className="icon-Plus-Square"/>
	              </span>
							</CanAccess>
            </span>
					</li>

					{bodyMetrics.map((metric, index) => {
						const {metricId, name, value} = metric;
						return (
							<li key={`${metricId}-${name}`} className={classes.column1148}>
								<span>{t(name)}</span> <span>{value ? value : t('N/A')}</span>
								<span>
                  <span onClick={() => onAnthropometricChart(metricId, name)}>
		                <i className="icon-Chart"/>
		              </span>
									<CanAccess users={[SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin]}>
										{index === 0 ? <span onClick={onAnthropometric}>
                    <i className="icon-Plus-Square"/>
                    </span> : undefined}
									</CanAccess>
                </span>
							</li>
						);
					})}

				</ul>
			</Group>

			<ModalWindow2 {...modalData}>
				<SwitchComponents active={modalData.form}>
					<HealthCheckForm
						{...modalData}
						name="healthCheck"
					/>
					<HealthCheckView
						{...modalData}
						name="healthCheckView"
					/>
					<HealthConditionForm
						{...modalData}
						name="healthCondition"
					/>
					<HealthConditionView
						{...modalData}
						name="healthConditionView"
					/>
					<AnthropometricForm
						{...modalData}
						name="anthropometric"
					/>
					<AnthropometricChart
						{...modalData}
						name="anthropometricChart"
					/>

				</SwitchComponents>
			</ModalWindow2>
		</>
	);
};

AnthropometryHealth.defaultProps = {};

export default AnthropometryHealth;
