import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import classes from './ImageUpload.module.scss';
import Toast, {toastMessage} from '../Toast/Toast';
import {useTranslation} from 'react-i18next';
import {haveDefaultValue} from '../../../utils/form';

const ImageUpload = forwardRef((props, ref) => {
    const {t} = useTranslation();
    let defaultValue = haveDefaultValue(props);

    const [accept, setAccept] = useState('image/jpeg, image/png, image/gif');
    const [image, setImage] = useState(defaultValue);

    const onDrop = useCallback(acceptedFiles => {
        if (props.disabled) {
            return;
        }

        if (acceptedFiles.length === 0) {
            toastMessage(false, t("Incompatible file type."));
        } else {
            const objectUrl = URL.createObjectURL(acceptedFiles[0]);
            setImage(objectUrl);
            defaultValue = objectUrl;

            console.log(acceptedFiles[0]);

            props.onChange({
                target: {
                    name:  props.name,
                    value: objectUrl,
                    file: acceptedFiles[0]
                }
            })

        }
    }, []);

    const {getRootProps, getInputProps, isDragActive, open} = useDropzone({onDrop, accept: accept, noClick: props.disabled});

    useEffect(() => {
        switch (props.mode) {
            case "video":
                setAccept('video/mp4');
                break;
            case "images":
                setAccept('image/jpeg, image/png, image/gif');
                break;
            case "all":
                setAccept('image/jpeg, image/png, image/gif, application/msword,' +
                    ' application/vnd.openxmlformats-officedocument.wordprocessingml.document,' +
                    ' application/vnd.oasis.opendocument.text, application/pdf, application/vnd.ms-powerpoint,' +
                    ' application/vnd.openxmlformats-officedocument.presentationml.presentation, text/plain');
                break;
        }
    }, []);

    useImperativeHandle(ref, () => ({
        onOpen() {
            if (props.disabled) {
                return;
            }

            open();
        }
    }));

    const getImage = () => {
        if (image)
            return (<img src={image} />);

        if (defaultValue && !image)
            return (<img src={defaultValue} />);

        return (
            <div>
                <span className="icon-Image"></span>
                <p>{t(props.text)}</p>
            </div>
        )
    }

    return (
        <>
            { (props.label) && <label>{props.label}</label> }
            <div {...getRootProps()} className={classes.imageUpload}>
                <input {...getInputProps()} />
                {getImage()}
            </div>
        </>
    );
});

ImageUpload.defaultProps = {
    onChange: (files) => {
        console.log(files);
    },
    mode: "images",
    text: "Add image",
    disabled: false
};

export default ImageUpload;
