import {InMemoryCache, makeVar} from '@apollo/client';

export const systemDataVar = makeVar({
  ready: false,
  settings: {
    general: {
        language: 'en',
        timeZone: 'default',
        dateFormat: 'dd.MM.yyyy',
        timeFormat: '00-24',
        weekStart: 'Monday',
        weekendStart: 'Saturday'
    },
    theme: {
        name: 'default',
    },
    apiVersion: '1.0.0',
    isActor: false
  },
  user: {
    userId: null,
    firstName: null,
    lastName: null,
    fullName: null,
    userPhoto: '/assets/images/no-photo.jpg',
    userRole: {
        roleId: null,
        name: null,
        clubId: null,
        clubName: null,
        clubLogo: null,
    },
    roles: [],
    userEmail: null,
  },
  languages: [],
});

export const naviDataVar = makeVar({
  isMenuOpen: false,
  language: 'en',
  groupId: null,
  groupName: null,
  clubId: null,
  clubName: null,
  teamId: null,
  teamName: "",
  playerId: null,
  playerName: null,
  selected: {
    id: null,
    hash: null,
    type: null,
    items: [],
    submenu: {
      id: null,
      type: null
    }
  },
  menu: {
    system: {
      collapsed: false
    },
    group: {
      collapsed: false
    },
    club: {
      collapsed: false
    },
    team: {
      collapsed: false
    },
    player: {
      collapsed: false
    }
  },
  settings: {
    open: false,
    chapter: 'general'
  }
});

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        naviData: {
          read() {
            return naviDataVar();
          }
        },
        systemData: {
          read() {
            return systemDataVar();
          }
        },
        // users: {
        //   keyArgs: false,
        //   merge(existing = [], incoming) {
        //     return [...existing, ...incoming];
        //   }
        // }
      }
    }
  }
});
