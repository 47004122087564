import React from 'react';
import {useTranslation} from 'react-i18next';
import classes from './NotFound.module.scss';

const NotFound = (props) => {
    const {t} = useTranslation();

    return (
        <>
            <div className="page-header">
                <h2>{t('404')}</h2>
            </div>
            <div className="page-content">
                <div>{t("Page not found.")}</div>
            </div>
        </>
    );
};

NotFound.defaultProps = {};

export default NotFound;
