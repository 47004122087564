import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import Panel from 'components/UI/Panel/Panel';
import Button from 'components/UI/Button/Button';
import Search from 'components/UI/Panel/Search/Search';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import ClubGroupForm from 'containers/SysAdmin/ClubGroups/forms/ClubGroupForm';
import {useAddClubToGroup, useDeleteGroup, useGetGroup} from 'apollo/queries/GroupQueries';
import classes from './ClubGroup.module.scss';
import Submenu from 'components/Navigation/Submenu/Submenu';
import CanAccess from 'components/Navigation/CanAccess/CanAccess';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';
import ClubGroupClubs from 'containers/SysAdmin/ClubGroups/ClubGroupClubs';
import ClubGroupSubgroups from 'containers/SysAdmin/ClubGroups/ClubGroupSubgroups';
import ClubGroupUsers from 'containers/SysAdmin/ClubGroups/ClubGroupUsers';
import DropdownButton from 'components/UI/DropdownButton/DropdownButton';
import Breadcrumbs from 'components/UI/Breadcrumbs/Breadcrumbs';
import SwitchComponents from 'components/System/SwitchComponents';
import NewClubForm from 'containers/Club/TeamList/forms/NewClubForm';
import ClubListForm from 'containers/SysAdmin/ClubList/forms/ClubListForm';
import {useReactiveVar} from '@apollo/client';
import {systemDataVar} from 'apollo/cashe';

const ClubGroup = (props) => {
    const {t} = useTranslation();
    const {groupId} = useParams();

    const systemData = useReactiveVar(systemDataVar);

    const [search, setSearch] = useState('');
    const [page, setPages] = useState("group");

    const {data, loading, error, refetch} = useGetGroup({
        groupId,
        group: {name: {contains: search}},
        club: {name: {contains: search}},
    });
    const [addClubToGroup] = useAddClubToGroup();
    const [deleteGroup] = useDeleteGroup();

    const [modalData, setModalData, initState] = ModalState();

    const actions = () => {
        let options = props.addOptions;

        if (!data) {
            return null;
        }

        if (data.group?.subgroups.length > 0) {
            options = options.filter(x => x.value === "group");
        }

        if (data.group?.clubs.length > 0) {
            options = options.filter(x => x.value !== "group");
        }

        return (
          <CanAccess users={[SystemRoles.SysAdmin]}>
              <DropdownButton type="primary" options={options} onClick={onAddClick}>
                  <span><i className="icon-Plus-Square"></i> {t('Add item')}</span>
              </DropdownButton>
          </CanAccess>
        );
    }

    const onAddClick = (item) => {
        const _data = {
            ...initState,
            title: t(`Add item`),
            form: item.value,
            styles: {
                maxWidth: "700px",
            },
            onComplete: onComplete,
            open: true
        }

        if (item.value === "exist") {
            delete _data.styles;
            _data.existedClubsIds = data.group?.clubs.map(x => x.clubId);
            _data.onAddClubs = onAddClubs;
            _data.onComplete = () => {};
        }

        setModalData(_data);
    }

    const onAddClubs = async (clubs) => {
        setModalData(initState);
        await addClubToGroup({variables: {input: {groupId: groupId, clubIds: clubs.map(x => x.clubId)}}});
        await refetch();
    }

    const onComplete = async () => {
        setModalData(initState);
        await refetch();
    }

    const panelSearch = () => {
        return (<Search onSearch={onSearch} placeholder={t("Search team...")} />);
    }

    const onSearch = (e) => {
        setSearch(e.target.value);
    }

    const onEditGroup = () => {
        const _data = {
            ...initState,
            title: t("Edit group"),
            form: "group",
            styles: {
                maxWidth: "700px",
            },
            defaultValues: {
                groupId: data.group.groupId,
                parentGroupId: data.group.parentGroupId,
                name: data.group.name,
                description: data.group.description,
                logo: data.group.logo,
                haveClub: data.group.haveClub
            },
            onComplete: onComplete,
            onDelete: () => onDeleteGroup(data),
            open: true
        }

        setModalData(_data);
    }

    const onDeleteGroup = async  (data) => {
        setModalData(initState);
        await deleteGroup({variables: {input: {id: data.groupId}}});
        await refetch();
    }

    const Header = ({data}) => {
        if (!data?.group) return(<>No data...</>);

        const {name, logo, breadcrumbs} = data.group;

        const navData = breadcrumbs.map(x => ({...x, url: `/group-${x.key}`}));

        return (
          <>
            <Breadcrumbs data={navData} />

              <div className={classes.group}>
                  <div className={classes.logo}><img src={logo ?? "/assets/images/emptyLogo.svg"} alt={name} /></div>
                  <div>
                      <h2>{name}</h2>
                      <CanAccess users={[SystemRoles.SysAdmin]}>
                          <ul className={classes.actions}>
                              <li>
                                  <Button type="third" onClick={onEditGroup}><i className="icon-Setting" />{t("Edit group")}</Button>
                              </li>
                          </ul>
                      </CanAccess>
                  </div>
              </div>
          </>
        );
    }

    const getSubmenu = () => {
        if ([SystemRoles.GroupManger, SystemRoles.SysAdmin].includes(systemData.user.userRole.roleId))  {
            return {
                group: "Items",
                users: "Users",
            }
        }

        return {
            group: "Items",
        }
    }

    return (
        <>
            <div className="page-header">
                <Header data={data} />
                <Submenu data={getSubmenu()} onFilter={(t) => setPages(t)} />
            </div>
            <div className="page-content">
                {page === "group" ? <Panel border={false} action={actions} search={panelSearch} /> : null}


                {loading && <>Loading...</>}
                {error && <>Error...</>}
                {data ?
                  <>
                    {page === "group"
                      ? <>
                          {data.group?.clubs?.length === 0
                            ? <ClubGroupSubgroups data={data} refetch={refetch} />
                            : <ClubGroupClubs data={data} refetch={refetch} />
                          }
                      </>
                      : null
                    }


                      {page === "users"
                        ? <ClubGroupUsers data={data} refetch={refetch} />
                        : null
                      }
                  </>
                : undefined}
            </div>

            <ModalWindow2 {...modalData}>
                <SwitchComponents active={modalData.form}>
                    <ClubGroupForm {...modalData} name="group" />
                    <NewClubForm {...modalData} name="club" />
                    <ClubListForm {...modalData} name="exist" />
                </SwitchComponents>
            </ModalWindow2>
        </>
    );
};

ClubGroup.defaultProps = {

    addOptions: [
        {label: "Add group", value: "group"},
        {label: "Add new club", value: "club"},
        {label: "Add exist clubs", value: "exist"},
    ]
};

export default ClubGroup;
