import React from 'react'
import classes from './Textarea.module.scss'
import {haveDefaultValue, haveError} from "utils/form";
import {useTranslation} from "react-i18next";

const Textarea = props => {
  const cls = ['form-field', classes.Textarea]
  const htmlFor = `textarea-${Math.random()}`
  const { t } = useTranslation();

  const error = haveError(props);
  const defaultValue = haveDefaultValue(props);

  if (error) {
    cls.push(classes.invalid)
  }

  return (
      <div className={cls.join(' ')}>

        {(props.label || defaultValue) && <label htmlFor={htmlFor}>{props.label || props.placeholder}</label> }

        <textarea
            name={props.name}
            id={htmlFor}
            placeholder={props.placeholder}
            onChange={props.onChange}
            defaultValue={defaultValue}
            disabled={props.disabled}
            style={{height: props.height}}
        />

        { error && <span>{error || t('Required')}</span> }

      </div>
  )
}

Textarea.defaultProps = {
    height: "auto"
};

export default Textarea
