import {gql, useLazyQuery, useMutation, useQuery} from '@apollo/client';

export const PLAYER_FIELDS = gql`
    fragment PlayerFields on PlayerType {
        playerId
        fullName
        firstName
        lastName
        middleName
        dateOfBirth
        gender
        licenceNr
        footed
        position
        phoneNumber
        email
        addressLine
        status
        registrationDate
        firstVisitDate
        photo
        photoUploadDate

        passportNumber
        passportDateOfIssue
        passportDateOfExpiry
        countryOfBirth
        cityOfBirth
        citizenship
        personalCode

        links {
            type
            name
            url
        }

        parents {
            id
            relationType
            fullName
            firstName
            lastName
            email
            phoneNumber
        }
    }
`;


export const GET_PLAYER = gql`
    ${PLAYER_FIELDS}
    query GetPlayer($playerId: Uuid!, $clubId: String!) {
        players(
            clubId: $clubId
            where: {
                playerId: { eq: $playerId }
            }
        ) {
            totalCount
            items {
                ...PlayerFields

                statistic {
                    matchesPlayed
                    minutesPlayed
                    minutesPlayedAverage
                    goals
                    assisted
                    yellowCards
                    redCards
                }

                teams {
                    teamNumber
                    position
                    group
                    team {
                        name
                        trainers {
                            fullName
                        }
                    }
                }

                healthCheckNextDate
                healthCondition

                bodyMetrics {
                    metricId
                    name
                    order
                    value
                }

                skills {
                    name
                    summary(playerId:$playerId)
                }
            }
        }
    }
`;
export const usePlayer = (variables) => useQuery(GET_PLAYER, {variables});
export const usePlayerLazy = () => useLazyQuery(GET_PLAYER, {fetchPolicy: 'network-only'});

export const GET_ARCHIVED_PLAYERS = gql`
    query GetArchivedPlayers($clubId: String!, $skip: Int) {
        archivedPlayers(clubId: $clubId, order: { archive: DESC }, take: 100, skip: $skip) {
            totalCount
            items {
                playerId
                fullName
                dateOfBirth
                phoneNumber
                email
            }
        }
    }`;
export const useArchivedPlayers = (variables) => useQuery(GET_ARCHIVED_PLAYERS, {variables});

export const ARCHIVE_PLAYER = gql`
    mutation ArchivePlayer($input: ArchiveInput!) {
        archivePlayer(input:$input)
    }`;
export const useArchivePlayer = () => useMutation(ARCHIVE_PLAYER, {refetchQueries: ['GET_ARCHIVED_PLAYERS']});


export const RESTORE_PLAYER = gql`
    mutation RestorePlayer($input: RestorePlayerInput!) {
        restorePlayer(input: $input)
    }`;
export const useRestorePlayer = () => useMutation(RESTORE_PLAYER);

export const SAVE_PLAYER = gql`
    mutation SavePlayer($input: PlayerInput!) {
        savePlayer(input: $input) {
            playerId
        }
    }`;
export const useSavePlayer = () => useMutation(SAVE_PLAYER);

export const GET_PLAYER_CAREER = gql`
    query PlayerCareer($playerId: Uuid!) {
        playerCareer(playerId: $playerId) {
            teamMemberId
            playerName
            clubName
            teamName
            trainers
            startDate
            endDate
            position
            notes
        }
    }`;
export const usePlayerCareer = () => useLazyQuery(GET_PLAYER_CAREER);


export const GET_PLAYER_COMMUNICATION = gql`
    query PlayerCommunication($playerId: Uuid!) {
        playerCommunication(playerId: $playerId) {
            playerCommunicationId
            date
            status
            nextAction
        }
    }`;
export const usePlayerCommunication = () => useLazyQuery(GET_PLAYER_COMMUNICATION);


const GET_PLAYER_VIDEOS = gql`
    query GetPlayerVideos($input: VideoFilterInput!) {
        videos(input: $input, take: 20) {
            totalCount
            items {
                videoId
                title:name
                link
            }
        }
    }
`;
export const usePlayerVideos = () => useLazyQuery(GET_PLAYER_VIDEOS);



const GET_SKILLS_AND_CATEGORIES = gql`
    query GetSkillsAndCategories($clubId: String) {
        skillCategories(clubId: $clubId) {
            id
            name
            isSystem
            isGoalkeeper
            isText
            showInClub
            order
            skills (clubId: $clubId) {
                skillId
                name
                skillCategoryId
                order
                showInClub
                isSystem
            }
        }
    }
`;

export const useSkillsAndCategories = (variables) => useQuery(GET_SKILLS_AND_CATEGORIES, {variables});


const SAVE_SKILL_CATEGORY = gql`
    mutation SaveSkillCategory($input: SkillCategoryInput!) {
        saveSkillCategory(input: $input)
    }`;
export const useSaveSkillCategory = () => useMutation(SAVE_SKILL_CATEGORY);


const DELETE_SKILL_CATEGORY = gql`
    mutation DeleteSkillCategory($input: DeleteInput!) {
        deleteSkillCategory(input: $input)
    }`;
export const useDeleteSkillCategory = () => useMutation(DELETE_SKILL_CATEGORY);


const SAVE_SKILL = gql`
    mutation SaveSkill($input: SkillInput!) {
        saveSkill(input: $input)
    }`;
export const useSaveSkill = () => useMutation(SAVE_SKILL);

const DELETE_SKILL = gql`
    mutation DeleteSkill($input: DeleteSkillInput!) {
        deleteSkill(input: $input)
    }`;
export const useDeleteSkill = () => useMutation(DELETE_SKILL);


const GET_PLAYER_SKILLS = gql`
    query GetPlayerSkills($clubId: String!, $playerId: Uuid!) {
        playerSkillCategories(clubId: $clubId, playerId:$playerId, where: {showInClub: {eq: true}}) {
            id
            name
            isSystem
            isText
            skills (clubId: $clubId, where: {showInClub: {eq: true}}) {
                skillId
                name
                isSystem
                lastValue(playerId: $playerId)
                prevValue(playerId: $playerId)
                values(playerId: $playerId) {
                    skillId
                    date
                    value
                    comment
                }
            }
        }
    }
`;

export const usePlayerSkills = (variables) => useQuery(GET_PLAYER_SKILLS, {variables});


const SAVE_PLAYER_SKILL = gql`
    mutation SavePlayerSkill($input: PlayerSkillInput!) {
        savePlayerSkill(input: $input)
    }`;
export const useSavePlayerSkill = () => useMutation(SAVE_PLAYER_SKILL);


const SAVE_PLAYER_ANTHROPOMETRY = gql`
    mutation SavePlayerAnthropometry($input: AnthropometryInput!) {
        savePlayerAnthropometry(input: $input)
    }`;
export const useSavePlayerAnthropometry = () => useMutation(SAVE_PLAYER_ANTHROPOMETRY);


const SAVE_PLAYER_HEALTH_CHECK = gql`
    mutation SavePlayerHealthCheck($input: HealthCheckInput!) {
        savePlayerHealthCheck(input: $input)
    }`;
export const useSavePlayerHealthCheck = () => useMutation(SAVE_PLAYER_HEALTH_CHECK);


const SAVE_PLAYER_HEALTH_CONDITIONS = gql`
    mutation SavePlayerHealthConditions($input: HealthConditionsInput!) {
        savePlayerHealthConditions(input: $input)
    }`;
export const useSavePlayerHealthConditions = () => useMutation(SAVE_PLAYER_HEALTH_CONDITIONS);


const GET_PLAYER_HEALTH_CHECK_HISTORY = gql`
    query GetPlayerHealthCheckHistory($playerId: Uuid!) {
        playerHealthCheckHistory(playerId: $playerId) {
            playerHealthCheckId
            healthCheckDate
            isHealthCheckPassed
            healthCheckNextDate
            comment
        }
    }
`;

export const usePlayerHealthCheckHistory = (variables) => useQuery(GET_PLAYER_HEALTH_CHECK_HISTORY, {variables, fetchPolicy: 'network-only'});


const DELETE_PLAYER_HEALTH_CHECK = gql`
    mutation DeletePlayerHealthCheck($input: DeleteInput!) {
        deletePlayerHealthCheck(input: $input)
    }`;
export const useDeletePlayerHealthCheck = () => useMutation(DELETE_PLAYER_HEALTH_CHECK);


const GET_PLAYER_HEALTH_CONDITION_HISTORY = gql`
    query GetPlayerHealthConditionHistory($playerId: Uuid!) {
        playerHealthConditionHistory(playerId: $playerId) {
            playerHealthConditionId
            date
            healthCondition
            severityOfInjury
            positionX
            positionY
            notes
        }
    }
`;

export const usePlayerHealthConditionHistory = (variables) => useQuery(GET_PLAYER_HEALTH_CONDITION_HISTORY, {variables, fetchPolicy: 'network-only'});


const DELETE_PLAYER_HEALTH_CONDITION_HISTORY = gql`
    mutation DeletePlayerHealthConditions($input: DeleteInput!) {
        deletePlayerHealthConditions(input: $input)
    }`;
export const useDeletePlayerHealthConditions = () => useMutation(DELETE_PLAYER_HEALTH_CONDITION_HISTORY);

const GET_PLAYER_ANTHROPOMETRIC_HISTORY = gql`
    query GetPlayerAnthropometricHistory($playerId: Uuid!, $metricId: Int!) {
        playerAnthropometricHistory(playerId: $playerId, metricId: $metricId) {
            label
            value
        }
    }
`;

export const usePlayerAnthropometricHistory = (variables) => useQuery(GET_PLAYER_ANTHROPOMETRIC_HISTORY, {variables, fetchPolicy: 'network-only'});

