import React, {useEffect, useState} from 'react';
import {notHaveErrors, validateFields, validateForm} from 'utils/form';
import * as yup from 'yup';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import {createGuid} from 'utils/utils';
import {useSaveDataEventRecord} from 'apollo/queries/GameQueries';
import Select from 'components/UI/Select/Select';
import TimePicker3 from 'components/UI/DatePicker/TimePicker3';

const CardForm = (props) => {
    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);
    const [values, setValues] = useState(props.values);
    const [errors, setErrors] = useState({});

    const [saveDataEventRecord] = useSaveDataEventRecord();

    useEffect(() => {
        if (values?.result === undefined && props.event?.haveSwitch === true)
            setValues({result: true});
    }, []);

    const fieldChanged = (event) => {
        const newValues = { ...values };
        newValues[event.target.name] = event.target.value

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });
        setValues(newValues);
    }

    const validationRules = {
        minutes: yup.string().required(t("Require"))
    }

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors
    };

    const formSubmitted = (e) => {
        e.preventDefault();
        validateForm(values, validationRules, (errors) => {
            if (notHaveErrors(errors))
                saveData();
            else
                setErrors(errors);
        });
    }

    const saveData = () => {
        const variables = {
            input: {
                id: values?.id ?? createGuid(),
                clubId: navState.clubId,
                teamId: navState.teamId,
                gameId: props.gameId,
                eventType: "TEAM_EVENT",
                gameDataEventId: props.eventId,
                playerId: props.playerId,
                time: + values.minutes,
                duration: 10,
                result: values.result,
            }
        };

        saveDataEventRecord({variables: variables}).then(result => {
            props.onComplete();
        });
    }

    return (
        <>
            <form id={props.id} onSubmit={formSubmitted}>
                <TimePicker3
                    name="minutes"
                    label={t('Minutes')}
                    format="m.ss"
                    isSportTimer={true}
                    {...regField} />

                {props.event?.haveSwitch === true &&
                    <Select
                    name="result"
                    label={t('Result')}
                    options={props.result}
                    {...regField} /> }
            </form>
        </>
    );
};

CardForm.defaultProps = {
    values: {},
    event: {},
    onComplete: () => {},
    result: [
        {label: "Positive", value: true},
        {label: "Negative", value: false}
    ]
};

export default CardForm;
