import React from 'react';
import {useTranslation} from 'react-i18next';
import Button from 'components/UI/Button/Button';
import Panel from 'components/UI/Panel/Panel';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import UserGroupForm from 'containers/SysAdmin/ClubGroups/forms/UserGroupForm';
import classes from 'containers/SysAdmin/ClubGroups/ClubGroup.module.scss';
import Subtext from 'components/UI/Table/Subtext/Subtext';
import Table from 'components/UI/Table/Table';
import {useDeleteUserFromGroup} from 'apollo/queries/GroupQueries';
import AccessForm from 'containers/Club/Access/forms/AccessForm';
import RemoveAccessForm from 'containers/Club/Access/forms/RemoveAccessForm';
import SwitchComponents from 'components/System/SwitchComponents';
import cn from 'classnames';
import {useParams} from 'react-router-dom';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';

const ClubGroupUsers = (props) => {
	const {data, refetch} = props;
	const {t} = useTranslation();
	const {groupId} = useParams();

	const [modalData, setModalData, initState] = ModalState();

	const [deleteUserFromGroup] = useDeleteUserFromGroup();

	const actions = () => {
		return (
			<Button type="primary" style={{margin: 0}} onClick={onAddClick}>
				<span><i className="icon-Plus-Square"></i> {t('Add user')}</span>
			</Button>
		);
	};

	const onAddClick = () => {
    const _data = {
      ...initState,
      title: t("Add user"),
      form: "user",
      onComplete: onComplete,
      open: true
    }

    setModalData(_data);
	};

  const onComplete = async () => {
    setModalData(initState);
    await refetch();
  }

	const onEditUser = (user) => {
		const _data = {
			...initState,
			title: t("Edit user"),
			form: "user",
			defaultValues: {
				...user
			},
			onComplete: onComplete,
			open: true
		}

		setModalData(_data);
	}

	const onSettings = () => {
		return [
			{label: 'Edit user', id: '1', action: onEditUser},
			{label: 'Delete from group', id: '2', action: onDeleteUser},
		];
	};

	const onDeleteUser = async (user) => {
		console.log(user);
		await deleteUserFromGroup({variables: {input: {groupId: data.group.groupId, groupUserIds: [user.id]}}});
		await refetch();
	}

	const onInvite = (data) => {
		const _data = {
			...initState,
			title: t("Send invite"),
			form: "invite",
			defaultValues: {
				id: data.id,
				name: data.fullName,
				email: data.email ?? "",
				roles: data.roles.map(x => x.roleId),
				groupId: groupId
			},
			onComplete: onComplete,
			open: true
		}

		setModalData(_data);
	}

	const onReInvite = (data) => {
		const _data = {
			...initState,
			title: t("Send re-invite"),
			form: "invite",
			defaultValues: {
				id: data.id,
				name: data.fullName,
				email: data.email ?? "",
				roles: data.roles.map(x => x.roleId),
				groupId: groupId
			},
			onComplete: onComplete,
			open: true
		}

		setModalData(_data);
	}

	const onRemove = (data) => {
		const modal = {
			...initState,
			form: "remove",
			defaultValues: {
				id: data.id,
				name: data.fullName,
				email: data.email,
				roles: data.roles.map(x => x.roleId),
				groupId: groupId
			},
			buttons: {
				saveButtonText: "Remove"
			},
			title: t("Remove access"),
			onComplete: onComplete,
			open: true,
		};

		setModalData(modal);
	}

	return (
		<>
			<h3>{t('Users')}</h3>
			<Panel border={false} action={actions} />

			<Table
				data={data.group?.users ?? []}
				access={[SystemRoles.SysAdmin, SystemRoles.GroupManger, SystemRoles.ClubAdmin]}
				columns={[
					{
						Header: t('Name'),
						accessor: 'fullName',
						Cell: (cellData) => <div style={{cursor: 'pointer'}}>
							{cellData.value}
							<Subtext>{t("Role")}: {cellData.row.original.roles.map((x,index) => (
								<span key={`${cellData.row.original.id}-${index}`} >{index ? ", " : undefined} {t(x.name)}</span>
							))}</Subtext>
						</div>
					},
					{
						Header: t('Email'),
						accessor: 'email',
						className: classes.hide,
						Cell: (cellData) => <div>{cellData.value}</div>
					}, {

						Header: t('Action'),
						accessor: 'invite',
						className: cn(classes.hide, classes.buttons),
						//Cell: (cellData) => <div>{cellData.value}</div>
						Cell: (cellData) => {
							return (
								<>
									{cellData.value === "NONE" ? <Button type="primary"
									                             onClick={() => onInvite(cellData.row.original)} style={{width: "100px"}}>{t("Invite")}</Button> : undefined}

									{cellData.value === "INVITED" || cellData.value === "REMOVED"  ? <Button type="warning"
									                                                         onClick={() => onReInvite(cellData.row.original)} style={{width: "100px"}}>{t("Re-invite")}</Button> : undefined}

									{cellData.value === "ACTIVE" ? <Button type="danger"
									                               onClick={() => onRemove(cellData.row.original)} style={{width: "100px"}}>{t("Remove")}</Button> : undefined}
								</>
							);
						}
					}
				]}
				onSettings={onSettings}
				onRowClick={() => {}}
			/>

      <ModalWindow2 {...modalData}>
	      <SwitchComponents active={modalData.form}>
          <UserGroupForm {...modalData} name="user" />
		      <AccessForm {...modalData} name="invite" />
		      <RemoveAccessForm {...modalData} name="remove" />
	      </SwitchComponents>
      </ModalWindow2>
		</>
	);
};

ClubGroupUsers.defaultProps = {};

export default ClubGroupUsers;
