import React, {useEffect} from 'react';
import classes from './TestForm.module.scss';
import Input from '../../../../components/UI/Input/Input';

const TestPageTwo = (props) => {
    const {values, setValues, regField, ages} = props;

    return (
        <>
            <div className={classes.headerGroup}>
                <div></div>
                <div>Male</div>
                <div>Female</div>
            </div>
            <div className={classes.header}>
                <div>Age</div>
                <div>Good</div>
                <div>Well</div>
                <div>Very well</div>
                <div>Good</div>
                <div>Well</div>
                <div>Very well</div>
            </div>

            {ages.map(x => (
                <div key={`row-${x}`} className={classes.row}>
                    <span>{x}</span>
                    <Input
                        name={`value${x}`}
                        type="number"
                        disabled={values.isSystem}
                        {...regField}
                    />
                    <Input
                        name={`valueWell${x}`}
                        type="number"
                        disabled={values.isSystem}
                        {...regField}
                    />
                    <Input
                        name={`valueVeryWell${x}`}
                        type="number"
                        disabled={values.isSystem}
                        {...regField}
                    />
                    <Input
                        name={`altValue${x}`}
                        type="number"
                        disabled={values.isSystem}
                        {...regField}
                    />
                    <Input
                        name={`altValueWell${x}`}
                        type="number"
                        disabled={values.isSystem}
                        {...regField}
                    />
                    <Input
                        name={`altValueVeryWell${x}`}
                        type="number"
                        disabled={values.isSystem}
                        {...regField}
                    />
                </div>
            ))}
        </>
    );
};

TestPageTwo.defaultProps = {};

export default TestPageTwo;
