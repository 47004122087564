import React, {PropTypes, useEffect, useState} from 'react';
import classes from '../Settings.module.scss';
import {useTranslation} from 'react-i18next';
import {useVideoTtdEvents} from '../../../apollo/queries/GameQueries';
import MatchesDetailsVideoDataCategories from '../../Team/MatchesDetails/MatchesDetailsVideoDataCategories';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from '../../../apollo/cashe';
import SettingsSubmenu from '../../../components/UI/SettingsSubmenu/SettingsSubmenu';
import MatchesDetailsVideoDataEvents from '../../Team/MatchesDetails/MatchesDetailsVideoDataEvents';

const Video = (props) => {
    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const [tab, setTab] = useState(null);

    const {loading, error, data, refetch} = useVideoTtdEvents({clubId: navState.clubId});

    useEffect(() => {
        setTab(null);
    }, [props.rerender]);

    if (loading) {
        return (<div>{t("Loading")}</div>);
    }

    if (error) {
        return (<div>{t("Error")}</div>);
    }

    if (tab === "categories") {
        return (<MatchesDetailsVideoDataCategories onBack={() => setTab(null)} data={data} refetch={refetch}/>);
    }

    if (tab === "highlights") {
        return (<MatchesDetailsVideoDataEvents onBack={() => setTab(null)} data={data} refetch={refetch}/>);
    }

    return (<>
        <div onClick={props.onBack} className={[classes.title, classes.link].join(" ")}>
            <i className={["icon-Arrow---Up", classes.backButton, "backArrow"].join(" ")}></i>
            {t("Video & TTP")}
        </div>

        <SettingsSubmenu menu={props.tabs} onClick={x => setTab(x)} />
    </>);
};

Video.propTypes = {};
Video.defaultProps = {
    tabs: [
        {id: "categories", name: "Categories"},
        {id: "highlights", name: "Highlights"},
    ],
};

export default Video;
