import React, {useState} from 'react';
import {useClubs} from 'apollo/queries/ClubQueries';
import ScrollList from 'components/UI/ScrollList/ScrollList';
import Input from 'components/UI/Input/Input';
import {useTranslation} from 'react-i18next';
import {getClubFormat} from 'containers/SysAdmin/ClubList/ClubFormats';

const ClubListForm = (props) => {
    const {setLoading, onAddClubs, existedClubsIds} = props;

    const {t} = useTranslation();

    const [addClubs, setAddClubs] = useState([]);
    const [values, setValues] = useState({search: ''});

    const {data, loading, error} = useClubs({where: {name: {contains: values.search}}});

    const fieldChanged = (event) => {
        const newValues = { ...values };
        newValues[event.target.name] = event.target.value;

        setValues(newValues);
    }

    const regField = {
        value: values,
        onChange: fieldChanged,
    };

    const onClubChange = (club) => {
        const clubs = [...addClubs];
        const index = clubs.findIndex(f => f.clubId === club.clubId);
        index === -1 ? clubs.push(club) : clubs.splice(index,1);
        setAddClubs(clubs);
    }

    const formSubmitted = (e) => {
        e.preventDefault();
        setLoading(true);
        onAddClubs(addClubs);
    }

    return (
      <>
          <form id={props.id} onSubmit={formSubmitted}>
              <Input
                name="search"
                label={t('Club name')}
                {...regField}
              />

              <ScrollList>
                  {loading && (<li>Loading...</li>)}
                  {error && (<li>Error...</li>)}
                  {data?.clubs && data.clubs.map((club) =>
                    <li key={club.clubId}>
                        <input type="checkbox" onChange={() => onClubChange(club)}
                               checked={existedClubsIds.indexOf(club.clubId) !== -1 || addClubs.find(f => f.clubId === club.clubId)  !== undefined}
                               disabled={existedClubsIds.indexOf(club.clubId) !== -1}
                               style={{position: 'relative', top: '1px', marginRight: '5px' }} />
                            {club.name} ({t(getClubFormat(club.clubFormat))}, {club.country})
                    </li>)}
              </ScrollList>
          </form>
      </>
    );
};

ClubListForm.defaultProps = {
    existedClubsIds: [],
};

export default ClubListForm;
