import React from 'react';
import {useReactiveVar} from '@apollo/client';
import {systemDataVar} from 'apollo/cashe';
import {showDate} from 'utils/date';

const ShowDate = (props) => {
	const {date} = props;
	const systemData = useReactiveVar(systemDataVar);

  return (
      <>{showDate(date, systemData.settings.general)}</>
  );
};

ShowDate.defaultProps = {
	date: new Date()
};

export default ShowDate;
