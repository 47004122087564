import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import {useSaveMetric} from 'apollo/queries/ExerciseQueries';
import {notHaveErrors, validateFields, validateForm} from 'utils/form';
import * as yup from 'yup';
import classes from './ExerciseBasicForm.module.scss';
import cn from 'classnames';
import Button from 'components/UI/Button/Button';
import Input from 'components/UI/Input/Input';
import Select from 'components/UI/Select/Select';
import Textarea from 'components/UI/Textarea/Textarea';
import Spinner from 'components/UI/Spinner/Spinner';
import {metricSettings} from '../../../Club/Normatives/Settings';

const TestBasicForm = (props) => {
    const {metricId, defaultData, edit, setEdit, onChange} = props;

    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState(defaultData);
    const [errors, setErrors] = useState({});

    const [saveMetric] = useSaveMetric();

    const fieldChanged = (event) => {
        const newValues = {...values};
        newValues[event.target.name] = event.target.value;

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });

        setValues(newValues);
    };

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors,
        disabled: edit !== metricId
    };

    const onSave = (e) => {
        e.preventDefault();
        setLoading(true);
        validateForm(values, validationRules, (errors) => {
            if (notHaveErrors(errors)) {
                saveData();
            } else {
                setErrors(errors);
                setLoading(false);
            }
        });
    };

    const validationRules = {
        name: yup.string().required(t('required')),
        unitOfMeasure: yup.string().required(t('required')),
        systemOfMeasurement: yup.string().required(t('required')),
        attempts: yup.number().required(t('required')),
    };

    const saveData = () => {
        onChange(values);

        setEdit(null);
        setLoading(false);

        if (!values.isNew)
            return;

        //metricId - INT
        const variables = {
            input: {
                clubId: navState.clubId,
                name: values.name,
                unitOfMeasure: values.unitOfMeasure,
                systemOfMeasurement: values.systemOfMeasurement,
                description: values.description,
                attempts: +values.attempts,
                metricCategoryId: '24C21FBC-DEA8-4246-9F86-1189FB338A0E',
                showInClub: true,
                order: 0,
                norms: []
            }
        };

        saveMetric({variables: variables}).then(() => {
            //toastMessage(true, t("Test added to library"));
        });
    };

    const onCancel = (e) => {
        setValues(defaultData);

        if (defaultData.name === undefined && defaultData.isNew)
            onDelete(e);

        setEdit(null);
    }

    const onDelete = (e) => {
        e.preventDefault();
        props.onDelete(metricId, e);
    }

    const image = () => {
        return values.image ? values.image :
            values.scheme?.image ? values.scheme?.image : "/assets/images/exercises/no-image.jpg";
    }

    return (
        <>
            <div className={classes.basicForm}>
                <div className={cn(classes.left, {[classes.schemeBg]: !values.image && values.scheme?.image})}>
                    <img className={classes.image} src={image()}
                         style={!values.image ? {backgroundImage: `url(${values.scheme?.background})`}: undefined} alt=""/>

                    {edit !== metricId &&
                        <Button type="second" disabled={edit !== null} className={classes.edit} onClick={() => setEdit(metricId)}>
                            {t("Edit test")}
                        </Button>}

                </div>
                <div className={classes.right}>
                    <Input
                        name="name"
                        label={t('Test name')}
                        placeholder={t('Ex. ball control')}
                        {...regField} />

                    <Select
                        name="unitOfMeasure"
                        label={t('Measurement unit')}
                        options={metricSettings.unitOfMeasure}
                        {...regField} />

                    <Select
                        name="systemOfMeasurement"
                        label={t('Measurement system')}
                        options={metricSettings.systemOfMeasurement}
                        {...regField} />

                    <Textarea
                        name="description"
                        label={t('Description')}
                        placeholder={t('Description of test')}
                        {...regField} />

                    <Input
                        name="attempts"
                        type="number"
                        placeholder={t('E.g 2')}
                        label={t('Attempts')}
                        {...regField}
                    />

                </div>
                {edit !== metricId && <>
                    <div className={cn(classes.footer, classes.delete)}></div>
                    <div className={cn(classes.footer, classes.footerSave)}>
                        <Button type="second" disabled={edit !== null} className={classes.edit} onClick={() => setEdit(metricId)}>
                            {t("Edit test")}
                        </Button>
                    </div>
                </>
                }

                {edit === metricId && <>
                    <div className={cn(classes.footer, classes.delete)}>
                        <Button type="secondary" disabled={loading} onClick={onDelete}>{t("Delete")}</Button>
                    </div>
                    <div className={cn(classes.footer, classes.footerSave)}>
                        <Button type="primary" disabled={loading} onClick={onSave}>
                            {props.loading ? <Spinner/> : t('Save')}</Button>
                        <Button type="secondary" disabled={loading} onClick={onCancel}>{t("Cancel")}</Button>
                    </div>
                </>}
            </div>
        </>
    );
};

TestBasicForm.defaultProps = {
    page: 0,
    level: [],
    language: [],
    accessType: [],
    onSubmit: () => {},
    onDelete: () => {},
    editMode: false,
    exerciseId: 0,
};

export default TestBasicForm;
