import React from 'react';
import classes from '../Settings.module.scss';
import Panel from '../../../components/UI/Panel/Panel';
import ModalWindow2, {ModalState} from '../../../components/UI/ModalWindow/ModalWindow2';
import SkillForm from './forms/SkillForm';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from '../../../apollo/cashe';
import Button from '../../../components/UI/Button/Button';
import Subtext from '../../../components/UI/Table/Subtext/Subtext';
import {useDeleteSkill, useDeleteSkillCategory} from '../../../apollo/queries/PlayerQueries';
import SettingsTitle from 'components/UI/SettingsTitle/SettingsTitle';

const SkillList = (props) => {

    const {skillCategories} = props.data;

    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const [modalData, setModalData, initState] = ModalState();
    const [deleteSkill] = useDeleteSkill();

    const addActivity = () => (
        <Button type="primary" style={{margin: 0}} onClick={onAddEvent}>
            <span><i className="icon-Plus-Square"></i> {t('Add skill')}</span>
        </Button>
    );

    const onAddEvent = () => {
        const _data = {
            ...initState,
            title: t("Add skill"),
            onComplete: onComplete,
            categories: skillCategories,
            open: true
        }

        setModalData(_data);
    };

    const onComplete = () => {
        props.refetch();
        setModalData(initState);
    }

    const onEventClick = (data) => {
        const {skillId, name, skillCategoryId, order, showInClub, isSystem} = data;

        const _data = {
            ...initState,
            title: t("Edit skill category"),
            defaultValues: {
                skillId: skillId,
                name: name,
                categoryId: skillCategoryId,
                order: order,
                showInClub: showInClub,
                isSystem: isSystem
            },
            categories: skillCategories,
            onComplete: onComplete,
            onDelete: isSystem ? undefined : () => onDelete(skillId),
            open: true
        }

        setModalData(_data);
    }

    const onDelete = (id) => {

        const variables = {
            input: {
                id: id,
                clubId: navState.clubId,
            }
        };

        deleteSkill({variables: variables}).then((result) => {
            if (result) props.refetch();
            setModalData(initState);
        });
    }

    const printEvent = (skill) => {
        return (<div key={`skill-${skill.skillId}`} onClick={() => onEventClick(skill)}  className={classes.eventItem}>
            <div>
                {skill.isSystem ? t(skill.name) : skill.name}
                <Subtext>{t('Type')}: {skill.isSystem ? t('system') : t('editable')}</Subtext>
            </div>
            <div>{skill.showInClub ? t('Yes') : t('No')}</div>
            <div>{skill.order}</div>
        </div>);
    }

    return (
        <>
            <SettingsTitle title={t("Skills")} onBack={props.onBack} />

            <Panel border={false} action={addActivity}>
                <br/>
            </Panel>

            {skillCategories.map(category => {
                return (
                    <div key={category.id} className={classes.categoryTitle}>
                        <h3>{category.isSystem ? t(category.name) : category.name}</h3>
                        <div className={classes.eventTitles}>
                            <div>{t('Skill')}</div>
                            <div>{t('Enabled')}</div>
                            <div>{t('Order')}</div>
                        </div>
                        <div className={classes.eventGroup}>

                            {category.skills.length !== 0
                                ? category.skills.map(event => printEvent(event))
                                : <div className={classes.empty}>{t("No data to display.")}</div>
                            }
                        </div>
                    </div>
                );
            })}

            <ModalWindow2 {...modalData}>
                <SkillForm {...modalData} />
            </ModalWindow2>
        </>
    );
};

SkillList.defaultProps = {};

export default SkillList;
