import React, {useCallback, useEffect, useState} from 'react';
import Modal from 'react-modal';
import classes from './ModalWindow.module.scss';
import Button from '../Button/Button';
import {useTranslation} from 'react-i18next';
import Spinner from '../Spinner/Spinner';
import cn from 'classnames';
import {useReactiveVar} from '@apollo/client';
import {systemDataVar} from 'apollo/cashe';

const ModalWindow = (props) => {
    const {id, open, title, children, pages, styles, access, buttons, setModal, onDelete, onClose } = props;

    const {t} = useTranslation();
    const systemData = useReactiveVar(systemDataVar);

    const [selected, setSelected] = useState(pages.page);
    const [isLoading, setIsLoading] = useState(false);

    const height = styles.minHeight || styles.maxHeight ? {minHeight: styles.minHeight, maxHeight: styles.maxHeight} : {}
    const width = styles.maxWidth ? {content: {maxWidth: styles.maxWidth, maxHeight: window.innerHeight}} : {};

    useEffect(() => {
        setIsLoading(false);
    }, [open]);

    useEffect(() => {
        setSelected(pages.page);
    }, [pages.page]);

    const onPrevClick = () => {
        const page = selected - 1;
        setModal({...props, pages: { ...props.pages, page: page}});
    }

    const onNextClick = () => {
        const page = selected + 1;
        setModal({...props, pages: { ...props.pages, page: page}});
    }

    const onCloseClick = () => {
        setModal({...props, open: false});
        onClose();
    }

    const setLoading = (v) => {
        setIsLoading(v);
    }

    const childrenWithProps = React.Children.map(children, child => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { setLoading });
        }
        return child;
    });

    if (access && access.includes(systemData.user.userRole.roleId) === false) {
        return (<></>);
    }

    if (!open) return (<></>);

    return (
        <Modal
            portalClassName={cn("ReactModalPortal", styles.theme)}
            className={classes.modal}
            overlayClassName={classes.overlay}
            isOpen={open}
            ariaHideApp={false}
            onRequestClose={onCloseClick}
            shouldCloseOnOverlayClick={false}
            style={width}
        >
            <div className={classes.header}>
                {title} <Button disabled={isLoading} type="third"
                                      className={classes.closeButton} onClick={onCloseClick}>x</Button>
            </div>
            <div className={cn(classes.body, {[classes.noPadding]: styles.noPadding})} style={height}>
                {childrenWithProps}
            </div>
            <div className={classes.footer}>
                <div className={classes.footerButtons}>
                    <div>
                        {
                            onDelete !== undefined
                                ? <Button disabled={isLoading} onClick={onDelete}
                                          type="third">{t('Delete')}</Button>
                                : null
                        }
                    </div>
                    <div className={classes.right}>
                        {pages.maxPages > 0 && selected > 0 &&
                        <Button disabled={isLoading} type="second" style={{marginTop:0}}
                                onClick={onPrevClick}>{t('Prev')}</Button>}

                        {pages.maxPages > 0 && selected !== (pages.maxPages - 1) &&
                        <Button disabled={isLoading} type="second" style={{marginTop:0}}
                                onClick={onNextClick}>{t('Next')}</Button>}

                        {pages.maxPages > 0 && selected === (pages.maxPages - 1) && !buttons.hideSaveButton &&
                        <Button disabled={isLoading} form={id} type="primary" submit={true}
                                style={{marginTop: 0}}>
                            {isLoading ? <Spinner/> : buttons.saveButtonText ?? t('Save')}
                        </Button>}

                        {!buttons.hideCancelButton && <Button disabled={isLoading} type="third"
                                 onClick={onCloseClick}>{t("Close")}</Button>}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

ModalWindow.defaultProps = {
    open: false,
    title: "Modal window",
    pages: {
        page: 0,
        maxPages: 1,
    },
    styles: {
        theme: "default",
        minHeight: undefined,
        maxHeight: undefined,
        maxWidth: undefined,
        noPadding: false,
    },
    buttons: {
        saveButtonText: undefined,
        hideSaveButton: false,
        hideCancelButton: false,
    },
    setModal: () => {},
    onPageChange: () => {},
    onClose: () => {},
    onDelete: undefined,
};

export default ModalWindow;

export const ModalState = (value) => {

    const setModal = (newValue) => {
        _setModal({...newValue});
    }

    const initState = {
        ...value,
        open: false,
        id: `form-${Math.random()}`,
        setModal: setModal,
    };

    const [modal, _setModal] = useState(initState);


    return [modal, setModal, initState];
};
