import React, {useState} from 'react';
import Input from 'components/UI/Input/Input';
import {useTranslation} from 'react-i18next';
import {notHaveErrors, validateFields, validateForm} from 'utils/form';
import classes from './ExerciseBasicForm.module.scss';
import cn from 'classnames';
import Button from 'components/UI/Button/Button';
import Textarea from 'components/UI/Textarea/Textarea';
import Select from 'components/UI/Select/Select';
import * as yup from 'yup';
import Spinner from 'components/UI/Spinner/Spinner';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import Toast, {toastMessage} from 'components/UI/Toast/Toast';
import {useSaveExercise} from 'apollo/queries/ExerciseQueries';

const ExerciseBasicForm = (props) => {
    const {exerciseId, defaultData, edit, setEdit, onChange} = props;

    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState(defaultData);
    const [errors, setErrors] = useState({});

    const [saveExercise] = useSaveExercise(navState.clubId);

    const fieldChanged = (event) => {
        const newValues = {...values};
        newValues[event.target.name] = event.target.value;

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });

        setValues(newValues);
    };

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors,
        disabled: edit !== exerciseId
    };

    const onSave = (e) => {
        e.preventDefault();
        setLoading(true);
        validateForm(values, validationRules, (errors) => {
            if (notHaveErrors(errors)) {
                saveData();
            } else {
                setErrors(errors);
                setLoading(false);
            }
        });
    };

    const validationRules = {
        name: yup.string().required(t('required')),
        duration: yup.string().required(t('required')),
        ageGroup: yup.string().required(t('required')),
        level: yup.string().required(t('required')),
        numberOfPlayers: yup.string().required(t('required')),
        language: yup.string().required(t('required')),
        accessType: yup.string().required(t('required')),
    };

    const saveData = () => {
        onChange(values);

        setEdit(null);
        setLoading(false);

        if (!values.isNew)
            return;

        const variables = {
            'input': {
                'clubId': navState.clubId,
                'name': values.name,
                'duration': values.duration,
                'repeat': values.repeat,
                'rest': values.rest,
                'rhythm': values.rhythm,
                'coefficient': values.coefficient,
                'ageGroup': values.ageGroup,
                'level': values.level,
                'numberOfPlayers': values.numberOfPlayers,
                'language': values.language,
                'categories': [],
                'tags': [],
                'description': values.description,
                'tools': values.tools,
                'accessType': values.accessType,
            }
        };

        saveExercise({variables: variables}).then(() => {
            toastMessage(true, t("Exercise added to library"));
        });
    };

    const onCancel = (e) => {
        setValues(defaultData);

        if (defaultData.name === undefined && defaultData.isNew)
            onDelete(e);

        setEdit(null);
    }

    const onDelete = (e) => {
        e.preventDefault();
        props.onDelete(exerciseId, e);
    }

    const image = () => {
        return values.image ? values.image :
            values.scheme?.image ? values.scheme?.image : "/assets/images/exercises/no-image.jpg";
    }

    return (
        <>
            <div className={classes.basicForm}>
                <div className={cn(classes.left, {[classes.schemeBg]: !values.image && values.scheme?.image})}>
                    <img className={classes.image} src={image()}
                         style={!values.image ? {backgroundImage: `url(${values.scheme?.background})`}: undefined} alt=""/>
                    <div className={classes.load}>Нагрузка: 3</div>

                    {edit !== exerciseId &&
                    <Button type="second" disabled={edit !== null} className={classes.edit} onClick={() => setEdit(exerciseId)}>
                        {t("Edit exercise")}
                    </Button>}

                </div>
                <div className={classes.right}>
                    <Input
                        name="name"
                        label={t('Exercise name')}
                        placeholder={t('Ex. attack start')}
                        {...regField} />

                    <div className={classes.filters}>
                        <Input
                            name="duration"
                            type="number"
                            {...regField}
                            label={t('Duration')}
                        />

                        <Input
                            name="repeat"
                            type="number"
                            {...regField}
                            label={t('Repeat')}
                        />

                        <Input
                            name="rest"
                            type="number"
                            {...regField}
                            label={t('Rest')}
                        />

                        <Input
                            name="rhythm"
                            {...regField}
                            type="number"
                            label={t('Rhythm')}
                        />

                        <Input
                            name="coefficient"
                            {...regField}
                            type="number"
                            label={t('Coefficient')}
                        />

                        <Select
                            name="level"
                            {...regField}
                            options={props.level.filter(x => x.value !== '')}
                            label={t('Level')}
                        />
                    </div>

                    <Textarea
                        name="description"
                        label={t('Description')}
                        placeholder={t('Description of exercise')}
                        {...regField} />

                    <Textarea
                        name="tools"
                        label={t('Items and tools')}
                        placeholder={t('E.g 3 balls, 6 vests')}
                        {...regField} />

                </div>
                    {edit !== exerciseId && <>
                            <div className={cn(classes.footer, classes.delete)}></div>
                            <div className={cn(classes.footer, classes.footerSave)}>
                            <Button type="second" disabled={edit !== null} className={classes.edit} onClick={() => setEdit(exerciseId)}>
                                {t("Edit exercise")}
                            </Button>
                            </div>
                        </>
                    }

                    {edit === exerciseId && <>
                        <div className={cn(classes.footer, classes.delete)}>
                            <Button type="secondary" disabled={loading} onClick={onDelete}>{t("Delete")}</Button>
                        </div>
                        <div className={cn(classes.footer, classes.footerSave)}>
                            <Button type="primary" disabled={loading} onClick={onSave}>
                                {props.loading ? <Spinner/> : t('Save')}</Button>
                            <Button type="secondary" disabled={loading} onClick={onCancel}>{t("Cancel")}</Button>
                        </div>
                    </>}
            </div>
        </>
    );
};

ExerciseBasicForm.defaultProps = {
    page: 0,
    level: [],
    language: [],
    accessType: [],
    onSubmit: () => {},
    onDelete: () => {},
    editMode: false,
    exerciseId: 0,
};

export default ExerciseBasicForm;
