import React, {useState, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import OrgChart from "components/UI/OrgChart/OrgChart";
import {findChildrenInObject, findParentInObject, newId} from "utils/utils";
import {orgChartDefaultData} from "./DefaultData";
import CardForm from "./forms/CardForm";
import Submenu from 'components/Navigation/Submenu/Submenu';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar, systemDataVar} from 'apollo/cashe';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import SwitchComponents from 'components/System/SwitchComponents';
import {useClubChart, useSaveClubChart} from 'apollo/queries/ClubQueries';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';

const ClubOrgChart = () => {
  const {t} = useTranslation();
  const navState = useReactiveVar(naviDataVar);

  const [tree, setTree] = useState({});

  const [modalData, setModalData, initState] = ModalState();

  const {loading, error, data, refetch} = useClubChart(navState.clubId);
  const [saveClubChart] = useSaveClubChart();

  useEffect(() => {
    if (loading || error)
      return;

    if(data?.clubChart !== null)
      setTree(JSON.parse(data.clubChart.document));
    else {
      setTree(orgChartDefaultData.tree);
    }

  },[loading, error, data])

  const saveChanges = (document) => {
    const variables = {
      "clubId": navState.clubId,
      "document": JSON.stringify(document),
    };

    saveClubChart({variables}).then((res) => {
      refetch().then(() => {});
    });
  }

  const onAddClick = (id, e) => {
    e.preventDefault();
    e.stopPropagation();

    const _data = {
      ...initState,
      title: t("Add card"),
      form: "card",
      access: [SystemRoles.SysAdmin, SystemRoles.ClubAdmin],
      defaultValues: {
        id: newId(),
        parentId: id
      },
      onComplete: onComplete,
      open: true
    };

    setModalData(_data);
  }

  const onEditClick = (id, e) => {
    e.preventDefault();

    const item = findChildrenInObject(tree.root, id);

    const _data = {
      ...initState,
      title: t("Edit card"),
      form: "card",
      access: [SystemRoles.SysAdmin, SystemRoles.ClubAdmin],
      defaultValues: {
        id: id,
        name: item.name,
        position: item.position,
        description: item.description
      },
      onComplete: onComplete,
      onDelete: () => onDelete(id),
      open: true
    };

    setModalData(_data);
  }

  const onComplete = (data) => {
    setModalData(initState);

    const newTree = {...tree};
    const item = findChildrenInObject(newTree.root, data.id);

    if (item === undefined) {
      const parent = findChildrenInObject(newTree.root, data.parentId);
      if (parent !== undefined) {
        const newItem = {
          id: data.id,
          name: data.name,
          position: data.position,
          description: data.description
        };

        if (parent["children"] === undefined) {
          parent.children = [];
        }

        parent.children.push(newItem);
      }
    } else {
      item.name = data.name;
      item.position = data.position;
      item.description = data.description;
    }

    saveChanges(newTree);
  }

  const onDelete = (id) => {
    setModalData(initState);

    const newTree = {...tree};
    const parent = findParentInObject(newTree.root, id);
    parent.children = parent.children.filter((item) => item.id !== id);

    saveChanges(newTree);
  }

  if (loading) return <div>{t('Loading...')}</div>;
  if (error) return <div>{t('Error!')}</div>;

  return (
      <>
        <div className="page-header">
          <h2>{t('Club organization chart')}</h2>
          <Submenu />
        </div>
        <div className="page-content">
          <OrgChart {...tree} onAddClick={onAddClick} onEditClick={onEditClick}/>
        </div>

        <ModalWindow2 {...modalData}>
          <SwitchComponents active={modalData.form}>
            <CardForm {...modalData} name="card" />
          </SwitchComponents>
        </ModalWindow2>
      </>
  );
};

export default ClubOrgChart;
