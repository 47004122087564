import React, {useEffect, useState} from 'react';
import classes from './TimePickerBase.module.scss';
import cn from 'classnames';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import {validateFields} from 'utils/form';
import Button from 'components/UI/Button/Button';

const TimePickerBase = (props) => {
    const {time, isOpen, onChange, onBack, onSet, withDatePicker, isSportTimer} = props;

    const {t} = useTranslation();
    const max = isSportTimer ? 99999 : 23;

    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const _time = isNaN(time) === false ? time : 0;
        const _timeArray = _time.toString().split('.');
        const _hours = _timeArray[0] ?? 0;
        const _minutes = _timeArray[1] ?? 0;
        setHours(+_hours);
        setMinutes(+_minutes);
    }, [time, isOpen]);


    useEffect(() => {
        onChange({hours: +hours, minutes: minutes});
    }, [hours, minutes, errors]);



    const addHours = (e) => {
        e.preventDefault();
        const newHours = +hours ?? 0;

        if (newHours + 1 > max) {
            return;
        }

        setHours(newHours + 1);
    }

    const minusHours = (e) => {
        e.preventDefault();
        const newHours = +hours ?? 0;

        if (newHours - 1 < 0) {
            return;
        }

        setHours(newHours - 1);
    }

    const onHoursChange = (e) => {
        const newHours = +e.target.value ?? 0;
        setHours(newHours);
        validate({hours: newHours});
    }

    const addMinutes = (e) => {
        e.preventDefault();
        const newMinutes = (+minutes ?? 0);

        if (newMinutes + 1 > 59) {
            return;
        }

        setMinutes(newMinutes + 1);
    }

    const minusMinutes = (e) => {
        e.preventDefault();
        const newMinutes = +minutes ?? 0;

        if (newMinutes - 1 < 0) {
            return;
        }

        setMinutes(newMinutes - 1);
    }

    const onMinutesChange = (e) => {
        const newMinutes = +e.target.value ?? 0;
        setMinutes(newMinutes);
        validate({minutes: newMinutes});
    }

    const validationRules = {
        hours: yup.number().required(t("Required")).min(0, 'Min value 0.').max(max, 'Max value ' + max),
        minutes: yup.number().required(t("Required")).min(0, 'Min value 0.').max(59, 'Max value 59')
    }

    const validate = (obj) => {
        validateFields(obj, validationRules, (errors) => {
            setErrors(errors);
        });
    }

    const onSelected = () => {
        onSet({hours: hours, minutes: minutes});
    }

    return (
        <div className={classes.TimePickerBase}>
            <button className={classes.button} onClick={addHours}><i className={cn(classes.arrow, classes.arrowUp)}></i></button>
            <div></div>
            <button className={classes.button} onClick={addMinutes}><i className={cn(classes.arrow, classes.arrowUp)}></i></button>
            <div className={cn({[classes.error]: errors.hours !== undefined})}>
                <input step={1} onChange={onHoursChange} key={"hours"} value={String(hours).padStart(2, '0')} type="number" />
            </div>
            <div className={classes.separator}>:</div>
            <div className={cn({[classes.error]: errors.minutes !== undefined})}>
                <input step={5} onChange={onMinutesChange} key={"minutes"} value={String(minutes).padStart(2, '0')} type="number" />
            </div>
            <button className={classes.button}  onClick={minusHours}><i className={cn(classes.arrow, classes.arrowDown)}></i></button>
            <div></div>
            <button className={classes.button} onClick={minusMinutes}><i className={cn(classes.arrow, classes.arrowDown)}></i></button>

            <hr className={classes.hr} />

            <Button style={{margin: "0px"}} type="second" onClick={onBack}>{withDatePicker ? t("Back") : t("Close")}</Button>
            <div></div>
            <Button style={{margin: "0px"}} type="primary" onClick={onSelected}>{t("Select")}</Button>
        </div>
    );
};

TimePickerBase.defaultProps = {
    isOpen: true,
    withDatePicker: false,
    isSportTimer: false,
    onChange: () => {},
    onBack: () => {},
    onSet: () => {},
    tabs: [
        { id: '1', name: '1'},
        { id: '5', name: '5'},
        { id: '15', name: '15'},
    ],
};

export default TimePickerBase;
