import React from 'react';
import {Countries, PlayerPositions} from '../../Team/MatchesDetails/Settings';
import classes from './Player.module.scss';
import {showDate} from 'utils/date';
import Button from 'components/UI/Button/Button';
import Select from 'components/UI/Select/Select';
import Card from 'components/UI/Card/Card';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import {usePlayer} from 'apollo/queries/PlayerQueries';
import NewPlayerForm from '../../Team/Team/forms/NewPlayerForm';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';
import CanAccess from 'components/Navigation/CanAccess/CanAccess';

const PlayerHeader = (props) => {
    const {teamFilter} = props;

    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const [modalData, setModalData, initState] = ModalState();

    const {loading, error, data, refetch} = usePlayer({
        clubId: navState.clubId,
        playerId: navState.playerId,
    });

    const Header = ({data}) => {
        const {fullName, photo, dateOfBirth, personalCode, licenceNr, footed, position, citizenship} = data;

        const playerPosition = PlayerPositions().find(x => x.value === position);
        const playerCountry = Countries().find(x => x.value === citizenship);

        return (
            <div className={classes.player}>
                <div className={classes.photo}>
                    <img src={photo ? photo : "/assets/images/emptyLogo.svg"} alt={fullName ? fullName : ""} />
                </div>
                <div>
                    <div className={classes.name}>{t('Player')} {/*#15*/}</div>
                    <h2>{fullName ? fullName : ""}</h2>
                    <div className={classes.position}>
                        <span>{t(playerPosition.label)}</span>
                        <span>{playerCountry?.label ? <><i className="icon-Location" style={{fontSize: "14px"}} /> {t(playerCountry.label)}</> : undefined}</span>
                    </div>
                    <div className={classes.quickInfo}>
                        <div><span>{t("Birth date")}</span>: <span>{showDate(dateOfBirth)}</span></div>
                        <div><span>{t("Personal code")}</span>: <span>{personalCode}</span></div>
                        <div><span>{t("License")}</span>: <span>{licenceNr}</span></div>
                        <div><span>{t("Preferred foot")}</span>: <span>{t(footed)}</span></div>
                    </div>
                    <ul className={classes.actions}>
                        <CanAccess users={[SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin]}>
                            <li><Button type="third" style={{marginTop: "0px"}} onClick={onEditPlayer}><i className="icon-Setting" /> {t('Edit profile')}</Button></li>
                        </CanAccess>
                        <li className={classes.team}><i className="icon-Team" /> <Select name="teamFilter" options={teamFilter} value={{teamFilter: "all"}} small="true" /></li>
                    </ul>
                </div>
            </div>
        );
    };

    const Info = ({data}) => {
        if (!data?.statistic) return (<></>);

        const {matchesPlayed, minutesPlayed, minutesPlayedAverage, goals, assisted, yellowCards, redCards}
            = data.statistic;
        const timePlayed = `${minutesPlayed} (~${minutesPlayedAverage})`;

        return (
            <div className={classes.info}>
                <Card name={t('Matches played')} value={matchesPlayed}/>
                <Card name={t('Minutes on field')} value={timePlayed}/>
                <Card name={t('Goals (G+P)')} value={goals}/>
                <Card name={t('Assisted')} value={assisted}/>
                <Card name={t('Yellow Cards')} value={yellowCards}/>
                <Card name={t('Red cards')} value={redCards}/>
            </div>
        );
    };

    const onEditPlayer = () => {
        if (!data) return;

        const _data = {
            ...initState,
            title: t("Edit player"),
            form: "player",
            pages: {
                page: 0,
                maxPages: 3,
            },
            styles: {
                maxWidth: "700px",
            },
            defaultValues: {
                ...data.players.items[0]
            },
            onComplete: onComplete,
            onDeleteClick: onDeleteClick,
            open: true
        };

        setModalData(_data);
    }

    const onComplete = () => {
        setModalData(initState);
        refetch();
    }

    const onDeleteClick = () => {

    }

    if (loading) return (<div>Loading...</div>);
    if (error) return (<div>Error...</div>);

    return (
        <>
            <Header data={data.players.items[0]}/>
            <Info data={data.players.items[0]}/>

            <ModalWindow2 {...modalData}>
                <NewPlayerForm {...modalData} name="player" />
            </ModalWindow2>
        </>
    );
};

PlayerHeader.defaultProps = {
    teamFilter: [
        {label: "All teams", value: "all"}
    ]
};

export default PlayerHeader;
