import React, {useState} from 'react';
import {notHaveErrors, validateFields, validateForm} from '../../../../utils/form';
import Input from '../../../../components/UI/Input/Input';
import {useTranslation} from 'react-i18next';
import Switch from '../../../../components/UI/Switch/Switch';
import * as yup from 'yup';
import {useSaveDataEvent, useSaveDataEventCategory} from '../../../../apollo/queries/GameQueries';
import {createGuid} from '../../../../utils/utils';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from '../../../../apollo/cashe';
import {useSaveSkillCategory} from '../../../../apollo/queries/PlayerQueries';

const SkillCategoryForm = (props) => {
    const {defaultValues, setLoading} = props;

    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const [values, setValues] = useState(defaultValues ?? {
        name: "",
        isGoalkeeper: false,
        isText: false,
        order: 0,
        showInClub: true,
        isSystem: false
    });

    const [errors, setErrors] = useState({});

    const [saveSkillCategory] = useSaveSkillCategory();

    const fieldChanged = (event) => {
        const newValues = { ...values };
        newValues[event.target.name] = event.target.value

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });

        setValues(newValues);
    }

    const validationRules = {
        name: yup.string().required(t("required")),
        order: yup.number().required(t("required")),
    }

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors
    };

    const formSubmitted = (e) => {
        e.preventDefault();
        setLoading(true);
        validateForm(values, validationRules, (errors) => {
          if (notHaveErrors(errors))
            saveData();
          else
            setErrors(errors);
            setLoading(false);
        });
    };

    const saveData = () => {
        const variables = {
            input: {
                id: values.id ?? createGuid(),
                clubId: navState.clubId,
                name: values.name,
                order: +values.order,
                showInClub: values.showInClub
            }
        }

        saveSkillCategory({variables: variables}).then(result => {
            props.onComplete();
        });
    };

    return (
        <>
            <form id={props.id} onSubmit={formSubmitted}>
                <Input

                    name="name"
                    label={t('Name')}
                    disabled={values.isSystem}
                    {...regField}
                />

                <Switch
                    fullSize={true}
                    name="isGoalkeeper"
                    label={t('Goalkeeper')}
                    disabled={values.isSystem}
                    {...regField}
                />

                <Switch
                    fullSize={true}
                    name="isText"
                    label={t('Text')}
                    disabled={values.isSystem}
                    {...regField}
                />

                <Input

                    name="order"
                    type="number"
                    label={t('Order')}
                    {...regField}
                />

                <Switch
                    fullSize={true}
                    name="showInClub"
                    label={t('Enabled')}
                    {...regField}
                />
            </form>
        </>
    );
};

SkillCategoryForm.defaultProps = {
};

export default SkillCategoryForm;
