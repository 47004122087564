import React from 'react';
import SkillStatChart from '../charts/SkillStatChart';

const SkillStat = (props) => {
    return (
        <SkillStatChart {...props} />
    );
};

SkillStat.defaultProps = {};

export default SkillStat;
