export const library = () => ({
    categories: [
        {
            id: "ex-group-1",
            group: "Technical",
            items: [
                {
                    id: "ex-category-1",
                    name: "Passing",
                    image: "/assets/images/exercises/categories/passing.jpg",
                    selected: false,
                    default: 2,
                },
                {
                    id: "ex-category-2",
                    name: "Movement",
                    image: "/assets/images/exercises/categories/movement.jpg",
                    selected: false
                },
                {
                    id: "ex-category-3",
                    name: "Finishing",
                    image: "/assets/images/exercises/categories/finishing.jpg",
                    selected: false
                },
                {
                    id: "ex-category-4",
                    name: "Crossing",
                    image: "/assets/images/exercises/categories/crossing.jpg",
                    selected: false
                },
                {
                    id: "ex-category-5",
                    name: "Dribbling",
                    image: "/assets/images/exercises/categories/dribbling.jpg",
                    selected: false,
                    default: 1
                }
            ]
        }, {
            id: "ex-group-2",
            group: "Tactical",
            items: [
                {
                    id: "ex-category-6",
                    name: "Combinations",
                    image: "/assets/images/exercises/categories/combinations.jpg",
                    selected: false
                },
                {
                    id: "ex-category-7",
                    name: "Compactness",
                    image: "/assets/images/exercises/categories/compactness.jpg",
                    selected: false
                },
                {
                    id: "ex-category-8",
                    name: "Counter Attacking",
                    image: "/assets/images/exercises/categories/counter-attacking.jpg",
                    selected: false,
                    default: 3
                },
                {
                    id: "ex-category-9",
                    name: "Defensive Transition",
                    image: "/assets/images/exercises/categories/defence.jpg",
                    selected: false
                },
                {
                    id: "ex-category-10",
                    name: "Overloads",
                    image: "/assets/images/exercises/categories/overloads.jpg",
                    selected: false
                }
            ]
        }, {
            id: "ex-group-3",
            group: "Practice Type",
            items: [
                {
                    id: "ex-category-11",
                    name: "Individual",
                    image: "/assets/images/exercises/categories/individual.jpg",
                    selected: false,
                    default: 4
                },
                {
                    id: "ex-category-12",
                    name: "Group",
                    image: "/assets/images/exercises/categories/group.jpg",
                    selected: false
                },

                {
                    id: "ex-category-13",
                    name: "Skill",
                    image: "/assets/images/exercises/categories/skill.jpg",
                    selected: false,
                    default: 5
                },
                {
                    id: "ex-category-14",
                    name: "Opposed - Possession",
                    image: "/assets/images/exercises/categories/possession.jpg",
                    selected: false
                },
                {
                    id: "ex-category-15",
                    name: "Technical",
                    image: "/assets/images/exercises/categories/technical.jpg",
                    selected: false,
                    default: 6
                },
                {
                    id: "ex-category-16",
                    name: "Small Sided Games",
                    image: "/assets/images/exercises/categories/small-side.jpg",
                    selected: false,
                    default: 7
                }
            ]
        }, {
            id: "ex-group-4",
            group: "Attack or Defend",
            items: [
                {
                    id: "ex-category-18",
                    name: "Attack",
                    image: "/assets/images/exercises/categories/attack.jpg",
                    selected: false,
                    default: 8
                },
                {
                    id: "ex-category-19",
                    name: "Defend",
                    image: "/assets/images/exercises/categories/defend.jpg",
                    selected: false,
                    default: 9
                }
            ]
        }, {
            id: "ex-group-5",
            group: "Phase",
            items: [
                {
                    id: "ex-category-20",
                    name: "Begin the Attack",
                    image: "/assets/images/exercises/categories/begin-the-attack.jpg",
                    selected: false,
                    default: 12
                },
                {
                    id: "ex-category-21",
                    name: "Possession Questions",
                    image: "/assets/images/exercises/categories/possession-questions.jpg",
                    selected: false
                },
                {
                    id: "ex-category-22",
                    name: "Finish the Attack",
                    image: "/assets/images/exercises/categories/finish-the-attack.jpg",
                    selected: false,
                    default: 11
                },
                {
                    id: "ex-category-23",
                    name: "Press to Regain",
                    image: "/assets/images/exercises/categories/press-to-regain.jpg",
                    selected: false
                },
                {
                    id: "ex-category-25",
                    name: "Organised Defending",
                    image: "/assets/images/exercises/categories/organised-defending.jpg",
                    selected: false
                }
            ]
        }, {
            id: "ex-group-6",
            group: "Other",
            items: [
                {
                    id: "ex-category-26",
                    name: "Warmup",
                    image: "/assets/images/exercises/categories/warm-up.jpg",
                    selected: false,
                    default: 10
                },
                {
                    id: "ex-category-27",
                    name: "Other",
                    image: "/assets/images/exercises/categories/defence.jpg",
                    selected: false
                },
            ]
        }
    ],
    tags: [
        {id: "d22e4e9e-529a-4d63-87b8-89ce4d1252da", tag: "#training", selected: false},
        {id: "6c5f21ae-ebda-426b-b934-331070399a46", tag: "#workout", selected: false},
        {id: "5bfcea5e-34c0-47f7-a6b3-0cfa68dc0aff", tag: "#gym", selected: false},
        {id: "97a1e61e-323e-44e0-9c80-cd404e675edf", tag: "#motivation", selected: false},
        {id: "0fa5e935-30ab-4d4d-be1f-f959f3c9ece8", tag: "#fit", selected: false},
        {id: "2ebde32c-7dc1-4612-bd0d-01d60077ca67", tag: "#sport", selected: false},
        {id: "e30c1650-9f61-4bb1-b87a-60bb9bb953c1", tag: "#health", selected: false},
        {id: "a40a24b2-aee5-47f2-ab4d-e6aee6e03489", tag: "#football", selected: false},
        {id: "368c2af5-5de5-42bd-90eb-ee26bd835f4d", tag: "#soccer", selected: false},
        {id: "4f83eb4e-ad51-4bac-9890-aba8969644c2", tag: "#2x2", selected: false},
        {id: "ff01c36a-a862-4c4f-8bd2-de5b461d4182", tag: "#1x1", selected: false},
        {id: "81906d64-3ae8-4946-9477-d9897376b249", tag: "#championsleague", selected: false},
        {id: "c51ed5ed-8cad-427f-b94f-883ffcff8701", tag: "#premierleague", selected: false},
        {id: "2749adc4-85bc-494a-83cd-69a585620446", tag: "#fifa", selected: false},
        {id: "d78f4156-b67b-4aff-96a1-ca31ff855c93", tag: "#exercise", selected: false},
        {id: "987ce272-b875-4f1a-954a-03fe65173cde", tag: "#warmuptime", selected: false}
    ],
    accessType: "CLUB",
    duration: "",
    numberOfPlayers: "",
    ageGroup: "",
    level: "",
    language: "",
});

export const filterOptions = {
    accessType: [
        {label: "All", value: ""},
        {label: "My", value: "MY"},
        {label: "Club", value: "CLUB"},
        {label: "Public +", value: "PUBLIC"},
        {label: "FIFA", value: "FIFA"},
    ],
    duration: [
        {label: "All", value: ""},
        {label: "10 min or less", value: "10"},
        {label: "10 - 30 min", value: "30"},
        {label: "30 - 60 min", value: "60"},
        {label: "60 - 90 min", value: "90"},
        {label: "90 min +", value: "120"}
    ],
    players: [
        {label: "All", value: ""},
        {label: "1", value: "1"},
        {label: "2 - 4", value: "4"},
        {label: "5 - 8", value: "8"},
        {label: "9 - 12", value: "12"},
        {label: "13 +", value: "13"}
    ],
    age: [
        {label: "All", value: ""},
        {label: "8 - 11", value: "11"},
        {label: "12 - 16", value: "16"},
        {label: "17 +", value: "17"},
    ],
    level: [
        {label: "All", value: ""},
        {label: "Beginner", value: "BEGINNER"},
        {label: "Intermediate", value: "INTERMEDIATE"},
        {label: "Advanced", value: "ADVANCED"},
        {label: "Elite", value: "ELITE"},
    ],
    language: [
        {label: "All", value: ""},
        {label: "中文", value: "cn"},
        {label: "English", value: "en"},
        {label: "Español", value: "es"},
        {label: "Francais", value: "fr"},
        {label: "Ελληνικά", value: "gr"},
        {label: "Latviešu", value: "lv"},
        {label: "Português", value: "pt"},
        {label: "Русский", value: "ru"},
        {label: "Український", value: "ua"}
    ]
}

export const durationFilter = (val) => {
    switch (val) {
        case "10":
            return {gte: 0, lte: 10};
        case "30":
            return {gte: 10, lte: 30};
        case "60":
            return {gte: 30, lte: 60};
        case "90":
            return {gte: 60, lte: 90};
        default:
            return {gte: 90};
    }
}

export const loadLegacyScripts = () => {
    const scripts = [
        '/Scripts/jquery-3.2.1.js',
        '/Scripts/bootstrap.js',
        '/Scripts/bootstrap-slider.js',
        '/Scripts/bootstrap-colorpicker.js',
        '/Scripts/tmpl.min.js',
        '/Scripts/app/app.js',
        '/Scripts/globs/globs.common.js',
        '/Scripts/globs/graphic-editor.js',
        '/Scripts/globs/ypd.js',
    ];

    scripts.forEach((script) => {
        const scriptTag = document.createElement('script');
        scriptTag.src = script;
        scriptTag.async = false;
        document.body.appendChild(scriptTag);
    });
}
