import * as yup from 'yup';

export const notHaveErrors = (obj) => {
  for(let prop in obj) {
    if(obj.hasOwnProperty(prop)) {
      return false;
    }
  }
  return JSON.stringify(obj) === JSON.stringify({});
}

export const haveError = ({error, name}) => (
    error && hasOwnProperty(error, name)
        ? error[name]
        : null
);

export const haveDefaultValue = ({value, name}) => (
    value && name && hasOwnProperty(value, name)
        ? value[name]
        : null
);

export const hasOwnProperty = (obj, prop) => {
  const proto = obj.__proto__ || obj.constructor.prototype;
  return (prop in obj) &&
      (!(prop in proto) || proto[prop] !== obj[prop]);
}

export const validateForm = (data, rules, callback) => {
  const promises = Object.keys(rules).map(function(element) {
    const schema = {[element]: rules[element]};
    return yup.object().shape(schema)
        .validate(data)
        .then(() => null)
        .catch((error) => [element, error.errors[0]]
        );
  });
  Promise.all(promises).then(value => {
    const errors = value.filter((e) => e != null);
    callback(errors.length ? Object.assign(...errors.map(([key, val]) => ({[key]: val}))) : {});
  });
}

export const validateFields = (data, rules, callback) => {
  const promises = Object.keys(data).map(function(element) {
    const schema = {[element]: rules[element]};
    if (schema === undefined) return () => {};
    return yup.object().shape(schema)
        .validate(data)
        .then(() => null)
        .catch((error) => [element, error.errors[0]]
        );
  });
  Promise.all(promises).then(value => {
    const errors = value.filter((e) => e != null);
    callback(errors.length ? Object.assign(...errors.map(([key, val]) => ({[key]: val}))) : {});
  });
}
