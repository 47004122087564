import React, {useState} from 'react';
import Panel from 'components/UI/Panel/Panel';
import classes from './Team.module.scss';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import Card from 'components/UI/Card/Card';
import Subtext from 'components/UI/Table/Subtext/Subtext';
import DateTime from 'components/UI/Table/DateTime/DateTime';
import Table from 'components/UI/Table/Table';
import Birthday from 'components/UI/Table/Birthday/Birthday';
import Submenu from 'components/Navigation/Submenu/Submenu';
import {useNavigate} from 'react-router-dom';
import {naviDataVar} from 'apollo/cashe';
import Pagination from 'components/UI/Pagination/Pagination';
import {useAddTeamPlayers, useRemoveTeamPlayers, useTeam} from 'apollo/queries/TeamQueries';
import TeamForm from 'containers/Club/TeamList/forms/TeamForm';
import Button from 'components/UI/Button/Button';
import DeleteTeamForm from 'containers/Club/TeamList/forms/DeleteTeamForm';
import NewPlayerForm from './forms/NewPlayerForm';
import SwitchComponents from 'components/System/SwitchComponents';
import {menu} from 'components/Navigation/Menu/Settings';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import AddPlayerForm from 'containers/Team/TrainingsDetails/forms/AddPlayersForm';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';
import CanAccess from 'components/Navigation/CanAccess/CanAccess';
import TeamPlayerForm from 'containers/Team/Team/forms/TeamPlayerForm';
import UploadTeamForm from 'containers/Team/Team/forms/UploadTeamForm';
import PreviewUploadTeamForm from 'containers/Team/Team/forms/PreviewUploadTeamForm';
import {Countries} from 'containers/Team/MatchesDetails/Settings';

const Team = () => {
    const {t} = useTranslation();
    const history = useNavigate();
    const navState = useReactiveVar(naviDataVar);

    const [skip, setSkip] = useState(0);

    const [modalData, setModalData, initState] = ModalState();
    const [modalData2, setModalData2, initState2] = ModalState();

    const [addTeamPlayers] = useAddTeamPlayers();
    const [removeTeamPlayers] = useRemoveTeamPlayers();

    const {loading, error, data, refetch} = useTeam({
        clubId: navState.clubId,
        teamId: navState.teamId,
        skip: skip
    });

    const [selectedRows, setSelectedRows] = useState([]);

    //region Edit team
    const onEditTeam = () => {
        const {birthYear, suffix, gender, goalkeepersOnly, photo} = data.teams.items[0];

        const _data = {
            ...initState,
            title: t("Edit team"),
            form: "team",
            styles: {
                maxWidth: "700px",
            },
            defaultValues: {
                id: navState.teamId,
                birthYear: birthYear,
                suffix: suffix,
                gender: gender,
                goalkeepersOnly: goalkeepersOnly,
                photo: photo
            },
            onComplete: onComplete,
            onDelete: onDeleteClick,
            open: true
        }

        setModalData(_data);
    }

    const onComplete = async () => {
        setModalData(initState);
        await refetch();
    };
    //endregion

    //region Delete modal
        const onDeleteClick = () => {
            const _data = {
                ...initState2,
                title: t("Delete team"),
                form: "deleteTeam",
                onComplete: onDeleteComplete,
                open: true
            }

            setModalData2(_data);
        };

        const onDeleteComplete = () => {
            setModalData({...initState});
            setModalData2({...initState2});
            history(`/club-${navState.clubId}`);
        }
    //endregion

    //region Add players
    const onAddPlayers = () => {
        const players = data.players.items ?? [];

        const _data = {
            ...initState,
            form: "add",
            title: t("Add players"),
            existedPlayersIds : players.map(p => p.playerId),
            onAddPlayers: onCompleteAddPlayers,
            open: true
        }

        setModalData(_data);
    }

    const onCompleteAddPlayers = (players) => {
        setModalData(initState);

        if (players.length === 0)
            return;

        const variables = {
            input: {
                clubId: navState.clubId,
                teamId: navState.teamId,
                playerIds: players.map(x => x.playerId)
            }
        };

        addTeamPlayers({variables: variables}).then(result => {
            refetch();
        });
    };
    //endregion

    const Header = ({data}) => {
        if (data.length === 0) return (<>No data...</>);

        const {name, photo} = data[0];

        return (
            <div className={classes.team}>
                <div className={classes.photo}>
                    <img src={photo ? photo : "/assets/images/emptyLogo.svg"} alt={name} />
                </div>
                <div>
                    <div className={classes.name}>{t('Team')}</div>
                    <h2>{name}</h2>
                    <CanAccess users={[SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin]}>
                        <ul className={classes.actions}>
                            <li><Button type="third" onClick={onEditTeam}><i className="icon-Setting" /> {t('Edit team')}</Button></li>
                        </ul>
                    </CanAccess>
                </div>
            </div>
        );
    };

    const Info = ({data}) => {
        if (data.length === 0) return (<></>);

        const {playerCount, games, wins, draws, losses, goals}
            = data[0];
        return (
            <div className={classes.info}>
                <Card name={t('Players')} value={playerCount}/>
                <Card name={t('Matches')} value={games}/>
                <Card name={t('Wins')} value={wins}/>
                <Card name={t('Draws')} value={draws}/>
                <Card name={t('Losses')} value={losses}/>
                <Card name={t('Goals')} value={goals}/>
            </div>
        );
    };

    const onAddNewPlayer = () => {
        const _data = {
            ...initState,
            title: t("Add new player"),
            form: "player",
            pages: {
                page: 0,
                maxPages: 3,
            },
            styles: {
                maxWidth: "700px",
            },
            onComplete: onComplete,
            open: true
        };

        setModalData(_data);
    }

    const onRemove = () => {
        const variables = {
            input: {
                clubId: navState.clubId,
                teamId: navState.teamId,
                playerIds: selectedRows.map(x => x.playerId)
            }
        };

        removeTeamPlayers({variables: variables}).then(result => {
            refetch().then(() => {});
        });
    }

    const actions = () => {
        return (
          <CanAccess users={[SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin]}>
                <Button type="primary" style={{margin: 0}} onClick={onAddNewPlayer}>
                    <span><i className="icon-Plus-Square"></i> {t('Add player')}</span>
                </Button>

                {selectedRows.length > 0 &&
                    <Button type="secondary" style={{margin: 0}} onClick={onRemove}>
                        <span>{t('Remove')}</span>
                    </Button>
                }
          </CanAccess>
        );
    };

    const filters = () => {
        return (
            <>
                <CanAccess users={[SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin]}>
                    <Button type="third" style={{margin: '0 0 0 20px'}} onClick={onAddPlayers}>
                        <span><i className="icon-Plus-Square"> </i> {t('Player from list')}</span>
                    </Button>

                    <Button type="third" style={{margin: '0 0 0 20px'}} onClick={onAddXls}>
                        <span><i className="icon-Upload1"> </i> {t('Load from Excel')}</span>
                    </Button>
                </CanAccess>

                {/*<a className={cn(buttonClasses.Button, buttonClasses.third)}*/}
                {/*   style={{margin: '0 0 0 20px'}}*/}
                {/*   data-toggle="modal" data-target="#b2e9db-player-list-add-bodily-metrics"*/}
                {/*   aria-expanded="false"><span><i className="icon-Chart"> </i> {t('Update anthropometric data')}</span></a>*/}
            </>
        );
    };

    const onAddXls = () => {
        const _data = {
            ...initState,
            title: t("Load from Excel"),
            form: "upload",
            buttons: {
                hideSaveButton: true
            },
            onComplete: previewLoadedData,
            open: true
        }

        setModalData(_data);
    }

    const previewLoadedData = (data) => {

        const formData = data.reduce((acc, row, index) => {
            const obj = Object.keys(row).reduce((rowAcc, key) => ({...rowAcc, ...{[`${key}-${index}`]: row[key]}}), 0);
            obj[`countryOfBirth-${index}`] = getCountry(obj[`countryOfBirth-${index}`]);
            obj[`citizenship-${index}`] = getCountry(obj[`citizenship-${index}`]);
            return {...acc, ...obj}
        }, 0);

        const _data = {
            ...initState,
            title: t("Preview team"),
            form: "preview",
            styles: {
                maxWidth: "95%",
                maxHeight: "calc(100vh - 220px)",
            },
            defaultValues: formData,
            onComplete: onComplete,
            open: true
        }

        setModalData(_data);
    };

    const getCountry = (country) => {
        const selectedCountry = Countries().find(x => x.label === country);
        return selectedCountry?.value;
    };

    const onClick = (data) => {
        const {playerId, fullName} = data;
        const item = menu.player.items[0];

        naviDataVar({
            ...navState,
            playerId: playerId,
            playerName: fullName,
            selected: {
                id:  item.id,
                path: item.path,
                type: item.type,
                hash: item.hash,
                items: item.submenu,
                submenu: {
                    id: item.submenu[0] ? item.submenu[0].id : null
                }
            }
        });

        history(`/club-${navState.clubId}/team-${navState.teamId}/player-${playerId}`);
    };

    const onSettings = (data) => {
        const team = data.teams[0] ?? {};

        const _data = {
            ...initState,
            title: t("Setup team player"),
            form: "setup",
            styles: {
                maxWidth: "700px",
            },
            defaultValues: {
                teamMemberId: team.teamMemberId,
                firstName: data.firstName,
                lastName: data.lastName,
                teamNumber: team.teamNumber,
                position: team.position,
                group: team.group,
                startDate: team.startDate,
                endDate: team.endDate,
            },
            onComplete: onComplete,
            open: true
        }

        setModalData(_data);
    }

    const getGroup = (data) => {
       const group = data.teams[0].group;

       switch (group) {
        case "ALL":
            return t("All");
        case "SECOND":
            return `2 ${t('group')}`;
        case "THIRD":
           return `3 ${t('group')}`;
        case "FOURTH":
           return `4 ${t('group')}`;
        case "FIFTH":
           return `5 ${t('group')}`;
        case "SIXTH":
           return `6 ${t('group')}`;
        case "SEVENTH":
           return `7 ${t('group')}`;
        default:
           return `1 ${t('group')}`;
       }
    }

    if (loading) return (<div>{t("Loading...")}</div>);
    if (error) return (<div>{t("Error...")}</div>);

    return (
        <>
            <div className="page-header">
                <Header data={data.teams.items}/>
                <Info data={data.teams.items}/>
                <Submenu/>
            </div>
            <div className="page-content">
                <Panel border={false} action={actions} filters={filters}/>

                <Table
                    data={data.players.items}
                    access={[SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin]}
                    columns={[
                        {
                            Header: t('Player'),
                            accessor: 'fullName',
                            Cell: (cellData) => {
                                return (
                                    <div onClick={() => onClick(cellData.row.original)}>
                                        {cellData.value}
                                        <Subtext>{cellData.row.original.teams[0].position}</Subtext>
                                    </div>
                                );
                            }
                        },
                        {
                            Header: '#',
                            accessor: 'teams',
                            className: classes.number,
                            Cell: (cellData) => <div className={classes.number} onClick={() => onClick(cellData.row.original)}>
                                {cellData.row.original.teams[0].teamNumber}</div>
                        },
                        {
                            Header: t('Group'),
                            accessor: 'playerId',
                            className: classes.hide,
                            Cell: (cellData) => <div>{getGroup(cellData.row.original)}</div>
                        },
                        {
                            Header: t('Birthday'),
                            className: classes.hide,
                            accessor: 'dateOfBirth',
                            Cell: (cellData) => <div onClick={() => onClick(cellData.row.original)} className={classes.hide}>
                                {cellData.value ? <Birthday data={cellData.value}/> : "-"}</div>
                        },
                        {
                            Header: t('Licence'),
                            className: classes.hide,
                            accessor: 'licenceNr',
                            Cell: (cellData) => <div onClick={() => onClick(cellData.row.original)} className={classes.hide}>
                                {cellData.value}</div>
                        },
                        {
                            Header: t('Next health check'),
                            className: classes.hide,
                            accessor: 'healthCheckNextDate',
                            Cell: (cellData) => <div className={classes.hide}><DateTime data={cellData.value}
                                                                                        showTime={false}/></div>
                        },
                        {
                            accessor: 'action',
                            Cell: (cellData) => <div>
                                <CanAccess users={[SystemRoles.Coach,SystemRoles.ClubAdmin,SystemRoles.SysAdmin]}>
                                    <Button type="primary" style={{margin:0}} onClick={() => onSettings(cellData.row.original)}>{t("Setup")}</Button>
                                </CanAccess>
                            </div>
                        }
                    ]}
                    onSelectClick={(x) => setSelectedRows(x)}
                    onRowClick={() => {}}/>

                <Pagination page={skip} count={data.players.totalCount} onPage={100}
                            onPageChange={(p) => setSkip(p.selected)}/>
            </div>

            <ModalWindow2 {...modalData}>
                <SwitchComponents active={modalData.form}>
                    <TeamForm {...modalData} name="team" />
                    <NewPlayerForm {...modalData} name="player" />
                    <AddPlayerForm {...modalData} name="add" />
                    <TeamPlayerForm {...modalData} name="setup" />
                    <UploadTeamForm {...modalData} name="upload" />
                    <PreviewUploadTeamForm {...modalData} name="preview" />
                </SwitchComponents>
            </ModalWindow2>

            <ModalWindow2 {...modalData2}>
                <DeleteTeamForm {...modalData2} />
            </ModalWindow2>
        </>
    );
};

Team.propTypes = {};
Team.defaultProps = {};

export default Team;
