import React, { PropTypes } from 'react'
import classes from '../VideoEditor.module.scss';
import Input from '../../../../../components/UI/Input/Input';
import Button from '../../../../../components/UI/Button/Button';
import {useTranslation} from 'react-i18next';

const TimerForm = (props) => {
  const {t} = useTranslation();

  const onSave = () => {
    props.onSave();
  }

  const onClose = () => {
    props.onClose();
  }

  return (
      <>
        <div className={classes.group}>
          <div className={classes.columns2}>
            <Input
                name="name"
                label={t('1 period')}
            />

            <Input
                name="name"
                label={t('Video')}
            />
          </div>
          <div className={classes.columns2}>
            <Input
                name="name"
                label={t('Additional time')}
            />

            <Input
                name="name"
                label={t('Video')}
            />
          </div>
          <div className={classes.columns2}>
            <Input
                name="name"
                label={t('2 period')}
            />

            <Input
                name="name"
                label={t('Video')}
            />
          </div>
          <div className={classes.columns2}>
            <Input
                name="name"
                label={t('Additional time')}
            />

            <Input
                name="name"
                label={t('Video')}
            />
          </div>
          <div className={classes.columns2}>
            <Input
                name="name"
                label={t('1 Plus 15 minutes')}
            />

            <Input
                name="name"
                label={t('Video')}
            />
          </div>
          <div className={classes.columns2}>
            <Input
                name="name"
                label={t('2 Plus 15 minutes')}
            />

            <Input
                name="name"
                label={t('Video')}
            />
          </div>
          <div className={classes.buttons}>
            <Button type="third" onClick={onSave}>Save</Button>
            <Button type="third" onClick={onClose}>Cancel</Button>
          </div>
        </div>
        <div className={classes.separator}></div>
      </>
  );
};

TimerForm.propTypes = {};
TimerForm.defaultProps = {
  onSave: () => {},
  onClose: () => {}
};

export default TimerForm;