import React, {useState} from 'react';
import Submenu from 'components/Navigation/Submenu/Submenu';
import Panel from 'components/UI/Panel/Panel';
import {useTranslation} from 'react-i18next';
import Search from 'components/UI/Panel/Search/Search';
import {useLoginAs, useSystemUsers} from 'apollo/queries/PermissionsQueries';
import Table from 'components/UI/Table/Table';
import Subtext from 'components/UI/Table/Subtext/Subtext';
import Pagination from 'components/UI/Pagination/Pagination';
import classes from './Users.module.scss';
import cn from 'classnames';
import {useNavigate} from 'react-router-dom';

const Users = () => {
	const {t} = useTranslation();
	const history = useNavigate();

	const onPage = 10;

	const [skip, setSkip] = useState(0);
	const [search, setSearch] = useState('');

	const [loginAs] = useLoginAs();
	const {data, loading, error} = useSystemUsers({
    where: {
	    or:[{fullName: {contains: search}}, {email: {contains: search}}]
    },
    skip: skip * onPage,
    take: onPage
	});

	const actions = () => {
		return (<div></div>);
	};

	const panelSearch = () => (
		<Search onSearch={onSearch} placeholder={t('Search user...')}/>
	);

	const onSearch = (e) => {
		setSkip(0);
		setSearch(e.target.value);
	};

	const status = (data) => {
		switch (data) {
			case 'ACTIVE':
				return t('Active');
			case 'INVITED':
				return t('Invited');
			case 'REMOVED':
				return t('Removed');
			default:
				return t("Not invited");
		}
	}

	const onSettings = () => {
		return [
			{label: 'Login as user', id: '1', action: onLoginAs},
			{label: 'Invite user', id: '2', action: () => {}},
			{label: 'Delete user', id: '3', action: () => {}}
		];
	};

	const onLoginAs = (data) => {
		loginAs({variables: {userId: data.userId}}).then((result) => {
			history(`/select-profile`);
		});
	}

	return (
		<>
			<div className="page-header">
				<h2>{t('Users')}</h2>
				<Submenu/>
			</div>
			<div className="page-content">
				<Panel border={false} action={actions} search={panelSearch}/>

				{loading ? <>{t('Loading...')}</> : undefined}
				{error ? <>{t('Error...')}</> : undefined}

				{data ? <>
					<Table
						data={data.systemUsers.items ?? []}
						columns={[
							{
								Header: t('User name'),
								accessor: 'fullName',
								Cell: (cellData) =>
								<div>{cellData.value}
									<Subtext>{t('E-mail')}:
										<a href={'mailto:' + cellData.row.original.email}>{cellData.row.original.email}</a>
									</Subtext>
								</div>
							},
							{
								Header: t('Roles'),
								className: cn(classes.roles, classes.hide),
								accessor: 'roles',
								Cell: (cellData) => <div className={classes.hide}>
									{cellData.value.map((role, i) => [i > 0 && ", ", <span key={i}><b>{role.clubName}</b>: {role.name}</span>])}
								</div>
							},
							{
								Header: t('Status'),
								accessor: 'invited',
								className: cn(classes.status, classes.hide),
								Cell: (cellData) =>
									<div className={classes.hide}> {status(cellData.value)}
									<Subtext>{t('Last login')}: { cellData.row.original.lastLogin
										? cellData.row.original.lastLogin : "-"}
									</Subtext>
								</div>
							},
						]}
						onSettings={onSettings}
					/>

					<Pagination page={skip} onPage={onPage} count={data.systemUsers.totalCount}
					            onPageChange={(p) => setSkip(p.selected)}/>
				</> : undefined}
			</div>
		</>
	);
};

Users.defaultProps = {};

export default Users;
