import {gql, useLazyQuery, useMutation, useQuery} from '@apollo/client';

export const SYSTEM_STATISTIC = gql`
    query SystemStatistic {
        systemStatistic {
            clubs
            teams
            coaches
            staffs
            parents
            players
            trainings
            games
            exercises
            tests
            videos
            highlights
        }
        clubList(order: {registrationDate: DESC, name: ASC}) {
            items {
                clubId
                name
                country
                city
                logo
                clubFormat
                registrationDate
                lastLoginDate
                confirmed
                deleted
            }
        }
    }
`;
export const useSystemStatistic = () => useQuery(SYSTEM_STATISTIC);

export const GET_CLUB = gql`
    query GetClub($clubId: String!, $teamId: String) {
        club(clubId: $clubId, teamId: $teamId) {
            clubId
            name
            logo
            teamId
            teamName
        }
    }
`;
export const useClub = () => useLazyQuery(GET_CLUB);

export const CLUB_LIST = gql`
    query GetClubs(
        $where: ClubTypeFilterInput
        $order: [ClubTypeSortInput!]
        $skip: Int!
    ) {
        clubList(where: $where, skip: $skip, order: $order) {
            totalCount
            items {
                clubId
                name
                country
                city
                logo
                clubFormat
                registrationDate
                lastLoginDate
                confirmed
                deleted
            }
        }
    }
`;
export const useClubList = () => useLazyQuery(CLUB_LIST);

export const CLUBS = gql`
  query GetClubs(
    $where: ClubTypeFilterInput
  ) {
    clubs(where: $where) {
      clubId
      name
      country
      city
      clubFormat
      registrationDate
    lastLoginDate
    }
  }
`;
export const useClubs = (variables) => useQuery(CLUBS, {variables});

export const CLUB_INFO = gql`
    query GetClub($clubId: String!) {
        club(clubId: $clubId) {
            clubId
            name
            registrationNumber
            state
            city
            country
            logo
            clubFormat
            phone
            email
            teamCount
            trainerCount
            playerCount
            playerWithTeamCount
            playerWithoutTeamCount
            playerWithoutMedicineCount
        }
    }
`;
export const useClubInfo = () => useLazyQuery(CLUB_INFO);

export const CLUB_DETAILS = gql`
    query GetClubDetails($clubId: String!) {
        club(clubId: $clubId) {
            clubId
            name
            registrationNumber
            state
            city
            country
            logo
            clubFormat
            phone
            email
            teamCount
            trainerCount
            playerCount
            playerWithTeamCount
            playerWithoutTeamCount
            playerWithoutMedicineCount
        }
    }
`;
export const useClubDetails = () => useLazyQuery(CLUB_DETAILS);

export const TEAMS_LIST = gql`
    query GetTeams(
        $clubId: String!
        $search: String
        $skip: Int!
        $take: Int!
    ) {
        teams(
            clubId: $clubId
            where: {name: {contains: $search}}
            order: {birthYear: ASC}
            take: $take
            skip: $skip
        ) {
            totalCount
            items {
                teamId
                name
                playerCount
                games
                wins
                draws
                losses
                goals
                trainers {
                    firstName
                    lastName
                    trainerId
                }
            }
        }
    }
`;
export const useTeams = () =>
    useLazyQuery(TEAMS_LIST, {fetchPolicy: 'network-only'});

const CLUB_TEAMS_AND_PLAYERS = gql`
    query GetTeamsAndPlayers(
        $clubId: String!
        $playerWhere: PlayerTypeFilterInput!
    ) {
        teams(clubId: $clubId, order: {birthYear: ASC}, take: 100) {
            totalCount
            items {
                name
                teamId
                playerCount
            }
        }

        players(clubId: $clubId, where: $playerWhere, take: 100) {
            items {
                playerId
                fullName
            }
        }
    }
`;

export const useClubTeamsAndPlayers = (variables) =>
    useQuery(CLUB_TEAMS_AND_PLAYERS, {variables: variables});

const CLUB_PLAYERS = gql`
    query GetPlayers(
        $clubId: String!
        $where: PlayerTypeFilterInput!
        $skip: Int!
        $take: Int!
        $order: [PlayerTypeSortInput!]
    ) {
        players(
            clubId: $clubId
            where: $where
            take: $take
            skip: $skip
            order: $order
        ) {
            totalCount
            items {
                playerId
                fullName
                dateOfBirth
                email
                phoneNumber
                gender
                healthCheckNextDate
                healthCondition
                anthropometryDate
                teams {
                    team {
                        name
                    }
                    teamNumber
                }
            }
        }
    }
`;

export const useClubPlayers = (variables) =>
    useQuery(CLUB_PLAYERS, {variables: variables});

export const CLUB_CHART = gql`
    query GetClubChart($clubId: String!) {
        clubChart(clubId: $clubId) {
            clubId
            document
        }
    }
`;

export const useClubChart = (clubId) =>
    useQuery(CLUB_CHART, {
        variables: {clubId: clubId},
    });

export const SAVE_CLUB_CHART = gql`
    mutation ($clubId: String!, $document: String!) {
        saveClubChart(input: {clubId: $clubId, document: $document}) {
            clubId
            document
        }
    }
`;

export const useSaveClubChart = () => useMutation(SAVE_CLUB_CHART);

export const GET_TRAININGS = gql`
    query GetClubTeamsTrainings(
        $clubId: String!
        $startDate: DateTime!
        $endDate: DateTime!
    ) {
        clubTeamsTrainings(
            clubId: $clubId
            startDate: $startDate
            endDate: $endDate
            order: {birthYear: ASC}
        ) {
            teamId
            name
            items: trainings {
                id: trainingId
                name
                start
                end
                duration
                venue
                trainingStatus
            }
        }
    }
`;

export const useLazyClubTrainings = () =>
    useLazyQuery(GET_TRAININGS, {
        pollInterval: 30 * 1000,
    });


export const SAVE_CLUB = gql`
    mutation SaveClub($input: ClubInput!) {
        saveClub(input: $input) 
    }`;
export const useSaveClub = () => useMutation(SAVE_CLUB);


export const DELETE_CLUB = gql`
    mutation DeleteClub($clubId: String!) {
        deleteClub(clubId: $clubId)
    }`;
export const useDeleteClub = () => useMutation(DELETE_CLUB);


export const CLUB_EVALUATION_STAT = gql`
  query ClubEvaluationStat ($clubId: String!)  {
    clubEvaluationStat(clubId: $clubId) {
      count
      clubAverage
      coachAverage
      gameAverage
    }
  }

`;
export const useClubEvaluationStat = (variables) => useQuery(CLUB_EVALUATION_STAT, {variables});

export const COACH_EVALUATION_LIST = gql`
    query ClubEvaluations ($clubId: String!) {
        trainers(
          clubId: $clubId
          take: 100
        ) {
          items {
            trainerId
            fullName
          }
        }
        clubEvaluations (
            clubId: $clubId
            order: {date: DESC}
        ) {
              id
              clubId
              keyItem
              date
              title
              document
              clubEvaluationValue
              couchEvaluationValue
        }
    }
`;
export const useCoachEvaluationList = (variables) => useQuery(COACH_EVALUATION_LIST, {variables});


export const ADD_COACH_EVALUATION = gql`
    mutation AddClubEvaluation (
        $clubId: String!
        $keyItem: Uuid!
        $title: String!
        $document: String!
        $clubEvaluation: Decimal!
        $couchEvaluation: Decimal!
    ) {
      addClubEvaluation(
        input: {
          clubId: $clubId
          keyItem: $keyItem
          title: $title
          document: $document
          clubEvaluationValue: $clubEvaluation
          couchEvaluationValue: $couchEvaluation
        }
      )
      {
        id
        clubId
        keyItem
        date
        title
        document
        clubEvaluationValue
        couchEvaluationValue
      }
}`;
export const useAddCouchEvaluation = (clubId) => useMutation(ADD_COACH_EVALUATION, {
  update: function (cache, {data: {addClubEvaluation}}) {
    const {clubEvaluations} = cache.readQuery({
      query: COACH_EVALUATION_LIST,
      variables: {"clubId": addClubEvaluation.clubId, "keyItem": addClubEvaluation.keyItem}
    });

    cache.writeQuery({
      query: COACH_EVALUATION_LIST,
      data: {clubEvaluations: [addClubEvaluation, ...clubEvaluations]},
      variables: {"clubId": addClubEvaluation.clubId, "keyItem": addClubEvaluation.keyItem}
    });
  }
});

export const EDIT_COACH_EVALUATION = gql`
        mutation EditClubEvaluation (
            $id: Uuid!
            $document: String!
            $clubEvaluation: Decimal!
            $couchEvaluation: Decimal!
            ) {
          editClubEvaluation(
            input: {
              id: $id
              document: $document
              clubEvaluationValue: $clubEvaluation
              couchEvaluationValue: $couchEvaluation
            }
          ) {
            id
            clubId
            keyItem
            date
            title
            document
            clubEvaluationValue
            couchEvaluationValue
          }
        }`;

export const useEditCouchEvaluation = (clubId) => useMutation(EDIT_COACH_EVALUATION);

export const DELETE_COUCH_EVALUATION = gql`
  mutation DeleteClubEvaluation($input: DeleteInput!) {
    deleteClubEvaluation(input: $input)
  }`;
export const useDeleteClubEvaluation = () => useMutation(DELETE_COUCH_EVALUATION);


export const GAMES_EVALUATION_LIST = gql`
  query GameEvaluations ($clubId: String!) {
    trainers(
      clubId: $clubId
      take: 100
    ) {
      items {
        trainerId
        fullName
      }
    }
    clubGameEvaluations (
      clubId: $clubId
      order: {date: DESC}
    ) {
      id
      clubId
      keyItem
      date
      title
      document
      evaluation
    }
  }
`;
export const useGameEvaluationList = (variables) => useQuery(GAMES_EVALUATION_LIST, {variables});


export const SAVE_CLUB_GAME_EVALUATION = gql`
  mutation SaveClubGameEvaluation ($input: ClubGameEvaluationInput!) {
    saveClubGameEvaluation(
      input: $input
    ) {
      id
      clubId
      keyItem
      date
      title
      document
      evaluation
    }
  }`;

export const useSaveClubGameEvaluation = () => useMutation(SAVE_CLUB_GAME_EVALUATION);


export const DELETE_GAME_EVALUATION = gql`
  mutation DeleteClubGameEvaluation($input: DeleteInput!) {
    deleteClubGameEvaluation(input: $input)
  }`;
export const useDeleteClubGameEvaluation = () => useMutation(DELETE_GAME_EVALUATION);
