import React, {useEffect, useState} from 'react';
import { useTable, useRowSelect } from 'react-table';
import {useTranslation} from "react-i18next";
import Empty from '../Empty/Empty';
import classes from './Table.module.scss';
import cn from 'classnames';
import {ContextMenu, ContextMenuTrigger, hideMenu, MenuItem, showMenu} from 'react-contextmenu';
import {useReactiveVar} from '@apollo/client';
import {systemDataVar} from 'apollo/cashe';


const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        )
    }
)

const Table = ({className, columns, data, access, center, onAddClick, onRowClick, onRowCheck, onSelectClick, onSettings, empty, rowClassName})  => {
    const { t } = useTranslation();
    const systemData = useReactiveVar(systemDataVar);
    const [menuId] = useState(`menu-${Math.random()}`);
    const columnsHook = (hooks) => {
        if (access && access.includes(systemData.user.userRole.roleId) === false) {
            return;
        }

        if (onSelectClick !== undefined) {
            hooks.visibleColumns.push(columns => [
                {
                    id: 'selection',
                    Header: ({getToggleAllRowsSelectedProps}) => (
                        <div><IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} /></div>
                    ),
                    Cell: ({row}) => (
                        <div><IndeterminateCheckbox {...row.getToggleRowSelectedProps()} /></div>
                    ),
                },
                ...columns,
            ]);
        }

        if (onSettings) {
            hooks.visibleColumns.push(columns => [
                ...columns,
                {
                    id: 'settings',
                    Header: () => (<div></div>),
                    className: classes.settings,
                    Cell: ({row}) => (
                        <ContextMenuTrigger id={menuId} collect={() => (row.original)}>
                            <span onClick={(e) => handleLeftMouseClick(e, row.original)} className={cn("icon-dots", classes.dots)}></span>
                        </ContextMenuTrigger>
                    ),
                }
            ]);
        }
    }

    function handleLeftMouseClick(e, data) {
        hideMenu();
        const x = e.clientX;
        const y = e.clientY;
        showMenu({
            position: { x, y },
            id: menuId,
            data: data
        });
    }


    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, selectedFlatRows, state: { selectedRowIds } } = useTable({ columns, data },
        useRowSelect, hooks => columnsHook(hooks));

    useEffect(() => {
        if (onSelectClick === undefined)
            return;

        onSelectClick(selectedFlatRows.map(
            d => d.original
        ));

    }, [selectedRowIds]);


    if (rows.length == 0 && empty) return (<Empty onAddClick={onAddClick} />);

    const getColumnsCount = () => {
        return onSelectClick !== undefined
            ? headerGroups[0].headers.length + 1
            : headerGroups[0].headers.length
    }

    return (
        <div className={cn(className, classes.scroll, "table-portal")}>
            <table className={classes.Table} {...getTableProps()} >
                <thead className={classes.header}>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column, i) => (
                            <th className={cn(column.className, {[classes.center]:center && i > 1})} {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody className={classes.tbody} {...getTableBodyProps()}>

                {rows.map((row, index) => {
                    prepareRow(row);

                    return (
                        <tr {...onRowCheck(row.original)}
                            {...row.getRowProps()} className={rowClassName(row.original)}>
                            {row.cells.map((cell, i) => {
                                if (cell.column.id === 'settings') {
                                    return <td {...cell.getCellProps({className: cn(cell.column.className, {[classes.center]:center && i > 1})
                                    })}>{cell.render('Cell')}</td>
                                }

                                cell.access = access;

                                return <td  onClick={() => onRowClick(row.original)}
                                            {...cell.getCellProps({className: cn(cell.column.className, {[classes.center]:center && i > 1})
                                })}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                })}

                {rows.length === 0 ? <tr><td colSpan={getColumnsCount()}> {t("No data to display.")}</td></tr> : undefined }

                </tbody>
            </table>

            {onSettings ? <ContextMenu id={menuId}>
                {onSettings().map((item, index) => <MenuItem key={index} onClick={(event, data) => item.action(data)}>{item.label}</MenuItem>)}
            </ContextMenu> : undefined}
        </div>
    );
}
Table.defaultProps = {
    rowClassName: () => {return undefined},
    onRowClick: () => {},
    onRowCheck: () => {},
    className: {},
    empty: false,
    center: false
}
export default Table;
