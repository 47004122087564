import React from 'react';
import classes from './Progress.module.scss';
import {useTranslation} from 'react-i18next';

const Progress = props => {
  const step = classes['line' + props.step];
  const {t} = useTranslation();

  return (
      <div className={classes.Progress}>
        <div className={classes.line}>
          <div className={step}></div>
        </div>
        <div>{t('step')} {props.step} {t('fromStep')} {props.max}</div>
      </div>
  );
};

export default Progress;
