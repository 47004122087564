import React, { useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Tabs from 'components/UI/Tabs/Tabs';
import TimerForm from './forms/TimerForm';
import DrawForm from './forms/DrawForm';
import {toMSS} from 'utils/date';
import PlayerList from './PlayerList';
import classes from './VideoEditor.module.scss';
import cn from 'classnames';
import EventList from './EventList';
import VideoPlayer from './VideoPlayer';
import {createGuid} from 'utils/utils';
import {
    useDeleteDataEventRecord,
    useSaveDataEventRecord
} from 'apollo/queries/GameQueries';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import {DataEvents} from '../Settings';
import {fabric} from 'fabric';

const VideoEditor = (props) => {
    const {players} = props.data;

    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const ref = React.createRef();
    const canvasId = `canvas-${Math.random()}`;

    const [showElement, setShowElement] = useState("event");
    const [playlist, setPlaylist] = useState([]);
    const [selectedTab, setSelectedTab] = useState("events");
    const [canvas, setCanvas] = useState(null);
    const [showEventList, setShowEventList] = useState(false);
    const [selectedPlayerId, setSelectedPlayerId] = useState(null);
    const [selectedTeamId, setSelectedTeamId] = useState(null);
    const [selectedRecordId, setSelectedRecordId] = useState(null);
    const [timer, setTimer] = useState("0.00");

    const [saveDataEventRecord] = useSaveDataEventRecord();
    const [deleteDataEventRecord] = useDeleteDataEventRecord();
    //
    // const [alert, setAlert] = useState({
    //     open: false,
    //     formId: `form-${Math.random()}`,
    //     title: t("Alert"),
    // });

    useEffect(() => {
        if (canvas == null) {
            const newCanvas = new fabric.Canvas(canvasId);
            newCanvas.freeDrawingBrush.color = "#ff0000";
            setCanvas(newCanvas);
        }
    }, []);

    useEffect(() => {
        filterEvents(selectedTab)
    }, [props.data.eventRecords]);

    const onAddTag = () => {
        setShowElement("event");
        ref.current.handleStop();
        onStopInteractive();
    }

    const onDraw = () => {
        setShowElement("draw");
        ref.current.handleStop();
    }

    const onShowPlaylist = () => {
        setShowElement("playlist");
        ref.current.handleStop();
        onStopInteractive();
    }

    const changePlaylist = (selected) => {
        setSelectedTab(selected);
        filterEvents(selected);
    }

    const filterEvents = (selected) => {
        switch (selected) {
            case "events":
                setPlaylist(props.data.eventRecords.filter(x => x.eventType !== "NOTE" && x.videoId === props.video.videoId));
                break;
            case "notes":
                setPlaylist(props.data.eventRecords.filter(x => x.eventType === "NOTE" && x.videoId === props.video.videoId));
                break;
            case "all":
                setPlaylist(props.data.eventRecords);
                break;
        }
    }

    const draw = (context) => {
        context.fillStyle = "rgb(200, 0, 0)";
        context.fillRect(10, 10, 50, 50);

        context.fillStyle = "rgba(0, 0, 200, 0.5)";
        context.fillRect(30, 30, 50, 50);
    };

    const onPlayerClick = (data) => {
        setShowEventList(true);
        setSelectedTeamId(data.team ? data.id : null);
        setSelectedPlayerId(data.team ? null : data.playerId);
        ref.current.handleStop();
    }

    const onEventClicked = (data) => {

        const variables = {
            input: {
                id: createGuid(),
                clubId: navState.clubId,
                teamId: navState.teamId,
                gameId: props.data.gameId,
                eventType: "PLAYER_EVENT",
                gameDataEventId: data.eventId,
                videoId: props.video.videoId,
                playerId: selectedPlayerId,
                time: ref.current.getEventTime(-2),
                duration: ref.current.getEventDuration(10),
                result: data.result
            }
        };

        if (selectedPlayerId == null) {
            variables.input.eventType = props.data.isWeOwnerTeam === true && props.data.ownerTeamId === selectedTeamId ? "TEAM_EVENT" : "OPPONENT_EVENT";
        }

        saveDataEventRecord({variables: variables}).then(result => {
            props.onComplete();
        });

        setShowEventList(false);
        setSelectedTeamId(null);
        setSelectedPlayerId(null);
        ref.current.handlePlay();
    }

    const onCloseEventList = () => {
        setShowEventList(false);
        setSelectedTeamId(null);
        setSelectedPlayerId(null);
        ref.current.handlePlay();
    }

    const onSeekEvent = (data) => {
        if (data.eventType === "NOTE") {
            setSelectedRecordId(data.id);
            ref.current.handleStop();
            ref.current.setEventTime(data.time);

            canvas.loadFromJSON(data.data, () => {
                canvas.renderAll();
                onStopInteractive();
            });

        } else {
            onClearCanvas();
            ref.current.setEventTime(data.time);
            ref.current.handlePlay();
        }
    }

    const onDeleteEvent = (data) => {
        const variables = {
            input: {
                id: data.id,
                clubId: navState.clubId,
                teamId: navState.teamId,
            }
        };

        deleteDataEventRecord({variables: variables}).then(result => {
            props.onComplete();
        });
    }

    const onTimeChange = (data) => {
        setTimer(data);
    }

    const onUserSeek = () => {
        onClearCanvas();
    }

    const onSaveCanvas = () => {
        if (canvas !== null && canvas.getObjects().length > 0) {
            const id = selectedRecordId ?? createGuid();

            if (id !== selectedRecordId) {
                setSelectedRecordId(id);
            }

            const variables = {
                input: {
                    id: id,
                    clubId: navState.clubId,
                    teamId: navState.teamId,
                    gameId: props.data.gameId,
                    eventType: "NOTE",
                    gameDataEventId: DataEvents.Note,
                    videoId: props.video.videoId,
                    time: ref.current.getEventTime(0),
                    duration: ref.current.getEventDuration(0),
                    data: JSON.stringify(canvas)
                }
            };

            saveDataEventRecord({variables: variables}).then(result => {
                props.onComplete();
            });
        }
    }

    const onClearCanvas = () => {
        if (canvas !== null && canvas.getObjects().length > 0) {
            canvas.clear();
            setSelectedRecordId(null);
            canvas.renderAll();
        }
    }

    const onStopInteractive = () => {
        if (canvas !== null && canvas.getObjects().length > 0) {
            canvas.forEachObject(object => {
                object.selectable = false;
                object.evented = false;
            });
            canvas.renderAll();
        }
    }

    const onPlay = () => {
        onClearCanvas();
    }

    return (
        <div id="videoEditorV2" className={classes.videoEditor}>
            <div className={classes.panel}>
                <ul className={classes.toolbar}>
                    <li className={cn([classes.button],{[classes.active]:showElement === "event"})} onClick={onAddTag}>
                        <span className="icon-bookmark2"></span>
                    </li>
                    <li className={cn([classes.button],{[classes.active]:showElement === "playlist"})} onClick={onShowPlaylist}>
                        <span className="icon-Burger"></span>
                    </li>
                    <li className={cn([classes.button],{[classes.active]:showElement === "draw"})}  onClick={onDraw}>
                        <span className="icon-edit"></span>
                    </li>
                </ul>

                {showElement === "event" && <PlayerList onClick={onPlayerClick} data={props.data} selectedId={selectedPlayerId !== null ? selectedPlayerId : selectedTeamId} />}
                {showElement === "timer" && <TimerForm onSave={() => setShowElement(null)} onClose={() => setShowElement(null)} />}
                {showElement === "draw" && <DrawForm canvas={canvas} onSave={onSaveCanvas} onClear={onClearCanvas} />}

                {showElement === "playlist" && <div>
                    <div className={classes.tabs}>
                        <Tabs items={props.tabs} selected={selectedTab} onClick={changePlaylist} />
                    </div>

                    <ul className={cn(classes.editorList, {[classes.records]:showElement === "playlist"})}>
                        {playlist && playlist.map(event => {
                            const player = players.find(p => p.playerId === event.playerId);
                            const ourTeam = props.data.isWeOwnerTeam ? props.data.ownerTeamName : props.data.otherTeamName;
                            const otherTeam = !props.data.isWeOwnerTeam ? props.data.ownerTeamName : props.data.otherTeamName;
                            return (<li key={event.id} className={cn({[classes.belongThisVideo]: event.videoId === props.video.videoId})}>
                                <span className={event.eventType === "NOTE" ? "icon-edit" : "icon-bookmark2"}></span>
                                <span onClick={() => onSeekEvent(event)} >{toMSS(event.time)} {event.title}
                                    {event.eventType === "PLAYER_EVENT" && player ? ` - ${player.playerName} (${player.teamNumber})` : ""}
                                    {event.eventType === "TEAM_EVENT" ? ` - ${ourTeam}` : ""}
                                    {event.eventType === "OPPONENT_EVENT" ? ` - ${otherTeam}` : ""}
                                </span>
                                <span onClick={() => onDeleteEvent(event)} className="icon-Delete"></span>
                            </li>
                            );
                        })}
                    </ul>
                </div>}

            </div>
            <div className={classes.main}>
                <div className={classes.scoreboard} >
                    <div className={classes.bar}>
                        <div className={classes.plus1}><span className="icon-change"></span></div>
                        <div className={classes.team1}>{props.data.ownerTeamName}</div>
                        <div className={classes.scoreGroup}>
                            <div className={classes.score}>{props.data.resultLeft}</div>
                            <div  onClick={() => setShowElement("timer")} className={classes.time}>{timer}</div>
                            <div className={classes.score}>{props.data.resultRight}</div>
                        </div>
                        <div className={classes.team2}>{props.data.otherTeamName}</div>
                        <div className={classes.plus2}><span className="icon-change"></span></div>
                    </div>
                </div>

                <div className={classes.player}>
                    <VideoPlayer
                        width={640}
                        height={360}
                        ref={ref}
                        url={props.video.link}
                        play={false}
                        canvas={true}
                        controls={props.video.store === "YOPLAYDO"}
                        canvasId={canvasId}
                        canvasShow={showElement === "draw"}
                        onTimeChange={onTimeChange}
                        onPlay={onPlay}
                        onUserSeek={onUserSeek}
                    />
                </div>

                {showEventList && <EventList data={props.data} onClick={onEventClicked} onClose={onCloseEventList} />}
            </div>

            {/*{alert.open && <Alert*/}
            {/*    theme="dark"*/}
            {/*    title={alert.title}*/}
            {/*    formSubmitId={alert.formId}*/}
            {/*    onCloseClick={() => setAlert({...alert, open: false})}*/}
            {/*>*/}
            {/*    <div>{alert.text}</div>*/}
            {/*</Alert>}*/}
        </div>
    );
};

VideoEditor.propTypes = {};
VideoEditor.defaultProps = {
    data: {},
    video: {
        events: []
    },
    tabs: [
        {id: "all", name: "All"},
        {id: "events", name: "Events"},
        {id: "notes", name: "Notes"}
    ]
};

export default VideoEditor;
