import React, {useEffect, useRef, useState} from 'react';
import classes from '../Settings.module.scss';
import {useTranslation} from 'react-i18next';
import ImageUpload from '../../../components/UI/ImageUpload/ImageUpload';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from '../../../apollo/cashe';
import {notHaveErrors, validateFields, validateForm} from '../../../utils/form';
import * as yup from 'yup';
import Input from '../../../components/UI/Input/Input';
import Select from '../../../components/UI/Select/Select';
import {useClubInfo} from '../../../apollo/queries/ClubQueries';
import {
    useCountrySettings,
    useSaveClubAddress,
    useSaveClubBasic,
    useSaveClubContact
} from '../../../apollo/queries/SettingQueries';
import Button from '../../../components/UI/Button/Button';
import {useAuth} from 'react-oidc-context';
import {fileUploader} from 'utils/upload';

const Club = (props) => {
    const {t} = useTranslation();
    const auth = useAuth();
    const logoUploadRef = useRef();
    const navState = useReactiveVar(naviDataVar);

    const [errors, setErrors] = useState({});
    const [values, setValues] = useState({});
    const [image, setImage] = useState(null);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);

    const [basic, setBasic] = useState(false);
    const [address, setAddress] = useState(false);
    const [contact, setContact] = useState(false);

    const basicFields = ['name', 'registrationNumber', 'clubFormat', 'logo'];
    const addressFields = ['country', 'state', 'city'];
    const contactsFields = ['email', 'phone'];

    const [getCountrySettings, countrySettingsData] = useCountrySettings();
    const [getClubInfo, clubInfoData] = useClubInfo();

    const [saveClubBasic, saveClubBasicData] = useSaveClubBasic({clubId: navState.clubId});
    const [saveClubAddress, saveClubAddressData] = useSaveClubAddress({clubId: navState.clubId});
    const [saveClubContact, saveClubContactData] = useSaveClubContact({clubId: navState.clubId});

    useEffect(() => {
        getCountrySettings();
        getClubInfo({variables: {clubId: navState.clubId}});
    }, []);

    useEffect(() => {
        if (!countrySettingsData.data) return;

        const countryList = countrySettingsData.data.country.map(x => ({label: x.value, value: x.key}));
        setCountries(countryList);

        const stateList = countrySettingsData.data.state.map(x => {
            const json =  JSON.parse(x.jsonAttributes);
            return {label: x.value, value: x.key, key: json[0]?.Value ?? ""};
        });
        setStates(stateList);
    }, [countrySettingsData.data]);

    useEffect(() => {
        if (!clubInfoData.data) return;

        const {clubId, logo, name, registrationNumber, clubFormat, country, state, city, email, phone}
            = clubInfoData.data.club;

        const newValues = {
            clubId: clubId,
            logo: logo,
            name: name,
            clubFormat: clubFormat,
            registrationNumber: registrationNumber,
            country: country,
            state: state,
            city: city,
            email: email,
            phone: phone
        };

        setValues(newValues);
    }, [clubInfoData.data]);


    const fieldChanged = (event) => {
        const newValues = {...values};
        newValues[event.target.name] = event.target.value;

        if (event.target.file !== undefined) {
            newValues[event.target.name+"File"] = event.target.file;
        }

        let validationRules = {};

        if (basicFields.includes(event.target.name)) {
            setBasic(true);
            validationRules = basicValidationRules;
        }

        if (addressFields.includes(event.target.name)) {
            setAddress(true);
            validationRules = addressValidationRules;
        }

        if (contactsFields.includes(event.target.name)) {
            setContact(true);
            validationRules = contactsValidationRules;
        }

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });

        setValues(newValues);
    }

    const basicValidationRules = {
        name: yup.string().required(t("required")),
        clubFormat: yup.string().required(t("required")),
    }

    const addressValidationRules = {
        country: yup.string().required(t("required")),
        city: yup.string().required(t("required")),
    }

    const contactsValidationRules = {
        email: yup.string().required(t("required")),
        phone: yup.string().required(t("required")),
    }

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors
    };

    const onImageChange = (data) => {
        console.log(data);
        setImage(data);
    }

    useEffect(() => {
        if (!image) return;
        fieldChanged(image);
    }, [image]);

    const onBasicSave = async () => {
        validateForm(values, basicValidationRules, async (errors) => {
            if (notHaveErrors(errors)) {
                await sendClubBasic();
            } else {
                setErrors(errors);
            }
        });
    }

    const sendClubBasic = async () => {
        const variables = {
            input: {
                clubId: navState.clubId,
                name: values.name,
                registrationNumber: values.registrationNumber,
                clubFormat: values.clubFormat,
            }
        };

        if (values.logoFile !== undefined) {
            const fileData = {
                clubId: navState.clubId,
                fileId: navState.clubId
            }

            if (await fileUploader(values.logoFile, fileData, auth)) {
                variables.input.logoUploadDate = new Date().toISOString();
            }
        }

        await saveClubBasic({variables: variables});
        setBasic(false);
    }

    const onAddressSave = () => {
        validateForm(values, basicValidationRules, async (errors) => {
            if (notHaveErrors(errors)) {
                sendClubAddress();
            } else {
                setErrors(errors);
            }
        });
    }

    const sendClubAddress = () => {
        const variables = {
            input: {
                clubId: navState.clubId,
                country: values.country,
                state: values.state,
                city: values.city,
            }
        };

        saveClubAddress({variables: variables}).then(() => {
            setAddress(false);
        });
    }

    const onContactSave = () => {
        validateForm(values, basicValidationRules, async (errors) => {
            if (notHaveErrors(errors)) {
                sendClubContact();
            } else {
                setErrors(errors);
            }
        });
    }

    const sendClubContact = () => {
        const variables = {
            input: {
                clubId: navState.clubId,
                email: values.email,
                phone: values.phone,
            }
        };

        saveClubContact({variables: variables}).then(() => {
            setContact(false);
        });
    }

    return (
        <>
            <div onClick={props.onBack} className={[classes.title, classes.link].join(" ")}>
                <i className={["icon-Arrow---Up", classes.backButton, "backArrow"].join(" ")}></i>
                {t("Club")}
            </div>

            <div className={classes.logo}>
                <div>

                    <Input
                        name="name"
                        label={t('Club name')}
                        {...regField}
                    />

                    <Input
                        name="registrationNumber"
                        label={t('Registration Number')}
                        {...regField}
                    />

                    <Select
                        name="clubFormat"
                        {...regField}
                        options={props.format}
                        placeholder={t('Format')}
                    />

                </div>

                <div>
                    <ImageUpload
                        name="logo"
                        ref={logoUploadRef}
                        label={t('Club logo')}
                        text="Add club logo"
                        value={values}
                        error={errors}
                        onChange={onImageChange}
                    />
                </div>

            </div>

            {basic && <Button type="primary" onClick={onBasicSave} style={{margin: "0 0 10px"}}>Save</Button>}
            {saveClubBasicData?.loading && <div className={classes.loading}>{t("Saving... please wait.")}</div>}
            {!basic && saveClubBasicData?.data && <div className={classes.saved}>{t("Settings are saved.")}</div>}
            {!basic && saveClubBasicData?.error && <div className={classes.error}>{t("Error, settings are not saved.")}</div>}

            <div className={classes.title}>{t("Address")}</div>

            <Select
                name="country"
                {...regField}
                options={countries}
                placeholder={t('Country')}
            />

            {states && states.filter(x => x.key === values?.country).length > 0 ?
                <Select
                    name="state"
                    {...regField}
                    label={"State"}
                    options={states.filter(x => x.key === values?.country)}
                    placeholder={t('State / region')}
                />
                : undefined }

            <Input
                name="city"
                label={t('Address')}
                {...regField}
            />

            {address && <Button type="primary" onClick={onAddressSave} style={{margin: "0 0 10px"}}>Save</Button>}
            {saveClubAddressData?.loading && <div className={classes.loading}>{t("Saving... please wait.")}</div>}
            {!address && saveClubAddressData?.data && <div className={classes.saved}>{t("Settings are saved.")}</div>}
            {!address && saveClubAddressData?.error && <div className={classes.error}>{t("Error, settings are not saved.")}</div>}

            <div className={classes.title}>{t("Contacts")}</div>

            <Input
                name="email"
                label={t('E-mail')}
                {...regField}
            />

            <Input
                name="phone"
                label={t('Phone')}
                {...regField}
            />

            {contact && <Button type="primary" onClick={onContactSave} style={{margin: "0 0 10px"}}>Save</Button>}
            {saveClubContactData?.loading && <div className={classes.loading}>{t("Saving... please wait.")}</div>}
            {!contact && saveClubContactData?.data && <div className={classes.saved}>{t("Settings are saved.")}</div>}
            {!contact && saveClubContactData?.error && <div className={classes.error}>{t("Error, settings are not saved.")}</div>}
        </>
    );
};

Club.defaultProps = {
    format: [
        {label: "Club", value: "NONE"},
        {label: "Private Club", value: "COMMERCIAL_CLUB"},
        {label: "School", value: "SPORT_SCHOOL"},
        {label: "Academy", value: "FOOTBALL_ACADEMY"},
        {label: "Federation", value: "FOOTBALL_FEDERATION"},
        {label: "Other", value: "OTHER"},
    ],
};

export default Club;
