import './App.css';
import {useAuth} from 'react-oidc-context';
import {useEffect} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {ApolloClient, ApolloProvider} from '@apollo/client';
import {onError} from '@apollo/client/link/error';
import {createUploadLink} from 'apollo-upload-client';
import {cache} from 'apollo/cashe';
import Routes from 'Routes';
import {GetRootPage} from 'components/Navigation/CanAccess/roles';
import {useTranslation} from 'react-i18next';

function App() {
  const auth = useAuth();
  const {i18n } = useTranslation();

  document.body.dir = i18n.dir(i18n.language);

  const anonymousCanAccessTo = [
      '/registration',
      '/confirm-email',
  ];

  useEffect(() => {
    const pathname = window.location.pathname;

    if (auth.isLoading === true || auth.isAuthenticated === true || auth.error !== undefined) {
      return;
    }

    if (anonymousCanAccessTo.includes(window.location.pathname)) {
      return;
    }

    if (pathname !== '/') {
      auth.signinRedirect({redirect_uri: process.env.REACT_APP_REDIRECT_URI + "?returnUrl=" + pathname});
    } else {
      auth.signinRedirect({redirect_uri: process.env.REACT_APP_REDIRECT_URI});
    }

  }, [auth]);


  const uploadLink = createUploadLink({
    uri:  process.env.REACT_APP_GRAPHQL_URL,
    headers: {
      authorization: `Bearer ${auth.user?.access_token}`
    }
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(async error =>
      {
        console.log(error);
        if (error.extensions?.code === "AUTH_NOT_AUTHENTICATED" && anonymousCanAccessTo.includes(window.location.pathname) === false) {
          await auth.signoutRedirect();
        }

        const rootUrl = GetRootPage();
        if (error.extensions.code === "AUTH_NOT_AUTHORIZED" && window.location.href !== rootUrl) {
          console.log("AUTH_NOT_AUTHORIZED - " + error.path[0] ?? "");
          window.location.href = rootUrl;
        }
      });
    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  const client = new ApolloClient({
    link: errorLink.concat(uploadLink),
    cache: cache,
    connectToDevTools: true
  })

  if (auth.isLoading === true) {
    return (
        <div>Loading...</div>
    );
  }

  return (
      <div className="App">
        <Router>
          <ApolloProvider client={client}>
            <Routes />
          </ApolloProvider>
        </Router>
      </div>
  );
}

export default App;
