import React from 'react';
import {useReactiveVar} from '@apollo/client';
import {systemDataVar} from 'apollo/cashe';

const CanAccess = (props) => {
    const systemData = useReactiveVar(systemDataVar);
    return (
        <>{props.users.includes(systemData.user.userRole.roleId) ? props.children : undefined}</>
    );
};

CanAccess.defaultProps = {};

export default CanAccess;
