import React, {useState} from 'react';
import DatePicker from '../../../../components/UI/DatePicker/DatePicker';
import Select from '../../../../components/UI/Select/Select';
import {notHaveErrors, validateFields, validateForm} from '../../../../utils/form';
import * as yup from 'yup';
import {dateAsTimestamp} from '../../../../utils/date';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from '../../../../apollo/cashe';
import Textarea from '../../../../components/UI/Textarea/Textarea';
import {useSavePlayerSkill} from '../../../../apollo/queries/PlayerQueries';

const CommentForm = (props) => {
    const {defaultValues, skills, setLoading} = props;

    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const [values, setValues] = useState(defaultValues ?? {});
    const [errors, setErrors] = useState({});

    const [savePlayerSkill] = useSavePlayerSkill();

    const fieldChanged = (event) => {
        const newValues = { ...values };
        newValues[event.target.name] = event.target.value;

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });

        setValues(newValues);
    }

    const validationRules = {
        date: yup.string().required(t("required")),
        skillId: yup.string().required(t("required")),
        comment: yup.string().required(t("required")),
    }

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors
    };

    const formSubmitted = (e) => {
        e.preventDefault();
        setLoading(true);
        validateForm(values, validationRules, (errors) => {
            if (notHaveErrors(errors))
                saveData();
            else
                setErrors(errors);
            setLoading(false);
        });
    };

    const saveData = () => {
        const variables = {
            input: {
                playerId: navState.playerId,
                dateVal: dateAsTimestamp(values.date),
                skills: [{skillId: values.skillId, comment: values.comment}]
            }
        }

        savePlayerSkill({variables: variables}).then(result => {
            props.onComplete();
        });
    };

    return (
        <>
            <form id={props.id} onSubmit={formSubmitted}>
                <DatePicker
                    name="date"
                    onChange={() => {}}
                    label={t('Date')}
                    {...regField}
                />

                <Select
                    name="skillId"
                    label={t('Category')}
                    options={skills.map(x => ({label: x.name, value: x.skillId}))}
                    {...regField}
                />

                <Textarea
                    name="comment"
                    height="150px"
                    label={t("Record")}
                    {...regField}
                />
            </form>
        </>
    );
};

CommentForm.defaultProps = {};

export default CommentForm;
