import React, {PropTypes, useEffect, useState} from 'react';
import Submenu from 'components/Navigation/Submenu/Submenu';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import cn from 'classnames';
import classes from './MatchesDetails.module.scss';
import {useGameDetails} from 'apollo/queries/GameQueries';
import {useParams} from 'react-router-dom';
import MatchesDetailsPlayers from './MatchesDetailsPlayers';
import MatchesDetailsVideo from './MatchesDetailsVideo';
import MatchesDetailsTtp from './MatchesDetailsTtp';
import MatchesDetailsPositions from './MatchesDetailsPositions';
import MatchesDetailsStat from './MatchesDetailsStat';
import {MatchType} from './Settings';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import MatchForm from '../Matches/forms/MatchForm';
import Button from 'components/UI/Button/Button';
import SwitchComponents from 'components/System/SwitchComponents';
import ShowDateTime from 'components/UI/DatePicker/ShowDateTime';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';
import CanAccess from 'components/Navigation/CanAccess/CanAccess';

const MatchesDetails = (props) => {
    const {t} = useTranslation();
    const { gameId } = useParams();

    const navState = useReactiveVar(naviDataVar);
    const [type, setType] = useState("players");
    const [data, setData] = useState(props.data);

    const [modalData, setModalData, initState] = ModalState();

    const [getMatchesDetails, getMatchesDetailsData] = useGameDetails();

    useEffect(() => {
        if (navState.teamId === null || gameId === undefined)
            return;

        const variables = {
            teamId: navState.teamId,
            clubId: navState.clubId,
            where: {
                "gameId": {"eq": gameId}
            }
        };

        getMatchesDetails({variables: variables});
    }, [navState, gameId]);

    useEffect(() => {
        if(!getMatchesDetailsData.data) return;
        setData(getMatchesDetailsData.data.games.items[0]);
    }, [getMatchesDetailsData]);

    const onEditMatch = () => {
        const {gameId, ownerTeamId, ownerTeamName, otherTeamId, otherTeamName, scope, date,
            duration, awayMatch, periodCount, location, championshipId, matchNotes, players}
            = getMatchesDetailsData.data?.games?.items[0] ?? {};

        const _data = {
            ...initState,
            title: t("Edit match"),
            form: "edit",
            pages: {
                page: 0,
                maxPages: 1,
            },
            styles: {
                maxWidth: "700px",
            },
            defaultValues: {
                gameId: gameId,
                name: ownerTeamName,
                scope: scope,
                opponent: otherTeamId,
                venue: location,
                date: date,
                duration: duration,
                periodCount: periodCount,
                awayMatch: awayMatch,
                competition: championshipId,
                matchNotes: matchNotes,
            },
            onComplete: onComplete,
            open: true
        }

        setModalData(_data);
    }

    const onComplete = () => {
        setModalData(initState);
        getMatchesDetailsData.refetch();
    }

    const Header = () => {
        const {scope, date, duration, periodCount} = getMatchesDetailsData.data?.games?.items[0] ?? {};
        const selectedType = MatchType().find(m => m.value === scope);

        return (
            <div className={classes.quickInfo}>
                <div>
                    <div><span>{t("Match type")}:</span> {t(selectedType?.label) ?? "-"}</div>
                    <div><span>{t("Date")}:</span> {date ? <ShowDateTime date={date} /> : "-"}</div>
                </div>
                <div>
                    <div><span>{t("Duration")}:</span> {duration} {t("min.")}</div>
                    <div><span>{t("Period count")}:</span> {periodCount}</div>
                </div>
            </div>
        );
    }

    return (

        <>
            <div className={cn('page-header', classes.MatchesDetails)}>
                <div className={classes.name}>{data.name ?? t('Match')}</div>
                {data.awayMatch
                    ? <h2>{data.otherTeamName } <span>{data.resultLeft}</span> : <span>{data.resultRight}</span> {data.ownerTeamName }</h2>
                    : <h2>{data.ownerTeamName } <span>{data.resultLeft}</span> : <span>{data.resultRight}</span> {data.otherTeamName }</h2>}
                <CanAccess users={[SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin]}>
                    <ul className={classes.actions}>
                        <li><Button type="third" style={{marginTop: "0px"}} onClick={onEditMatch}><i className="icon-Setting"> </i> {t("Edit match")}</Button></li>
                    </ul>
                </CanAccess>
                <Header/>
                <Submenu data={props.types} onFilter={(t) => setType(t)}/>
            </div>
            <div className="page-content">
                {getMatchesDetailsData.loading && <>Loading...</>}
                {getMatchesDetailsData.error && <>Error...</>}
                {getMatchesDetailsData.data && <>

                    {type === "players" && <MatchesDetailsPlayers data={getMatchesDetailsData.data.games.items[0]} refetch={getMatchesDetailsData.refetch} />}
                    {type === "stat" && <MatchesDetailsStat data={getMatchesDetailsData.data.games.items[0]} refetch={getMatchesDetailsData.refetch} />}
                    {type === "positions" && <MatchesDetailsPositions data={getMatchesDetailsData.data.games.items[0]} refetch={getMatchesDetailsData.refetch} />}
                    {type === "ttp" && <MatchesDetailsTtp data={getMatchesDetailsData.data.games.items[0]} refetch={getMatchesDetailsData.refetch}  />}
                    {type === "video" && <MatchesDetailsVideo data={getMatchesDetailsData.data.games.items[0]} refetch={getMatchesDetailsData.refetch} />}
                </>}
            </div>

            <ModalWindow2 {...modalData}>
                <SwitchComponents active={modalData.form}>
                    <MatchForm {...modalData} name="edit" />
                </SwitchComponents>
            </ModalWindow2>
        </>
    );
};

MatchesDetails.propTypes = {};
MatchesDetails.defaultProps = {
    types: {
        players: "Players stat",
        stat: "Match stat",
        positions: "Tactical positions",
        video: "Video",
        ttp: "TTP",
    },
    data: {
        name: "",
        ownerTeamName: "",
        otherTeamName: "",
        resultLeft: 0,
        resultRight: 0
    }
};

export default MatchesDetails;
