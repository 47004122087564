import React, {useState} from 'react';
import Input from 'components/UI/Input/Input';
import {useTranslation} from 'react-i18next';
import {notHaveErrors, validateFields, validateForm} from 'utils/form';
import * as yup from 'yup';
import {useAddGameVideoLink} from 'apollo/queries/GameQueries';
import {useParams} from 'react-router-dom';

const YouTubeForm = (props) => {
    const [values, setValues] = useState({});
    const [errors, setErrors] = useState({});
    const {gameId} = useParams();
    const [addGameVideoLink] = useAddGameVideoLink();
    const {t} = useTranslation();

    const fieldChanged = (event) => {
        const newValues = { ...values };
        newValues[event.target.name] = event.target.value

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });

        setValues(newValues);
    }

    const validationRules = {
        link: yup.string().required(t("required")).url(t("required")),
        name: yup.string().required(t("required")),
    }

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors
    };

    const formSubmitted = (e) => {
        e.preventDefault();
        validateForm(values, validationRules, (errors) => {
            if (notHaveErrors(errors))
                saveData();
            else
                setErrors(errors);
        });
    };

    const saveData = () => {
        const variables = {
            input: {
                gameId: gameId,
                link: values.link,
                name: values.name,
            }
        }

        addGameVideoLink({variables: variables}).then(result => {
            props.onComplete();
        });
    };

    return (
        <form id={props.id} onSubmit={formSubmitted}>
            <Input
                {...regField}
                name="link"
                label={t('Url')}
            />

            <Input
                {...regField}
                name="name"
                label={t('Name')}
            />
        </form>
    );
};

YouTubeForm.defaultProps = {
    defaultValues: {},
    onComplete: () => {
    }
};

export default YouTubeForm;
