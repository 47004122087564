import React, {useEffect, useState} from 'react';
import classes from './MatchesDetailsPositions.module.scss';
import Input from 'components/UI/Table/Input/Input';
import Dropdown from 'components/UI/Table/DropDown/Dropdown';
import {useTranslation} from 'react-i18next';
import {useAddGamePlayer, useDeleteGamePlayer, useUpdateGamePlayer} from 'apollo/queries/GameQueries';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar, systemDataVar} from 'apollo/cashe';
import Button from 'components/UI/Button/Button';
import Panel from 'components/UI/Panel/Panel';
import AddPlayerForm from '../TrainingsDetails/forms/AddPlayersForm';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import {useParams} from 'react-router-dom';
import Table from 'components/UI/Table/Table';
import {toastMessage} from 'components/UI/Toast/Toast';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';
import CanAccess from 'components/Navigation/CanAccess/CanAccess';

const MatchesTeamList = (props) => {
    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);
    const systemData = useReactiveVar(systemDataVar);
    const {gameId} = useParams();

    const [selectedRows, setSelectedRows] = useState([]);
    const [mainTeam, setMainTeam] = useState([]);
    const [reserveTeam, setReserveTeam] = useState([]);

    const [modalData, setModalData, initState] = ModalState();

    const [addGamePlayer] = useAddGamePlayer();
    const [deleteGamePlayer] = useDeleteGamePlayer();
    const [updateGamePlayer] = useUpdateGamePlayer();

    const isDisabled = [SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin].includes(systemData.user.userRole.roleId) === false;

    useEffect(() => {
        const first = props.data.players.filter(f => f.substitute === false);
        const reserve = props.data.players.filter(f => f.substitute !== false);
        setMainTeam(first);
        setReserveTeam(reserve);
    }, [props.data]);

    const playerActions = () => (
      <CanAccess users={[SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin]}>
        <div className={classes.actions}>
            <Button type="primary" style={{margin: 0}} onClick={onAddPlayers}>
                <span><i className="icon-Plus-Square"></i> {t('Add player')}</span>
            </Button>

            {selectedRows.length > 0 && <Button type="secondary" style={{margin: "0 0 0 10px"}} onClick={onDeletePlayers}>
                <span>{t('Delete')}</span>
            </Button>}
        </div>
      </CanAccess>
    );

    const onAddPlayers = () => {
        const {players} = props.data ?? [];

        const _data = {
            ...initState,
            form: "player",
            title: t("Add players"),
            existedPlayersIds : players.map(p => p.playerId),
            onAddPlayers: onComplete,
            open: true
        }

        setModalData(_data);
    }

    const onComplete = (data) => {
        setModalData(initState);

        if (data.length === 0)
            return;

        const {players} = props.data ?? [];

        const variables = {
            input: {
                clubId: navState.clubId,
                teamId: navState.teamId,
                gameId: gameId,
                playerIds: data.map(x => x.playerId),
                substitute: mainTeam.length > 10
            }
        }

        addGamePlayer({variables: variables}).then(result => {
            props.refetch();
        });
    }

    const onPlayerNumberChange = (data, event) => {
        const {playerId, teamNumber} = data;
        const newNumber = +event.target.value;

        if(teamNumber == newNumber)
            return;

        const variables = {
            input: {
                clubId: navState.clubId,
                teamId: navState.teamId,
                gameId: gameId, //props.data.id,
                playerId: playerId,
                number: newNumber,
            }
        }

        updateGamePlayer({variables: variables}).then(result => {
            props.refetch();
        });
    }

    const onCaptainChange = (data, event) => {
        const {playerId} = data;
        const captain = event.target.checked;

        const variables = {
            input: {
                clubId: navState.clubId,
                teamId: navState.teamId,
                gameId: gameId, //?? props.data.id,
                playerId: playerId,
                setAsCaptain: captain,
            }
        }

        updateGamePlayer({variables: variables}).then(result => {
            props.refetch();
        });
    }

    const onPositionChange = (data, event) => {
        const {playerId} = data;
        const position = event.target.value;

        const variables = {
            input: {
                clubId: navState.clubId,
                teamId: navState.teamId,
                gameId: gameId, //props.data.id,
                playerId: playerId,
                position: position,
            }
        }

        updateGamePlayer({variables: variables}).then(result => {
            props.refetch();
        });
    }

    const onDeletePlayers = () => {
        if (selectedRows.length === 0)
            return;

        const variables = {
            input: {
                clubId: navState.clubId,
                teamId: navState.teamId,
                gameId: gameId,
                playerIds: selectedRows.map(p => p.playerId)
            }
        }

        deleteGamePlayer({variables: variables}).then(result => {
            props.refetch();
        });
    }

    const onMoveReserve = (data) => {
        const {playerId} = data;

        const variables = {
            input: {
                clubId: navState.clubId,
                teamId: navState.teamId,
                gameId: gameId,
                playerId: playerId,
                substitute: true,
            }
        }

        updateGamePlayer({variables: variables}).then(result => {
            toastMessage(true, t("Player moved to reserve team."));
            props.refetch();
        });
    }

    const onMoveMain = (data) => {
        if (mainTeam.length > 10) {
            toastMessage(false, t("Player not moved. Main team can have only 11 players."));
            return;
        }

        const {playerId} = data;

        const variables = {
            input: {
                clubId: navState.clubId,
                teamId: navState.teamId,
                gameId: gameId,
                playerId: playerId,
                substitute: false,
            }
        }

        updateGamePlayer({variables: variables}).then(result => {
            toastMessage(true, t("Player moved to main team."));
            props.refetch();
        });
    }

    return (
        <>
            <Panel border={false} action={playerActions}>
                <br/>
            </Panel>
                <h2>{t("Main team")} ({mainTeam.length})</h2>
                <Table
                    data={mainTeam}
                    center="true"
                    access={[SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin]}
                    columns={[
                        {
                            Header: t('Name'),
                            accessor: 'playerName',
                            className: classes.nameColumn,
                            Cell: cellData => <div >{t(cellData.value)} </div>
                        },
                        {
                            Header: t('Number'),
                            accessor: 'teamNumber',
                            Cell: cellData => <div><Input type="number" onBlur={e =>
                                onPlayerNumberChange(cellData.row.original, e)}  disabled={isDisabled}
                                                          className={classes.number} value={cellData.value} /></div>
                        },
                        {
                            Header: t('Captain'),
                            accessor: 'captain',
                            className: classes.checkBoxColumn,
                            Cell: cellData => <div><input type="checkbox" checked={cellData.value} disabled={isDisabled}
                                                          onChange={e => onCaptainChange(cellData.row.original, e)} /> </div>
                        },
                        {
                            Header: t('Position'),
                            accessor: 'position',
                            Cell: cellData => <Dropdown name="position" options={props.position}  access={cellData.cell.access}
                                                        value={cellData.value} onChange={e => onPositionChange(cellData.row.original, e)} />
                        },
                        {
                            accessor: 'id',
                            className: classes.downButton,
                            Cell: cellData => <span onClick={() =>  isDisabled ? null : onMoveReserve(cellData.row.original)} className="icon-Arrow---Up"></span>
                        }
                    ]}
                    onRowClick={() => {}}
                    onSelectClick={(x) => setSelectedRows(x)}
                />

                <h2>Reserve team ({reserveTeam.length})</h2>
                <Table
                    data={reserveTeam}
                    center="true"
                    columns={[
                        {
                            Header: t('Name'),
                            accessor: 'playerName',
                            className: classes.nameColumn,
                            Cell: cellData => <div>{t(cellData.value)}</div>
                        },
                        {
                            Header: t('Number'),
                            accessor: 'teamNumber',
                            Cell: cellData => <div><Input type="number"
                                                          onBlur={e => onPlayerNumberChange(cellData.row.original, e)}
                                                          className={classes.number} value={cellData.value} /></div>
                        },
                        {
                            Header: t('Capitan'),
                            accessor: 'captain',
                            className: classes.checkBoxColumn,
                            Cell: cellData => <div><input type="checkbox" checked={cellData.value} disabled
                                                          onChange={e => onCaptainChange(cellData.row.original, e)} /> </div>
                        },
                        {
                            Header: t('Position'),
                            accessor: 'position',
                            Cell: cellData => <Dropdown name="position" options={props.position}
                                                        value={cellData.value} onChange={e => onPositionChange(cellData.row.original, e)} />
                        },
                        {
                            accessor: 'id',
                            className: classes.upButton,
                            Cell: cellData => <span onClick={() => onMoveMain(cellData.row.original)} className="icon-Arrow---Up"></span>
                        }
                    ]}
                    onRowClick={() => {}}
                    onSelectClick={(x) => setSelectedRows(x)}
                />

            <ModalWindow2 {...modalData}>
                <AddPlayerForm {...modalData} name="player" />
            </ModalWindow2>
        </>
    );
};

MatchesTeamList.defaultProps = {};

export default MatchesTeamList;
