import {gql, useLazyQuery, useMutation, useQuery} from '@apollo/client';

export const EXERCISE_FIELDS = gql`
    fragment ExerciseFields on ExerciseType {
        id
        clubId
        name
        duration
        repeat
        rest
        rhythm
        coefficient
        ageGroup
        level
        numberOfPlayers
        language
        description
        tools
        tags
        categories
        image
        scheme {
            image
            background
            elementList
            frameList
            kitData
        }
        video
        accessType
    }
`;

export const TAG_FIELDS = gql`
    fragment TagFields on ExerciseTagType {
        id
        tag
        selected
    }
`;

const GET_EXERCISES = gql`
    ${EXERCISE_FIELDS}
    ${TAG_FIELDS}
    query Exercises($clubId: String!, $take: Int, $skip: Int, $where: ExerciseTypeFilterInput) {
        exercises (clubId: $clubId, where: $where, take: $take, skip: $skip) {
            totalCount
            items {
                ...ExerciseFields
            }
        }

        exerciseTags(clubId: $clubId) {
            ...TagFields
        }
    }`;

export const useExercises = () => useLazyQuery(GET_EXERCISES);

const GET_EXERCISE_TAGS = gql`
    ${TAG_FIELDS}
    query ExerciseTags($clubId: String) {
        exerciseTags(clubId: $clubId) {
            ...TagFields
        }
    }`;

export const useExerciseTag = (variables) => useQuery(GET_EXERCISE_TAGS, {variables: variables});

const SAVE_EXERCISE_TAG = gql`
    ${TAG_FIELDS}
    mutation SaveExerciseTag($input: ExerciseTagInput!) {
        saveExerciseTag(input: $input) {
            ...TagFields
        }
    }`;

export const useSaveExerciseTag = (clubId) => useMutation(SAVE_EXERCISE_TAG, {
    update: (cache, mutationResult) => {
        const newTag = mutationResult.data.saveExerciseTag;

        const data = cache.readQuery({
            query: GET_EXERCISE_TAGS, variables: {clubId: clubId}
        });

        cache.writeQuery({
            query: GET_EXERCISE_TAGS,
            variables: {clubId: clubId},
            data: {...data, exerciseTags: [...data.exerciseTags, newTag]}
        });
    }
});

const SAVE_EXERCISE = gql`
    ${EXERCISE_FIELDS}
    mutation ExerciseSave($input: ExerciseInput) {
        saveExercise(input: $input) {
            ...ExerciseFields
        }
    }
`;

export const useSaveExercise = (settings) => useMutation(SAVE_EXERCISE,
//     {
//     update: (cache, mutationResult) => {
//         const newExercise = mutationResult.data.saveExercise;
//         const data = cache.readQuery({ query: GET_EXERCISES, variables: settings});
//
//         cache.writeQuery({
//             query: GET_EXERCISES,
//             variables: settings,
//             data: {...data, exercises: { items: [...data.exercises.items, newExercise]}}
//         });
//     }
// }
);

const GET_METRIC_DATA = gql`
    query GetMetricCategories($clubId: String!, $teamId: Uuid) {
        metricCategories(clubId: $clubId, teamId: $teamId) {
            items {
                id
                name
                isSystem
                order
                showInClub
            }
        }

        metrics(clubId: $clubId, take: 500) {
            items {
                metricId
                name
                logoId
                logo
                description
                unitOfMeasure
                systemOfMeasurement
                attempts
                metricCategoryId
                isSystem
                order
                showInClub
                norms {
                    age
                    value
                    valueWell
                    valueVeryWell
                    altValue
                    altValueWell
                    altValueVeryWell
                }
            }
        }
    }`;

export const useMetricData = () => useLazyQuery(GET_METRIC_DATA);

const SAVE_METRIC_CATEGORY = gql`
    mutation SaveMetricCategory($input: MetricCategoryInput!) {
      saveMetricCategory(input: $input)
    }`;

export const useSaveMetricCategory = () => useMutation(SAVE_METRIC_CATEGORY)


const SAVE_METRIC = gql`
    mutation SaveMetric($input: MetricInput!) {
        saveMetric(input: $input)
    }`;

export const useSaveMetric = () => useMutation(SAVE_METRIC)
