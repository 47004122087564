import React from 'react';
import classes from './SettingsTitle.module.scss';

const SettingsTitle = (props) => {
    const {title, onBack} = props;

    return (
        <div onClick={onBack} className={[classes.title, classes.link].join(" ")}>
            <i className={["icon-Arrow---Up", classes.backButton, "backArrow"].join(" ")}></i>
            {title}
        </div>
    );
};

SettingsTitle.defaultProps = {
    onBack: () => {}
};

export default SettingsTitle;
