import React, {useEffect, useRef, useState} from 'react';
import {CalendarContainer, default as ReactDatePicker} from 'react-datepicker';
import {useTranslation} from 'react-i18next';
import {haveDefaultValue, haveError} from 'utils/form';
import classes from './DatePicker.module.scss';
import cn from 'classnames';
import {dateAsTimeNumber, dateTimeToServer, dateToServer, validDate} from 'utils/date';
import TimePickerBase from './TimePickerBase';
import {useReactiveVar} from '@apollo/client';
import {systemDataVar} from 'apollo/cashe';

const DatePicker = (props) => {
    const {t} = useTranslation();
    const systemData = useReactiveVar(systemDataVar);

    const htmlFor = `date-${Math.random()}`;
    const error = haveError(props);
    const ref = useRef();
    const defaultDate = haveDefaultValue(props);

    const defaultValue = defaultDate !== null
        ? defaultDate instanceof Date ? defaultDate : new Date(defaultDate)
        :  null;

    const [date, setDate] = useState(defaultValue);
    const [mode, setMode] = useState("date");

    const onChange = (data) => {
        if (data === null) {
            if (mode === "time") { setMode("date");}
            return;
        }

        setDate(data);

        if(props.time) {
            setMode("time");
        } else {
            props.onChange({
                target: {
                    name: props.name,
                    value: dateToServer(data)
                }
            });
        }
    };

    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const range = (start, stop, step) => {
        const a = [start];
        let b = start;
        while (b < stop) {
            a.push(b += step || 1);
        }
        return a;
    }

    const getYear = (date) => date.getFullYear();
    const getMonth = (date) => date.getMonth();
    const years = range(1950, getYear(new Date()) + 10, 1);


    const CustomHeader = (headerData) => {
        const {
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
        } = headerData;


        return (
            <div
                style={{
                    margin: 2,
                    display: "flex",
                    justifyContent: "center",
                }}
            >

                <div onClick={decreaseMonth} disabled={prevMonthButtonDisabled} aria-label="Previous Month"
                     className="react-datepicker__navigation react-datepicker__navigation--previous"><span
                    className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">&lt;</span>
                </div>

                <select
                    className={classes.month}
                    value={months[getMonth(date)]}
                    onChange={({ target: { value } }) =>
                        changeMonth(months.indexOf(value))
                    }
                >
                    {months.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>

                <select
                    className={classes.year}
                    value={getYear(date)}
                    onChange={({ target: { value } }) => changeYear(value)}
                >
                    {years.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>

                <div onClick={increaseMonth} disabled={nextMonthButtonDisabled} aria-label="Next Month"
                     className="react-datepicker__navigation react-datepicker__navigation--next"><span
                    className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">&gt;</span>
                </div>
            </div>
        )
    }

    const ExtendedContainer = ({ className, children }) => {
        return (
            <div className={classes.PickerContainer}>
                {mode === "date"
                    ?<CalendarContainer className={className}>
                        <div style={{ position: "relative" }}>{children}</div>
                    </CalendarContainer>
                    : <TimePickerBase withDatePicker={true} time={dateAsTimeNumber(date)} onBack={onBack} onSet={onSet} /> }
            </div>
        );
    };

    const handleCalendarOpen = () => {
        setMode("date");
    }

    const handleCalendarClose = () => {
        setDate(defaultValue);
    }

    const onBack = () => {
        setDate(defaultValue);
        setMode("date");
    }

    const onSet = (data) => {
        date.setHours(data.hours);
        date.setMinutes(data.minutes);

        props.onChange({
            target: {
                name: props.name,
                value: dateTimeToServer(date)
            }
        });

        ref.current.setOpen(false);
    }

    return (
        <div className={cn(classes.datePicker, props.className, 'datePicker',
            {[classes.invalid]:error})} style={props.style}>

            {(props.label) && <label htmlFor={htmlFor}>
                {props.label || props.placeholder}: </label>}

            {!props.time && <ReactDatePicker
                renderCustomHeader={CustomHeader}
                id={htmlFor}
                showMonthDropdown
                showYearDropdown
                dateFormat={systemData.settings.general.dateFormat}
                onChange={onChange}
                selected={date}
                calendarStartDay={1}
                calendarContainer={ExtendedContainer}
            />}

            {props.time && <ReactDatePicker
                id={htmlFor}
                ref={ref}
                renderCustomHeader={CustomHeader}
                dateFormat={`${systemData.settings.general.dateFormat} ${systemData.settings.general.timeFormat}`}
                onChange={onChange}
                selected={date}
                calendarStartDay={1}
                calendarContainer={ExtendedContainer}
                onCalendarOpen={handleCalendarOpen}
                onCalendarClose={handleCalendarClose}
                shouldCloseOnSelect={false}
            />}

            {error && <span className="errorMessage">{error || t('Required')}</span>}
        </div>
    );
};

DatePicker.defaultProps = {
    time: false,
    empty: false
};

export default DatePicker;
