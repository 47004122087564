import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import Duration, {durationFormat, durationToString} from './Duration';
import classes from './VideoPlayer.module.scss';

import ReactPlayer from 'react-player';
import cn from 'classnames';

class VideoPlayer extends Component {
    state = {
        url: null,
        pip: false,
        playing: false,
        controls: false,
        light: false,
        volume: 0.8,
        muted: false,
        played: 0,
        loaded: 0,
        duration: 0,
        playbackRate: 1.0,
        loop: false,
        playedSeconds: 0,
        canvasShow: true
    }

    load = url => {
        this.setState({
            url,
            played: 0,
            loaded: 0,
            pip: false
        })
    }

    componentDidMount() {
        this.load(this.props.url);
    }

    handlePlayPause = () => {
        this.setState({ playing: !this.state.playing })
    }

    handleStop = () => {
        this.setState({playing: false })
    }

    handlePlay = () => {
        this.setState({playing: true });
        this.props.onPlay();
    }

    handleCanvas = (show) => {
        this.setState({canvasShow: show })
    }

    handleToggleControls = () => {
        const url = this.state.url
        this.setState({
            controls: !this.state.controls,
            url: null
        }, () => this.load(url))
    }

    handleToggleLight = () => {
        this.setState({ light: !this.state.light })
    }

    handleToggleLoop = () => {
        this.setState({ loop: !this.state.loop })
    }

    handleVolumeChange = e => {
        this.setState({ volume: parseFloat(e.target.value) })
    }

    handleToggleMuted = () => {
        this.setState({ muted: !this.state.muted })
    }

    handleSetPlaybackRate = e => {
        this.setState({ playbackRate: parseFloat(e.target.value) })
    }

    handleOnPlaybackRateChange = (speed) => {
        this.setState({ playbackRate: parseFloat(speed) })
    }

    handleTogglePIP = () => {
        this.setState({ pip: !this.state.pip })
    }

    handlePlay = () => {
        //console.log('onPlay')
        this.setState({ playing: true })
        this.props.onPlay();
    }

    handleSeek = (e) => {
        const seconds = parseFloat(e.target.value);
        const newTime = this.state.playedSeconds + seconds;
        this.player.seekTo(newTime);
        console.log('handleSeek');
    }

    handleEnablePIP = () => {
        this.setState({ pip: true })
    }

    handleDisablePIP = () => {
        //console.log('onDisablePIP')
        this.setState({ pip: false })
    }

    handlePause = () => {
        //console.log('onPause')
        this.setState({ playing: false })
    }

    handleSeekMouseDown = e => {
        //console.log('handleSeekMouseDown');
        this.setState({ seeking: true })
    }

    handleSeekChange = e => {
        //console.log('handleSeekChange');
        this.setState({ played: parseFloat(e.target.value) });
        this.props.onUserSeek();
    }

    handleSeekMouseUp = e => {
        //console.log('handleSeekMouseUp');
        this.setState({ seeking: false })
        this.player.seekTo(parseFloat(e.target.value))

    }

    handleProgress = state => {
        // console.log('onProgress', state)
        const seconds = this.state.duration * this.state.played;
        this.props.onTimeChange(durationToString(seconds));

        // We only want to update time slider if we are not currently seeking
        if (!this.state.seeking) {
            this.setState(state)
        }
    }

    handleEnded = () => {
        //console.log('onEnded')
        this.setState({ playing: this.state.loop })
    }

    handleDuration = (duration) => {
        //console.log('onDuration', duration)
        this.setState({ duration })
    }

    getEventTime = (time) => {
        const seconds = this.state.duration * this.state.played + time;
        return durationFormat(seconds > 0 ? seconds : 0);
    }

    getEventDuration = (time) => {
        const seconds = this.state.duration * this.state.played + time;
        return durationFormat(seconds < this.state.duration ? seconds : this.state.duration);
    }

    setEventTime = (time) => {
        const minutes = Math.trunc(time);
        const seconds = (time - minutes) * 100;
        this.player.seekTo(minutes*60+seconds);
    }

    ref = player => {
        this.player = player
    }

    extraStyle = () => {
        return {__html: "<style> .ytp-pause-overlay{ display:none; } </style>"};
    }

    render () {
        const { url, playing, controls, light, volume, muted, loop, played, loaded, duration, playbackRate, pip } = this.state
        const SEPARATOR = ' · '

        return (
            <div className='app'>
                <section className='section'>
                    <div className={cn('player-wrapper',{[classes.showCanvas]: this.state.canvasShow})}>
                        <div className={classes.playerArea}>
                        {this.props.canvas && <canvas id={this.props.canvasId}  className={cn(classes.canvas)} width={640} height={360} />}

                            <div dangerouslySetInnerHTML={this.extraStyle()} />;

                            <ReactPlayer
                                ref={this.ref}
                                className='react-player'
                                width='100%'
                                height='calc(100% - 20px)'
                                url={url}
                                pip={pip}
                                playing={playing}
                                controls={false}
                                light={light}
                                loop={loop}
                                playbackRate={playbackRate}
                                progressInterval={500}
                                volume={volume}
                                muted={muted}
                                // onReady={() => console.log('onReady')}
                                // onStart={() => console.log('onStart')}
                                onPlay={this.handlePlay}
                                onEnablePIP={this.handleEnablePIP}
                                onDisablePIP={this.handleDisablePIP}
                                onPause={this.handlePause}
                                //onBuffer={() => console.log('onBuffer')}
                                //onPlaybackRateChange={this.handleOnPlaybackRateChange}
                                onSeek={this.props.onSeek}
                                onEnded={this.handleEnded}
                                //onError={e => console.log('onError', e)}
                                onProgress={this.handleProgress}
                                onDuration={this.handleDuration}
                            />
                        </div>
                    </div>

                    <div className={classes.controls}>
                        <div className={classes.seek}>
                            <div><Duration seconds={duration * played} /> / <Duration seconds={duration} /></div>
                            <input
                                type='range' min={0} max={0.999999} step='any'
                                value={played}
                                onMouseDown={this.handleSeekMouseDown}
                                onChange={this.handleSeekChange}
                                onMouseUp={this.handleSeekMouseUp}
                            />
                        </div>
                        <div className={classes.buttons}>
                            <div className={classes.speed}>
                                <button onClick={this.handleSetPlaybackRate} value={1}>1x</button>
                                <button onClick={this.handleSetPlaybackRate} value={1.5}>1.5x</button>
                                <button onClick={this.handleSetPlaybackRate} value={2}>2x</button>
                            </div>
                            <div></div>
                            <button className={classes.time} onClick={this.handleSeek} value={-5}>-5</button>
                            <div className={classes.play}>
                                <button onClick={this.handlePlayPause}>{playing ? 'Pause' : 'Play'}</button>
                            </div>
                            <button className={classes.time} onClick={this.handleSeek} value={5}>5+</button>
                            <div></div>
                            <div className={classes.volume}>
                                <input type='range' min={0} max={1} step='any' value={volume} onChange={this.handleVolumeChange} />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

VideoPlayer.defaultProps = {
    onTimeChange: () => {},
    onPlay: () => {},
    onUserSeek: () => {},
    canvas: false,
    canvasShow: false,
    canvasId: "player-canvas"
};

export default VideoPlayer
