import React from 'react';
import classes from './CreateOrRecommend.module.scss';
import Button from 'components/UI/Button/Button';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {regStateVar} from 'apollo/variables/registration';


const CreateOrRecommend = () => {
  const {t} = useTranslation();
  const regState = useReactiveVar(regStateVar);

  const onGoTo = (goto, e) => {
    e.preventDefault();

    regStateVar({
      ...regState,
      main: goto
    });
  }

  return (
      <div>
        <Button onClick={e => onGoTo('IsClubRegistered', e)} type="third">
          &#8592; {t('goBack')}
        </Button>

        <h1>{t('newSchool')}</h1>

        <div className={classes.description}>{t('newSchoolMessagee')}</div>

        <p>{t('free30')}</p>

        <Button onClick={e => onGoTo('CreateNewClub', e)}
                type="primary">{t('create')}</Button>

        <Button
            onClick={e => onGoTo('RecommendUs', e)}
            type="third"
            style={{marginRight: '0'}}>{t('tellToOvner')}</Button>
      </div>
  );
};

export default CreateOrRecommend;
