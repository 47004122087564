import React from 'react';
import {useTranslation} from 'react-i18next';
import CanAccess from 'components/Navigation/CanAccess/CanAccess';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';
import Button from 'components/UI/Button/Button';
import Panel from 'components/UI/Panel/Panel';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar, systemDataVar} from 'apollo/cashe';
import {
	useDeleteClubGameEvaluation,
	useGameEvaluationList
} from 'apollo/queries/ClubQueries';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import Table from 'components/UI/Table/Table';
import DateTime from 'components/UI/Table/DateTime/DateTime';
import GameEvaluationForm from 'containers/Club/SportDirector/ClubEvaluation/forms/GameEvaluationForm';

const ClubGamesEvaluation = (props) => {
	const {reload} = props;

	const { t } = useTranslation();
	const navState = useReactiveVar(naviDataVar);
	const systemData = useReactiveVar(systemDataVar);

	const readOnly = ![SystemRoles.GroupManger, SystemRoles.GroupMember, SystemRoles.SysAdmin].includes(systemData.user.userRole.roleId);

	const {data, loading, error, refetch} = useGameEvaluationList( {clubId: navState.clubId} );
	const [deleteClubGameEvaluation] = useDeleteClubGameEvaluation();

	const [modalData, setModalData, initState] = ModalState();

	const actions = () => {
		return (
			<CanAccess users={[SystemRoles.GroupMember, SystemRoles.GroupManger, SystemRoles.SysAdmin]}>
				<Button type="primary" style={{margin: 0}} onClick={onAddClick}>
					<span><i className="icon-Plus-Square"></i> {t("Add evaluation")}</span>
				</Button>
			</CanAccess>
		);
	}

	const onAddClick = () => {
		const _data = {
			...initState,
			title: t("Add game evaluation"),
			styles: {
				maxWidth: "700px",
			},
			coaches: data.trainers.items?.map(x => ({value: x.trainerId, label: x.fullName})) ?? [],
			onComplete: onComplete,
			open: true
		}

		setModalData(_data);
	}

	const onEditEvaluation = (row) => {
		const _data = {
			...initState,
			title: t("Add coach evaluation"),
			styles: {
				maxWidth: "700px",
			},
			defaultValues: {
				id: row.id,
				coach: row.keyItem,
				...JSON.parse(row.document)
			},
			buttons: {
				hideSaveButton: readOnly
			},
			coaches: data.trainers.items?.map(x => ({value: x.trainerId, label: x.fullName})) ?? [],
			onComplete: onComplete,
			onDelete: () => readOnly ? {} : onDelete(row),
			open: true
		}

		setModalData(_data);
	}

	const onDelete = async (row) => {
		setModalData(initState);
		await deleteClubGameEvaluation({variables: {input: {clubId: navState.clubId, id: row.id}}});
		await refetch();
		reload();
	}

	const onComplete = async (data) => {
		setModalData(initState);
		await refetch();
		reload();
	}

	if (loading) return (<div>{t("Loading...")}</div>);
	if (error) return (<div>{t("Error...")}</div>);

	return (
		<>
			<h3>{t("Games evaluations")}</h3>

			<Panel border={false} action={actions}  />

			<Table
				data={data.clubGameEvaluations}
				columns={[
					{
						Header: t('Date and time'),
						accessor: 'date',
						Cell: cellData => <DateTime data={cellData.value}/>
					},
					{
						Header: t('Title'),
						accessor: 'title',
					},
					{
						Header: t('Evaluation'),
						accessor: 'evaluation',
						Cell: cellData => <div>{cellData.value}</div>
					}
				]}
				onRowClick={onEditEvaluation}/>

			<ModalWindow2 {...modalData}>
				<GameEvaluationForm {...modalData} />
			</ModalWindow2>
		</>
	);
};

ClubGamesEvaluation.defaultProps = {};

export default ClubGamesEvaluation;
