import React, {useEffect, useImperativeHandle} from 'react';
import Subtext from '../../../components/UI/Table/Subtext/Subtext';
import Table from '../../../components/UI/Table/Table';
import {useTranslation} from 'react-i18next';
import ModalWindow2, {ModalState} from '../../../components/UI/ModalWindow/ModalWindow2';
import SwitchComponents from '../../../components/System/SwitchComponents';
import CategoryForm from './forms/CategoryForm';
import TestForm from './forms/TestForm';

const NormativesCategories = (props) => {
    const {loading, error, data, refetch} = props;

    const {t} = useTranslation();

    const [modalData, setModalData, initState] = ModalState();

    const onRowClick = (row) => {
        const data = {
            ...initState,
            title: t("Edit category"),
            defaultValues: {
                ...row
            },
            onComplete: onComplete,
            open: true
        }

        setModalData(data);
    }

    const onComplete = () => {
        setModalData(initState);
        refetch();
    }

    const CategoriesTable = () => {
        const {metricCategories} = data;

        return (
            <Table
                data={metricCategories.items}
                center="true"
                columns={[
                    {
                        Header: t('Category'),
                        accessor: 'name',
                        Cell: cellData =>
                            <div>{t(cellData.value)}<Subtext>{t('Type')}: {cellData.row.original.isSystem ? t('system') : t('editable')}</Subtext>
                            </div>
                    },
                    {
                        Header: t('Show in team'),
                        accessor: 'showInTeam',
                        Cell: cellData =>
                            <div>{cellData.value ? t('Yes') : t('No')}</div>
                    },
                    {
                        Header: t('Show in club'),
                        accessor: 'showInClub',
                        Cell: cellData =>
                            <div>{cellData.value ? t('Yes') : t('No')}</div>
                    },
                    {
                        Header: t('Order'),
                        accessor: 'order',
                        Cell: cellData => <div>{cellData.value}</div>
                    },
                ]}
                onRowClick={onRowClick} />
        );
    }

    return (
        <>
            {loading && <>Loading...</>}
            {error && <>Error...</>}
            {data && <CategoriesTable />}

            <ModalWindow2 {...modalData}>
                <CategoryForm {...modalData} name="categories" />
            </ModalWindow2>
        </>
    );
};

NormativesCategories.defaultProps = {
    onRowClick: () => {},
};

export default NormativesCategories;
