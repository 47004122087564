import React from 'react';
import cn from 'classnames';
import defaultClasses from './ExerciseLibrary.module.scss';
import {useTranslation} from 'react-i18next';

const GroupedCategories = (props) => {
    const {t} = useTranslation();
    const {data, onFilter, classes} = props;

    return data.map(group => {
        return (
            <div key={group.id} className={classes.categoryGroup}>
                <h4 className={classes.groupTitle}>{t(group.group)}</h4>
                {group.items.map(category => {
                    const categoryName = t(category.name);
                    return (<div className={cn(classes.category, {[classes.selectedCategory]: category.selected})}
                         key={category.id} onClick={() => onFilter(category)}>
                        <img className={classes.image} src={category.image} alt={categoryName}/>
                        <div className={classes.title}>{categoryName}</div>
                    </div>);
                  }
                )}
            </div>
        );
    });
};

GroupedCategories.defaultProps = {
    data: [],
    classes: defaultClasses
};

export default GroupedCategories;
