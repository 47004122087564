import React, { PropTypes } from 'react'
import Input from '../../Input/Input';
import {useTranslation} from 'react-i18next';

const Search = (props) => {
  const {t} = useTranslation();

  return (
      <>
        <Input
          style={{margin: 0}}
          name="search"
          onChange={props.onSearch}
          placeholder={t(props.placeholder)}
          value={props.values}
          />
      </>
  );
};

Search.propTypes = {};
Search.defaultProps = {};

export default Search;
