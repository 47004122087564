import React from 'react';
import {useTranslation} from 'react-i18next';
import classes from './Normatives.module.scss';
import Subtext from '../../../components/UI/Table/Subtext/Subtext';
import ModalWindow2, {ModalState} from '../../../components/UI/ModalWindow/ModalWindow2';
import TestForm from './forms/TestForm';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';

const NormativesTests = (props) => {
    const {loading, error, data, refetch} = props;

    const {t} = useTranslation();

    const [modalData, setModalData, initState] = ModalState();

    const MetricCategory = () => {
        const {metricCategories} = data;

        return (
            <>
                {metricCategories.items.map(category => (
                    <div key={category.id}>
                        <h3>{category.isSystem ? t(category.name) : category.name}</h3>
                        <div className={classes.eventTitles}>
                            <div>{t('Test')}</div>
                            <div>{t('Show in club')}</div>
                            <div>{t('Order')}</div>
                        </div>
                        <div className={classes.eventGroup}>
                            <Metrics category={category} />
                        </div>
                    </div>
                ))}
            </>
        );
    }

    const Metrics = ({category}) => {
        const {metrics} = data;
        const rows = metrics.items.filter(e => e.metricCategoryId === category.id);

        return rows.length > 0
            ? rows.map(metric => <Row key={`metric-${metric.metricId}`} metric={metric} />)
            : <div className={classes.empty}>{t("No data to display.")}</div>;
    }

    const Row = ({metric}) => (
        <div onClick={() => onRowClick(metric)}  className={classes.eventItem}>
            <div>
                {metric.name} {metric.unitOfMeasure !== "NONE" ? <>, {metric.unitOfMeasure}</> : undefined}
                <Subtext>{t('Type')}: {metric.isSystem ? t('system') : t('editable')}</Subtext>
            </div>
            <div>{metric.showInClub ? t('Yes') : t('No')}</div>
            <div>{metric.order}</div>
        </div>
    )

    const onRowClick = (row) => {
        const categories = data.metricCategories.items.map(x => ({label: x.name, value: x.id}));

        const _data = {
            ...initState,
            title: t("Edit test"),
            access: [SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
            pages: {
                page: 0,
                maxPages: 2,
            },
            styles: {
                maxWidth: "700px",
            },
            defaultValues: {
                ...row
            },
            settings: {
                categories: categories
            },
            onComplete: onComplete,
            open: true
        }

        setModalData(_data);
    }

    const onComplete = () => {
        setModalData(initState);
        refetch();
    }

    return (
        <>
            {loading && <>Loading...</>}
            {error && <>Error...</>}
            {data && <MetricCategory />}

            <ModalWindow2 {...modalData}>
                <TestForm {...modalData} />
            </ModalWindow2>
        </>
    );
};

NormativesTests.defaultProps = {
};

export default NormativesTests;
