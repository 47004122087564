import React, {useEffect} from 'react';
import {useReactiveVar} from '@apollo/client';
import {useTranslation} from 'react-i18next';
import {naviDataVar, systemDataVar} from 'apollo/cashe';
import cn from 'classnames';
import classes from './Menu.module.scss'
import LogoBar from '../LogoBar/LogoBar';
import {menu} from './Settings';
import {findInMenu, getUrl} from 'utils/history';
import {NavLink} from 'react-router-dom';
import useComponentVisible from '../../Hooks/useComponentVisible';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';

const Menu = () => {

  const systemData = useReactiveVar(systemDataVar);
  const navState = useReactiveVar(naviDataVar);

  const { ref, isComponentVisible } = useComponentVisible(navState.isMenuOpen);
  const {club, team, player, group, system} = menu;
  const {t} = useTranslation();

  const isActiveMenu = (item, selected) => (item.id === selected?.id);

  const onCollapse = (type) => {
    const newState = {...navState};

    switch (type) {
      case "system":
        newState.menu.system.collapsed = !newState.menu.system.collapsed;
        break;
      case "group":
        newState.menu.group.collapsed = !newState.menu.group.collapsed;
        break;
      case "club":
        newState.menu.club.collapsed = !newState.menu.club.collapsed;
        break;
      case "team":
        newState.menu.team.collapsed = !newState.menu.team.collapsed;
        break;
      case "player":
        newState.menu.player.collapsed = !newState.menu.player.collapsed;
        break;
    }
    naviDataVar(newState);
  }

  const onClick = (data) => {
    const newState = {
      ...navState,
      isMenuOpen: false,
      selected: {
        id:  data.id,
        path: data.path,
        items: data.submenu,
        submenu: {
          id: data.submenu[0] ? data.submenu[0].id : null,
        }
      }
    };

    if (data.menu === "team") {
      newState.playerId = null;
      newState.playerName = "";
    }

    if (data.menu === "club") {
      newState.teamId = null;
      newState.teamName = "";
      newState.playerId = null;
      newState.playerName = "";
    }

    if (data.menu === "group") {
      newState.teamId = null;
      newState.teamName = "";
      newState.playerId = null;
      newState.playerName = "";
      newState.clubId = null;
      newState.clubName = "";
    }

    if (data.menu === "system") {
      newState.teamId = null;
      newState.teamName = "";
      newState.playerId = null;
      newState.playerName = "";
      newState.clubId = null;
      newState.clubName = "";
      newState.groupId = null;
      newState.groupName = "";
    }

    naviDataVar(newState);
  }

  const MenuGroup = ({data, type}) => {
    const {collapsed} = navState.menu[type];
    const {name, access, items} = data;

    if (!access.includes(systemData.user.userRole.roleId)) return (<></>);

    return (
      <li>
        <div onClick={() => onCollapse(type)}
             className={cn(classes.submenu, {[classes.collapsed]: collapsed})}>
          <i className="icon-Chevron"></i> {t(name)}
        </div>
        {!collapsed ?
            <ul>
              {items.map(item => <Item key={item.id} data={item} /> )}
            </ul>
          : undefined }
      </li>
    );
  }

  const Item = ({data}) => {
    const {icon, name, access} = data;

    if (!access.includes(systemData.user.userRole.roleId)) return (<></>);

    return (
        <li className={classes.item}>
          <NavLink
            end
            to={getUrl(data, navState)}
            onClick={() => onClick(data)}
          >
            <i className={icon}></i> {t(name)}
          </NavLink>
        </li>
    )
  }

  return (
      <nav ref={ref} className={cn(classes.Nav, {[classes.Open]:navState.isMenuOpen, "Open":isComponentVisible})}>
        <LogoBar />
        <ul className={classes.Menu}>
          {navState.playerId !== null && navState.clubId !== null && <MenuGroup data={player} type="player" /> }
          {navState.teamId !== null && navState.clubId !== null && <MenuGroup data={team} type="team" /> }
          {navState.clubId !== null && <MenuGroup data={club} type="club" /> }
          {navState.groupId !== null && <MenuGroup data={group} type="group" /> }
          {systemData.user.userRole.roleId === SystemRoles.SysAdmin && <MenuGroup data={system} type="system" /> }
        </ul>

        <div className={classes.copy}>
          <img src="/assets/images/yoplaydo-gray.svg" width="76" height="32" alt="YoPlayDo" title="YoPlayDo" />
          <div>&copy; {new Date().getFullYear()} - <a href="https://yoplaydo.com" target="_blank">YoPlayDo</a>. All rights reserved.</div>
          <div>v{systemData.settings.apiVersion}</div>
        </div>
      </nav>
  );

};

export default Menu;
