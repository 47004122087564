import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import Dropzone from 'react-dropzone-uploader';
import classes from './FileUpload2.module.scss';
import {toastMessage} from '../Toast/Toast';
import {useTranslation} from 'react-i18next';
import {useAuth} from 'react-oidc-context';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import {createGuid} from 'utils/utils';

const FileUpload2 = forwardRef((props, ref) => {
    const {onUpload} = props;

    const auth = useAuth();
    const {t} = useTranslation();

    const naviData = useReactiveVar(naviDataVar);
    const inputRef = React.useRef(null);
    const [status, setStatus] = useState(null);

    const [accept, setAccept] = useState('image/jpeg, image/png, image/gif');

    useEffect(() => {
        switch (props.mode) {
            case "video":
                setAccept('video/mp4');
                break;
            case "images":
                setAccept('image/jpeg, image/png, image/gif');
                break;
            case "xls":
                setAccept('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
                break;
            default:
                setAccept('image/jpeg, image/png, image/gif, application/msword,' +
                    ' application/vnd.openxmlformats-officedocument.wordprocessingml.document,' +
                    ' application/vnd.oasis.opendocument.text, application/pdf, application/vnd.ms-powerpoint,' +
                    ' application/vnd.openxmlformats-officedocument.presentationml.presentation, text/plain');
                break;
        }
    }, [props.mode]);

    useImperativeHandle(ref, () => ({
        onOpen() {
            inputRef.current.click();
        }
    }));

    const getUploadParams = (uploadProps) => {
        const { file, meta } = uploadProps;

        const body = new FormData()

        Object.keys(props.meta).forEach(function(key) {
            body.append(key, props.meta[key]);
        });

        body.append('clubId', naviData.clubId);
        body.append('fileId', createGuid());
        body.append('name', meta.name);
        body.append('contentType', meta.type);
        body.append('size', meta.size);
        body.append('file', file);

        return {
            url: process.env.REACT_APP_STORAGE_VIDEO_UPLOAD_URL,
            headers : {
                authorization: `Bearer ${auth.user?.access_token}`
            },
            body: body
        };
    };

    const Layout = ({ input, previews, dropzoneProps, files, extra: { maxFiles } }) => {
        return (
            <div className={classes.fileUpload2}>
                {status ? <>{previews}</> : undefined}
                <div {...dropzoneProps}>
                    {files.length < maxFiles && input}
                </div>
            </div>
        )
    }

    const Input = ({ accept, onFiles, files, getFilesFromEvent }) => {
          return (
              <label>
                  {t("Drag 'n' drop some files here, or click to select files")}
                  <input
                      ref={inputRef}
                      style={{ display: 'none' }}
                      type="file"
                      accept={accept}
                      multiple
                      onChange={async e => {
                          const target = e.target
                          const chosenFiles = await getFilesFromEvent(e)
                          onFiles(chosenFiles)
                          target.value = null
                      }}
                  />
              </label>
          )
    }

    const handleChangeStatus = ({ meta, remove, xhr}, status) => {
        setStatus(status);
        if (status === 'done') {
            let response = JSON.parse(xhr.response);
            toastMessage(response.result, `${meta.name} ${t("uploaded.")}`);
            remove();
            if (response.result) {
                onUpload(response, meta);
            }
        } else if (status === 'aborted' || status === 'error_upload' || status === 'exception_upload') {
            toastMessage(false, `${meta.name} ${t("upload failed.")}`);
        }
    }

    return (
        <Dropzone
            getUploadParams={getUploadParams}
            onChangeStatus={handleChangeStatus}
            submitButtonContent={null}
            LayoutComponent={Layout}
            InputComponent={Input}
            accept={accept}
        />
    );
});

FileUpload2.defaultProps = {
    onUpload: () => {},
    meta: {},
    mode: "images",
};

export default FileUpload2;
