import React, {useState} from 'react';
import classes from './Dropdown.module.scss'
import ReactSelect, {components} from 'react-select';
import {useTranslation} from "react-i18next";
import cn from 'classnames';
import {useReactiveVar} from '@apollo/client';
import {systemDataVar} from 'apollo/cashe';

const Dropdown = (props) => {
    const {t} = useTranslation();
    const systemData = useReactiveVar(systemDataVar);

    const [mode] = useState(props.mode);
    const htmlFor = `select-${Math.random()}`
    const defaultValue = props.value;
    const isDisabled = props.access && props.access.includes(systemData.user.userRole.roleId) === false;

    const { Option, SingleValue } = components;

    const IconValue= props => (
        <SingleValue {...props}>
            {props.data.icon && <span dangerouslySetInnerHTML={{__html: props.data.icon}}></span>}
            {mode !== "iconWithoutText" && t(props.data.label)}
        </SingleValue>
    );

    const IconOption = props => (
        <Option {...props} >
            <div className={classes.tbOption}>
            {props.data.icon && <span dangerouslySetInnerHTML={{__html: props.data.icon}}></span>}
            {t(props.data.label)}
            </div>
        </Option>
    );

    const selected = props.options?.find(element => {
        return element.value == defaultValue
    });

    const onRegularOptionChange = (selectedOption) => {
        props.onChange({
            target: {
                name:  props.name,
                value: selectedOption.value
            }
        });
    }
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            padding: classes.padding,
            borderColor:classes.borderColor,
            boxShadow: 'none',
            minHeight: "auto",
            backgroundColor: props.disabled ? classes.disabledColor : classes.whiteColor,
            ':hover': { borderColor: classes.blueColor}
        }),
        singleValue: (provided, state) => ({
            ...provided,
            fontSize: classes.fontSize,
            lineHeight: classes.lineHeight,
            color: classes.blackColor
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: classes.fontSize,
            lineHeight: classes.lineHeight,
            color: state.isSelected ? classes.primaryColor :classes.blackColor,
            backgroundColor: classes.whiteColor
        }),
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                fontSize: classes.fontSize,
                color: classes.blackColor
            }
        },
        menuPortal: ({ left, top, ...provided }, state) => ({
            ...provided
        })
    }

    return (
        <div className={classes.select}>
            <ReactSelect
                id={htmlFor}
                styles={customStyles}
                defaultValue={selected}
                classNamePrefix="tbDropdown"
                onChange={onRegularOptionChange}
                options={props.options}
                isSearchable={false}
                isDisabled={isDisabled}
                //menuPosition="fixed"
                components={{
                    IndicatorsContainer: () => null,
                    IndicatorSeparator: () => null,
                    SingleValue: IconValue,
                    Option: IconOption
                }} />
        </div>
    )
}

Dropdown.defaultProps = {
    value: "",
    mode: "default",
    onChange: () => {}
}

export default Dropdown
