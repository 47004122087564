import React, {useEffect, useRef, useState} from 'react';
import { fabric } from 'fabric';
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';
import classes from './FieldMap.module.scss';
import FieldVertical from './FieldVertical.svg';

const FieldMap = (props) => {

    const [loaded, setLoaded] = useState(false);

    const map = useRef(null);
    let isObjectMoving  = false;

    const { editor, onReady } = useFabricJSEditor();

    useEffect(() => {
        if (!editor)
            return;

        if (!loaded) {
            setLoaded(true);
            loading();
        }

    }, [fabric, editor]);

    useEffect(() => {
        if (!editor)
            return;

        editor.canvas._objects
            .filter(f => f.type === "mark")
            .forEach((mark) => removeMark(mark));

        props.marks
            .forEach((mark) => addMark(mark));

        editor.canvas.renderAll();
    }, [props.marks]);

    const addMark = (mark) => {
        const scale = Math.round(map.current.offsetWidth / 250);

        const circle = new fabric.Circle({
            radius: 10 * scale,
            fill: '#FFC31F',
            stroke: '#ff9800',
            strokeWidth: 1,
            originX: 'center',
            originY: 'center'
        });

        const text = new fabric.Text(mark.number, {
            fontSize: 10 * scale,
            fill: '#fff',
            originX: 'center',
            originY: 'center'
        });

        const group = new fabric.Group([ circle, text ], {
            id: mark.id,
            isMain: mark.isMain,
            initLeft: mark.x * scale,
            initTop: mark.y* scale,
            type: "mark",
            left: mark.x * scale,
            top: mark.y * scale,
            hasControls: false,
            hasBorders: false,
            selectable: mark.isMain,
            evented: mark.isMain,
        });

        editor.canvas.add(group);
    }

    const removeMark = (mark) => {
        editor.canvas.remove(mark);
    }

    const loading = () => {
        const scale = Math.round(map.current.offsetWidth / 250);
        const x = 250 * scale;
        const y = 410 * scale;

        editor.canvas.setWidth(x);
        editor.canvas.setHeight(y);

        const height = map.current.offsetWidth / x * y;
        map.current.style.height = height + "px";

        editor.canvas.on('object:moving', function (e) {
            const obj = e.target;
            obj.setCoords();

            if(obj.getBoundingRect().top < 0 || obj.getBoundingRect().left < 0){
                obj.top = Math.max(obj.top, obj.top-obj.getBoundingRect().top);
                obj.left = Math.max(obj.left, obj.left-obj.getBoundingRect().left);
            }

            if(obj.getBoundingRect().top+obj.getBoundingRect().height  > obj.canvas.height * scale
                || obj.getBoundingRect().left+obj.getBoundingRect().width  > obj.canvas.width * scale){
                obj.top = Math.min(obj.top, obj.canvas.height-obj.getBoundingRect().height+obj.top-obj.getBoundingRect().top);
                obj.left = Math.min(obj.left, obj.canvas.width-obj.getBoundingRect().width+obj.left-obj.getBoundingRect().left);
            }
        });

        fabric.loadSVGFromURL("/assets/images/FieldVertical.svg", function(objects, options) {
            const obj = fabric.util.groupSVGElements(objects, options);
            obj.scaleToWidth(x);
            obj.scaleToHeight(y);
            obj.selectable = false;
            obj.evented = false;
            editor.canvas.add(obj);
            obj.sendToBack();
            editor.canvas.renderAll();
        });


        editor.canvas.on('object:moving', function (e) {
            isObjectMoving = true;
        });

        editor.canvas.on('mouse:up', function (e) {
            if (isObjectMoving) {
                isObjectMoving = false;

                const mark = {
                    id: e.currentTarget.id,
                    x: Math.round(e.currentTarget.left / scale),
                    y: Math.round(e.currentTarget.top / scale),
                }

                props.onMarkMoved(mark);
            }
        });
    }


    return (
        <div ref={map} >
            <FabricJSCanvas className={classes.mapCanvas} onReady={onReady} />
        </div>
    );
};

FieldMap.defaultProps = {
    marks: [],
    onMarkMoved: () => {}
};

export default FieldMap;
