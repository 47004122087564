import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import {notHaveErrors, validateFields, validateForm} from 'utils/form';
import * as yup from 'yup';
import Input from 'components/UI/Input/Input';
import DatePicker from 'components/UI/DatePicker/DatePicker';
import Select from 'components/UI/Select/Select';
import Textarea from 'components/UI/Textarea/Textarea';
import classes from './HealthConditionForm.module.scss';
import BodyMap from 'components/UI/BodyMap/BodyMap';
import {useSavePlayerHealthConditions} from 'apollo/queries/PlayerQueries';

const HealthConditionForm = (props) => {
    const {defaultValues, onComplete} = props;

    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const [values, setValues] = useState({
        ...defaultValues ?? {
            date: new Date(),
            healthCondition: "GOOD",
            severityOfInjury: "Moderate"
        }
    });
    const [errors, setErrors] = useState({});
    const [points, setPoints] = useState([]);

    const [savePlayerHealthConditions] = useSavePlayerHealthConditions();



    useEffect(() => {
        if (values.healthCondition !== "INJURY") {
            setPoints([]);
            return;
        }

        const point = points[0] ?? {id: 'INJURY-POINT', color: "#F8571B", x: values.positionX, y: values.positionY};

        switch (values.severityOfInjury) {
            case "MINOR":
                point.color = "#3BCE9D";
                setPoints([point]);
                break;
            case "MODERATE":
                point.color = "#FFC31F";
                setPoints([point]);
                break;
            default:
                point.color = "#F8571B";
                setPoints([point]);
                break;
        }


    }, [values.healthCondition, values.severityOfInjury]);

    const onPointMoved = (point) => {
        setPoints([point]);
    }

    const fieldChanged = (event) => {
        const newValues = {...values};
        newValues[event.target.name] = event.target.value;

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });

        setValues(newValues);
    };

    const formSubmitted = (e) => {
        e.preventDefault();
        validateForm(values, validationRules, (errors) => {
            if (notHaveErrors(errors)) saveData();
            else setErrors(errors);
        });
    };

    const validationRules = {
        date: yup.string().required(t('required')),
        healthCondition: yup.string().required(t("required")),
        severityOfInjury: yup.string().when("healthCondition", {is: "INJURY", then: yup.string().required(t("required"))})
    };

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors,
    };

    const saveData = () => {
        const variables = {
            input: {
                playerHealthConditionId: values.playerHealthConditionId,
                clubId: navState.clubId,
                playerId: defaultValues.playerId,
                date: values.date,
                healthCondition: values.healthCondition,
                severityOfInjury: "NONE",
                notes: values.notes
            },
        };

        if (values.healthCondition === "INJURY") {
            variables.input.severityOfInjury = values.severityOfInjury;
            variables.input.positionX = +points[0].x;
            variables.input.positionY = +points[0].y;
        }

        savePlayerHealthConditions({variables: variables}).then(result => {
            onComplete();
        });
    };

    return (
        <form id={props.id} onSubmit={formSubmitted}>
            <div className={classes.row12}>
                <div>
                    <Input
                        name="fullName"
                        label={t('Player')}
                        {...regField}
                        disabled={true}
                    />

                    <DatePicker
                        name="date"
                        label={t('Date')}
                        {...regField}
                    />

                    <Select
                        name="healthCondition"
                        label={t('Health condition')}
                        options={props.conditions}
                        {...regField}
                    />

                    {values.healthCondition === "INJURY" ?
                    <Select
                      name="severityOfInjury"
                      label={t('Health condition')}
                      options={props.injuries}
                      {...regField}
                    /> : undefined}

                    <Textarea
                        name="notes"
                        label={t('Comment')}
                        {...regField}
                    />
                </div>
                <div>
                    <BodyMap
                      gender={values.gender}
                      points={points}
                      onPointMoved={onPointMoved}
                      readOnly={values.healthCondition !== "INJURY"}
                    />
                </div>
            </div>
        </form>
    );
};

HealthConditionForm.defaultProps = {
    conditions: [
        {value: 'GOOD', label: 'Good'},
        {value: 'ILLNESS', label: 'Illness'},
        {value: 'INJURY', label: 'Injury'},
    ],
    injuries: [
        {value: 'MINOR', icon: '<i class="icon-Info-Square icon-green"></i>', label: 'Minor'},
        {value: 'MODERATE', icon: '<i class="icon-Info-Square icon-yellow"></i>', label: 'Moderate'},
        {value: 'SEVERE', icon: '<i class="icon-Info-Square icon-red"></i>', label: 'Severe'},
    ],
};

export default HealthConditionForm;
