import React, {useEffect, useState} from 'react';
import Languages from 'components/UI/Languages/Languages'
import Input from 'components/UI/Input/Input'
import Checkbox from 'components/UI/Checkbox/Checkbox'
import Button from 'components/UI/Button/Button'
import {useTranslation} from "react-i18next";
import {useReactiveVar} from '@apollo/client';
import {regStateVar} from "apollo/variables/registration";
import {notHaveErrors, validateFields, validateForm} from "utils/form";
import * as yup from "yup";
import axios from 'axios';
import ErrorMessage from 'components/UI/ErrorMessage/ErrorMessage';
import Spinner from 'components/UI/Spinner/Spinner';
import classes from './UserEmail.module.scss';
import {useOutletContext} from 'react-router-dom';

const UserEmail = () => {
    const {t} = useTranslation();
    const [setType] = useOutletContext();
    const regState = useReactiveVar(regStateVar);

    const [values, setValues] = useState(regState);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [hasNetError, setNetError] =useState(false);

    const loginUrl = process.env.REACT_APP_AUTHORITY + '/Account/Login';

    useEffect(() => {
        setType("right")
        const loader = document.querySelector(".preloader");
        loader.classList.add("loader-hide");
    }, []);

    const fieldChanged = (event) => {
        const newValues = {...values};

        if (event.target.type === "checkbox")
            newValues[event.target.name] = event.target.checked;
        else
            newValues[event.target.name] = event.target.value;

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });

        setValues(newValues);
    }

    const validationRules = {
        email: yup.string().required(t("wrongEmail")).email(t("wrongEmail")).matches(/^[aA-zZ0-9-_.@\s]+$/, t("Please check email.")),
        agree: yup.boolean().required().oneOf([true]),
    }

    const onSubmit = (e) => {
        e.preventDefault();

        validateForm(values, validationRules, async (errors) => {
            if (notHaveErrors(errors)) {
                await sendMessage();
            } else
                setErrors(errors);
        });
    }

    const sendMessage = async () => {
        setLoading(true);
        setNetError(false);

        const params = new URLSearchParams();
        params.append('email', values.email);

        await axios.post(process.env.REACT_APP_REGISTRATION_URL + '/checkmail', params).then((response) => {
            if (response.status === 200) {
                Ok(response);
            }
            else {
                Error();
            }
        }).catch(() => {
            Error();
        });
    }

    const Ok = (response) => {
        setLoading(false);

        const isNew = response.data.type === 0;
        const invited = response.data.invited;
        const main = isNew ? 'UserRole' : 'Variants';
        // const country = _registrationSettings.selectedCountry;
        // const currency = _registrationSettings.selectedCurrency

        let role = response.data.type;
        if (response.data.type === 0) role = 1;
        if (response.data.type === 5) role = 2;

        regStateVar({
            ...regState,
            ...values,
            main: main,
            isNew: isNew,
            role: role,
            invited: invited,
            // country: country,
            // currency: currency
        });
    }

    const Error = () => {
        setLoading(false);
        setNetError(true);
    }

    return (
        <div className={classes.UserEmail}>
            <Languages/>
            <h1>{t('Registration')}</h1>
            <div className={classes.login}>
                {t('Already have an account?')} <a href={loginUrl}>{t('Login')}</a>
            </div>

            { hasNetError && <ErrorMessage message={t('error404')} /> }

            <fieldset disabled={loading}>
                <div>
                    <Input
                        name="email"
                        type="email"
                        value={values}
                        onChange={fieldChanged}
                        error={errors}
                        placeholder={t('Email')} />

                    <Checkbox
                        name="agree"
                        value={values}
                        onChange={fieldChanged}
                        error={errors}
                        label={<React.Fragment>
                            {t('I agree with')} <a target="_blank" href={`${process.env.REACT_APP_REVIEW_URL}/terms-and-conditions`}>
                                {t('Conditions of service')}</a> {t('and')} <a target="_blank" href={`${process.env.REACT_APP_REVIEW_URL}/privacy-policy`}>
                                {t('Privacy policy')}</a>.
                        </React.Fragment>}
                    />
                </div>

                <Button onClick={onSubmit} type="primary" style={{width: '100%', textAlign: 'center'}}>
                    {loading ? <Spinner /> : t('Create an account')}
                </Button>
            </fieldset>
        </div>
    );
}

export default UserEmail
