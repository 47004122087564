import React, {useEffect, useState} from 'react';
import cn from 'classnames';
import classes from './Attendance.module.scss';
import Submenu from 'components/Navigation/Submenu/Submenu';
import Panel from 'components/UI/Panel/Panel';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import DatePicker from 'components/UI/DatePicker/DatePicker';
import {firstDayOfMonth, firstDayOfYear, lastDayOfMonth, lastDayOfYear, toDateTime} from 'utils/date';
import Matrix from 'components/UI/Matrix/Matrix';
import Button from 'components/UI/Button/Button';
import {useTeamAttendanceStatistic} from 'apollo/queries/GameQueries';
import Select from 'components/UI/Select/Select';

const AttendanceStatistic = (props) => {
    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const [matrixData, setMatrixData] = useState([]);
    const [columns, setColumns] = useState("170px repeat(4, 90px) 100px 1fr");

    const [values, setValues] = useState({
        startDate: firstDayOfMonth(),
        endDate: lastDayOfMonth(),
        show: "ALL"
    });

    const [getTeamAttendanceStatistic, useTeamAttendanceStatisticData] = useTeamAttendanceStatistic();

    useEffect(() => {
        if (!navState.clubId) return;

        getTeamAttendanceStatistic({ variables: {
            clubId: navState.clubId,
            teamId: navState.teamId,
            statisticType: values.show,
            from: values.startDate,
            till: values.endDate
        }});
    }, [navState, values]);

    useEffect(() => {
        if(!useTeamAttendanceStatisticData.data) return;

        const {data} = useTeamAttendanceStatisticData;

        let _matrixData = [];
        const _header = {
            rowId: "header0",
            cells: [
                { columnId: "column-name", cellName: t("Player")},
                { columnId: "column-present", cellName: t("Present")},
                { columnId: "column-wasLate", cellName: t("Was late")},
                { columnId: "column-ill", cellName: t("Ill")},
                { columnId: "column-excused", cellName: t("Excused")},
                { columnId: "column-unexcused", cellName: t("Unexcused")},
                { columnId: "column-attendance", cellName: t("Attendance")},
            ]
        };

        _matrixData.push(_header);

        const _rows = data.players.items.map(p => {
            const stat = data.teamAttendanceStatistic.find(f => f.playerId === p.playerId);
            const {present = 0, wasLate = 0, ill = 0, excused = 0, unexcused = 0} = stat ?? {};

            const attendance = present + wasLate;
            const sum = present  + wasLate + ill +excused + unexcused;

            const percent = attendance !== 0 ? Math.round( attendance / sum * 100) : 0;

            const _row = {
                rowId: p.playerId,
                cells: [
                    { columnId: p.playerId, cellName: `${p.fullName} (${p.teams[0]?.teamNumber})`},
                    { columnId: "column-present", cellName: present},
                    { columnId: "column-wasLate", cellName: wasLate},
                    { columnId: "column-ill", cellName: ill},
                    { columnId: "column-excused", cellName: excused},
                    { columnId: "column-unexcused", cellName: unexcused},
                    { columnId: "column-attendance", cellName:  `${attendance}/${sum} (${percent}%)`}
                ]
            };

            setColumns(`170px repeat(5, 90px) 120px 1fr`);
            return _row;
        });

        _matrixData = _matrixData.concat(_rows);

        setMatrixData(_matrixData);

    }, [useTeamAttendanceStatisticData.data]);

    const actions = () => {
        return (
            <Button type="primary" style={{margin: 0}}>
                <span><i className="icon-Send"></i> {t("Share")}</span>
            </Button>
        )
    }

    const onFilter = (e) => {
        const newFilters = {...values};
        newFilters[e.target.name] = e.target.value;
        setValues(newFilters);
    }

    const filters = () => {
        return (
            <>
                <DatePicker
                    name="startDate"
                    onChange={onFilter}
                    placeholder={t("Date from")}
                    value={values}
                />

                <DatePicker
                    name="endDate"
                    onChange={onFilter}
                    placeholder={t("Date till")}
                    value={values}
                />

                <Select
                  small="small"
                  name="show"
                  value={values}
                  onChange={onFilter}
                  options={props.show}
                  placeholder={t("Show")} />
            </>
        )
    }

    if (useTeamAttendanceStatisticData.loading) return (<div>{t("Loading...")}</div>);
    if (useTeamAttendanceStatisticData.error) return (<div>{t("Error...")}</div>);

    return (
        <>
            <div className={cn("page-header", classes.attendance)}>
                <div className={classes.name}>{navState.teamName}</div>
                <h2>{t('Attendance statistic')}</h2>
                <Submenu />
            </div>
            <div className="page-content">
                <Panel border={false} action={actions} filters={filters} />

                <Matrix data={matrixData} columns={columns} />
            </div>
        </>
    );
};

AttendanceStatistic.defaultProps = {
    attendance: [
        {label: "Present", icon: "<i class=\"icon-Check-Square icon-green\"></i>", value: "PRESENT"},
        {label: "Was late", icon: "<i class=\"icon-Time-Square icon-yellow\"></i>", value: "WAS_LATE"},
        {label: "Ill", icon: "<i class=\"icon-medicine icon-blue\"></i>", value: "ILL"},
        {label: "Excused", icon: "<i class=\"icon-Document icon-blue\"></i>", value: "EXCUSED"},
        {label: "Unexcused", icon: "<i class=\"icon-Close-Square icon-red\"></i>", value: "UNEXCUSED"},
    ],
    show: [
        {label: "All", value: "ALL" },
        {label: "Trainings", value: "TRAININGS" },
        {label: "Games", value: "GAMES" }
    ],
};

export default AttendanceStatistic;

