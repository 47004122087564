import axios from 'axios';


export const fileUploader = (file, data, auth) => {
    const formData = new FormData();

    formData.append('clubId', data.clubId);
    formData.append('fileType', data.type ?? 'CLUB_FILE');
    formData.append('fileId', data.fileId);
    formData.append('name', file.name);
    formData.append('contentType', file.type);
    formData.append('size', file.size);
    formData.append('file', file);

    return axios.post(process.env.REACT_APP_STORAGE_FILE_UPLOAD_URL,
      formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            authorization: `Bearer ${auth?.user?.access_token}`
          }
      }
    );
};
