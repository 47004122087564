import React, {PropTypes, useEffect, useState} from 'react';
import classes from './MatchesDetails.module.scss';
import cn from 'classnames';
import {useTranslation} from 'react-i18next';
import SwitchComponents from '../../../components/System/SwitchComponents';
import PlayVideo from './forms/PlayVideo';
import ModalWindow2, {ModalState} from '../../../components/UI/ModalWindow/ModalWindow2';

const VideoMatrix = (props) => {
    const {t} = useTranslation();

    const [modalData, setModalData, initState] = ModalState();

    const [matrix, setMatrix] = useState({});
    const [matrixLength, setMatrixLength] = useState(0);

    const {eventRecords, events, players} = props.data;

    useEffect(() => {
        if (eventRecords?.length === 0)
            return;

        const filteredEvents = events.filter(f => f.isVideo).map(e => e.id);

        const videoMatrix = {};
        eventRecords.filter(f => filteredEvents.indexOf(f.gameDataEventId) && f.videoId !== null && f.eventType !== "NOTE").forEach(e => {
            const player = e.playerName !== null ? e.playerName : e.eventType;
            const array = videoMatrix[e.title] !== undefined && videoMatrix[e.title][player] !== undefined ? videoMatrix[e.title][player] : [];

            videoMatrix[e.title] = {
                ...videoMatrix[e.title],
                [player]: [...array, {time: e.time, videoId: e.videoId}]
            }
        });

        setMatrix(videoMatrix);
        setMatrixLength(players.length + 2);
    }, [eventRecords, events]);

    if (Object.keys(matrix).length === 0)
        return (<div className={classes.empty}>{t("No data to display.")}</div>);

    console.log(props.data);

    const onVideoClick = (data) => {
        console.log(data);

        if (Array.isArray(data))
            data = data[0];

        const {name, link} = data;

        const _data = {
            ...initState,
            form: "video",
            title: name,
            defaultValues: {
                video: link ?? "https://youtu.be/N-1f7nVEdOM"
            },
            open: true
        };

        setModalData(_data);
    }

    console.log(players);

    return (
        <div>
            <div className={classes.matrix}>
                <div className={cn(classes.matrixRow, classes.matrixHeader)}>
                    <div className={classes.leftPanel}>&nbsp;</div>
                    <div className={classes.matrixCells}
                         style={{gridTemplateColumns: `150px repeat(${matrixLength}, 65px)`}}>
                        <div className={classes.leftPanelSpacer}></div>
                        {players.map(p => <div key={`matrix-${p.playerId}`}
                                                          className={classes.event}>{p.playerName} ({p.teamNumber})</div>)}
                        <div className={classes.event}>{props.data?.ownerTeamName}</div>
                        <div className={classes.event}>{props.data?.otherTeamName}</div>
                    </div>
                </div>

                {matrix && Object.entries(matrix).map(([key, value]) => (
                    <div key={`matrix-${key}`} className={classes.matrixRow}>
                        <div className={classes.leftPanel}>{key}</div>
                        <div className={classes.matrixCells}
                             style={{gridTemplateColumns: `150px repeat(${matrixLength}, 65px)`}}>
                            <div className={classes.leftPanelSpacer}></div>
                            {players.map(p => {
                               const events = Object.entries(value).find(f => f[0] === p.playerName);
                               return ( <div key={`matrix-${key}-${p.playerId}`} onClick={() => onVideoClick(events)}>
                                    {events !== undefined ? events[1].length : undefined}
                                </div>);
                            })}
                            <div className={classes.border}>{matrix[key]['TEAM_EVENT']?.length}</div>
                            <div>{matrix[key]['OPPONENT_EVENT']?.length}</div>
                        </div>
                    </div>
                ))}
            </div>

            <ModalWindow2 {...modalData}>
                <SwitchComponents active={modalData.form}>
                    <PlayVideo {...modalData} name="video" />
                </SwitchComponents>
            </ModalWindow2>
        </div>
    );
};

VideoMatrix.propTypes = {};
VideoMatrix.defaultProps = {
    data: {}
};

export default VideoMatrix;
