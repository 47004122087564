import React, {useState} from 'react';
import Input from 'components/UI/Input/Input';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar, systemDataVar} from 'apollo/cashe';
import {notHaveErrors, validateFields, validateForm} from 'utils/form';
import classes from './EvaluationForm.module.scss';
import * as yup from 'yup';
import {useAddCouchEvaluation, useEditCouchEvaluation} from 'apollo/queries/ClubQueries';
import Select from 'components/UI/Select/Select';

const CoachEvaluationForm = (props) => {
	const {defaultValues, pages, setModal, setLoading, onComplete, coaches} = props;

	const {t} = useTranslation();
	const navState = useReactiveVar(naviDataVar);

	const [values, setValues] = useState(defaultValues);
	const [errors, setErrors] = useState({});

	const [addEvaluation] = useAddCouchEvaluation(navState.clubId);
	const [editEvaluation] = useEditCouchEvaluation(navState.clubId);

	const fieldChanged = (event) => {
		const newValues = {...values};
		newValues[event.target.name] = event.target.value;

		validateFields(newValues, validationRules, (errors) => {
			setErrors(errors);
		});

		recalculate(newValues);
		setValues(newValues);
	};

	const regField = {
		type: 'number',
		min: 0,
		step: 0.5,
		value: values,
		onChange: fieldChanged,
		error: errors
	};


	const pageOneValidationRules = {
		coach: yup.string().required(t('Required')),
		size: yup.number().min(0, t('Minimum value') + ' 0').max(1, t('Maximum value') + ' 1').typeError(t('Require')).required(t('Required')),
		state: yup.number().min(0, t('Minimum value') + ' 0').max(1, t('Maximum value') + ' 1').typeError(t('Require')).required(t('Required')),
		safety: yup.number().min(0, t('Minimum value') + ' 0').max(1, t('Maximum value') + ' 1').typeError(t('Require')).required(t('Required')),
		equipmentMinimum: yup.number().min(0, t('Minimum value') + ' 0').max(1.5, t('Maximum value') + ' 1.5').typeError(t('Require')).required(t('Required')),
		equipmentUsing: yup.number().min(0, t('Minimum value') + ' 0').max(1.5, t('Maximum value') + ' 1.5').typeError(t('Require')).required(t('Required')),
		headCouch: yup.number().min(0, t('Minimum value') + ' 0').max(2, t('Maximum value') + ' 2').typeError(t('Require')).required(t('Required')),
		assistant: yup.number().min(0, t('Minimum value') + ' 0').max(2, t('Maximum value') + ' 2').typeError(t('Require')).required(t('Required')),
		language: yup.number().min(0, t('Minimum value') + ' 0').max(2, t('Maximum value') + ' 2').typeError(t('Require')).required(t('Required')),
		attendance80: yup.number().min(0, t('Minimum value') + ' 0').max(2, t('Maximum value') + ' 2').typeError(t('Require')).required(t('Required')),
		discipline: yup.number().min(0, t('Minimum value') + ' 0').max(1, t('Maximum value') + ' 1').typeError(t('Require')).required(t('Required')),
	};

	const pageTwoValidationRules = {
		age: yup.number().min(0, t('Minimum value') + ' 0').max(1, t('Maximum value') + ' 1').typeError(t('Require')).required(t('Required')),
		process: yup.number().min(0, t('Minimum value') + ' 0').max(1, t('Maximum value') + ' 1').typeError(t('Require')).required(t('Required')),
		conspectus: yup.number().min(0, t('Minimum value') + ' 0').max(1, t('Maximum value') + ' 1').typeError(t('Require')).required(t('Required')),
		voice: yup.number().min(0, t('Minimum value') + ' 0').max(1, t('Maximum value') + ' 1').typeError(t('Require')).required(t('Required')),
		recommendation: yup.number().min(0, t('Minimum value') + ' 0').max(1, t('Maximum value') + ' 1').typeError(t('Require')).required(t('Required')),
		emotion: yup.number().min(0, t('Minimum value') + ' 0').max(1, t('Maximum value') + ' 1').typeError(t('Require')).required(t('Required')),
		equipment: yup.number().min(0, t('Minimum value') + ' 0').max(1.5, t('Maximum value') + ' 1.5').typeError(t('Require')).required(t('Required')),
		tasks: yup.number().min(0, t('Minimum value') + ' 0').max(1.5, t('Maximum value') + ' 1.5').typeError(t('Require')).required(t('Required')),
		showing: yup.number().min(0, t('Minimum value') + ' 0').max(3, t('Maximum value') + ' 3').typeError(t('Require')).required(t('Required')),
		withTrainers: yup.number().min(0, t('Minimum value') + ' 0').max(1.5, t('Maximum value') + ' 1.5').typeError(t('Require')).required(t('Required')),
		withAthletes: yup.number().min(0, t('Minimum value') + ' 0').max(1.5, t('Maximum value') + ' ').typeError(t('Require')).required(t('Required')),
	};

	const validationRules = {...pageOneValidationRules, ...pageTwoValidationRules};

	const recalculate = (values) => {
		values['infrastructure'] = sum(values, ['size', 'state', 'safety']);
		values['inventory'] = sum(values, ['equipmentMinimum', 'equipmentUsing']);
		values['coaches'] = sum(values, ['headCouch', 'assistant']);
		values['languages'] = sum(values, ['language']);
		values['athletes'] = sum(values, ['attendance80', 'discipline']);
		values['totalClub'] = sum(values, ['infrastructure', 'inventory', 'coaches', 'languages', 'athletes']);
		values['plan'] = sum(values, ['age', 'process', 'conspectus']);
		values['training'] = sum(values, ['voice', 'recommendation', 'emotion']);
		values['explanation'] = sum(values, ['equipment', 'tasks']);
		values['show'] = sum(values, ['showing']);
		values['communication'] = sum(values, ['withTrainers', 'withAthletes']);
		values['totalCouch'] = sum(values, ['plan', 'training', 'explanation', 'show', 'communication']);
	};

	const sum = (newValues, items) => {
		let result = 0;
		items.forEach((item) => {
			result += newValues[item] !== undefined
				? Number(newValues[item]) : 0;
		});
		return result;
	};

	const showSum = (name) => (values && values[name] !== undefined ? values[name] : 0);

	const onPageChanged = (newPage) => {
		const page = newPage - 1;
		setModal({...props, pages: {...props.pages, page: page}});
	};

	const formSubmitted = async (e) => {
		e.preventDefault();
		//setLoading(true);

		new Promise(function (resolve, reject) {
			//Page 1
			validateForm(values, pageOneValidationRules, (errors) => {
				notHaveErrors(errors) ? resolve(1) : reject({page: 1, errors});
			});
		}).then(function (result) {
			//Page 2
			return new Promise(function (resolve, reject) {
				validateForm(values, pageTwoValidationRules, (errors) => {
					notHaveErrors(errors) ? resolve(2) : reject({page: 2, errors});
				});
			});
		}).then(function (result) {
			saveData();
		}).catch(e => {
			setErrors(e.errors);
			onPageChanged(e.page);
			setLoading(false);
		});
	};

	const saveData = async () => {
		const document = {...values};

		delete document.id;
		delete document.coach;

    const coachName = coaches.find(c => c.value === values.coach).label;

		if (values.id === undefined) {
		  const variables = {
		    clubId: navState.clubId,
		    keyItem: values.coach,
		    document: JSON.stringify(document),
		    title:  coachName + ' - trenera darba novērtējums',
		    clubEvaluation: document.totalClub,
		    couchEvaluation: document.totalCouch
		  };
		  await addEvaluation({variables});
		  onComplete();
		}

		if (values.id !== undefined){
		  const variables = {
		    id: values.id,
		    document: JSON.stringify(document),
		    clubEvaluation: document.totalClub,
		    couchEvaluation: document.totalCouch,
		  };
		  await editEvaluation({variables});
			onComplete();
		}
	};

	const PageOne = () => (
		<>
			<div>
				<Select
					name="coach"
					label={t('Coach')}
					options={coaches ?? []}
					value={values}
					onChange={fieldChanged}
					error={errors}/>
			</div>
			<div className={classes.row}>
				<div className="col-md-12 form-group">
					<h3>Infrastruktūra</h3>
				</div>
			</div>
			<div className={classes.row}>
				<div className="col-md-8 form-group">
					Izmērs
				</div>
				<div className="col-md-4 form-group">
					<Input name="size" key="k1" {...regField} min={0} max={1}/>
				</div>
			</div>
			<div className={classes.row}>
				<div className="col-md-8 form-group">
					Laukuma gattavība
				</div>
				<div className="col-md-4 form-group">
					<Input name="state" key="k2" {...regField} min={0} max={1}/>
				</div>
			</div>
			<div className={classes.row}>
				<div className="col-md-8 form-group">
					Drošība
				</div>
				<div className="col-md-4 form-group">
					<Input name="safety" key="k3" {...regField} min={0} max={1}/>
				</div>
			</div>
			<div className={classes.row}>
				<div className="col-md-12 form-group">
					<h3>Inventārs</h3>
				</div>
			</div>
			<div className={classes.row}>
				<div className="col-md-8 form-group">
					Minimālais nepieciešamā inventāra esamība
				</div>
				<div className="col-md-4 form-group">
					<Input name="equipmentMinimum" key="k4" {...regField} min={0} max={1.5}/>
				</div>
			</div>
			<div className={classes.row}>
				<div className="col-md-8 form-group">
					Izmantošana
				</div>
				<div className="col-md-4 form-group">
					<Input name="equipmentUsing" key="k5" {...regField} min={0} max={1.5}/>
				</div>
			</div>
			<div className={classes.row}>
				<div className="col-md-12 form-group">
					<h3>Treneri</h3>
				</div>
			</div>
			<div className={classes.row}>
				<div className="col-md-8 form-group">
					Galvenais treneris
				</div>
				<div className="col-md-4 form-group">
					<Input name="headCouch" key="k6" {...regField} {...regField} min={0} max={2}/>
				</div>
			</div>
			<div className={classes.row}>
				<div className="col-md-8 form-group">
					Asistents un papildus speciālisti.
				</div>
				<div className="col-md-4 form-group">
					<Input name="assistant" key="k7" {...regField} {...regField} min={0} max={2}/>
				</div>
			</div>
			<div className={classes.row}>
				<div className="col-md-12 form-group">
					<h3>Valodas</h3>
				</div>
			</div>
			<div className={classes.row}>
				<div className="col-md-8 form-group">
					Valoda
				</div>
				<div className="col-md-4 form-group">
					<Input name="language" key="k8" {...regField} {...regField} min={0} max={2}/>
				</div>
			</div>
			<div className={classes.row}>
				<div className="col-md-12 form-group">
					<h3>Futbolisti</h3>
				</div>
			</div>
			<div className={classes.row}>
				<div className="col-md-8 form-group">
					Klātesamības procents no max audzēkņu skaita grupā<br/>
					<small>80% un vairāk = 2 pukti, 50% un vairāk = 1 pukts</small>
				</div>
				<div className="col-md-4 form-group">
					<Input name="attendance80" key="k9" {...regField} {...regField} min={0} max={2}/>
				</div>
			</div>
			<div className={classes.row}>
				<div className="col-md-8 form-group">
					Futbolistu ekipējums,disciplīna
				</div>
				<div className="col-md-4 form-group">
					<Input name="discipline" key="k11" {...regField} {...regField} min={0} max={1}/>
				</div>
			</div>
		</>
	);

	const PageTwo = () => (
		<>
			<div className={classes.row}>
				<div className="col-md-12 form-group">
					<h3>Treneris</h3>
				</div>
			</div>
			<div className={classes.row}>
				<div className="col-md-8 form-group">
					Atbilstība vecuma grupai
				</div>
				<div className="col-md-4 form-group">
					<Input name="age" key="k21" {...regField} min={0} max={1}/>
				</div>
			</div>
			<div className={classes.row}>
				<div className="col-md-8 form-group">
					Atbilstība treniņprocesa metodikai
				</div>
				<div className="col-md-4 form-group">
					<Input name="process" key="k22" {...regField} min={0} max={1}/>
				</div>
			</div>
			<div className={classes.row}>
				<div className="col-md-8 form-group">
					Konspekts
				</div>
				<div className="col-md-4 form-group">
					<Input name="conspectus" key="k22-1" {...regField} min={0} max={1}/>
				</div>
			</div>
			<div className={classes.row}>
				<div className="col-md-12 form-group">
					<h3>Treniņa vadīšana</h3>
				</div>
			</div>
			<div className={classes.row}>
				<div className="col-md-8 form-group">
					Balss
				</div>
				<div className="col-md-4 form-group">
					<Input name="voice" key="k23" {...regField} min={0} max={1}/>
				</div>
			</div>
			<div className={classes.row}>
				<div className="col-md-8 form-group">
					Konstruktīvas rekomendācijas
				</div>
				<div className="col-md-4 form-group">
					<Input name="recommendation" key="k24" {...regField} min={0} max={1}/>
				</div>
			</div>
			<div className={classes.row}>
				<div className="col-md-8 form-group">
					Aktivitāte,emocionilitāte
				</div>
				<div className="col-md-4 form-group">
					<Input name="emotion" key="k25" {...regField} min={0} max={1}/>
				</div>
			</div>
			<div className={classes.row}>
				<div className="col-md-12 form-group">
					<h3>Izskaidrošana</h3>
				</div>
			</div>
			<div className={classes.row}>
				<div className="col-md-8 form-group">
					Treniņa vielas
				</div>
				<div className="col-md-4 form-group">
					<Input name="equipment" key="k26" {...regField} min={0} max={1.5}/>
				</div>
			</div>
			<div className={classes.row}>
				<div className="col-md-8 form-group">
					Uzdevumu
				</div>
				<div className="col-md-4 form-group">
					<Input name="tasks" key="k27" {...regField} {...regField} min={0} max={1.5}/>
				</div>
			</div>
			<div className={classes.row}>
				<div className="col-md-12 form-group">
					<h3>Demonstrēšana</h3>
				</div>
			</div>
			<div className={classes.row}>
				<div className="col-md-8 form-group">
					Demonstrēšana
				</div>
				<div className="col-md-4 form-group">
					<Input name="showing" key="k28" {...regField} {...regField} min={0} max={3}/>
				</div>
			</div>
			<div className={classes.row}>
				<div className="col-md-12 form-group">
					<h3>Komunikācija</h3>
				</div>
			</div>
			<div className={classes.row}>
				<div className="col-md-8 form-group">
					Ar treneru štābu
				</div>
				<div className="col-md-4 form-group">
					<Input name="withTrainers" key="k29" {...regField} {...regField} min={0} max={1.5}/>
				</div>
			</div>
			<div className={classes.row}>
				<div className="col-md-8 form-group">
					Ar spēlētājiem
				</div>
				<div className="col-md-4 form-group">
					<Input name="withAthletes" key="k30" {...regField} {...regField} min={0} max={1.5}/>
				</div>
			</div>
		</>
	);


	return (
		<>
			{pages.page === 0 && PageOne()}
			{pages.page === 1 && PageTwo()}

			<form id={props.id} onSubmit={formSubmitted}></form>
		</>
	);
};

CoachEvaluationForm.defaultProps = {
	page: 0,
};

export default CoachEvaluationForm;
