import {naviDataVar, systemDataVar} from 'apollo/cashe';

export const SystemRoles = {
    SysAdmin: '47767a040fa648a2bdbc8bc2d512676c',
    GroupManger: '35e2a1048c4640aa9c6134472640c226',
    GroupMember: '24acc3f5b6e34005bdc396dc4fa081cb',
    ClubAdmin: 'd69fc8e4360644169406d583bc939ead',
    //Federations: '0daada1a843b4bc58682c35a9f440ece',
    Coach: '01ee5ba651164941b26c9a949f0b2fd3',
    Medic: 'b5486881c9fd43e3a1df2519a9745db1',
    Parent: '86ef54c741984500b3c7f1476470147e',
    Player: '981c53943b8d49ee8e3ca40e3244d8d6',
};

export const GetRootPage = () => {
    const systemData = systemDataVar();
    const naviData = naviDataVar();

    switch (systemData.user.userRole.roleId) {
        case SystemRoles.SysAdmin:
            return `${process.env.REACT_APP_ROOT}`;
        case SystemRoles.GroupManger:
        case SystemRoles.GroupMember:
            return `${process.env.REACT_APP_ROOT}/group-list`;
        case SystemRoles.ClubAdmin:
        case SystemRoles.Coach:
        case SystemRoles.Medic:
            return `${process.env.REACT_APP_ROOT}/club-${naviData.clubId}`;
        case SystemRoles.Parent:
            return `${process.env.REACT_APP_ROOT}/club-${naviData.clubId}/parent-${naviData.clubId}`;
        case SystemRoles.Player:
            return `${process.env.REACT_APP_ROOT}/club-${naviData.clubId}/team-all/player-${naviData.playerId}`;
        default:
            return `${process.env.REACT_APP_ROOT}/404`;
    }
}

