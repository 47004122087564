import React from 'react';
import classes from './HederWithSettings.module.scss';
import CanAccess from '../../Navigation/CanAccess/CanAccess';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';

const HeaderWithSettings = (props) => {
    const {title, onSettings} = props;

    return (
        <h3 className={classes.header}>{title}
            <CanAccess users={[SystemRoles.Coach,SystemRoles.ClubAdmin,SystemRoles.SysAdmin]}><button onClick={onSettings}><i className="icon-Setting" /></button></CanAccess>
        </h3>
    );
};

HeaderWithSettings.defaultProps = {};

export default HeaderWithSettings;
