import React, {useState, useEffect} from 'react';
import classes from './Settings.module.scss';
import {useTranslation} from "react-i18next";
import General from './General/General';
import Account from './Account/Account';
import Theme from './Theme/Theme';
import Permissions from './Permissions/Permissions';
import Finances from './Finances/Finances';
import Reports from './Reports/Reports';
import Mail from './Mail/Mail';
import Notification from './Notification/Notification';
import Integrations from './Integrations/Integrations';
import Club from './Club/Club';
import Video from './Video/Video';
import Venues from './Venues/Venues';
import Skills from './Skills/Skills';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from '../../apollo/cashe';
import CanAccess from '../../components/Navigation/CanAccess/CanAccess';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';

const Settings = (props) => {
  const {t} = useTranslation();
  const navState = useReactiveVar(naviDataVar);

  const [selectedMenu, setSelectedMenu] = useState(props.selected);
  const [showMenu, setShowMenu] = useState(false);

  const [rerender, setRerender] = useState(false);

  useEffect(() => {
    if (selectedMenu === "settings") {
      setShowMenu(true);
    }
  }, [selectedMenu])

  const onChangePage = (page) => {
    setShowMenu(false);
    setSelectedMenu(page);
    setRerender(!rerender);
  }

  const onBack = () => {
    setShowMenu(true);
  }

  return (
      <div className={classes.Settings}>
        <div className={classes.Header}>
          <div></div>
          <div>
            {t("Settings")}
          </div>
          <div className={classes.Close}>
            <button onClick={props.onClose} title={t("Close")}>x</button>
          </div>
          <div></div>
        </div>
        <div className={classes.Content}>
          <div></div>
          {showMenu || document.body.clientWidth > 992 ? <div>
              <ul className={[classes.menu].join(' ')}>
                <li
                  onClick={() => onChangePage('general')}
                  className={selectedMenu === "general" ? classes.selected : undefined}>
                  <i  className="icon-Setting"></i> {t("General")}
                </li>
                <li><b>{t("User")}</b></li>
                <li
                  onClick={() => onChangePage('account')}
                  className={selectedMenu === "account" ? classes.selected : undefined}>
                  <i  className="icon-Profile"></i> {t("Account")}
                </li>
                <li
                  onClick={() => onChangePage('theme')}
                  className={selectedMenu === "theme" ? classes.selected : undefined}>
                  <i  className="icon-color-palette-1"></i> {t("Theme")}
                </li>

                {navState.clubId
                    ? <>
                      <li><b>{t("Club")}</b></li>

                      <CanAccess users={[SystemRoles.ClubAdmin,SystemRoles.SysAdmin]}>
                        <li onClick={() => onChangePage('club')}
                            className={selectedMenu === "club" ? classes.selected : undefined}>
                          <i  className="icon-Category"></i> {t("Club")}
                        </li>
                      </CanAccess>

                      <CanAccess users={[SystemRoles.Coach,SystemRoles.ClubAdmin,SystemRoles.SysAdmin]}>
                        <li onClick={() => onChangePage('venues')}
                            className={selectedMenu === "venues" ? classes.selected : undefined}>
                          <i  className="icon-Location"></i> {t("Venues")}
                        </li>
                      </CanAccess>

                      <CanAccess users={[SystemRoles.ClubAdmin,SystemRoles.SysAdmin]}>
                        <li onClick={() => onChangePage('video')}
                            className={selectedMenu === "video" ? classes.selected : undefined}>
                          <i  className="icon-videoPlay"></i> {t("Video & TTP")}
                        </li>
                      </CanAccess>

                      <CanAccess users={[SystemRoles.ClubAdmin,SystemRoles.SysAdmin]}>
                        <li onClick={() => onChangePage('skills')}
                            className={selectedMenu === "skills" ? classes.selected : undefined}>
                          <i  className="icon-Activity"></i> {t("Skills")}
                        </li>
                      </CanAccess>

                      <CanAccess users={[SystemRoles.ClubAdmin,SystemRoles.SysAdmin]}>
                        <li onClick={() => onChangePage('finances')}
                            className={selectedMenu === "finances" ? classes.selected : undefined}>
                          <i  className="icon-dollar"></i> {t("Finances")}
                        </li>
                      </CanAccess>

                      <CanAccess users={[SystemRoles.ClubAdmin,SystemRoles.SysAdmin]}>
                        <li onClick={() => onChangePage('mail')}
                            className={selectedMenu === "mail" ? classes.selected : undefined}>
                          <i  className="icon-message"></i> {t("Mail settings")}
                        </li>
                      </CanAccess>

                      <CanAccess users={[SystemRoles.ClubAdmin,SystemRoles.SysAdmin]}>
                        <li onClick={() => onChangePage('reports')}
                            className={selectedMenu === "reports" ? classes.selected : undefined}>
                          <i  className="icon-Document"></i> Отчеты
                        </li>
                      </CanAccess>

                      {/*<CanAccess users={[SystemRoles.ClubAdmin,SystemRoles.SysAdmin]}>*/}
                      {/*  <li onClick={() => onChangePage('permissions')}*/}
                      {/*      className={selectedMenu === "permissions" ? classes.selected : undefined}>*/}
                      {/*    <i  className="icon-lock"></i> {t("Permissions")}*/}
                      {/*  </li>*/}
                      {/*</CanAccess>*/}

                      <CanAccess users={[SystemRoles.Player,SystemRoles.Parent,SystemRoles.Medic,SystemRoles.Coach,SystemRoles.Federations,SystemRoles.ClubAdmin,SystemRoles.SysAdmin]}>
                        <li onClick={() => onChangePage('notifications')}
                            className={selectedMenu === "notifications" ? classes.selected : undefined}>
                          <i  className="icon-Notification1"></i> {t("Notifications")}
                        </li>
                      </CanAccess>
                    </> : undefined}

                <li><b>{t("Other")}</b></li>
                <li
                    onClick={() => onChangePage('integrations')}
                    className={selectedMenu === "integrations" ? classes.selected : undefined}>
                  <i  className="icon-Category"></i> {t("Integrations")}
                </li>
              </ul>
          </div> : undefined}

          <div className={[classes.content, showMenu ? classes.hide : undefined].join(' ')}>
              {(selectedMenu === "general" || selectedMenu === "settings") && <General onBack={onBack} />}
              {selectedMenu === "account" && <Account onBack={onBack}  />}
              {selectedMenu === "theme" && <Theme onBack={onBack}  />}
              {selectedMenu === "club" && <Club onBack={onBack}  />}
              {selectedMenu === "venues" && <Venues onBack={onBack}  />}
              {selectedMenu === "video" && <Video onBack={onBack} rerender={rerender} />}
              {selectedMenu === "skills" && <Skills onBack={onBack} rerender={rerender} />}
              {selectedMenu === "finances" && <Finances onBack={onBack}  />}
              {selectedMenu === "mail" && <Mail onBack={onBack}  />}
              {selectedMenu === "reports" && <Reports onBack={onBack}  />}
              {selectedMenu === "permissions" && <Permissions onBack={onBack} />}
              {selectedMenu === "notifications" && <Notification onBack={onBack} />}
              {selectedMenu === "integrations" && <Integrations onBack={onBack} />}
            </div>
            <div></div>
          </div>
      </div>
  );
};

Settings.defaultProps = {
  selected: 'general'
}

export default Settings;
