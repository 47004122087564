import React, {useState} from 'react';
import Input from '../../../../components/UI/Input/Input';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from '../../../../apollo/cashe';
import {useInvitePlayers} from '../../../../apollo/queries/PermissionsQueries';
import {notHaveErrors, validateFields, validateForm} from '../../../../utils/form';
import * as yup from 'yup';

const ParentForm = (props) => {
    const {defaultValues, onComplete} = props;

    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const [values, setValues] = useState(defaultValues);
    const [errors, setErrors] = useState({});

    const [invitePlayers] = useInvitePlayers();

    const fieldChanged = (event) => {
        const newValues = { ...values };
        newValues[event.target.name] = event.target.value

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });

        setValues(newValues);
    }

    const formSubmitted = (e) => {
        e.preventDefault();
        validateForm(values, validationRules, (errors) => {
            if (notHaveErrors(errors))
                saveData();
            else
                setErrors(errors);
        });
    }

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors
    };

    const validationRules = {
        email: yup.string().required(t("wrongEmail")).email(t("wrongEmail")),
    }

    const saveData = () => {
        const user = {
            id: defaultValues.id,
            email: values.email,
            roles: values.roles
        }
        //
        // invitePlayers({variables: {clubId: navState.clubId, users: [user]}}).then((result) => {
        //     onComplete(result.data.inviteUsers);
        // });
    }


    return (
        <form id={props.id} onSubmit={formSubmitted}>
            <Input
                name="firstName"
                placeholder={t('First name')}
                {...regField}
            />

            <Input
                name="lastName"
                placeholder={t('Last name')}
                {...regField}
            />

            <Input
                name="email"
                type="email"
                placeholder={t('E-mail')}
                {...regField}
            />

            <Input
                name="phone"
                placeholder={t('Phone')}
                {...regField}
            />
        </form>
    );
};

ParentForm.defaultProps = {};

export default ParentForm;
