import React, {useEffect, useRef, useState} from 'react';
import {FabricJSCanvas, useFabricJSEditor} from 'fabricjs-react';
import {fabric} from 'fabric';
import classes from './PointMap.module.scss';
import {useTranslation} from 'react-i18next';

const BodyMap = (props) => {
  const {gender, points, readOnly, onPointMoved} = props;

  const {t} = useTranslation();

  const [scale, setScale] = useState(1);

  const imageUrl = gender !== 'FEMALE'
    ? '/assets/images/human.png'
    : '/assets/images/woman.png'

  const [loaded, setLoaded] = useState(false);

  const { editor, onReady } = useFabricJSEditor();
  const mapRef = useRef(null);
  const imageRef = useRef(null);

  useEffect(() => {
    if (!editor) {
      return;
    }

    if (!loaded) {
      setLoaded(true);
      loading();
    }

  }, [editor]);

  useEffect(() => {
    if (!editor) {
      return;
    }

    addPoints(points);
  }, [points]);

  const loading = () => {
    editor.canvas.on('object:moving', function (e) {
      const obj = e.target;
      obj.setCoords();

      if(obj.getBoundingRect().top < 0 || obj.getBoundingRect().left < 0){
        obj.top = Math.max(obj.top, obj.top-obj.getBoundingRect().top);
        obj.left = Math.max(obj.left, obj.left-obj.getBoundingRect().left);
      }

      if(obj.getBoundingRect().top+obj.getBoundingRect().height  > obj.canvas.height
        || obj.getBoundingRect().left+obj.getBoundingRect().width  > obj.canvas.width){
        obj.top = Math.min(obj.top, obj.canvas.height-obj.getBoundingRect().height+obj.top-obj.getBoundingRect().top);
        obj.left = Math.min(obj.left, obj.canvas.width-obj.getBoundingRect().width+obj.left-obj.getBoundingRect().left);
      }
    });

    editor.canvas.on('mouse:up', function (e) {
      if (e.currentTarget) {
        const point = {
          id: e.currentTarget.id,
          x: Math.round(e.currentTarget.left / scale),
          y: Math.round(e.currentTarget.top / scale),
          color: e.currentTarget.fill,
        }

        onPointMoved(point);
      }
    });
  }

  const imageReady = () => {
    const imageScale = mapRef.current.offsetWidth / imageRef.current.naturalWidth;
    const x = imageRef.current.naturalWidth * imageScale;
    const y = imageRef.current.naturalHeight * imageScale;

    setScale(imageScale);

    editor.canvas.setWidth(x);
    editor.canvas.setHeight(y);
  }

  const addPoints = (points) => {
    editor.canvas.clear();
    const x = editor.canvas.width;
    const y = editor.canvas.height;

    points.forEach(point => {
      const circle = new fabric.Circle({
        radius: 7,
        fill: point.color ?? '#FFC31F',
        stroke: '#ff9800',
        strokeWidth: 1,
        left: point.x ?? (x * scale),
        top: point.y ?? (y * scale),
        originX: 'center',
        originY: 'center',
        hasControls: false,
        hasBorders: false,
        selectable: !readOnly,
      });

      editor.canvas.add(circle);
    });

    editor.canvas.renderAll();
  }


  return (
    <div ref={mapRef} className={classes.map}>
      <img
          ref={imageRef}
          src={imageUrl}
          className={classes.image}
          onLoad={imageReady}
          alt={t("Body map")}
      />
      <FabricJSCanvas className={classes.canvas} onReady={onReady} />
    </div>
  );
};

BodyMap.defaultProps = {
  points: {},
  onPointMoved: () => {}
};

export default BodyMap;
