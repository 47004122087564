import React, {useState} from 'react';
import classes from './UserRole.module.scss';
import Progress from 'components/UI/Progress/Progress'
import Button from 'components/UI/Button/Button'
import {useTranslation} from "react-i18next";
import {useReactiveVar} from "@apollo/client";
import {regStateVar} from "apollo/variables/registration";
import * as yup from "yup";
import {notHaveErrors, validateForm} from "utils/form";
import Select from "components/UI/Select/Select";

const UserRole = () => {
  const {t} = useTranslation();
  const regState = useReactiveVar(regStateVar);

  const [values, setValues] = useState(regState);
  const [errors, setErrors] = useState({});

  const fieldChanged = (event) => {
    const newValues = {...values};
    newValues[event.target.name] = event.target.value;
    setValues(newValues);
  }

  const validationRules = {
    role: yup.number().required(t("selectError")).typeError(t("selectError"))
  }

  const onSubmit = (event) => {
    event.preventDefault();

    const nextPage = values.role < 3 ? 'CreateNewClub' : 'IsClubRegistered';

    validateForm(values, validationRules, (errors) => {
      if (notHaveErrors(errors)) {
        regStateVar({
          ...regState,
          ...values,
          main: nextPage
        });
      } else
        setErrors(errors);
    });
  }

  const onGoTo = (goto, e) => {
    e.preventDefault();

    regStateVar({
      ...regState,
      main: goto
    });
  }

  const options = [
    {label: t('role1'), value: '1'},
    {label: t('role2'), value: '2'},
    {label: t('role3'), value: '3'},
    {label: t('role4'), value: '4'}
  ];

  return (
      <div className={classes.UserEmail}>
        <Button onClick={(e) => onGoTo('UserEmail', e)} type="third">  &#8592; {t('goBack')}</Button>
        <h1>{t('yourRole')}</h1>

        <Progress step="1" max="2"/>

        <div>
          <Select
              name="role"
              value={values}
              onChange={fieldChanged}
              error={errors}
              options={options}
              placeholder={t('selectRole')}
          />
        </div>

        <Button onClick={onSubmit} type="primary">{t('Next')}</Button>
      </div>
  );
}

export default UserRole
