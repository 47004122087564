import React from 'react';
import classes from './SettingsSubmenu.module.scss';
import {useTranslation} from 'react-i18next';

const SettingsSubmenu = (props) => {
    const {menu, onClick} = props;

    const {t} = useTranslation();

    return (
        <ul className={classes.submenu}>
            {menu.map(x => (<li key={x.id} onClick={() => onClick(x.id)}>{t(x.name)}</li>))}
        </ul>
    );
};

SettingsSubmenu.defaultProps = {};

export default SettingsSubmenu;
