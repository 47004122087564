import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import SettingsTitle from '../../../components/UI/SettingsTitle/SettingsTitle';
import Panel from '../../../components/UI/Panel/Panel';
import Table from '../../../components/UI/Table/Table';
import Subtext from '../../../components/UI/Table/Subtext/Subtext';
import Pagination from '../../../components/UI/Pagination/Pagination';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from '../../../apollo/cashe';
import {useDeleteVenue, useVenues} from '../../../apollo/queries/GameQueries';
import Button from '../../../components/UI/Button/Button';
import VenueForm from './forms/VenueForm';
import ModalWindow2, {ModalState} from '../../../components/UI/ModalWindow/ModalWindow2';
import classes from 'containers/Settings/Settings.module.scss';

const Venues = (props) => {
    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);
    const onPage = 10;
    const [skip, setSkip] = useState(0);

    const [modalData, setModalData, initState] = ModalState();

    const queryVariables = {
        clubId: navState.clubId,
        take: onPage,
        skip: skip * onPage,
    };


    const {data, loading, error, refetch} = useVenues(queryVariables);
    const [deleteVenue] = useDeleteVenue(queryVariables);

    const onAddVenue = () => {
        const _data = {
            ...initState,
            title: t("Add new venue"),
            form: "venue",
            onComplete: onComplete,
            open: true
        }

        setModalData(_data);
    }



    const onRowClick = ({venueId, name, belongsToClub, showOnTrainings, showOnGames}) => {
        const _data = {
            ...initState,
            title: t("Edit venue"),
            defaultValues: {
                venueId,
                name,
                belongsToClub,
                showOnTrainings,
                showOnGames
            },
            onComplete: onComplete,
            onDeleteClick: onDeleteClick,
            open: true
        }

        setModalData(_data);
    }

    const onComplete = () => {
        setModalData(initState);
        refetch();
    }

    const onDeleteClick = (id) => {
        const variables = {
            input: {
                id: id,
                clubId: navState.clubId
            }
        };

        deleteVenue({variables: variables}).then(result => {
            setModalData(initState);
            refetch();
        });
    }

    const actions = () => {
        return (
            <Button type="primary" style={{margin: 0}}
                    onClick={onAddVenue}>
                <span><i className="icon-Plus-Square"></i> {t('Add venues')}</span>
            </Button>
        );
    };

    return (
        <>
            <div onClick={props.onBack} className={[classes.title, classes.link].join(" ")}>
                <i className={["icon-Arrow---Up", classes.backButton, "backArrow"].join(" ")}></i>
                {t("Venues")}
            </div>


            <Panel border={false} action={actions}/>

            {loading && <>Loading...</>}
            {error && <>Error...</>}
            {data && <>
                <Table
                    data={data.venues.items}
                    center={true}
                    columns={[
                        {
                            Header: t('Venue'),
                            accessor: 'name',
                            Cell: (cellData) => <>
                                {cellData.value}
                                {cellData.row.original.belongsToClub === true
                                    ? <Subtext>{t('Belongs to club')}</Subtext> : undefined }</>
                        },
                        {
                            Header: t('Show in matches'),
                            accessor: 'showOnGames',
                            Cell: (cellData) => <>{cellData.value? t('Yes') : t('No')}</>
                        },
                        {
                            Header: t('Show in trainings'),
                            accessor: 'showOnTrainings',
                            Cell: (cellData) => <>{cellData.value? t('Yes') : t('No')}</>
                        }
                    ]}
                    onRowClick={onRowClick}/>

                <Pagination page={skip} count={data.venues.totalCount} onPage={onPage}
                            onPageChange={(p) => setSkip(p.selected)}/>
            </>}

            <ModalWindow2 {...modalData}>
                <VenueForm {...modalData} />
            </ModalWindow2>
        </>
    );
};

Venues.defaultProps = {};

export default Venues;
