import React, {useEffect, useState} from 'react';
import cn from 'classnames';
import classes from './Opponents.module.scss';
import Submenu from 'components/Navigation/Submenu/Submenu';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import {useTranslation} from 'react-i18next';
import Panel from 'components/UI/Panel/Panel';
import Button from 'components/UI/Button/Button';
import {useDeleteOpponent, useOpponents} from 'apollo/queries/GameQueries';
import Table from 'components/UI/Table/Table';
import Pagination from 'components/UI/Pagination/Pagination';
import OpponentForm from './forms/OpponentForm';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';

const Opponents = (props) => {
    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);
    const onPage = 10;

    const [skip, setSkip] = useState(0);

    const queryVariables = {
        teamId: navState.teamId,
        take: onPage,
        skip: skip * onPage,
    };

    const {data, loading, error, refetch} = useOpponents(queryVariables);
    const [deleteOpponent] = useDeleteOpponent(queryVariables);

    const [modalData, setModalData, initState] = ModalState();

    const actions = () => {
        return (
            <Button type="primary" style={{margin: 0}} onClick={onAddOpponent}>
                <span><i className="icon-Plus-Square"></i> {t('Add opponent')}</span>
            </Button>
        );
    };

    const onAddOpponent = () => {
        const _data = {
            ...initState,
            title: t("Add opponent"),
            form: "opponent",
            onComplete: onComplete,
            open: true
        }

        setModalData(_data);
    }

    const onRowClick = (row) => {
        const _data = {
            ...initState,
            title: t("Edit opponent"),
            form: "opponent",
            defaultValues: {
                ...row
            },
            onComplete: onComplete,
            onDelete: () => onDeleteClick(row),
            open: true
        }

        setModalData(_data);
    }

    const onComplete = async () => {
        setModalData(initState);
        await refetch();
    }

    const onDeleteClick = async (row) => {
        const variables = {
            input: {
                id: row.id,
                teamId: navState.teamId,
                clubId: navState.teamId
            }
        };

        await deleteOpponent({variables: variables});
        setModalData(initState);
        await refetch();
    }

    return (
        <>
            <div className={cn('page-header', classes.opponents)}>
                <div className={classes.name}>{navState.teamName}</div>
                <h2>{t('Opponents')}</h2>
                <Submenu/>
            </div>
            <div className="page-content">
                <Panel border={false} action={actions}/>

                {loading && <>Loading...</>}
                {error && <>Error...</>}
                {data && <>
                    <Table
                        data={data.opponents.items}
                        center={true}
                        columns={[
                            {
                                Header: t('Opponent'),
                                accessor: 'name',
                            },
                            {
                                Header: t('Games'),
                                accessor: 'games',
                            },
                            {
                                Header: t('Wins'),
                                accessor: 'wins',
                            },
                            {
                                Header: t('Losses'),
                                accessor: 'losses',
                            },
                            {
                                Header: t('Draws'),
                                accessor: 'draws',
                            },
                            {
                                Header: t('Goals'),
                                accessor: 'goals',
                            },
                        ]}
                        onRowClick={onRowClick}/>

                    <Pagination page={skip} count={data.opponents.totalCount} onPage={onPage}
                                onPageChange={(p) => setSkip(p.selected)}/>
                </>}
            </div>

            <ModalWindow2 {...modalData}>
                <OpponentForm {...modalData} name="opponent" />
            </ModalWindow2>
        </>
    );
};

Opponents.defaultProps = {};

export default Opponents;
