import React, {useCallback, useEffect, useRef, useState} from 'react';
import ExerciseBasicForm from './forms/ExeciseBasicForm';
import {filterOptions} from 'containers/Club/ExerciseLibrary/Settings';
import {createGuid, newId} from 'utils/utils';
import classes from './TrainingDetails.module.scss';
import cn from 'classnames';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar, systemDataVar} from 'apollo/cashe';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import ExerciseLibrary from 'containers/Club/ExerciseLibrary/ExerciseLibrary';
import Toast, {toastMessage} from 'components/UI/Toast/Toast';
import {useParams} from 'react-router-dom';
import {useSaveTraining} from 'apollo/queries/TrainingQueries';
import SwitchComponents from 'components/System/SwitchComponents';
import TestLibrary from 'containers/Club/ExerciseLibrary/TestLibrary';
import TestBasicForm from './forms/TestBasicForm';
import Panel from 'components/UI/Panel/Panel';
import Tabs from 'components/UI/Tabs/Tabs';
import BasicExerciseView from './view/BasicExerciseView';
import {useReactToPrint} from 'react-to-print';
import {Printer} from 'components/UI/Printer/Printer';
import Button from 'components/UI/Button/Button';
import BasicTestView from './view/BasicTestView';
import {showDateHours} from 'utils/date';
import TextEditor from 'components/UI/TextEditor/TextEditor';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';
import CanAccess from 'components/Navigation/CanAccess/CanAccess';

const TrainingDetailsPlan = (props) => {
    const {data, refetch} = props;

    const {t} = useTranslation();
    const { trainingId } = useParams();
    const navState = useReactiveVar(naviDataVar);
    const systemData = useReactiveVar(systemDataVar);

    const printerRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => printerRef.current,
        documentTitle: "AwesomeFileName",
    });

    const [modalData, setModalData, initState] = ModalState();

    const [edit, setEdit] = useState(null);
    const [mode, setMode] = useState("edit");
    const [players, setPlayers] = useState([]);
    const [detailedPlan, setDetailedPlan] = useState(null);
    const [exercises, setExercises] = useState({
        warmup: [], stretching: [], conditioning: [], tests: [], cooldown: []
    });

    const [saveTraining] = useSaveTraining();

    useEffect(() => {
        const _exercises = JSON.parse(data.trainings.items[0].exercises);
        if (_exercises) {
           setExercises(_exercises);
        }

        //Set view mode
        [SystemRoles.GroupMember, SystemRoles.GroupManger].includes(systemData.user.userRole.roleId) === false
            ? setMode(data.trainings.items[0].editMode ? "edit" : "view")
            : setMode("view");

        setPlayers(data.trainings.items[0].players);
        setDetailedPlan({detailedPlan: data.trainings.items[0].detailedPlan});
    }, [data]);

    const saveExercises = (list, reload = true) => {
        setExercises(list);

        const {name, block, trainingNumber, start, duration, venueId, group} = data.trainings.items[0] ?? {};

        const variables = {
            input: {
                trainingId: trainingId,
                teamId: navState.teamId,
                name: name,
                block: block,
                trainingNumber: trainingNumber,
                start: start,
                duration: +duration,
                venueId: venueId,
                group: group,
                exercises: JSON.stringify(list),
                editMode: mode === "edit",
                detailedPlan: detailedPlan.detailedPlan
            }
        };

        saveTraining({variables: variables}).then(result => {
            if (reload ) {
                refetch();
            }
        });
    }

    const saveMode = (newMode) => {
        const {name, block, trainingNumber, start, duration, venueId, group} = data.trainings.items[0] ?? {};

        const variables = {
            input: {
                trainingId: trainingId,
                teamId: navState.teamId,
                name: name,
                block: block,
                trainingNumber: trainingNumber,
                start: start,
                duration: +duration,
                venueId: venueId,
                group: group,
                exercises: JSON.stringify(exercises),
                editMode: newMode === "edit",
                detailedPlan: detailedPlan.detailedPlan
            }
        };

        saveTraining({variables: variables}).then(result => {});
    }

    const AddExercise = (data) => {
        return (
            <div className={cn(classes.addExercise, {[classes.disabled]:edit})}>
                    <span>
                        <span disabled={edit} onClick={() => SelectExercise(data)}><i className="icon-Plus-Square"></i> <div>
                        <button disabled={edit} className={classes.selectExercise}
                                onClick={() => SelectExercise(data)}>Select exercise</button> from
                        library or add <button  disabled={edit}  className={classes.selectExercise}
                                 onClick={() => AddNewExercise(data)}>new exercise</button>.</div>
                        </span>
                    </span>
            </div>
        );
    }

    const AddTest = (data) => {
        return (
            <div className={cn(classes.addExercise, {[classes.disabled]:edit})}>
                    <span>
                        <span disabled={edit} onClick={() => SelectTest(data)}><i className="icon-Plus-Square"></i> <div>
                        <button disabled={edit} className={classes.selectExercise}
                                onClick={() => SelectTest(data)}>Select test</button> from
                        library or add <button  disabled={edit}  className={classes.selectExercise}
                                                onClick={() => AddNewTest(data)}>new test</button>.</div>
                        </span>
                    </span>
            </div>
        );
    }

    const AddNewExercise = (data) => {
        const {list:type} = data;
        const list = {...exercises};
        const exerciseId = createGuid();

        list[type].push({exerciseId: exerciseId, isNew: true, editMode: true});
        saveExercises(list);
        setEdit(exerciseId);
    }

    const AddNewTest = (data) => {
        const {list:type} = data;
        const list = {...exercises};
        const metricId = createGuid();

        list[type].push({metricId: metricId, isNew: true, editMode: true});
        saveExercises(list);
        setEdit(metricId);
    }

    const SelectExercise = (data) => {
        const _data = {
            ...initState,
            title: t("Select training"),
            form: "exercise",
            action: "select",
            styles: {
                maxWidth: "95%",
            },
            defaultValues: {
                list: data.list
            },
            buttons: {
                hideSaveButton: true
            },
            onComplete: onSelectExercise,
            open: true
        };

        setModalData(_data);
    }

    const onSelectExercise = (data) => {
        const exercise = {
            isNew: false,
            exerciseId: data.id,
            name: data.name,
            duration: data.duration,
            repeat: data.repeat,
            rest: data.rest,
            rhythm: data.rhythm,
            coefficient: data.coefficient,
            level: data.level,
            description: data.description,
            tools: data.tools,
            image: data.image,
            scheme: data.scheme
        }

        const _exercises = {...exercises};
        _exercises[data.list].push(exercise);
        saveExercises(_exercises);

        toastMessage(true, t("Exercise successfully added to training"));
    }

    const SelectTest = (data) => {
        const _data = {
            ...initState,
            title: t("Select test"),
            form: "test",
            action: "select",
            styles: {
                maxWidth: "95%",
            },
            defaultValues: {
                list: 'tests'
            },
            buttons: {
                hideSaveButton: true
            },
            onComplete: onSelectTest,
            open: true
        };

        setModalData(_data);
    }

    const onSelectTest = (data) => {
        const test =
            {
            isNew: false,
            metricId: data.metricId,
            name: data.name,
            unitOfMeasure: data.unitOfMeasure,
            systemOfMeasurement: data.systemOfMeasurement,
            description: data.description,
            attempts: data.attempts,
            logo:  data.logo,
        }

        const _tests = {...exercises};
        _tests["tests"].push(test);
        saveExercises(_tests);

        toastMessage(true, t("Test successfully added to training"));
    }

    const ChangeExerciseList = (exercise) => {
        const _exercises = {...exercises};

        let index = exercise.exerciseId
            ? _exercises[exercise.list].findIndex(x => x.exerciseId === exercise.exerciseId)
            : _exercises[exercise.list].findIndex(x => x.metricId === exercise.metricId);

        _exercises[exercise.list][index] = exercise;

        saveExercises(_exercises, false);
    }

    const DeleteExercise = (id) => {
        const warmup = exercises.warmup.filter(x => x.exerciseId !== id);
        const stretching = exercises.stretching.filter(x => x.exerciseId !== id);
        const conditioning = exercises.conditioning.filter(x => x.exerciseId !== id);
        const tests = exercises.tests;
        const cooldown = exercises.cooldown.filter(x => x.exerciseId !== id);

        setEdit(null);
        const _exercises = {warmup, stretching, conditioning, tests, cooldown};
        saveExercises(_exercises);
    }

    const DeleteTest = (id) => {
        const tests = exercises.tests.filter(x => x.metricId !== id);
        setEdit(null);
        const _exercises = {...exercises, tests};
        saveExercises(_exercises);
    }

    const BasicExerciseForm = ({list, defaultData}) => {
        const {exerciseId, editMode} = defaultData;

        return  (<ExerciseBasicForm
            exerciseId={exerciseId}
            defaultData={{
                ...defaultData,
                list: list,
                duration: defaultData.duration ?? 5,
                rhythm: defaultData.rhythm ?? 140,
                coefficient: defaultData.coefficient ?? 0.4,
                level: defaultData.level ?? "ADVANCED",
                language: defaultData.language ?? navState.language,
                ageGroup: defaultData.ageGroup ?? 7,
                numberOfPlayers: defaultData.numberOfPlayers ?? 8,
                accessType: defaultData.accessType ??  "CLUB"}}
            editMode={editMode}
            level={filterOptions.level}
            language={filterOptions.language}
            accessType={filterOptions.accessType}
            edit={edit}
            setEdit={setEdit}
            onChange={ChangeExerciseList}
            onDelete={DeleteExercise}
        />);
    }

    const BasicTestForm = ({list, defaultData}) => {
        const {metricId, editMode} = defaultData;
        return  (<TestBasicForm
            metricId={metricId}
            defaultData={{
                ...defaultData,
                list: list,
                unitOfMeasure: "QUANTITY",
                systemOfMeasurement: "MORE_BETTER"
            }}
            editMode={editMode}
            level={filterOptions.level}
            language={filterOptions.language}
            accessType={filterOptions.accessType}
            edit={edit}
            setEdit={setEdit}
            onChange={ChangeExerciseList}
            onDelete={DeleteTest}
        />);
    }

    const onChangeMode = (val) => {
        setMode(val);
        saveMode(val);
    }

    const actions = () => (
        <>
            <Button type="primary" style={{margin: 0}} onClick={handlePrint}>
                <span><i className="icon-Plus-Square"></i> {t('Print')}</span>
            </Button>

            <CanAccess users={[SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin]}>
                <Tabs onClick={onChangeMode}
                      selected={mode}
                      items={props.tabs} />
            </CanAccess>
        </>
    );

    const onObjectivesChange = (data) => {
        setDetailedPlan({detailedPlan:data.target.value});
        saveMode(mode);
    }

    const EditPlan = () => {
        return (
        <>
            <h3>{t('Objectives')}</h3>
            <TextEditor
              name="detailedPlan"
              value={detailedPlan}
              onChange={onObjectivesChange}
            />

            <h3>{t('Warmup')}</h3>
            {exercises.warmup.map(x => (<BasicExerciseForm key={x.exerciseId} list="warmup" defaultData={x} />))}
            <AddExercise list="warmup" />

            <h3>{t('Main training')}</h3>
            {exercises.stretching.map(x => (<BasicExerciseForm key={x.exerciseId} list="stretching" defaultData={x} />))}
            <AddExercise list="stretching" />

            <h3>{t('Conditioning')}</h3>
            {exercises.conditioning.map(x => (<BasicExerciseForm key={x.exerciseId} list="conditioning" defaultData={x} />))}
            <AddExercise list="conditioning" />

            <h3>{t('Cooldown')}</h3>
            {exercises.cooldown.map(x => (<BasicExerciseForm key={x.exerciseId} list="cooldown" defaultData={x} />))}
            <AddExercise list="cooldown" />

            <h3>{t('Tests')}</h3>
            {exercises.tests.map(x => (<BasicTestForm key={x.metricId} list="tests" defaultData={x} />))}
            <AddTest list="tests" />
        </>
    )};


    const Header = () => {
        const {name, start, block, trainingNumber, duration, type, venue, group, rhythm, workload, trainers}
            = data?.trainings?.items[0] ?? {};

        return (
            <div>
                <div className={classes.coaches}>
                    <span>{t("Coaches")}:</span> {trainers.map(x => x.fullName).join(", ")}
                </div>
                <div className={cn("header", classes.quickInfo)}>
                    <div>
                        <div><span>{t("Date")}:</span> {showDateHours(start)}</div>
                        <div><span>{t("Training number")}:</span> {trainingNumber}</div>
                        <div><span>{t("Block name")}:</span> {block}</div>

                    </div>
                    <div>
                        <div><span>{t("Training type")}:</span> {type}</div>
                        <div><span>{t("Duration (min)")}:</span> {duration}</div>
                        <div><span>{t("Average heart rhythm")}:</span> {rhythm}</div>
                    </div>
                    <div>
                        <div><span>{t("Place")}:</span> {venue}</div>
                        <div><span>{t("Group")}:</span> {group}</div>
                        <div><span>{t("Total workload")}:</span> {workload}</div>
                    </div>
                </div>

                <div className={classes.team}>{navState.teamName}</div>
                <h1>{name}</h1>
            </div>
        );
    }

    const getPageMargins = () => {
        return `
            @page { margin: 20px 20px 20px 20px !important; }
            @media print {
              .viewOnPrint {display: block !important;}
              .header {background: #ccc !important; padding: 20px; }
                 * {-webkit-print-color-adjust:exact;}
               
            }
        `;
    };

    const ViewPlan = () => (
        <Printer ref={printerRef}>
            <div>
                <style>{getPageMargins()}</style>
                <div className={cn("viewOnPrint",
                    classes.hide
                )}>
                    <Header />
                </div>

                {detailedPlan?.detailedPlan ?
                    <div>
                        <h3>{t('Objectives')}</h3>
                        <div dangerouslySetInnerHTML={{__html: detailedPlan.detailedPlan}} />
                    </div>
                : undefined}

                {exercises.warmup?.length ?
                    <div>
                        <h3>{t('Warmup')}</h3>
                        {exercises.warmup.map((x, index) => (<BasicExerciseView key={x.exerciseId} list="warmup" defaultData={x} />))}
                    </div>
                : undefined}

                {exercises.stretching?.length ?
                    <div>
                        <h3>{t('Main training')}</h3>
                        {exercises.stretching.map(x => (<BasicExerciseView key={x.exerciseId} list="warmup" defaultData={x} />))}
                    </div>
                : undefined}

                {exercises.conditioning?.length ?
                    <div>
                        <h3>{t('Conditioning')}</h3>
                        {exercises.conditioning.map(x => (<BasicExerciseView key={x.exerciseId} list="warmup" defaultData={x}/>))}
                    </div>
                : undefined}

                {exercises.cooldown?.length ?
                    <div>
                        <h3>{t('Cooldown')}</h3>
                        {exercises.cooldown.map(x => (<BasicExerciseView key={x.exerciseId} list="cooldown" defaultData={x} />))}
                    </div>
                : undefined}

                {exercises.tests?.length ?
                    <div>
                        <h3>{t('Tests')}</h3>
                        {exercises.tests.map(x => (<BasicTestView key={x.metricId} defaultData={x} players={players} list="tests" onChange={ChangeExerciseList} />))}
                    </div>
                    : undefined}

                {exercises.warmup.length === 0
                    && exercises.stretching.length === 0
                    && exercises.conditioning.length === 0
                    && exercises.cooldown.length === 0
                    && exercises.tests.length === 0
                    && <div className={classes.empty}>{t("No data to display.")}</div>}
            </div>
        </Printer>
    )

    return (
        <>
            <Panel border={false} action={actions}>
                <br/>
            </Panel>

            {mode === "edit" ? <EditPlan /> : undefined}

            <div style={{display: mode === "edit" ? "none" : "block"}}>
                <ViewPlan />
            </div>

            <ModalWindow2 {...modalData}>
                <SwitchComponents active={modalData.form}>
                    <ExerciseLibrary {...modalData} name="exercise" />
                    <TestLibrary {...modalData} name="test" />
                </SwitchComponents>
            </ModalWindow2>

            <Toast />
        </>
    );
};

TrainingDetailsPlan.defaultProps = {
    tabs: [
        { id: 'edit', name: 'Edit'},
        { id: 'view', name: 'View'},
    ],
};

export default TrainingDetailsPlan;
