import React, {useEffect, useRef, useState} from 'react';
import Tooltip from 'react-tooltip-lite';
import TimePickerBase from './TimePickerBase';
import classes from './TimePicker3.module.scss';
import {useTranslation} from 'react-i18next';
import {haveDefaultValue, haveError} from 'utils/form';
import {showNumberAsTime} from 'utils/date';

const TimePicker3 = (props) => {
    const {isSportTimer} = props;

    const {t} = useTranslation();
    const ref = useRef(null);

    const cls = ['form-field', classes.Input];
    const htmlFor = props.id || `timePicker-${Math.random()}`;

    const error = haveError(props);
    const defaultValue = haveDefaultValue(props) ?? 0;

    const [value, setValue] = useState(defaultValue);
    const [isOpen, setOpen] = useState(false);


    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target) && isOpen === true) {
            setOpen(false);
            setValue(defaultValue ?? "");
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    if (error) {
        cls.push(classes.invalid)
    }

    const onOpen = (date) => {
        setOpen(true);
    }

    const onChange = (date) => {
        setValue(date.hours + date.minutes / 100);
    }

    const onSet = (date) => {
        props.onChange({
            target: {
                name: props.name,
                value: date.hours + date.minutes / 100
            }
        });
        setOpen(false);
    }

    const onClose = () => {
        setValue(defaultValue ?? "");
        setOpen(false);
    }

    return (
        <Tooltip
            isOpen={isOpen}
            content={(
                <div
                    ref={ref}
                >
                    <TimePickerBase
                        time={defaultValue}
                        onChange={onChange}
                        onSet={onSet}
                        onBack={onClose}
                        isSportTimer={isSportTimer}
                        isOpen={isOpen}
                    />
                </div>
            )}
            useHover={false}
            direction="down-start"
            arrow={false}
            tagName="span"
            className={classes.TimePicker}
            padding={15}
        >
            <div
                onClick={onOpen}
                className={cls.join(' ')} style={props.style}
            >
                { (props.label || defaultValue) ?  <label htmlFor={htmlFor}>{props.label || props.placeholder}</label> : undefined }

                <input
                    name={props.name}
                    id={htmlFor}
                    value={showNumberAsTime(value)}
                    disabled={true}
                    placeholder={props.placeholder}
                />

                { error && <span>{error || t('Required')}</span> }
            </div>
        </Tooltip>
    );
};

TimePicker3.defaultProps = {
    isSportTimer: false
};

export default TimePicker3;
