import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Submenu from 'components/Navigation/Submenu/Submenu';
import Panel from 'components/UI/Panel/Panel';
import Table from 'components/UI/Table/Table';
import DateTime from 'components/UI/Table/DateTime/DateTime';
import Pagination from 'components/UI/Pagination/Pagination';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import DatePicker from 'components/UI/DatePicker/DatePicker';
import {getDate, inThisWeek,today,todayMinus14,todayPlus14} from 'utils/date';
import {useDeleteTraining, useTrainings, useTrainingsAverageLazy} from 'apollo/queries/TrainingQueries';
import PanelItem from 'components/UI/PanelItem/PanelItem';
import TrainingForm from './forms/TrainingForm';
import Button from 'components/UI/Button/Button';
import {useNavigate} from 'react-router-dom';
import cn from 'classnames';
import classes from './Trainings.module.scss';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import Subtext from 'components/UI/Table/Subtext/Subtext';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';
import CanAccess from 'components/Navigation/CanAccess/CanAccess';

const Trainings = (props) => {
    const {t} = useTranslation();
    const history = useNavigate();

    const onPage = 20;
    const navState = useReactiveVar(naviDataVar);

    const [getTrainings, getTrainingsData] = useTrainings();
    const [getAverage, getAverageData] = useTrainingsAverageLazy();
    const [deleteTraining] = useDeleteTraining();

    const [skip, setSkip] = useState(0);
    const [values, setValues] = useState({
        startDate: todayMinus14(),
        endDate: todayPlus14()
    });

    const [modalData, setModalData, initState] = ModalState();

    useEffect(() => {
        if (navState.teamId === null)
            return;

        const variables = {
            teamId: navState.teamId,
            where: {
                "start": {"gte": values.startDate},
                "end": {"lte": values.endDate}
            },
            skip: skip * onPage,
            take: onPage
        };

        getTrainings({variables: variables});

        const average = {
            teamId: navState.teamId,
            from: values.startDate,
            till: values.endDate
        }

        getAverage({variables: average});
    }, [navState, values, skip, onPage]);


    const onFilter = (e) => {
        const newFilters = {...values};
        newFilters[e.target.name] = e.target.value;
        setValues(newFilters);
    }

    const onAddTraining = () => {
        const data = {
            ...initState,
            title: t("Add training"),
            onComplete: onComplete,
            open: true
        }

        setModalData(data);
    }

    const onComplete = async () => {
        setModalData(initState);
        await getTrainingsData.refetch();
    }

    const actions = () => {
        return (
          <CanAccess users={[SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin]}>
            <Button type="primary" style={{margin: 0}} onClick={onAddTraining}>
                <span><i className="icon-Plus-Square"></i> {t("Add training")}</span>
            </Button>
          </CanAccess>
        )
    }

    const filters = () => {
        return (
            <>
                <DatePicker
                    name="startDate"
                    onChange={onFilter}
                    label={t("Date from")}
                    value={values}
                />

                <DatePicker
                    name="endDate"
                    onChange={onFilter}
                    label={t("Date till")}
                    value={values}
                />
            </>
        )
    }

    const onRowClick = (item) => {
        history(`/club-${navState.clubId}/team-${navState.teamId}/training-${item.trainingId}`);
    }

    const Status = ({data}) => {
        switch (data) {
            case "ACCEPTED":
                return <span className={classes.accepted}>{t("Accepted")}</span>;
            case "IN_REVIEW":
                return <span className={classes.review}>{t("In review")}</span>;
            case "NO_PLAN":
                return <span className={classes.remake}>{t("No plan")}</span>;
            default:
                return <span className={classes.remake}>{t("Need remake")}</span>;
        }
    }

    const rowClassName = (row) => {
        return inThisWeek(row.start) ? classes.thisWeek : undefined;
    }

    const onSettings = () => {
        return [
            {label: t('Edit training'), id: '1', action: onEditTraining},
            {label: t('Copy training'), id: '2', action: onCopyTraining},
            {label: t('Delete training'), id: '3', action: onDeleteTraining},
        ];
    };

    const onEditTraining = (_data) => {
        const data = {
            ...initState,
            title: t("Edit training"),
            defaultValues: {
                ..._data
            },
            onComplete: onComplete,
            onDelete: () => onDeleteTraining(_data),
            open: true
        }

        setModalData(data);
    }


    const onCopyTraining = (_data) => {
        const defaultValues = {..._data};
        delete defaultValues.start;

        const data = {
            ...initState,
            title: t("Copy training"),
            defaultValues,
            onComplete: onComplete,
            copy: true,
            open: true
        }

        setModalData(data);
    }

    const onDeleteTraining = async (_data) => {
        setModalData(initState);

        const variables = {
            input: {
              clubId: navState.clubId,
              teamId: navState.teamId,
              id: _data.trainingId
            },
        };

        await deleteTraining({variables: variables});
        await getTrainingsData.refetch();
    }

    return (
        <>
            <div className={cn("page-header", classes.trainings)}>
                <div className={classes.name}>{navState.teamName}</div>
                <h2>{t('Trainings')}</h2>
                <Submenu />
            </div>
            <div className="page-content">
                <Panel border={false} action={actions} filters={filters} />

                {getTrainingsData.loading && <>Loading...</>}
                {getTrainingsData.error && <>Error...</>}
                {getTrainingsData.data && <>
                    <Panel>
                        <PanelItem name={t('Trainings')} value={getTrainingsData.data.trainings.totalCount} />
                        {getAverageData.data &&
                            <PanelItem name={t('Average players')} value={getAverageData.data.trainingsAveragePlayer ?? 0} />}
                    </Panel>

                    <br />

                    <Table
                        data={getTrainingsData.data.trainings.items}
                        access={[SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin]}
                        columns={[
                            {
                                Header: t('Date and time'),
                                accessor: 'start',
                                Cell: cellData => <DateTime data={cellData.value}/>
                            },
                            {
                                Header: t('Title'),
                                accessor: 'name',
                                Cell: (cellData) => <div>
                                    {cellData.value} <span className={classes.show}>, {cellData.row.original.group}</span>
                                    <Subtext className={classes.show}>
                                        {t("Place")}: {cellData.row.original.venue},  {t("Status")}: <Status  data={cellData.row.original.trainingStatus} />
                                    </Subtext>
                                </div>
                            },
                            {
                                Header: t('Group'),
                                accessor: 'group',
                                className: classes.hide,
                                Cell: (cellData) => <div className={classes.hide}>{t(cellData.value)}</div>
                            },
                            {
                                Header: t('Place'),
                                accessor: 'venue',
                                className: classes.hide,
                                Cell: (cellData) => <div className={classes.hide}>{cellData.value}</div>
                            },
                            {
                                Header: t('Players'),
                                accessor: 'playerInTeam',
                                className: classes.hide,
                                Cell: (cellData) => {
                                    const {original} = cellData.row;
                                    const playersOnTraining = getDate(original.start) > today()
                                        ? 0 : original.players.filter(x => (x.status === "PRESENT" || x.status === "WAS_LATE")).length;
                                    return (<div className={classes.hide}>{playersOnTraining} / {cellData.value}</div>);
                                }
                            },
                            {
                                Header: t('Status'),
                                accessor: 'trainingStatus',
                                className: classes.hide,
                                Cell: (cellData) => <div className={classes.hide}><Status  data={cellData.value} /></div>
                            }
                        ]}
                        rowClassName={rowClassName}
                        onSettings={onSettings}
                        onRowClick={onRowClick}/>

                    <Pagination page={skip} count={getTrainingsData.data.trainings.totalCount} onPage={onPage}
                                onPageChange={(p) => setSkip(p.selected)}/>
                </>}
            </div>

            <ModalWindow2 {...modalData}>
                <TrainingForm {...modalData} name="training" />
            </ModalWindow2>
        </>
    );
};

Trainings.propTypes = {};
Trainings.defaultProps = {};

export default Trainings;
