import React, {useState} from 'react';
import RadioList from '../../../../components/UI/RadioList/RadioList';
import {notHaveErrors, validateFields, validateForm} from '../../../../utils/form';
import * as yup from 'yup';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from '../../../../apollo/cashe';
import {useDeleteTeam} from '../../../../apollo/queries/TeamQueries';

const DeleteTeamForm = (props) => {
    const {teamOptions, playerActionOnArchive, playerActionOnDelete, setLoading} = props;
    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const [values, setValues] = useState({teamAction: "archive", playerAction: "leave"});
    const [errors, setErrors] = useState({});

    const [deleteTeam] = useDeleteTeam({clubId: navState.clubId, search: "", take: 50, skip: 0});

    const fieldChanged = (event) => {
        const newValues = { ...values };
        newValues[event.target.name] = event.target.value

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });

        setValues(newValues);
    }

    const validationRules = {
        teamAction: yup.string().required(t("required")),
        playerAction: yup.string().required(t("required")),
    }

    const formSubmitted = (e) => {
        e.preventDefault();
        validateForm(values, validationRules, (errors) => {
            if (notHaveErrors(errors))
                saveData();
            else
                setErrors(errors);
        });
    };

    const saveData = () => {
        const variables = {
            input: {
                teamId: navState.teamId,
                clubId: navState.clubId,
                archiveTeam: values.teamAction === "archive",
                leavePlayers: values.playerAction === "leave",
            }
        }

        deleteTeam({variables: variables}).then(result => {
            props.onComplete();
        });
    };

    return (
        <>
            <form id={props.id} onSubmit={formSubmitted}>
                <div>What to do with team?</div>
                <RadioList
                    name="teamAction"
                    options={teamOptions}
                    value={values}
                    onChange={fieldChanged}
                    error={errors}  />

                <div>What to do with players?</div>
                <RadioList
                    name="playerAction"
                    options={values.teamAction === "archive" ? playerActionOnArchive : playerActionOnDelete}
                    value={values}
                    onChange={fieldChanged}
                    error={errors}  />
            </form>
        </>
    );
};

DeleteTeamForm.defaultProps = {
    teamOptions: [
        {label: "Move team to archive", value: "archive"},
        {label: "Delete team from club", value: "delete"},
    ],
    playerActionOnArchive: [
        {label: "Leave players in club", value: "leave"},
        {label: "Move players to archive", value: "move"},
    ],
    playerActionOnDelete: [
        {label: "Leave players in club", value: "leave"},
        {label: "Delete players from club", value: "move"},
    ],
    onComplete: () => {
        console.log("onComplete");
    }
};

export default DeleteTeamForm;
