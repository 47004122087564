import React, {useEffect, useState} from 'react';
import cn from 'classnames';
import classes from './TeamStat.module.scss';
import Submenu from 'components/Navigation/Submenu/Submenu';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import Panel from 'components/UI/Panel/Panel';
import Tabs from 'components/UI/Tabs/Tabs';
import Card from 'components/UI/Card/Card';
import Group from 'components/UI/Group/Group';
import GoalChart from './charts/GoalChart';
import TendencyChart from './charts/TendencyChart';
import {useTeamStat} from 'apollo/queries/TeamQueries';
import GoalMap from './GoalMap';
import Select from 'components/UI/Select/Select';
import Table from 'components/UI/Table/Table';
import Subtext from 'components/UI/Table/Subtext/Subtext';
import {PlayerPositions} from '../MatchesDetails/Settings';

const TeamStat = (props) => {
    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const [viewTab, setViewTab] = useState("team");
    const [filters, setFilters] = useState({season: "2022", competition: undefined, opponent: undefined});
    const [seasonList, setSeasonList] = useState([]);
    const [competitionList, setCompetitionList] = useState(props.competitionList);
    const [opponentList, setOpponentList] = useState(props.opponentList);

    const {data,loading, error} = useTeamStat({
        teamId: navState.teamId,
        season: filters.season,
        competition: filters.competition,
        opponent: filters.opponent
    });

    useEffect(() => {
        const from = new Date().getFullYear();
        const till = 1970;
        const years = Array.from({length: (from - till)}, (v, k) => from - k);
        const list = [].concat(...years.map(x => ([{label: x + "/"+(x+1).toString().substring(2,4),
            value: x + "/"+(x+1).toString().substring(2,4)}, {label: x, value: x.toString()}])));
        setSeasonList(list);
    }, []);

    useEffect(() => {
        if (!data)
            return;

        const {competitions, teamOpponentsForStatistic} = data;

        const filteredCompetitions = competitions.items.map(x => ({label: x.name, value: x.id}));
        const filteredOpponents = teamOpponentsForStatistic.map(x => ({label: x.name, value: x.name}));

        setCompetitionList([...props.competitionList, ...filteredCompetitions]);
        setOpponentList([...props.opponentList, ...filteredOpponents]);
    }, [data]);

    const onFilter = (e) => {
        const newFilters = {...filters};
        newFilters[e.target.name] = e.target.value ? e.target.value : undefined;
        setFilters(newFilters);
    }

    const panelFilters = () => (
        <>
            <Tabs items={props.viewTabs} selected={viewTab} onClick={(x) => setViewTab(x)} />

            <Select
                small="small"
                name="season"
                value={filters}
                onChange={onFilter}
                options={seasonList}
                placeholder={t("Season")} />

            <Select
                small="small"
                name="competition"
                value={filters}
                onChange={onFilter}
                options={competitionList}
                placeholder={t("Competition")} />

            <Select
                small="small"
                name="opponent"
                value={filters}
                onChange={onFilter}
                options={opponentList}
                placeholder={t("Opponent")} />
        </>
    );

    const Info = ({data}) => {
        const {games, wins, draws, losses, goals, score} = data;
        return (
            <div className={classes.info}>
                <Card name={t("Matches")} value={games} />
                <Card name={t("Wins")} value={wins} />
                <Card name={t("Draws")} value={draws} />
                <Card name={t("Losses")} value={losses} />
                <Card name={t("Goals")} value={goals} />
                <Card name={t("Points")} value={score} />
            </div>
        );
    }

    const GoalChartGroup = ({data}) => {
        const {teamGoalStatistic, teamTendency} = data;
        return (
            <Group title="Goal statistics">
                <div className={cn(classes.columns11, classes.padding)}>
                    <div>
                        <GoalChart data={teamGoalStatistic} />
                    </div>

                    <div>
                        <TendencyChart data={teamTendency} />
                    </div>
                </div>
            </Group>
        );
    }

    const MapGroup = (props) => {
        return (
            <Group title="Goal map">
                <div className={classes.padding}>
                    <GoalMap {...props} />
                </div>
            </Group>
        );
    }

    const PlayerTable = ({data}) => {
        const {teamPlayerStatistic} = data;
        const players = teamPlayerStatistic.filter(f => f.goalkeeper !== true);

        return (
            <>
                <h3>{t('Players')}</h3>
                <Table
                    data={players}
                    columns={[
                        {
                            Header: t('Player'),
                            accessor: 'playerName',
                            Cell: (cellData) => <>
                                {cellData.value} ({cellData.row.original.teamNumber})
                                <Subtext>{t("Position")}: {getPosition(cellData.row.original.position)}</Subtext>
                            </>
                        },
                        {
                            Header: t('GP (FE)'),
                            accessor: 'matchesPlayed',
                            Cell: (cellData) => <>{cellData.value} ({cellData.row.original.matchesPlayedInMainTeam})</>
                        },
                        {
                            Header: t('MP'),
                            accessor: 'minutesPlayed',
                        },
                        {
                            Header: t('G'),
                            accessor: 'goals',
                        },
                        {
                            Header: t('A'),
                            accessor: 'assisted',
                        },
                        {
                            Header: t('YC'),
                            accessor: 'yellowCards',
                        },
                        {
                            Header: t('RC'),
                            accessor: 'redCards',
                        },
                        {
                            Header: t('APR'),
                            accessor: 'performanceRate',
                        },
                    ]}
                    onRowClick={() => {}}/>
            </>
        )
    }

    const getPosition = (position) => {
        return PlayerPositions().find(f => f.value === position)?.label;
    }

    return (
        <>
            <div className={cn('page-header', classes.teamStat)}>
                <div className={classes.name}>{navState.teamName}</div>
                <h2>{t('Statistic')}</h2>
                <Submenu/>
            </div>
            <div className="page-content">
                <Panel border={false} filters={panelFilters}>
                    <br />
                </Panel>

                {loading && <>Loading...</>}
                {error && <>Error...</>}
                {data &&
                <>
                    {viewTab === "team" &&
                        <>
                            <Info data={data.teamStatistic} />
                            <GoalChartGroup data={data} />
                            <MapGroup data={data} />
                        </>
                    }

                    {viewTab === "players" &&
                        <>
                            <PlayerTable data={data}  />
                        </>
                    }
                </>}
            </div>
        </>
    );
};

TeamStat.defaultProps = {
    viewTabs: [
        {id: "team", name: "Team"},
        {id: "players", name: "Players"},
    ],
    competitionList: [
        {label: "All", value: ""},
        {label: "Friendly matches", value: "friendly"}
    ],
    opponentList: [
        {label: "All", value: ""}
    ],
};

export default TeamStat;
