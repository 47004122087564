import React, {useState, useEffect} from 'react';
import classes from './LogoBar.module.scss'
import {isEmpty, isNotEmpty} from '../../../utils/utils';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from '../../../apollo/cashe';
import {Link, useNavigate} from 'react-router-dom';
import {useClub} from '../../../apollo/queries/ClubQueries';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';

const LogoBar = (props) => {
  const history = useNavigate();

  const [clubLogo, setClubLogo] = useState(props.clubLogo);
  const [clubName, setClubName] = useState(props.clubName);
  const [clubRoot, setClubRoot] = useState(props.clubRoot);

  const navState = useReactiveVar(naviDataVar);
  const [getClubData, {data}] = useClub();

  useEffect(() => {
    if (isNotEmpty(navState.clubId)) {
      getClubData({variables:{"clubId": navState.clubId, "teamId": navState.teamId}});
      setClubRoot(`/club-${navState.clubId}`);
    } else {
      setClubLogo(props.clubLogo);
      setClubName(props.clubName);
      setClubRoot(props.clubRoot);
    }
  }, [navState.clubId]);

  useEffect(() => {
    if (data) {
      setClubName(data.club.name);
      setClubLogo(data.club.logo);

      if (isEmpty(data.club.teamName) !== null) {
        naviDataVar({
          ...navState,
          teamName: data.club.teamName,
        });
      }
    }

  }, [data]);

  const onClick = (e) => {
    e.preventDefault();
    history(clubRoot);
    if (isNotEmpty(navState.clubId)) {
      const newState = {...navState};
      newState.teamId = null;
      newState.teamName = "";
      newState.playerId = null;
      newState.playerName = "";
      naviDataVar(newState);
    }
  };

  return (
      <div className={classes.LogoBar}>
        <div className={classes.panel}>
          <div>
            <Link
                onClick={onClick}
                className={classes.logo}
                style={{backgroundImage: `url(${clubLogo})`}}
                to={clubRoot} />
          </div>
          <div>
            <Link
                onClick={onClick}
                to={clubRoot}>{clubName}</Link>
          </div>
        </div>
      </div>
  );
};

LogoBar.defaultProps = {
  clubLogo: "/assets/images/emptyLogo.svg",
  clubName: "YoPlayDo",
  clubRoot: "/"
}

export default LogoBar;
