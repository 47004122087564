import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {useDeleteGroup, useGetGroups} from 'apollo/queries/GroupQueries';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import Button from 'components/UI/Button/Button';
import Search from 'components/UI/Panel/Search/Search';
import Submenu from 'components/Navigation/Submenu/Submenu';
import Panel from 'components/UI/Panel/Panel';
import Table from 'components/UI/Table/Table';
import classes from 'containers/SysAdmin/ClubGroups/ClubGroup.module.scss';
import Subtext from 'components/UI/Table/Subtext/Subtext';
import ClubGroupForm from 'containers/SysAdmin/ClubGroups/forms/ClubGroupForm';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';
import CanAccess from 'components/Navigation/CanAccess/CanAccess';

const ClubGroupList = (props) => {
    const {t} = useTranslation();
    const history = useNavigate();

    const onPage = 50;

    const [skip, setSkip] = useState(0);
    const [search, setSearch] = useState('');

    const {data, loading, error, refetch} = useGetGroups({
        parentGroupId: '00000000-0000-0000-0000-000000000000',
        search: search,
        take: onPage,
        skip: skip * onPage,
    });
    const [deleteGroup] = useDeleteGroup();

    const [modalData, setModalData, initState] = ModalState();

    const actions = () => {
        return (
          <CanAccess users={[SystemRoles.SysAdmin]}>
              <Button type="primary" style={{margin: 0}} onClick={onAddClick}>
                  <span><i className="icon-Plus-Square"></i> {t("Add group")}</span>
              </Button>
          </CanAccess>
        );
    }

    const onAddClick = () => {
        const _data = {
            ...initState,
            title: t("Add group"),
            form: "group",
            styles: {
                maxWidth: "700px",
            },
            defaultValues: {
                parentGroupId: '00000000-0000-0000-0000-000000000000'
            },
            onComplete: onComplete,
            open: true
        }

        setModalData(_data);
    }

    const onComplete = async () => {
        setModalData(initState);
        await refetch();
    }

    const panelSearch = () => {
        return (<Search onSearch={onSearch} placeholder={t("Search team...")} />);
    }

    const onSearch = (e) => {
        setSkip(0);
        setSearch(e.target.value);
    }

    const onClick = (data) => {
        history(`/group-${data.groupId}`);
    }

    const onSettings = () => {
        return [
            {label: 'Edit group', id: '1', action: onEditGroup},
            {label: 'Delete group', id: '2', action: onDeleteGroup},
        ];
    };

    const onEditGroup = (data) => {
        const _data = {
            ...initState,
            title: t("Add group"),
            form: "group",
            styles: {
                maxWidth: "700px",
            },
            defaultValues: {
                ...data
            },
            onComplete: onComplete,
            onDelete: () => onDeleteGroup(data),
            open: true
        }

        setModalData(_data);
    }

    const onDeleteGroup = async  (data) => {
        setModalData(initState);
        await deleteGroup({variables: {input: {id: data.groupId}}});
        await refetch();
    }


    return (
      <>
          <div className="page-header">
              <h2>{t('Club groups')}</h2>
              <Submenu/>
          </div>
          <div className="page-content">
              <Panel border={false} action={actions} search={panelSearch} />

              {loading && <>Loading...</>}
              {error && <>Error...</>}
              {data ?
                <>
                    <Table
                      data={data.groups.items}
                      access={[SystemRoles.SysAdmin, SystemRoles.ClubAdmin]}
                      columns={[
                          {
                              Header: '',
                              accessor: 'logo',
                              className: classes.rowLogo,
                              Cell: (cellData) => <div style={{cursor: 'pointer'}}>
                                  <img src={cellData.value ?? "/assets/images/emptyLogo.svg"} alt={cellData.row.original.name} /></div>
                          },
                          {
                              Header: t('Group name'),
                              accessor: 'name',
                              Cell: (cellData) => <div style={{cursor: 'pointer'}}>
                                  {cellData.value}
                                  <Subtext>{cellData.row.original.description}</Subtext>
                              </div>
                          },
                      ]}
                      onSettings={onSettings}
                      onRowClick={onClick}/>
                </>
                : undefined}
          </div>

          <ModalWindow2 {...modalData}>
              <ClubGroupForm {...modalData} name="group" />
          </ModalWindow2>
      </>
    );
};

ClubGroupList.defaultProps = {};

export default ClubGroupList;
