import React, {useState} from 'react';
import Input from "components/UI/Input/Input";
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import {notHaveErrors, validateFields, validateForm} from "utils/form";
import {useInvitePlayers} from 'apollo/queries/PermissionsQueries';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import {useCanAssignEmailToRoleLazy} from 'apollo/queries/SettingQueries';

const AccessForm = (props) => {
    const {defaultValues, onComplete, setLoading} = props;

    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const [values, setValues] = useState(defaultValues);
    const [errors, setErrors] = useState({});

    const [invitePlayers] = useInvitePlayers();
    const [canAssignEmailToRoleLazy] = useCanAssignEmailToRoleLazy();

    const fieldChanged = (event) => {
        const newValues = { ...values };
        newValues[event.target.name] = event.target.value

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });

        setValues(newValues);
    }

    const validationRules = {
        email: yup.string().required(t("wrongEmail")).email(t("wrongEmail")),
    }


    const formSubmitted = async (e) => {
        e.preventDefault();
        setLoading(true);
        validateForm(values, validationRules, (errors) => {
            if (notHaveErrors(errors))
                validateEmail();
            else {
                setErrors(errors);
                setLoading(true);
            }
        });
    }

    const validateEmail = async () => {
        const variables = {
            input: {
                email: values.email,
                clubId: navState.clubId ?? "",
                roleIds: values.roles
            }
        }

        const canAssign = await canAssignEmailToRoleLazy({variables});
        if (canAssign.data.canAssignEmailToRole === false) {
            const errors = {
                email: t('Email already assigned to this role. Check staff and archive.')
            };
            setErrors(errors);
            setLoading(false);
        } else {
            saveData();
        }
    }

    const saveData = () => {
        const variables = {
            clubId: navState.clubId,
            users: [{
                id: defaultValues.id,
                email: values.email,
                roles: values.roles
            }]
        };

        if (values.groupId !== undefined) {
            variables.clubId = values.groupId;
        }

        invitePlayers({variables}).then((result) => {
            onComplete(result.data.inviteUsers);
        }).catch((error) => {
            onComplete({ result: false, message: t("Invite not send.") });
            console.log(error);
        });
    }

    return (
        <form id={props.id} onSubmit={formSubmitted}>
            <div className="message">{t("Your invite will be send to this user.")}</div>

            <Input
                name="name"
                placeholder={t('Name')}
                value={defaultValues}
                disabled={true}
                />

            <Input
                name="email"
                placeholder={t('E-mail')}
                value={defaultValues}
                onChange={(e) => fieldChanged(e)}
                disabled={defaultValues.email !== ""}
                error={errors}/>
        </form>
    )
}

export default AccessForm

