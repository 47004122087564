import {gql, useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {CLUB_INFO} from './ClubQueries';

export const SETTINGS_FIELDS = gql`
    fragment SettingsFields on SettingType {
        general {
            language
            suggestions
            
            timeZone
            dateFormat
            timeFormat
            weekStart
            weekendStart
        }
        theme {
            name
        }
        apiVersion
        isActor
    }
`;

export const GET_SETTINGS = gql`
    ${SETTINGS_FIELDS}
    query GetSettings {
        settings {
            ...SettingsFields
        }
        user {
            userId
            firstName
            lastName
            email
            fullName
            userPhoto
            userRole {
                roleId
                name
            }
            roles {
                roleId
                name
                clubId
                clubName
                clubLogo
                objectId
            }
        }
        languages:locales {
            value:culture
            label:displayName
        }
    }`;
export const useSettings = (options = {}) => useQuery(GET_SETTINGS, options);

export const EDIT_SETTINGS = gql`
    ${SETTINGS_FIELDS}
    mutation EditSettings($input: SettingInput!) {
        editSettings(input: $input) {
            ...SettingsFields
        }
    }`;
export const useEditSettings = () => useMutation(EDIT_SETTINGS);

export const EDIT_USER = gql`
    mutation EditSettings($input: UserInput!) {
        editUser(user:$input){
            firstName
            lastName
            fullName
        }
    }`;
export const useEditUser = () => useMutation(EDIT_USER);

export const COUNTRY_SETTINGS = gql`
    query Country {
        country: otherSettings(
            where: { groupName: { eq: "Country" } }
            order: { order: ASC }
        ) {
            key
            value
        }

        state: otherSettings(
            where: { groupName: { eq: "CountryState" } }
            order: { order: ASC }
        ) {
            key
            value
            jsonAttributes
        }
    }
`;
export const useCountrySettings = () => useLazyQuery(COUNTRY_SETTINGS);


export const SAVE_CLUB_BASIC = gql`
    mutation SaveClubBasic($input: ClubBasicInput!) {
        saveClubBasic(input:$input)
    }`;
export const useSaveClubBasic = (variables) => useMutation(SAVE_CLUB_BASIC, {refetchQueries: [{ query: CLUB_INFO, variables: variables }]});

export const SAVE_CLUB_ADDRESS = gql`
    mutation SaveClubAddress($input: ClubAddressInput!) {
        saveClubAddress(input:$input)
    }`;
export const useSaveClubAddress = (variables) => useMutation(SAVE_CLUB_ADDRESS, {refetchQueries: [{ query: CLUB_INFO, variables: variables }]});

export const SAVE_CLUB_CONTACT = gql`
    mutation SaveClubContact($input: ClubContactInput!) {
        saveClubContact(input:$input)
    }`;
export const useSaveClubContact = (variables) => useMutation(SAVE_CLUB_CONTACT, {refetchQueries: [{ query: CLUB_INFO, variables: variables }]});



export const GET_LOCALES= gql`
    query Locales {
        locales {
            culture
            displayName
            translated
            translations
        }
    }`;
export const useLocales = () => useQuery(GET_LOCALES);

export const GET_TRANSLATES= gql`
    query Translates($language: LanguageFlag!, $where: TranslateTypeFilterInput, $skip:Int!) {
        translates(language:$language, where:$where, skip:$skip) {
            totalCount
            items {
                messageKeyId
                code
                prefix
                value
            }
        }
    }`;
export const useTranslates = (variables) => useQuery(GET_TRANSLATES, {variables});


export const GET_EMPTY_TRANSLATES= gql`
    query EmptyTranslates($language: LanguageFlag!, $where: TranslateTypeFilterInput, $skip:Int!) {
        emptyTranslates(language:$language, where:$where, skip:$skip) {
            totalCount
            items {
                messageKeyId
                code
                prefix
                value
            }
        }
    }`;
export const useEmptyTranslates = (variables) => useQuery(GET_EMPTY_TRANSLATES, {variables});


export const SAVE_TRANSLATE = gql`
    mutation SaveTranslate($input: TranslateInput!) {
        saveTranslate(input:$input) {
            messageKeyId
            code
            language
            prefix
            value
        }
    }`;
export const useSaveTranslate = () => useMutation(SAVE_TRANSLATE);

export const GOOGLE_TRANSLATE = gql`
    mutation GoogleTranslate($language: LanguageFlag!) {
        googleTranslate(language: $language)
    }`;
export const useGoogleTranslate = () => useMutation(GOOGLE_TRANSLATE);


export const SUGGEST_TRANSLATION = gql`
    mutation SuggestTranslation($input: SuggestedTranslationInput!) {
        suggestTranslation(input:$input)
    }`;
export const useSuggestTranslation = () => useMutation(SUGGEST_TRANSLATION);


export const GET_SUGGESTED_TRANSLATIONS = gql`
    query GetSuggestedTranslations($skip:Int!) {
        suggestedTranslations(skip:$skip) {
            totalCount
            items {
                suggestedTranslationId
                userId
                userName
                clubId
                culture
                tag
                value
                url
                suggestedTime
            }
        }
    }`;
export const useSuggestedTranslations = (variables) => useQuery(GET_SUGGESTED_TRANSLATIONS, {variables});

export const DELETE_SUGGESTED_TRANSLATIONS = gql`
    mutation DeleteSuggestedTranslation($input: DeleteInput!) {
        deleteSuggestedTranslation(input: $input)
    }`;
export const useDeleteSuggestedTranslation = () => useMutation(DELETE_SUGGESTED_TRANSLATIONS);


export const CAN_ASSIGN_EMAIL_TO_ROLE = gql`
    query CanAssignEmailToRole($input: EmailRoleInput!) {
        canAssignEmailToRole(input: $input)
    }
`;
export const useCanAssignEmailToRoleLazy = () => useLazyQuery(CAN_ASSIGN_EMAIL_TO_ROLE);
