import React, {useEffect, useState} from 'react';
import {notHaveErrors, validateFields, validateForm} from '../../../../utils/form';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from '../../../../apollo/cashe';
import * as yup from 'yup';
import classes from './ScoreSkillsForm.module.scss';
import Select from '../../../../components/UI/Select/Select';
import DatePicker from '../../../../components/UI/DatePicker/DatePicker';
import {useSavePlayerSkill} from '../../../../apollo/queries/PlayerQueries';
import {dateAsTimestamp} from '../../../../utils/date';

const ScoreSkillsForm = (props) => {
    const {skills, setLoading} = props;

    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const [values, setValues] = useState(skills.map(x => x.skillId).reduce((a, v) => ({ ...a, [`skill-value-${v}`]: 5}), {}));
    const [errors, setErrors] = useState({});

    const [savePlayerSkill] = useSavePlayerSkill();

    const fieldChanged = (event) => {
        const newValues = { ...values };
        newValues[event.target.name] = event.target.value;

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });

        setValues(newValues);
    }

    const validationRules = {
        date: yup.string().required(t("required")),
    }

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors
    };

    const formSubmitted = (e) => {
        e.preventDefault();
        setLoading(true);
        validateForm(values, validationRules, (errors) => {
            if (notHaveErrors(errors))
                saveData();
            else
                setErrors(errors);
            setLoading(false);
        });
    };

    const saveData = () => {
        const variables = {
            input: {
                teamId: navState.teamId,
                playerId: navState.playerId,
                dateVal: dateAsTimestamp(values.date),
                skills: skills.map(x => ({skillId: x.skillId, value: values[`skill-value-${x.skillId}`]}))
            }
        }

        savePlayerSkill({variables: variables}).then(result => {
            props.onComplete();
        });
    };


    return (
        <>
            <form id={props.id} onSubmit={formSubmitted}>
                <DatePicker
                    name="date"
                    onChange={() => {}}
                    label={t('Date')}
                    {...regField}
                />

                <br />

                {skills.map(x => (
                    <div key={`score-skill-${x.skillId}`} className={classes.row}>
                        <span>{x.isSystem ? t(x.name) : x.name}</span>
                        <div className={classes.rating}>
                            <Select
                                name={`skill-value-${x.skillId}`}
                                options={props.rating}
                                {...regField}
                                />
                        </div>
                    </div>
                ))}
            </form>
        </>
    );
};

ScoreSkillsForm.defaultProps = {
    rating: [
        {label: '1 / 10', value: 1},
        {label: '2 / 10', value: 2},
        {label: '3 / 10', value: 3},
        {label: '4 / 10', value: 4},
        {label: '5 / 10', value: 5},
        {label: '6 / 10', value: 6},
        {label: '7 / 10', value: 7},
        {label: '8 / 10', value: 8},
        {label: '9 / 10', value: 9},
        {label: '10 / 10', value: 10}
    ],
};

export default ScoreSkillsForm;
