import React, {useEffect, useState} from 'react';
import classes from './StaffForm.module.scss';
import Input from 'components/UI/Input/Input';
import DatePicker from 'components/UI/DatePicker/DatePicker';
import Select from 'components/UI/Select/Select';
import {Countries} from 'containers/Team/MatchesDetails/Settings';
import ImageUpload from 'components/UI/ImageUpload/ImageUpload';
import Submenu from 'components/Navigation/Submenu/Submenu';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import {notHaveErrors, validateForm} from 'utils/form';
import Checkbox from 'components/UI/Checkbox/Checkbox';
import * as yup from 'yup';
import {createGuid} from 'utils/utils';
import {useSaveTrainer} from 'apollo/queries/TrainerQueries';
import {fileUploader} from 'utils/upload';
import {useAuth} from 'react-oidc-context';
import {useCanAssignEmailToRoleLazy} from 'apollo/queries/SettingQueries';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';

const StaffForm = (props) => {
	const {pages, setModal, defaultValues, setLoading} = props;

	const {t} = useTranslation();
	const auth = useAuth();
	const navState = useReactiveVar(naviDataVar);

	const [errors, setErrors] = useState({});
	const [values, setValues] = useState(defaultValues);
	const [image, setImage] = useState(null);

	const [saveTrainer] = useSaveTrainer();
	const [canAssignEmailToRoleLazy] = useCanAssignEmailToRoleLazy();

	const fieldChanged = (event) => {
		const newValues = {...values};
		newValues[event.target.name] = event.target.value;

		if (event.target.file !== undefined) {
			newValues[event.target.name + 'File'] = event.target.file;
		}

		if (event.target.name === 'removeAccess' && event.target.value)
			newValues['sendInvite'] = false;

		if (event.target.name === 'sendInvite' && event.target.value)
			newValues['removeAccess'] = false;

		setValues(newValues);
	};

	const regField = {
		value: values,
		onChange: fieldChanged,
		error: errors
	};

	const onImageChanged = (e) => {
		setImage(e);
	};

	useEffect(() => {
		if (!image) return;
		fieldChanged(image);
	}, [image]);

	const PageOne = () => (
		<div className={classes.columns21}>
			<div>
				<div className={classes.columns11}>
					<Input
						name="firstName"
						label={t('First name')}
						{...regField}
					/>

					<Input
						name="lastName"
						label={t('Last name')}
						{...regField}
					/>
				</div>
				<div className={classes.columns11}>
					<Input
						name="middleName"
						label={t('Middle name')}
						{...regField}
					/>

					<DatePicker
						name="dateOfBirth"
						label={t('Birth date')}
						{...regField}
					/>
				</div>

				<div className={classes.title}>{t('Career')}</div>

				<div className={classes.columns11}>
					<Input
						name="position"
						label={t('Position')}
						{...regField}
					/>

					<DatePicker
						name="careerStartDate"
						label={t('Career Start Date')}
						{...regField}
					/>

				</div>

				<div className={classes.columns11}>
					<Input
						name="certification"
						label={t('License')}
						{...regField}
					/>

					<DatePicker
						name="certificationEndDate"
						label={t('License end date')}
						{...regField}
					/>

				</div>

				<div className={classes.title}>{t('Contacts')}</div>

				<div className={classes.columns11}>
					<Input
						name="phoneNumber"
						type="number"
						label={t('Phone')}
						{...regField}
					/>

					<Input
						name="email"
						label={t('E-mail')}
						{...regField}
					/>
				</div>

				<Input
					name="addressLine"
					label={t('Address')}
					{...regField}
				/>

			</div>
			<div>
				<ImageUpload
					name="photo"
					label={t('Coach photo')}
					text="Add coach photo"
					{...regField}
					onChange={onImageChanged}
				/>
			</div>
		</div>
	);

	const PageTwo = () => (
		<div>
			<div className={classes.columns111}>

				<Input
					name="passportNumber"
					label={t('Passport number')}
					{...regField}
				/>

				<DatePicker
					name="passportDateOfIssue"
					label={t('Passport date of issue')}
					{...regField}
				/>

				<DatePicker
					name="passportDateOfExpiry"
					label={t('Passport date of expiry')}
					{...regField}
				/>

			</div>

			<div className={classes.columns111}>

				<Select
					name="countryOfBirth"
					options={Countries()}
					label={t('Country of birth')}
					{...regField}
				/>

				<Input
					name="cityOfBirth"
					label={t('City of birth')}
					{...regField}
				/>

				<Select
					name="citizenship"
					label={t('Citizenship')}
					options={Countries()}
					{...regField} />

			</div>

			<div className={classes.columns111}>

				<Input
					name="personalCode"
					label={t('Personal Code')}
					{...regField}
				/>

			</div>

			{values.isTrainer && <>
				<div className={classes.title}>{t('Social media')}</div>

				<div className={classes.columns12}>
					<Input
						name="linkName0"
						label={t('Website name')}
						{...regField}
					/>

					<Input
						name="linkUrl0"
						label={t('Link')}
						{...regField}
					/>
				</div>
				<div className={classes.columns12}>
					<Input
						name="linkName1"
						label={t('Social network')}
						{...regField}
					/>

					<Input
						name="linkUrl1"
						label={t('Link')}
						{...regField}
					/>

				</div>

				<div className={classes.columns12}>
					<Input
						name="linkName2"
						label={t('Video chanel')}
						{...regField}
					/>

					<Input
						name="linkUrl2"
						label={t('Link')}
						{...regField}
					/>
				</div>

				<div className={classes.columns12}>
					<Input
						name="linkName3"
						label={t('Photo album')}
						{...regField}
					/>

					<Input
						name="linkUrl3"
						label={t('Link')}
						{...regField}
					/>
				</div>
			</>}
		</div>
	);

	const PageThree = () => (
		<div className={classes.parents}>

			<div className={classes.title}>{t('Club roles')}</div>
			<Checkbox
				name="isAdministrator"
				label={t('Club administrator')}
				disabled={values.isAdministratorDisabled ?? false}
				{...regField}
			/>

			<Checkbox
				name="isTrainer"
				label={t('Trainer')}
				disabled={values.isTrainerDisabled ?? false}
				{...regField}
			/>

			<Checkbox
				name="isMedic"
				label={t('Medic')}
				disabled={values.isMedicDisabled ?? false}
				{...regField}
			/>

			<Checkbox
				name="isVideoOperator"
				label={t('Video operator')}
				disabled={values.isVideoDisabled ?? false}
				{...regField}
			/>

			<Checkbox
				name="isScout"
				label={t('Scout')}
				disabled={values.isScoutDisabled ?? false}
				{...regField}
			/>

			<br/>

			<div className={classes.title}>{t('Club access')}</div>


			{!values.contactStatus
				? <Checkbox
					name="sendInvite"
					label={t('Send invite to user')}
					{...regField}
				/>
				: <>
					<Checkbox
						name="sendInvite"
						label={t('Re-send invite to user')}
						{...regField}
					/>

					<Checkbox
						name="removeAccess"
						label={t('Remove user access')}
						{...regField}
					/>
				</>
			}

			<br/>
		</div>
	);

	const pageOneValidationRules = {
		firstName: yup.string().required(t('required')),
		lastName: yup.string().required(t('required')),

		email: yup.string().when(['sendInvite', 'access'], {
			is: (sendInvite, access) => sendInvite || access,
			then: yup.string().required('required')
		}),
	};

	const pageTwoValidationRules = {};

	const formSubmitted = async (e) => {
		e.preventDefault();
		setLoading(true);

		new Promise(function (resolve, reject) {
        //Page 1
        validateForm(values, pageOneValidationRules, async (errors) => {

					if (notHaveErrors(errors) === false) {
						reject({page: 1, errors});
					}

					const variables = {
						input: {
							email: values.email,
							clubId: navState.clubId,
							roleIds: []
						}
					}

					if (values.isAdministrator) {
						variables.input.roleIds.push(SystemRoles.ClubAdmin);
					}

	        if (values.isTrainer) {
		        variables.input.roleIds.push(SystemRoles.Coach);
	        }

					const canAssign = await canAssignEmailToRoleLazy({variables});
					if (canAssign.data.canAssignEmailToRole === false) {
						errors.email = t('Email already assigned to this role. Check staff and archive.');
						reject({page: 1, errors});
					}

	        resolve(1);
        });
      })
			.then(function (result) {
				//Page 2
				return new Promise(function (resolve, reject) {
					validateForm(values, pageTwoValidationRules, (errors) => {
						notHaveErrors(errors) ? resolve(2) : reject({page: 2, errors});
					});
				});
			})
			.then(function (result) {
				//Page 3
				return new Promise((resolve, reject) => {
					resolve(1);
				});
			})
			.then(function (result) {
				saveData();
			})
			.catch(e => {
				setErrors(e.errors);
				onPageChanged(e.page);
				setLoading(false);
			});
	};

	const saveData = async () => {
		const variables = {
			input: {
				trainerId: values.trainerId ?? createGuid(),
				teamId: navState.teamId,
				clubId: navState.clubId,
				firstName: values.firstName,
				lastName: values.lastName,
				middleName: values.middleName,
				dateOfBirth: values.dateOfBirth,

				position: values.position,
				careerStartDate: values.careerStartDate,
				certification: values.certification,
				certificationEndDate: values.certificationEndDate,

				phoneNumber: values.phoneNumber,
				email: values.email,
				addressLine: values.addressLine,

				passportNumber: values.passportNumber,
				passportDateOfIssue: values.passportDateOfIssue,
				passportDateOfExpiry: values.passportDateOfExpiry,
				countryOfBirth: values.countryOfBirth,
				cityOfBirth: values.cityOfBirth,
				citizenship: values.citizenship,
				personalCode: values.personalCode,

				links: [],

				isAdministrator: values.isAdministrator,
				isTrainer: values.isTrainer,
				isMedic: values.isMedic,
				isVideoOperator: values.isVideoOperator,
				isScout: values.isScout,

				sendInvite: values.sendInvite,
				removeAccess: values.removeAccess
			}
		};

		if (values.linkName0 && values.linkUrl0)
			variables.input.links.push({type: 'WEBSITE', name: values.linkName0, url: values.linkUrl0});
		if (values.linkName1 && values.linkUrl1)
			variables.input.links.push({type: 'SOCIAL_LINK', name: values.linkName1, url: values.linkUrl1});
		if (values.linkName2 && values.linkUrl2)
			variables.input.links.push({type: 'VIDEO_CHANEL', name: values.linkName2, url: values.linkUrl2});
		if (values.linkName3 && values.linkUrl3)
			variables.input.links.push({type: 'PHOTO_ALBUM', name: values.linkName3, url: values.linkUrl3});


		if (values.photoFile !== undefined) {

			const fileData = {
				clubId: navState.clubId,
				fileId: variables.input.trainerId
			};

			if (await fileUploader(values.photoFile, fileData, auth)) {
				variables.input.photoUploadDate = new Date().toISOString();
			}
		}

		await saveTrainer({variables: variables});
		props.onComplete();
	};

	const onPageChanged = (newPage) => {
		const page = newPage - 1;
		setModal({...props, pages: {...props.pages, page: page}});
	};

	return (
		<>
			<Submenu default={props.pages.page + 1} data={props.pageNames} onFilter={onPageChanged}
			         className={classes.submenu}/>

			<form id={props.id} onSubmit={formSubmitted}>
				{pages.page === 0 && PageOne()}
				{pages.page === 1 && PageTwo()}
				{pages.page === 2 && PageThree()}
			</form>
		</>
	);
};

StaffForm.defaultProps = {
	pageNames: {
		1: 'General info',
		2: 'Additional',
		3: 'Access',
	},
	gender: [
		{label: 'None', value: 'NONE'},
		{label: 'Men', value: 'MALE'},
		{label: 'Women', value: 'FEMALE'},
	],
	foot: [
		{label: 'None', value: 'NONE'},
		{label: 'Left', value: 'LEFT'},
		{label: 'Right', value: 'RIGHT'},
	],
	files: [],
	status: [
		{label: 'None', value: 'NONE'},
		{label: 'Player', value: 'PLAYER'},
		{label: 'Candidate', value: 'CANDIDATE'},
	],
	onPageChange: () => {
	},
	linkTypes: [
		{
			type: 'WEBSITE',
			name: 'Website name',
			url: 'Link'
		}, {
			type: 'SOCIAL_LINK',
			name: 'Social network',
			url: 'Link'
		}, {
			type: 'VIDEO_CHANEL',
			name: 'Video chanel',
			url: 'Link'
		}, {
			type: 'PHOTO_ALBUM',
			name: 'Photo album',
			url: 'Link'
		}
	]
};

export default StaffForm;
