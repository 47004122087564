import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import {notHaveErrors, validateForm} from 'utils/form';
import Input from 'components/UI/Input/Input';
import Select from 'components/UI/Select/Select';
import {Groups, PlayerPositions} from 'containers/Team/MatchesDetails/Settings';
import classes from './TeamPlayerForm.module.scss';
import DatePicker from 'components/UI/DatePicker/DatePicker';
import {useSaveTeamPlayer} from 'apollo/queries/TeamQueries';
import {createGuid} from 'utils/utils';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';

const TeamPlayerForm = (props) => {
    const {defaultValues, setLoading, onComplete} = props;

    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const [values, setValues] = useState(defaultValues ?? {});
    const [errors, setErrors] = useState({});

    const [saveTeamPlayer] = useSaveTeamPlayer();

    const fieldChanged = (event) => {
        const newValues = { ...values };
        newValues[event.target.name] = event.target.value;

        if (event.target.file !== undefined) {
            newValues[event.target.name+"File"] = event.target.file;
        }

        setValues(newValues);
    }

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors
    };

    const validationRules = {
        teamNumber: yup.string().required(t("required")),
        position: yup.string().required(t("required")),
        group: yup.string().required(t("required")),
        startDate: yup.string().required(t("required")),
    }

    const formSubmitted = (e) => {
        e.preventDefault();
        setLoading(true);
        validateForm(values, validationRules, (errors) => {
            if (notHaveErrors(errors)) {
                saveData();
            } else {
                setLoading(false);
                setErrors(errors);
            }
        });
    };

    const saveData = () => {
        const variables = {
            input: {
                teamMemberId: values.teamMemberId ?? createGuid(),
                teamId: navState.teamId,
                teamNumber: +values.teamNumber,
                position: values.position,
                group: values.group,
                startDate: values.startDate,
                endDate: values.endDate,
            }
        }

        saveTeamPlayer({variables: variables}).then(result => {
            onComplete();
        });
    };

    console.log(defaultValues);

    return (
        <>
            <form id={props.id} onSubmit={formSubmitted}>
                <div className={classes.columns21}>
                    <div>
                        <div className={classes.columns11}>
                            <Input
                                name="firstName"
                                label={t('First name')}
                                {...regField}
                                disabled={true}
                            />

                            <Input
                                name="lastName"
                                label={t('Last name')}
                                {...regField}
                                disabled={true}
                            />
                        </div>

                        <br />

                        <div className={classes.columns11}>
                            <Input
                                name="teamNumber"
                                type="number"
                                label={t('Team number')}
                                {...regField}
                            />

                            <Select
                                name="group"
                                label={t('Group')}
                                options={Groups()}
                                {...regField} />

                        </div>
                        <div>
                            <Select
                                name="position"
                                label={t('Position')}
                                options={PlayerPositions()}
                                {...regField} />
                        </div>
                        <div className={classes.columns11}>
                            <DatePicker
                                name="startDate"
                                label={t('Join team')}
                                {...regField}
                            />
                            <DatePicker
                                name="endDate"
                                label={t('Leave team')}
                                {...regField}
                            />
                        </div>
                    </div>
                    <div className={classes.photo}>
                        <label>{t("Photo")}</label>
                        <img src={values.photo ?? "/assets/images/no-photo.jpg"} alt="Player photo" />
                    </div>
                </div>
            </form>
        </>
    );
};

TeamPlayerForm.defaultProps = {};

export default TeamPlayerForm;
