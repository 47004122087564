import React, {useEffect} from 'react';
import PlayerHeader from '../Player/PlayerHeader';
import Submenu from '../../../components/Navigation/Submenu/Submenu';
import Panel from '../../../components/UI/Panel/Panel';
import Button from '../../../components/UI/Button/Button';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from '../../../apollo/cashe';
import {usePlayerCareer} from '../../../apollo/queries/PlayerQueries';
import Table from '../../../components/UI/Table/Table';
import DateTime from '../../../components/UI/Table/DateTime/DateTime';
import Subtext from '../../../components/UI/Table/Subtext/Subtext';
import CanAccess from '../../../components/Navigation/CanAccess/CanAccess';

const PlayerCareer = (props) => {

    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const [getPlayerCareer, playerCareerData] = usePlayerCareer({playerId: navState.playerId});

    useEffect(() => {
        getPlayerCareer({variables: {playerId: navState.playerId}})
    }, [navState.playerId]);

    const actions = () => (
        <>
            <Button type="primary" style={{margin: 0}} onClick={onAddCareer}>
                <span><i className="icon-Plus-Square"></i> {t("Add record")}</span>
            </Button>
        </>
    );

    const onAddCareer = () => {

    }

    const onRowClick = () => {

    }

    return (
        <>
            <div className="page-header">
                <PlayerHeader />
                <Submenu/>
            </div>
            <div className="page-content">
                <CanAccess users={[5,9,15]}>
                    <Panel border={false} action={actions}>
                        <br />
                    </Panel>
                </CanAccess>

                {playerCareerData.loading && <>Loading...</>}
                {playerCareerData.error && <>Error...</>}
                {playerCareerData.data && <Table
                    data={playerCareerData.data.playerCareer}
                    center="true"
                    columns={[
                        {
                            Header: t('Club'),
                            accessor: 'clubName',
                            Cell: cellData => <div>
                                <div>{cellData.value}</div>
                                <Subtext>&nbsp;</Subtext>
                            </div>
                        },
                        {
                            Header: t('Team'),
                            accessor: 'teamName',
                            Cell: cellData => <div>
                                <div>{cellData.value}</div>
                                <Subtext>{t("Coaches")}: {cellData.row.original.trainers.join(', ') ?? " -"}</Subtext>
                            </div>
                        },
                        {
                            Header: t('Dates'),
                            accessor: 'dateStart',
                            Cell: cellData => <div>
                                <DateTime data={cellData.value} showTime={false} />
                                <Subtext><DateTime data={cellData.row.original.endDate} showTime={false} /></Subtext>
                            </div>
                        }
                    ]}
                    onRowClick={onRowClick} />}
            </div>
        </>
    );
};

PlayerCareer.defaultProps = {};

export default PlayerCareer;
