import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import {notHaveErrors, validateFields, validateForm} from 'utils/form';
import * as yup from 'yup';
import {dateAsTimestamp} from 'utils/date';
import DatePicker from 'components/UI/DatePicker/DatePicker';
import Input from 'components/UI/Input/Input';
import Textarea from 'components/UI/Textarea/Textarea';
import Select from 'components/UI/Select/Select';
import {useSavePlayerHealthCheck} from 'apollo/queries/PlayerQueries';
import {newId} from 'utils/utils';

const HealthCheckForm = (props) => {
    const {defaultValues, onComplete} = props;

    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const [values, setValues] = useState({
        ...defaultValues, date: new Date(), isHealthCheckPassed: true
    });
    const [errors, setErrors] = useState({});

    const [savePlayerHealthCheck] = useSavePlayerHealthCheck();

    const fieldChanged = (event) => {
        const newValues = { ...values };
        newValues[event.target.name] = event.target.value

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });

        setValues(newValues);
    }

    const formSubmitted = (e) => {
        e.preventDefault();
        validateForm(values, validationRules, (errors) => {
            if (notHaveErrors(errors))
                saveData();
            else
                setErrors(errors);
        });
    }

    const validationRules = {
        healthCheckDate: yup.string().required(t("required")),
        isHealthCheckPassed: yup.bool().required(t("required")),
        //healthCheckNextDate: yup.string().when("isHealthCheckPassed", {is: true, then: yup.string().required(t("required"))})
    }

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors
    };

    const saveData = () => {
        const variables = {
            input: {
                playerHealthCheckId: values.playerHealthCheckId ?? "00000000-0000-0000-0000-000000000000",
                clubId: navState.clubId,
                playerId: defaultValues.playerId,
                healthCheckDate: values.healthCheckDate,
                isHealthCheckPassed: values.isHealthCheckPassed,
                healthCheckNextDate: values.healthCheckNextDate,
                comment: values.comment
            }
        }

        savePlayerHealthCheck({variables: variables}).then(result => {
            onComplete();
        });
    }

    return (
        <form id={props.id} onSubmit={formSubmitted}>
            <Input
              name="fullName"
              label={t('Player')}
              {...regField}
              disabled={true}
            />

            <DatePicker
                name="healthCheckDate"
                label={t('Date')}
                {...regField}
            />

            <Select
                name="isHealthCheckPassed"
                label={t('Status')}
                options={props.status}
                {...regField} />

            {values.isHealthCheckPassed === true && (values.playerHealthCheckId === undefined || values.healthCheckNextDate !== null) ?
                <DatePicker
                    name="healthCheckNextDate"
                    label={t('Next check date')}
                    {...regField}
                /> : undefined}

            <Textarea
                name="comment"
                label={t('Comment')}
                {...regField}
            />

        </form>
    );
};

HealthCheckForm.defaultProps = {
    status: [
        {value: true, label: 'Passed'},
        {value: false, label: 'Not Passed'},
    ]
};

export default HealthCheckForm;
