import React, {useEffect, useState} from 'react';
import HeaderWithSettings from 'components/UI/HeaderWithSettings/HederWithSettings';
import Panel from 'components/UI/Panel/Panel';
import VideoList from '../MatchesDetails/VideoList';
import Tabs from 'components/UI/Tabs/Tabs';
import Select from 'components/UI/Select/Select';
import Search from 'components/UI/Panel/Search/Search';
import {naviDataVar} from 'apollo/cashe';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {useHighlightsLazy} from 'apollo/queries/GameQueries';
import MatchesVideoMatrix from './MatchesVideoMatrix';

const MatchesVideoHighlights = (props) => {

    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const [tab, setTab] = useState("list");
    const [filters, setFilters] = useState(props.filters);
    const [players, setPlayers] = useState([{label: t("All"), value: "" }]);
    const [dataEvents, setDataEvents] = useState([{label: t("All"), value: "" }]);
    const [skip, setSkip] = useState(0);

    const [getHighlightsLazy, highlightsData]  = useHighlightsLazy();


    useEffect(() => {
        if (!navState.clubId || !navState.teamId) {
            return;
        }

        const variables = {
            clubId: navState.clubId,
            teamId: navState.teamId,
            type: "GAME",
            where: {
                title: {contains: filters.search}
            },
            skip: skip * 20,
        }

        if (filters.playerId) {
            variables.where.playerId = {eq: filters.playerId}
        }

        if (props.userMode) {
            variables.where.playerId = {eq: navState.playerId}
        }

        if (filters.gameDataEventId) {
            variables.where.gameDataEventId = {eq: filters.gameDataEventId}
        }

        getHighlightsLazy({variables});
    }, [navState, filters, skip]);


    useEffect(() => {
        if (!highlightsData.data) {
            return;
        }

        const _players = highlightsData.data.players.items.map(x => ({label: x.fullName, value: x.playerId }));
        setPlayers([{label: t("All"), value: "" }, ..._players]);

        const _events = highlightsData.data.dataEvents.map(x => ({label: x.name, value: x.id }));
        setDataEvents([{label: t("All"), value: "" }, ..._events]);

    }, [highlightsData.data]);

    useEffect(() => {

    }, [highlightsData.data]);

    const onFilter = (e) => {
        const newFilters = {...filters};
        newFilters[e.target.name] = e.target.value;
        setFilters(newFilters);
    }

    const highlightActions = () => (
        <Tabs items={props.tabs} selected={tab} onClick={(x) => setTab(x)} />
    );

    const highlightFilters = () => (
        <>
            {tab === "list" ? <>

                {props.userMode === false
                    ? <Select
                        small="small"
                        name="playerId"
                        value={filters}
                        onChange={onFilter}
                        options={players}
                        placeholder={t("Players")} />
                    : undefined}

            <Select
                small="small"
                name="gameDataEventId"
                value={filters}
                onChange={onFilter}
                options={dataEvents}
                placeholder={t("Highlights")} />
            </> : undefined}
        </>
    );

    const highlightSearch = () => (
        <>{tab === "list" ? <Search onSearch={onHighlightSearch} placeholder={t("Search highlight...")} /> : undefined}</>
    );

    const onHighlightSearch = (e) => {
        setSkip(0);
        onFilter({
            target: {
                name: "search",
                value: e.target.value
            }
        });
    }

    const onSettings = () => {
        naviDataVar({
            ...navState,
            settings: {
                open: true,
                chapter: 'video'
            }
        });
    }

    return (
        <>
            <HeaderWithSettings title={t("Highlights")} onSettings={onSettings} />

            <Panel border={false} action={highlightActions} filters={highlightFilters} search={highlightSearch}>
                <br/>
            </Panel>

            {tab === "list" ? <>
                {highlightsData.loading ? <>Loading</> : undefined}
                {highlightsData.error ? <>Error</> : undefined}
                {highlightsData.data ?  <VideoList data={highlightsData.data.highlights} onPage={20} skip={skip} setSkip={setSkip} /> : undefined}
                </> : undefined}

            {tab === "matrix" ? <MatchesVideoMatrix /> : undefined}
        </>
    );
};

MatchesVideoHighlights.defaultProps = {
    userMode: false,
    tabs: [
        {id: "list", name: "List"},
        {id: "matrix", name: "Matrix"},
    ],
    filters: {
        search: "",
        playerId: "",
        gameDataEventId: "",
    }
};

export default MatchesVideoHighlights;
