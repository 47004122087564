import React from 'react';
import Select from './Select';
import classes from './Select.module.scss';

const SelectWithAdd = (props) => {
    return (
        <div className={classes.selectWithAdd}>
            <Select {...props} />
            <button className={classes.addButton} onClick={props.onAddClick}><i className="icon-Plus-Square"></i></button>
        </div>
    );
};

SelectWithAdd.defaultProps = {
    onAddClick: () => {}
};

export default SelectWithAdd;