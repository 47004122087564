import React, {useEffect, useState} from 'react';
import classes from './Submenu.module.scss'
import {useReactiveVar} from '@apollo/client';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';
import {getBaseUrl} from '../../../utils/history';
import {naviDataVar, systemDataVar} from '../../../apollo/cashe';
import {NavLink} from 'react-router-dom';

const  Submenu = (props) => {
  const { t } = useTranslation();

  const systemData = useReactiveVar(systemDataVar);
  const navState = useReactiveVar(naviDataVar);

  const {selected} = navState;
  const [filter, setFilter] = useState();


  useEffect(() => {
    if (props.default)
      setFilter(""+props.default);

    if (!props.default && props.data)
     setFilter(Object.keys(props.data)[0]);
  },[props.default]);


  const onClick = (item) => {
    // document.getElementById("body-content").style.display = "none";
    //
    // if (item.type === 1 || (item.type === 0 && location.pathname.indexOf(item.path) >= 0)) {
    //   const newSate = {...navState};
    //   newSate.selected.submenu = {id: item.id};
    //   naviDataVar(newSate);
    // }
    //
    // if (item.type === 0) {
    //   window.location.href = getUrl(item, navState);
    // }
  }

  const onFilterClick = (key) => {
    setFilter(key);
    props.onFilter(key);
  };

  if (props.data) {
    return (
        <ul className={cn(classes.submenu, props.className)}>
          {Object.keys(props.data).map((key) => {
            const item = props.data[key];
            return (
              <li key={`user-submenu-${key}`}>
                <button onClick={() => onFilterClick(key)} className={cn({[classes.selected]: key === filter})}>
                  {t(item)}
                </button>
              </li>
          )})}
        </ul>
    )
  }


  return (
    <ul className={classes.submenu}>
      {selected && selected.items.filter(x => x.access.includes(systemData.user.userRole.roleId)).map((item) => {
        const {id, access, path, param, hash} = item;
        const base = getBaseUrl(path, param, hash);
        const url = base.replace("!CLUBID!", navState.clubId ?? "")
            .replace("!TEAMID!", navState.teamId ?? "")
            .replace("!PLAYERID!", navState.playerId ?? "");

        return (
            <li key={`submenu-${id}`}>
              <NavLink to={url} onClick={() => onClick(item)}>
                {t(item.name)}
              </NavLink>
            </li>
      )})}
    </ul>
  );
};

export default Submenu;
