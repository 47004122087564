import React from 'react';
import {useDeletePlayerHealthCheck, usePlayerHealthCheckHistory} from 'apollo/queries/PlayerQueries';
import classes from './HealthCheckView.module.scss';
import DateTime from 'components/UI/Table/DateTime/DateTime';
import Table from 'components/UI/Table/Table';
import {useTranslation} from 'react-i18next';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import HealthCheckForm from 'containers/Club/ClubMedicine/form/HealthCheckForm';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';

const HealthCheckView = (props) => {
	const {playerId, fullName} = props.defaultValues;

	const {t} = useTranslation();
	const navState = useReactiveVar(naviDataVar);

	const [modalData, setModalData, initState] = ModalState();
  const [deletePlayerHealthCheck] = useDeletePlayerHealthCheck();

	const {data, loading, error, refetch} = usePlayerHealthCheckHistory({
		playerId
	});

	const onRowClick = (row) => {

		const modalData = {
			...initState,
			form: 'healthCheck',
			title: t('Edit health check'),
			defaultValues: {
				playerId,
				fullName,
				playerHealthCheckId: row.playerHealthCheckId,
				healthCheckDate: row.healthCheckDate,
				isHealthCheckPassed: row.isHealthCheckPassed,
				healthCheckNextDate: row.healthCheckNextDate,
				comment: row.comment,
			},
			onComplete: onComplete,
      onDelete: () => onDelete(row.playerHealthCheckId),
			open: true,
		};

		setModalData(modalData);
	};

	const onComplete = () => {
		setModalData(initState);
		refetch().then();
	};

	const onDelete = (playerHealthCheckId) => {
		const variables = {
			input: {
				id: playerHealthCheckId,
				playerId,
				clubId: navState.clubId
			}
		};

    deletePlayerHealthCheck({variables}).then(() => {
			setModalData(initState);
			refetch().then();
		});
	};

	if (loading) return (<div>Loading...</div>);
	if (error) return (<div>Error...</div>);

	return (
		<>
			<Table
				data={data.playerHealthCheckHistory}
				columns={[
					{
						Header: t('Date'),
						accessor: 'healthCheckDate',
						Cell: (cellData) => {
							return (
								<DateTime data={cellData.value} showTime={false}/>
							);
						}
					},
					{
						Header: 'Result',
						accessor: 'isHealthCheckPassed',
						Cell: (cellData) => {
							return (
								cellData.value ? t('Passed') : t('Failed')
							);
						}
					},
					{
						Header: t('Comment'),
						className: classes.hide,
						accessor: 'comment',
					},
				]}
				onRowClick={onRowClick}/>

			<ModalWindow2 {...modalData}>
				<HealthCheckForm
					{...modalData}
					name="healthCheck"
				/>
			</ModalWindow2>
		</>
	);
};

HealthCheckView.defaultProps = {};

export default HealthCheckView;
