import React, {useState} from 'react';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from '../../../../apollo/cashe';
import {useUpdateGamePlayer} from '../../../../apollo/queries/GameQueries';
import {notHaveErrors, validateFields, validateForm} from '../../../../utils/form';
import * as yup from 'yup';
import {useTranslation} from 'react-i18next';
import TimePicker3 from '../../../../components/UI/DatePicker/TimePicker3';

const MinutesForm = (props) => {
  const {t} = useTranslation();
  const navState = useReactiveVar(naviDataVar);

  const [values, setValues] = useState(props.values);
  const [errors, setErrors] = useState({});

  const [updateGamePlayer] = useUpdateGamePlayer();

  const fieldChanged = (event) => {
    const newValues = { ...values };
    newValues[event.target.name] = event.target.value

    validateFields(newValues, validationRules, (errors) => {
      setErrors(errors);
    });
    setValues(newValues);
  }

  const validationRules = {
    playTime: yup.string().required(t("Require"))
  }

  const regField = {
    value: values,
    onChange: fieldChanged,
    error: errors
  };

  const formSubmitted = (e) => {
    e.preventDefault();
    validateForm(values, validationRules, (errors) => {
      if (notHaveErrors(errors))
        saveData();
      else
        setErrors(errors);
    });
  }

  const saveData = () => {
    const variables = {
      input: {
        clubId: navState.clubId,
        teamId: navState.teamId,
        gameId: props.gameId,
        playerId: props.playerId,
        playTime: +values.playTime,
      }
    }

    updateGamePlayer({variables: variables}).then(result => {
      props.onComplete();
    });
  }

  return (
      <>
        <form id={props.id} onSubmit={formSubmitted}>

          <TimePicker3
              name="playTime"
              label={t('Minutes')}
              isSportTimer={true}
              {...regField}
          />

        </form>
      </>
  );
};

MinutesForm.defaultProps = {
  values: {},
  onComplete: () => {},
};

export default MinutesForm;
