import React, {useState} from 'react';
import Input from '../../../../components/UI/Input/Input';
import Select from '../../../../components/UI/Select/Select';
import Switch from '../../../../components/UI/Switch/Switch';
import {notHaveErrors, validateForm} from '../../../../utils/form';
import * as yup from 'yup';
import {useTranslation} from 'react-i18next';
import classes from './ParentForm.module.scss';
import {createGuid} from '../../../../utils/utils';
import {useSaveParent} from '../../../../apollo/queries/ParentQueries';

const ParentForm = (props) => {
    const {pages, defaultValues, setModal, setLoading, onComplete, relation} = props;

    const {t} = useTranslation();

    const [errors, setErrors] = useState({});
    const [values, setValues] = useState(props.defaultValues ?? {});

    const [saveParent] = useSaveParent();

    const fieldChanged = (event) => {
        const newValues = { ...values };
        newValues[event.target.name] = event.target.value;

        if (event.target.file !== undefined) {
            newValues[event.target.name+"File"] = event.target.file;
        }

        setValues(newValues);
    }

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors
    };

    const validationRules = {
        firstName: yup.string().required(t("required")),
        lastName: yup.string().required(t("required")),
        relationType: yup.string().required(t("required")),
    }

    const formSubmitted = (e) => {
        e.preventDefault();
        setLoading(true);
        validateForm(values, validationRules, (errors) => {
            if (notHaveErrors(errors)) {
                saveData();
            } else {
                setLoading(false);
                setErrors(errors);
            }
        });
    };

    const saveData = () => {
        // const variables = {
        //     input: {
        //         id: values.Id ?? createGuid(),
        //         playerId: createGuid(),
        //         relationType: values.relationType,
        //         firstName: values.firstName,
        //         lastName: values.lastName,
        //         email: values.email,
        //         phoneNumber: values.phoneNumber,
        //         isVideoOperator: values.isVideoOperator
        //     }
        // }
        //
        // saveParent({variables: variables}).then(result => {
        //     props.onComplete();
        // });

        const parent = {
            id: values.Id ?? createGuid(),
            relationType: values.relationType,
            firstName: values.firstName,
            lastName: values.lastName,
            fullName: `${values.firstName} ${values.lastName}`,
            email: values.email,
            phoneNumber: values.phoneNumber,
            isVideoOperator: values.isVideoOperator
        }

        props.onComplete(parent);
    };

    return (
        <>
            <form id={props.id} onSubmit={formSubmitted}>
                <div>
                    <Select
                        name="relationType"
                        label={t('Relation type')}
                        options={relation}
                        {...regField} />

                    <div className={classes.columns11}>
                        <Input
                            name="firstName"
                            label={t('First name')}
                            {...regField}
                        />

                        <Input
                            name="lastName"
                            label={t('Last name')}
                            {...regField}
                        />
                    </div>

                    <div className={classes.columns11}>
                        <Input
                            name="email"
                            label={t('E-mail')}
                            {...regField}
                        />

                        <Input
                            name="phoneNumber"
                            type="number"
                            label={t('Phone')}
                            {...regField}
                        />
                    </div>

                    <Switch
                        fullSize={true}
                        name="isVideoOperator"
                        label={t('Video operator')}
                        {...regField}
                    />

                </div>
            </form>
        </>
    );
};

ParentForm.defaultProps = {
    relation: [
        {label: "Guardian", value: "GUARDIAN"},
        {label: "Parent", value: "PARENT"},
        {label: "Mother", value: "MOTHER"},
        {label: "Father", value: "FATHER"},
        {label: "Grandmother", value: "GRANDMOTHER"},
        {label: "Grandfather", value: "GRANDFATHER"},
        {label: "Agent", value: "AGENT"},
        {label: "Girlfriend", value: "GIRLFRIEND"},
        {label: "Boyfriend", value: "BOYFRIEND"},
        {label: "Wife", value: "WIFE"},
        {label: "Husband", value: "HUSBAND"},
        {label: "Other", value: "OTHER"},
    ]
};

export default ParentForm;
