import React from 'react';
import classes from './ScrollList.module.scss';

const ScrollList = (props) => {
  return (
      <ul className={classes.list}>
        {props.children}
      </ul>
  );
};

ScrollList.defaultProps = {};

export default ScrollList;