import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import cn from 'classnames';
import classes from './MatchesVideo.module.scss';
import Submenu from 'components/Navigation/Submenu/Submenu';
import MatchesVideoList from './MatchesVideoList';
import MatchesVideoHighlights from './MatchesVideoHighlights';

const MatchesVideo = (props) => {
    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    return (
        <>
            <div className={cn("page-header", classes.matches)}>
                <div className={classes.name}>{navState.teamName}</div>
                <h2>{t('Matches videos')}</h2>
                <Submenu />
            </div>
            <div className="page-content">
                <MatchesVideoList />
                <br />
                <MatchesVideoHighlights />
            </div>
        </>
    );
};

MatchesVideo.defaultProps = {};

export default MatchesVideo;
