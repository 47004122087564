import React, {useEffect, useState} from 'react';
import Submenu from '../../../components/Navigation/Submenu/Submenu';
import Panel from '../../../components/UI/Panel/Panel';
import {useTranslation} from 'react-i18next';
import DropdownButton from '../../../components/UI/DropdownButton/DropdownButton';
import Search from '../../../components/UI/Panel/Search/Search';
import classes from './TeamCoaches.module.scss';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from '../../../apollo/cashe';
import cn from 'classnames';
import Subtext from '../../../components/UI/Table/Subtext/Subtext';
import Table from '../../../components/UI/Table/Table';
import Pagination from '../../../components/UI/Pagination/Pagination';
import DateTime from '../../../components/UI/Table/DateTime/DateTime';
import {
    useAssignTrainersToTeam,
    useDeleteTrainerTeamLink,
    useTeamCoaches
} from '../../../apollo/queries/TrainerQueries';
import StaffForm from '../../Club/Access/forms/StaffForm';
import SelectCoachForm from './forms/SelectCoachForm';
import SwitchComponents from '../../../components/System/SwitchComponents';
import TeamCoachForm from './forms/TeamCoachForm';
import ModalWindow2, {ModalState} from '../../../components/UI/ModalWindow/ModalWindow2';

const TeamCoaches = (props) => {
    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);
    const onPage = 100;

    const [skip, setSkip] = useState(0);
    const [search, setSearch] = useState('');
    const [coaches, setCoaches] = useState([]);

    const [modalData, setModalData, initState] = ModalState();

    const [assignTrainersToTeam] = useAssignTrainersToTeam();
    const [deleteTrainerTeamLink] = useDeleteTrainerTeamLink();
    const {data, loading, error, refetch}  = useTeamCoaches({
        clubId: navState.clubId,
        teamId: navState.teamId,
        search: search,
        take: onPage,
        skip: skip * onPage,
    });

    useEffect(() => {
        if (!data)
            return;

        const {items} = data.trainers;
        const table = [];

        items.forEach(x => {
            x.teams.forEach(t =>
                table.push({
                    trainerId: x.trainerId,
                    fullName: x.fullName,
                    email: x.email,
                    phone: x.phoneNumber,
                    teamTrainerId: t.teamTrainerId,
                    type: t.type,
                    startDate: t.startDate,
                    endDate: t.endDate,
                    canDelete: t.canDelete,
                })
            );
        })

        setCoaches(table);
    }, [data]);


    const resetModal = {
        open: false,
        id: `form-${Math.random()}`,
        page: 0,
        onComplete: () => onComplete(),
    };

    //const [modalData, setModalData] = useState(resetModal);

    const onSelect = () => {
        const existedTrainerIds = coaches.map(x => x.trainerId);

        const data = {
            ...initState,
            form: "select",
            title: t("Assign coach"),
            existedTrainerIds: existedTrainerIds,
            onAddCoaches: onAddCoaches,
            open: true
        }
        setModalData(data);
    }

    const onAddNew = () => {
        const data = {
            ...initState,
            form: "addNew",
            title: t("Add new coach"),
            pages: {
                page: 0,
                maxPages: 3,
            },
            styles: {
                maxWidth: "700px",
            },
            defaultValues: {
                teamId: navState.teamId,
                isTrainer: true,
                isTrainerDisabled: true
            },
            onComplete: onComplete,
            open: true
        }
        setModalData(data);
    }


    const onAddClick = (data) => {
        switch (data.value) {
            case "select":
                onSelect();
                break;
            case "addNew":
                onAddNew();
                break;
        }
    }

    const onAddCoaches = (data) => {
        setModalData(initState);

        if (data.trainersIds.length === 0)
            return;

        const variables = {
            input: {
                clubId: navState.clubId,
                teamId: navState.teamId,
                trainersIds: data.trainersIds,
                type: data.type
            }
        }

        assignTrainersToTeam({variables: variables}).then(result => {
            refetch();
        });
    }

    const onComplete = () => {
        setModalData(initState);
        refetch();
    }

    const actions = () => (
        <DropdownButton type="primary" options={props.options} onClick={onAddClick}>
            <span><i className="icon-Plus-Square"></i> {t('Assign coach')}</span>
        </DropdownButton>
    );

    const onSearch = (e) => {
        setSkip(0);
        setSearch(e.target.value);
    }

    const panelSearch = () => (
        <Search onSearch={onSearch} placeholder={t("Search coach...")} />
    );

    const comparer = (a, b) => !a.endDate ? -1 : 0;

    const onDeleteClick = (row) => {
        const variables = {
            input: {
                clubId: navState.clubId,
                teamId: navState.teamId,
                id: row.teamTrainerId,
            }
        };
        deleteTrainerTeamLink({variables: variables}).then(result => {
            refetch();
        });

        setModalData({...resetModal});
    }

    const onEdit = (row) => {
        const data = {
            ...initState,
            form: "edit",
            title: t("Edit coach"),
            defaultValues: {
                ...row,
                teamId: navState.teamId,
                teamName: navState.teamName,
            },
            onDeleteClick: row.canDelete ? () => onDeleteClick(row) : undefined,
            onComplete: onComplete,
            open: true
        }

        setModalData(data);
    }

    return (
        <>
            <div className={cn('page-header', classes.coaches)}>
                <div className={classes.name}>{navState.teamName}</div>
                <h2>{t('Coaches')}</h2>
                <Submenu />
            </div>
            <div className="page-content">
                <Panel border={false} action={actions} search={panelSearch}>
                    <br />
                </Panel>

                {loading && <>Loading...</>}
                {error && <>Error...</>}

                {data ?
                    <>
                        <Table
                            data={coaches.sort(comparer)}
                            center={true}
                            columns={[
                                {
                                    Header: t('Coach'),
                                    accessor: 'fullName',
                                    Cell: (cellData) => <>
                                        {cellData.value}
                                        <Subtext>
                                            <span className={classes.pointer} onClick={() => onEdit(cellData.row.original)} >
                                                {t(cellData.row.original.type)}
                                            </span>
                                        </Subtext>
                                    </>
                                },
                                {
                                    Header: t('Contacts'),
                                    accessor: 'email',
                                    Cell: (cellData) => {
                                        const email = cellData.value;
                                        const phone = cellData.row.original.phone;
                                        return (<>
                                            <a href={`mailto:${email}`}>{email}</a>
                                            <Subtext>
                                                {t("Phone")}: {phone ? <a href={`phone:${phone}`}>{phone}</a> : "-"}
                                            </Subtext>
                                        </>);
                                    }
                                },
                                {
                                    Header: t('Start date'),
                                    accessor: 'startDate',
                                    Cell: (cellData) =>
                                        <span className={classes.pointer} onClick={() => onEdit(cellData.row.original)} >
                                            <DateTime data={cellData.value} showTime={false} />
                                        </span>
                                },
                                {
                                    Header: t('End date'),
                                    accessor: 'endDate',
                                    Cell: (cellData) =>
                                        <span className={classes.pointer} onClick={() => onEdit(cellData.row.original)} >
                                            <DateTime data={cellData.value} showTime={false} />
                                        </span>
                                }
                            ]}
                            onRowClick={() => {}}/>

                        <Pagination page={skip} count={data.trainers.totalCount} onPage={onPage}
                                onPageChange={(p) => setSkip(p.selected)}/>
                    </>
                    :undefined
                }
            </div>

            <ModalWindow2 {...modalData}>
                <SwitchComponents active={modalData.form}>
                    <TeamCoachForm {...modalData}  name="edit" />
                    <SelectCoachForm {...modalData}  name="select" />
                    <StaffForm {...modalData} name="addNew" />
                </SwitchComponents>
            </ModalWindow2>

            {/*{modalData.open*/}
            {/*    ? <ModalWindow*/}
            {/*        maxWidth={modalData.maxWidth}*/}
            {/*        maxPages={modalData.maxPages}*/}
            {/*        onPageChange={onPageChange}*/}
            {/*        page={modalData.page}*/}
            {/*        title={modalData.title}*/}
            {/*        formSubmitId={modalData.id}*/}
            {/*        onDeleteClick={modalData.onDeleteClick}*/}
            {/*        onCloseClick={() => setModalData({...resetModal, open: false})}*/}
            {/*    >*/}
            {/*        <SwitchComponents active={modalData.mode}>*/}
            {/*            <SelectCoachForm {...modalData}  name="select" />*/}
            {/*            <StaffForm {...modalData} onPageChange={onPageChange} name="addNew" />*/}
            {/*        </SwitchComponents>*/}
            {/*    </ModalWindow>*/}
            {/*    : null*/}
            {/*}*/}
        </>
    );
};

TeamCoaches.defaultProps = {
    options: [
        {label: "Select from staff", value: "select"},
        {label: "Add new employer", value: "addNew"},
    ]
};

export default TeamCoaches;
