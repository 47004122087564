import React, {useState} from 'react';
import Table from 'components/UI/Table/Table';
import {useTranslation} from 'react-i18next';
import Pagination from 'components/UI/Pagination/Pagination';
import {toMSS} from 'utils/date';

const ListForm = (props) => {
    const {t} = useTranslation();
    const onPage = 5;

    const [skip, setSkip] = useState(0);

    console.log(props);

    const onRowClick = (row) => {
        const values = {
            id: row.id,
            minutes: toMSS(row.time)
        }

        props.onComplete({cellData: props.cellData, formData: props.formData, values: values});
    }

    return (
        <>
            <Table
                data={props.data.slice(skip * onPage, (skip + 1) * onPage)}
                columns={[
                    {
                        Header: t('Player'),
                        accessor: 'playerName',
                    },
                    {
                        Header: t('Time'),
                        accessor: 'time',
                        Cell: (cellData) => <>{toMSS(cellData.value)}</>
                    },
                    {
                        Header: t('Event'),
                        accessor: 'title',
                        Cell: (cellData) => <>{t(cellData.value)}</>
                    },
                ]}
                onRowClick={onRowClick}/>

            <Pagination page={skip} count={props.data.length} onPage={onPage} onPageChange={(p) => setSkip(p.selected)}/>
        </>
    );
};

ListForm.propTypes = {};
ListForm.defaultProps = {
    data: [],
    onComplete: () => {}
};

export default ListForm;
