import React, {useEffect, useState} from 'react';
import Panel from '../../../../components/UI/Panel/Panel';
import classes from '../Matches.module.scss';
import Input from '../../../../components/UI/Input/Input';
import Dropdown from '../../../../components/UI/Table/DropDown/Dropdown';
import {PlayerPositions} from '../../MatchesDetails/Settings';
import Button from '../../../../components/UI/Button/Button';
import {useTranslation} from 'react-i18next';
import ModalWindow2, {ModalState} from '../../../../components/UI/ModalWindow/ModalWindow2';
import AddPlayerForm from '../../TrainingsDetails/forms/AddPlayersForm';
import Table from '../../../../components/UI/Table/Table';
import {toastMessage} from '../../../../components/UI/Toast/Toast';

const MatchFormTwo = (props) => {
    const {data, mainTeam, setMainTeam, reserveTeam, setReserveTeam} = props;

    const {t} = useTranslation();

    const [selectedRows, setSelectedRows] = useState([]);

    const [modalData, setModalData, initState] = ModalState();

    useEffect(() => {
        if (!data)
            return;

        const playerList = data.players.items.map(x => ({playerId: x.playerId, playerName: x.fullName,
            teamNumber: x.teams[0].teamNumber, captain: false, position: x.teams[0].position}));

        setMainTeam(playerList.slice(0, 11));

        if (playerList.length > 11) {
            setReserveTeam(playerList.slice(11));
        }

    }, [data]);

    const playerActions = () => (
        <div className={classes.actions}>
            <Button type="primary" style={{margin: 0}} onClick={onAddPlayers}>
                <span><i className="icon-Plus-Square"></i> {t('Add player')}</span>
            </Button>

            {selectedRows.length > 0 && <Button type="secondary" style={{margin: "0 0 0 10px"}} onClick={onDeletePlayers}>
                <span>{t('Delete')}</span>
            </Button>}
        </div>
    );

    const onAddPlayers = () => {
        const players = [...mainTeam, ...reserveTeam];

        const _data = {
            ...initState,
            form: "player",
            title: t("Add players"),
            existedPlayersIds : players.map(p => p.playerId),
            onAddPlayers: completeAddPlayers,
            open: true
        }

        setModalData(_data);
    }

    const completeAddPlayers = (data) => {
        setModalData(initState);

        if (data.length === 0)
            return;

        const newPlayers = data.map(x => ({playerId: x.playerId, playerName: x.fullName}));

        const newMainTeam = [...mainTeam];
        const newReserveTeam = [...reserveTeam];

        newPlayers.forEach(p => {
            newMainTeam.length < 11
                ? newMainTeam.push(p)
                : newReserveTeam.push(p);
        });

        setMainTeam(newMainTeam);
        setReserveTeam(newReserveTeam);
    }

    const onDeletePlayers = () => {
        if (selectedRows.length === 0)
            return;

        const selectedIds = selectedRows.map(x => x.playerId);
        const mainTeamList = mainTeam.filter(f => selectedIds.indexOf(f.playerId) === -1);
        const mainReserveList = reserveTeam.filter(f => selectedIds.indexOf(f.playerId) === -1);

        setMainTeam(mainTeamList);
        setReserveTeam(mainReserveList);
    }

    const onNumberChange = (data, event, type) => {
        const {playerId} = data.row.original;
        const teamNumber = event.target.value;
        const team = type === "main" ? mainTeam : reserveTeam;
        const _players = [...team];
        const _player = _players.find(x => x.playerId === playerId);

        _player.teamNumber = teamNumber;

        if (type === "main") {
            setMainTeam(_players);
        } else {
            setReserveTeam(_players);
        }

        console.log(_players);
    }


    const onCaptainChange = (data, event) => {
        const {playerId} = data.row.original;
        const captain = event.target.checked;
        const _players = [...mainTeam];

        _players.forEach(x => {
            x.captain = x.playerId === playerId && captain;
        });

        setMainTeam(_players);
    }

    const onPositionChange = (data, event, type) => {
        const {playerId} = data.row.original;
        const position = event.target.value;
        const team = type === "main" ? mainTeam : reserveTeam;
        const _players = [...team];
        const _player = _players.find(x => x.playerId === playerId);

        _player.position = position;
        if (type === "main") {
            setMainTeam(_players);
        } else {
            setReserveTeam(_players);
        }
    }

    const onMoveReserve = (data) => {
        const player = {...data};
        const newMainTeam = mainTeam.filter(x => x.playerId !== player.playerId);
        setMainTeam(newMainTeam);

        player.captain = false;
        const newReserveTeam = [...reserveTeam, player];
        setReserveTeam(newReserveTeam);

        toastMessage(true, t("Player moved to reserve team."));
    }

    const onMoveMain = (data) => {
        if (mainTeam.length > 10) {
            toastMessage(false, t("Player not moved. Main team can have only 11 players."));
            return;
        }

        const newReserveTeam = reserveTeam.filter(x => x.playerId !== data.playerId);
        setReserveTeam(newReserveTeam);

        const newMainTeam = [...mainTeam, data];
        setMainTeam(newMainTeam);

        toastMessage(true, t("Player moved to main team."));
    }

    return (
        <>
            <Panel border={false} style={{marginTop:"0"}} action={playerActions}>
            </Panel>
                <h3 className={classes.h3}>Main team ({mainTeam.length})</h3>
                <Table
                    data={mainTeam}
                    center="true"
                    columns={[
                        {
                            Header: t('Name'),
                            accessor: 'playerName',
                            className: classes.nameColumn,
                            Cell: cellData => <div >{t(cellData.value)} </div>
                        },
                        {
                            Header: t('Number'),
                            accessor: 'teamNumber',
                            className: classes.inputColumn,
                            Cell: cellData => {
                                const value = { teamNumber: cellData.value };
                                return (<div><Input name="teamNumber" type="number" className={classes.number}
                                                    style={{marginBottom: 0}} value={value}
                                                    onBlur={e => onNumberChange(cellData, e, "main")} /></div>);
                            }
                        },
                        {
                            Header: t('Captain'),
                            accessor: 'captain',
                            className: classes.checkBoxColumn,
                            Cell: cellData => {
                                return (<div><input type="checkbox" checked={cellData.value} onChange={e => onCaptainChange(cellData, e)} /></div>);
                            }
                        },
                        {
                            Header: t('Position'),
                            accessor: 'position',
                            className: classes.selectColumn,
                            Cell: cellData => <Dropdown name="position" options={PlayerPositions()}
                                                        value={cellData.value}
                                                        onChange={e => onPositionChange(cellData, e, "main")} />
                        },
                        {
                            accessor: 'id',
                            className: classes.downButton,
                            Cell: cellData => <span onClick={() => onMoveReserve(cellData.row.original)} className="icon-Arrow---Up"></span>
                        }
                    ]}
                    onRowClick={() => {}}
                    onSelectClick={(x) => setSelectedRows(x)}
                />

                <h3>Reserve team ({reserveTeam.length})</h3>
                <Table
                    data={reserveTeam}
                    center="true"
                    rowClass={classes.tableRow}
                    columns={[
                        {
                            Header: t('Name'),
                            accessor: 'playerName',
                            className: classes.nameColumn,
                            Cell: cellData => <div>{t(cellData.value)}</div>
                        },
                        {
                            Header: t('Number'),
                            accessor: 'teamNumber',
                            className: classes.inputColumn,
                            Cell: cellData => {
                                const value = { teamNumber: cellData.value };
                                return (<div><Input name="teamNumber" type="number" className={classes.number}
                                                    style={{marginBottom: 0}} value={value}
                                                    onBlur={e => onNumberChange(cellData, e, "reserve")} /></div>);
                            }
                        },
                        {
                            Header: t('Capitan'),
                            accessor: 'captain',
                            className: classes.checkBoxColumn,
                            Cell: cellData => <div><input type="checkbox" checked={cellData.value} disabled
                                onChange={e => onCaptainChange(cellData, e)} /> </div>
                        },
                        {
                            Header: t('Position'),
                            accessor: 'position',
                            className: classes.selectColumn,
                            Cell: cellData => <Dropdown name="position" options={PlayerPositions()}
                                value={cellData.value} onChange={e => onPositionChange(cellData, e, "reserve")} />
                        },
                        {
                            accessor: 'id',
                            className: classes.upButton,
                            Cell: cellData => <span onClick={() => onMoveMain(cellData.row.original)} className="icon-Arrow---Up"></span>
                        }
                    ]}
                    onRowClick={() => {}}
                    onSelectClick={(x) => setSelectedRows(x)}
                />

            <ModalWindow2 {...modalData}>
                <AddPlayerForm {...modalData} />
            </ModalWindow2>
        </>
    );
};

MatchFormTwo.defaultProps = {};

export default MatchFormTwo;
