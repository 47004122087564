import React from 'react';
import PlayerHeader from '../Player/PlayerHeader';
import Submenu from '../../../components/Navigation/Submenu/Submenu';
import MatchesVideoList from '../../Team/MatchesVideo/MatchesVideoList';
import MatchesVideoHighlights from '../../Team/MatchesVideo/MatchesVideoHighlights';

const PlayerVideoMatches = (props) => {
    return (
        <>
            <div className="page-header">
                <PlayerHeader />
                <Submenu/>
            </div>
            <div className="page-content">
                <MatchesVideoList userMode={true} />
                <br />
                <MatchesVideoHighlights userMode={true} />
            </div>
        </>
    );
};

PlayerVideoMatches.defaultProps = {
    confirmedOnly: [
        {label: "All", value: false },
    ],
    filters: {
        type: false,
        players: false,
        actions: false,
    }
};

export default PlayerVideoMatches;
