import React from 'react';
import DateTime from 'components/UI/Table/DateTime/DateTime';
import Table from 'components/UI/Table/Table';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import {useCoachEvaluationList} from 'apollo/queries/ClubQueries';
import {useParams} from 'react-router-dom';
import Submenu from 'components/Navigation/Submenu/Submenu';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';
import CanAccess from 'components/Navigation/CanAccess/CanAccess';
import Panel from 'components/UI/Panel/Panel';
import Button from 'components/UI/Button/Button';

const CoachEvaluationTable = () => {
	const navState = useReactiveVar(naviDataVar);
	const {t} = useTranslation();

	let {trainerId} = useParams();

	const {loading, error, data} = useCoachEvaluationList(navState.clubId, trainerId);

	const actions = () => {
		return (
			<CanAccess users={[SystemRoles.GroupMember, SystemRoles.GroupManger, SystemRoles.SysAdmin]}>
				<Button type="primary" style={{margin: 0}} onClick={onAddEvaluation}>
					<span><i className="icon-Plus-Square"></i> {t("Add evaluation")}</span>
				</Button>
			</CanAccess>
		);
	}

	const onAddEvaluation = (data) => {
		// setEvaluationData();
		// setShowEvaluation(true);
	};

	const onEditEvaluation = (data) => {
		// const json = { id: data.id, ...JSON.parse(data.document)};
		// setEvaluationData(json);
		// setShowEvaluation(true);
	};

	if (loading) return <div>{t('Loading...')}</div>;
	if (error) return <div>{t('Error!')}</div>;

	return (
		<>
			<div className="page-header">
				<h2>{t('Club evaluation')}</h2>
				<Submenu/>
			</div>
			<div className="page-content">
        <h3>{data.trainers.items[0].fullName}</h3>
				<Panel border={false} action={actions}  />
				<Table
					data={data.clubEvaluations}
					columns={[
						{
							Header: t('Date and time'),
							accessor: 'date',
							Cell: cellData => <DateTime data={cellData.value}/>
						},
						{
							Header: t('Title'),
							accessor: 'title',
						},
						{
							Header: t('Evaluation'),
							accessor: 'clubEvaluationValue',
							Cell: cellData => {
								const {clubEvaluationValue, couchEvaluationValue} = cellData.row.original;
								return <div>{clubEvaluationValue}/{couchEvaluationValue}</div>;
							}
						}
					]}
					onRowClick={onEditEvaluation}/>
			</div>
		</>
	);
};

export default CoachEvaluationTable;
