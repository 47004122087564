import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useParams} from 'react-router-dom';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import classes from './TrainingDetails.module.scss';
import cn from 'classnames';
import Submenu from 'components/Navigation/Submenu/Submenu';
import Button from 'components/UI/Button/Button';
import {useChangeTrainingStatus, useTrainingDetails} from 'apollo/queries/TrainingQueries';
import TrainingDetailsPlayers from "./TrainingDetailsPlayers";
import TrainingDetailsFiles from './TrainingDetailsFiles';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import TrainingForm from '../Trainings/forms/TrainingForm';
import TrainingDetailsVideo from './TrainingDetailsVideo';
import TrainingDetailsPlan from './TrainingDetailsPlan';
import ShowDateTime from 'components/UI/DatePicker/ShowDateTime';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';
import CanAccess from 'components/Navigation/CanAccess/CanAccess';

const TrainingDetails = (props) => {
    const {t} = useTranslation();
    const { trainingId } = useParams();
    const { hash } = useLocation();
    const navState = useReactiveVar(naviDataVar);

    const openTab = () => {
        switch (hash) {
            case "#training-plan":
                return "plan";
            case "#video-tab":
                return "video";
            case "#files-tab":
                return "files"
            default:
                return "players";
        }
    }

    const [type, setType] = useState(openTab());

    const [modalData, setModalData, initState] = ModalState();

    const [changeTrainingStatus] = useChangeTrainingStatus();
    const [getTrainingDetails, getTrainingData] = useTrainingDetails();

    useEffect(() => {
        if (navState.teamId === null || trainingId === undefined)
            return;

        const variables = {
            teamId: navState.teamId,
            where: {
                "trainingId": {"eq": trainingId}
            }
        };

        getTrainingDetails({variables: variables});
    }, [navState, trainingId]);



    const onEditTraining = () => {
        const {trainingId, name, start, block, trainingNumber, duration, venueId, group}
            = getTrainingData.data.trainings.items[0];

        const data = {
            ...initState,
            title: t("Edit training"),
            defaultValues: {
                trainingId,
                name,
                start,
                block,
                trainingNumber,
                duration,
                venueId,
                group
            },
            onComplete: onSaveTraining,
            open: true
        };

        setModalData(data);
    }

    const onAcceptTraining = () => {
        const {trainingId}  = getTrainingData.data.trainings.items[0];

        const variables = {
            input: {
                trainingId: trainingId,
                teamId: navState.teamId,
                status: "ACCEPTED"
            }
        };

        changeTrainingStatus({variables: variables}).then(result => {
            onSaveTraining();
        });
    }

    const onDeclineTraining = () => {
        const {trainingId}  = getTrainingData.data.trainings.items[0];

        const variables = {
            input: {
                trainingId: trainingId,
                teamId: navState.teamId,
                status: "NEED_REMAKE"
            }
        };

        changeTrainingStatus({variables: variables}).then(result => {
            onSaveTraining();
        });
    }

    const onSaveTraining = () => {
        getTrainingData.refetch();
        setModalData(initState);
    }

    const Header = () => {
        const {start, block, trainingNumber, duration, type, venue, group, rhythm, workload, trainingStatus}
            = getTrainingData.data?.trainings?.items[0] ?? {};

        return (
            <div className={classes.quickInfo}>
                <div>
                    <div><span>{t("Date")}:</span> <ShowDateTime date={start} /></div>
                    <div><span>{t("Training number")}:</span> {trainingNumber}</div>
                    <div><span>{t("Block name")}:</span> {block}</div>
                    <div><span>{t("Group")}:</span> {group}</div>
                </div>
                <div>
                    <div><span>{t("Training type")}:</span> {type}</div>
                    <div><span>{t("Duration (min)")}:</span> {duration}</div>
                    <div><span>{t("Average heart rhythm")}:</span> {rhythm}</div>
                    <div><span>{t("Total workload")}:</span> {workload}</div>

                </div>
                <div>
                    <div><span>{t("Place")}:</span> {venue}</div>
                    <div><span>{t("Status")}:</span> {status(trainingStatus)}</div>
                </div>
            </div>
        );
    }

    const status = (data) => {
        switch (data) {
            case "ACCEPTED":
                return <span className={classes.accepted}>{t("Accepted")}</span>;
            case "IN_REVIEW":
                return <span className={classes.review}>{t("In review")}</span>;
            case "NO_PLAN":
                return <span className={classes.remake}>{t("No plan")}</span>;
            default:
                return <span className={classes.remake}>{t("Need remake")}</span>;
        }
    }


    const title = () => {
        const {name} = getTrainingData.data?.trainings?.items[0] ?? {};
        return name;
    }

    return (
        <>
            <div className={cn('page-header', classes.TrainingDetails)}>
                <div className={classes.name}>{navState.teamName}</div>
                <h2>{title()}</h2>
                <ul className={classes.actions}>
                    <CanAccess users={[SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin]}>
                        <li><Button type="third" style={{marginTop: "0px"}} onClick={onEditTraining}><i className="icon-Setting"> </i> {t("Edit training")}</Button></li>
                    </CanAccess>
                    <CanAccess users={[SystemRoles.ClubAdmin, SystemRoles.SysAdmin]}>
                        <li><Button type="third" style={{marginTop: "0px"}} onClick={onAcceptTraining}><i className="icon-mark2"> </i> {t("Accept training")}</Button></li>
                        <li><Button type="third" style={{marginTop: "0px"}} onClick={onDeclineTraining}><i className="icon-crosses"> </i> {t("Decline training")}</Button></li>
                    </CanAccess>
                </ul>
                <Header />
                <Submenu data={props.types} default={type} onFilter={(t) => setType(t)} />
            </div>
            <div className="page-content">
                {getTrainingData.loading && <>Loading...</>}
                {getTrainingData.error && <>Error...</>}
                {getTrainingData.data && <>
                {type === "players" && <TrainingDetailsPlayers {...getTrainingData} />}
                {type === "plan" && <TrainingDetailsPlan {...getTrainingData} />}
                {type === "video" && <TrainingDetailsVideo {...getTrainingData} />}
                {type === "files" && <TrainingDetailsFiles {...getTrainingData} />}
                </>}
            </div>

            <ModalWindow2 {...modalData}>
                <TrainingForm {...modalData} name="edit" />
            </ModalWindow2>
        </>
    );
};

TrainingDetails.propTypes = {};
TrainingDetails.defaultProps = {
    types: {
        players: "Players",
        plan: "Training plan",
        video: "Video",
        files: "Files"
    }
};

export default TrainingDetails;
