import React, {useEffect, useState} from 'react';
import classes from './NewPlayerForm.module.scss';
import Input from 'components/UI/Input/Input';
import Select from 'components/UI/Select/Select';
import ImageUpload from 'components/UI/ImageUpload/ImageUpload';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import {notHaveErrors, validateForm} from 'utils/form';
import * as yup from 'yup';
import Button from 'components/UI/Button/Button';
import Table from 'components/UI/Table/Table';
import Submenu from 'components/Navigation/Submenu/Submenu';
import DatePicker from 'components/UI/DatePicker/DatePicker';
import {Countries, Foot, Gender, PlayerPositions, PlayerStatus} from '../../MatchesDetails/Settings';
import {useSavePlayer} from 'apollo/queries/PlayerQueries';
import {createGuid} from 'utils/utils';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import ParentForm from './ParentForm';
import DropdownButton from 'components/UI/DropdownButton/DropdownButton';
import Subtext from 'components/UI/Table/Subtext/Subtext';
import {fileUploader} from 'utils/upload';
import {useAuth} from 'react-oidc-context';

const NewPlayerForm = (props) => {
    const {pages, defaultValues, setModal, setLoading, onComplete} = props;
    const {t} = useTranslation();
    const auth = useAuth();
    const navState = useReactiveVar(naviDataVar);

    const [image, setImage] = useState(null);
    const [errors, setErrors] = useState({});
    const [values, setValues] = useState(defaultValues ?? {
        registrationDate: new Date(),
        firstVisitDate: new Date(),
        passportDateOfIssue: null,
        position: "NONE",
        status: "NONE",
        parents: []
    });

    useEffect(() => {
        if(!defaultValues) {
            return;
        }

        const newValues = { ...values };

        const web = defaultValues.links.find(x => x.type === "WEBSITE");
        if (web) {
            newValues.linkName0 = web.name;
            newValues.linkUrl0 = web.url;
        }

        const social = defaultValues.links.find(x => x.type === "SOCIAL_LINK");
        if (social) {
            newValues.linkName1 = social.name;
            newValues.linkUrl1 = social.url;
        }

        const video = defaultValues.links.find(x => x.type === "VIDEO_CHANEL");
        if (video) {
            newValues.linkName2 = video.name;
            newValues.linkUrl2 = video.url;
        }

        const photo = defaultValues.links.find(x => x.type === "PHOTO_ALBUM");
        if (photo) {
            newValues.linkName3 = photo.name;
            newValues.linkUrl3 = photo.url;
        }

        setValues(newValues);
    }, [defaultValues]);

    const [selectedParents, setSelectedParents] = useState([]);

    const [modalData, setModalData, initState] = ModalState();
    const [savePlayer] = useSavePlayer();

    const fieldChanged = (event) => {
        const newValues = { ...values };
        newValues[event.target.name] = event.target.value;

        if (event.target.file !== undefined) {
            newValues[event.target.name+"File"] = event.target.file;
        }

        setValues(newValues);
    }

    const pageOneValidationRules = {
        firstName: yup.string().required(t("required")),
        lastName: yup.string().required(t("required")),
        gender: yup.string().required(t("required")),
        dateOfBirth: yup.string().required(t("required")),
        position: yup.string().required(t("required")),
        status: yup.string().required(t("required")),
    }

    const pageTwoValidationRules = {
    }

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors
    };

    const formSubmitted = async (e) => {
        e.preventDefault();
        setLoading(true);

        new Promise(function(resolve, reject) {
            //Page 1
            validateForm(values, pageOneValidationRules, (errors) => {
                notHaveErrors(errors) ? resolve(1) : reject({page: 1, errors});
            });
        }).then(function(result) {
            //Page 2
            return new Promise(function(resolve, reject) {
                validateForm(values, pageTwoValidationRules, (errors) => {
                    notHaveErrors(errors) ? resolve(2) : reject({page: 2, errors});
                });
            });
        }).then(function(result) {
            //Page 3
            return new Promise((resolve, reject) => {
                resolve(1);
            });
        }).then(function(result) {
            saveData();
        }).catch(e => {
            setErrors(e.errors);
            onPageChanged(e.page);
            setLoading(false);
        });
    };

    const saveData = async () => {
        const variables = {
            input: {
                playerId: values.playerId ?? createGuid(),
                teamId: navState.teamId,
                clubId: navState.clubId,
                firstName: values.firstName,
                lastName: values.lastName,
                middleName: values.middleName,
                dateOfBirth: values.dateOfBirth,
                gender: values.gender,
                licenceNr: values.licenceNr,
                footed: values.footed,
                position: values.position,
                phoneNumber: values.phoneNumber,
                email: values.email,
                addressLine: values.addressLine,
                status: values.status,
                registrationDate: values.registrationDate,
                firstVisitDate: values.firstVisitDate,

                passportNumber: values.passportNumber,
                passportDateOfIssue: values.passportDateOfIssue,
                passportDateOfExpiry: values.passportDateOfExpiry,
                countryOfBirth: values.countryOfBirth,
                cityOfBirth: values.cityOfBirth,
                citizenship: values.citizenship,
                personalCode: values.personalCode,
                links: [],

                parents: []
            }
        }

        if (values.linkName0 && values.linkUrl0)
            variables.input.links.push({type: "WEBSITE", name: values.linkName0, url: values.linkUrl0});
        if (values.linkName1 && values.linkUrl1)
            variables.input.links.push({type: "SOCIAL_LINK", name: values.linkName1, url: values.linkUrl1});
        if (values.linkName2 && values.linkUrl2)
            variables.input.links.push({type: "VIDEO_CHANEL", name: values.linkName2, url: values.linkUrl2});
        if (values.linkName3 && values.linkUrl3)
            variables.input.links.push({type: "PHOTO_ALBUM", name: values.linkName3, url: values.linkUrl3});

        if (values.parents) {
            values.parents.forEach(x => {
                const parent = {
                    id: x.id,
                    playerId: variables.input.playerId,
                    relationType: x.relationType,
                    firstName: x.firstName,
                    lastName: x.lastName,
                    email: x.email,
                    phoneNumber: x.phoneNumber,
                };
                variables.input.parents.push(parent);
            })
        }

        if (values.photoFile !== undefined) {
            const fileData = {
                clubId: navState.clubId,
                fileId: variables.input.playerId
            };

            if (await fileUploader(values.photoFile, fileData, auth)) {
                variables.input.photoUploadDate = new Date().toISOString();
            }
        }

        await savePlayer({variables: variables});
        onComplete({result: true, message: t("Player saved")});
    };

    const onImageChanged = (e) => {
        setImage(e);
    }

    useEffect(() => {
        if (!image) return;
        fieldChanged(image);
    }, [image]);

    const PageOne = () => (
        <div className={classes.columns21}>
            <div>
                <div className={classes.columns11}>
                    <Input
                        name="firstName"
                        label={t('First name')}
                        {...regField}
                    />

                    <Input
                        name="lastName"
                        label={t('Last name')}
                        {...regField}
                    />
                </div>
                <div className={classes.columns11}>
                    <Input
                        name="middleName"
                        label={t('Middle name')}
                        {...regField}
                    />

                    <DatePicker
                        name="dateOfBirth"
                        label={t('Birth date')}
                        {...regField}
                    />
                </div>

                <div className={classes.columns11}>
                    <Select
                        name="gender"
                        label={t('Gender')}
                        options={Gender()}
                        {...regField} />

                    <Input
                        name="licenceNr"
                        label={t('License')}
                        {...regField}
                    />

                </div>

                <div className={classes.columns11}>
                    <Select
                        name="footed"
                        label={t('Preferred foot')}
                        options={Foot()}
                        {...regField} />

                    <Select
                        name="position"
                        label={t('Position')}
                        options={PlayerPositions()}
                        {...regField} />
                </div>

                <div className={classes.columns11}>

                    <Select
                        name="status"
                        label={t('Status')}
                        options={PlayerStatus()}
                        {...regField} />

                </div>

                <div className={classes.title}>{t("Contacts")}</div>

                <div className={classes.columns11}>
                    <Input
                        name="phoneNumber"
                        type="number"
                        label={t('Phone')}
                        {...regField}
                    />

                    <Input
                        name="email"
                        label={t('E-mail')}
                        {...regField}
                    />
                </div>

                <Input
                    name="addressLine"
                    label={t('Address')}
                    {...regField}
                />

            </div>
            <div>
                <ImageUpload
                    name="photo"
                    label={t('Player photo')}
                    text="Add player photo"
                    {...regField}
                    onChange={onImageChanged}
                />
            </div>
        </div>
    );

    const PageTwo = () => (
        <div>
            <div className={classes.columns111}>

                <Input
                    name="passportNumber"
                    label={t('Passport number')}
                    {...regField}
                />

                <DatePicker
                    name="passportDateOfIssue"
                    label={t('Passport date of issue')}
                    {...regField}
                />

                <DatePicker
                    name="passportDateOfExpiry"
                    label={t('Passport date of expiry')}
                    {...regField}
                />

            </div>

            <div className={classes.columns111}>

                <Select
                    name="countryOfBirth"
                    options={Countries()}
                    label={t('Country of birth')}
                    {...regField}
                />

                <Input
                    name="cityOfBirth"
                    label={t('City of birth')}
                    {...regField}
                />

                <Select
                    name="citizenship"
                    label={t('Citizenship')}
                    options={Countries()}
                    {...regField} />

            </div>

            <div className={classes.columns111}>

                <Input
                    name="personalCode"
                    label={t('Personal Code')}
                    {...regField}
                />

                <DatePicker
                    name="registrationDate"
                    label={t('Joined club date')}
                    {...regField}
                />

                <DatePicker
                    name="firstVisitDate"
                    label={t('First visit date')}
                    {...regField}
                />
            </div>

            <div className={classes.title}>{t("Social media")}</div>

            <div className={classes.columns12}>
                <Input
                    name="linkName0"
                    label={t('Website name')}
                    {...regField}
                />

                <Input
                    name="linkUrl0"
                    label={t('Link')}
                    {...regField}
                />
            </div>
            <div className={classes.columns12}>
                <Input
                    name="linkName1"
                    label={t('Social network')}
                    {...regField}
                />

                <Input
                    name="linkUrl1"
                    label={t('Link')}
                    {...regField}
                />

            </div>

            <div className={classes.columns12}>
                <Input
                    name="linkName2"
                    label={t('Video chanel')}
                    {...regField}
                />

                <Input
                    name="linkUrl2"
                    label={t('Link')}
                    {...regField}
                />
            </div>

            <div className={classes.columns12}>
                <Input
                    name="linkName3"
                    label={t('Photo album')}
                    {...regField}
                />

                <Input
                    name="linkUrl3"
                    label={t('Link')}
                    {...regField}
                />
            </div>
        </div>
    );

    const PageThree = () => (
       <div className={classes.parents}>
           <div className={classes.action}>
               <DropdownButton type="primary" options={props.parentsOptions} onClick={onAddParent}>
                   <span><i className="icon-Plus-Square"></i> {t('Add parent')}</span>
               </DropdownButton>

               {selectedParents.length > 0 &&
                   <Button type="secondary" style={{margin: 0}} onClick={onDeleteParentClick}>
                       <span>{t('Delete')}</span>
                   </Button>
               }
           </div>
           <div className={classes.minHeight}>
               <Table
                   data={values.parents}
                   columns={[
                       {
                           Header: t('Name'),
                           accessor: 'firstName',
                           Cell: cellData => <div>
                               {cellData.row.original.fullName}
                               <Subtext>{cellData.row.original.relationType}</Subtext>
                           </div>
                       },
                       {
                           Header: t('Contact'),
                           accessor: 'email',
                           Cell: cellData => <div>
                               <a href={"phone:" + cellData.value}>{cellData.value}</a>
                               <Subtext><a href={"phone:" + cellData.row.original.phone}>{cellData.row.original.phone}</a></Subtext>
                           </div>
                       },
                   ]}
                   onSelectClick={(x) => setSelectedParents(x)}
                   onRowClick={() => {}} />
           </div>
       </div>
    );

    const onAddParent = (action) => {
        console.log(action);

        const data = {
            ...initState,
            form: "staff",
            title: t("Add parent"),
            onComplete: onCompleteParent,
            open: true
        }

        setModalData(data);
    }

    const onCompleteParent = (parent) => {
        setModalData(initState);

        const newValues = { ...values };
        newValues.parents = [...newValues.parents, parent];

        setValues(newValues);
    }

    const onDeleteParentClick = () => {
        const newValues = { ...values };

        const selectedIds = selectedParents.map(x => x.id);
        const parents = newValues.parents.filter(x => selectedIds.indexOf(x.id) === -1);
        newValues.parents = parents ?? [];

        setValues(newValues);
    }

    const onPageChanged = (newPage) => {
        const page = newPage - 1;
        setModal({...props, pages: { ...props.pages, page: page}});
    }


    return (
        <>
            <Submenu default={pages.page + 1} data={props.pageNames} onFilter={onPageChanged} className={classes.submenu} />

            {pages.page  === 0 && PageOne()}
            {pages.page  === 1 && PageTwo()}
            {pages.page  === 2 && PageThree()}

            <form id={props.id} onSubmit={formSubmitted}></form>

            <ModalWindow2 {...modalData}>
                <ParentForm {...modalData} name="parent" />
            </ModalWindow2>
        </>
    );
};

NewPlayerForm.defaultProps = {
    page: 0,
    pageNames: {
        1: "General info",
        2: "Additional",
        3: "Parents",
    },
    files: [],
    onPageChange: () => {},
    linkTypes: [
        {
            type: "WEBSITE",
            name: "Website name",
            url: "Link"
        }, {
            type: "SOCIAL_LINK",
            name: "Social network",
            url: "Link"
        }, {
            type: "VIDEO_CHANEL",
            name: "Video chanel",
            url: "Link"
        }, {
            type: "PHOTO_ALBUM",
            name: "Photo album",
            url: "Link"
        }
    ],
    parentsOptions: [
        {label: "New parent", value: "new"},
        {label: "Exist parent", value: "exist"},
    ]
};

export default NewPlayerForm;
