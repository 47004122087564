import React, {useEffect, useState} from 'react';
import ViewSkillsChart from '../charts/ViewSkillsChart';
import Select from '../../../../components/UI/Select/Select';
import {timestampAsDate} from '../../../../utils/date';

const ViewSkillsStat = (props) => {
    const [dates, setDates] = useState([]);
    const [values, setValues] = useState({
        labels: props.values.labels,
        datasets: [{}]
    });

    useEffect(() => {
        const options = props.values.datasets.map(x => ({label: timestampAsDate(x.date), value: x.date}));
        setDates(options);

    }, [props.values]);

    const onChange = (data) => {
        const _data = data.target.value ?? [];

        const selected = {
            labels: props.values.labels,
            datasets: props.values.datasets.filter(x => _data.includes(x.date))
        };

        if(selected.datasets.length === 0) {
            selected.datasets.push([{}]);
        }

        setValues(selected);
    }



    return (
        <div>
            <Select
                name="dates"
                options={dates}
                isMulti={true}
                onChange={onChange}
            />
            <ViewSkillsChart data={values} />
        </div>
    );
};

ViewSkillsStat.defaultProps = {};

export default ViewSkillsStat;
