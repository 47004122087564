import React from 'react';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import cn from 'classnames';
import classes from 'containers/Club/Archive/PlayerArchive/PlayerArchive.module.scss';
import Submenu from 'components/Navigation/Submenu/Submenu';
import Panel from 'components/UI/Panel/Panel';
import {useArchivedPlayers, useRestorePlayer} from 'apollo/queries/PlayerQueries';
import Table from 'components/UI/Table/Table';
import DateTime from 'components/UI/Table/DateTime/DateTime';
import Button from 'components/UI/Button/Button';
import Subtext from 'components/UI/Table/Subtext/Subtext';
import Birthday from 'components/UI/Table/Birthday/Birthday';

const PlayerArchive = (props) => {
    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const {loading, data, error, refetch} = useArchivedPlayers({clubId: navState.clubId});
    const [restorePlayer] = useRestorePlayer();

    const onRowClick = (data) => {
        // const teamId = UuidTOGuid(data.teamId);
        // history.push(`/Team/TeamDetails/${teamId}#team-player-list-panel`);
    }

    const onRestore = (data) => {
        const variables = {
            input: {
                clubId: navState.clubId,
                playerId: data.playerId
            }
        }

        restorePlayer({variables: variables}).then(() => {
            refetch();
        });
    }

    return (
        <>
            <div className={cn('page-header', classes.playerArchive)}>
                <div className={classes.name}>{navState.clubName}</div>
                <h2>{t('Player archive')}</h2>
                <Submenu/>
            </div>

            <div className="page-content">
                <Panel border={false} />

                {loading && <>Loading...</>}
                {error && <>Error...</>}
                {data && <Table
                    data={data.archivedPlayers.items}
                    center="true"
                    columns={[
                        {
                            Header: t('Player'),
                            accessor: 'fullName',
                            Cell: cellData => <div>{cellData.row.original.fullName}
                                <Subtext>{t("Birth")}: { cellData.row.original.dateOfBirth ?
                                    <Birthday className={classes.birthday} coma={true} data={cellData.row.original.dateOfBirth} /> : "-" }
                                </Subtext>
                            </div>
                        },
                        {
                            Header: t('Contacts'),
                            accessor: 'email',
                            Cell: (cellData) => {
                                const email = cellData.value;
                                const phone = cellData.row.original.phoneNumber;
                                return (<>
                                    <a href={`mailto:${email}`}>{email}</a>
                                    <Subtext>
                                        {t("Phone")}: {phone ? <a href={`phone:${phone}`}>{phone}</a> : "-"}
                                    </Subtext>
                                </>);
                            }
                        },
                        {
                            Header: t('Archived'),
                            accessor: 'archive',
                            Cell: cellData => <DateTime data={cellData} showTime={false} />
                        },
                        {
                            Header: t('Action'),
                            accessor: 'restore',
                            Cell: cellData => <div><Button onClick={() => onRestore(cellData.row.original)} type="primary" style={{margin: "0"}}>Restore</Button></div>
                        },
                    ]}
                    onRowClick={onRowClick}/>
                }
            </div>

        </>
    );
};

PlayerArchive.defaultProps = {};

export default PlayerArchive;
