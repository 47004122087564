import React from 'react';
import classes from './MessageSent.module.scss';
import Button from 'components/UI/Button/Button';
import {useTranslation} from 'react-i18next';

const MessageSent = () => {
  const {t} = useTranslation();

  const onClick = () => {
    window.location.href = process.env.REACT_APP_REVIEW_URL;
  };

  return (
      <div>
        <h1>{t('thankYou')}</h1>

        <div className={classes.description}>
          {t('questionSent')}
        </div>

        <Button onClick={onClick} type="primary">{t('goMain')}</Button>
      </div>
  );
};

export default MessageSent;
