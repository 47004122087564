import React from 'react';
import cn from 'classnames';
import classes from 'containers/Club/Archive/StaffArchive/StaffArchive.module.scss';
import Submenu from 'components/Navigation/Submenu/Submenu';
import Panel from 'components/UI/Panel/Panel';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import Table from 'components/UI/Table/Table';
import DateTime from 'components/UI/Table/DateTime/DateTime';
import Button from 'components/UI/Button/Button';
import {useArchivedTrainers, useRestoreTrainer} from 'apollo/queries/TrainerQueries';
import Subtext from 'components/UI/Table/Subtext/Subtext';

const StaffArchive = (props) => {
    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const {loading, data, error, refetch} = useArchivedTrainers({clubId: navState.clubId});
    const [restoreTrainer] = useRestoreTrainer();

    const onRowClick = (data) => {
    }

    const onRestore = (data) => {
        const variables = {
            input: {
                clubId: navState.clubId,
                trainerId: data.trainerId
            }
        }

        restoreTrainer({variables: variables}).then(() => {
            refetch();
        });
    }

    return (
        <>
            <div className={cn('page-header', classes.coachArchive)}>
                <div className={classes.name}>{navState.clubName}</div>
                <h2>{t('Staff archive')}</h2>
                <Submenu/>
            </div>

            <div className="page-content">
                <Panel border={false} />

                {loading && <>Loading...</>}
                {error && <>Error...</>}
                {data && <Table
                    data={data.archivedTrainers.items}
                    center="true"
                    columns={[
                        {
                            Header: t('Name'),
                            accessor: 'fullName',
                            Cell: (cellData) => {
                                const phone = cellData.row.original.phoneNumber;
                                return (<>
                                    {cellData.value}
                                    <Subtext>{t("Coach")}</Subtext>
                                </>);
                            }
                        },
                        {
                            Header: t('Contacts'),
                            accessor: 'email',
                            Cell: (cellData) => {
                                const email = cellData.value;
                                const phone = cellData.row.original.phoneNumber;
                                return (<>
                                    <a href={`mailto:${email}`}>{email}</a>
                                    <Subtext>
                                        {t("Phone")}: {phone ? <a href={`phone:${phone}`}>{phone}</a> : "-"}
                                    </Subtext>
                                </>);
                            }
                        },
                        {
                            Header: t('Archived'),
                            accessor: 'archive',
                            Cell: cellData => <DateTime data={cellData} showTime={false} />
                        },
                        {
                            Header: t('Action'),
                            accessor: 'restore',
                            Cell: cellData => <div><Button onClick={() => onRestore(cellData.row.original)} type="primary" style={{margin: "0"}}>Restore</Button></div>
                        },
                    ]}
                    onRowClick={onRowClick}/>
                }
            </div>
        </>
    );
};

StaffArchive.defaultProps = {

};

export default StaffArchive;
