import React, {useEffect} from 'react';
import {useParams, useLocation, Outlet} from 'react-router-dom';
import {naviDataVar, systemDataVar} from 'apollo/cashe';
import {useReactiveVar} from '@apollo/client';
import TextSelector from 'text-selection-react'
import {useTranslation} from 'react-i18next';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import SwitchComponents from 'components/System/SwitchComponents';
import Menu from 'components/Navigation/Menu/Menu';
import Toast from 'components/UI/Toast/Toast';
import {HeaderBar} from 'components/Navigation';
import LocalizationEditor from 'containers/SysAdmin/Localization/forms/LocalizationEditor';
import SuggestTranslationForm from 'containers/SysAdmin/Localization/forms/SuggestTranslationForm';
import {useSettings} from 'apollo/queries/SettingQueries';
import {deepMergeFlatten} from 'utils/utils';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';
import './MainLayout.module.scss';
import FooterBar from 'components/Navigation/FooterBar/FooterBar';
import {menu} from 'components/Navigation/Menu/Settings';
import {findInMenu} from 'utils/history';
import Cookie from 'cookie-universal';
import i18n from 'i18next';

const MainLayout = (props) => {
  const {t} = useTranslation();

  const {data, loading, error} = useSettings();
  const {groupId, clubId, teamId, playerId} = useParams();

  const systemData = useReactiveVar(systemDataVar);
  const navState = useReactiveVar(naviDataVar);

  useEffect(() => {
    if (data && !systemData.ready) {
      const newSystemState = deepMergeFlatten(systemData, data);
      newSystemState.ready = true;
      systemDataVar(newSystemState);

      const cookies = Cookie();
      const language = cookies.get('locale') ?? process.env.REACT_APP_DEFAULT_LANGUAGE;

      if (newSystemState.settings.general.language !== language) {
        cookies.set('locale', newSystemState.settings.general.language, {domain: process.env.REACT_APP_TRANSLATES_COOKIE_DOMAIN});
        i18n.changeLanguage(newSystemState.settings.general.language);
      }

      const loader = document.querySelector(".preloader");
      loader.classList.add("loader-hide");
    }
  }, [data]);

  useEffect(() => {
    const newNavState = {...navState};

    if (groupId && groupId !== null) {
      newNavState.groupId = groupId;
    }

    if (clubId && clubId !== null) {
      newNavState.clubId = clubId;
    }

    if (teamId && teamId !== "none") {
      newNavState.teamId = teamId;
    }

    if (playerId) {
      newNavState.playerId = playerId;
    }

    newNavState.selected = findInMenu(newNavState);

    naviDataVar(newNavState);
  }, [groupId, clubId, teamId, playerId]);

  useEffect(() => {
      if (error) {
        console.log("Setting not loaded");

        //TODO add logout
      }
  }, [error]);

  const [modalData, setModalData, initState] = ModalState();


  const translate = (html, text) => {
    const _data = {
      ...initState,
      title: t("Translate"),
      form: "translate",
      styles: {
        maxWidth: "900px",
      },
      buttons: {
        hideSaveButton: true
      },
      defaultValues: {
        search: text,
        locale: systemData.settings.general.language,
      },
      onComplete: onComplete,
      open: true
    }

    setModalData(_data);
  }

  const suggestTranslation = (html, text) => {
    const _data = {
      ...initState,
      title: t("Suggest translation"),
      form: "suggest",
      defaultValues: {
        code: text,
        locale: systemData.settings.general.language,
      },
      onComplete: onComplete,
      open: true
    }

    setModalData(_data);
  }

  const onComplete = () => {
    setModalData(initState);
  }

  const selectorButtons = [
    systemData.user.userRole.roleId === SystemRoles.SysAdmin
    ? {
        text: t('Translate'),
        handler: translate
      }
    : {
        text: t('Suggest a translation'),
        handler: suggestTranslation
      }
  ];

  if (loading) {
    return <div>Loading...</div>;
  }

  if (systemData.ready) {

    return (
        <>
          <div id="ypd">
            <div id="appMenu">
              <Menu />
            </div>
            <div id="appContent">
              <HeaderBar />
              <Outlet />
              <FooterBar />
            </div>
            { window.innerWidth > 1024 && systemData.settings.general.suggestions ? <TextSelector events={selectorButtons} /> : undefined}
          </div>

          <Toast />

          <ModalWindow2 {...modalData}>
            <SwitchComponents active={modalData.form}>
              <LocalizationEditor {...modalData} name="translate" />
              <SuggestTranslationForm {...modalData} name="suggest" />
            </SwitchComponents>
          </ModalWindow2>
        </>
    )
  }

  return (
      <div>Error...</div>
  );
};

MainLayout.propTypes = {};
MainLayout.defaultProps = {};

export default MainLayout;
