import React, {useEffect, useState} from 'react';
import cn from 'classnames';
import classes from './Attendance.module.scss';
import Submenu from 'components/Navigation/Submenu/Submenu';
import Panel from 'components/UI/Panel/Panel';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar, systemDataVar} from 'apollo/cashe';
import DatePicker from 'components/UI/DatePicker/DatePicker';
import {firstDayOfMonth, lastDayOfMonth, showDateHours} from 'utils/date';
import Matrix from 'components/UI/Matrix/Matrix';
import Dropdown from 'components/UI/Table/DropDown/Dropdown';
import Button from 'components/UI/Button/Button';
import {useGamesAttendance} from 'apollo/queries/GameQueries';

const AttendanceMatches = (props) => {
    const {attendance, health} = props;

    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);
    const systemData = useReactiveVar(systemDataVar);

    const [matrixData, setMatrixData] = useState([]);
    const [columns, setColumns] = useState("170px repeat(4, 90px) 100px 1fr");

    const [values, setValues] = useState({
        startDate: firstDayOfMonth(),
        endDate: lastDayOfMonth()
    });

    const [getGamesAttendance, gamesAttendanceData] = useGamesAttendance();

    useEffect(() => {
        if (!navState.clubId) return;

        getGamesAttendance({ variables: {
            "clubId": navState.clubId,
            "teamId": navState.teamId,
            "where": {
                "and": [
                    {"date": {"gte": values.startDate}},
                    {"date": {"lte": values.endDate}}
                ]
            }
        }});
    }, [navState, values]);

    useEffect(() => {
        if(!gamesAttendanceData.data) return;

        const {data} = gamesAttendanceData;

        let _matrixData = [];
        const _header = {
            rowId: "header0",
            cells: [{ columnId: "column-name", cellName: t("Player")}]
        };

        const _columns = data.games.items.map(e => (
          {columnId: e.gameId,  cellName: showDateHours(e.date, systemData.settings.general)}
        ));

        _header.cells = _header.cells.concat(_columns);
        _header.cells.push({ columnId: "column-rating", cellName: t("Rating")});
        _header.cells.push({ columnId: "column-attendance", cellName: t("Attendance")});
        _matrixData.push(_header);

        const _rows = data.players.items.map(p => {
            const _cells = data.games.items.map(e => {

                const player = e.players.find(f => f.playerId === p.playerId);

                return {
                    columnId: e.trainingId,
                    cellName: player ? <Dropdown name="status" mode="iconWithoutText"
                                        onChange={data => {}} options={attendance}
                                                 value={player?.status} /> : "-",
                    rating: player?.rating * 2 ?? "",
                    status: player?.status ?? "",
                }
            });

            const rating = Math.round(_cells.reduce((sum, a) => sum + a.rating ?? 0, 0) / _cells.length) ?? 0;
            const attendanceSum = _cells.reduce((sum, a) => a.status !== "" ? sum + 1 : sum, 0);
            const percent = attendanceSum !== 0 ? Math.round( attendanceSum / _cells.length * 100) : 0;

            const _row = {
                rowId: p.playerId,
                cells: [
                    { columnId: p.playerId, cellName: `${p.fullName} (${p.teams[0]?.teamNumber})`},
                    ..._cells,
                    { columnId: "column-rating", cellName: rating !== 0 && !isNaN(rating) ? rating : ""},
                    { columnId: "column-attendance", cellName:  `${attendanceSum}/${_cells.length} (${percent}%)`}
                ]
            };

            setColumns(`170px repeat(${_cells.length + 1}, 90px) 120px 1fr`);
            return _row;
        });

        _matrixData = _matrixData.concat(_rows);

        setMatrixData(_matrixData);

    }, [gamesAttendanceData.data]);

    const actions = () => {
        return (
            <Button type="primary" style={{margin: 0}}>
                <span><i className="icon-Send"></i> {t("Share")}</span>
            </Button>
        )
    }

    const onFilter = (e) => {
        const newFilters = {...values};
        newFilters[e.target.name] = e.target.value;
        setValues(newFilters);
    }

    const filters = () => {
        return (
            <>
                <DatePicker
                    name="startDate"
                    onChange={onFilter}
                    placeholder={t("Date from")}
                    value={values}
                />

                <DatePicker
                    name="endDate"
                    onChange={onFilter}
                    placeholder={t("Date till")}
                    value={values}
                />
            </>
        )
    }

    if (gamesAttendanceData.loading) return (<div>{t("Loading...")}</div>);
    if (gamesAttendanceData.error) return (<div>{t("Error...")}</div>);

    return (
        <>
            <div className={cn("page-header", classes.attendance)}>
                <div className={classes.name}>{navState.teamName}</div>
                <h2>{t('Attendance matches')}</h2>
                <Submenu />
            </div>
            <div className="page-content">
                <Panel border={false} action={actions} filters={filters} />

                <Matrix data={matrixData} columns={columns} />
            </div>
        </>
    );
};

AttendanceMatches.defaultProps = {
    attendance: [
        {label: "Present", icon: "<i class=\"icon-Check-Square icon-green\"></i>", value: "PRESENT"},
        {label: "Was late", icon: "<i class=\"icon-Time-Square icon-yellow\"></i>", value: "WAS_LATE"},
        {label: "Ill", icon: "<i class=\"icon-medicine icon-blue\"></i>", value: "ILL"},
        {label: "Excused", icon: "<i class=\"icon-Document icon-blue\"></i>", value: "EXCUSED"},
        {label: "Unexcused", icon: "<i class=\"icon-Close-Square icon-red\"></i>", value: "UNEXCUSED"},
    ],
};

export default AttendanceMatches;
