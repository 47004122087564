import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Button from 'components/UI/Button/Button';
import classes from './ConfirmEmail.module.scss';
import Input from 'components/UI/Input/Input';
import {notHaveErrors, validateFields, validateForm} from 'utils/form';
import * as yup from 'yup';
import Spinner from 'components/UI/Spinner/Spinner';
import axios from 'axios';
import ErrorMessage from 'components/UI/ErrorMessage/ErrorMessage';
import {useSearchParams} from 'react-router-dom';

const ConfirmEmail = () => {
    const {t} = useTranslation();
    let [searchParams, setSearchParams] = useSearchParams();

    const [status, setStatus] = useState(null);
    const [values, setValues] = useState({});
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getConfirmation();
        const loader = document.querySelector(".preloader");
        loader.classList.add("loader-hide");
    }, []);

    const getConfirmation = () => {
        const params = new URLSearchParams();
        params.append('userid', searchParams.get('userid'));
        params.append('code', searchParams.get('code'));

        axios.post(`${process.env.REACT_APP_REGISTRATION_URL}/confirmemail`, params)
            .then(res => {
                setStatus(res.status === 201 ? 'ok' : 'error');
            }).catch(err => {
                setStatus('error');
            }
        );
    }

    const fieldChanged = (event) => {
        const newValues = {...values};

        newValues[event.target.name] = event.target.value;

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });
        setValues(newValues);
    };

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors
    };

    const validationRules = {
        email: yup.string().required(t('Required')),
    };


    const onLogin = () => {
        window.location.href = process.env.REACT_APP_POST_LOGIN_URI;
    }

    const onResend = (e) => {
        e.preventDefault();

        validateForm(values, validationRules, async (errors) => {
            if (notHaveErrors(errors))
                await sendRequest()
            else
                setErrors(errors);
        });
    }

    const sendRequest = async () => {
        try {
            setLoading(true);

            const params = new URLSearchParams();
            params.append('email', values.email);

            const response = await axios.post(process.env.REACT_APP_REGISTRATION_URL  + '/resendemailconfirmation', params);

            if (response.status === 201) {
                setLoading(false);
                setStatus('resend');
            } else {
                notSend();
            }
        } catch (e) {
            notSend();
        }
    }

    const notSend = () => {
        setLoading(false);
    }

    const Ok = () => (
        <div>
            <h1>{t("Email confirmed")}</h1>
            <p>{t("Thank you for confirming your email. Please click login button below.")}</p>
            <div className={classes.center}>
                <Button type="primary" style={{margin: "30px 0"}} onClick={onLogin}>{t("Log in")}</Button>
            </div>
        </div>
    );

    const Error = () => (
        <div>
            <h1>{t("Email not confirmed")}</h1>
            <p className={classes.error}>{t("Something wrong. Email not confirmed. Type your email and try again.")}</p>

            <div>
                <Input
                    type="email"
                    name={"email"}
                    {...regField}
                    label={t("Email")}
                />
            </div>
            <div className={classes.center}>
                <Button type="primary" style={{margin: "0 0 15px 0", width: "100%"}} onClick={onResend}>
                    {loading ? <Spinner /> : t("Resend confirmation")}
                </Button>
                <a href="mailto:support@yoplaydo.com" className={classes.support}>{t("Contact support team")}</a>
            </div>
        </div>
    );

    const Resend = () => (
        <div>
            <h1>{t("Confirm code resend")}</h1>
            <p>{t("We have sent you a new confirmation code. Please check your email.")}</p>
        </div>
    );

    if (status === null) return (<div style={{fontSize: "14px"}}>{t("Loading...")}</div>);

    return (
        <div>
            {status === "ok" ? <Ok /> : undefined}
            {status === "error" ? <Error /> : undefined}
            {status === "resend" ? <Resend /> : undefined}
        </div>
    );
};

ConfirmEmail.defaultProps = {};

export default ConfirmEmail;
