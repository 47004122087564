import React from 'react';
import classes from './VideoEditor.module.scss';
import cn from 'classnames';
import Button from '../../../../components/UI/Button/Button';

const EventList = (props) => {
    const {eventCategories, events} = props.data;

    const onClick = (data, value) => {
        const result = {
            eventId: data.id,
            result: value
        };

        props.onClick(result);
    }

    const printEvent = (event) => {
        const subEvents = events.filter(fe => fe.parentId === event.id && fe.isVideo === true);
        if (subEvents) {
            return (
                <div key={event.id} className={classes.eventGroup}>
                    {event.haveSwitch
                        ? <div className={cn(classes.eventItem, event.haveSwitch)}>
                            {event.name}
                            {event.haveSwitch && <div className={classes.switch}>
                                <div onClick={() => onClick(event, true)} className={classes.green}></div>
                                <div onClick={() => onClick(event, false)} className={classes.red}></div>
                            </div>}
                        </div>
                        : <div onClick={() => onClick(event, null)} className={classes.eventItem}>
                            {event.name}
                        </div>
                    }

                    <div></div>
                    {subEvents.map(subEvent => printEvent(subEvent))}
                </div>
            );
        } else {
            return (<div key={event.id} onClick={() => onClick(event, null)} className={classes.eventItem}>{event.name}</div>);
        }
    }

    return (
        <div className={classes.eventsList}>
            <Button onClick={props.onClose} type="primary">Close</Button>
            {eventCategories.map(category => {
                return (
                    <div key={category.id} className={classes.eventsCategory}>
                        <h3>{category.name}</h3>
                        <div className={classes.eventGroup}>
                            {events.filter(e => e.categoryId === category.id && e.isVideo === true && e.parentId === '00000000000000000000000000000000')
                                .map(event => printEvent(event)
                                )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

EventList.defaultProps = {
    onClick: () => {},
    onClose: () => {}
};

export default EventList;