import React, {useEffect, useState} from 'react';
import classes from './Select.module.scss'
import ReactSelect, {components} from 'react-select';
import {useTranslation} from "react-i18next";
import {haveDefaultValue, haveError} from "../../../utils/form";
import './Select.css'
import cn from 'classnames';

const Select = (props) => {
    const {t} = useTranslation();
    const htmlFor = `select-${Math.random()}`
    const [width, setWidth] = useState(window.innerWidth);
    const [small, setSmall] = useState(props.small);
    const [options, setOptions] = useState(props.options);

    const error = haveError(props);
    const defaultValue = haveDefaultValue(props);

    useEffect(() => {
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        }
    }, []);

    useEffect(() => {
        if (props.small === false)
            return;

        if (width < 700) setSmall(false);
        if (width >= 700) setSmall(true);
    }, [width]);

    useEffect(() => {
       if (!options || options.length === 0)
           return;

        const translatedOptions = options.map(option => ({...option, label: t(option.label)}));
    }, [options])

    const { Option } = components;
    const IconOption = props => (
        <Option {...props}>
            {props.data.icon && <span dangerouslySetInnerHTML={{__html: props.data.icon}}></span>}
            {props.data.label}
        </Option>
    );

    const onResize = (e) => {
        setWidth(window.innerWidth);
    }

    const selected = props.options?.find(element => {
        return element.value == defaultValue
    });

    const onRegularOptionChange = (selectedOption) => {
        props.onChange({
            target: {
                name: props.name,
                value: props.isMulti ? selectedOption.map(x => x.value) : selectedOption.value
            }
        });
    }
    const onSmallSelectChange = (event) => {
        props.onChange({
            target: {
                name:  props.name,
                value: event.target.value
            }
        });
    }

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            padding: classes.padding,
            borderColor: error ? classes.errorColor : classes.borderColor,
            boxShadow: 'none',
            minHeight: "auto",
            backgroundColor: props.disabled ? classes.disabledColor : classes.whiteColor,
            ':hover': { borderColor: classes.blueColor}
        }),
        singleValue: (provided, state) => ({
            ...provided,
            fontSize: classes.fontSize,
            lineHeight: classes.lineHeight,
            color: classes.blackColor
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: classes.fontSize,
            lineHeight: classes.lineHeight,
            color: state.isSelected ? classes.primaryColor :classes.blackColor,
            backgroundColor: classes.whiteColor
        }),
        placeholder: (defaultStyles) => {
            return {
                ...defaultStyles,
                fontSize: classes.fontSize,
                color: classes.blackColor
            }
        },
       menuPortal: base => ({ ...base, zIndex: 9999 })
    }

    if (small) {
        return (
            <div style={props.smallStyle} className={cn(classes.select, classes.small, props.className)}>
                {(props.label || defaultValue !== null) && <label htmlFor={htmlFor}>
                    {t(props.label || props.placeholder)} :
                </label>  }
                <select
                    onChange={onSmallSelectChange}
                    value ={defaultValue}
                    disabled={props.disabled}>

                    {props.options.map((item) => (
                        <option key={props.name+"_"+item.value} value={item.value}>{item.label}</option>
                    ))}

                </select>
            </div>
        );
    }

    return (
        <div className={classes.select}>

            {(props.label || defaultValue !== null) && <label htmlFor={htmlFor}>
                {props.label || props.placeholder} </label> }

            <ReactSelect
                id={htmlFor}
                styles={customStyles}
                value={selected}
                isDisabled={props.disabled}
                isMulti={props.isMulti}
                classNamePrefix="rselect"
                onChange={onRegularOptionChange}
                options={props.options}
                menuPortalTarget={document.body}
                placeholder={<div className="select-placeholder-text">{t(props.placeholder)}</div>}
                components={{
                    IndicatorSeparator: () => null,
                    Option: IconOption
                }} />

            { error &&  <span className="errorMessage">{error || t('Required')}</span> }
        </div>
    )
}

Select.defaultProps = {
    small: false,
    smallStyle: {},
    isMulti: false
}

export default Select
