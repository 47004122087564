import React, {useState} from 'react';
import Input from 'components/UI/Input/Input';
import {useTranslation} from 'react-i18next';
import {notHaveErrors, validateFields, validateForm} from 'utils/form';
import * as yup from 'yup';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';

const StreamForm = (props) => {
    const [values, setValues] = useState({});
    const [errors, setErrors] = useState({});

    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const fieldChanged = (event) => {
        const newValues = { ...values };
        newValues[event.target.name] = event.target.value

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });

        setValues(newValues);
    }

    const validationRules = {
        link: yup.string().required(t("required")).url(t("required")),
        name: yup.string().required(t("required")),
    }

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors
    };

    const formSubmitted = (e) => {
        e.preventDefault();
        validateForm(values, validationRules, (errors) => {
            if (notHaveErrors(errors))
                saveData();
            else
                setErrors(errors);
        });
    };

    const saveData = () => {
        const variables = {
            input: {
                teamId: navState.teamId,
                clubId: navState.clubId,
                link: values.link,
                name: values.name,
            }
        }

        props.onComplete(variables);
    };

    return (
        <form id={props.id} onSubmit={formSubmitted}>
            <Input
                {...regField}
                name="link"
                label={t('Url')}
            />

            <Input
                {...regField}
                name="name"
                label={t('Name')}
            />
        </form>
    );
};

StreamForm.defaultProps = {
    defaultValues: {},
    onComplete: () => {
    }
};

export default StreamForm;
