import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import classes from './TeamList.module.scss';
import Card from 'components/UI/Card/Card';
import {useReactiveVar} from '@apollo/client';
import cn from 'classnames';
import Panel from 'components/UI/Panel/Panel';
import Pagination from 'components/UI/Pagination/Pagination';
import {Link, useParams} from 'react-router-dom';
import Empty from 'components/UI/Empty/Empty';
import {UuidTOGuid} from 'utils/utils';
import {naviDataVar} from 'apollo/cashe';
import {menu} from 'components/Navigation/Menu/Settings';
import TeamForm from './forms/TeamForm';
import Button from 'components/UI/Button/Button';
import {useClubDetails, useTeams} from 'apollo/queries/ClubQueries';
import Search from 'components/UI/Panel/Search/Search';
import Submenu from 'components/Navigation/Submenu/Submenu';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import CanAccess from 'components/Navigation/CanAccess/CanAccess';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';

const TeamList = () => {
  const {t} = useTranslation();

  let { clubId } = useParams();

  const navState = useReactiveVar(naviDataVar);

  const onPage = 50;

  const [skip, setSkip] = useState(0);

  const [search, setSearch] = useState('');
  const [modalData, setModalData, initState] = ModalState();

  const [getClubData, clubData] = useClubDetails();
  const [getTeamData, teamsData] = useTeams();

  useEffect(() => {
    if (!clubId)
      return;

    getClubData({variables: {clubId: clubId}});
    getTeamData({variables: {clubId: clubId, search: search, take: onPage, skip: skip * onPage}});

  }, [clubId, search, skip, getClubData, getTeamData]);

  const onComplete = (data) => {
    setModalData(initState);
    clubData.refetch();
    teamsData.refetch();
  }

  const Header = ({data}) => {
    if (!data.club) return(<>No data...</>);

    const {type, name, country, logo} = data.club;
    const flag = `/assets/flags/${country.toLowerCase()}.png`;

    return (
        <div className={classes.club}>
          <div className={classes.logo}><img src={logo} alt={name} /></div>
          <div>
            <div className={classes.type}>{type}</div>
            <h2>{name}</h2>
            <div className={classes.country}><img src={flag} alt={country} /></div>
            <CanAccess users={[SystemRoles.SysAdmin, SystemRoles.ClubAdmin]}>
              <ul className={classes.actions}>
                <li>
                  <Button type="third" onClick={onEditClub}><i className="icon-Setting" />{t("Edit club")}</Button>
                </li>
              </ul>
            </CanAccess>
          </div>
        </div>
    );
  }

  const onEditClub = () => {
    naviDataVar({
      ...navState,
      settings: {
        open: true,
        chapter: 'club'
      }
    });
  }

  const Info = ({data}) => {
    if (!data.club) return(<></>);

    const {teamCount, trainerCount, playerCount, playerWithTeamCount, playerWithoutTeamCount, playerWithoutMedicineCount}
        = data.club;
    return (
        <div className={classes.info}>
          <Card name={t("Teams")} value={teamCount} icon={<i className="icon-Team" />} />
          <Card name={t("Coaches")} value={trainerCount} icon={<i className={cn("icon-whistle", classes.green)} />} />
          <Card name={t("Players")} value={playerCount} icon={<i className={cn("icon-Players", classes.yellow)} />} />
          <Card name={t("With team")} value={playerWithTeamCount} icon={<i className="icon-happy" />} />
          <Card name={t("Without team")} value={playerWithoutTeamCount} icon={<i className="icon-sad" />} />
          <Card name={t("Without med check")} value={playerWithoutMedicineCount} icon={<i className="icon-medicine" />} />
        </div>
    );
  }

  const onAddClick = () => {
    const _data = {
      ...initState,
      title: t("Add team"),
      styles: {
        maxWidth: "700px",
      },
      onComplete: onComplete,
      open: true
    }

    setModalData(_data);
  }

  const onTeamClick = (team) => {
    const item = menu.team.items[0];
    naviDataVar({
      ...navState,
      teamId: team.teamId,
      teamName: team.name,
      selected: {
        id:  item.id,
        path: item.path,
        type: item.type,
        hash: item.hash,
        items: item.submenu,
        submenu: {
          id: item.submenu[0] ? item.submenu[0].id : null
        }
      }
    });
  }

  const onSearch = (e) => {
    setSkip(0);
    setSearch(e.target.value);
  }

  const panelSearch = () => (
      <Search onSearch={onSearch} placeholder={t("Search team...")} />
  );

  const TeamRow = ({data}) => {

    if (data.length === 0) return (<Empty onAddClick={onAddClick} />);

    return data.map((item) => {
      const id = UuidTOGuid(item.teamId);
      const team = `/club-${clubId}/team-${id}`;
      const attendance = `/club-${clubId}/team-${id}/attendance-trainings`;
      const trainers = item.trainers.map((trainer) => trainer.firstName + " " + trainer.lastName);

      return (
          <div key={item.teamId}>
            <div className={classes.team}>
              <div className={classes.title}>
                <Link to={team} onClick={() => onTeamClick(item)}>{item.name}</Link>
              </div>
              <div>
                {item.playerCount}
                <div>{t("Players")}</div>
              </div>
              <div>
                {item.games}
                <div>{t("Matches")}</div>
              </div>
              <div>
                {item.wins}
                <div>{t("Wins")}</div>
              </div>
              <div>
                {item.draws}
                <div>{t("Draws")}</div>
              </div>
              <div>
                {item.losses}
                <div>{t("Losses")}</div>
              </div>
              <div>
                {item.goals}
                <div>{t("Goals")}</div>
              </div>
              <div className={classes.attendance}>
                <a href={attendance} title={t("Attendance")}><i className="icon-Time-Square"> </i></a>
              </div>
            </div>
            <div className={classes.trainers}>
              <span>{t("Coaches")}:&nbsp;</span>
              {item.trainers.length !== 0 ? trainers.join(", ") : <span>-</span>}
            </div>
          </div>
      );
    });
  }



  const actions = () => {
    return (
      <CanAccess users={[SystemRoles.SysAdmin, SystemRoles.ClubAdmin]}>
        <Button type="primary" style={{margin: 0}} onClick={onAddClick}>
          <span><i className="icon-Plus-Square"></i> {t("Add team")}</span>
        </Button>
      </CanAccess>
    );
  }

  // const filters = () => {
  //   const uploadTeam = `/Club/ImportClub/${navState.clubId}`;
  //
  //   return (
  //       <>
  //         <a href={uploadTeam}
  //            className={cn(buttonClasses.Button, buttonClasses.third)}
  //            style={{margin: "0 0 0 20px"}}>
  //           <span><i className="icon-Upload1"> </i> {t("Upload teams")}</span>
  //         </a>
  //       </>
  //   )
  // }

  return (
      <>
        <div className="page-header">
          {clubData.loading && <>Loading...</>}
          {clubData.error && <>Error...</>}
          {clubData.data ? <>
            <Header data={clubData.data} />
            <Info data={clubData.data} />
          </> : undefined }
          <Submenu/>
        </div>
        <div className="page-content">
          <Panel border={false} action={actions} search={panelSearch} />

          {teamsData.loading && <>Loading...</>}
          {teamsData.error && <>Error...</>}
          {teamsData.data ?
              <>
                <TeamRow data={teamsData.data.teams.items} />
                <Pagination page={skip} onP count={teamsData.data.teams.totalCount} onPage={onPage} onPageChange={(p) => setSkip(p.selected)}/>
              </>
              : undefined }
        </div>

        <ModalWindow2 {...modalData}>
          <TeamForm {...modalData} />
        </ModalWindow2>
      </>
  );
};

export default TeamList;
