import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {notHaveErrors, validateFields, validateForm} from 'utils/form';
import * as yup from 'yup';
import {createGuid} from 'utils/utils';
import classes from 'containers/Club/TeamList/forms/TeamForm.module.scss';
import Input from 'components/UI/Input/Input';
import ImageUpload from 'components/UI/ImageUpload/ImageUpload';
import Select from 'components/UI/Select/Select';
import {useCountrySettings} from 'apollo/queries/SettingQueries';
import {useReactiveVar} from '@apollo/client';
import {systemDataVar} from 'apollo/cashe';
import {useSaveClub} from 'apollo/queries/ClubQueries';
import {useAddClubToGroup} from 'apollo/queries/GroupQueries';
import {fileUploader} from 'utils/upload';
import {useAuth} from 'react-oidc-context';

const NewClubForm = (props) => {
    const {setLoading} = props;
    const auth = useAuth();

    const {t} = useTranslation();
    const {groupId} = useParams();
    const systemData = useReactiveVar(systemDataVar);

    const [image, setImage] = useState(null);
    const [errors, setErrors] = useState({});
    const [values, setValues] = useState(props.defaultValues);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);

    const [getCountrySettings, countrySettingsData] = useCountrySettings();
    const [saveClub] = useSaveClub();
    const [addClubToGroup] = useAddClubToGroup();

    useEffect(() => {
        getCountrySettings();
    }, []);

    useEffect(() => {
        if (!countrySettingsData.data) return;

        const countryList = countrySettingsData.data.country.map(x => ({label: x.value, value: x.key}));
        setCountries(countryList);

        const stateList = countrySettingsData.data.state.map(x => {
            const json =  JSON.parse(x.jsonAttributes);
            return {label: x.value, value: x.key, key: json[0]?.Value ?? ""};
        });
        setStates(stateList);
    }, [countrySettingsData.data]);

    const fieldChanged = (event) => {
        const newValues = { ...values };
        newValues[event.target.name] = event.target.value;

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });

        if (event.target.file !== undefined) {
            newValues[event.target.name+"File"] = event.target.file;
        }
        setValues(newValues);
    }

    const validationRules = {
        name: yup.string().required(t("required")),
        clubFormat: yup.string().required(t("required")),
        //language: yup.string().required(t("required")),
        country: yup.string().required(t("required")),
        city: yup.string().required(t("required")),
    }

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors
    };

    const onImageChanged = (e) => {
        setImage(e);
    }

    useEffect(() => {
        if (!image) return;
        fieldChanged(image);
    }, [image]);

    const formSubmitted = async (e) => {
        e.preventDefault();
        setLoading(true);
        validateForm(values, validationRules, (errors) => {
            if (notHaveErrors(errors)) {
                saveData();
            } else {
                setLoading(false);
                setErrors(errors);
            }
        });
    };

    const saveData = async () => {
        const variables = {
            input: {
                clubId: values.clubId ?? createGuid(),
                name: values.name,
                registrationNumber: values.registrationNumber,
                clubFormat: values.clubFormat,
                language: values.language ? values.language.toUpperCase() : undefined,
                timeZone: values.timeZone,
                dateFormat: values.dateFormat,
                timeFormat: values.timeFormat,
                country: values.country,
                states: values.states,
                city: values.city,
            }
        }

        if (values.logoFile !== undefined) {
            const fileData = {
                clubId: variables.input.clubId,
                fileId: variables.input.clubId
            }

            if (await fileUploader(values.logoFile, fileData, auth)) {
                variables.input.logoUploadDate = new Date().toISOString();
            }
        }

        await saveClub({variables: variables});

        if (groupId && values.clubId === undefined) {
            await addClubToGroup({variables: {input: {groupId: groupId, clubIds: [variables.input.clubId]}}});
        }

        props.onComplete();
    };

    return (
      <>
          <form id={props.id} onSubmit={formSubmitted}>
              <div className={classes.columns21}>
                  <div>
                      <Input
                        name="name"
                        label={t('Club name')}
                        {...regField}
                      />

                      <div className={classes.columns11}>
                          <Input
                            name="registrationNumber"
                            label={t('Registration Number')}
                            {...regField}
                          />

                          <Select
                            name="clubFormat"
                            {...regField}
                            options={props.format}
                            label={t('Format')}
                          />
                      </div>



                      <div className={classes.title}>{t("Language and time")}</div>
                      <div className={classes.columns11}>
                          <Select
                            name="language"
                            options={systemData.languages ?? []}
                            {...regField}
                            label={t('Language')}
                          />

                          <Select
                            name="timeZone"
                            {...regField}
                            options={props.timeZone}
                            label={t('Time zone')}
                          />

                          <Select
                            name="dateFormat"
                            {...regField}
                            options={props.dateFormat}
                            label={t('Date format')}
                          />

                          <Select
                            name="timeFormat"
                            {...regField}

                            options={props.timeFormat}
                            label={t('Time format')}
                          />
                      </div>

                      <div className={classes.title}>{t("Address")}</div>
                      <div className={classes.columns11}>
                          <Select
                            name="country"
                            {...regField}
                            options={countries}
                            label={t('Country')}
                          />

                          {states && states.filter(x => x.key === values?.country).length > 0 ?
                            <Select
                              name="state"
                              {...regField}
                              label={"State"}
                              options={states.filter(x => x.key === values?.country)}
                              label={t('State / region')}
                            />
                            : undefined }
                      </div>

                      <Input
                        name="city"
                        label={t('Address')}
                        {...regField}
                      />


                  </div>
                  <div>
                      <ImageUpload
                        name="logo"
                        label={t('Group logo')}
                        text="Add group logo"
                        {...regField}
                        onChange={onImageChanged}
                      />
                  </div>
              </div>
          </form>
      </>
    );
};

NewClubForm.defaultProps = {
    format: [
        {label: "Club", value: "NONE"},
        {label: "Private Club", value: "COMMERCIAL_CLUB"},
        {label: "School", value: "SPORT_SCHOOL"},
        {label: "Academy", value: "FOOTBALL_ACADEMY"},
        {label: "Other", value: "OTHER"},
    ],
    timeZone: [
        { value:"default", label:"Browser Default"},
        { value:"Africa/Casablanca", label:"(GMT) Casablanca"},
        { value:"Europe/London", label:"(GMT) Greenwich Mean Time ː Dublin, Edinburgh, Lisbon, London"},
        { value:"Atlantic/Reykjavik", label:"(GMT) Monrovia, Reykjavik"},
        { value:"Europe/Berlin", label:"(GMT+01ː00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"},
        { value:"Europe/Prague", label:"(GMT+01ː00) Belgrade, Bratislava, Budapest, Ljubljana, Prague"},
        { value:"Europe/Paris", label:"(GMT+01ː00) Brussels, Copenhagen, Madrid, Paris"},
        { value:"Europe/Warsaw", label:"(GMT+01ː00) Sarajevo, Skopje, Warsaw, Zagreb"},
        { value:"Europe/Warsaw", label:"(GMT+01ː00) West Central Africa"},
        { value:"Asia/Amman", label:"(GMT+02ː00) Amman"},
        { value:"Europe/Athens", label:"(GMT+02ː00) Athens, Bucharest, Istanbul"},
        { value:"Asia/Beirut", label:"(GMT+02ː00) Beirut"},
        { value:"Africa/Cairo", label:"(GMT+02ː00) Cairo"},
        { value:"Africa/Harare", label:"(GMT+02ː00) Harare, Pretoria"},
        { value:"Europe/Riga", label:"(GMT+02ː00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius"},
        { value:"Asia/Jerusalem", label:"(GMT+02ː00) Jerusalem"},
        { value:"Europe/Minsk", label:"(GMT+02ː00) Minsk"},
        { value:"Africa/Windhoek", label:"(GMT+02ː00) Windhoek"},
        { value:"Asia/Baghdad", label:"(GMT+03ː00) Baghdad"},
        { value:"Asia/Kuwait", label:"(GMT+03ː00) Kuwait, Riyadh"},
        { value:"Europe/Moscow", label:"(GMT+03ː00) Moscow, St. Petersburg, Volgograd"},
        { value:"Africa/Nairobi", label:"(GMT+03ː00) Nairobi"},
        { value:"Asia/Tbilisi", label:"(GMT+03ː00) Tbilisi"},
        { value:"Asia/Tehran", label:"(GMT+03ː30) Tehran"},
        { value:"Asia/Muscat", label:"(GMT+04ː00) Abu Dhabi, Muscat"},
        { value:"Asia/Baku", label:"(GMT+04ː00) Baku"},
        { value:"Indian/Mauritius", label:"(GMT+04ː00) Port Louis"},
        { value:"Asia/Yerevan", label:"(GMT+04ː00) Yerevan"},
        { value:"Asia/Kabul", label:"(GMT+04ː30) Kabul"},
        { value:"Asia/Yekaterinburg", label:"(GMT+05ː00) Ekaterinburg"},
        { value:"Asia/Karachi", label:"(GMT+05ː00) Islamabad, Karachi"},
        { value:"Asia/Tashkent", label:"(GMT+05ː00) Tashkent"},
        { value:"Asia/Kolkata", label:"(GMT+05ː30) Chennai, Kolkata, Mumbai, New Delhi"},
        { value:"Asia/Colombo Standard Time", label:"(GMT+05ː30) Sri Jayawardenepura"},
        { value:"Asia/Kathmandu", label:"(GMT+05ː45) Kathmandu"},
        { value:"Asia/Novosibirsk", label:"(GMT+06ː00) Almaty, Novosibirsk"},
        { value:"Asia/Dhaka", label:"(GMT+06ː00) Astana, Dhaka"},
        { value:"Asia/Yangon", label:"(GMT+06ː30) Yangon (Rangoon)"},
        { value:"Asia/Bangkok", label:"(GMT+07ː00) Bangkok, Hanoi, Jakarta"},
        { value:"Asia/Krasnoyarsk", label:"(GMT+07ː00) Krasnoyarsk"},
        { value:"Asia/Chongqing", label:"(GMT+08ː00) Beijing, Chongqing, Hong Kong, Urumqi"},
        { value:"Asia/Irkutsk", label:"(GMT+08ː00) Irkutsk, Ulaan Bataar"},
        { value:"Asia/Singapore", label:"(GMT+08ː00) Kuala Lumpur, Singapore"},
        { value:"Australia/Perth", label:"(GMT+08ː00) Perth"},
        { value:"Asia/Taipei", label:"(GMT+08ː00) Taipei"},
        { value:"Asia/Tokyo", label:"(GMT+09ː00) Osaka, Sapporo, Tokyo"},
        { value:"Asia/Seoul", label:"(GMT+09ː00) Seoul"},
        { value:"Asia/Yakutsk", label:"(GMT+09ː00) Yakutsk"},
        { value:"Australia/Adelaide", label:"(GMT+09ː30) Adelaide"},
        { value:"Australia/Darwin", label:"(GMT+09ː30) Darwin"},
        { value:"Australia/Brisbane", label:"(GMT+10ː00) Brisbane"},
        { value:"Australia/Canberra", label:"(GMT+10ː00) Canberra, Melbourne, Sydney"},
        { value:"Pacific/Guam", label:"(GMT+10ː00) Guam, Port Moresby"},
        { value:"Australia/Hobart", label:"(GMT+10ː00) Hobart"},
        { value:"Asia/Vladivostok", label:"(GMT+10ː00) Vladivostok"},
        { value:"Asia/Magadan", label:"(GMT+11ː00) Magadan, Solomon Is., New Caledonia"},
        { value:"Pacific/Auckland", label:"(GMT+12ː00) Auckland, Wellington"},
        { value:"Pacific/Fiji", label:"(GMT+12ː00) Fiji, Kamchatka, Marshall Is."},
        { value:"Pacific/Tongatapu", label:"(GMT+13ː00) Nuku'alofa"},
        { value:"Atlantic/Azores", label:"(GMT-01ː00) Azores"},
        { value:"Atlantic/Cape_Verde", label:"(GMT-01ː00) Cape Verde Is."},
        { value:"America/Argentina/Buenos_Aires", label:"(GMT-03ː00) Buenos Aires"},
        { value:"America/Guyana", label:"(GMT-03ː00) Georgetown"},
        { value:"America/Barbados", label:"(GMT-03ː00) Greenland"},
        { value:"America/Montevideo", label:"(GMT-03ː00) Montevideo"},
        { value:"Canada/Newfoundland", label:"(GMT-03ː30) Newfoundland"},
        { value:"America/Toronto", label:"(GMT-04ː00) Atlantic Time (Canada)"},
        { value:"America/La_Paz", label:"(GMT-04ː00) La Paz"},
        { value:"America/Manaus", label:"(GMT-04ː00) Manaus"},
        { value:"America/Santiago", label:"(GMT-04ː00) Santiago"},
        { value:"America/Caracas", label:"(GMT-04ː30) Caracas"},
        { value:"America/Bogota", label:"(GMT-05ː00) Bogota, Lima, Quito, Rio Branco"},
        { value:"America/New_York", label:"(GMT-05ː00) Eastern Time (US & Canada)"},
        { value:"America/New_York", label:"(GMT-05ː00) Indiana (East)"},
        { value:"America/Monterrey", label:"(GMT-06ː00) Central America"},
        { value:"America/Monterrey", label:"(GMT-06ː00) Central Time (US & Canada)"},
        { value:"America/Monterrey", label:"(GMT-06ː00) Guadalajara, Mexico City, Monterrey"},
        { value:"Canada/Saskatchewan", label:"(GMT-06ː00) Saskatchewan"},
        { value:"America/Phoenix", label:"(GMT-07ː00) Arizona"},
        { value:"America/Chihuahua", label:"(GMT-07ː00) Chihuahua, La Paz, Mazatlan"},
        { value:"America/Phoenix", label:"(GMT-07ː00) Mountain Time (US & Canada)"},
        { value:"America/Los_Angeles", label:"(GMT-08ː00) Pacific Time (US & Canada)"},
        { value:"America/Tijuana", label:"(GMT-08ː00) Tijuana, Baja California"},
        { value:"US/Alaska", label:"(GMT-09ː00) Alaska"},
        { value:"US/Hawaii", label:"(GMT-10ː00) Hawaii"},
        { value:"US/Samoa", label:"(GMT-11ː00) Midway Island, Samoa"}
    ],
    dateFormat: [
        {label: 'DD.MM.YYYY', value: 'dd.MM.yyyy'},
        {label: 'DD-MM-YYYY', value: 'dd-MM-yyyy'},
        {label: 'MM-DD-YYYY', value: 'MM-dd-yyyy'},
        {label: 'YYYY-DD-MM', value: 'yyyy-dd-MM'}
    ],
    timeFormat: [
        {label: '13ː00', value: 'HH:mm'},
        {label: '01ː00 PM', value: 'hh:mm aa'},
    ],
};

export default NewClubForm;
