import {
    format,
    add,
    endOfMonth,
    endOfYear,
    parseISO,
    startOfMonth,
    startOfYear,
    getWeek,
    getUnixTime,
    fromUnixTime,
    isValid,
    isSameDay,
    differenceInYears,
    startOfDay,
    endOfDay,
    startOfISOWeek,
    endOfISOWeek,
    isSameMonth,
    isSameYear, getDaysInMonth, addMinutes, formatISO, parse
} from 'date-fns';
import {formatInTimeZone} from 'date-fns-tz';

export const dateToServer = (date) => {
    const dateWithoutTime = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );

    return dateWithoutTime.toISOString();
}

export const dateTimeToServer = (date) => {
    return date.toISOString();
}

export const dayStart = (date = new Date()) => {
    return startOfDay(date);
}

export const dayEnd = (date = new Date()) => {
    return endOfDay(date);
}

export const firstDayOfWeek = (date = new Date()) => {
    return startOfISOWeek(date);
}

export const lastDayOfWeek = (date = new Date()) => {
    return endOfISOWeek(date);
}

export const currentDayOfWeek = (date = new Date()) => {
    return format(date, 'i');
}

export const firstDayOfMonth = (date = new Date()) => {
    return startOfMonth(date);
}

export const lastDayOfMonth = (date = new Date()) => {
    return endOfMonth(date);
}

export const firstDayOfYear = (date = new Date()) => {
    return startOfYear(date);
}

export const lastDayOfYear = (date = new Date()) => {
    return endOfYear(date);
}

export const nextHour = (date = new Date()) => {
    const next = add(date, {
        hours: 1
    });

    return format(next, 'H');
}

export const getDate = (date) => {
    return parseISO(date);
}

export const today = () => {
    return new Date();
}

export const todayMinus14 = () => {
    return add(new Date(), {
        days: -14
    });
}

export const todayPlus14 = () => {
    return add(new Date(), {
        days: 14
    });
}

export const toMSS = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
}

export const isValidDate = (date) => {
    const parsedDate = parseISO(date)
    return isValid(parsedDate);
}

export const showDate = (date, settings) => {

    if (!isValid(date)) {
        date = parseISO(date);
    }

    if (settings === undefined) {
        return format(date, 'dd.MM.yyyy');
    }

    if (settings !== undefined && settings.timeZone === 'default') {
        return format(date, settings.dateFormat);
    }

    return formatInTimeZone(date, settings.timeZone, settings.dateFormat)
}

export const showTime = (date, settings) => {
    if (!isValid(date)) {
        date = parseISO(date);
    }

    if (!isValid(date)) {
        date = new Date();
    }

    if (settings === undefined) {
        return format(date, 'HH:mm');
    }

    if (settings !== undefined && settings.timeZone === 'default') {
        return format(date, settings.timeFormat);
    }

    return formatInTimeZone(date, settings.timeZone, settings.timeFormat)
}


export const showDateHours = (date, settings) => {
    if (date === undefined) {
        return;
    }

    return `${showDate(date, settings)} ${showTime(date, settings)}`;
}

export const dayNumber = (date) => {
    return format(date, 'd');
}

export const showPeriod = (date1, date2) => {
    if (!isValid(date1)) {
        date1 = parseISO(date1);
    }

    if (!isValid(date2)) {
        date2 = parseISO(date2);
    }

    let show = format(date1, "dd ");
    if (!isSameMonth(date1, date2)) {
        show +=  format(date1, "MMM ");
    }

    if (!isSameYear(date1, date2)) {
        show +=  format(date1, "yyyy ")
    }

    show += format(date2, "- dd MMM yyyy ");

    return show;
}

export const showMonth = (date) => {
    if (date === undefined) {
        return;
    }

    if (!isValid(date)) {
        date = parseISO(date);
    }
    return format(date, 'MMM yyyy');
}

export const monthNumber = (date) => {
    if (date === undefined) {
        return;
    }

    if (!isValid(date)) {
        date = parseISO(date);
    }
    return format(date, 'M');
}

export const showYear = (date) => {
    if (date === undefined) {
        return;
    }

    if (!isValid(date)) {
        date = parseISO(date);
    }
    return format(date, 'yyyy');
}

export const daysInMonth = (date) => {
    return getDaysInMonth(date);
}

export const toDateTime = (date) => {
    return format(date, 'YYYY-MM-DD');
}



export const showFormat = (date, format) => {
    const parsedDate = parseISO(date)
    return format(parsedDate, format);
}

export const parseDataUtc = (date) => {
    return parseISO(date);
}

export const validDate = (date, tz = 'Europe/Riga') => {
    if (date === undefined) {
        return;
    }

    if (!isValid(date)) {
        date = parseISO(date);
    }

    return date.toISOString();
}

export const inThisWeek = (date) => {
    const parsedDate = parseISO(date);
    const dateWeek = getWeek(parsedDate);
    const currentWeek = getWeek(new Date());
    return dateWeek === currentWeek;
}

export const showNumberAsTime = (num) => {
    const time = isNaN(num) === false ? num : 0;
    const timeArray = time.toString().split('.');
    const hours = String(timeArray[0] ?? 0).padStart(2, '0');
    const minutes = String(timeArray[1] ?? 0).padStart(2, '0');
    return `${hours}:${minutes}`;
}

export const dateAsTimeNumber = (date) => {
    if (!isValid(date)) {
        return 0;
    }

    return format(date, 'H.mm');
}

export const dateAsTimestamp = (date) => {
    const parsedDate = parseISO(date);
    return getUnixTime(parsedDate);
}

export const timestampAsDate = (timestamp) => {
    const date = fromUnixTime(timestamp);
    return format(date, 'dd.MM.yyyy');
}

export const timestampAsUtc = (timestamp) => {
    const date = fromUnixTime(timestamp);
    return date.toISOString();
}

export const isSameDate = (date1, date2) => {
    if (!isValid(date1)) {
        date1 = parseISO(date1);
    }

    if (!isValid(date2)) {
        date2 = parseISO(date2);
    }

    return isSameDay(date1, date2);
}

export const difDatesYears = (date1, date2) => {
    if (!isValid(date1)) {
        date1 = parseISO(date1);
    }

    if (!isValid(date2)) {
        date2 = parseISO(date2);
    }

    return differenceInYears(date2, date1);
}

export const importDate = (date) => {
    console.log(date);
    const parsedDate = parse(date, 'yyyy-mm-dd', new Date());
    if (isValid(parsedDate)) {
        return parsedDate.toISOString();
    }
    console.log(parsedDate);
    return "";
}




