import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import Timeline from 'components/UI/Timeline/TImeline';
import classes from './ClubTimeline.module.scss';
import Button, {ButtonLink} from 'components/UI/Button/Button';
import Submenu from 'components/Navigation/Submenu/Submenu';
import {isSameDate, showDate, showTime} from 'utils/date';
import {useNavigate} from 'react-router-dom';
import {naviDataVar, systemDataVar} from 'apollo/cashe';
import {useReactiveVar} from '@apollo/client';
import {useLazyClubTrainings} from 'apollo/queries/ClubQueries';

const ClubTimeline = () => {
  const {t} = useTranslation();
  const history = useNavigate();
  const navState = useReactiveVar(naviDataVar);
  const systemData = useReactiveVar(systemDataVar);

    const onOpen = (teamId, item) => {
        naviDataVar({
            ...navState,
            teamId: teamId,
            selected: {
                id: 't2',
                hash: '#training-player-list',
                type: 1,
                items: [],
                submenu: {
                    id: null,
                    type: null
                }
            }
        });

        history(`/club-${navState.clubId}/team-${teamId}/training-${item.id}`);
    }

    const tooltip = (teamId, item) => {
        const color = statusColors[item.trainingStatus];
        const link = `/club-${navState.clubId}/team-${teamId}/training-${item.id}`;

        const start = item.start;
        const end = item.end;

        let date = '<b>' + showTime(start, systemData.settings.general) + ' - ' + showTime(end, systemData.settings.general) + '</b>'
            + ' <span>' + showDate(start, systemData.settings.general) + '</span>';

        if (!isSameDate(start, end))
          date = '<b>' + showTime(start, systemData.settings.general)+ ' <span>' + showDate(start, systemData.settings.general)
              + '</span>  - ' + showTime(end, systemData.settings.general) + '</b>'
              + ' <span>' + showDate(end, systemData.settings.general) + '</span>';

        return (
            <div className={classes.tooltipContent}>
              <span className={classes.dot} style={{background: color}}></span>
              <span className={classes.title}>{item.name}</span>
              <div className={classes.dates} dangerouslySetInnerHTML={{__html: date}}/>
              <div className={classes.location}>{t('Location')}: {item.venue}</div>
              <Button onClick={() => onOpen(teamId, item)} type="third">{t('Show training')}</Button>
              <ButtonLink href={link} target="_blank" type="third">{t('Open new window')}</ButtonLink>
            </div>
        );
  };

  const statusColors = {
      ACCEPTED: '#3BCE9D',
      IN_REVIEW: '#FFC31F',
      NEED_REMAKE: '#F8571B',
      NO_PLAN: '#F8571B'
  };

  const parseData = (data) => data && data.clubTeamsTrainings;

  return (
      <>
        <div className="page-header">
          <h2>{t('Club trainings')}</h2>
          <Submenu/>
        </div>
        <div className="page-content">
          <Timeline
              lazyData={useLazyClubTrainings}
              parseData={parseData}
              tooltip={tooltip}
              colors={statusColors}/>
        </div>
      </>
  );
};

export default ClubTimeline;
