import React, {useEffect, useState} from 'react';
import Panel from 'components/UI/Panel/Panel';
import VideoList from '../MatchesDetails/VideoList';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import {useVideosLazy} from 'apollo/queries/GameQueries';
import Select from 'components/UI/Select/Select';
import Search from 'components/UI/Panel/Search/Search';

const MatchesVideoList = (props) => {
    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const [filters, setFilters] = useState(props.filters);
    const [skip, setSkip] = useState(0);

    const [getVideosLazy, videosData]  = useVideosLazy();


    useEffect(() => {
        if (!navState.clubId || !navState.teamId) {
            return;
        }

        const variables = {
            clubId: navState.clubId,
            teamId: navState.teamId,
            type: "GAME",
            where: {
                title: {contains: filters.search}
            },
            skip: skip * 10,
        }

        if (filters.scope) {
            variables.where.scope = {eq: filters.scope}
        }

        if (props.userMode) {
            variables.where.players = {some: {playerId: {eq: navState.playerId}}}
        }

        getVideosLazy({variables});

    }, [navState, filters, skip]);

    const onFilter = (e) => {
        const newFilters = {...filters};
        newFilters[e.target.name] = e.target.value;
        setFilters(newFilters);
    }

    const videoFilters = () => (
        <>
            <Select
                small="small"
                name="scope"
                value={filters}
                onChange={onFilter}
                options={props.scope}
                placeholder={t("Type")} />
        </>
    );

    const videoSearch = () => (
        <Search onSearch={onVideoSearch} placeholder={t("Search video...")} />
    );


    const onVideoSearch = (e) => {
        setSkip(0);
        onFilter({
            target: {
                name: "search",
                value: e.target.value
            }
        });
    }

    return (
        <>
            <h3>{t("Video")}</h3>
            <Panel border={false} filters={videoFilters} search={videoSearch}>
                <br/>
            </Panel>

            {videosData.loading ? <>Loading</> : undefined}
            {videosData.error ? <>Error</> : undefined}
            {videosData.data ? <VideoList data={videosData.data.videos} skip={skip} setSkip={setSkip} /> : undefined}
        </>
    );
};

MatchesVideoList.defaultProps = {
    scope: [
        {label: "All", value: "" },
        {label: "Competition", value: "COMPETITION" },
        {label: "Friendly game", value: "FRIENDLY_GAME" },
        {label: "Training", value: "TRAINING" },
    ],
    filters: {
        search: "",
        scope: ""
    }
};

export default MatchesVideoList;
