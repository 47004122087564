export const competitionSettings = {
    type: [
        {label: "Official Championship", value: "CHAMPIONSHIP"},
        {label: "Tournament", value: "TOURNAMENT"},
        {label: "Cup", value: "CUP"}
    ],
    achievements: [
        {label: "None", value: "NONE"},
        {label: "Winner", value: "WINNER"},
        {label: "Second", value: "SECOND"},
        {label: "Third", value: "THIRD"},
        {label: "4th", value: "FOURTH"},
        {label: "5th", value: "FIFTH"},
        {label: "6th", value: "SIXTH"},
        {label: "7th", value: "SEVENTH"},
        {label: "8th", value: "EIGHTH"},
        {label: "9th", value: "NINETH"},
        {label: "10th", value: "TENTH"},
        {label: "11th", value: "ELEVENTH"},
        {label: "12th", value: "TWELFTH"},
        {label: "13th", value: "THIRTEENTH"},
        {label: "14th", value: "FOURTEENTH"},
        {label: "15th", value: "FIFTEENTH"},
        {label: "16th", value: "SIXTEENTH"},
        {label: "Group stage failure", value: "GROUP_STAGE_FAILURE"},
    ]
}