import React, {useEffect, useState} from 'react';
import classes from '../Settings.module.scss';
import Select from 'components/UI/Select/Select';
import Button from 'components/UI/Button/Button';
import {useTranslation} from 'react-i18next';
import {notHaveErrors, validateFields, validateForm} from 'utils/form';
import * as yup from 'yup';
import {useEditSettings} from 'apollo/queries/SettingQueries';
import {useReactiveVar} from '@apollo/client';
import {systemDataVar} from 'apollo/cashe';
import i18n from 'i18next';
import Cookie from 'cookie-universal';
import {toastMessage} from 'components/UI/Toast/Toast';
import Switch from 'components/UI/Switch/Switch';

const General = (props) => {
  const {t} = useTranslation();
  const systemData = useReactiveVar(systemDataVar);

  console.log(systemData);

  const [values, setValues] = useState(systemData.settings.general);
  const [days, setDays] = useState(props.days);
  const [errors, setErrors] = useState({});
  const [languageChanged, setLanguageChanges] = useState(false);
  const [hasChanged, setHasChanges] = useState(false);

  const [editSettings, editSettingsData] = useEditSettings();

  useEffect(() => {
    const translatedDays = days.map((day) => {
      day.label = t(day.label);
      return day;
    });
    setDays(translatedDays);
  }, []);

  const fieldChanged = (event) => {
    const newValues = {...values};

    newValues[event.target.name] = event.target.value;

    validateFields(newValues, validationRules, (errors) => {
      setErrors(errors);
    });

    setValues(newValues);
    setHasChanges(true);
  };

  const validationRules = {
    language: yup.string().required(t('Required')),
    timeZone: yup.string().required(t('Required')),
    dateFormat: yup.string().required(t('Required')),
    timeFormat: yup.string().required(t('Required')),
    weekStart: yup.string().required(t('Required')),
    weekendStart: yup.string().required(t('Required')),
  };

  const regField = {
    value: values,
    onChange: fieldChanged,
    error: errors
  };

  const onLanguageChanged = (event) => {
    const newValues = {...values};
    newValues[event.target.name] = event.target.value;
    setValues(newValues);
    setLanguageChanges(true);
  }

  const onLanguageSave = () => {
    if (systemData.settings.general.language !== values.language) {
      const cookies = Cookie();
      cookies.set('locale', values.language, {domain: process.env.REACT_APP_TRANSLATES_COOKIE_DOMAIN});

      const newSystemState = {...systemData};
      newSystemState.settings.general.language = values.language;
      systemDataVar(newSystemState);

      i18n.changeLanguage(values.language);

      setLanguageChanges(false);
    }

    sendLanguageSettings();
  }

  const onSave = () => {
    validateForm(values, validationRules, async (errors) => {
      if (notHaveErrors(errors)) {
        sendDateSettings();
      } else {
        setErrors(errors);
      }
    });
  }

  const sendLanguageSettings = () => {
    const settings = {
      ...systemData.settings,
      general: {
        ...systemData.settings.general,
        language: values.language,
        suggestions: values.suggestions,
      }
    };

    delete settings.apiVersion;
    delete settings.isActor;

    editSettings({variables: {input: settings}}).then(() => {
      const newSystemState = {...systemData};
      newSystemState.settings = settings;
      systemDataVar(newSystemState);
      setLanguageChanges(false);
      toastMessage(true, t('Settings saved'));
    });
  };

  const sendDateSettings = () => {
    const settings = {
      ...systemData.settings,
      general: {
        ...systemData.settings.general,
        timeZone: values.timeZone,
        dateFormat: values.dateFormat,
        timeFormat: values.timeFormat,
        weekStart: values.weekStart,
        weekendStart: values.weekendStart
      }
    };

    delete settings.apiVersion;
    delete settings.isActor;

    editSettings({variables: {input: settings}}).then(() => {
      const newSystemState = {...systemData};
      newSystemState.settings = settings;
      systemDataVar(newSystemState);
      setHasChanges(false);
      toastMessage(true, t('Settings saved'));
    });
  };

  return (
      <>
        <div onClick={props.onBack} className={[classes.title, classes.link].join(" ")}>
          <i className={["icon-Arrow---Up", classes.backButton, "backArrow"].join(" ")} />
          {t("General")}
        </div>

        <Select
            name="language"
            value={values}
            options={systemData.languages ?? []}
            onChange={onLanguageChanged}
            placeholder={t('Language')}
        />

        <Switch
            name="suggestions"
            value={values}
            fullSize={true}
            onChange={onLanguageChanged}
            label={t('Enable translates suggestions')}
        />

        {languageChanged && <Button type="primary" onClick={onLanguageSave} style={{margin: 0}}>Save</Button>}
        {languageChanged && editSettingsData.loading && <div className={classes.loading}>{t("Saving... please wait.")}</div>}
        {languageChanged && editSettingsData.error && <div className={classes.error}>{t("Error, settings are not saved.")}</div>}

        <hr/>

        <>
          <div className={classes.title}>{t("Date and time")}</div>

          <Select
              name="timeZone"
              {...regField}
              options={props.timeZone}
              placeholder={t('Time zone')}
          />

          <Select
              name="dateFormat"
              {...regField}
              options={props.dateFormat}
              placeholder={t('Date format')}
          />

          <Select
              name="timeFormat"
              {...regField}

              options={props.timeFormat}
              placeholder={t('Time format')}
          />
          <hr/>

          <Select
              name="weekStart"
              {...regField}
              options={days}
              placeholder={t('Start of the week')}
          />

          <Select
              name="weekendStart"
              {...regField}
              options={days}
              placeholder={t('Start of the weekend')}
          />

          {hasChanged && <Button type="primary" onClick={onSave} style={{margin: 0}}>Save</Button>}
          {hasChanged && editSettingsData.loading && <div className={classes.loading}>{t("Saving... please wait.")}</div>}
          {hasChanged && editSettingsData.error && <div className={classes.error}>{t("Error, settings are not saved.")}</div>}
        </>
      </>
  );
};

General.propTypes = {};
General.defaultProps = {
  timeZone: [
    { value:"default", label:"Browser Default"},
    { value:"Africa/Casablanca", label:"(GMT) Casablanca"},
    { value:"Europe/London", label:"(GMT) Greenwich Mean Time ː Dublin, Edinburgh, Lisbon, London"},
    { value:"Atlantic/Reykjavik", label:"(GMT) Monrovia, Reykjavik"},
    { value:"Europe/Berlin", label:"(GMT+01ː00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"},
    { value:"Europe/Prague", label:"(GMT+01ː00) Belgrade, Bratislava, Budapest, Ljubljana, Prague"},
    { value:"Europe/Paris", label:"(GMT+01ː00) Brussels, Copenhagen, Madrid, Paris"},
    { value:"Europe/Warsaw", label:"(GMT+01ː00) Sarajevo, Skopje, Warsaw, Zagreb"},
    { value:"Europe/Warsaw", label:"(GMT+01ː00) West Central Africa"},
    { value:"Asia/Amman", label:"(GMT+02ː00) Amman"},
    { value:"Europe/Athens", label:"(GMT+02ː00) Athens, Bucharest, Istanbul"},
    { value:"Asia/Beirut", label:"(GMT+02ː00) Beirut"},
    { value:"Africa/Cairo", label:"(GMT+02ː00) Cairo"},
    { value:"Africa/Harare", label:"(GMT+02ː00) Harare, Pretoria"},
    { value:"Europe/Riga", label:"(GMT+02ː00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius"},
    { value:"Asia/Jerusalem", label:"(GMT+02ː00) Jerusalem"},
    { value:"Europe/Minsk", label:"(GMT+02ː00) Minsk"},
    { value:"Africa/Windhoek", label:"(GMT+02ː00) Windhoek"},
    { value:"Asia/Baghdad", label:"(GMT+03ː00) Baghdad"},
    { value:"Asia/Kuwait", label:"(GMT+03ː00) Kuwait, Riyadh"},
    { value:"Europe/Moscow", label:"(GMT+03ː00) Moscow, St. Petersburg, Volgograd"},
    { value:"Africa/Nairobi", label:"(GMT+03ː00) Nairobi"},
    { value:"Asia/Tbilisi", label:"(GMT+03ː00) Tbilisi"},
    { value:"Asia/Tehran", label:"(GMT+03ː30) Tehran"},
    { value:"Asia/Muscat", label:"(GMT+04ː00) Abu Dhabi, Muscat"},
    { value:"Asia/Baku", label:"(GMT+04ː00) Baku"},
    { value:"Indian/Mauritius", label:"(GMT+04ː00) Port Louis"},
    { value:"Asia/Yerevan", label:"(GMT+04ː00) Yerevan"},
    { value:"Asia/Kabul", label:"(GMT+04ː30) Kabul"},
    { value:"Asia/Yekaterinburg", label:"(GMT+05ː00) Ekaterinburg"},
    { value:"Asia/Karachi", label:"(GMT+05ː00) Islamabad, Karachi"},
    { value:"Asia/Tashkent", label:"(GMT+05ː00) Tashkent"},
    { value:"Asia/Kolkata", label:"(GMT+05ː30) Chennai, Kolkata, Mumbai, New Delhi"},
    { value:"Asia/Colombo Standard Time", label:"(GMT+05ː30) Sri Jayawardenepura"},
    { value:"Asia/Kathmandu", label:"(GMT+05ː45) Kathmandu"},
    { value:"Asia/Novosibirsk", label:"(GMT+06ː00) Almaty, Novosibirsk"},
    { value:"Asia/Dhaka", label:"(GMT+06ː00) Astana, Dhaka"},
    { value:"Asia/Yangon", label:"(GMT+06ː30) Yangon (Rangoon)"},
    { value:"Asia/Bangkok", label:"(GMT+07ː00) Bangkok, Hanoi, Jakarta"},
    { value:"Asia/Krasnoyarsk", label:"(GMT+07ː00) Krasnoyarsk"},
    { value:"Asia/Chongqing", label:"(GMT+08ː00) Beijing, Chongqing, Hong Kong, Urumqi"},
    { value:"Asia/Irkutsk", label:"(GMT+08ː00) Irkutsk, Ulaan Bataar"},
    { value:"Asia/Singapore", label:"(GMT+08ː00) Kuala Lumpur, Singapore"},
    { value:"Australia/Perth", label:"(GMT+08ː00) Perth"},
    { value:"Asia/Taipei", label:"(GMT+08ː00) Taipei"},
    { value:"Asia/Tokyo", label:"(GMT+09ː00) Osaka, Sapporo, Tokyo"},
    { value:"Asia/Seoul", label:"(GMT+09ː00) Seoul"},
    { value:"Asia/Yakutsk", label:"(GMT+09ː00) Yakutsk"},
    { value:"Australia/Adelaide", label:"(GMT+09ː30) Adelaide"},
    { value:"Australia/Darwin", label:"(GMT+09ː30) Darwin"},
    { value:"Australia/Brisbane", label:"(GMT+10ː00) Brisbane"},
    { value:"Australia/Canberra", label:"(GMT+10ː00) Canberra, Melbourne, Sydney"},
    { value:"Pacific/Guam", label:"(GMT+10ː00) Guam, Port Moresby"},
    { value:"Australia/Hobart", label:"(GMT+10ː00) Hobart"},
    { value:"Asia/Vladivostok", label:"(GMT+10ː00) Vladivostok"},
    { value:"Asia/Magadan", label:"(GMT+11ː00) Magadan, Solomon Is., New Caledonia"},
    { value:"Pacific/Auckland", label:"(GMT+12ː00) Auckland, Wellington"},
    { value:"Pacific/Fiji", label:"(GMT+12ː00) Fiji, Kamchatka, Marshall Is."},
    { value:"Pacific/Tongatapu", label:"(GMT+13ː00) Nuku'alofa"},
    { value:"Atlantic/Azores", label:"(GMT-01ː00) Azores"},
    { value:"Atlantic/Cape_Verde", label:"(GMT-01ː00) Cape Verde Is."},
    { value:"America/Argentina/Buenos_Aires", label:"(GMT-03ː00) Buenos Aires"},
    { value:"America/Guyana", label:"(GMT-03ː00) Georgetown"},
    { value:"America/Barbados", label:"(GMT-03ː00) Greenland"},
    { value:"America/Montevideo", label:"(GMT-03ː00) Montevideo"},
    { value:"Canada/Newfoundland", label:"(GMT-03ː30) Newfoundland"},
    { value:"America/Toronto", label:"(GMT-04ː00) Atlantic Time (Canada)"},
    { value:"America/La_Paz", label:"(GMT-04ː00) La Paz"},
    { value:"America/Manaus", label:"(GMT-04ː00) Manaus"},
    { value:"America/Santiago", label:"(GMT-04ː00) Santiago"},
    { value:"America/Caracas", label:"(GMT-04ː30) Caracas"},
    { value:"America/Bogota", label:"(GMT-05ː00) Bogota, Lima, Quito, Rio Branco"},
    { value:"America/New_York", label:"(GMT-05ː00) Eastern Time (US & Canada)"},
    { value:"America/New_York", label:"(GMT-05ː00) Indiana (East)"},
    { value:"America/Monterrey", label:"(GMT-06ː00) Central America"},
    { value:"America/Monterrey", label:"(GMT-06ː00) Central Time (US & Canada)"},
    { value:"America/Monterrey", label:"(GMT-06ː00) Guadalajara, Mexico City, Monterrey"},
    { value:"Canada/Saskatchewan", label:"(GMT-06ː00) Saskatchewan"},
    { value:"America/Phoenix", label:"(GMT-07ː00) Arizona"},
    { value:"America/Chihuahua", label:"(GMT-07ː00) Chihuahua, La Paz, Mazatlan"},
    { value:"America/Phoenix", label:"(GMT-07ː00) Mountain Time (US & Canada)"},
    { value:"America/Los_Angeles", label:"(GMT-08ː00) Pacific Time (US & Canada)"},
    { value:"America/Tijuana", label:"(GMT-08ː00) Tijuana, Baja California"},
    { value:"US/Alaska", label:"(GMT-09ː00) Alaska"},
    { value:"US/Hawaii", label:"(GMT-10ː00) Hawaii"},
    { value:"US/Samoa", label:"(GMT-11ː00) Midway Island, Samoa"}
  ],
  dateFormat: [
    {label: 'DD.MM.YYYY', value: 'dd.MM.yyyy'},
    {label: 'DD-MM-YYYY', value: 'dd-MM-yyyy'},
    {label: 'MM-DD-YYYY', value: 'MM-dd-yyyy'},
    {label: 'YYYY-DD-MM', value: 'yyyy-dd-MM'}
  ],
  timeFormat: [
    {label: '13ː00', value: 'HH:mm'},
    {label: '01ː00 PM', value: 'hh:mm aa'},
  ],
  days: [
    {label: 'Monday', value: 'Monday'},
    {label: 'Tuesday', value: 'Tuesday'},
    {label: 'Wednesday', value: 'Wednesday'},
    {label: 'Thursday', value: 'Thursday'},
    {label: 'Friday', value: 'Friday'},
    {label: 'Saturday', value: 'Saturday'},
    {label: 'Sunday', value: 'Sunday'},
  ]
};

export default General;
