import React, {useEffect, useRef, useState} from 'react';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import {useTranslation} from 'react-i18next';
import {notHaveErrors, validateFields, validateForm} from 'utils/form';
import * as yup from 'yup';
import {createGuid} from 'utils/utils';
import classes from './TeamForm.module.scss';
import Input from 'components/UI/Input/Input';
import Select from 'components/UI/Select/Select';
import ImageUpload from 'components/UI/ImageUpload/ImageUpload';
import Switch from 'components/UI/Switch/Switch';
import {useSaveTeam} from 'apollo/queries/TeamQueries';
import {fileUploader} from 'utils/upload';
import {useAuth} from 'react-oidc-context';

const TeamForm = (props) => {
    const {gender, setLoading} = props;

    const {t} = useTranslation();
    const auth = useAuth();
    const navState = useReactiveVar(naviDataVar);

    const [image, setImage] = useState(null);
    const [errors, setErrors] = useState({});
    const [values, setValues] = useState(props.defaultValues ?? {
        birthYear: "",
        suffix: "",
        name: "",
        order: 0,
        isSystem: false,
        photoFile: undefined
    });


    const [saveTeam] = useSaveTeam();

    const fieldChanged = (event) => {
        const newValues = { ...values };
        newValues[event.target.name] = event.target.value

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });

        if (event.target.file !== undefined) {
            newValues[event.target.name+"File"] = event.target.file;
        }
        setValues(newValues);
    }

    const validationRules = {
        birthYear: yup.string().ensure().when('suffix', {
            is: '',
            then: yup.string().required()
        }),
        suffix: yup.string().ensure().when('birthYear', {
            is: '',
            then: yup.string().required()
        }),
        gender: yup.string().required(t("required")),
    }

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors
    };

    const formSubmitted = async (e) => {
        e.preventDefault();
        setLoading(true);
        validateForm(values, validationRules, (errors) => {
            if (notHaveErrors(errors)) {
                saveData();
            } else {
                setLoading(false);
                setErrors(errors);
            }
        });
    };

    const saveData = async () => {

        const variables = {
            input: {
                teamId: values.id ?? createGuid(),
                clubId: navState.clubId,
                birthYear: values.birthYear ? +values.birthYear : null,
                suffix: values.suffix,
                gender: values.gender,
                goalkeepersOnly: values.goalkeepersOnly
            }
        }

        if (values.photoFile !== undefined) {
            const fileData = {
                clubId: navState.clubId,
                fileId: variables.input.teamId
            }

            console.log('fixed');
            if (await fileUploader(values.photoFile, fileData, auth)) {
                variables.input.photoUploadDate = new Date().toISOString();
            }
        }

        await saveTeam({variables: variables});
        props.onComplete();
    };

    const onImageChanged = (e) => {
        setImage(e);
    }

    useEffect(() => {
        if (!image) return;
        fieldChanged(image);
    }, [image]);


    return (
        <>
            <form id={props.id} onSubmit={formSubmitted}>
                <div className={classes.columns21}>
                    <div>
                        <Input
                            name="birthYear"
                            type="number"
                            label={t('Birth year')}
                            {...regField}
                        />

                        <Input
                            name="suffix"
                            label={t('Additional information')}
                            {...regField}
                        />

                        <Select
                            name="gender"
                            label={t('Gender')}
                            options={gender}
                            {...regField} />

                        <Switch
                            fullSize={true}
                            name="goalkeepersOnly"
                            label={t('Goalkeepers only')}
                            {...regField}
                        />

                    </div>
                    <div>
                        <ImageUpload
                            name="photo"
                            label={t('Team photo')}
                            text="Add team photo"
                            {...regField}
                            onChange={onImageChanged}
                        />
                    </div>
                </div>
            </form>
        </>
    );
};

TeamForm.defaultProps = {
    gender: [
        {label: "None", value: "NONE"},
        {label: "Men", value: "MALE"},
        {label: "Women", value: "FEMALE"},
        {label: "Mixed", value: "MIXED"},
    ],
};

export default TeamForm;
