import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {notHaveErrors, validateFields, validateForm} from 'utils/form';
import Textarea from 'components/UI/Textarea/Textarea';
import {useSaveTrainingPlayerStatus} from 'apollo/queries/TrainingQueries';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';

const CommentForm = (props) => {
    const {defaultValues, onComplete, setLoading} = props;

    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const [values, setValues] = useState(defaultValues);
    const [errors, setErrors] = useState({});

    const [saveTrainingPlayerStatus] = useSaveTrainingPlayerStatus();

    const fieldChanged = (event) => {
        const newValues = {...values};
        newValues[event.target.name] = event.target.value;

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });

        setValues(newValues);
    };

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors
    };

    const validationRules = {};

    const formSubmitted = (e) => {
        e.preventDefault();
        setLoading(true);
        validateForm(values, validationRules, (errors) => {
            if (notHaveErrors(errors)) {
                saveData();
            } else {
                setLoading(false);
                setErrors(errors);
            }
        });
    };

    const saveData = () => {
        const variables = {
            input: {
                teamId: navState.teamId,
                playerTrainingId: values.playerTrainingId,
                comments: values.comments
            }
        };

        saveTrainingPlayerStatus({variables: variables}).then(result => {
            onComplete();
        });
    }

    return (
        <form id={props.id} onSubmit={formSubmitted}>
            <Textarea
                name="comments"
                placeholder={t('Comment')}
                {...regField}
                />
        </form>
    );
};

CommentForm.defaultProps = {};

export default CommentForm;
