import React, {useState} from 'react';
import Progress from 'components/UI/Progress/Progress';
import Input from 'components/UI/Input/Input';
import Button from 'components/UI/Button/Button';
import Password from 'components/UI/Password/Password';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {regStateVar} from 'apollo/variables/registration';
import {notHaveErrors, validateFields, validateForm} from 'utils/form';
import * as yup from 'yup';
import Checkbox from 'components/UI/Checkbox/Checkbox';
import axios from 'axios'
import ErrorMessage from 'components/UI/ErrorMessage/ErrorMessage';
import Spinner from 'components/UI/Spinner/Spinner';
import i18n from 'i18next';

const UserInfo = () => {
  const {t} = useTranslation();
  const regState = useReactiveVar(regStateVar);

  const [values, setValues] = useState(regState);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [hasNetError, setNetError] = useState(false);

  const fieldChanged = (event) => {
    const newValues = {...values};

    newValues[event.target.name] = event.target.value;

    validateFields(newValues, validationRules, (errors) => {
      setErrors(errors);
    });
    setValues(newValues);
  };

  const regField = {
    value: values,
    onChange: fieldChanged,
    error: errors
  };

  const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

  const validationRules = {
    userFirstName: yup.string().required(t('Required')),
    userLastName: yup.string().required(t('Required')),
    phone: yup.string().required(t('Required')).matches(phoneRegExp, t('notValidPhone')),
    userPassword: yup.string().required(t('Required'))
        .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
        t('showPasswordError')),
    userPasswordAgain: yup.string().required(t('Required'))
        .oneOf([yup.ref('userPassword'), null], t('passwordsMustMatch'))
  };

  const controller = (data) => {
    const country = data.toLowerCase();

    switch (country) {
      // case "ru":
      //   return "https://world.yoplaydo.com/Registration";
      //   //return "https://play.yoplaydo.ru/Registration";
      // case "ua":
      //   return "https://eu.yoplaydo.com/Registration";
      //   //return "https://ua.yoplaydo.com/Registration";
      default:
        return process.env.REACT_APP_REGISTRATION_URL;
    }
  }

  const onGoTo = (goto, e) => {
    e.preventDefault();

    regStateVar({
      ...regState,
      main: goto,
    });
  };

  const onSubmit = (e) => {
    console.log(controller(values.country));
    e.preventDefault();

    validateForm(values, validationRules, async (errors) => {
      if (notHaveErrors(errors))
        await sendMessage();
      else
        setErrors(errors);
    });
  };

  const sendMessage = async () => {
    try {
      setLoading(true);
      setNetError(false);

      const params = new URLSearchParams();
      params.append('Appointment', 'Coach');
      params.append('Campaign', 'yoplaydo');
      params.append('FirstName', values.userFirstName);
      params.append('LastName', values.userLastName);
      params.append('ClubName', values.clubName);
      params.append('Country', values.country);
      params.append('Сurrency', values.currency);
      params.append('Phone', values.phone);
      params.append('Email', values.email);
      params.append('Password', values.userPassword);
      params.append('ConfirmPassword', values.userPassword);
      params.append('IsTrainer', false);
      params.append('AgreeTermsOfCondition', true);
      params.append('RegisterRequestId', 0);
      params.append('Locale', i18n.language);

      const response = await axios.post(controller(values.country) + "/club", params);

      if (response.status === 201) {
        setLoading(false);

        regStateVar({
          main: 'Finish'
        });
      } else {
        notSend();
      }
    } catch (e) {
      notSend();
    }
  }

  const notSend = () => {
    setLoading(false);
    setNetError(true);
  }

  return (
      <div>
        <Button onClick={e => onGoTo('CreateNewClub', e)}
                type="third">  &#8592; {t('goBack')}</Button>
        <h1>{t('infoAboutYou')}</h1>

        <Progress step="2" max="2" style={{marginBottom: '10px'}}/>

        { hasNetError && <ErrorMessage message={t('error404')} /> }

        <fieldset disabled={loading}>
          <div>
            <Input
                name="userFirstName"
                {...regField}
                placeholder={t('yourName0')}/>

            <Input
                name="userLastName"
                {...regField}
                placeholder={t('yourSename')}/>

            <Input
                name="phone"
                {...regField}
                placeholder={t('Phone')}/>

            <br/>

            <Password
                type={values?.showPassword ? "text" : "password"}
                name="userPassword"
                {...regField}
                placeholder={t('Password')}/>

            <Input
                type={values?.showPassword ? "text" : "password"}
                name="userPasswordAgain"
                {...regField}
                placeholder={t('passwordAgain')}/>

            <Checkbox
                label={t('showPassword')}
                name="showPassword"
                {...regField}
                />
          </div>

          <Button onClick={onSubmit} type="primary">
            {loading ? <Spinner /> : t('Send')}
          </Button>
        </fieldset>
      </div>
  );
};

export default UserInfo;
