import React from 'react';
import {useTranslation} from 'react-i18next';
import classes from './Dashboard.module.scss';
import Card from 'components/UI/Card/Card';
import Subtext from 'components/UI/Table/Subtext/Subtext';
import DateTime from 'components/UI/Table/DateTime/DateTime';
import Table from 'components/UI/Table/Table';
import {useNavigate} from 'react-router-dom';
import {useSystemStatistic} from 'apollo/queries/ClubQueries';

const Dashboard = (props) => {
    const {t} = useTranslation();
    const history = useNavigate();

    const {data, loading, error} = useSystemStatistic();
    const {clubs, teams, coaches, staffs, parents, players,
            trainings, games, exercises, tests, videos, highlights} = data?.systemStatistic || {};

    const onClick = (item) => {
        history(`/club-${item.clubId}`);
    };

    const getClubFormat = (data) => {
        return props.types[data];
    }

    if (loading) {
        return <div>{t("Loading...")}</div>;
    }

    if (error) {
        return <div>{t("Error...")}</div>;
    }

    return (
        <>
            <div className="page-header">
                <h2>{t('Dashboard')}</h2>
            </div>
            <div className="page-content">
                {data ? <div className={classes.cards}>
                    <Card name="Clubs" value={clubs} icon={<i className="icon-Location"></i>} className={classes.card} />
                    <Card name="Teams" value={teams} icon={<i className="icon-Team"></i>} className={classes.card} />
                    <Card name="Coaches" value={coaches} icon={<i className="icon-whistle"></i>} className={classes.card} />
                    <Card name="Staffs" value={staffs} icon={<i className="icon-staff"></i>} className={classes.card} />
                    <Card name="Parents" value={parents} icon={<i className="icon-AddUser"></i>} className={classes.card} />
                    <Card name="Players" value={players} icon={<i className="icon-Profile"></i>} className={classes.card} />
                    <Card name="Trainings" value={trainings} icon={<i className="icon-ball"></i>} className={classes.card} />
                    <Card name="Games" value={games} icon={<i className="icon-trophy"></i>} className={classes.card} />
                    <Card name="Exercises" value={exercises} icon={<i className="icon-field"></i>} className={classes.card} />
                    <Card name="Tests" value={tests} icon={<i className="icon-running"></i>} className={classes.card} />
                    <Card name="Videos" value={videos} icon={<i className="icon-videoPlay"></i>} className={classes.card} />
                    <Card name="Highlights" value={highlights} icon={<i className="icon-Matches1"></i>} className={classes.card} />
                </div>
                    : undefined}

                <h3>{t("10 new clubs")}</h3>
                {data ? <Table
                    data={data.clubList.items}
                    columns={[
                        {
                            Header: t('Club name'),
                            accessor: 'name',
                            Cell: (cellData) => <div style={{cursor: 'pointer'}}>{cellData.value}
                                <Subtext>{t("Type")}: {t(getClubFormat(cellData.row.original.clubFormat))}</Subtext></div>
                        },
                        {
                            Header: t('Country'),
                            accessor: 'country',
                            Cell: (cellData) => <div>{cellData.value}
                                <Subtext>{t("Address")}: {cellData.row.original.city ? cellData.row.original.city : "-"}</Subtext></div>
                        },
                        {
                            Header: t('Registered'),
                            className: classes.hide,
                            accessor: 'registrationDate',
                            Cell: (cellData) => <div className={classes.hide}><DateTime data={cellData.value}/></div>
                        },
                        {
                            Header: t('Last activity'),
                            className: classes.hide,
                            accessor: 'lastLoginDate',
                            Cell: (cellData) => <div className={classes.hide}><DateTime data={cellData.value}/></div>
                        }
                    ]}
                    onRowClick={onClick}/>
                    : undefined}
            </div>
        </>
    );
};

Dashboard.defaultProps = {
    types: {
        all: "All",
        FOOTBALL_FEDERATION: "Federation",
        FOOTBALL_ACADEMY: "Academy",
        SPORT_SCHOOL: "School",
        COMMERCIAL_CLUB: "Private Club",
        OTHER: "Other"
    },
};

export default Dashboard;
