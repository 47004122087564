import React, {useEffect} from 'react';
import PlayerHeader from '../Player/PlayerHeader';
import Submenu from '../../../components/Navigation/Submenu/Submenu';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from '../../../apollo/cashe';
import Button from '../../../components/UI/Button/Button';
import Panel from '../../../components/UI/Panel/Panel';
import {usePlayerCommunication} from '../../../apollo/queries/PlayerQueries';
import Table from '../../../components/UI/Table/Table';
import DateTime from '../../../components/UI/Table/DateTime/DateTime';
import CanAccess from '../../../components/Navigation/CanAccess/CanAccess';

const Communication = (props) => {

    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const [getPlayerCommunication, PlayerCommunicationData] = usePlayerCommunication({playerId: navState.playerId});

    useEffect(() => {
        getPlayerCommunication({variables: {playerId: navState.playerId}})
    }, [navState.playerId]);

    const actions = () => (
        <>
            <Button type="primary" style={{margin: 0}} onClick={onAddCommunication}>
                <span><i className="icon-Plus-Square"></i> {t("Add record")}</span>
            </Button>
        </>
    );

    const onAddCommunication = () => {

    }

    const onRowClick = () => {

    }

    return (
        <>
            <div className="page-header">
                <PlayerHeader />
                <Submenu/>
            </div>
            <div className="page-content">
                <CanAccess users={[5,9,15]}>
                    <Panel border={false} action={actions}>
                        <br />
                    </Panel>
                </CanAccess>

                {PlayerCommunicationData.loading && <>Loading...</>}
                {PlayerCommunicationData.error && <>Error...</>}
                {PlayerCommunicationData.data && <Table
                    data={PlayerCommunicationData.data.playerCommunication}
                    center="true"
                    columns={[
                        {
                            Header: t('Date'),
                            accessor: 'date',
                            Cell: cellData => <DateTime data={cellData.value} showTime={false} />
                        },
                        {
                            Header: t('Status'),
                            accessor: 'status',

                        },
                        {
                            Header: t('Next action'),
                            accessor: 'nextAction',
                        }
                    ]}
                    onRowClick={onRowClick} />}
            </div>
        </>
    );
};

Communication.defaultProps = {};

export default Communication;
