import React, {useEffect, useState, useRef} from 'react';
import Tooltip from '../Tooltip/Tooltip';
import classes from './Timeline.module.scss'
import {DAY_SETTINGS, WEAK_SETTINGS, MONTH_SETTINGS, YEAR_SETTINGS} from './Settings'
import Tabs from '../Tabs/Tabs';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from '../../../apollo/cashe';
import {getDate} from '../../../utils/date';

const Timeline = (props) => {
  const navState = useReactiveVar(naviDataVar);
  const {t} = useTranslation();

  const [mode, setMode] = useState("week");
  const [date, setDate] = useState(new Date());
  const myRef = useRef(null);

  const [lazyLoadData, {called, loading, data, error}] = props.lazyData();

  useEffect(() => {
    loadData();

    if (myRef?.current !== null) {
      myRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center'
      })
    }
  }, [navState, mode, date])


  const loadData = () => {
    const start = settings().start; //moment(date).startOf(mode);
    const end = settings().end; //moment(date).endOf(mode);

    if (navState.clubId === null)
      return;

    lazyLoadData({variables: {
      "clubId": navState.clubId,
      "startDate": start,
      "endDate": end
    }});
  }

  const settings = () => {
    switch (mode) {
      case "day":
        return DAY_SETTINGS(date);
      case "month":
        return MONTH_SETTINGS(date);
      case "year":
        return YEAR_SETTINGS(date);
      default:
        return WEAK_SETTINGS(date);
    }
  }

  const renderHeader = () => {
    const style = `150px repeat(${settings().colsCount}, minmax(100px, 1fr)`;
    return (
        <div className={[classes.ganttRow, classes.header].join(' ')}
             style={{gridTemplateColumns: style}}>
          <div className={classes.ganttRowFirst}></div>
          {
            settings().cols.map(col => (
                <span key={col.id}>{col.title}</span>
            ))
          }
        </div>
    )
  }

  const renderRows = (rows) => {
    const style = `repeat(${settings().colsCount}, minmax(100px, 1fr)`;
    return rows.map(row =>  (
        <React.Fragment key={row.teamId}>
        <div className={[classes.ganttRow, classes.Row,
          row.items ? classes.ganttRowEmpty : classes.ganttRowEmpty].join(' ')}
             style={{gridTemplateColumns: "150px 1fr"}}>
          <div className={classes.ganttRowFirst}>
            {row.name}
          </div>
          <ul className={classes.ganttRowBars} style={{gridTemplateColumns: style}}>
            {row.items?.length > 0 && renderItems(row)}
          </ul>
        </div>

        {/*{(row.children && row.children.length > 0) && renderRows(row.children)}*/}
        </React.Fragment>
    ));
  }

  const renderColumns = () => {
    const style = `150px repeat(${settings().colsCount}, minmax(100px, 1fr)`;
    return (
        <div className={[classes.ganttRow, classes.ganttRowLines].join(' ')}
             style={{gridTemplateColumns: style}}>
          <div className={[classes.ganttRowFirst, classes.firstColumn].join(' ')}></div>
          { [...Array(settings().colsCount).keys()].map(item => {
            const cls = ['column'];
            if (isWeekend(item)) cls.push(classes.weekend);
            if (settings().scrollTo - 1  === item) {
              cls.push(classes.current);
              return <span key={item} ref={myRef} className={cls.join(' ')} />
            }
            return <span key={item} className={cls.join(' ')} />
          })}
        </div>
    );
  }

  const isWeekend = (day) => (
      settings().weekends?.length > 0 && settings().weekends.includes(day)
);

  const calcPosition = (item) => {
    const start = getDate(item.start);
    return settings().cols.findIndex((c) => c.start <= start && c.end >= start) + 1;
  }

  const renderItems = (row) => {
    return row.items.map(item => {
      const position = calcPosition(item);
      const color = props.colors[item.trainingStatus];
      const name = item.name ? item.name : '...'
      if (position == 0) return false;
      return <li key={item.id} style={{gridColumn: position, backgroundColor: color}}>
        <Tooltip content={props.tooltip(row.teamId, item)}>{name}</Tooltip>
      </li>
    });
  }

  const prevDate = () => {
    const newDate = settings().prev(date);
    setDate(newDate);
  }

  const nextDate = () => {
    const newDate = settings().next(date);
    setDate(newDate);
  }

  const tabItems = [
    { id: 'day', name: t('Day')},
    { id: 'week', name: t('Week')},
    { id: 'month', name: t('Month')},
    { id: 'year', name: t('Year')},
  ]

  if (loading) return <div>{t('Loading...')}</div>;
  if (error) return <div>{t('Error!')}</div>;
  //https://stackoverflow.com/questions/40987309/react-display-loading-screen-while-dom-is-rendering
  const rowData = props.parseData(data);

  return (
      <div className={classes.Timeline}>
        <div className={classes.controls}>
          <div className={classes.calendar}>
            <span onClick={prevDate}>&#60;</span>
            {settings().show}
            <span onClick={nextDate}>&#62;</span>
          </div>
          <div className={classes.days}>
            <Tabs
                onClick={(val) => setMode(val)}
                selected={mode}
                items={tabItems}
            />
          </div>
        </div>
        <div className={classes.gantt}>
          {renderHeader()}
          {renderColumns()}
          {called && !loading && renderRows(rowData)}
        </div>
      </div>
  );
}

Timeline.defaultProps = {
  mode: 'weak'
}

export default Timeline
