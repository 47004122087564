import React, {useEffect, useState} from 'react';
import classes from './Languages.module.scss'
import i18n from 'i18next';
import cn from 'classnames';

const Languages = () => {
    const [languages, setLanguages] = useState([]);
    const [isActive, setActive] = useState(false);

    const toggleClass = () => {
        setActive(!isActive);
    };

    useEffect(() => {
        const storedLanguages = sessionStorage.getItem('languages');
        setLanguages(JSON.parse(storedLanguages));
    }, []);

    const changeLanguage = (language) => {
        i18n.changeLanguage(language).then(() => {});
        setActive(false);
    };

    if (languages.length === 0) {
        return (
            <div></div>
        );
    }

    return (
        <div className={classes.Languages}>
            <div className={cn(classes.language, classes.active)} onClick={toggleClass} >
                {languages[i18n.language]} {(i18n.language.toUpperCase())} <i className={cn("icon-Chevron",classes.chevron)}></i>
            </div>

            <div className={cn(classes.languageList, {[classes.languageListShow]:isActive})}>
                {Object.keys(languages).map((language) => {
                    if (i18n.language === language) {
                        return null;
                    }

                    return (
                        <div className={classes.language} key={language} onClick={() => changeLanguage(language)}>{languages[language]} {language.toUpperCase()}</div>
                    )
                })}
            </div>
        </div>
    )
}

export default Languages
