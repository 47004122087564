import React from 'react';
import classes from './Breadcrumbs.module.scss';
import {Link} from 'react-router-dom';

const Breadcrumbs = (props) => {
	const {data} = props;

	if (data.length === 0)  {
		return null;
	}

  return (
		<ul className={classes.root}>
			{data.map((item) => {
				return (
					<li key={item.key}><Link to={item.url}>{item.value}</Link></li>
				);
			})}
		</ul>
  );
};

Breadcrumbs.defaultProps = {
	data: []
};

export default Breadcrumbs;
