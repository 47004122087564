import React, {useEffect, useState} from 'react';
import Submenu from 'components/Navigation/Submenu/Submenu';
import {useTranslation} from 'react-i18next';
import {useNavigate} from "react-router-dom";
import Table from 'components/UI/Table/Table';
import DateTime from 'components/UI/Table/DateTime/DateTime';
import Subtext from 'components/UI/Table/Subtext/Subtext';
import Pagination from 'components/UI/Pagination/Pagination';
import Panel from 'components/UI/Panel/Panel';
import Search from 'components/UI/Panel/Search/Search';
import Select from 'components/UI/Select/Select';
import {useReactiveVar} from '@apollo/client';
import classes from './ClubList.module.scss';
import {naviDataVar} from 'apollo/cashe';
import {useClubList, useDeleteClub} from 'apollo/queries/ClubQueries';
import Button from 'components/UI/Button/Button';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import NewClubForm from 'containers/Club/TeamList/forms/NewClubForm';
import {getClubFormat} from 'containers/SysAdmin/ClubList/ClubFormats';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';
import CanAccess from 'components/Navigation/CanAccess/CanAccess';

const ClubList = (props) => {
  const navState = useReactiveVar(naviDataVar);
  const {t} = useTranslation();
  const history = useNavigate();

  const [search, setSearch] = useState('');
  const [skip, setSkip] = useState(0);
  const [type, setType] = useState("all");
  const [filters, setFilters] = useState(props.filters);

  const [modalData, setModalData, initState] = ModalState();
  const [getClubList, clubData] = useClubList();
  const [deleteClub] = useDeleteClub();

  useEffect(() => {
    const variables = {
      where: {
        "name": {"contains": search},
      },
      order: {
        name: "ASC",
        registrationDate: "ASC"
      },
      skip: skip * 10,
    };

    if (filters.hideDeleted) {
      variables.where.deleted = {"eq": false};
    }

    if (filters.sort === "new") {
      variables.order = {
        registrationDate: "DESC",
        name: "ASC",
      }
    }

    if (filters.sort === "activity") {
      variables.order = {
        lastLoginDate: "DESC",
        name: "ASC",
      }
    }

    if (type !== "all") {
      variables.where.clubFormat = {"eq": type};
    }

    getClubList({variables: variables});
  }, [search, skip, type, filters])

  const onSearch = (e) => {
    setSkip(0);
    setSearch(e.target.value);
  }

  const onFilter = (e) => {
    const newFilters = {...filters};
    if (e.target.name !== "sort") {
      newFilters[e.target.name] = e.target.value === "true";
    } else {
      newFilters[e.target.name] = e.target.value;
    }
    setFilters(newFilters);
  }

  const onClick = (item) => {
    naviDataVar({
      ...navState,
      clubId: item.clubId,
      clubName: item.name,
      teamId: null,
      selected: {
        id: 'c1',
        hash: '#club-team-list-panel',
        type: 1,
        items: [],
        submenu: {
          id: null,
          type: null
        }
      }
    });
    history(`/club-${item.clubId}`);
  };

  const actions = () => (
    <Button type="primary" style={{margin: 0}} onClick={onAddClick}>
      <span><i className="icon-Plus-Square"></i> {t("Add club")}</span>
    </Button>
  );

  const onAddClick = () => {
    const _data = {
      ...initState,
      title: t('Add club'),
      form: 'club',
      styles: {
        maxWidth: "700px",
      },
      onComplete: onComplete,
      open: true
    }

    setModalData(_data);
  }

  const onComplete = async () => {
    setModalData(initState);
    await clubData.refetch()
  }

  const panelFilters = () => (
      <>
        <Select
            small="small"
            name="confirmedOnly"
            value={filters}
            onChange={onFilter}
            options={props.confirmedOnly}
            placeholder={t("Unconfirmed")} />

        <Select
            small="small"
            name="hideDeleted"
            value={filters}
            onChange={onFilter}
            options={props.hideDeleted}
            placeholder={t("Deleted")} />

        <Select
            small="small"
            name="sort"
            value={filters}
            onChange={onFilter}
            options={props.sort}
            placeholder={t("Sort")} />
      </>
  );

  const panelSearch = () => (
      <Search onSearch={onSearch} placeholder={t("Search club...")} />
  );

  const onSettings = () => {
    return [
      {label: t('Edit club'), id: '1', action: onEditClub},
      {label: t('Delete club'), id: '2', action: onDeleteClub},
    ];
  };

  const onEditClub = (data) => {
    const _data = {
      ...initState,
      title: t('Edit club'),
      form: 'club',
      styles: {
        maxWidth: "700px",
      },
      defaultValues: {
        ...data,
      },
      onComplete: onComplete,
      onDelete: () => onDeleteClub(data),
      open: true
    }

    setModalData(_data);
  }

  const onDeleteClub = async (data) => {
    if (window.confirm(t("Are you sure want delete club?"))) {
      setModalData(initState);
      await deleteClub({variables: {clubId: data.clubId}});
      await clubData.refetch();
    }
  }

  return (
      <>
        <div className="page-header">
          <h2>{t('Club list')}</h2>
          <Submenu data={props.types} onFilter={(t) => setType(t)} />
        </div>
        <div className="page-content">
          <Panel border={false} action={actions} filters={panelFilters} search={panelSearch}>
            <br />
          </Panel>
          {clubData.loading && <>Loading...</>}
          {clubData.error && <>Error...</>}
          {clubData.data &&
          <CanAccess users={[SystemRoles.SysAdmin]}>
            <Table
                data={clubData.data.clubList.items}
                columns={[
                  {
                    Header: t('Club name'),
                    accessor: 'name',
                    Cell: (cellData) => <div style={{cursor: 'pointer'}}>{cellData.value}
                      <Subtext>{t("Type")}: {t(getClubFormat(cellData.row.original.clubFormat))}</Subtext></div>
                  },
                  {
                    Header: t('Country'),
                    accessor: 'country',
                    Cell: (cellData) => <div>{cellData.value}
                      <Subtext>{t("Address")}: {cellData.row.original.city ? cellData.row.original.city : "-"}</Subtext></div>
                  },
                  {
                    Header: t('Registered'),
                    className: classes.hide,
                    accessor: 'registrationDate',
                    Cell: (cellData) => <div className={classes.hide}><DateTime data={cellData.value}/></div>
                  },
                  {
                    Header: t('Last activity'),
                    className: classes.hide,
                    accessor: 'lastLoginDate',
                    Cell: (cellData) => <div className={classes.hide}><DateTime data={cellData.value}/></div>
                  }
                ]}
                onSettings={onSettings}
                onRowClick={onClick}
            />

            <Pagination page={skip} count={clubData.data.clubList.totalCount}
                        onPageChange={(p) => setSkip(p.selected)}/>
          </CanAccess>
          }
        </div>

        <ModalWindow2 {...modalData}>
            <NewClubForm {...modalData} name="club" />
        </ModalWindow2>
      </>
  );
};

ClubList.propTypes = {};
ClubList.defaultProps = {
  types: {
    all: "All",
    FOOTBALL_FEDERATION: "Federation",
    FOOTBALL_ACADEMY: "Academy",
    SPORT_SCHOOL: "School",
    COMMERCIAL_CLUB: "Private Club",
    OTHER: "Other"
  },
  confirmedOnly: [
    {label: "Show", value: false },
    {label: "Hide", value: true }
  ],
  hideDeleted: [
    {label: "Show", value: false },
    {label: "Hide", value: true }
  ],
  sort: [
    {label: "Abc", value: 'abc' },
    {label: "New", value: 'new' },
    //{label: "Activity", value: 'activity' }
  ],
  filters: {
    confirmedOnly: false,
    hideDeleted: true,
    sort: 'abc'
  }
};

export default ClubList;
