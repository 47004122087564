import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import classes from './Localization.module.scss';
import Table from 'components/UI/Table/Table';
import Button from 'components/UI/Button/Button';
import Subtext from 'components/UI/Table/Subtext/Subtext';
import {useUploadLanguage} from 'apollo/queries/FileQueries';
import FileUpload from 'components/UI/FileUpload/FileUpload';
import {toastMessage} from 'components/UI/Toast/Toast';
import {useGoogleTranslate, useLocales} from 'apollo/queries/SettingQueries';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import LocalizationEditor from './forms/LocalizationEditor';
import Panel from 'components/UI/Panel/Panel';
import i18n from 'i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import Submenu from 'components/Navigation/Submenu/Submenu';
import SwitchComponents from 'components/System/SwitchComponents';
import EmptyEditor from './forms/EmptyEditor';
import {createGuid} from 'utils/utils';

const Localization = (props) => {
    const {t} = useTranslation();
    const fileUploadRef = useRef();
    const apiDomain = process.env.REACT_APP_TRANSLATES_URL;
    const navState = useReactiveVar(naviDataVar);

    const [selected, setSelected] = useState(null);
    const [file, setFile] = useState(null);

    const [modalData, setModalData, initState] = ModalState();

    const {loading, error, data, refetch} = useLocales();
    const [googleTranslate] = useGoogleTranslate();
    const [uploadLanguage] = useUploadLanguage();

    useEffect(() => {
        if (file === null) {
            return;
        }

        const variables = {
            file: file,
            meta: {
                id: createGuid(),
                name: selected
            }
        };

        uploadLanguage({variables: variables}).then(({data}) => {
            if (data.uploadLanguage) {
                toastMessage(true, t("Translate uploaded."));
            }
            else {
                toastMessage(false, t("Something going wrong, file not load."));
            }
        }).then(() => {
            setSelected(null);
            setFile(null);
        }).catch((e) => {
            toastMessage(false, t("Something going wrong, file not load."));
        });

    }, [file]);


    const onRowClick = (data) => {
        const _data = {
            ...initState,
            title: data.displayName,
            form: "translates",
            styles: {
                maxWidth: "900px",
            },
            buttons: {
                hideSaveButton: true
            },
            defaultValues: {
                locale: data.culture,
            },
            onComplete: onComplete,
            open: true
        }

        setModalData(_data);
    }

    const onEmptyClick = (data) => {
        const _data = {
            ...initState,
            title: data.displayName,
            form: "empty",
            styles: {
                maxWidth: "900px",
            },
            buttons: {
                hideSaveButton: true
            },
            defaultValues: {
                locale: data.culture,
            },
            onComplete: onComplete,
            open: true
        }

        setModalData(_data);
    }

    const onComplete = async () => {
        setModalData(initState);
        await refetch();
    }

    const onGoogleClick = async (data) => {
        toastMessage(true, t("Start Google translate, it take time."));

        const variables = {
            language: data.culture.toUpperCase()
        };

        await googleTranslate({variables});
        await refetch();
    }

    const onDownload = (data) => {
        window.open(`${apiDomain}/download/${data.culture}`);
    }

    const onUploadClick = (data) => {
        setSelected(data.culture);
        fileUploadRef.current.onOpen();
    }

    const onUpload = (files) => {
        setFile(files[0]);
    };

    const actions = () => (
        <Button type="primary" style={{margin: 0}}
                onClick={onClearCache}>
            <span><i className="icon-Plus-Square"></i> {t("Clear cache")}</span>
        </Button>
    );

    const onClearCache = async () => {
        localStorage.removeItem(`i18next_res_${navState.language}-translation`);
        await i18n?.reloadResources();
        window.location.reload();
    }

    return (
        <>
            <div className="page-header">
                <h2>{t('Localization')}</h2>
                <Submenu/>
            </div>
            <div className="page-content">
                <Panel border={false} action={actions} />

                <FileUpload onUpload={(files) => onUpload(files)} ref={fileUploadRef} mode="xls" hide />

                {loading ? <>{t("Loading...")}</> : undefined}
                {error ? <>{t("Error...")}</> : undefined}

                {data ? <Table
                    data={data.locales}
                    columns={[
                        {
                            Header: t('Language name'),
                            accessor: 'displayName',
                            Cell: (cellData) => <div onClick={() => onRowClick(cellData.row.original)}>
                                {cellData.value}
                                <Subtext>{t("Code")}: {cellData.row.original.culture}</Subtext>
                            </div>
                        },
                        {
                            Header: t('Translated'),
                            accessor: 'translated',
                            className: classes.buttonColumn,
                            Cell: (cellData) => <div onClick={() => onEmptyClick(cellData.row.original)}>{cellData.value} / {cellData.row.original.translations}</div>
                        },
                        {
                            Header: t('Google translate'),
                            accessor: 'google',
                            className: classes.buttonColumn,
                            Cell: (cellData) => <div><Button onClick={(e) => onGoogleClick(cellData.row.original, e)} type="primary" style={{margin: "0"}}>Google</Button></div>
                        },
                        {
                            Header: t('Upload'),
                            accessor: 'upload',
                            className: classes.buttonColumn,
                            Cell: (cellData) => <div><Button onClick={(e) => onUploadClick(cellData.row.original, e)} type="primary" style={{margin: "0"}}>{t("Upload")}</Button></div>
                        },
                        {
                            Header: t('Download'),
                            accessor: 'download',
                            className: classes.buttonColumn,
                            Cell: (cellData) => <div><Button onClick={(e) => onDownload(cellData.row.original, e)} type="primary" style={{margin: "0"}}>{t("Download")}</Button></div>
                        },
                    ]}
                    onRowClick={() => {}}/> : undefined}
            </div>

            <ModalWindow2 {...modalData}>
                <SwitchComponents active={modalData.form}>
                    <LocalizationEditor {...modalData} name="translates" />
                    <EmptyEditor {...modalData} name="empty" />
                </SwitchComponents>
            </ModalWindow2>
        </>
    );
};

Localization.defaultProps = {};

export default Localization;
