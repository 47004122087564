import React, {PropTypes, useEffect, useState} from 'react';
import { ChromePicker } from 'react-color';

const ColorPicker = (props) => {
  const [color, setColor] = useState(props.color ?? "#ff0000");
  const [openPicker, setOpenPicker] = useState(false);

  useEffect(() => {
    setColor(props.color);
  }, [props.color])

  const popover = {
    position: 'absolute',
    zIndex: '2',
  }

  const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  }

  const onChange = (data) => {
    setColor(data.hex);
    props.onChange(data.hex);
  }

  return (
      <div>
        <button disabled={props.disabled} style={{background:color}} className={props.buttonClass} onClick={() => setOpenPicker(true)}></button>
        { openPicker ? <div style={ popover }>
          <div style={ cover } onClick={() => setOpenPicker(false)}/>
          <ChromePicker color={color} onChange={onChange}  />
        </div> : null }
      </div>
  );
};

ColorPicker.propTypes = {};
ColorPicker.defaultProps = {
  buttonClass: "pickColor",
  color: "#ff0000",
  disabled: false,
  onChange: () => {}
};

export default ColorPicker;