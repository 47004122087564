import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {notHaveErrors, validateFields, validateForm} from 'utils/form';
import * as yup from 'yup';
import {createGuid} from 'utils/utils';
import Input from 'components/UI/Input/Input';
import Select from 'components/UI/Select/Select';
import {useAddUserToGroup} from 'apollo/queries/GroupQueries';
import {useParams} from 'react-router-dom';

const UserGroupForm = (props) => {
	const {setLoading, onComplete, existedUserIds} = props;

	const {t} = useTranslation();
	const {groupId} = useParams();

	const [addUsers, setAddUsers] = useState([]);
	const [values, setValues] = useState({});
	const [errors, setErrors] = useState({});

	const [addUserToGroup] = useAddUserToGroup();

	const fieldChanged = (event) => {
		const newValues = { ...values };
		newValues[event.target.name] = event.target.value;

		validateFields(newValues, validationRules, (errors) => {
			setErrors(errors);
		});

		setValues(newValues);
	}

	const validationRules = {
		firstName: yup.string().required(t("required")),
		lastName: yup.string().required(t("required")),
		email: yup.string().required(t("required")),
		role: yup.string().required(t("required")),
	}

	const regField = {
		value: values,
		onChange: fieldChanged,
		error: errors
	};

	const formSubmitted = async (e) => {
		e.preventDefault();
		setLoading(true);
		validateForm(values, validationRules, (errors) => {
			if (notHaveErrors(errors)) {
				saveData();
			} else {
				setLoading(false);
				setErrors(errors);
			}
		});
	};

	const saveData = async () => {
		const variables = {
			input: {
				groupUserId: values.groupUserId ?? createGuid(),
				groupId: groupId,
				email: values.email,
				firstName: values.firstName,
				lastName: values.lastName,
				role: values.role
			}
		}

		await addUserToGroup({variables: variables});
		props.onComplete();
	};

  return (
    <>
	    <form id={props.id} onSubmit={formSubmitted}>
		    <Input
			    name="firstName"
			    label={t('First name')}
			    {...regField}
		    />

		    <Input
			    name="lastName"
			    label={t('Last name')}
			    {...regField}
		    />

		    <Input
			    name="email"
			    label={t('E-mail')}
			    {...regField}
		    />

		    <Select
			    name="role"
			    options={props.accessType}
			    label={t('Access')}
			    {...regField}
		    />
	    </form>
    </>
  );
};

UserGroupForm.defaultProps = {
	accessType: [
		{value: 'MEMBER', label: 'Can view clubs'},
		{value: 'MANAGER', label: 'Can view clubs and add users'},
	]
};

export default UserGroupForm;
