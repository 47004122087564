import React, {useEffect, useState} from 'react';
import cn from 'classnames';
import classes from './Matches.module.scss';
import Submenu from 'components/Navigation/Submenu/Submenu';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import {useTranslation} from 'react-i18next';
import Panel from 'components/UI/Panel/Panel';
import Button from 'components/UI/Button/Button';
import {useDeleteGame, useGames} from 'apollo/queries/GameQueries';
import Pagination from 'components/UI/Pagination/Pagination';
import Table from 'components/UI/Table/Table';
import DateTime from 'components/UI/Table/DateTime/DateTime';
import {inThisWeek, todayMinus14,  todayPlus14} from 'utils/date';
import {useNavigate} from 'react-router-dom';
import MatchForm from './forms/MatchForm';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import DatePicker from 'components/UI/DatePicker/DatePicker';
import Subtext from 'components/UI/Table/Subtext/Subtext';
import SwitchComponents from 'components/System/SwitchComponents';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';
import CanAccess from 'components/Navigation/CanAccess/CanAccess';

const Matches = (props) => {
    const {t} = useTranslation();
    const history = useNavigate();
    const navState = useReactiveVar(naviDataVar);

    const onPage = 20;
    const [skip, setSkip] = useState(0);
    const [values, setValues] = useState({
        startDate: todayMinus14(),
        endDate: todayPlus14()
    });

    const [modalData, setModalData, initState] = ModalState();
    const [getMatches, getMatchesData] = useGames();
    const [deleteGame] = useDeleteGame();

    useEffect(() => {
        if (!navState.teamId)
            return;

        const variables = {
            teamId: navState.teamId,
            where: {
                and: [
                    {date: {gte: values.startDate}},
                    {date: {lte: values.endDate}}
                ]
            },
            skip: skip * onPage,
            take: onPage
        };

        getMatches({variables: variables});
    }, [navState, values, skip, onPage]);

    const onFilter = (e) => {
        const newFilters = {...values};
        newFilters[e.target.name] = e.target.value;
        setValues(newFilters);
    }

    const onAddMatch = () => {
        const _data = {
            ...initState,
            title: t("Add match"),
            form: "match",
            pages: {
                page: 0,
                maxPages: 2,
            },
            styles: {
                maxWidth: "700px",
            },
            defaultValues: {
                scope: "FRIENDLY_GAME",
                name: navState.teamName,
                duration: 90,
                periodCount: 2
            },
            onComplete: onComplete,
            open: true
        }

        setModalData(_data);
    }

    const onComplete = () => {
        setModalData(initState);
        getMatchesData.refetch();
    }

    const actions = () => (
        <CanAccess users={[SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin]}>
            <Button type="primary" style={{margin: 0}} onClick={onAddMatch}>
                <span><i className="icon-Plus-Square"></i> {t('Add match')}</span>
            </Button>
        </CanAccess>
    );

    const filters = () => {
        return (
            <>
                <DatePicker
                    name="startDate"
                    onChange={onFilter}
                    placeholder={t("Date from")}
                    value={values}
                />

                <DatePicker
                    name="endDate"
                    onChange={onFilter}
                    placeholder={t("Date till")}
                    value={values}
                />
            </>
        )
    }

    const onRowClick = (item) => {
        history(`/club-${navState.clubId}/team-${navState.teamId}/match-${item.gameId}`);
    };

    const rowClassName = (row) => {
        return inThisWeek(row.date) ? classes.thisWeek : undefined;
    }

    const onSettings = () => {
        return [
            {label: t('Edit match'), id: '1', action: onEditMatch},
            {label: t('Copy match'), id: '2', action: onCopyMatch},
            {label: t('Delete match'), id: '3', action: onDeleteMatch},
        ];
    };

    const onEditMatch = (_data) => {
        const data = {
            ...initState,
            title: t("Edit match"),
            form: "match",
            pages: {
                page: 0,
                maxPages: 1,
            },
            styles: {
                maxWidth: "700px",
            },
            defaultValues: {
                ..._data,
                name: _data.awayMatch !== true ? _data.ownerTeamName : _data.otherTeamName,
                opponent: _data.awayMatch !== true ? _data.otherTeamId : _data.ownerTeamId,
                venue: _data.location,
            },
            onComplete: onComplete,
            onDelete: () => onDeleteMatch(_data),
            open: true
        }

        setModalData(data);
    }


    const onCopyMatch = (_data) => {
        const defaultValues = {
            ..._data,
            name: _data.awayMatch !== true ? _data.ownerTeamName : _data.otherTeamName,
            opponent: _data.awayMatch !== true ? _data.otherTeamId : _data.ownerTeamId,
            venue: _data.location,
        };
        delete defaultValues.date;

        const data = {
            ...initState,
            title: t("Edit match"),
            form: "match",
            pages: {
                page: 0,
                maxPages: 1,
            },
            styles: {
                maxWidth: "700px",
            },
            defaultValues: defaultValues,
            onComplete: onComplete,
            copy: true,
            open: true
        }

        setModalData(data);
    }

    const onDeleteMatch = async (_data) => {
        setModalData(initState);

        const variables = {
            input: {
                clubId: navState.clubId,
                teamId: navState.teamId,
                gameId: _data.gameId
            },
        };

        await deleteGame({variables: variables});
        await getMatchesData.refetch();
    }

    return (
        <>
            <div className={cn('page-header', classes.trainings)}>
                <div className={classes.name}>{navState.teamName}</div>
                <h2>{t('Matches')}</h2>
                <Submenu/>
            </div>
            <div className="page-content">
                <Panel border={false} action={actions} filters={filters} />

                {getMatchesData.loading && <>Loading...</>}
                {getMatchesData.error && <>Error...</>}
                {getMatchesData.data && <>
                    <Table
                        data={getMatchesData.data.games.items}
                        access={[SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin]}
                        columns={[
                            {
                                Header: t('Date and time'),
                                accessor: 'date',
                                Cell: cellData => <DateTime data={cellData.value}/>
                            },
                            {
                                Header: t('Name'),
                                accessor: 'name',
                                className: classes.hide,
                                Cell: (cellData) => <div className={classes.hide}>{cellData.value}</div>
                            },
                            {
                                Header: t('Team'),
                                accessor: 'ownerTeamName',
                                Cell: (cellData) => {
                                    const team1 = cellData.row.original.awayMatch ? cellData.row.original.otherTeamName : cellData.value;
                                    const team2 = cellData.row.original.awayMatch ? cellData.value : cellData.row.original.otherTeamName;
                                    return (
                                        <div>
                                            {team1} <span className={classes.show}>vs {team2}</span>
                                            <Subtext className={classes.show}>
                                                {cellData.row.original.name}, {cellData.row.original.resultLeft} - {cellData.row.original.resultRight}
                                            </Subtext>
                                        </div>
                                    );
                                }
                            },
                            {
                                Header: t('Score'),
                                accessor: 'resultLeft',
                                className: classes.hide,
                                Cell: (cellData) => <div  className={classes.hide}>{cellData.value} : {cellData.row.original.resultRight}</div>
                            },
                            {
                                Header: t('Team'),
                                className: classes.hide,
                                accessor: 'otherTeamName',
                                Cell: (cellData) => {
                                    const team = cellData.row.original.awayMatch ? cellData.row.original.ownerTeamName : cellData.value;
                                    return (<div className={classes.hide}>{team}</div>);
                                }
                            }
                        ]}
                        onRowClick={onRowClick}
                        onSettings={onSettings}
                        rowClassName={rowClassName}
                    />

                    <Pagination page={skip} count={getMatchesData.data.games.totalCount} onPage={onPage}
                                onPageChange={(p) => setSkip(p.selected)}/>
                </>}
            </div>

            <ModalWindow2 {...modalData}>
                <SwitchComponents active={modalData.form}>
                    <MatchForm {...modalData} name="match" />
                </SwitchComponents>
            </ModalWindow2>
        </>
    );
};

Matches.propTypes = {};
Matches.defaultProps = {};

export default Matches;
