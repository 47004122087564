import React, {useEffect, useState} from 'react';
import {useSaveGameDetails, useUpdateGamePlayer} from 'apollo/queries/GameQueries';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import FieldMap from './FieldMap';
import MatchesTeamList from './MatchesTeamList';
import Input from 'components/UI/Input/Input';
import {useTranslation} from 'react-i18next';
import classes from './MatchesDetailsPositions.module.scss';

const MatchesDetailsPositions = (props) => {
    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const [marks, setMarks] = useState([]);
    const [values, setValues] = useState({});

    const [updateGamePlayer] = useUpdateGamePlayer();
    const [saveGameDetails] = useSaveGameDetails();

    const onMarkMoved = (data) => {
        const variables = {
            input: {
                clubId: navState.clubId,
                teamId: navState.teamId,
                gameId: props.data.gameId,
                playerId: data.id,
                positionX: data.x,
                positionY: data.y
            }
        }

        updateGamePlayer({variables: variables}).then(result => {
            props.refetch();
        });
    }

    useEffect(() => {
        if (!props.data) {
            return;
        }

        setValues({tacticalScheme: props.data.tacticalScheme});
    }, [props.data]);

    useEffect(() => {
        if (props.data.players.length === 0)
            return;

        const mapPositionsArray = [];
        const mainTeam = props.data.players.filter(f => f.substitute === false).map(p => p.playerId);

        const players = props.data.players.map(p => {
            const position = props.position.find(x => x.value === p.position);

            const player = {
                id: p.playerId,
                number: ""+p.teamNumber,
                x: p.positionX ?? position.x,
                y: p.positionY ?? position.y,
                isMain: !p.substitute
            }

            if (mainTeam.indexOf(p.playerId) === -1) {
                player.x = 10;
                player.y = 380;
            }

            let index = +player.x * 1000 + player.y;

            while (mapPositionsArray.indexOf(index) !== -1) {
                player.x += 10;
                index = player.x * 1000 + player.y;
            }
            mapPositionsArray.push(index);

            return player;
        });

        setMarks(players);

    }, [props.data]);

    const reload = () => {
        props.refetch()
    }

    const fieldChanged = (event) => {
        const scheme = event.target.value

        const variables = {
            input: {
                clubId: navState.clubId,
                teamId: navState.teamId,
                gameId: props.data.gameId,
                tacticalScheme: scheme
            }
        }

        saveGameDetails({variables: variables}).then(result => {
            props.refetch();
        });
    }

    return (
        <div>
            <div className={classes.twoColumns}>
                <div>
                    <MatchesTeamList
                        data={props.data}
                        position={props.position}
                        refetch={reload} />
                </div>
                <div>
                    <h2>Tactical map</h2>
                    <div>
                        <Input
                            name="tacticalScheme"
                            label={t('Scheme')}
                            value={values}
                            onBlur={fieldChanged}
                        />
                    </div>
                    <FieldMap marks={marks} onMarkMoved={onMarkMoved} />
                </div>
            </div>
        </div>
    );
};

MatchesDetailsPositions.defaultProps = {
    position: [
        {label: "None", value: "NONE", x: 40, y: 94},
        {label: "Striker", value: "STRIKER", x: 75, y: 40},
        {label: "2nd Striker", value: "SECOND_STRIKER", x: 40, y: 40},
        {label: "Left Forward", value: "LEFT_FORWARD", x: 10, y: 50 },
        {label: "Right Forward", value: "RIGHT_FORWARD", x: 105, y: 50},
        {label: "Attacking Midfielder", value: "ATTACKING_MIDFIELDER", x: 58, y: 60},
        {label: "Midfielder", value: "MIDFIELDER", x: 58, y: 80},
        {label: "Left Midfielder", value: "LEFT_MIDFIELDER", x: 10, y: 90 },
        {label: "Right Midfielder", value: "RIGHT_MIDFIELDER", x: 105, y: 90},
        {label: "Defensive Midfielder", value: "DEFENSIVE_MIDFIELDER", x: 58, y: 100},
        {label: "Left Fullback", value: "LEFT_FULLBACK", x: 10, y: 130},
        {label: "Right Fullback", value: "RIGHT_FULLBACK", x: 105, y: 130},
        {label: "Defender", value: "DEFENDER", x: 58, y: 130},
        {label: "Center Back", value: "CENTER_BACK", x: 58, y: 150},
        {label: "Sweeper", value: "SWEEPER", x: 58, y: 165 },
        {label: "Goalkeeper", value: "GOALKEEPER", x: 58, y: 185 },
    ]
};

export default MatchesDetailsPositions;
