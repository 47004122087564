import React, {Component} from 'react';
import classes from './IsClubRegistered.module.scss';
import Progress from 'components/UI/Progress/Progress'
import Button from 'components/UI/Button/Button'
import {useTranslation} from "react-i18next";
import {useReactiveVar} from "@apollo/client";
import {regStateVar} from "apollo/variables/registration";

const IsClubRegistered = (props) => {
  const {t} = useTranslation();
  const regState = useReactiveVar(regStateVar);
  const rolesWhoCanCreateNewClub = [1, 2];

  const onSubmit = (goto, e) => {
    e.preventDefault();

    const role = +regState.role;
    if (goto === "RecommendUs" && rolesWhoCanCreateNewClub.includes(role))
      goto = "CreateOrRecommend";

    regStateVar({
      ...regState,
      main: goto
    });
  }

  return (
      <div>
        <Button onClick={e => onSubmit('UserRole', e)} type="third">  &#8592; {t('goBack')}</Button>
        <h1 dangerouslySetInnerHTML={{__html: t('registrationMessage')}}></h1>

        <Progress step="2" max="2"/>

        <div className={classes.spaser}></div>


        <Button onClick={e => onSubmit('RecommendUs', e)}  type="primary">{t('no')}</Button>
        <Button onClick={e => onSubmit('AskInvite', e)}>{t('yes')}</Button>
        <Button onClick={e => onSubmit('RecommendUs', e)} type="third">{t('dontKnow')}</Button>

      </div>
  );
}

export default IsClubRegistered
