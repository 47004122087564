import React, { PropTypes } from 'react'
import classes from './Card.module.scss';
import cn from 'classnames';

const Card = ({name, value, icon, className}) => {
  return (
      <div className={cn(classes.card, className)}>
        <div className={classes.value}>
          <div>{value}</div>
          <div>{icon}</div>
        </div>
        <div className={classes.name}>{name}</div>
      </div>
  );
};

Card.propTypes = {};
Card.defaultProps = {
  name: "name",
  value: 0,
  icon: "",
  className: {}
};

export default Card;