import React, { PropTypes } from 'react'
import classes from './PanelItem.module.scss'

const PanelItem = (props) => {
  return (
      <div className={classes.PanelItem}>
        <b>{props.value}</b>
        <div>{props.name}</div>
      </div>
  );
};

PanelItem.defaultProps = {
  value: '-',
  name: "Item"
};

export default PanelItem;
