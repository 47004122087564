import {gql, useMutation, useQuery} from '@apollo/client';

export const GROUP_FIELDS = gql`
    fragment GroupFields on GroupType {
        groupId
        parentGroupId
        name
        description
        logo
        logoUploadDate
        haveClub
    }
`;


export const GET_GROUPS = gql`
    ${GROUP_FIELDS}
    query GetGroups($parentGroupId: Uuid!, $search: String, $take: Int! $skip: Int!) {
        groups(parentGroupId: $parentGroupId, where: {name: {contains: $search}}, take: $take, skip: $skip) {
            totalCount
            items {
                ...GroupFields
            }
        }
    }
`;
export const useGetGroups = (variables) => useQuery(GET_GROUPS, {variables});


export const GET_GROUP = gql`
    ${GROUP_FIELDS}
    query GetGroup($groupId:Uuid!, $group:GroupTypeFilterInput, $club:ClubTypeFilterInput, $user:UserTypeFilterInput) {
        group(groupId: $groupId) {
            ...GroupFields
            breadcrumbs {
                key
                value
            }
            subgroups (where: $group) {
                ...GroupFields
                stat {
                    clubs
                    teams
                    coaches
                    players
                }
                clubs {
                    name
                }
            }
            clubs (where: $club) {
                clubId
                name
                registrationNumber
                state
                city
                country
                logo
                clubFormat
                logo
                logoUploadDate
                trainerCount
                teamCount
                playerCount
            }
            users (where: $user) {
                id
                userId
                fullName
                email
                userRole {
                    roleId
                    roleCode
                    name
                }
                roles {
                    roleId
                    roleCode
                    name
                }
                invite
                lastLogin
            }
        }
    }`;
export const useGetGroup = (variables) => useQuery(GET_GROUP, {variables});


export const SAVE_GROUP = gql`
    mutation SaveGroup($input: GroupInput!) {
        saveGroup(input: $input)
    }`;
export const useSaveGroup = () => useMutation(SAVE_GROUP);


export const DELETE_GROUP = gql`
    mutation DeleteGroup($input: DeleteInput!) {
        deleteGroup(input: $input)
    }`;
export const useDeleteGroup = () => useMutation(DELETE_GROUP);


export const ADD_CLUB_TO_GROUP = gql`
    mutation AddClubToGroup($input: AddClubToGroupInput!) {
        addClubToGroup(input: $input)
    }`;
export const useAddClubToGroup = () => useMutation(ADD_CLUB_TO_GROUP);


export const DELETE_CLUB_FROM_GROUP = gql`
    mutation DeleteClubToGroup($input: DeleteClubFromGroupInput!) {
        deleteClubFromGroup(input: $input)
    }`;
export const useDeleteClubToGroup = () => useMutation(DELETE_CLUB_FROM_GROUP);


export const ADD_USER_TO_GROUP = gql`
    mutation AddUserToGroup($input: AddUserToGroupInput!) {
        addUserToGroup(input: $input)
    }`;
export const useAddUserToGroup = () => useMutation(ADD_USER_TO_GROUP);


export const DELETE_USER_FROM_GROUP = gql`
    mutation DeleteUserFromGroup($input: DeleteUserFromGroupInput!) {
        deleteUserFromGroup(input: $input)
    }`;
export const useDeleteUserFromGroup = () => useMutation(DELETE_USER_FROM_GROUP);


