import React, {useState} from 'react';
import Submenu from 'components/Navigation/Submenu/Submenu';
import Panel from 'components/UI/Panel/Panel';
import PanelItem from 'components/UI/PanelItem/PanelItem';
import Tabs from 'components/UI/Tabs/Tabs';
import {useTranslation} from 'react-i18next';
import ClubCoachesEvaluation from 'containers/Club/SportDirector/ClubEvaluation/ClubCoachesEvaluation';
import ClubGamesEvaluation from 'containers/Club/SportDirector/ClubEvaluation/ClubGamesEvaluation';
import {useClubEvaluationStat} from 'apollo/queries/ClubQueries';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';

const ClubEvaluation = (props) => {
	const { t } = useTranslation();
	const navState = useReactiveVar(naviDataVar);

	const [mode, setMode] = useState("coaches");
	const {data, loading, error, refetch} = useClubEvaluationStat({clubId: navState.clubId});

	const actions = () => (
		<Tabs onClick={(val) => setMode(val)} selected={mode} items={props.tabs} />
	);

	const reload = async () => {
		await refetch();
	}

    return (
	    <>
		    <div className="page-header">
			    <h2>{t('Club evaluation')}</h2>
			    <Submenu />
		    </div>
		    <div className="page-content">
			    {data ? <Panel>
				    <PanelItem name={t('Evaluates')} value={data.clubEvaluationStat.count} />
				    <PanelItem name={t('Club')} value={data.clubEvaluationStat.clubAverage} />
				    <PanelItem name={t('Coaches')} value={data.clubEvaluationStat.coachAverage} />
				    <PanelItem name={t('Games')} value={data.clubEvaluationStat.gameAverage} />
			    </Panel> : undefined}

			    <br/>

			    <Panel border={false} action={actions} />

			    {mode === "coaches"
				    ? <ClubCoachesEvaluation reload={reload} />
				    : <ClubGamesEvaluation reload={reload} />}
		    </div>
	    </>
    );
};

ClubEvaluation.defaultProps = {
	tabs: [
		{ id: 'coaches', name: 'Coaches'},
		{ id: 'games', name: 'Games'},
	]
};

export default ClubEvaluation;
