import React, {useState} from 'react';
import classes from './Password.module.scss';
import {strengthIndicator, strengthColor} from './strength-password';
import {haveDefaultValue, haveError} from '../../../utils/form';
import {useTranslation} from 'react-i18next';

const Password = props => {
  const {t} = useTranslation();
  const cls = ['form-field', classes.Password];
  const htmlFor = props.id || `password-${Math.random()}`;
  const inputType = props.type || 'password';

  const error = haveError(props);
  const defaultValue = haveDefaultValue(props);
  const [value, setValue] = useState(defaultValue);

  const strength = strengthIndicator(value);
  const color = strengthColor(strength);

  const onPasswordChange = (e) => {
    setValue(e.target.value);
    props.onChange(e);
  }

  if (error) {
    cls.push(classes.invalid);
  }

  return (
      <div className={cls.join(' ')}>

        { (props.label || defaultValue) && <label htmlFor={htmlFor}>{props.label || props.placeholder}</label> }

        <input
            type={inputType}
            name={props.name}
            id={htmlFor}
            placeholder={props.placeholder}
            disabled={props.disabled}
            onChange={onPasswordChange}
        />

        <div
            className={classes.indicator} style={{backgroundColor: color}}>
        </div>

        { error && <span>{error || t('Required')}</span> }
      </div>
  );
};

export default Password;
