import React from 'react';
import classes from '../Settings.module.scss';
import Panel from '../../../components/UI/Panel/Panel';
import Table from '../../../components/UI/Table/Table';
import Subtext from '../../../components/UI/Table/Subtext/Subtext';
import Button from '../../../components/UI/Button/Button';
import {useTranslation} from 'react-i18next';
import ModalWindow2, {ModalState} from '../../../components/UI/ModalWindow/ModalWindow2';
import SkillCategoryForm from './forms/SkillCategoryForm';
import {useDeleteSkillCategory} from '../../../apollo/queries/PlayerQueries';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from '../../../apollo/cashe';
import SettingsTitle from 'components/UI/SettingsTitle/SettingsTitle';

const SkillCategories = (props) => {
    const {refetch} = props;

    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const [modalData, setModalData, initState] = ModalState();
    const [deleteSkillCategory] = useDeleteSkillCategory();

    const addActivity = () => (
        <Button type="primary" style={{margin: 0}} onClick={onAddCategory}>
            <span><i className="icon-Plus-Square"></i> {t('Add category')}</span>
        </Button>
    );

    const onAddCategory = () => {
        const _data = {
            ...initState,
            title: t("Add skill category"),
            onComplete: onComplete,
            open: true
        }

        setModalData(_data);
    }
    const onCategoryClick = (data) => {
        const {id, name, order, showInClub, isGoalkeeper, isText, isSystem} = data;

        const _data = {
            ...initState,
            title: t("Edit skill category"),
            defaultValues: {
                id: id,
                name: name,
                isGoalkeeper: isGoalkeeper,
                isText: isText,
                order: order,
                showInClub: showInClub,
                isSystem: isSystem
            },
            onComplete: onComplete,
            onDelete:  isSystem ? undefined : () => onDelete(id),
            open: true
        }

        setModalData(_data);
    }

    const onComplete = () => {
        refetch();
        setModalData(initState);
    }

    const onDelete = (id) => {
        const variables = {
            input: {
                id: id,
                clubId: navState.clubId,
            }
        };

        deleteSkillCategory({variables: variables}).then((result) => {
            if (result) props.refetch();
            setModalData(initState);
        });
    }

    return (
        <>
            <SettingsTitle title={t("Skill categories")} onBack={props.onBack} />

            <Panel border={false} action={addActivity}>
                <br/>
            </Panel>

            <Table
                data={props.data.skillCategories}
                center="true"
                columns={[
                    {
                        Header: t('Category'),
                        accessor: 'name',
                        Cell: cellData =>
                            <div>
                                {t(cellData.value)}
                                <Subtext>
                                    {t('Type')}: {cellData.row.original.isSystem ? t('system') : t('editable')}, &nbsp;
                                    {cellData.row.original.showInClub ? t('enabled') : t('disabled')}
                                </Subtext>
                            </div>
                    },
                    {
                        Header: t('Goalkeeper'),
                        accessor: 'isGoalkeeper',
                        Cell: cellData => <div>{cellData.value ? t("Yes") : t("No")}</div>
                    },
                    {
                        Header: t('Text'),
                        accessor: 'isText',
                        Cell: cellData => <div>{cellData.value ? t("Yes") : t("No")}</div>
                    },
                    {
                        Header: t('Order'),
                        accessor: 'order',
                        Cell: cellData => <div>
                            {cellData.value}
                        </div>
                    }
                ]}
                onRowClick={onCategoryClick}/>

            <ModalWindow2 {...modalData}>
                <SkillCategoryForm {...modalData} />
            </ModalWindow2>
        </>
    );
};

SkillCategories.defaultProps = {};

export default SkillCategories;
