import React, {useState} from 'react';
import Submenu from '../../../components/Navigation/Submenu/Submenu';
import {useTranslation} from 'react-i18next';
import {useDeleteSuggestedTranslation, useSuggestedTranslations} from '../../../apollo/queries/SettingQueries';
import Table from '../../../components/UI/Table/Table';
import classes from './Localization.module.scss';
import Button from '../../../components/UI/Button/Button';
import DateTime from '../../../components/UI/Table/DateTime/DateTime';
import Pagination from '../../../components/UI/Pagination/Pagination';
import ModalWindow2, {ModalState} from '../../../components/UI/ModalWindow/ModalWindow2';
import SuggestTranslationForm from './forms/SuggestTranslationForm';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from '../../../apollo/cashe';

const SuggestedTranslates = (props) => {

    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);
    const onPage = 10;

    const [skip, setSkip] = useState(0);

    const [modalData, setModalData, initState] = ModalState();
    const [deleteSuggestedTranslation] = useDeleteSuggestedTranslation();

    const {loading, error, data, refetch} = useSuggestedTranslations({
        skip: skip
    });

    const onRowClick = (data) => {
        const {suggestedTranslationId, tag, value} = data;
        const _data = {
            ...initState,
            title: t("Suggest translation"),
            form: "suggest",
            buttons: {
                hideSaveButton: true,
            },
            defaultValues: {
                code: tag,
                value: value
            },
            onComplete: onComplete,
            onDelete: () => onDelete(suggestedTranslationId),
            open: true
        }

        setModalData(_data);
    }

    const onComplete = () => {
        setModalData(initState);
    }

    const onDelete = (id) => {
        const variables = {
            input: {
                id: id,
                clubId: navState.clubId ?? ""
            }
        };

        deleteSuggestedTranslation({variables}).then(() => {
            setModalData(initState);
            refetch();
        });
    }

    const onOpen = (data) => {
        const {url} = data;
        window.open(url, '_blank');
    }

    return (
        <>
            <div className="page-header">
                <h2>{t('Localization')}</h2>
                <Submenu/>
            </div>
            <div className="page-content">

                {loading ? <>Loading...</> : undefined}
                {error ? <>Error...</> : undefined}

                {data ? <>
                    <Table
                    data={data.suggestedTranslations.items}
                    columns={[
                        {
                            Header: t('Date'),
                            accessor: 'suggestedTime',
                            Cell: (cellData) => <div onClick={() => onRowClick(cellData.row.original)}>
                                <DateTime data={cellData.value} />
                            </div>
                        },
                        {
                            Header: t('User'),
                            accessor: 'userName',
                            Cell: (cellData) => <div onClick={() => onRowClick(cellData.row.original)}>
                                {cellData.value}
                            </div>
                        },
                        {
                            Header: t('Locale'),
                            accessor: 'culture',
                            Cell: (cellData) => <div onClick={() => onRowClick(cellData.row.original)}>
                                {cellData.value}
                            </div>
                        },
                        {
                            Header: t('Translated'),
                            accessor: 'value',
                            Cell: (cellData) => <div onClick={() => onRowClick(cellData.row.original)}>
                                {cellData.value}
                            </div>
                        },
                        {
                            Header: t('Page'),
                            accessor: 'page',
                            className: classes.buttonColumn,
                            Cell: (cellData) => <div><Button onClick={(e) => onOpen(cellData.row.original)} type="primary" style={{margin: "0"}}>{t("Open")}</Button></div>
                        },
                    ]}
                    onRowClick={() => {}}/>
                    <Pagination page={skip} onP count={data.suggestedTranslations.totalCount} onPage={onPage} onPageChange={(p) => setSkip(p.selected)}/>
                    </>: undefined}

                <ModalWindow2 {...modalData}>
                    <SuggestTranslationForm {...modalData} />
                </ModalWindow2>
            </div>
        </>
    );
};

SuggestedTranslates.defaultProps = {};

export default SuggestedTranslates;
