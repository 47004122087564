import React, {useState} from 'react';
import {notHaveErrors, validateFields, validateForm} from '../../../../utils/form';
import Input from '../../../../components/UI/Input/Input';
import {useTranslation} from 'react-i18next';
import Switch from '../../../../components/UI/Switch/Switch';
import * as yup from 'yup';
import {createGuid} from '../../../../utils/utils';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from '../../../../apollo/cashe';
import {useSaveMetric, useSaveMetricCategory} from '../../../../apollo/queries/ExerciseQueries';

const CategoryForm = (props) => {
    const [values, setValues] = useState(props.defaultValues ?? {
        name: "",
        order: 0,
        isSystem: false
    });

    const [errors, setErrors] = useState({});
    const navState = useReactiveVar(naviDataVar);
    const {t} = useTranslation();

    const [saveMetricCategory] = useSaveMetricCategory()

    const fieldChanged = (event) => {
        const newValues = { ...values };
        newValues[event.target.name] = event.target.value

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });

        setValues(newValues);
    }

    const validationRules = {
        name: yup.string().required(t("required")),
        order: yup.number().required(t("required")),
    }

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors
    };

    const formSubmitted = (e) => {
        e.preventDefault();
        validateForm(values, validationRules, (errors) => {
          if (notHaveErrors(errors))
            saveData();
          else
            setErrors(errors);
        });
    };

    const saveData = () => {
        const variables = {
            input: {
                id: values.id ?? createGuid(),
                clubId: navState.clubId,
                teamId: navState.teamId,
                name: values.name,
                showInClub: values.showInClub,
                order: +values.order
            }
        }

        saveMetricCategory({variables: variables}).then(result => {
            props.onComplete();
        });
    };

    return (
        <>
            <form id={props.id} onSubmit={formSubmitted}>
                <Input
                    {...regField}
                    name="name"
                    label={t('Name')}
                    disabled={values.isSystem}
                />

                <Switch
                    {...regField}
                    fullSize={true}
                    name="showInClub"
                    label={t('Show in club')}
                    disabled={values.isSystem}
                />

                <Input
                    {...regField}
                    name="order"
                    type="number"
                    label={t('Order')}
                />
            </form>
        </>
    );
};

CategoryForm.defaultProps = {
    defaultValues: {},
    onComplete: () => {
    }
};

export default CategoryForm;
