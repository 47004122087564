import React, {useEffect, useRef, useState} from 'react';
import cn from 'classnames';
import Submenu from 'components/Navigation/Submenu/Submenu';
import Panel from 'components/UI/Panel/Panel';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import Button from 'components/UI/Button/Button';
import Tabs from 'components/UI/Tabs/Tabs';
import Search from 'components/UI/Panel/Search/Search';
import SwitchComponents from 'components/System/SwitchComponents';
import CategoryForm from './forms/CategoryForm';
import TestForm from './forms/TestForm';
import NormativesCategories from './NormativesCategories';
import NormativesTests from './NormativesTests';
import {useMetricData} from 'apollo/queries/ExerciseQueries';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import classes from './Normatives.module.scss';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';
import CanAccess from 'components/Navigation/CanAccess/CanAccess';

const Normatives = (props) => {
    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const [search, setSearch] = useState('');
    const [skip, setSkip] = useState(0);
    const [mode, setMode] = useState("tests");

    const [modalData, setModalData, initState] = ModalState();

    const [getMetricData, metricData] = useMetricData();

    useEffect(() => {
        if (!navState.clubId)
            return;

        const variables = {
            clubId: navState.clubId,
            teamId: navState.teamId
        };

        getMetricData({variables: variables});
    }, [navState]);

    const onComplete = () => {
        setModalData(initState);
        metricData.refetch();
    }

    const onAddTest = (row) => {
        const categories = metricData.data.metricCategories.items.map(x => ({label: x.name, value: x.id}));

        const data = {
            ...initState,
            form: "tests",
            title: t("Add test"),
            pages: {
                page: 0,
                maxPages: 2,
            },
            styles: {
                maxWidth: "700px",
            },
            defaultValues: {
                attempts: 1,
                unitOfMeasure: "QUANTITY",
                systemOfMeasurement: "MORE_BETTER",
                showInTeam: true,
                showInClub: true,
                order: 1
            },
            settings: {
                categories: categories
            },
            onComplete: onComplete,
            open: true
        }

        setModalData(data);
    }

    const onAddCategory = (row) => {
        const data = {
            ...initState,
            form: "categories",
            title: t("Add category"),
            onComplete: onComplete,
            open: true
        }

        setModalData(data);
    }

    const actions = () => {
        return (
          <CanAccess users={[SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin]}>
            {mode === "tests"
                ? <Button type="primary" style={{margin: 0}} onClick={onAddTest}>
                    <span><i className="icon-Plus-Square"></i> {t("Add test")}</span>
                </Button>
                : <Button type="primary" style={{margin: 0}} onClick={onAddCategory}>
                    <span><i className="icon-Plus-Square"></i> {t("Add category")}</span>
                </Button>
            }
          </CanAccess>
        );
    };

    const onSearch = (e) => {
        setSkip(0);
        setSearch(e.target.value);
    }

    const panelFilters = () => (
        <>
            <Tabs onClick={(val) => setMode(val)}
                  selected={mode}
                  items={props.tabs} />
        </>
    );

    const panelSearch = () => (
        <Search onSearch={onSearch} placeholder={t('Search test...')}/>
    );

    return (
        <>
            <div className={cn('page-header', classes.tests)}>
                <div className={classes.name}>{navState.teamName}</div>
                    <h2>{t('Normatives')}</h2>
                    <Submenu/>
            </div>

            <div className="page-content">
                <Panel border={false} action={actions} filters={panelFilters} search={panelSearch} />

                {mode === "categories" && <NormativesCategories {...metricData} />}
                {mode === "tests" && <NormativesTests {...metricData} />}
            </div>

            <ModalWindow2 {...modalData}>
                <SwitchComponents active={modalData.form}>
                    <CategoryForm {...modalData} name="categories" />
                    <TestForm {...modalData} name="tests" />
                </SwitchComponents>
            </ModalWindow2>
        </>
    );
};

Normatives.defaultProps = {
    tabs: [
        { id: 'tests', name: 'Tests'},
        { id: 'categories', name: 'Categories'},
    ],
};

export default Normatives;
