import React from 'react'
import toast, { Toaster } from 'react-hot-toast';

export const toastMessage = (type, message) => {
    if (type === true) return toast.success(message);
    if (type === false) return toast.error(message);
}

const Toast = (props) => {
    return (
        <Toaster
            position="bottom-center"
            reverseOrder={false}
            containerStyle={{
                bottom: 100
            }}
            toastOptions={{
                duration: 5000,
                success: {
                    style: {
                        background: '#3BCE9D',
                        color: '#ffffff',
                        padding: '12px 15px',
                        borderRadius: '4px',
                        minWidth: '300px'
                    },
                },
                error: {
                    style: {
                        background: '#ea6d3e',
                        color: '#ffffff',
                        padding: '12px 15px',
                        borderRadius: '4px',
                        minWidth: '300px'
                    },
                },
            }}
        />
    );
};

export default Toast;