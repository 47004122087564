import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar, systemDataVar} from 'apollo/cashe';
import {notHaveErrors, validateFields, validateForm} from 'utils/form';
import Input from 'components/UI/Input/Input';
import * as yup from 'yup';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';
import classes from './EvaluationForm.module.scss';
import DatePicker from 'components/UI/DatePicker/DatePicker';
import Select from 'components/UI/Select/Select';
import {useSaveClubGameEvaluation} from 'apollo/queries/ClubQueries';
import {createGuid} from 'utils/utils';

const GameEvaluationForm = (props) => {
    const {defaultValues, setLoading, onComplete, coaches} = props;

    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);
    const systemData = useReactiveVar(systemDataVar);

    const readOnly = ![SystemRoles.GroupManger, SystemRoles.GroupMember, SystemRoles.SysAdmin].includes(systemData.user.userRole.roleId);

    const [values, setValues] = useState(defaultValues);
    const [errors, setErrors] = useState({});

    const [saveClubGameEvaluation] = useSaveClubGameEvaluation();

    const fieldChanged = (event) => {
        const newValues = {...values};
        newValues[event.target.name] = event.target.value;

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });

        recalculate(newValues);
        setValues(newValues);
    };

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors
    };

    const numSettings = {
        type: 'number',
        min: 0,
        step: 0.5,
    }

    const validationRules = {
        date: yup.string().required(t('Required')),
        group: yup.string().required(t('Required')),
        place: yup.string().required(t('Required')),
        game: yup.string().required(t('Required')),
        coach: yup.string().required(t('Required')),

        beforeTraining: yup.number().min(0, t("Minimum value") + " 0").max(1, t("Maximum value") + " 1").typeError(t("Require")).required(t('Required')),
        afterTraining: yup.number().min(0, t("Minimum value") + " 0").max(1, t("Maximum value") + " 1").typeError(t("Require")).required(t('Required')),
        headquarters: yup.number().min(0, t("Minimum value") + " 0").max(0.5, t("Maximum value") + " 0.5").typeError(t("Require")).required(t('Required')),
        warmUp: yup.number().min(0, t("Minimum value") + " 0").max(0.5, t("Maximum value") + " 0.5").typeError(t("Require")).required(t('Required')),
        management: yup.number().min(0, t("Minimum value") + " 0").max(2, t("Maximum value") + " 2").typeError(t("Require")).required(t('Required')),
        behavior: yup.number().min(0, t("Minimum value") + " 0").max(2, t("Maximum value") + " 2").typeError(t("Require")).required(t('Required')),
        result: yup.number().min(0, t("Minimum value") + " 0").max(3, t("Maximum value") + " 3").typeError(t("Require")).required(t('Required')),
    }

    const recalculate = (values) => {
        values['totalClub'] = sum(values, ['beforeTraining', 'afterTraining', 'headquarters', 'warmUp', 'management', 'behavior', 'result']);
    };

    const sum = (newValues, items) => {
        let result = 0;
        items.forEach((item) => {
            result += newValues[item] !== undefined
              ? Number(newValues[item]) : 0;
        });
        return result;
    };

    const formSubmitted = async (e) => {
        e.preventDefault();
        setLoading(true);
        validateForm(values, validationRules, (errors) => {
            if (notHaveErrors(errors))
                onSubmit();
            else {
                setErrors(errors);
                setLoading(false);
            }

        });
    };

    const onSubmit = async () => {
        const document = {...values};

        const coachName = coaches.find(c => c.value === values.coach).label;

        const variables = {
            input: {
                id: values.id ?? createGuid(),
                clubId: navState.clubId,
                keyItem: values.coach,
                document: JSON.stringify(document),
                title:  coachName + ' - spēles procesa novērtējums',
                evaluation: document.totalClub,
            }
        }

        await saveClubGameEvaluation({variables});
        onComplete(values);
    };

    return (
      <form  id={props.id} onSubmit={formSubmitted}>
          <fieldset disabled={readOnly}>
              <div>
                  <div className={classes.columns11}>
                      <DatePicker
                        name="date"
                        key="k1-1"
                        label={"Datums"}
                        {...regField}
                      />

                      <Input name="group" label={"Vecuma grupa"} key="k1-2" {...regField} />
                  </div>

                  <div>
                      <Select
                        name="coach"
                        label={t('Coach')}
                        options={coaches ?? []}
                        value={values}
                        onChange={fieldChanged}
                        error={errors}/>
                  </div>

                  <div className={classes.columns11}>
                      <Input name="place" label={"Vieta"} key="k1-3" {...regField} />

                      <Input name="game" label={"Spēle"} key="k1-4" {...regField} />
                  </div>

                  <div className={classes.row}>
                      <div className="col-md-12 form-group">
                          <h3>Spēles vērtējums</h3>
                      </div>
                  </div>
                  <div className={classes.row}>
                      <div className="col-md-8 form-group">
                          Pirmsspēles treniņš
                      </div>
                      <div className="col-md-4 form-group">
                          <Input name="beforeTraining" key="k1" {...numSettings} {...regField} min={0} max={1} />
                      </div>
                  </div>
                  <div className={classes.row}>
                      <div className="col-md-8 form-group">
                          Pirmsspēles sapulce
                      </div>
                      <div className="col-md-4 form-group">
                          <Input name="afterTraining" key="k2" {...numSettings} {...regField} min={0} max={1} />
                      </div>
                  </div>
                  <div className={classes.row}>
                      <div className="col-md-8 form-group">
                          Treneru štābs
                      </div>
                      <div className="col-md-4 form-group">
                          <Input name="headquarters" key="k3" {...numSettings} {...regField} min={0} max={0.5} />
                      </div>
                  </div>
                  <div className={classes.row}>
                      <div className="col-md-8 form-group">
                          Iesildīšanās
                      </div>
                      <div className="col-md-4 form-group">
                          <Input name="warmUp" key="k4" {...numSettings} {...regField} min={0} max={0.5} />
                      </div>
                  </div>
                  <div className={classes.row}>
                      <div className="col-md-8 form-group">
                          Spēles vadīšana<br />
                          <small>Konstruktīvi padomi, emocionalitāte, maiņu veikšana</small>
                      </div>
                      <div className="col-md-4 form-group">
                          <Input name="management" key="k5" {...numSettings} {...regField} min={0} max={2} />
                      </div>
                  </div>
                  <div className={classes.row}>
                      <div className="col-md-8 form-group">
                          Uzvedība<br />
                          <small>Trenera, spēlētāju, attiecības ar tiesnešiem</small>
                      </div>
                      <div className="col-md-4 form-group">
                          <Input name="behavior" key="k6" {...numSettings} {...regField} min={0} max={2} />
                      </div>
                  </div>
                  <div className={classes.row}>
                      <div className="col-md-8 form-group">
                          Rezultāts<br />
                          <small>Spēlētāju darbības,komandas darbības,taktiskās darbības un t.t.</small>
                      </div>
                      <div className="col-md-4 form-group">
                          <Input name="result" key="k7" {...numSettings} {...regField} min={0} max={3} />
                      </div>
                  </div>
              </div>
          </fieldset>
      </form>
    );
};

GameEvaluationForm.defaultProps = {};

export default GameEvaluationForm;
