import {gql, useMutation, useQuery} from '@apollo/client';
import {TEAMS_LIST} from './ClubQueries';

export const TEAM_PLAYERS = gql`
    query GetClubs($clubId: String!, $teamId: Uuid!, $skip: Int!) {
        teams(clubId: $clubId, where:{teamId:{eq: $teamId}}) {
            totalCount
            items {
                teamId
                name
                photo
                photoUploadDate
                birthYear
                suffix
                gender
                goalkeepersOnly
                playerCount
                games
                wins
                draws
                losses
                goals
                trainers {
                    fullName
                }
            }
        }

        players(clubId:$clubId, where: {teams: {some: {team: {teamId: {eq: $teamId}}}}}, skip:$skip, take:100) {
            totalCount
            items {
                playerId
                firstName
                lastName
                fullName
                dateOfBirth
                licenceNr
                healthCheckNextDate
                teams (where: {teamId: {eq: $teamId}}) {
                    teamMemberId
                    teamNumber
                    group
                    position
                    startDate
                    endDate
                }
            }
        }
    }`;

export const useTeam = (variables) => useQuery(TEAM_PLAYERS, {variables});


const GET_TEAM_STAT = gql`
    query TeamStatistic($teamId: Uuid!, $season: String, $competition: String, $opponent: String) {
        competitions: championships(
            teamId: $teamId
            where: { season: { eq: $season } }
        ) {
            totalCount
            items {
                id: championshipId
                name
            }
        }
    
        teamOpponentsForStatistic(teamId: $teamId, season: $season, championshipId: $competition) {
            id
            name
        }
    
        teamStatistic(teamId: $teamId, season: $season, championshipId: $competition, opponent: $opponent) {
            games
            wins
            losses
            draws
            goals
            score
        }
    
        teamGoalStatistic(teamId: $teamId, season: $season, championshipId: $competition, opponent: $opponent) {
            xMin
            xMax
            yMin
            yMax
            data
        }
        
        teamTendency(teamId: $teamId, season: $season, championshipId: $competition, opponent: $opponent) {
            xMin
            xMax
            yMin
            yMax
            data
        }

        teamGoalMapStatistic(teamId: $teamId, season: $season, championshipId: $competition, opponent: $opponent) {
            id
            gameDataEventId
            data
        }
        
        teamPlayerStatistic(teamId: $teamId, season: $season, championshipId: $competition, opponent: $opponent) {
            playerId
            teamNumber
            playerName
            position
            goalkeeper
            licenceNr
            matchesPlayed
            matchesPlayedInMainTeam
            goals
            minutesPlayed
            assisted
            yellowCards
            redCards
            performanceRate
            goalsConceded
            cleanSheet
            penaltySavingRate
        }
    }`;
export const useTeamStat = (variables) => useQuery(GET_TEAM_STAT, {variables});

export const SAVE_TEAM = gql`
    mutation SaveTeam($input: TeamInput!) {
        saveTeam(input: $input) {
            teamId
            name
            playerCount
            games
            wins
            draws
            losses
            goals
            trainers {
                fullName
            }
        }
    }`;
export const useSaveTeam = () => useMutation(SAVE_TEAM);

export const DELETE_TEAM = gql`
    mutation DeleteTeam($input: DeleteTeamInput!) {
        deleteTeam(input: $input)
    }`;
export const useDeleteTeam = (variables) => useMutation(DELETE_TEAM, {refetchQueries: [{ query: TEAMS_LIST, variables: variables }]});

export const GET_ARCHIVED_TEAMS = gql`
    query GetArchivedTeams($clubId: String!, $skip: Int) {
        archivedTeams(clubId: $clubId, order: { archive: DESC }, take: 100, skip: $skip) {
            totalCount
            items {
                teamId
                name
                gender
                archive
            }
        }
    }`;
export const useArchivedTeams = (variables) => useQuery(GET_ARCHIVED_TEAMS, {variables, fetchPolicy: 'network-only'});

export const RESTORE_TEAM = gql`
    mutation RestoreTeam($input: RestoreTeamInput!) {
        restoreTeam(input: $input)
    }`;
export const useRestoreTeam = (variables) => useMutation(RESTORE_TEAM, {refetchQueries: [{ query: TEAMS_LIST, variables: variables }]});


export const ADD_TEAM_PLAYERS = gql`
    mutation AddTeamPlayers($input: TeamPlayersInput!) {
        addTeamPlayers(input: $input)
    }`;
export const useAddTeamPlayers = () => useMutation(ADD_TEAM_PLAYERS);

export const REMOVE_TEAM_PLAYERS = gql`
    mutation RemoveTeamPlayers($input: TeamPlayersInput!) {
        removeTeamPlayers(input: $input)
    }`;
export const useRemoveTeamPlayers = () => useMutation(REMOVE_TEAM_PLAYERS);


export const SAVE_TEAM_PLAYER = gql`
    mutation SaveTeamPlayer($input: TeamPlayerInput!) {
        saveTeamPlayer(input: $input)
    }`;
export const useSaveTeamPlayer = () => useMutation(SAVE_TEAM_PLAYER);
