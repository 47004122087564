import React, {useEffect, useState} from 'react';
import {durationFilter, filterOptions, library, loadLegacyScripts} from './Settings';
import {useTranslation} from 'react-i18next';
import Submenu from 'components/Navigation/Submenu/Submenu';
import Panel from 'components/UI/Panel/Panel';
import cn from 'classnames';
import Select from 'components/UI/Select/Select';
import Search from 'components/UI/Panel/Search/Search';
import classes from './ExerciseLibrary.module.scss';
import Button from 'components/UI/Button/Button';
import ExerciseForm from './forms/ExerciseForm/ExeciseForm';
import {toastMessage} from 'components/UI/Toast/Toast';
import {useExercises} from 'apollo/queries/ExerciseQueries';
import Pagination from 'components/UI/Pagination/Pagination';
import {TextAnnouncement} from 'utils/utils';
import Tabs from 'components/UI/Tabs/Tabs';
import GroupedCategories from './GroupedCategories';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import SwitchComponents from 'components/System/SwitchComponents';
import Exercise from './Exercise';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar, systemDataVar} from 'apollo/cashe';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';
import CanAccess from 'components/Navigation/CanAccess/CanAccess';

const ExerciseLibrary = (props) => {
    const {action, defaultValues, onComplete} = props;

    const {t} = useTranslation();
    const systemData = useReactiveVar(systemDataVar);
    const navState = useReactiveVar(naviDataVar);

    const onPage = 12;
    const selectMode = action === "select";

    const [search, setSearch] = useState('');
    const [skip, setSkip] = useState(0);
    const [filters, setFilters] = useState(library());
    const [showMore, setShowMore] = useState(false);
    const [mode, setMode] = useState("categories");
    const [settings, setSettings] = useState({});
    const [height, setHeight] = useState(120);
    const [width, setWidth] = useState(120);

    const [selected, setSelected] = useState({ categories: [], tags: []});

    const [getExercises, exercisesData] = useExercises();

    const [modalData, setModalData, initState] = ModalState();

    useEffect(() => {
        loadLegacyScripts();
    }, []);

    useEffect(() => {
        const clientWidth = document.getElementById("appContent").clientWidth;
        const imageWidth = calcWidth(clientWidth);
        const imageHeight = Math.round(imageWidth) / 1.5;

        setWidth(imageWidth);
        setHeight(imageHeight);
    }, []);


    const calcWidth = (width) => {
        if (width >= 1350)
           return (width - 400) / 6;
        if (width >= 700)
            return (width - 360) / 4;
        return (width - 20) / 2;
    }

    useEffect(() => {
        if (!navState.clubId)
            return;

        const categories = [].concat(...filters.categories.map((c) => c.items.filter(x => x.selected).map(i => i.name)));
        const tags = filters.tags.filter(x => x.selected).map(i => i.tag);

        const variables = {
            clubId: navState.clubId,
            where: {
                deleted: {eq:false},
                name:{contains: search}
            },
            take: onPage,
            skip: skip * onPage,
        }

        if (categories.length) variables.where.categories = {some:{in: categories}};
        if (tags.length) variables.where.tags = {some:{in: tags}};
        if (filters.accessType !== "") variables.where.accessType = {eq:filters.accessType};
        if (filters.language !== "") variables.where.language = {eq:filters.language};
        if (filters.duration !== "") variables.where.duration = durationFilter(filters.duration);

        setSettings(variables);
        getExercises({ variables: variables });

        const selectedCategories = [].concat(...filters.categories.map((c) => c.items.filter(x => x.selected)));
        const selectedTags = filters.tags.filter(x => x.selected);
        setSelected({categories: selectedCategories, tags: selectedTags});

    }, [navState.clubId, skip, filters, search])

    const AddExercise = () => {
        const data = {
            ...initState,
            form: "edit",
            title: t("Add exercise"),
            pages: {
                page: 0,
                maxPages: 4,
            },
            styles: {
                maxWidth: "700px",
            },
            defaultValues: {
                duration: 5,
                rhythm: 140,
                coefficient: 0.4,
                level: "ADVANCED",
                language: systemData.settings.general.language,
                ageGroup: 7,
                numberOfPlayers: 8,
                accessType: "CLUB",
                categoryList: [...library().categories],
                tagList: [...library().tags],
            },
            settings: settings,
            onComplete: onSaveExercise,
            open: true
        }

        setModalData(data);
    }

    const onOpenExercise = (exercise) => {
        //onEditExercise(exercise);
        const data = {
            ...initState,
            form: "view",
            title: exercise.name,
            styles: {
                maxWidth: "700px",
                noPadding: true
            },
            buttons: {
                hideSaveButton: !selectMode,
                saveButtonText: selectMode ? t("Select") : null
            },
            defaultValues: {
                ...exercise
            },
            onEditExercise: onEditExercise,
            onComplete: onSelectExercise,
            open: true
        }

        setModalData(data);
    }

    const onSelectExercise = (data) => {
        setModalData(initState);
        const newData = {...data, ...defaultValues};
        onComplete(newData);
    }

    const onEditExercise = (exercise) => {
        const data = {
            ...initState,
            form: "edit",
            title: t("Edit exercise"),
            pages: {
                page: 0,
                maxPages: 4,
            },
            styles: {
                maxWidth: "700px",
            },
            defaultValues: {
                ...exercise,
                categoryList: [...library().categories],
                tagList: [...library().tags],
            },
            settings: settings,
            onComplete: onSaveExercise,
            open: true
        }

        setModalData(data);
    }

    const onSaveExercise = (data) => {
        if (data.result) {exercisesData.refetch();}

        toastMessage(data.result, data.message);
        setModalData(initState);
    }

    const actions = () => (
      <CanAccess users={[SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin]}>
        <Button type="primary" style={{margin: 0}} onClick={AddExercise}>
            <span><i className="icon-Plus-Square"></i> {t("Add exercise")}</span>
        </Button>
      </CanAccess>
    );

    const panelFilters = () => (
        <>
            <Tabs onClick={(val) => setMode(val)}
                  selected={mode}
                  items={props.tabs} />
        </>
    );

    const panelSubFilters = () => (
        <>
            <Select
                small="small"
                smallStyle={{margin :0}}
                name="accessType"
                value={filters}
                onChange={onFilterChange}
                options={filterOptions.accessType}
                label={"Type"}/>

            <Select
                small="small"
                name="duration"
                value={filters}
                onChange={onFilterChange}
                options={filterOptions.duration}
                label={"Duration"}/>

            <Select
                small="small"
                name="numberOfPlayers"
                value={filters}
                onChange={onFilterChange}
                options={filterOptions.players}
                label={"Players"}/>

            <Select
                small="small"
                name="ageGroup"
                value={filters}
                onChange={onFilterChange}
                options={filterOptions.age}
                label={"Age"}/>

            <Select
                small="small"
                name="level"
                value={filters}
                onChange={onFilterChange}
                options={filterOptions.level}
                label={"Level"}/>

            <Select
                small="small"
                name="language"
                value={filters}
                onChange={onFilterChange}
                options={filterOptions.language}
                label={"Language"}/>
        </>
    );

    const onSearch = (e) => {
        setSkip(0);
        setSearch(e.target.value);
    }

    const panelSearch = () => (
        <Search onSearch={onSearch} placeholder={t('Search training...')}/>
    );

    const onFilterCategory = (selected) => {
        const newFilters = {...filters};
        const group = newFilters.categories.find(c => c.items.find(x => x.id === selected.id));
        const category = group.items.find(x => x.id === selected.id);
        category.selected = !category.selected;
        setFilters(newFilters);
    }

    const onFilterTag = (selected) => {
        const newFilters = {...filters};
        const tag = newFilters.tags.find(x => x.id === selected.id);
        tag.selected = !tag.selected;
        setFilters(newFilters);
    }

    const onFilterChange = (event) => {
        const newFilters = {...filters};
        newFilters[event.target.name] = event.target.value
        setFilters(newFilters);
    }

    const mainFilters = [].concat(...filters.categories.map(x => x.items.filter(i => i.default)))
        .sort((a, b) => a.default - b.default);

    const ExercisePreview = ({data}) => {
        const {image, scheme, name, duration, numberOfPlayers, repeat, ageGroup, description, tags} = data;
        const exerciseImage = image ? image :
            scheme?.image ? scheme?.image : "/assets/images/exercises/no-image.jpg";

        const tagMaxCount = width < 300 ? 1 : 2;
        return (
            <div className={classes.exercise} onClick={() => onOpenExercise(data)}>
                <div className={cn(classes.preview, {[classes.schemeBg]: !image && scheme?.image})} style={{height: height}}>
                    <img className={classes.image} style={!image ? {backgroundImage: `url(${scheme?.background})`}: undefined}
                         src={exerciseImage} title={name} alt={name} />
                    <div className={classes.previewTags}>
                        {tags.slice(0, tagMaxCount).map(t =>
                            <span key={t} className={classes.previewTag}>{t}</span>)}
                        {tags.length > tagMaxCount ?
                            <span className={classes.previewTag}>+ {tags.length - tagMaxCount}</span> : undefined}
                    </div>
                </div>
                <div className={classes.description}>
                    <div className={classes.title}>{name}</div>
                    <div className={classes.panel}>
                        <div><span className="icon-Time-Circle"></span> {duration ? duration : "-"}</div>
                        <div><span className="icon-Team"></span> {numberOfPlayers ? numberOfPlayers : "-"}</div>
                        <div><span className="icon-refresh"></span> {repeat ? repeat : "-"}</div>
                        <div><span className="icon-happy"></span> {ageGroup ? ageGroup : "-"}</div>
                    </div>
                    {description && <div className={classes.announcement}>
                        {TextAnnouncement(description)}
                    </div>}
                </div>
            </div>
        );
    }

    return (
        <>
            {!selectMode && <div className="page-header">
                <h2>{t('Training library')}</h2>
                <Submenu />
            </div>}
            <div className="page-content">
                <Panel border={false} action={actions} filters={panelFilters} subFilters={panelSubFilters} search={panelSearch} >
                    <br/>
                </Panel>

                {mode === "categories" && <>
                    <h3>{t("Categories")}</h3>
                    <div className={classes.categoryGroup}>
                        {mainFilters.map(category => {
                            const categoryName = t(category.name);
                            return (
                                <div className={cn(classes.category, {[classes.selectedCategory]: category.selected})} key={"main"+category.id} onClick={() => onFilterCategory(category)}>
                                    <img className={classes.image} src={category.image} alt={categoryName} />
                                    <div className={classes.title}>{categoryName}</div>
                                </div>
                            );
                        })}
                    </div>

                    <div className={classes.loadMore}><Button onClick={() => setShowMore(!showMore)} type="third">Show more categories</Button></div>
                    {showMore && <GroupedCategories data={filters.categories} onFilter={onFilterCategory} classes={classes} />}
                </>}

                {mode === "tags" && <>
                    <h3>{t("Tags")}</h3>
                    <div className={classes.tags}>
                        {filters.tags.map(x => (<span key={x.id} onClick={() => onFilterTag(x)} className={cn(classes.tag, {[classes.selectedTag]: x.selected})}>{x.tag}</span>))}
                    </div>
                </>}

                <h3>{t("Discover exercises")}</h3>

                {selected.categories.length > 0 || selected.tags.length > 0
                    ? <div className={classes.list}>
                    {selected.categories.map(category => <span key={category.id} onClick={() => onFilterCategory(category)}
                                                        className={classes.selected}>{category.name} <span>x</span></span>)}
                    {selected.tags.map(tag => <span key={tag.id} onClick={() => onFilterTag(tag)}
                                                    className={classes.selected}>{tag.tag} <span>x</span></span>)}
                </div> : undefined}

                {exercisesData.loading && <>Loading...</>}
                {exercisesData.error && <>Error...</>}
                {exercisesData.data && <>
                    <div id="exercises" className={classes.exercises}>
                        {exercisesData.data.exercises.items.map(item => <ExercisePreview key={item.id} data={item} />)}
                        {exercisesData.data.exercises.items.length === 0 && <div>{t("No exercises found.")}</div>}
                    </div>

                    <Pagination page={skip} count={exercisesData.data.exercises.totalCount}  onPage={onPage}
                                    onPageChange={(p) => setSkip(p.selected)}/>
                </>}
            </div>

            <ModalWindow2 {...modalData}>
                <SwitchComponents active={modalData.form}>
                    <Exercise {...modalData} name="view" />
                    <ExerciseForm {...modalData} name="edit" />
                </SwitchComponents>
            </ModalWindow2>
        </>
    );
};

ExerciseLibrary.propTypes = {};
ExerciseLibrary.defaultProps = {
    libraries: {
        exercises: 'Exercises',
        tests: 'Tests'
    },
    tabs: [
        { id: 'categories', name: 'Categories'},
        { id: 'tags', name: 'Tags'},
    ],
};

export default ExerciseLibrary;
