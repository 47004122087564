import { createBrowserHistory } from "history";
import {stripTrailingSlash} from './utils';
import {menu} from '../components/Navigation/Menu/Settings';

const customHistory = createBrowserHistory();
export default customHistory;

export const getBaseUrl = (path, param, hash) => {
  let _path = stripTrailingSlash(path) + "/";
  _path = stripTrailingSlash(_path + param);

  if (_path === "") _path = "/";
  return _path + hash;
}

export const getUrl = (item, navState) => {
  const base = getBaseUrl(item.path, item.param, item.hash);
  return base
      .replace("!GROUPID!", navState.groupId ?? "")
      .replace("!CLUBID!", navState.clubId ?? "")
      .replace("!TEAMID!", navState.teamId ?? "")
      .replace("!PLAYERID!", navState.playerId ?? "");
}

export const findInMenu = (navState) => {

  const path = document.location.pathname
      .replace(navState.groupId, "!GROUPID!")
      .replace(navState.clubId, "!CLUBID!")
      .replace(navState.teamId, "!TEAMID!")
      .replace(navState.playerId, "!PLAYERID!");

  let selected = findPath(menu.club.items, path);
  if (!selected) selected = findPath(menu.group.items, path);
  if (!selected) selected = findPath(menu.team.items, path);
  if (!selected) selected = findPath(menu.player.items, path);
  if (!selected) selected = findPath(menu.system.items, path);

  const result = {
    id: selected?.id ?? null,
    hash: selected?.hash ?? null,
    items: selected?.submenu ?? [],
    submenu: {
      id: selected?.id
    }};

  return result;
}

const findPath = (obj, path) => {
  const itemIndex = Object.keys(obj).find(key => obj[key].path === path);
  return obj[itemIndex];
}

