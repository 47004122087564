import React, {useEffect} from 'react';
import Table from 'components/UI/Table/Table';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import {difDatesYears} from 'utils/date';

const CoachTable = ({onDataLoaded, onSelectCoach}) => {
  const navState = useReactiveVar(naviDataVar);
  const {t} = useTranslation();
  const {loading, error, data} = {};// useCoachList(navState.clubId);

  useEffect(() => {
    if (loading || error) return;

    const evaluationsData = data.trainers.items.filter(item =>
        item.lastDate && difDatesYears(item.lastDate.date, new Date()));

    let [club, couches] = [0, 0];
    evaluationsData.forEach(item => {
      club += item.averageEvaluation.club;
      couches += item.averageEvaluation.couch;
    });

    const stat = {
      count: evaluationsData.length,
      club: evaluationsData.length > 0 ? Math.round(club / evaluationsData.length * 100) / 100 : '-',
      couches: evaluationsData.length > 0 ? Math.round(couches / evaluationsData.length * 100) / 100 : '-'
    };
    onDataLoaded(stat);

  }, [loading, error, data]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error!</div>;

  return (
      <Table
          data={data.trainers.items}
          columns={[
            {
              Header: t('Coach'),
              accessor: 'firstName',
              Cell: (cellData) => <div>{cellData.value} {cellData.row.original.lastName}</div>
            },
            {
              Header: t('Teams'),
              accessor: 'teams',
              Cell: (cellData) => <div style={{maxWidth: '350px'}}>{cellData.value.map(e => e.team.name).join(', ')}</div>
            },
            {
              Header: t('Evaluation'),
              accessor: 'averageEvaluation',
              Cell: (cellData) => <div>{cellData.value ? cellData.value.club
                  + '/' + cellData.value.couch : null}</div>
            }
          ]}
          onRowClick={onSelectCoach}/>
  );
}

export default CoachTable;
