import React, {useEffect, useState} from 'react';
import Input from 'components/UI/Input/Input';
import {useTranslation} from 'react-i18next';
import {useTranslates} from 'apollo/queries/SettingQueries';
import Table from 'components/UI/Table/Table';
import Subtext from 'components/UI/Table/Subtext/Subtext';
import Pagination from 'components/UI/Pagination/Pagination';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import TranslateEditor from './TranslateEditor';
import i18n from 'i18next';
import classes from '../Localization.module.scss';

const LocalizationEditor = (props) => {
    const {defaultValues} = props;

    const {t} = useTranslation();
    const onPage = 10;
    const language = defaultValues.locale.toUpperCase();

    const [skip, setSkip] = useState(0);
    const [where, setWhere] = useState();

    const [modalData, setModalData, initState] = ModalState();

    const {loading, error, data, refetch} = useTranslates({
        language: language,
        where: where,
        skip: skip
    });

    useEffect(() => {
        if (!defaultValues.search) {
            return;
        }
        prepareSearch(defaultValues.search);
    }, [defaultValues.search]);

    const onSearch = (e) => {
        const key = e.target.value;
        prepareSearch(key);
    }

    const prepareSearch = (key) => {
        setWhere({
            or: [
                {value: {contains: key}},
                {code: {contains: key}}
            ]
        });
    }

    const onRowClick = (data) => {
        const _data = {
            ...initState,
            title: t("Edit translate"),
            styles: {
                maxWidth: "700px",
            },
            defaultValues: {
                messageKeyId: data.messageKeyId,
                language: language,
                code: data.code,
                prefix: data.prefix,
                value: data.value
            },
            onComplete: onComplete,
            open: true
        }

        setModalData(_data);
    }

    const onComplete = async () => {
        localStorage.removeItem(`i18next_res_${defaultValues.locale}-translation`);
        await i18n?.reloadResources();
        window.location.reload();
    }

    return (
        <>
            <div>
                <Input
                    name="search"
                    onChange={onSearch}
                    placeholder={t("Search...")}
                    value={{search: defaultValues.search}}
                />

                {loading ? <>Loading...</> : undefined}
                {error ? <>Error...</> : undefined}

                {data ? <>
                    <Table
                        data={data.translates.items}
                        columns={[
                            {
                                Header: t('Id'),
                                accessor: 'messageKeyId',
                                className: classes.buttonColumn,
                            },
                            {
                                Header: t('Translate'),
                                accessor: 'value',
                                Cell: (cellData) => <div>
                                    {cellData.value}
                                    <Subtext>{t("Tag")}: {cellData.row.original.code}</Subtext>
                                </div>
                            },
                        ]}
                        onRowClick={onRowClick}/>

                    <Pagination page={skip} onP count={data.translates.totalCount} onPage={onPage} onPageChange={(p) => setSkip(p.selected)}/>
                </> : undefined}
            </div>

            <ModalWindow2 {...modalData}>
                <TranslateEditor {...modalData} />
            </ModalWindow2>
        </>
    );
};

LocalizationEditor.defaultProps = {};

export default LocalizationEditor;
