import React, {useState} from 'react';
import Submenu from 'components/Navigation/Submenu/Submenu';
import Panel from 'components/UI/Panel/Panel';
import {useTranslation} from 'react-i18next';
import Search from 'components/UI/Panel/Search/Search';
import classes from './ClubAdmins.module.scss';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import cn from 'classnames';
import Subtext from 'components/UI/Table/Subtext/Subtext';
import Table from 'components/UI/Table/Table';
import Pagination from 'components/UI/Pagination/Pagination';
import {showDate} from 'utils/date';
import Button from 'components/UI/Button/Button';
import StaffForm from '../Access/forms/StaffForm';
import {useArchiveTrainer, useClubAdmins} from 'apollo/queries/TrainerQueries';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';

const ClubAdmins = (props) => {
    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);
    const onPage = 20;

    const [skip, setSkip] = useState(0);
    const [search, setSearch] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);

    const {data, loading, error, refetch}  = useClubAdmins({
        clubId: navState.clubId,
        teamId: navState.teamId,
        search: search,
        take: onPage,
        skip: skip * onPage,
    });

    const [modalData, setModalData, initState] = ModalState();
    const [archiveTrainer] = useArchiveTrainer();

    const onAddClick = () => {
        const data = {
            ...initState,
            form: "staff",
            title: t("Add new admin"),
            pages: {
                page: 0,
                maxPages: 3,
            },
            styles: {
                maxWidth: "700px",
            },
            defaultValues: {
                isAdministrator: true,
                isAdministratorDisabled: true,
            },
            onComplete: onComplete,
            open: true
        }

        setModalData(data);
    }

    const onArchiveClick = () => {
        selectedRows.forEach(row => {
            const variables = {
                input: {
                    id: row.trainerId,
                    clubId: navState.clubId,
                }
            };

            archiveTrainer({variables: variables}).then((result) => {
                if (result) refetch();
            });
        });
    }

    const actions = () => (
        <>
            <Button type="primary" style={{margin: 0}} onClick={onAddClick}>
                <span><i className="icon-Plus-Square"></i> {t('Add admin')}</span>
            </Button>

            {selectedRows.length > 0 &&
                <Button type="secondary" style={{margin: 0}} onClick={onArchiveClick}>
                    <span>{t('Archive')}</span>
                </Button>
            }
        </>
    );

    const onSearch = (e) => {
        setSkip(0);
        setSearch(e.target.value);
    }

    const panelSearch = () => (
        <Search onSearch={onSearch} placeholder={t("Search admin...")} />
    );

    const onEdit = (data) => {
        const _data = {
            ...initState,
            form: "staff",
            title: t("Edit admin"),
            pages: {
                page: 0,
                maxPages: 3,
            },
            styles: {
                maxWidth: "700px",
            },
            defaultValues: data,
            onComplete: onComplete,
            open: true
        }

        setModalData(_data);
    }

    const onComplete = () => {
        setModalData({...initState});
        refetch();
    };

    const onAssign = () => {

    }

    return (
        <>
            <div className={cn('page-header', classes.coaches)}>
                <div className={classes.name}>{navState.clubName}</div>
                <h2>{t('Admins')}</h2>
                <Submenu />
            </div>
            <div className="page-content">
                <Panel border={false} action={actions} search={panelSearch}>
                    <br />
                </Panel>

                {loading && <>Loading...</>}
                {error && <>Error...</>}

                {data ?
                    <>
                        <Table
                            data={data.admins.items}
                            columns={[
                                {
                                    Header: t('Admin'),
                                    accessor: 'fullName',
                                    Cell: (cellData) => {
                                        const {certification, certificationEndDate} = cellData.row.original;
                                        return (<div onClick={() => onEdit(cellData.row.original)}>
                                            {cellData.value}
                                            <Subtext>
                                                {t("Certification")}:
                                                {certification ? <> {certification}
                                                    {certificationEndDate ? <>, {showDate(certificationEndDate)}</>
                                                        : undefined }</> : " -"}
                                            </Subtext>
                                        </div>)
                                    }
                                },
                                {
                                    Header: t('Contacts'),
                                    accessor: 'email',
                                    Cell: (cellData) => {
                                        const email = cellData.value;
                                        const phone = cellData.row.original.phoneNumber;
                                        return (<>
                                            <a href={`mailto:${email}`}>{email}</a>
                                            <Subtext>
                                                {t("Phone")}: {phone ? <a href={`phone:${phone}`}>{phone}</a> : "-"}
                                            </Subtext>
                                        </>);
                                    }
                                },
                            ]}
                            onSelectClick={(x) => setSelectedRows(x)}
                            onRowClick={() => {}}
                        />

                        <Pagination page={skip} count={data.admins.totalCount} onPage={onPage}
                                    onPageChange={(p) => setSkip(p.selected)}/>
                    </>
                    :undefined
                }
            </div>

            <ModalWindow2 {...modalData}>
                <StaffForm {...modalData} name="staff" />
            </ModalWindow2>
        </>
    );
};

ClubAdmins.defaultProps = {
    options: [
        {label: "Select from staff", value: "select"},
        {label: "Add new employer", value: "addNew"},
    ]
};

export default ClubAdmins;

