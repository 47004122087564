import React, {useRef} from 'react';
import TinySlider from "tiny-slider-react";
import 'tiny-slider/dist/tiny-slider.css';
import classes from './Slider.module.scss';
import cn from 'classnames';

const Slider = (props) => {
    const sliderRef = useRef();

    const tinySettings = {
        lazyload: true,
        nav: false,
        mouseDrag: true,
        items: 1,
        controls: false,
        responsive: {
            420: {
                items: 1
            }
        }
    };

    const onLeft = () => {
        sliderRef.current.slider.goTo("prev");
    }

    const onRight = () => {
        sliderRef.current.slider.goTo("next");
    }

    return (
        <div className={classes.slider}>
            {props.children && Array.isArray(props.children) && props.children.length > 1
                ? <div className={classes.controls}>
                    <span onClick={onLeft} className={cn("icon-back_arrow_icon", classes.arrow)}></span>
                    <div></div>
                    <span onClick={onRight} className={cn("icon-forward_arrow_icon", classes.arrow)}></span>
                </div>
                : undefined }
            <TinySlider ref={sliderRef} settings={tinySettings}>
                {props.children}
            </TinySlider>
        </div>

    );
};

Slider.defaultProps = {};

export default Slider;
