import React, {useState} from 'react';
import Select from '../../../../components/UI/Select/Select';
import ScrollList from '../../../../components/UI/ScrollList/ScrollList';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from '../../../../apollo/cashe';
import {useClubCoaches} from '../../../../apollo/queries/TrainerQueries';

const SelectCoachForm = (props) => {
    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);
    const [values, setValues] = useState({
        type: "TRAINER"
    });
    const [addCoaches, setAddCoaches] = useState([]);

    const {data, loading, error}  = useClubCoaches({
        clubId: navState.clubId,
        search: "",
        take: 100,
        skip: 0,
    });


    const formSubmitted = (e) => {
        e.preventDefault();

        const data = {
            trainersIds: addCoaches.map(x => x.trainerId),
            type: values.type
        };

        props.onAddCoaches(data);
    }

    const fieldChanged = (event) => {
        const newValues = { ...values };
        newValues[event.target.name] = event.target.value
        setValues(newValues);
    }

    const regField = {
        value: values,
        onChange: fieldChanged,
    };

    const onTrainerChange = (coach) => {
        const coaches = [...addCoaches];
        const index = coaches.findIndex(f => f.trainerId === coach.trainerId);
        index === -1 ? coaches.push(coach) : coaches.splice(index,1);
        setAddCoaches(coaches);
    }

    return (
        <>
            <form id={props.id} onSubmit={formSubmitted}>
                <Select
                    name="type"
                    {...regField}
                    options={props.roles}
                    label={t('Role')}
                />

                <ScrollList>
                    {loading && (<li>Loading...</li>)}
                    {error && (<li>Error...</li>)}
                    {data?.trainers?.items && data.trainers.items.map((trainer) =>
                        <li key={trainer.trainerId}>
                            <input type="checkbox" onChange={() => onTrainerChange(trainer)}
                                   checked={props.existedTrainerIds.indexOf(trainer.trainerId) !== -1 || addCoaches.find(f => f.trainerId === trainer.trainerId)  !== undefined}
                                   disabled={props.existedTrainerIds.indexOf(trainer.trainerId) !== -1}
                                   style={{position: 'relative', top: '1px', marginRight: '5px' }} />{trainer.fullName}
                        </li>)}
                </ScrollList>
            </form>
        </>
    );
};

SelectCoachForm.defaultProps = {
    roles: [
        {label: "Coach", value: "TRAINER"},
        {label: "Assistant", value: "ASSISTANT"}
    ],
    existedTrainerIds: [],
    onAddCoaches: () => {}
};

export default SelectCoachForm;
