import {SystemRoles} from 'components/Navigation/CanAccess/roles';

export const menu = {
  player: {
    name: 'Player',
    collapsed: false,
    access: [SystemRoles.Player, SystemRoles.Parent, SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.GroupMember, SystemRoles.GroupManger, SystemRoles.SysAdmin],
    items: [
      {
        id: 'p1',
        type: 1,
        menu: "player",
        access: [SystemRoles.Player, SystemRoles.Parent, SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.GroupMember, SystemRoles.GroupManger, SystemRoles.SysAdmin],
        icon: 'icon-Profile',
        name: 'Player profile',
        path: '/club-!CLUBID!/team-!TEAMID!/player-!PLAYERID!',
        param: '',
        hash: '',
        submenu: [
          {
            id: 'p1-1',
            type: 1,
            access: [SystemRoles.Player, SystemRoles.Parent, SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.GroupMember, SystemRoles.GroupManger, SystemRoles.SysAdmin],
            name: 'Player profile',
            path: '/club-!CLUBID!/team-!TEAMID!/player-!PLAYERID!',
            param: '',
            hash: '',
          },
          {
            id: 'p1-2',
            type: 1,
            access: [SystemRoles.Player, SystemRoles.Parent, SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.GroupMember, SystemRoles.GroupManger, SystemRoles.SysAdmin],
            name: 'Skills',
            path: '/club-!CLUBID!/team-!TEAMID!/player-!PLAYERID!/skills',
            param: '',
            hash: '',
          },
          {
            id: 'p1-3',
            type: 1,
            access: [SystemRoles.Player, SystemRoles.Parent, SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.GroupMember, SystemRoles.GroupManger, SystemRoles.SysAdmin],
            name: 'Career',
            path: '/club-!CLUBID!/team-!TEAMID!/player-!PLAYERID!/career',
            param: '',
            hash: '',
          },
          // {
          //   id: 'p1-4',
          //   type: 0,
          //   access: [SystemRoles.Player, SystemRoles.Parent, SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
          //   name: 'Tests',
          //   path: '/Player/Details',
          //   param: '!PLAYERID!/?teamId=!TEAMID!',
          //   hash: '#player-med-test-panel',
          // },
          {
            id: 'p1-5',
            type: 1,
            access: [SystemRoles.Player, SystemRoles.Parent, SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.GroupMember, SystemRoles.GroupManger, SystemRoles.SysAdmin],
            name: 'Communication',
            path: '/club-!CLUBID!/team-!TEAMID!/player-!PLAYERID!/communication',
            param: '',
            hash: '',
          },
        ]
      },
      {
        id: 'p2',
        type: 1,
        menu: "player",
        access: [SystemRoles.Player, SystemRoles.Parent, SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.GroupMember, SystemRoles.GroupManger, SystemRoles.SysAdmin],
        icon: 'icon-Time-Square',
        name: 'Attendance',
        path: '/club-!CLUBID!/team-!TEAMID!/player-!PLAYERID!/attendance-trainings',
        param: '',
        hash: '',
        submenu: [
          {
            id: 'p2-1',
            type: 1,
            access: [SystemRoles.Player, SystemRoles.Parent, SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.GroupMember, SystemRoles.GroupManger, SystemRoles.SysAdmin],
            name: 'Trainings',
            path: '/club-!CLUBID!/team-!TEAMID!/player-!PLAYERID!/attendance-trainings',
            param: '',
            hash: '',
          },
          {
            id: 'p2-2',
            type: 1,
            access: [SystemRoles.Player, SystemRoles.Parent, SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.GroupMember, SystemRoles.GroupManger, SystemRoles.SysAdmin],
            name: 'Games',
            path: '/club-!CLUBID!/team-!TEAMID!/player-!PLAYERID!/attendance-matches',
            param: '',
            hash: '',
          }
        ]
      },
      // {
      //   id: 'p3',
      //   type: 1,
      //   menu: "player",
      //   access: [SystemRoles.Player, SystemRoles.Parent, SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
      //   icon: 'icon-running',
      //   name: 'Hometasks',
      //   path: '/Player/Details',
      //   param: '!PLAYERID!/?teamId=!TEAMID!',
      //   hash: '#home-tasks',
      //   submenu: []
      // },
      // {
      //   id: 'p4',
      //   type: 0,
      //   menu: "player",
      //   access: [SystemRoles.Player, SystemRoles.Parent, SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
      //   icon: 'icon-Graph',
      //   name: 'Statistic',
      //   path: '/Player/Details',
      //   param: '!PLAYERID!/?teamId=!TEAMID!',
      //   hash: '#player-achievements',
      //   submenu: [
      //     {
      //       id: 'p4-1',
      //       type: 0,
      //       access: [SystemRoles.Player, SystemRoles.Parent, SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
      //       name: 'Achievements',
      //       path: '/Player/Details',
      //       param: '!PLAYERID!/?teamId=!TEAMID!',
      //       hash: '#player-achievements',
      //     },
      //     {
      //       id: 'p4-2',
      //       type: 0,
      //       access: [SystemRoles.Player, SystemRoles.Parent, SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
      //       name: 'Games',
      //       path: '/Player/Details',
      //       param: '!PLAYERID!/?teamId=!TEAMID!',
      //       hash: '#player-games',
      //     },
      //   ]
      // },
      {
        id: 'p5',
        type: 1,
        menu: "player",
        access: [SystemRoles.Player, SystemRoles.Parent, SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.GroupMember, SystemRoles.GroupManger, SystemRoles.SysAdmin],
        icon: 'icon-videoPlay',
        name: 'Video',
        path: '/club-!CLUBID!/team-!TEAMID!/player-!PLAYERID!/video-matches',
        param: '',
        hash: '',
        submenu: [
          {
            id: 'p5-1',
            type: 0,
            access: [SystemRoles.Player, SystemRoles.Parent, SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.GroupMember, SystemRoles.GroupManger, SystemRoles.SysAdmin],
            name: 'Matches',
            path: '/club-!CLUBID!/team-!TEAMID!/player-!PLAYERID!/video-matches',
            param: '',
            hash: '',
          },
          {
            id: 'p5-3',
            type: 0,
            access: [SystemRoles.Player, SystemRoles.Parent, SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.GroupMember, SystemRoles.GroupManger, SystemRoles.SysAdmin],
            name: 'Trainings',
            path: '/club-!CLUBID!/team-!TEAMID!/player-!PLAYERID!/video-matches',
            param: '',
            hash: '',
          },
          {
            id: 'p5-4',
            type: 0,
            access: [SystemRoles.Player, SystemRoles.Parent, SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.GroupMember, SystemRoles.GroupManger, SystemRoles.SysAdmin],
            name: 'Personal',
            path: '/club-!CLUBID!/team-!TEAMID!/player-!PLAYERID!/video-matches',
            param: '',
            hash: '',
          },
        ]
      },
    ]
  },
  team: {
    name: 'Team',
    access: [SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.GroupMember, SystemRoles.GroupManger, SystemRoles.SysAdmin],
    collapsed: false,
    items: [
      {
        id: 't1',
        type: 1,
        menu: "team",
        access: [SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.GroupMember, SystemRoles.GroupManger, SystemRoles.SysAdmin],
        icon: 'icon-Players',
        name: 'Team',
        path: '/club-!CLUBID!/team-!TEAMID!',
        param: '',
        hash: '',
        submenu: [
          {
            id: 't1-1',
            type: 1,
            access: [SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.GroupMember, SystemRoles.GroupManger, SystemRoles.SysAdmin],
            name: 'Squad',
            path: '/club-!CLUBID!/team-!TEAMID!',
            param: '',
            hash: '',
          },
          {
            id: 't1-, ',
            type: 1,
            access: [ SystemRoles.ClubAdmin, SystemRoles.GroupMember, SystemRoles.GroupManger, SystemRoles.SysAdmin],
            name: 'Coaches',
            path: '/club-!CLUBID!/team-!TEAMID!/coaches',
            param: '',
            hash: '',
          },
          // {
          //   id: 't1-2',
          //   type: 0,
          //   access: [SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
          //   name: 'Achievements',
          //   path: '/Team/TeamDetails/',
          //   param: '!TEAMID!/',
          //   hash: '#achievement-panel',
          // },
          // {
          //   id: 't1-3',
          //   type: 0,
          //   access: [SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
          //   name: 'Left team',
          //   path: '/Team/TeamDetails/',
          //   param: '!TEAMID!/',
          //   hash: '#player-history-panel',
          // },
          // {
          //   id: 't1-4',
          //   type: 0,
          //   access: [SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
          //   name: 'Equipment',
          //   path: '/Team/TeamDetails/',
          //   param: '!TEAMID!/',
          //   hash: '#team-equipment-panel',
          // },
          // {
          //   id: 't1-7',
          //   type: 0,
          //   access: [SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
          //   name: 'Documents',
          //   path: '/Team/TeamDetails/',
          //   param: '!TEAMID!/',
          //   hash: '#team-documents-panel',
          // },
        ]
      },
      {
        id: 't7',
        type: 1,
        menu: "team",
        access: [SystemRoles.Medic, SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.GroupMember, SystemRoles.GroupManger, SystemRoles.SysAdmin],
        icon: 'icon-Time-Square',
        name: 'Attendance',
        path: '/club-!CLUBID!/team-!TEAMID!/attendance-trainings',
        param: '',
        hash: '',
        submenu: [
          {
            id: 't7-1',
            type: 1,
            access: [SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.GroupMember, SystemRoles.GroupManger, SystemRoles.SysAdmin],
            name: 'Trainings',
            path: '/club-!CLUBID!/team-!TEAMID!/attendance-trainings',
            param: '',
            hash: '',
          },
          {
            id: 't7-2',
            type: 1,
            access: [SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.GroupMember, SystemRoles.GroupManger, SystemRoles.SysAdmin],
            name: 'Matches',
            path: '/club-!CLUBID!/team-!TEAMID!/attendance-matches',
            param: '',
            hash: '',
          },
          {
            id: 't7-3',
            type: 1,
            access: [SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.GroupMember, SystemRoles.GroupManger, SystemRoles.SysAdmin],
            name: 'Statistic',
            path: '/club-!CLUBID!/team-!TEAMID!/attendance-statistic',
            param: '',
            hash: '',
          }
        ]
      },
      {
        id: 't2',
        type: 1,
        menu: "team",
        access: [SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.GroupMember, SystemRoles.GroupManger, SystemRoles.SysAdmin],
        icon: 'icon-whistle',
        name: 'Trainings',
        path: '/club-!CLUBID!/team-!TEAMID!/trainings',
        param: '',
        hash: '',
        submenu: [
          {
            id: 't2-1',
            type: 1,
            access: [SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.GroupMember, SystemRoles.GroupManger, SystemRoles.SysAdmin],
            name: 'Trainings',
            path: '/club-!CLUBID!/team-!TEAMID!/trainings',
            param: '',
            hash: '',
          },
          // {
          //   id: 't2-6',
          //   access: [SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
          //   type: 1,
          //   name: 'Video',
          //   path: '/club-!CLUBID!/team-!TEAMID!/training-video',
          //   param: '',
          //   hash: '',
          // },
          // {
          //   id: 't2-4',
          //   type: 0,
          //   access: [SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
          //   name: 'Tests',
          //   path: '/club-!CLUBID!/team-!TEAMID!/tests',
          //   param: '',
          //   hash: '',
          // },
          // {
          //   id: 't2-2',
          //   type: 0,
          //   access: [SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
          //   name: 'Home tasks',
          //   path: '/club-!CLUBID!/team-!TEAMID!/home-task',
          //   param: '',
          //   hash: '',
          // },
          // {
          //   id: 't2-3',
          //   type: 0,
          //   access: [SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
          //   name: 'Attributes',
          //   path: '/club-!CLUBID!/team-!TEAMID!/team-attributes',
          //   param: '',
          //   hash: '',
          // },
        ]
      },
      {
        id: 't3',
        type: 1,
        menu: "team",
        access: [SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.GroupMember, SystemRoles.GroupManger, SystemRoles.SysAdmin],
        icon: 'icon-trophy',
        name: 'Matches',
        path: '/club-!CLUBID!/team-!TEAMID!/matches',
        param: '',
        hash: '',
        submenu: [{
          id: 't3-1',
          access: [SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.GroupMember, SystemRoles.GroupManger, SystemRoles.SysAdmin],
          type: 1,
          name: 'Matches',
          path: '/club-!CLUBID!/team-!TEAMID!/matches',
          param: '',
          hash: '',
        },
          {
            id: 't3-2',
            access: [SystemRoles.Medic, SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.GroupMember, SystemRoles.GroupManger, SystemRoles.SysAdmin],
            type: 1,
            name: 'Statistic',
            path: '/club-!CLUBID!/team-!TEAMID!/matches-statistic',
            param: '',
            hash: '',
          },
          {
            id: 't3-5',
            access: [SystemRoles.Medic, SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
            type: 1,
            name: 'Video',
            path: '/club-!CLUBID!/team-!TEAMID!/matches-video',
            param: '',
            hash: '',
          },
          {
            id: 't3-3',
            type: 1,
            access: [SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
            icon: 'icon-trophy',
            name: 'Competitions',
            path: '/club-!CLUBID!/team-!TEAMID!/competitions',
            param: '',
            hash: '',
          },
          {
            id: 't3-4',
            type: 1,
            access: [SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
            icon: 'icon-trophy',
            name: 'Opponents',
            path: '/club-!CLUBID!/team-!TEAMID!/opponents',
            param: '',
            hash: '',
          }
        ]
      },
      // {
      //   id: 't5',
      //   type: 0,
      //   menu: "team",
      //   access: [SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
      //   icon: 'icon-Calendar',
      //   name: 'Planning',
      //   path: '/Planing/Index/',
      //   param: '!TEAMID!/',
      //   hash: '',
      //   submenu: []
      // },
      // {
      //   id: 't6',
      //   type: 0,
      //   menu: "team",
      //   access: [SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
      //   icon: 'icon-dollar',
      //   name: 'Finances',
      //   path: '/Team/TeamDetails/',
      //   param: '!TEAMID!/',
      //   hash: '#team-payments-panel',
      //   submenu: []
      // },
      {
        id: 't6',
        type: 1,
        menu: "team",
        access: [SystemRoles.Medic, SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
        icon: 'icon-medicine',
        name: 'Health',
        path: '/club-!CLUBID!/team-!TEAMID!/health',
        param: '',
        hash: '',
        submenu: [
          {
            id: 't6-1',
            type: 1,
            access: [SystemRoles.Medic, SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
            name: 'Health check',
            path: '/club-!CLUBID!/team-!TEAMID!/health',
            param: '',
            hash: '',
          },
          {
            id: 't6-2',
            type: 1,
            access: [SystemRoles.Medic, SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
            name: 'Need attencion',
            path: '/club-!CLUBID!/team-!TEAMID!/condition',
            param: '',
            hash: '',
          },
          {
            id: 't6-3',
            type: 1,
            access: [SystemRoles.Medic, SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
            name: 'Measurements',
            path: '/club-!CLUBID!/team-!TEAMID!/anthropometric',
            param: '',
            hash: '',
          }
        ]
      },
      {
        id: 't4',
        type: 1,
        menu: "team",
        access: [SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
        icon: 'icon-AddUser',
        name: 'Access',
        path: '/club-!CLUBID!/team-!TEAMID!/access-players',
        param: '',
        hash: '',
        submenu: [

          {
            id: 't4-1',
            type: 1,
            access: [SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
            name: 'Players',
            path: '/club-!CLUBID!/team-!TEAMID!/access-players',
            param: '',
            hash: '',
          },
          {
            id: 't4-2',
            type: 1,
            access: [SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
            name: 'Parents',
            path: '/club-!CLUBID!/team-!TEAMID!/access-parents',
            param: '',
            hash: '',
          },
          {
            id: 't4-3',
            type: 1,
            access: [SystemRoles.ClubAdmin,,SystemRoles.SysAdmin],
            name: 'Staff',
            path: '/club-!CLUBID!/team-!TEAMID!/access-staff',
            param: '',
            hash: '',
          },
        ]
      }
    ]
  },
  club: {
    name: 'Club',
    access: [SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.GroupMember, SystemRoles.GroupManger, SystemRoles.SysAdmin],
    collapsed: false,
    items: [
      {
        id: 'c1',
        user: 1,
        type: 1,
        menu: "club",
        access: [SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.GroupMember, SystemRoles.GroupManger, SystemRoles.SysAdmin],
        icon: 'icon-Team',
        name: 'Teams',
        path: '/club-!CLUBID!',
        param: '',
        hash: '',
        submenu: [
          {
            id: 'c1-1',
            type: 1,
            access: [SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.GroupMember, SystemRoles.GroupManger, SystemRoles.SysAdmin],
            name: 'Teams',
            path: '/club-!CLUBID!',
            param: '',
            hash: '',
          },
          {
            id: 'c1-2',
            type: 1,
            access: [ SystemRoles.ClubAdmin, SystemRoles.GroupMember, SystemRoles.GroupManger, SystemRoles.SysAdmin],
            name: 'Coaches',
            path: '/club-!CLUBID!/coaches',
            param: '',
            hash: '',
          },
          {
            id: 'c1-3',
            type: 1,
            access: [ SystemRoles.ClubAdmin, SystemRoles.GroupMember, SystemRoles.GroupManger, SystemRoles.SysAdmin],
            name: 'Players',
            path: '/club-!CLUBID!/players',
            param: '',
            hash: '',
          },
        ]
      },
      // {
      //   id: 'c12',
      //   user: 9,
      //   type: 1,
      //   menu: "club",
      //   access: [ SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
      //   icon: 'icon-whistle',
      //   name: 'Coaches',
      //   path: '/club-!CLUBID!/coaches',
      //   param: '',
      //   hash: '',
      //   submenu: [
      //     {
      //       id: 'c12-1',
      //       type: 1,
      //       access: [ SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
      //       name: 'Coaches',
      //       path: '/club-!CLUBID!/coaches',
      //       param: '',
      //       hash: '',
      //     }
      //   ]
      // },
      {
        id: 'c6',
        user: 11,
        type: 1,
        menu: "club",
        access: [ SystemRoles.ClubAdmin, SystemRoles.GroupMember, SystemRoles.GroupManger, SystemRoles.SysAdmin],
        icon: 'icon-supervisor',
        name: 'Sport director',
        path: '/club-!CLUBID!/timeline',
        param: '',
        hash: '',
        submenu: [
          {
            id: 'c6-1',
            type: 1,
            access: [ SystemRoles.ClubAdmin, SystemRoles.GroupMember, SystemRoles.GroupManger, SystemRoles.SysAdmin],
            name: 'Trainings calendar',
            path: '/club-!CLUBID!/timeline',
            param: '',
            hash: '',
          },
          {
            id: 'c6-2',
            type: 1,
            access: [ SystemRoles.ClubAdmin, SystemRoles.GroupMember, SystemRoles.GroupManger, SystemRoles.SysAdmin],
            name: 'Club chart',
            path: '/club-!CLUBID!/chart',
            param: '',
            hash: '',
          },
          {
            id: 'c6-3',
            type: 1,
            access: [SystemRoles.ClubAdmin, SystemRoles.GroupMember, SystemRoles.GroupManger, SystemRoles.SysAdmin],
            name: 'Club evaluation',
            path: '/club-!CLUBID!/evaluation',
            param: '',
            hash: '',
          },
        ]
      },
      {
        id: 'c4',
        user: 9,
        type: 1,
        menu: "club",
        access: [SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.GroupMember, SystemRoles.GroupManger, SystemRoles.SysAdmin],
        icon: 'icon-field',
        name: 'Exercise',
        path: '/club-!CLUBID!/exercises',
        param: '',
        hash: '',
        submenu: [
          {
            id: 'c4-1',
            type: 1,
            access: [SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.GroupMember, SystemRoles.GroupManger, SystemRoles.SysAdmin],
            name: 'Exercise',
            path: '/club-!CLUBID!/exercises',
            param: '',
            hash: '',
          },
          {
            id: 'c4-2',
            type: 1,
            access: [SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.GroupMember, SystemRoles.GroupManger, SystemRoles.SysAdmin],
            name: 'Tests',
            path: '/club-!CLUBID!/tests',
            param: '',
            hash: '',
          },
        ]
      },
      {
        id: 'c5',
        user: 9,
        type: 1,
        menu: "club",
        access: [SystemRoles.Medic, SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
        icon: 'icon-medicine',
        name: 'Medicine',
        path: '/club-!CLUBID!/medicine',
        param: '',
        hash: '',
        submenu: [
          {
            id: 'c5-1',
            type: 1,
            access: [SystemRoles.Medic, SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
            name: 'Health check',
            path: '/club-!CLUBID!/medicine',
            param: '',
            hash: '',
          },
          {
            id: 'c5-2',
            type: 1,
            access: [SystemRoles.Medic, SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
            name: 'Need attencion',
            path: '/club-!CLUBID!/medicine/health-condition',
            param: '',
            hash: '',
          },
          {
            id: 'c5-3',
            type: 1,
            access: [SystemRoles.Medic, SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
            name: 'Measurements',
            path: '/club-!CLUBID!/medicine/anthropometric',
            param: '',
            hash: '',
          }
        ]
      },
      // {
      //   id: 'c, ',
      //   user: 9,
      //   type: 0,
      //   menu: "club",
      //   access: [9,SystemRoles.SysAdmin],
      //   icon: 'icon-dollar',
      //   name: 'Finances',
      //   path: '/Club/Administration/',
      //   param: '!CLUBID!/',
      //   hash: '#billing-panel',
      //   submenu: [
      //     {
      //       id: 'c, -1',
      //       access: [9,SystemRoles.SysAdmin],
      //       name: 'Club',
      //       path: '/Club/Administration/',
      //       param: '!CLUBID!/',
      //       hash: '#billing-panel',
      //     },
      //     {
      //       id: 'c, -2',
      //       access: [9,SystemRoles.SysAdmin],
      //       name: 'Players',
      //       path: '/Club/Administration/',
      //       param: '!CLUBID!/',
      //       hash: '#payments-panel',
      //     },
      //     {
      //       id: 'c, -3',
      //       access: [9,SystemRoles.SysAdmin],
      //       name: 'Reports',
      //       path: '/Club/Administration/',
      //       param: '!CLUBID!/',
      //       hash: '#billing-reports',
      //     },
      //     {
      //       id: 'c, -4',
      //       access: [9,SystemRoles.SysAdmin],
      //       name: 'Settings',
      //       path: '/Club/Administration/',
      //       param: '!CLUBID!/',
      //       hash: '#abonnements-panel',
      //     },
      //   ]
      // },
      // {
      //   id: 'c10',
      //   type: 0,
      //   user: 9,
      //   menu: "club",
      //   access: [9,SystemRoles.SysAdmin],
      //   icon: 'icon-t-shirt',
      //   name: 'Equipment',
      //   path: '/Club/Administration/',
      //   param: '!CLUBID!/',
      //   hash: '#trainers-equipment-panel',
      //   submenu: []
      // },
      // {
      //   id: 'c3',
      //   type: 0,
      //   user: 9,
      //   icon: 'icon-Graph',
      //   name: 'Statistic',
      //   path: '/Club/Statistics/',
      //   param: '!CLUBID!/',
      //   hash: '#tab-attendance-panel',
      //   submenu: []
      // },
      {
        id: 'c14',
        user: 9,
        type: 1,
        menu: "club",
        access: [SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
        icon: 'icon-staff',
        name: 'Staff',
        path: '/club-!CLUBID!/staff-admins',
        param: '',
        hash: '',
        submenu: [
          {
            id: 'c14-1',
            type: 1,
            access: [SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
            name: 'Admins',
            path: '/club-!CLUBID!/staff-admins',
            param: '',
            hash: '',
          },
          {
            id: 'c14-2',
            type: 1,
            access: [SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
            name: 'Coaches',
            path: '/club-!CLUBID!/staff-coaches',
            param: '',
            hash: '',
          }
        ]
      },
      {
        id: 'c2',
        type: 1,
        menu: "club",
        access: [SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
        user: 5,
        icon: 'icon-AddUser',
        name: 'Access',
        path: '/club-!CLUBID!/access-staff',
        param: '',
        hash: '',
        submenu: [
          {
            id: 'c2-3',
            type: 1,
            access: [SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
            name: 'Staff',
            path: '/club-!CLUBID!/access-staff',
            param: '',
            hash: '',
          },
          {
            id: 'c2-1',
            type: 1,
            access: [SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
            name: 'Players',
            path: '/club-!CLUBID!/access-players',
            param: '',
            hash: '',
          },
          {
            id: 'c2-4',
            type: 1,
            access: [SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
            name: 'Parents',
            path: '/club-!CLUBID!/access-parents',
            param: '',
            hash: '',
          },
        ]
      },
      // {
      //   id: 'c5',
      //   type: 0,
      //   user: 9,
      //   icon: 'icon-Filter',
      //   name: 'Preferences',
      //   path: '/Club/Administration/',
      //   param: '!CLUBID!/',
      //   hash: '#role-list-panel',
      //   submenu: []
      // },
      {
        id: 'c11',
        type: 1,
        menu: "club",
        user: 9,
        icon: 'icon-Folder',
        name: 'Archive',
        access: [SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
        path: '/club-!CLUBID!/archive-teams',
        param: '',
        hash: '',
        submenu: [
          {
            id: 'c11-1',
            type: 1,
            access: [SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
            name: 'Teams',
            path: '/club-!CLUBID!/archive-teams',
            param: '',
            hash: '',
          },
          {
            id: '11-2',
            type: 1,
            access: [SystemRoles.Medic, SystemRoles.Coach,  SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
            name: 'Players',
            path: '/club-!CLUBID!/archive-players',
            param: '',
            hash: '',
          },
          {
            id: '11-3',
            type: 1,
            access: [ SystemRoles.ClubAdmin, SystemRoles.SysAdmin],
            name: 'Staff',
            path: '/club-!CLUBID!/archive-staff',
            param: '',
            hash: '',
          },
          // {
          //   id: '11-3',
          //   type: 0,
          //   name: 'Video',
          //   path: '/Game/GameVideoList/',
          //   hash: ''
          // },
        ]
      }
    ]
  },
  group: {
    name: 'Groups',
    access: [SystemRoles.GroupMember, SystemRoles.GroupManger],
    collapsed: false,
    items: [
      {
        id: 'g1',
        type: 1,
        icon: 'icon-Folder',
        access: [SystemRoles.GroupMember, SystemRoles.GroupManger],
        name: 'My groups',
        path: '/group-list',
        param: '',
        hash: '',
        submenu: []
      },
    ]
  },
  system: {
    name: 'System',
    access: [SystemRoles.SysAdmin],
    collapsed: false,
    items: [
      {
        id: 's1',
        user: 15,
        type: 0,
        menu: "system",
        access: [SystemRoles.SysAdmin],
        icon: 'icon-Star',
        name: 'Home',
        path: '/',
        param: '',
        hash: '',
        submenu: []
      },
      {
        id: 's2',
        user: 15,
        type: 1,
        menu: "system",
        access: [SystemRoles.SysAdmin],
        icon: 'icon-Category',
        name: 'Club list',
        path: '/system/club-list',
        param: '',
        hash: '',
        submenu: []
      },
      {
        id: 's3',
        user: 15,
        type: 0,
        menu: "system",
        access: [SystemRoles.SysAdmin],
        icon: 'icon-work',
        name: 'Customer Success',
        path: '/PaymentAdmin/CustomerSuccess/',
        param: '',
        hash: '#tab-list-panel',
        submenu: []
      },
      {
        id: 's4',
        user: 15,
        type: 0,
        menu: "system",
        access: [SystemRoles.SysAdmin],
        icon: 'icon-Profile',
        name: 'Users',
        path: '/system/users',
        param: '',
        hash: '',
        submenu: []
      },
      {
        id: 's5',
        user: 15,
        type: 0,
        menu: "system",
        access: [SystemRoles.SysAdmin],
        icon: 'icon-Players',
        name: 'Players',
        path: '/ManagePlayer/PlayerList/',
        param: '',
        hash: '',
        submenu: []
      },
      {
        id: 's6',
        user: 15,
        type: 0,
        menu: "system",
        access: [SystemRoles.SysAdmin],
        icon: 'icon-Upload',
        name: 'Game video list',
        path: '/Game/GameVideoList/',
        param: '',
        hash: '',
        submenu: []
      },
      // {
      //   id: 's7',
      //   user: 15,
      //   type: 0,
      //   menu: "system",
      //   access: [SystemRoles.SysAdmin],
      //   icon: 'icon-Image',
      //   name: 'Graphic schemes',
      //   path: '/GraphicEditor/',
      //   param: '',
      //   hash: '',
      //   submenu: []
      // },
      // {
      //   id: 's, ',
      //   user: 15,
      //   type: 0,
      //   menu: "system",
      //   access: [SystemRoles.SysAdmin],
      //   icon: 'icon-Activity',
      //   name: 'Edit Scores List',
      //   path: '/Normative/Scores/',
      //   param: '',
      //   hash: '',
      //   submenu: []
      // },
      // {
      //   id: 's9',
      //   user: 15,
      //   type: 0,
      //   menu: "system",
      //   access: [SystemRoles.SysAdmin],
      //   icon: 'icon-Chart',
      //   name: 'Edit Normatives',
      //   path: '/Normative/Normatives/',
      //   param: '',
      //   hash: '',
      //   submenu: []
      // },
      {
        id: 's10',
        user: 15,
        type: 0,
        menu: "system",
        access: [SystemRoles.SysAdmin],
        icon: 'icon-Notification1',
        name: 'Create notification',
        path: '/Notification/CreateSystemNotification/',
        param: '',
        hash: '',
        submenu: []
      },
      {
        id: 's11',
        user: 15,
        type: 0,
        menu: "system",
        access: [SystemRoles.SysAdmin],
        icon: 'icon-message',
        name: 'Mailing templates',
        path: '/Mailing/MailingTemplateList/',
        param: '',
        hash: '',
        submenu: []
      },
      {
        id: 's12',
        user: 15,
        type: 0,
        menu: "system",
        access: [SystemRoles.SysAdmin],
        icon: 'icon-Folder',
        name: 'Club groups',
        path: '/system/club-groups',
        param: '',
        hash: '',
        submenu: []
      },
      {
        id: 's13',
        user: 15,
        type: 1,
        menu: "system",
        access: [SystemRoles.SysAdmin],
        icon: 'icon-translate',
        name: 'Localization',
        path: '/system/translates',
        param: '',
        hash: '',
        submenu: [{
          id: 's13-1',
          type: 1,
          access: [SystemRoles.SysAdmin],
          name: 'Translates',
          path: '/system/translates',
          param: '',
          hash: '',
        },{
            id: 's13-2',
            type: 1,
            access: [SystemRoles.SysAdmin],
            name: 'Suggested translates',
            path: '/system/suggested',
            param: '',
            hash: '',
          }]
      },
      {
        id: 's14',
        user: 15,
        type: 0,
        menu: "system",
        access: [SystemRoles.SysAdmin],
        icon: 'icon-question',
        name: 'Helper',
        path: '/Helper/',
        param: '',
        hash: '',
        submenu: []
      }
    ]
  }
};
