import React from 'react';
import classes from './Finish.module.scss';
import Button from 'components/UI/Button/Button'
import {useTranslation} from 'react-i18next';

const Finish = () => {
  const {t} = useTranslation();

  const onClick = (e) => {
    e.preventDefault();
    window.location.href = process.env.REACT_APP_POST_LOGIN_URI
  }
    return (
        <div>
            <h1>{t('done')}</h1>

            <div className={classes.description}>
                {t('doneMessage')}
            </div>

            <Button onClick={onClick} type="primary">{t('ok')}</Button>
        </div>
    );
}

export default Finish
