import React from 'react';
import {Chart as ChartJS, CategoryScale, LinearScale, LineElement, BarElement, Title, Tooltip, Legend, LineController, PointElement} from 'chart.js';
import { Bubble, Line } from 'react-chartjs-2';
import {useTranslation} from 'react-i18next';

ChartJS.register(
    CategoryScale,
    LinearScale,
    LineElement,
    LineController,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const TendencyChart = (props) => {
    const {t} = useTranslation();
    const {xMin, xMax, yMin, yMax, data: jsonData} = props.data;
    const chartData = JSON.parse(jsonData);

    const options = {
        scales: {
            x: {
                suggestedMin: xMin,
                suggestedMax: xMax
            },
            y: {
                beginAtZero: true,
                suggestedMin: yMin,
                suggestedMax: yMax
            }
        },
        plugins: {
            title: {
                display: true,
                text: t('Tendency of won / loss matches'),
            },
            tooltip: {
                callbacks: {
                    label: function(context) {
                        if (context.dataset.label === "line")
                            return;

                            let label = context.dataset.label || '';

                        if (label) {
                            label += ': ';
                        }

                        if (context.parsed.y !== null) {
                            label += context.dataset.data[0].label;
                        }
                        return label;
                    }
                }
            },
            legend: {
                display: true,
                position: "bottom",
                labels: {
                    filter: function (item, chart) {
                        return item.datasetIndex !== 3;
                    }
                }
            }
        }
    };

    const allPoints = chartData[0].concat(chartData[1].concat(chartData[2]));
    const labels = allPoints.sort((a, b) => a.x - b.x).map(x => x.x);
    const linePoints = allPoints.sort((a, b) => a.x - b.x).map(x => x.y);

    const data = {
        labels: labels,
        datasets: [
            {
                type: "bubble",
                label: t('Win'),
                data: chartData[0],
                backgroundColor: "rgba(255, 99, 132, 0.5)"
            },
            {
                type: "bubble",
                label: t('Draw'),
                data: chartData[1],
                backgroundColor: "rgba(255, 159, 64, 0.5)"
            },
            {
                type: "bubble",
                label: t('Loss'),
                data: chartData[2],
                backgroundColor: "rgba(53, 162, 235, 0.5)"
            },
            {
                type: "line",
                label: "line",
                data: linePoints,
                borderColor: "rgb(255, 99, 132)",
                backgroundColor: "rgba(255, 99, 132, 0.5)"
            }
        ]
    };

    return (
        <Bubble options={options} data={data} />
    );
};

TendencyChart.defaultProps = {};

export default TendencyChart;
