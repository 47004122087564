import React, {useState} from 'react';
import classes from './Panel.module.scss';
import cn from 'classnames';
import Button from '../Button/Button';

const Panel = (props) => {
    const [showFilters, setShowFilters] = useState(false);

    let template = "1fr";
    if (props.action && props.search && props.search) template = "auto 1fr 180px";
    if (props.action && props.filters && !props.search) template = "auto 1fr";
    if (props.action && !props.filters && props.search) template = "1fr 180px";
    if (!props.action && props.filters && props.search) template = "1fr 180px";

    return (
        <div className={cn(classes.panel, {[classes.noBorder]: props.border === false})} style={props.style}>
            <div className={classes.toolbox} style={{gridTemplateColumns: template}}>
                {props.action && <div className={cn(classes.action, "action")}>
                    {props.action()}
                    {props.filters && <Button type="second" className={classes.filterButton}
                             onClick={() => setShowFilters(!showFilters)}>
                        <i className="icon-Filter"></i>
                    </Button>}
                </div>}
                {props.filters && <div className={cn(classes.filters, {[classes.filtersHide]:!showFilters}, {[classes.noAction]:!props.action}, "filters")} >
                  {props.filters()}
                </div>}
                {props.search && <div className={cn(classes.search, "search")}>
                  {props.search()}
                </div>}
                {props.subFilters && <div className={cn(classes.subBox, {[classes.filtersHide]:!showFilters})}><span><div className={classes.subFilters}>{props.subFilters()}</div></span></div>}
            </div>
            <div className={classes.children}>
                {props.children}
            </div>
        </div>
    );
}

export default Panel;
