import {gql, useLazyQuery, useMutation, useQuery} from '@apollo/client';

export const USERS_LIST = gql`
    query GetUsers ($clubId: String, $teamId: Uuid, $where: UserTypeFilterInput!, $skip: Int!, $take: Int!) {
        users (clubId: $clubId, teamId: $teamId, where: $where, skip: $skip, take: $take) {
            totalCount
            items {
                id
                userId
                fullName
                email
                roles {
                    name
                    roleId
                }
                phone
                description
                invite
                lastLogin
            }
        }
    }`;

export const useUsers = () => useLazyQuery(USERS_LIST, {fetchPolicy: 'network-only'});

export const INVITE_USERS = gql`
    mutation InviteUsers($clubId: String!, $users: [UserInput]) {
        inviteUsers(
            clubId: $clubId,
            users: $users
        )
        {
            result
            message
        }
    }
`;

export const useInvitePlayers = () => useMutation(INVITE_USERS);


export const RE_INVITE_USERS = gql`
    mutation ReInviteUsers($clubId: String!, $users: [UserInput]) {
        inviteUsers(
            clubId: $clubId,
            users: $users
        )
        {
            result
            message
        }
    }
`;

export const useReInvitePlayers = () => useMutation(RE_INVITE_USERS);

export const REMOVE_USER_ACCESS = gql`
    mutation RemoveUserAccess($clubId: String!, $users: [UserInput]) {
        removeUserAccess(
            clubId: $clubId,
            users: $users
        )
        {
            result
            message
        }
    }
`;

export const useRemoveUserAccess = () => useMutation(REMOVE_USER_ACCESS);


export const RESET_PERMISSIONS = gql`
    query ResetPermissions {
        resetPermissions
    }
`;

export const useResetPermissions = () => useLazyQuery(RESET_PERMISSIONS);


export const CHANGE_PASSWORD = gql`
    mutation ChangePassword($input: PasswordInput!) {
        changePassword(input: $input) {
            result
            message
        }
    }
`;

export const useChangePassword = () => useMutation(CHANGE_PASSWORD);


export const SAVE_SELECTED_ROLE = gql`
    mutation SaveSelectedRole($input: SelectedRoleInput!) {
        saveSelectedRole(input: $input)
    }
`;

export const useSaveSelectedRole = () => useMutation(SAVE_SELECTED_ROLE);


export const SYSTEM_USERS = gql`
    query GetSystemUsers ($where: UserTypeFilterInput!, $skip: Int!, $take: Int!) {
        systemUsers (where: $where, skip: $skip, take: $take) {
            totalCount
            items {
                userId
                fullName
                email
                phone
                roles {
                    clubName
                    name
                }
                invite
                lastLogin
            }
        }
    }`;

export const useSystemUsers = (variables) => useQuery(SYSTEM_USERS, {variables: variables, fetchPolicy: 'network-only'});


export const LOGIN_AS = gql`
    mutation LoginAs($userId: String!) {
        loginAs(userId: $userId)
    }
`;

export const useLoginAs = () => useMutation(LOGIN_AS);


export const LOGOUT_AS = gql`
    mutation LogoutAs {
        logoutAs
    }
`;

export const useLogoutAs = () => useMutation(LOGOUT_AS);

//
// export const PERMISSION_LIST = gql`
//     query GetPemissionList ($clubId: String) {
//         subscriptions {
//             id
//             name
//         }
//         roles(clubId: $clubId) {
//             roleId
//             name
//         }
//     }`;
// export const usePermissionList = () => useQuery(PERMISSION_LIST);
//
//
// export const SUBSCRIPTION_PERMISSION_READ = gql`
//     query GetSubscriptionPermissions($subscriptionId: Int!) {
//         data: subscriptionPermissions(subscriptionId: $subscriptionId) {
//             id
//             key
//             name
//             category
//             default {
//                 read
//                 write
//                 share
//             }
//             set {
//                 read
//                 write
//                 share
//             }
//         }
//     }`;
// export const useSubscriptionPermissionSave = () => useMutation(SUBSCRIPTION_PERMISSION_SAVE);
//
//
// export const SUBSCRIPTION_PERMISSION_SAVE = gql`
//     mutation EditSubscriptionPermissions(
//         $subscriptionId: Int!
//         $permissions: [EditPermissionInput]!
//     ) {
//         editSubscriptionPermissions(
//             subscriptionId: $subscriptionId
//             permissions: $permissions
//         ) {
//             id
//             key
//             name
//             category
//             default {
//                 read
//                 write
//                 share
//             }
//             set {
//                 read
//                 write
//                 share
//             }
//         }
//     }`;
// export const useRolePermissionSave = () => useMutation(ROLE_PERMISSION_SAVE);
//
//
// export const ROLE_PERMISSION_READ = gql`
//     query GetRolePermissions(
//         $clubId: String
//         $roleId: Uuid!
//     ) {
//         data: rolePermissions(
//             clubId: $clubId
//             roleId: $roleId
//         ) {
//             id
//             key
//             name
//             category
//             default {
//                 read
//                 write
//                 share
//             }
//             set {
//                 read
//                 write
//                 share
//             }
//         }
//     }`;
// export const useUserList = () => useLazyQuery(USERS_LIST);
//
// export const ROLE_PERMISSION_SAVE = gql`
//     mutation EditRolePermissions(
//         $clubId: String
//         $roleId: Uuid!
//         $permissions: [EditPermissionInput]!
//     ) {
//         editRolePermissions(
//             clubId: $clubId
//             roleId: $roleId
//             permissions: $permissions
//         ) {
//             id
//             key
//             name
//             category
//             default {
//                 read
//                 write
//                 share
//             }
//             set {
//                 read
//                 write
//                 share
//             }
//         }
//     }`;
//
//
// export const USERS_LIST = gql`
//     query GetUsersList ($clubId: String, $where: UserTypeFilterInput!, $skip: Int!, $take: Int!) {
//         users (clubId: $clubId, where: $where, skip: $skip, take: $take) {
//             totalCount
//             items {
//                 id
//                 userId
//                 fullName
//                 email
//                 groups
//                 phone
//                 description
//                 invite
//                 lastLogin
//             }
//         }
//     }`;
//
// export const useUserAccessList = () => useLazyQuery(USERS_LIST);
//
// export const USER_PERMISSION_READ = gql`
//     query GetUserPermissions(
//         $userId: String!
//         $roleId: Uuid!
//         $clubId: String!
//     ) {
//         data: userPermissions(
//             userId: $userId
//             roleId: $roleId
//             clubId: $clubId
//         ) {
//             id
//             key
//             name
//             category
//             default {
//                 read
//                 write
//                 share
//             }
//             set {
//                 read
//                 write
//                 share
//             }
//         }
//     }`;
//
// export const USER_PERMISSION_SAVE = gql`
//   mutation EditUserPermissions(
//       $clubId: String
//       $roleId: Uuid!
//       $userId: String!
//       $permissions: [EditPermissionInput]!
//     ) {
//       editUserPermissions(
//         clubId: $clubId
//         roleId: $roleId
//         userId: $userId
//         permissions: $permissions
//       ) {
//         id
//         key
//         name
//         category
//         default {
//           read
//           write
//           share
//           __typename
//         }
//         set {
//           read
//           write
//           share
//           __typename
//         }
//         __typename
//       }
//     }`;
// export const useUserPermissionSave = () => useMutation(USER_PERMISSION_SAVE);
