import React, {useEffect, useState} from 'react';
import Input from "../../../components/UI/Input/Input";
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import {notHaveErrors, validateFields, validateForm} from "../../../utils/form";
import Password from '../../../components/UI/Password/Password';
import {useChangePassword} from '../../../apollo/queries/PermissionsQueries';
import Checkbox from '../../../components/UI/Checkbox/Checkbox';

const PasswordForm = (props) => {
  const {setLoading, onComplete} = props;

  const {t} = useTranslation();

  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});

  const [changePassword] = useChangePassword();

  // useEffect(() => {
  //   if (values?.showPassword === undefined) {
  //     return;
  //   }
  //
  //   const type = passwordType === 'password' ? 'text' : 'password';
  //   setPasswordType(type);
  //
  // }, [values]);

  const fieldChanged = (event) => {
    const newValues = { ...values };
    newValues[event.target.name] = event.target.value
    setValues(newValues);
  }

  const regField = {
    value: values,
    onChange: fieldChanged,
    error: errors
  };

  const validationRules = {
    currentPassword: yup.string().required(t("Require")),
    newPassword: yup.string().required(t('Required'))
        .matches(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
            t('showPasswordError')),
    newPasswordAgain: yup.string().required(t('Required'))
        .oneOf([yup.ref('newPassword'), null], t('passwordsMustMatch'))
  }

  const formSubmitted = (e) => {
    e.preventDefault();
    setLoading(true);
    validateForm(values, validationRules, (errors) => {
      if (notHaveErrors(errors))
        saveData();
      else {
        setErrors(errors);
        setLoading(false);
      }
    });
  }

  const saveData = () => {
    const variables = {
      input: {
        oldPassword: values.currentPassword,
        newPassword: values.newPassword,
      }
    };

    changePassword({variables: variables}).then(result => {
      props.onComplete();
    });
  }

  return (
      <form id={props.id} onSubmit={formSubmitted}>
        <Input
            type={values?.showPassword ? "text" : "password"}
            name="currentPassword"
            placeholder={t('Current password')}
            {...regField}
        />

        <Password
            type={values?.showPassword ? "text" : "password"}
            name="newPassword"
            placeholder={t('Password')}
            {...regField}
        />

        <Input
            type={values?.showPassword ? "text" : "password"}
            name="newPasswordAgain"
            placeholder={t('Password again')}
            {...regField}
        />

        <Checkbox
            name="showPassword"
            label={t('Show password')}
            {...regField}
        />
      </form>
  )
}

export default PasswordForm

