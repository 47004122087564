import React, { PropTypes } from 'react';
import ReactPaginate from 'react-paginate';
import classes from './Pagination.module.scss';

const Pagination = (props) => {

  if (props.count == 0) return false;

  const pages = Math.ceil(props.count / props.onPage);

  if (pages < 2) return false;

  return (
        <ReactPaginate
            initialPage={props.page}
            onPageChange={props.onPageChange}
            activeClassName={classes.selected}
            containerClassName={classes.Pagination}
            previousLabel="<"
            nextLabel=">"
            pageCount={pages}
            marginPagesDisplayed={0}
            pageRangeDisplayed={4}
        />
  );
};

Pagination.propTypes = {};

Pagination.defaultProps = {
  count: 0,
  onPage: 10
};

export default Pagination;