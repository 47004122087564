import React, {useState} from 'react';
import classes from './FooterBar.module.scss';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import SwitchComponents from 'components/System/SwitchComponents';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import {useTranslation} from 'react-i18next';

const FooterBar = (props) => {

    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const [CalendarComponent, setCalendarComponent] = useState(null)

    const loadCalendarComponent = async () => {
        const loadResult = await import('../../Calendar/Calendar');
        setCalendarComponent(() => loadResult.default);
    }

    const [modalData, setModalData, initState] = ModalState();

    const chat = "";
    const calendar = "";
    const notification = "";
    const help = `https://help.yoplaydo.com/l-${navState.language}`;

    const onCalendarClick = () => {
        const _data = {
            ...initState,
            form: "calendar",
            title : t("Calendar"),
            styles: {
                maxWidth: "100%",
                minHeight: "calc(100vh - 200px)"
            },
            buttons: {
                hideSaveButton: true
            },
            defaultValues: {
            },
            open: true
        }

        loadCalendarComponent().then(() => {
            setModalData(_data);
        });
    }

    return (
        <>
            <div className={['footer-bar', classes.FooterBar].join(' ')}>
                <div className="chat">
                    <a className="tooltip-container" data-href={chat} data-scope="modal-dialog" title="Chats">
                        <i className="icon-Chat"></i>
                    </a>
                </div>
                <div className="calendar">
                    <div onClick={onCalendarClick} title={t("Calendar")}><i  className="icon-Calendar"></i></div>
                </div>
                <div className="notification">
                    <a className="tooltip-container" data-href={notification} data-scope="modal-dialog"
                       title="Notifications"><i className="icon-Notification"></i></a>
                </div>
                <div className="help">
                    <a href={help} title="Help" target="_blank"><i className="icon-Bookmark"></i></a>
                </div>
            </div>

            <ModalWindow2 {...modalData}>
                <SwitchComponents active={modalData.form}>
                    {CalendarComponent ? <CalendarComponent {...modalData} name="calendar" /> : undefined}
                </SwitchComponents>
            </ModalWindow2>
        </>
    );
};

FooterBar.propTypes = {};
FooterBar.defaultProps = {};

export default FooterBar;
