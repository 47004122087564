import React, {useState} from 'react';
import Input from '../../../../../components/UI/Input/Input';
import {useTranslation} from 'react-i18next';
import {notHaveErrors, validateFields, validateForm} from '../../../../../utils/form';
import * as yup from 'yup';

const AddVideoLink = (props) => {
    const {defaultValues, onComplete} = props;

    const {t} = useTranslation();

    const [values, setValues] = useState(defaultValues);
    const [errors, setErrors] = useState({});

    const fieldChanged = (event) => {
        const newValues = { ...values };
        newValues[event.target.name] = event.target.value

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });

        setValues(newValues);
    }

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors
    };

    const validationRules = {
        video: yup.string().required(t("required")),
    }

    const saveData = () => {
        onComplete(values);
    }

    const formSubmitted = (e) => {
        e.preventDefault();
        validateForm(values, validationRules, (errors) => {
            if (notHaveErrors(errors))
                saveData();
            else
                setErrors(errors);
        });
    }

    return (
        <>
            <form id={props.id} onSubmit={formSubmitted}>
                <Input
                    name="video"
                    label={t('Link')}
                    {...regField}
                />
            </form>
        </>
    );
};

AddVideoLink.defaultProps = {};

export default AddVideoLink;
