import React, {useState} from 'react';
import classes from '../Settings.module.scss';
import Input from 'components/UI/Input/Input';
import {useTranslation} from 'react-i18next';
import {notHaveErrors, validateFields, validateForm} from 'utils/form';
import * as yup from 'yup';
import Button from 'components/UI/Button/Button';
import Select from 'components/UI/Select/Select';
import PasswordForm from './PasswordForm';
import {useEditUser} from 'apollo/queries/SettingQueries';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import {toastMessage} from 'components/UI/Toast/Toast';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar, systemDataVar} from 'apollo/cashe';
import {useNavigate} from 'react-router-dom';
import {useSaveSelectedRole} from 'apollo/queries/PermissionsQueries';
import {GetRootPage} from 'components/Navigation/CanAccess/roles';

const Account = (props) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const systemData = useReactiveVar(systemDataVar);
  const naviData = useReactiveVar(naviDataVar);

  const [values, setValues] = useState({...systemData.user, role: systemData.user.userRole.roleId});
  const [errors, setErrors] = useState({});
  const [roleHasChanged, setRoleHasChanges] = useState(false);
  const [nameHasChanged, setNameHasChanges] = useState(false);

  const [editUser] = useEditUser();
  const [saveSelectedRole] = useSaveSelectedRole();
  const [modalData, setModalData, initState] = ModalState();

  const roles = systemData.user.roles.map(x => ({label: x.name, value: x.clubId + x.roleId}));

  const fieldChanged = (event) => {
    const newValues = {...values};

    newValues[event.target.name] = event.target.value;

    validateFields(newValues, validationRules, (errors) => {
      setErrors(errors);
    });
    setValues(newValues);
    setNameHasChanges(true);
  };

  const regField = {
    value: values,
    onChange: fieldChanged,
    error: errors
  };

  const validationRules = {
    firstName: yup.string().required(t('Required')),
    lastName: yup.string().required(t('Required')),
  };

  const onRoleChange = (event) => {
    const newValues = {...values};
    newValues.role = event.target.value;
    setValues(newValues);
    setRoleHasChanges(true);
  }

  const onRoleSaved = () => {
    const role = systemData.user.roles.find(x => x.clubId + x.roleId === values.role);

    const variables = {
      input: {
        roleId: role.roleId,
        clubId: role.clubId
      }
    }

    saveSelectedRole({variables: variables}).then(result => {
      if (result.data.saveSelectedRole) {
        const newSystemData = {...systemData};
        newSystemData.user.userRole = role;
        systemDataVar(newSystemData);

        const newNaviData = {...naviData};
        newNaviData.clubId = role.clubId;
        newNaviData.settings.open = false;
        naviDataVar(newNaviData);

        window.location.href = GetRootPage();
      }
    });

    setRoleHasChanges(false);
  }

  const onSave = () => {
    validateForm(values, validationRules, async (errors) => {
      if (notHaveErrors(errors)) {
        sendData();
      } else {
        setErrors(errors);
      }
    });
  }

  const sendData = () => {
    const data = {
      firstName: values.firstName,
      lastName: values.lastName
    }

    editUser({variables: {input: data}}).then((data) => {
      const newSystemState = {...systemData};
      newSystemState.user = {...newSystemState.user, ...data.data.editUser};
      systemDataVar(newSystemState);
      setNameHasChanges(false);
      toastMessage(true, t('Settings saved'));
    });
  };

  const onChangePassword = () => {
    const data = {
      ...initState,
      title: t("Change password"),
      onComplete: onComplete,
      open: true
    }
    setModalData(data);
  }

  const onComplete = () => {
    setModalData(initState);
    toastMessage(true, t("Password changed"));
  }

  return (
      <>
        <div onClick={props.onBack} className={[classes.title, classes.link].join(" ")}>
          <i className={["icon-Arrow---Up", classes.backButton, "backArrow"].join(" ")}></i>
          {t("Account")}
        </div>

        <Select
            name="role"
            {...regField}
            onChange={onRoleChange}
            options={roles}
            placeholder={t('Role')}
        />

        {roleHasChanged && <Button type="primary" onClick={onRoleSaved} style={{margin: 0}}>Save</Button>}

        <>
          <hr/>

          <div className={classes.title}>Personal information</div>
          <Input
              name="firstName"
              {...regField}
              placeholder={t('User name')}
          />

          <Input
              name="lastName"
              {...regField}
              placeholder={t('User name')}
          />

          <Input
              name="email"
              disabled
              {...regField}
              placeholder={t('E-mail')}
          />

          {nameHasChanged && <Button type="primary" onClick={onSave} style={{margin: 0}}>Save</Button>}
        </>

        <hr />

        <div className={classes.title}>User password</div>

        <Button onClick={onChangePassword} type="second">Edit password</Button>

        <ModalWindow2 {...modalData}>
          <PasswordForm {...modalData} name="password" />
        </ModalWindow2>
      </>
  );
};

Account.propTypes = {};
Account.defaultProps = {};

export default Account;
