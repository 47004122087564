import {gql, useLazyQuery, useMutation, useQuery} from '@apollo/client';

export const TRAINING_FIELDS = gql`
    fragment TrainingFields on TrainingType {
        trainingId
        name
        block,
        trainingNumber,
        start
        end
        duration
        venue
        venueId
        group
        playerInTeam
        playerOnTraining
        players {
            status
        }
        trainingStatus
        editMode
        exercises
        detailedPlan
    }
`;


export const GET_TRAININGS = gql`
    ${TRAINING_FIELDS}
    query GetTrainings($teamId: Uuid!, $where: TrainingTypeFilterInput, $skip: Int, $take: Int) {
        trainings(teamId: $teamId,  where: $where, skip: $skip, take: $take) {
            totalCount
            items {
                ...TrainingFields
            }
        }
    }`;

export const useTrainings = () => useLazyQuery(GET_TRAININGS, {fetchPolicy:'network-only'});

export const GET_TRAININGS_AVERAGE = gql`
    query GetTrainingsAverage($teamId: Uuid!, $from: DateTime!, $till: DateTime!) {
        trainingsAveragePlayer(teamId: $teamId, from: $from, till: $till)
    }`;

export const useTrainingsAverageLazy = () => useLazyQuery(GET_TRAININGS_AVERAGE, {fetchPolicy:'network-only'});

export const GET_TRAINING_DETAILS = gql`
    ${TRAINING_FIELDS}
    query GetTrainingDetails($teamId: Uuid!, $where: TrainingTypeFilterInput) {
        trainings(teamId: $teamId, where: $where) {
            items {
                ...TrainingFields
                players {
                    playerTrainingId
                    playerId
                    playerName
                    status
                    healthBefore
                    rating
                    comments
                    healthAfter
                }
                files {
                    id
                    name
                    contentType
                    link
                    size
                    uploaded
                    userName
                }
                videos {
                    videoId
                    name
                    size
                    contentType
                    link
                    store
                    userName
                    createdTime
                }
                trainers {
                    fullName
                }
            }
        }
    }`;
export const useTrainingDetails = () => useLazyQuery(GET_TRAINING_DETAILS);


const SAVE_EXERCISE = gql`
    ${TRAINING_FIELDS}
    mutation TrainingSave($input: TrainingInput) {
        saveTraining(input: $input) {
            ...TrainingFields
        }
    }
`;

export const useSaveTraining = () => useMutation(SAVE_EXERCISE);


const COPY_TRAINING = gql`
    mutation CopyTraining($input: TrainingInput) {
        copyTraining(input: $input)
    }
`;

export const useCopyTraining = () => useMutation(COPY_TRAINING);


const DELETE_TRAINING = gql`
    mutation DeleteTraining($input: DeleteInput) {
        deleteTraining(input: $input)
    }
`;

export const useDeleteTraining = () => useMutation(DELETE_TRAINING);

const CHANGE_TRAINING_STATUS = gql`
    mutation ChangeTrainingStatus($input: TrainingStatusInput) {
        changeTrainingStatus(input: $input)
    }
`;

export const useChangeTrainingStatus = () => useMutation(CHANGE_TRAINING_STATUS);


const ADD_TRAINING_PLAYERS = gql`
    mutation AddTrainingPlayers($input: AddTrainingPlayersInput) {
        addTrainingPlayers(input: $input)
    }
`;

export const useAddTrainingPlayers = () => useMutation(ADD_TRAINING_PLAYERS);


const SAVE_TRAINING_PLAYER_STATUS = gql`
    mutation SaveTrainingPlayerStatus($input: TrainingPlayerStatusInput) {
        saveTrainingPlayerStatus(input: $input)
    }
`;

export const useSaveTrainingPlayerStatus = () => useMutation(SAVE_TRAINING_PLAYER_STATUS);


const DELETE_TRAINING_PLAYERS = gql`
    mutation DeleteTrainingPlayers($input: DeleteTrainingPlayersInput) {
        deleteTrainingPlayers(input: $input)
    }
`;

export const useDeleteTrainingPlayers = () => useMutation(DELETE_TRAINING_PLAYERS);


export const GET_TRAINING_ATTENDANCE = gql`
    ${TRAINING_FIELDS}
    query GetTrainingsAttendance($clubId: String!, $teamId: Uuid!, $where: TrainingTypeFilterInput) {
        trainings(teamId: $teamId, where: $where, skip:0, take:100) {
            items {
                ...TrainingFields
                players {
                    playerTrainingId
                    playerId
                    playerName
                    status
                    healthBefore
                    rating
                    comments
                    healthAfter
                }
            }
        }

        players(clubId:$clubId, where: {teams: {some: {team: {teamId: {eq: $teamId}}}}}, skip:0, take:100) {
            totalCount
            items {
                playerId
                fullName
                dateOfBirth
                licenceNr
                healthCheckNextDate
                teams {
                    teamMemberId
                    teamNumber
                    group
                    position
                }
            }
        }
    }`;
export const useTrainingsAttendance = () => useLazyQuery(GET_TRAINING_ATTENDANCE);

const GET_TRAINING_VENUES = gql`
    query GetTrainingVenues($clubId: String!, $take: Int, $skip: Int) {
        venues(
            clubId: $clubId,
            where: {
                showOnTrainings: {eq: true}
            },
            take: $take,
            skip: $skip
        ) {
            totalCount
            items {
                venueId
                name
                belongsToClub
                showOnTrainings
                showOnGames
            }
        }
    }
`;
export const useTrainingVenues = (variables) => useQuery(GET_TRAINING_VENUES, {variables: variables});


const ADD_TRAINING_VIDEO = gql`
    mutation AddTrainingVideo($input:TrainingVideoInput!) {
        addTrainingVideo(input:$input)
    }`;
export const useAddTrainingVideo = () => useMutation(ADD_TRAINING_VIDEO);


const ADD_TRAINING_VIDEO_LINK = gql`
    mutation AddTrainingVideoLink($input: VideoLinkInput!) {
        addTrainingVideoLink(input: $input)
    }`;
export const useTrainingGameVideoLink = () => useMutation(ADD_TRAINING_VIDEO_LINK);


const ADD_TRAINING_FILE = gql`
    mutation AddTrainingFile($input:TrainingFileInput!) {
        addTrainingFile(input:$input)
    }`;
export const useAddTrainingFile = () => useMutation(ADD_TRAINING_FILE);
