import React from 'react';
import classes from './VideoEditor.module.scss';
import cn from 'classnames';

const PlayerList = (props) => {
    const {players, isWeOwnerTeam, ownerTeamId, ownerTeamName, otherTeamId, otherTeamName} = props.data;

    const owner = {
        team: true,
        id: isWeOwnerTeam ? ownerTeamId: otherTeamId,
        name: isWeOwnerTeam ? ownerTeamName: otherTeamName,
        owner: true
    }

    const other = {
        team: true,
        id: !isWeOwnerTeam ? ownerTeamId: otherTeamId,
        name: !isWeOwnerTeam ? ownerTeamName: otherTeamName,
        owner: false
    }

    //console.log(selectedId, ownerTeamId, otherTeamId);

    return (
        <ul className={classes.editorList}>
            <li className={cn(classes.ourTeam, {[classes.selected]: owner.id === props.selectedId})} onClick={() => props.onClick(owner)}>
                <span className="icon-Players"></span>
                <span>{owner.name}</span>
                <span className="icon-Plus-Square"></span>
            </li>

            {players.map(p => (
                <li key={p.playerId} className={cn({[classes.selected]: p.playerId === props.selectedId})} onClick={() => props.onClick(p)}>
                    <span className="icon-Profile"></span>
                    <span>{p.playerName} ({p.teamNumber})</span>
                    <span className="icon-Plus-Square"></span>
                </li>
            ))}

            <li className={cn(classes.otherTeam, {[classes.selected]: other.id === props.selectedId})} onClick={() => props.onClick(other)}>
                <span className="icon-Players"></span>
                <span>{other.name}</span>
                <span className="icon-Plus-Square"></span>
            </li>
        </ul>
    );
};

PlayerList.defaultProps = {
    onClick: () => {},
    data: {},
    selectedId: null
};

export default PlayerList;