import React, {useEffect, useState} from 'react';
import Submenu from "components/Navigation/Submenu/Submenu";
import classes from "./Access.module.scss";
import {useTranslation} from "react-i18next";
import Table from "components/UI/Table/Table";
import Subtext from "components/UI/Table/Subtext/Subtext";
import Pagination from "components/UI/Pagination/Pagination";
import cn from 'classnames';
import Panel from "components/UI/Panel/Panel";
import Select from "components/UI/Select/Select";
import Search from "components/UI/Panel/Search/Search";
import AccessForm from "./forms/AccessForm";
import Button from "components/UI/Button/Button";
import Toast, {toastMessage} from 'components/UI/Toast/Toast';
import {useUsers} from 'apollo/queries/PermissionsQueries';
import DropdownButton from 'components/UI/DropdownButton/DropdownButton';
import StaffForm from './forms/StaffForm';
import SwitchComponents from 'components/System/SwitchComponents';
import RemoveAccessForm from './forms/RemoveAccessForm';
import {naviDataVar} from 'apollo/cashe';
import {useReactiveVar} from '@apollo/client';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import {useArchiveTrainer, useClubCoachLazy} from 'apollo/queries/TrainerQueries';

const StaffAccess = (props) => {

    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const [search, setSearch] = useState('');
    const [skip, setSkip] = useState(0);
    const [filters, setFilters] = useState({status: "all", parent: "all", staff: "all"});
    const [selectedRows, setSelectedRows] = useState([]);

    const resetModal = {
        open: false,
        id: `form-${Math.random()}`,
        page: 0
    };

    const [modalData, setModalData, initState] = ModalState();

    const [getUserList, userData] = useUsers();
    const [getClub, clubData] = useClubCoachLazy();
    const [archiveTrainer] = useArchiveTrainer();

    const getStatus = (data) => {
        switch (data) {
            case "ACTIVE":
                return t("Invited");
            case "INVITED":
                return t("Not accepted");
            case "REMOVED":
                return t("Removed");
            default:
                return t("Not invited");
        }
    }

    const staffType = ["Administrator", "Staff", "Coach", "Scout"];

    useEffect(() => {
        if (!navState.clubId) {
            return;
        }

        const variables = {
            clubId: navState.clubId,
            where: {
                "roles": { "some": {"name": {"in": staffType}}},
                "fullName": {"contains": search}
            },
            skip: skip * 10,
            take: 10
        };

        if (props.accessType === "team") {
            variables.teamId = navState.teamId;
        }

        if (filters.status !== "all")
            variables.where.invite = {"eq": filters.status};

        if (filters.staff !== "all")
            variables.where.roles = { "some": {"name": {"in": [filters.staff]}}};

        getUserList({variables: variables});
    }, [navState, search, skip, filters]);

    useEffect(() => {
        if (!clubData.data) {
            return;
        }

        const _data = {
            ...initState,
            form: "staff",
            title: t("Edit staff"),
            pages: {
                page: 0,
                maxPages: 3,
            },
            styles: {
                maxWidth: "700px",
            },
            defaultValues: clubData.data.staff[0],
            onComplete: onComplete,
            open: true
        }

        setModalData(_data);
    }, [clubData]);


    const onSearch = (e) => {
        setSkip(0);
        setSearch(e.target.value);
    }

    const onFilter = (e) => {
        const newFilters = {...filters};
        newFilters[e.target.name] = e.target.value;
        setFilters(newFilters);
    }

    const onAddStaff = (staffType) => {
        const isTrainer = staffType.value === "trainer";
        const isAdministrator = staffType.value === "admin";

        const data = {
            ...initState,
            form: "staff",
            title: t(staffType.label),
            pages: {
                page: 0,
                maxPages: 3,
            },
            styles: {
                maxWidth: "700px",
            },
            defaultValues: {
                isTrainer: isTrainer,
                isTrainerDisabled: isTrainer,
                isAdministrator: isAdministrator,
                isAdministratorDisabled: isAdministrator,
            },
            onComplete: onComplete,
            open: true
        }

        setModalData(data);
    }

    const onComplete = async (data) => {
        if (data) {
            toastMessage(data.result, data.message);
        }

        setModalData({...resetModal});
        await userData.refetch();
    };

    const actions = () => (
        <>
            <DropdownButton type="primary" options={props.staffUsers} onClick={onAddStaff}>
                <span><i className="icon-Plus-Square"></i> {t('Add staff')}</span>
            </DropdownButton>

            {selectedRows.length > 0 &&
              <Button type="secondary" style={{margin: 0}} onClick={onArchiveClick}>
                  <span>{t('Archive')}</span>
              </Button>
            }
        </>
    );

    const onArchiveClick = async () => {
        selectedRows.forEach(row => {
            const variables = {
                input: {
                    id: row.id,
                    clubId: navState.clubId,
                }
            };

            archiveTrainer({variables: variables}).then((result) => {
                if (result) userData.refetch();
            });
        });
    }

    const panelFilters = () => (
        <>
            <Select
                small="small"
                name="status"
                value={filters}
                onChange={onFilter}
                options={props.status}
                placeholder={t("Status")} />

            <Select
                small="small"
                name="staff"
                value={filters}
                onChange={onFilter}
                options={props.staff}
                placeholder={t("User type")} />
        </>
    );

    const panelSearch = () => (
        <Search onSearch={onSearch} placeholder={t("Search user...")} />
    );

    const onInvite = (data) => {
        const _data = {
            ...initState,
            title: t("Send invite"),
            form: "invite",
            defaultValues: {
                id: data.id,
                name: data.fullName,
                email: data.email ?? "",
                roles: data.roles.map(x => x.roleId),
            },
            onComplete: onComplete,
            open: true
        }

        setModalData(_data);
    }

    const onReInvite = (data) => {
        const _data = {
            ...initState,
            title: t("Send re-invite"),
            form: "invite",
            defaultValues: {
                id: data.id,
                name: data.fullName,
                email: data.email ?? "",
                roles: data.roles.map(x => x.roleId),
            },
            onComplete: onComplete,
            open: true
        }

        setModalData(_data);
    }

    const onRemove = (data) => {
        const modal = {
            ...initState,
            form: "remove",
            defaultValues: {
                id: data.id,
                name: data.fullName,
                email: data.email,
                roles: data.roles.map(x => x.roleId)
            },
            buttons: {
                saveButtonText: "Remove"
            },
            title: t("Remove access"),
            onComplete: onComplete,
            open: true,
        };

        setModalData(modal);
    }


    const onUserClick = (data) => {
        const variables = {
            clubId: navState.clubId,
            trainerId: data.id
        };

        getClub({variables});
    }

    return (
        <>
            <div className="page-header">
                <h2>{props.accessType === "club" ? t('Club access') : t('Team access')}</h2>
                <Submenu />
            </div>
            <div className="page-content">
                <Panel border={false} action={actions} filters={panelFilters} search={panelSearch}>
                    <br />
                </Panel>

                {userData.loading && <>Loading...</>}
                {userData.error && <>Error...</>}
                {userData.data &&
                    <>
                        <Table
                            data={userData.data.users.items}
                            columns={[
                                {
                                    Header: t('User name'),
                                    accessor: 'fullName',
                                    Cell: (cellData) => <div onClick={() => onUserClick(cellData.row.original)} style={{cursor: 'pointer'}}>
                                        {cellData.value}
                                        <Subtext>{t("Role")}: {cellData.row.original.roles.map((x,index) => (
                                            <span key={`${cellData.row.original.id}-${index}`} >{index ? ", " : undefined} {t(x.name)}</span>
                                        ))}</Subtext>

                                    </div>
                                },
                                {
                                    Header: t('E-mail'),
                                    className: classes.hide,
                                    accessor: 'email',
                                    Cell: (cellData) => <div>
                                        <a href="mailto:{cellData.value}">{cellData.value}</a>
                                        {cellData.row.original.phone && <Subtext>{t("Phone")}: <a href="phone:{cellData.row.original.phone}">
                                            {cellData.row.original.phone}</a></Subtext>}
                                    </div>
                                },
                                {
                                    Header: t('Status'),
                                    accessor: 'invite',
                                    className: cn(classes.buttons, classes.hide),
                                    Cell: (cellData) => {
                                        const {lastLogin} = cellData.row.original;
                                        return (<div>
                                            {getStatus(cellData.value)}
                                            {lastLogin && <Subtext>{t("Last login")}: {lastLogin}</Subtext>}
                                        </div>);
                                    }
                                },
                                {
                                    Header: t('Action'),
                                    accessor: 'action',
                                    className: classes.buttons,
                                    Cell: (cellData) => {
                                        const {invite} = cellData.row.original;
                                        return (
                                            <>
                                                {invite === "NONE" ? <Button type="primary"
                                                                             onClick={() => onInvite(cellData.row.original)} style={{width: "100px"}}>{t("Invite")}</Button> : undefined}

                                                {invite === "INVITED" || invite === "REMOVED"  ? <Button type="warning"
                                                                                onClick={() => onReInvite(cellData.row.original)} style={{width: "100px"}}>{t("Re-invite")}</Button> : undefined}

                                                {invite === "ACTIVE" ? <Button type="danger"
                                                                               onClick={() => onRemove(cellData.row.original)} style={{width: "100px"}}>{t("Remove")}</Button> : undefined}
                                            </>
                                        );
                                    }
                                }
                            ]}
                            onSelectClick={(x) => setSelectedRows(x)}
                            onRowClick={() => {}}/>

                        <Pagination page={skip} count={userData.data.users.totalCount}
                                    onPageChange={(p) => setSkip(p.selected)}/>
                    </>
                }
            </div>

            <ModalWindow2 {...modalData}>
                <SwitchComponents active={modalData.form}>
                    <AccessForm {...modalData} name="invite" />
                    <RemoveAccessForm {...modalData} name="remove" />
                    <StaffForm {...modalData} name="staff" />
                </SwitchComponents>
            </ModalWindow2>

            <Toast />
        </>
    );
};

StaffAccess.defaultProps = {
    status: [
        {label: "All", value: "all" },
        {label: "Not invited", value: "NONE" },
        {label: "Not accepted", value: "INVITED" },
        {label: "Invited", value: "ACTIVE" }
    ],
    staff: [
        {label: "All", value: "all" },
        {label: "Admins", value: "Administrator" },
        {label: "Coaches", value: "Coach" },
        {label: "Scouts", value: "Scout" },
        //{label: "Others", value: "OTHER" },
    ],
    staffUsers: [
        {label: "Add admin", value: "admin"},
        {label: "Add coach", value: "trainer"},
    ]
}
export default StaffAccess;
