import {
  currentDayOfWeek,
  dayEnd, dayNumber, daysInMonth,
  dayStart, firstDayOfMonth,
  firstDayOfWeek, firstDayOfYear, lastDayOfMonth,
  lastDayOfWeek, lastDayOfYear, monthNumber,
  nextHour,
  showDate, showMonth,
  showPeriod, showYear
} from '../../../utils/date';

import {
  format,
  add,
} from 'date-fns';

const weekendsDays = [6,7];
const formatCols = (obj, unit) => (
    [...Array(obj.colsCount).keys()].map(i => {

      let start = obj.start;
      let end = obj.end;
      let title = "";

      switch (unit) {
        case "h":
          start = add(start, {hours: i});
          end = add(start, {hours: 1});
          title = format(start, 'HH:00');
          break;
        case "d":
          start = add(start, {days: i});
          end = add(start, {days: 1});
          title = format(start, 'dd.MM');
          break;
        case "m":
          start = add(start, {months: i});
          end = add(start, {months: 1});
          title = format(start, 'MM.yyyy');
          break;
      }

      return  {
        id: `col-${i}`,
        title: title,
        start: start,
        end: end
      };
    }));

// const getWeekends = (obj) => (
//     [...Array(obj.colsCount).keys()].filter( o => {
//       const day = moment(obj.start).add(o, 'd').isoWeekday()
//       return  obj.weekends.includes(day);
//     }).map(i => (i))
// );

const DAY_SETTINGS = (current = new Date()) => {
  const data = {
    start: dayStart(current),
    end: dayEnd(current),
    show: showDate(current),
    colsCount: 24,
    cols: [],
    weekends: [0, 1, 2, 3, 4, 5, 6, 7, 8, 20, 21, 22, 23],
    scrollTo: nextHour,
    prev: (date) => add(date, {days: -1}),
    next: (date) => add(date, {days: 1}),
  };

  data.cols = formatCols(data, 'h', 'HH:00');
  return data;
}


const WEAK_SETTINGS = (current = new Date()) => {
  const start = firstDayOfWeek(current);
  const end = lastDayOfWeek(current);
  const show = showPeriod(start, end);

  const data = {
    start: start,
    end: end,
    show: show,
    colsCount: 7,
    cols: [],
    weekends: weekendsDays,
    scrollTo: currentDayOfWeek(start),
    prev: (date) => add(date, {days: -7}),
    next: (date) => add(date, {days: 7}),
  }

  data.cols = formatCols(data, 'd');
  //data.weekends  = getWeekends(data);

  return data;
}


const MONTH_SETTINGS = (current = new Date()) => {
  const data = {
    start: firstDayOfMonth(current),
    end: lastDayOfMonth(current),
    show: showMonth(current),
    colsCount: daysInMonth(current),
    cols: [],
    weekends: weekendsDays,
    scrollTo: dayNumber(current),
    prev: (date) => add(date, {months: -1}),
    next: (date) => add(date, {months: 1}),
  };
  data.cols = formatCols(data, 'd');
  //data.weekends  = getWeekends(data);

  return data;
}


const YEAR_SETTINGS = (current = new Date()) => {
  const date = {
    start: firstDayOfYear(current),
    end: lastDayOfYear(current),
    show: showYear(current),
    colsCount: 12,
    cols: [],
    scrollTo: monthNumber(current),
    onChange: { amount: 1, unit: 'y' }
  }
  date.cols = formatCols(date, 'm');

  return date;
}


const settings = [
  {
    id: 'item-1',
    name: "Trainings",
    items: [],
    children: [
      {
        id: 'item-4',
        name: "Team one",
        items: [
          {
            id: 'item-5',
            name: "Training 1 🙌",
            start: new Date("2021-05-13T16:00:00"),
            end: new Date("2021-05-13T18:00:00")
          },
          {
            id: 'item-6',
            name: "Training 2 🙌",
            start: new Date("2021-05-13T17:00:00"),
            end: new Date("2021-05-13T19:00:00")
          }
        ]
      }
    ]
  },
  {
    id: 'item-2',
    name: "Games",
    items: [],
    children: []
  },
  {
    id: 'item-3',
    name: "Birth days",
    items: [],
    children: []
  }
];


export { DAY_SETTINGS, WEAK_SETTINGS, MONTH_SETTINGS, YEAR_SETTINGS }
