import React, {useState} from 'react';
import Textarea from '../../../../components/UI/Textarea/Textarea';
import {useTranslation} from 'react-i18next';
import {notHaveErrors, validateFields, validateForm} from '../../../../utils/form';
import * as yup from 'yup';
import {createGuid} from '../../../../utils/utils';
import {validDate} from '../../../../utils/date';
import {useSaveTranslate} from '../../../../apollo/queries/SettingQueries';

const TranslateEditor = (props) => {
    const {defaultValues, setLoading, onComplete} = props;

    const {t} = useTranslation();

    const [values, setValues] = useState(defaultValues);
    const [errors, setErrors] = useState({});

    const[saveTranslate] = useSaveTranslate()

    const fieldChanged = (event) => {
        const newValues = {...values};
        newValues[event.target.name] = event.target.value;

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });

        setValues(newValues);
    };

    const validationRules = {
        value: yup.string().required(t("Require")),
    };

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors
    };

    const formSubmitted = (e) => {
        e.preventDefault();
        setLoading(true);
        validateForm(values, validationRules, (errors) => {
            if (notHaveErrors(errors)) {
                saveData();
            } else {
                setLoading(false);
                setErrors(errors);
            }
        });
    };

    const saveData = () => {
        const variables = {
            input: {
                messageKeyId: values.messageKeyId,
                language: values.language,
                code: values.code,
                prefix: values.prefix,
                value: values.value,
            }
        };

        saveTranslate({variables: variables}).then(result => {
            onComplete();
        });
    }

    return (
        <>
            <form id={props.id} onSubmit={formSubmitted}>
                <Textarea
                    name="code"
                    label={t("Tag")}
                    disabled={true}
                    {...regField}
                />

                <Textarea
                    name="value"
                    height="150px"
                    label={t("Translate")}
                    {...regField}
                />
            </form>
        </>
    );
};

TranslateEditor.defaultProps = {};

export default TranslateEditor;
