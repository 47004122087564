import React, {useEffect, useState} from 'react';
import {useReactiveVar} from '@apollo/client';
import {regStateVar} from "apollo/variables/registration";
import UserEmail from 'containers/SysAdmin/Registration/UserEmail/UserEmail';
import UserRole from 'containers/SysAdmin/Registration/UserRole/UserRole';
import IsClubRegistered from 'containers/SysAdmin/Registration/IsClubRegistered/IsClubRegistered';
import AskInvite from 'containers/SysAdmin/Registration/AskInvite/AskInvite';
import CreateOrRecommend from 'containers/SysAdmin/Registration/CreateOrRecommend/CreateOrRecommend';
import RecommendUs from 'containers/SysAdmin/Registration/RecommendUs/RecommendUs';
import CreateNewClub from 'containers/SysAdmin/Registration/CreateNewClub/CreateNewClub';
import UserInfo from 'containers/SysAdmin/Registration/UserInfo/UserInfo';
import Finish from 'containers/SysAdmin/Registration/Finish/Finish';
import ThankYou from 'containers/SysAdmin/Registration/ThankYou/ThankYou';
import Variants from 'containers/SysAdmin/Registration/Variants/Variants';
import WriteToSupport from 'containers/SysAdmin/Registration/WriteToSupport/WriteToSupport';
import MessageSent from 'containers/SysAdmin/Registration/MessageSent/MessageSent';

const Registration = () => {
  const regState = useReactiveVar(regStateVar);

  return (
      <>
        {regState.main === 'UserEmail' && <UserEmail/>}
        {regState.main === 'UserRole' && <UserRole/>}
        {regState.main === 'IsClubRegistered' && <IsClubRegistered/>}
        {regState.main === 'AskInvite' && <AskInvite/>}
        {regState.main === 'CreateOrRecommend' && <CreateOrRecommend/>}
        {regState.main === 'RecommendUs' && <RecommendUs/>}
        {regState.main === 'CreateNewClub' && <CreateNewClub/>}
        {regState.main === 'UserInfo' && <UserInfo/>}
        {regState.main === 'Finish' && <Finish/>}
        {regState.main === 'ThankYou' && <ThankYou/>}
        {regState.main === 'Variants' && <Variants/>}
        {regState.main === 'WriteToSupport' && <WriteToSupport/>}
        {regState.main === 'MessageSent' && <MessageSent/>}
      </>
  );
}

export default Registration
