import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from '../../../../apollo/cashe';
import {notHaveErrors, validateFields, validateForm} from '../../../../utils/form';
import * as yup from 'yup';
import Input from '../../../../components/UI/Input/Input';
import {useSaveOpponent} from '../../../../apollo/queries/GameQueries';
import {createGuid} from '../../../../utils/utils';

const OpponentForm = (props) => {
    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);
    const [values, setValues] = useState(props.defaultValues);
    const [errors, setErrors] = useState({});

    const [saveOpponent] = useSaveOpponent(props.queryVariables);

    const fieldChanged = (event) => {
        const newValues = { ...values };
        newValues[event.target.name] = event.target.value

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });
        setValues(newValues);
    }

    const validationRules = {
        name: yup.string().required(t("Require"))
    }

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors
    };

    const formSubmitted = (e) => {
        e.preventDefault();
        validateForm(values, validationRules, (errors) => {
            if (notHaveErrors(errors))
                saveData();
            else
                setErrors(errors);
        });
    }

    const saveData = () => {
        const variables = {
            input: {
                id: values?.id ?? createGuid(),
                teamId: navState.teamId,
                name: values.name
            }
        };

        saveOpponent({variables: variables}).then(result => {
            props.onComplete({
                target: {
                    name: props.name,
                    value: values.name
                }
            });
        });
    }

    return (
        <>
            <form id={props.id} onSubmit={formSubmitted}>
                <Input
                    name="name"
                    label={t('Team name')}
                    {...regField}
                />
            </form>
        </>
    );
};

OpponentForm.defaultProps = {
    values: {},
    queryVariables: {},
    onComplete: () => {}
};

export default OpponentForm;
