import React, {useEffect, useState} from 'react';
import Card from '../../../components/UI/Card/Card';
import classes from './MatchesDetailsStat.module.scss';
import Group from '../../../components/UI/Group/Group';
import {useTranslation} from 'react-i18next';
import Button from '../../../components/UI/Button/Button';
import {useParams} from 'react-router-dom';
import ModalWindow from '../../../components/UI/ModalWindow/ModalWindow';
import SubstitutionForm from './forms/SubstitutionForm';
import {DataEvents} from './Settings';
import {useDeleteDataEventRecord} from '../../../apollo/queries/GameQueries';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from '../../../apollo/cashe';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';
import CanAccess from 'components/Navigation/CanAccess/CanAccess';

const MatchesDetailsStat = (props) => {
    const {t} = useTranslation();
    const {gameId} = useParams();
    const navState = useReactiveVar(naviDataVar);
    const [shorts, setShorts] = useState(0);
    const [shortsOnTarget, setShortsOnTarget] = useState(0);
    const [longPasses, setLongPasses] = useState(0);
    const [firstTeam, setFirstTeam] = useState([]);
    const [reserveTeam, setReserveTeam] = useState([]);
    const [players, setPlayers] = useState([]);
    const [scheme, setScheme] = useState([]);
    const [editSubstitutionId, setEditSubstitutionId] = useState(null);

    const [deleteDataEventRecord] = useDeleteDataEventRecord();

    const [modalData, setModalData, initState] = ModalState();

    useEffect(() => {
        const first = props.data.players.filter(f => f.substitute === false);
        setFirstTeam(first);

        const reserve = props.data.players.filter(f => f.substitute !== false);
        setReserveTeam(reserve);

        const substitutionPlayers = props.data.players.map(p => ({label: p.playerName, value: p.playerId}));
        setPlayers(substitutionPlayers);

        const shortsCount = props.data?.eventRecords
            .filter(x => x.gameDataEventId === DataEvents.Shots)?.length ?? 0;
        setShorts(shortsCount);

        const shortsOnTargetCount = props.data?.eventRecords
            .filter(x => x.gameDataEventId === DataEvents.ShotsOnTarget)?.length ?? 0;
        setShortsOnTarget(shortsOnTargetCount);

        const longPassesCount = props.data?.eventRecords
            .filter(x => x.gameDataEventId === DataEvents.LongPasses)?.length ?? 0;
        setLongPasses(longPassesCount);

        const tacticalScheme = props.data.tacticalScheme ?? "?";
        setScheme(tacticalScheme);
    }, [props.data]);

    const onAddCompetition = () => {
        const _data = {
            ...initState,
            title: t("Add substitution"),
            form: "substitution",
            players: players,
            onComplete: onComplete,
            open: true
        }

        setModalData(_data);
    }

    const onComplete = async () => {
        setModalData(initState);
        await props.refetch();
    }

    const onEditSubstitution = (row) => {
        const _data = {
            ...initState,
            title: t("Edit substitution"),
            form: "substitution",
            defaultValues: {
                ...row
            },
            players: players,
            onComplete: onComplete,
            onDelete: () => onDeleteSubstitution(row),
            open: true
        }

        setModalData(_data);
    }

    const onDeleteSubstitution = async (row) => {
        const variables = {
            input: {
                id: row.id,
                clubId: navState.clubId,
                teamId: navState.teamId,
            }
        };

        setEditSubstitutionId(null);
        await deleteDataEventRecord({variables: variables})
        setModalData(initState);
        props.refetch();
    }

    return (
        <div className={classes.columns}>
            <div  className={classes.cards}>
                <Card name="Tactical positions" value={scheme} className={classes.card} />
                <Card name="Possession"  value="?" className={classes.card}  />
                <Card name="Shots" value={shorts} className={classes.card}  />
                <Card name="Shots On Target"  value={shortsOnTarget} className={classes.card}  />
                <Card name="Corners" value="?" className={classes.card}  />
                <Card name="Long passes"  value={longPasses} className={classes.card}  />
                <Card name="Offsides"  value="?" className={classes.card}  />
                <Card name="Pressure after loss"  value="?" className={classes.card}  />
                <Card name="Entrance to a zone 3 with control"  value="?" className={classes.card}  />
            </div>
            <div>
                <Group title="Main team">
                    <div className={classes.list}>
                        {firstTeam.map(p => <div key={p.playerId}><span className="icon-Profile"></span>{p.playerName} ({p.teamNumber})</div>)}
                    </div>
                </Group>
                <Group title="Reserve team">
                    <div className={classes.list}>
                        {reserveTeam.map(p => <div key={p.playerId}><span className="icon-Profile"></span>{p.playerName} ({p.teamNumber})</div>)}
                    </div>
                </Group>
                <Group title="Not selected players">
                    <div className={classes.list}>
                        {props.data.notSelectedPlayers.map(p => <div key={p.playerId}><span className="icon-Profile"></span>{p.playerName} ({p.teamNumber})</div>)}
                    </div>
                </Group>
            </div>
            <div>
                <Group title="Substitutions">
                    {props.data.eventRecordsWithoutVideo.filter(f => f.gameDataEventId === DataEvents.Substitution).map(event => {
                        const data = JSON.parse(event.data);
                        const playerIn = props.data.players.find(f => f.playerId === data.in);
                        const playerOut = props.data.players.find(f => f.playerId === data.out);

                        const formValues = { id: event.id, minutes: event.time,
                            playerInId: data.in, playerOutId: data.out };

                        return (
                        <div key={event.id} onClick={() => onEditSubstitution(formValues)} className={classes.substitution}>
                            <div className={classes.time}>{event.time}</div>
                            <div className={classes.up}><span className="icon-Arrow---Up"></span>{playerIn?.playerName} ({playerIn?.teamNumber})</div>
                            <div className={classes.down}><span className="icon-Arrow---Up"></span>{playerOut?.playerName} ({playerOut?.teamNumber})</div>
                        </div>
                    )})}

                    <CanAccess users={[SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin]}>
                        <div className={classes.addSubstitution}>
                            <Button type="second" onClick={onAddCompetition}>
                                <span><i className="icon-Plus-Square"></i> {t('Add substitution')}</span>
                            </Button>
                        </div>
                    </CanAccess>
                </Group>
            </div>

            <ModalWindow2 {...modalData}>
                <SubstitutionForm {...modalData} name="substitution" />
            </ModalWindow2>
        </div>
    );
};

MatchesDetailsStat.defaultProps = {
    refetch: () => {}
};

export default MatchesDetailsStat;
