import React from 'react';
import TooltipLight from 'react-tooltip-lite';
import classes from './Tooltip.module.scss';

const Tooltip = (props) => {
  const useHover = props.isOpen === undefined;

  return (
      <TooltipLight
          className={classes.Tooltip}
          useHover={useHover}
          isOpen={props.isOpen}
          direction="down-start"
          padding={0}
          background="#fff"
          mouseOutDelay={300}
          content={props.content}>
        {props.children}
      </TooltipLight>
  );
}

Tooltip.defaultProps = {
  color: "#55de84",
  title: "Sample title",
  location: "Sample location",
  time: "18.05.2021 15:00 - 18.05.2021 16:00",
  isOpen: undefined
}

export default Tooltip