import React from 'react';
import classes from './DateTime.module.scss';
import cn from 'classnames';
import {isValidDate, showTime} from '../../../../utils/date';
import {useReactiveVar} from '@apollo/client';
import {systemDataVar} from 'apollo/cashe';
import ShowDate from 'components/UI/DatePicker/ShowDate';

const DateTime = (props) => {
  const systemData = useReactiveVar(systemDataVar);

  if (!isValidDate(props.data)) return (
      <div className={cn(classes.date, props.className)}>
          <span> </span>
          {props.showTime && <span className={classes.time}> </span>}
      </div>
  );

  return (
      <div className={cn(classes.date, props.className)}>
          <span><ShowDate date={props.data} /></span>
          {props.showTime && <span className={classes.time}>{showTime(props.data, systemData.settings.general)}</span>}
      </div>
  );
};

DateTime.defaultProps = {
    showTime: true
};

export default DateTime;
