import React from 'react';

const Input = (props) => {

    return (
        <input
            name={props.name}
            className={props.className}
            type={props.type}
            onBlur={props.onBlur}
            onChange={props.onChange}
            defaultValue={props.value}
            disabled={props.disabled}
        />
    );
};

Input.defaultProps = {
    type: "text",
    value: "",
    onChange: () => {}
};

export default Input;
