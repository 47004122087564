import React from 'react';
import {
    Chart as ChartJS,
    PointElement,
    Tooltip,
    Legend,
    RadialLinearScale, LineElement, Filler
} from 'chart.js';
import {Radar} from 'react-chartjs-2';

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);

const SkillChart = (props) => {
    const {labels, data} = props.data;

    const options = {
        scale: {
            beginAtZero: true,
            min: 0,
            max: 10,
            step: 2
        },

        plugins: {
            legend: {
                display: false
            }
        }
    };

    const chartData = {
        labels: labels,
        datasets: [
            {
                data: data,
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
            },
        ],
    };

    return (
        <Radar options={options} data={chartData} />
    );
};

SkillChart.defaultProps = {};

export default SkillChart;
