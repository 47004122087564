import React from 'react';

export default function SwitchComponents(props) {
    const { active, children, setLoading } = props;

    const childrenWithProps = React.Children.map(children, child => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { setLoading });
        }
        return child;
    });

    return childrenWithProps.filter(child => child.props.name === active)
}
