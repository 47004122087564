import React, {useEffect, useState} from 'react';
import classes from './HeaderBar.module.scss'
import {useTranslation} from "react-i18next";
import Settings from 'containers/Settings/Settings';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar, systemDataVar} from 'apollo/cashe';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import {screenWidth} from 'utils/utils';
import useComponentVisible from 'components/Hooks/useComponentVisible';
import SwitchComponents from 'components/System/SwitchComponents';
import Tooltip from 'components/UI/Tooltip/Tooltip';
import {useAuth} from 'react-oidc-context';
import cn from 'classnames';
import {useLogoutAs} from 'apollo/queries/PermissionsQueries';
import {useNavigate} from 'react-router-dom';

export const HeaderBar = (props) => {
  const {t} = useTranslation();
  const history = useNavigate();
  const auth = useAuth();
  const systemData = useReactiveVar(systemDataVar);
  const navState = useReactiveVar(naviDataVar);

  const [openSettings, setOpenSettings] = useState(false);
  const [selectedChapter, setSelectedChapter] = useState('general');
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const [modalData, setModalData, initState] = ModalState();

  const [logoutAs] = useLogoutAs();

  const [CalendarComponent, setCalendarComponent] = useState(null)

  const loadCalendarComponent = async () => {
    const loadResult = await import('../../Calendar/Calendar');
    setCalendarComponent(() => loadResult.default);
  }

  const onMenu = (e) => {
    e.preventDefault();

    naviDataVar({
      ...navState,
      isMenuOpen: true
    });
  }

  useEffect(() => {
    setOpenSettings(navState.settings.open);
    setSelectedChapter(navState.settings.chapter);
  }, [navState]);

  const onSettings = (chapter) => {
    setSelectedChapter(chapter);
    setOpenSettings(true);
  }

  const onCloseSettings = () => {
    setOpenSettings(false);

    naviDataVar({
      ...navState,
      settings: {
        open: false,
        chapter: 'general'
      }
    });
  }

  const chat = "";
  const calendar = "";
  const notification = "";
  const help = `https://help.yoplaydo.com/l-${navState.language}`;

  const onLogout = () => {
    auth.signoutRedirect().then(() => {});
  }

  const onLogoutAs = () => {
    logoutAs().then((result) => {
      history(`/select-profile`);
    });
  }

  const subMenu = () => {
    return (
      <div className={classes.submenu}>
        <ul>
          <li onClick={() => onSettings('account')}>
            <div className={classes.account}>
              <div className={classes.photo} style={{backgroundImage: `url(${systemData.user.userPhoto})`}}></div>
              <div>
                <b>{systemData.user.fullName}</b>
                <span>{systemData.user.userRole.name}</span>
              </div>
            </div>
          </li>
          <li className={classes.item} onClick={() => onSettings('general')}><i className="icon-translate"></i> {t("Language")}</li>
          <li className={classes.item} onClick={() => onSettings('account')}><i style={{fontSize: '20px', margin:'0 13px 0 3px'}} className="icon-Profile"></i> {t("Account")}</li>
          <li className={classes.item} onClick={() => onSettings('settings')}><i  className="icon-Setting"></i> {t("Settings")}</li>

          {systemData.settings.isActor
            ? <li className={cn(classes.item, classes.red)} onClick={onLogoutAs}><i
              className="icon-Close-Square"></i> {t("Log out as")} {systemData.user.fullName}</li>
            : <li className={classes.item} onClick={onLogout}><i className="icon-Close-Square"></i> {t("Log out")}</li>
          }
        </ul>
      </div>
    );
  };

  const onCalendarClick = () => {

    const _data = {
      ...initState,
      form: "calendar",
      title : t("Calendar"),
      styles: {
        maxWidth: screenWidth() > 700 ? "95%" : "100%",
        minHeight: "calc(100vh - 200px)"
      },
      buttons: {
        hideSaveButton: true
      },
      defaultValues: {
      },
      open: true
    }

    loadCalendarComponent().then(() => {
      setModalData(_data);
    });
  }

  return (
    <>
      <div className={[classes.HeaderBar, "header-bar"].join(" ")}>
        <div className="menu" onClick={onMenu} title={t("Menu")}>
          <i  className="icon-Burger"></i>
        </div>
        <div className="search">
          <i  className="icon-Search"></i> {t("Search in system...")}
        </div>
        <div className={classes.chat}>
          <a className="tooltip-container" data-href={chat} data-scope="modal-dialog" title="Chats">
            <i  className="icon-Chat"></i>
          </a>
        </div>
        <div className={classes.calendar}>
          <div onClick={onCalendarClick} title={t("Calendar")} className={classes.calendar}><i  className="icon-Calendar"></i></div>
        </div>
        <div className={classes.notifications}>
          <a className="tooltip-container" data-href={notification} data-scope="modal-dialog" title={t("Notifications")}><i  className="icon-Notification"></i></a>
        </div>
        <div className={classes.help}>
          <a href={help} title={t("Help")} target="_blank"><i  className="icon-Bookmark"></i></a>
        </div>
        <div className={classes.settings}>
          <Tooltip content={subMenu()} isOpen={isComponentVisible}>
            <span
                ref={ref}
                onClick={() => setIsComponentVisible(true)}
                className={cn(classes.profile, {[classes.actor]: systemData.settings.isActor})}
                style={{backgroundImage: `url(${systemData.user.userPhoto})`}} />
          </Tooltip>
        </div>
      </div>

      {openSettings && <Settings selected={selectedChapter} onClose={onCloseSettings} />}

      <ModalWindow2 {...modalData}>
        <SwitchComponents active={modalData.form}>
          {CalendarComponent ? <CalendarComponent {...modalData} name="calendar" /> : undefined}
        </SwitchComponents>
      </ModalWindow2>
    </>
  );
};

HeaderBar.propTypes = {};
HeaderBar.defaultProps = {};
