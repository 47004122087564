import React, {useEffect, useState} from 'react';
import {notHaveErrors, validateFields, validateForm} from 'utils/form';
import Input from 'components/UI/Input/Input';
import {useTranslation} from 'react-i18next';
import Switch from 'components/UI/Switch/Switch';
import * as yup from 'yup';
import {useSaveDataEvent} from 'apollo/queries/GameQueries';
import {createGuid} from 'utils/utils';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import Select from 'components/UI/Select/Select';

const EventForm = (props) => {
    const [values, setValues] = useState(props.defaultValues ?? {
        name: "",
        parentId: "00000000000000000000000000000000",
        isVideo: true,
        isTtp: true,
        forAllTeams: true,
        haveSwitch: false,
        order: 0,
        isSystem: false,
        categoryId: "00000000000000000000000000000000",
    });

    const [parents, setParents] = useState([]);
    const [categories, setCategories] = useState([]);

    const [errors, setErrors] = useState({});
    const navState = useReactiveVar(naviDataVar);
    const {t} = useTranslation();

    const [saveDataEvent] = useSaveDataEvent();

    useEffect(() => {
        setCategories(props.categories.map(x => ({label: x.name, value: x.id})));
    }, []);

    useEffect(() => {
        const newParents = props.parents.filter(x => x.id !== props.defaultValues?.id && x.categoryId === values.categoryId)
            .map(e => ({label: e.name, value: e.id}));

        newParents.unshift({label: "No parent", value: "00000000000000000000000000000000"});
        setParents(newParents);
    }, [values.categoryId]);



    const fieldChanged = (event) => {
        const newValues = { ...values };
        newValues[event.target.name] = event.target.value

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });

        setValues(newValues);
    }

    const validationRules = {
        categoryId: yup.string().required(t("required")),
        name: yup.string().required(t("required")),
        order: yup.number().required(t("required")),
    }

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors
    };

    const formSubmitted = (e) => {
        e.preventDefault();
        validateForm(values, validationRules, (errors) => {
          if (notHaveErrors(errors))
            saveData();
          else
            setErrors(errors);
        });
    };

    const saveData = () => {
        const variables = {
            input: {
                id: values.id ?? createGuid(),
                categoryId: values.categoryId,
                parentId: values.parentId,
                clubId: navState.clubId,
                teamId: navState.teamId,
                name: values.name,
                isVideo: values.isVideo,
                isTtp: values.isTtp,
                haveSwitch: values.haveSwitch,
                forAllTeams: values.forAllTeams,
                order: +values.order
            }
        }

        saveDataEvent({variables: variables}).then(result => {
            props.onComplete();
        });
    };

    return (
        <>
            <form id={props.id} onSubmit={formSubmitted}>
                <Select
                    {...regField}
                    name="categoryId"
                    label={t('Category')}
                    options={categories}
                    disabled={values.isSystem}
                />

                <Select
                    {...regField}
                    name="parentId"
                    label={t('Parent')}
                    options={parents}
                    disabled={values.isSystem}
                />

                <Input
                    {...regField}
                    name="name"
                    label={t('Name')}
                    disabled={values.isSystem}
                />

                <Switch
                    {...regField}
                    fullSize={true}
                    name="haveSwitch"
                    label={t('Positive / negative mark')}
                    disabled={values.isSystem}
                />

                <Switch
                    {...regField}
                    fullSize={true}
                    name="isVideo"
                    label={t('Video')}
                />

                <Switch
                    {...regField}
                    fullSize={true}
                    name="isTtp"
                    label={t('Ttp')}
                />

                <Switch
                    {...regField}
                    fullSize={true}
                    name="forAllTeams"
                    label={t('All Teams')}
                    disabled={values.isSystem}
                />

                <Input
                    {...regField}
                    name="order"
                    type="number"
                    label={t('Order')}
                />
            </form>
        </>
    );
};

EventForm.defaultProps = {
    defaultValues: {},
    onComplete: () => {
    },
    parents: [],
    categories: []
};

export default EventForm;
