import React, {useEffect, useState} from 'react';
import classes from './CreateNewClub.module.scss';
import Progress from 'components/UI/Progress/Progress';
import Input from 'components/UI/Input/Input';
import Select from 'components/UI/Select/Select';
import Button from 'components/UI/Button/Button';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {regStateVar} from 'apollo/variables/registration';
import {notHaveErrors, validateFields, validateForm} from 'utils/form';
import * as yup from 'yup';
import axios from 'axios';

const CreateNewClub = () => {
  const {t} = useTranslation();
  const regState = useReactiveVar(regStateVar);

  const [values, setValues] = useState(regState);
  const [errors, setErrors] = useState({});
  const [settings, setSettings] = useState({});

  useEffect(() => {
    const storedSettings = sessionStorage.getItem('registrationSettings');
    if (storedSettings === null) {
      getSettings();
    } else {
      setSettings(JSON.parse(storedSettings));
    }
  }, []);

  useEffect(() => {
    const currency = settings.currency !== "" ? settings.currency : process.env.REACT_APP_DEFAULT_CURRENCY;
    const country = settings.country !== "" ? settings.country : process.env.REACT_APP_DEFAULT_COUNTRY;
    const newValues = {...values, country, currency};
    setValues(newValues);
  }, [settings]);

  const getSettings = () => {
    axios.get(`${process.env.REACT_APP_REGISTRATION_URL}/settings`)
        .then(res => {
          sessionStorage.setItem('registrationSettings', JSON.stringify(res.data));
          setSettings(res.data);
        });
  }

  const fieldChanged = (event) => {
    const newValues = {...values};

    newValues[event.target.name] = event.target.value;

    validateFields(newValues, validationRules, (errors) => {
      setErrors(errors);
    });
    setValues(newValues);
  };

  const regField = {
    value: values,
    onChange: fieldChanged,
    error: errors
  };

  const validationRules = {
    clubName: yup.string().required(t('Required')),
    country: yup.string().required(t('Required')),
    currency: yup.string().required(t('Required'))
  };

  const onGoTo = (goto, e) => {
    e.preventDefault();

    if (regState.isNew && regState.role < 3) goto = 'UserRole';
    if (!regState.isNew) goto = 'Variants';

    regStateVar({
      ...regState,
      main: goto
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    validateForm(values, validationRules, (errors) => {
      if (notHaveErrors(errors))
        regStateVar({
          ...regState,
          ...values,
          main: 'UserInfo'
        });
      else
        setErrors(errors);
    });
  };

  if (!settings) {
    return <>{t("Loading...")}</>
  }

  return (
      <div className={classes.UserEmail}>
        <Button onClick={e => onGoTo('CreateOrRecommend', e)}
                type="third">  &#8592; {t('goBack')}</Button>

        <h1>{t('infoAboutClub')}</h1>

        <Progress step="1" max="2"/>

        <div>
          <Input
              name="clubName"
              {...regField}
              placeholder={t('schoolName')}/>

          <Select
              name="country"
              {...regField}
              options={settings?.countries ?? []}
              placeholder={t('selectCountry')}/>

          <Select
              name="currency"
              {...regField}
              options={settings?.currencies ?? []}
              placeholder={t('selectCurrency')}/>

        </div>

        <Button onClick={onSubmit} type="primary">{t('Next')}</Button>
      </div>
  );
};

export default CreateNewClub;
