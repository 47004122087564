import React, {useEffect, useState} from 'react';
import Select from '../../../../components/UI/Select/Select';
import Input from '../../../../components/UI/Input/Input';
import Switch from '../../../../components/UI/Switch/Switch';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from '../../../../apollo/cashe';
import {notHaveErrors, validateFields, validateForm} from '../../../../utils/form';
import * as yup from 'yup';
import {createGuid} from '../../../../utils/utils';
import {useSaveSkill, useSaveSkillCategory} from '../../../../apollo/queries/PlayerQueries';

const SkillForm = (props) => {
    const {defaultValues, setLoading} = props;

    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const [categories, setCategories] = useState([]);
    const [values, setValues] = useState(defaultValues ?? {
        name: "",
        order: 0,
        showInClub: true,
        isSystem: false
    });

    const [errors, setErrors] = useState({});

    const [saveSkill] = useSaveSkill();

    useEffect(() => {
        setCategories(props.categories.map(x => ({label: x.name, value: x.id})));
    }, []);

    const fieldChanged = (event) => {
        const newValues = { ...values };
        newValues[event.target.name] = event.target.value

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });

        setValues(newValues);
    }

    const validationRules = {
        categoryId: yup.string().required(t("required")),
        name: yup.string().required(t("required")),
        order: yup.number().required(t("required")),
    }

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors
    };

    const formSubmitted = (e) => {
        e.preventDefault();
        setLoading(true);
        validateForm(values, validationRules, (errors) => {
            if (notHaveErrors(errors))
                saveData();
            else
                setErrors(errors);
            setLoading(false);
        });
    };

    const saveData = () => {
        const variables = {
            input: {
                skillId: values.skillId ?? 0,
                clubId: navState.clubId,
                name: values.name,
                skillCategoryId: values.categoryId,
                order: +values.order,
                showInClub: values.showInClub
            }
        }

        saveSkill({variables: variables}).then(result => {
            props.onComplete();
        });
    };

    return (
        <>
            <form id={props.id} onSubmit={formSubmitted}>
                <Select
                    {...regField}
                    name="categoryId"
                    label={t('Category')}
                    options={categories}
                    disabled={values.isSystem}
                />

                <Input
                    {...regField}
                    name="name"
                    label={t('Name')}
                    disabled={values.isSystem}
                />

                <Input
                    {...regField}
                    name="order"
                    type="number"
                    label={t('Order')}
                />

                <Switch
                    {...regField}
                    fullSize={true}
                    name="showInClub"
                    label={t('Enabled')}
                />
            </form>
        </>
    );
};

SkillForm.defaultProps = {};

export default SkillForm;
