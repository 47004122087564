import React from 'react';
import ReactDOM from 'react-dom/client';
import 'translate/i18n';
import { AuthProvider } from "react-oidc-context";
import './index.css';
import App from './App';

const oidcConfig = {
    authority: process.env.REACT_APP_AUTHORITY,
    client_id: 'yoplaydo_v2',
    redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    response_type: "code",
    scope: "openid profile email app.yoplaydo",
    post_logout_redirect_uri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <AuthProvider {...oidcConfig}>
        <App />
    </AuthProvider>
);
