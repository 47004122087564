import React, { PropTypes } from 'react'
import classes from '../Settings.module.scss';
import {useTranslation} from 'react-i18next';

const Mail = (props) => {
  const {t} = useTranslation();
  return (
      <>
        <div onClick={props.onBack} className={[classes.title, classes.link].join(" ")}>
          <i className={["icon-Arrow---Up", classes.backButton, "backArrow"].join(" ")}></i>
          {t("Mail settings")}
        </div>
      </>
  );
};

Mail.propTypes = {};
Mail.defaultProps = {};

export default Mail;