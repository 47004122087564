import React, {useEffect, useRef, useState} from 'react';
import {Editor} from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import classes from './TextEditor.module.scss';
import {haveDefaultValue, haveError} from 'utils/form';
import {useTranslation} from 'react-i18next';

const initEditorState = (html) => {
	const newContentBlock = htmlToDraft(html ? html : '');
	if (newContentBlock) {
		const newContentState = ContentState.createFromBlockArray(newContentBlock.contentBlocks);
		const newEditorState = EditorState.createWithContent(newContentState);
		return newEditorState;
	}

	return EditorState.createEmpty();
};

const TextEditor = (props) => {
	const {t} = useTranslation();

	const error = haveError(props);
	const defaultValue = haveDefaultValue(props);

	const [editorState, setEditorState] = useState(initEditorState(defaultValue));

	const onEditorStateChange = (editorState) => {
		setEditorState(editorState);
	}

	const onBlur = () => {
		const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
		props.onChange({
			target: {
				name: props.name,
				value: html
			}
		});
	}

	return (
		<>
	    <Editor
		    defaultEditorState={editorState}
		    toolbarClassName={classes.toolbar}
		    wrapperClassName="wrapperClassName"
		    editorClassName="editorClassName"
		    onBlur={onBlur}
		    onEditorStateChange={onEditorStateChange}
		    toolbar={{
			    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'image', 'remove', 'history'],
			    inline: {
				    options: ['bold', 'italic', 'underline', 'strikethrough'],
			    },
			    list: { inDropdown: true },
			    textAlign: { inDropdown: true },
		    }}
	    />

			{ error && <span>{error || t('Required')}</span> }
		</>
    );
};

TextEditor.defaultProps = {
	onChange: () => {},
};

export default React.memo(TextEditor);
