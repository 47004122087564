import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {notHaveErrors, validateForm} from 'utils/form';
import Submenu from 'components/Navigation/Submenu/Submenu';
import * as yup from 'yup';
import {useSaveExercise} from 'apollo/queries/ExerciseQueries';
import classes from './ExerciseForm.module.scss';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import PageThree from './PageThree';
import PageOne from './PageOne';
import PageTwo from './PageTwo';
import PageFour from './PageFour';
import {createGuid} from 'utils/utils';
import {fileUploader} from 'utils/upload';
import {useAuth} from 'react-oidc-context';

const ExerciseForm = (props) => {
    const {defaultValues, settings, setModal, pages, setLoading, onComplete} = props;

    const {t} = useTranslation();
    const auth = useAuth();
    const navState = useReactiveVar(naviDataVar);

    const [values, setValues] = useState({
        ...defaultValues,
        imageFile: undefined
    });
    const [errors, setErrors] = useState({});
    const [image, setImage] = useState(null);

    const [saveExercise] = useSaveExercise(settings);

    useEffect(() => {
        const {categories, categoryList, tags, tagList} = defaultValues;

        if (categories) {
            const _categoryList = [...categoryList];
            _categoryList.forEach(_group => {
                _group.items.forEach(_category =>
                    categories.indexOf(_category.name) !== -1 ? _category.selected = true : undefined);
            });

            setValues({...defaultValues, categoryList: _categoryList});
        }

        if (tags) {
            const _tagList = [...tagList];
            _tagList.forEach(_tag => {
                return tags.indexOf(_tag.tag) !== -1 ? _tag.selected = true : undefined;
            });

            setValues({...defaultValues, tagList: _tagList});
        }

    }, []);

    const fieldChanged = (event) => {
        const newValues = { ...values };
        newValues[event.target.name] = event.target.value;

        if (event.target.file !== undefined) {
            newValues[event.target.name+"File"] = event.target.file;
        }

        setValues(newValues);
    }

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors
    };

    const pageOneValidationRules = {
        name: yup.string().required(t("required")),
        duration: yup.string().required(t("required")),
        ageGroup: yup.string().required(t("required")),
        level: yup.string().required(t("required")),
        numberOfPlayers: yup.string().required(t("required")),
        language: yup.string().required(t("required")),
        accessType: yup.string().required(t("required")),
    }

    const formSubmitted = async (e) => {
        setLoading(true);
        e.preventDefault();

        new Promise(function(resolve, reject) {
            //Page 1
            validateForm(values, pageOneValidationRules, (errors) => {
                notHaveErrors(errors) ? resolve(1) : reject({page: 1, errors});
            });
        }).then(function(result) {
            //Page 2
            return new Promise(function(resolve, reject) {
                resolve(1);
            });
        }).then(function(result) {
            //Page 3
            return new Promise((resolve, reject) => {
                resolve(1);
            });
        }).then(function(result) {
            //Page 4
            return new Promise((resolve, reject) => {
                resolve(1);
            });
        }).then(function(result) {
            saveData();
        }).catch(e => {
            setLoading(false);
            setErrors(e.errors);
            onPageChanged(e.page);
        });
    };

    const onPageChanged = (newPage) => {
        const page = newPage - 1;
        setModal({...props, pages: { ...props.pages, page: page}});
    }


    const saveData = async () => {
        const selectedCategories = [].concat(...values.categoryList.map(x => x.items?.filter(i => i.selected).map(s=>s.name)));
        const selectedTags = values.tagList.filter(x => x.selected).map(s => s.tag);

        const variables = {
            input: {
                id: values.id ?? createGuid(),
                clubId: navState.clubId,
                name: values.name,
                duration: +values.duration,
                repeat: +values.repeat,
                rest: +values.rest,
                rhythm: +values.rhythm,
                coefficient: +values.coefficient,
                ageGroup: +values.ageGroup,
                level: values.level,
                numberOfPlayers: +values.numberOfPlayers,
                language: values.language,
                categories: selectedCategories,
                tags: selectedTags,
                description: values.description,
                tools: values.tools,
                accessType: values.accessType,
                scheme: {
                    background: values.scheme?.background,
                    schemeData: values.scheme?.schemeData,
                    elementList: values.scheme?.elementList,
                    frameList: values.scheme?.frameList,
                    kitData: values.scheme?.kitData,
                },
                video: values.video
            }
        }


        if (values.imageFile !== undefined) {
            const fileData = {
                clubId: navState.clubId,
                fileId: variables.input.id
            }

            if (await fileUploader(values.imageFile, fileData, auth)) {
                variables.input.imageUploadDate = new Date().toISOString();
            }
        }

        await saveExercise({variables: variables});
        onCompleted();
    }

    const onCompleted = (ok = true) => {
        const result = ok
            ? {
                result: true,
                message: values.id
                    ? t("Exercise successfully edited.")
                    : t("Exercise successfully added.")
            }
            : {
                result: false,
                message: t("Exercise not saved.")
            }

       onComplete(result);
    }

    const onImageChange = (data) => {
        setImage(data);
    }

    useEffect(() => {
        if (!image) return;
        fieldChanged(image);
    }, [image]);

    const pageProps = {values, setValues, errors, setErrors, regField, onImageChange};

    return (
        <>
            <Submenu default={pages.page + 1} data={props.pageNames} onFilter={onPageChanged} className={classes.submenu} />

            {pages.page === 0 ? <PageOne {...pageProps} /> : undefined }
            {pages.page === 1 ? <PageTwo {...pageProps} /> : undefined }
            {pages.page === 2 ? <PageThree {...pageProps} /> : undefined }
            {pages.page === 3 ? <PageFour {...pageProps} /> : undefined }

            <form id={props.id} onSubmit={formSubmitted}></form>
        </>
    );
};

ExerciseForm.defaultProps = {
    page: 0,
    pageNames: {
        1: "Exercise",
        2: "Categories",
        3: "Images",
        4: "Tags",
    },
    level: [],
    language: [],
    accessType: [],
    onComplete: () => {}
};

export default ExerciseForm;
