import React, {useEffect, useState} from 'react';
import ReactSelect, {components} from 'react-select';
import {useTranslation} from 'react-i18next';
import classes from './DropdownButton.module.scss';
import cn from 'classnames';

const Control = ({ children, ...props }) => {
    const {button} = props.selectProps;

    return (
        <components.Control {...props}>
            <span className={classes.button}>{button}</span>
            {children}
        </components.Control>
    );
};

const SingleValue = ({children, ...props}) => (
    <components.SingleValue {...props}></components.SingleValue>
)

const DropdownButton = (props) => {
    const {t} = useTranslation();
    const [options, setOptions] = useState(props.options);

    useEffect(() => {
        if (!options || options.length === 0)
            return;

        const translatedOptions = options.map((item) => {
            item.label = t(item.label);
            return item;
        });

        setOptions(translatedOptions);
    }, []);

    return (
        <div className={cn(classes.DropdownButton, classes[props.type])}>
            <ReactSelect
                options={props.options}
                classNamePrefix="DdButton"
                components={{ Control, SingleValue }}
                placeholder=""
                isSearchable={false}
                isClearable={false}
                onChange={props.onClick}
                button={props.children} />
        </div>
    );
};

DropdownButton.defaultProps = {
    options: [
        { value: 'value1', label: 'Value 1' },
        { value: 'value2', label: 'Value 2' },
    ],
    type: "primary",
    onClick: () => {}
};

export default DropdownButton;