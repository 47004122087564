import React, {Component} from 'react';
import {durationFormat, durationToString} from '../../../containers/Team/MatchesDetails/editor/Duration';
import {findDOMNode} from 'react-dom';
import ReactPlayer from 'react-player';

class VideoPlayer extends Component {
    state = {
        id: `video-player-${Math.random()}`,
        url: null,
        pip: false,
        playing: false,
        controls: true,
        light: false,
        volume: 0.8,
        muted: false,
        played: 0,
        loaded: 0,
        duration: 0,
        playbackRate: 1.0,
        loop: false,
        playedSeconds: 0,
        width: "100%",
        height: "100%",
        firstTime: true
    }

    load = url => {
        this.setState({
            url,
            played: 0,
            loaded: 0,
            pip: false
        })
    }

    componentDidMount() {
        this.load(this.props.url);

        const width = document.getElementById(this.state.id).clientWidth;
        const height = Math.round(width / 1.7);
        this.setState({height: height})
    }

    handlePlayPause = () => {
        this.setState({playing: !this.state.playing})
    }

    handleStop = () => {
        this.setState({playing: false})
    }

    handlePlay = () => {
        this.setState({playing: true})
    }

    handleToggleControls = () => {
        const url = this.state.url
        this.setState({
            controls: !this.state.controls,
            url: null
        }, () => this.load(url))
    }

    handleToggleLight = () => {
        this.setState({light: !this.state.light})
    }

    handleToggleLoop = () => {
        this.setState({loop: !this.state.loop})
    }

    handleVolumeChange = e => {
        this.setState({volume: parseFloat(e.target.value)})
    }

    handleToggleMuted = () => {
        this.setState({muted: !this.state.muted})
    }

    handleSetPlaybackRate = e => {
        this.setState({playbackRate: parseFloat(e.target.value)})
    }

    handleOnPlaybackRateChange = (speed) => {
        this.setState({playbackRate: parseFloat(speed)})
    }

    handleTogglePIP = () => {
        this.setState({pip: !this.state.pip})
    }

    handlePlay = () => {
        //console.log('onPlay')
        this.setState({playing: true})
    }

    handleSeek = (e) => {
        const seconds = parseFloat(e.target.value);
        const newTime = this.state.playedSeconds + seconds;
        this.player.seekTo(newTime);
    }

    handleEnablePIP = () => {
        //console.log('onEnablePIP')
        this.setState({pip: true})
    }

    handleDisablePIP = () => {
        //console.log('onDisablePIP')
        this.setState({pip: false})
    }

    handlePause = () => {
        //console.log('onPause')
        this.setState({playing: false})
    }

    handleSeekMouseDown = e => {
        this.setState({seeking: true})
    }

    handleSeekChange = e => {
        this.setState({played: parseFloat(e.target.value)})
    }

    handleSeekMouseUp = e => {
        this.setState({seeking: false})
        this.player.seekTo(parseFloat(e.target.value))
    }

    handleProgress = state => {
        // We only want to update time slider if we are not currently seeking
        if (!this.state.seeking) {
            this.setState(state)
        }
    }

    handleEnded = () => {
        //console.log('onEnded')
        this.setState({playing: this.state.loop})
    }

    handleDuration = (duration) => {
        //console.log('onDuration', duration)
        this.setState({duration})
    }

    // handleClickFullscreen = () => {
    //     screenfull.request(findDOMNode(this.player))
    // }

    renderLoadButton = (url, label) => {
        return (
            <button onClick={() => this.load(url)}>
                {label}
            </button>
        )
    }

    getEventTime = (time) => {
        const seconds = this.state.duration * this.state.played + time;
        return durationFormat(seconds > 0 ? seconds : 0);
    }

    getEventDuration = (time) => {
        const seconds = this.state.duration * this.state.played + time;
        return durationFormat(seconds < this.state.duration ? seconds : this.state.duration);
    }

    setEventTime = (time) => {
        const minutes = Math.trunc(time);
        const seconds = (time - minutes) * 100;
        this.player.seekTo(minutes * 60 + seconds);
    }

    onReady = () => {
        if (this.state.firstTime && this.props.time !== undefined) {

            this.setState({firstTime: false});
            this.setEventTime(this.props.time);
            this.handlePlay();
        }
    }

    ref = player => {
        this.player = player
    }

    render() {
        const {id, url, height, playing, controls, light, volume, muted, loop, playbackRate, pip } = this.state

        return (
            <div id={id}>
                <ReactPlayer
                    ref={this.ref}
                    className='react-player'
                    width='100%'
                    height={height}
                    url={url}
                    pip={pip}
                    playing={playing}
                    controls={controls}
                    light={light}
                    loop={loop}
                    playbackRate={playbackRate}
                    progressInterval={500}
                    volume={volume}
                    muted={muted}
                    onReady={this.onReady}
                    // onStart={() => console.log('onStart')}
                    onPlay={this.handlePlay}
                    onEnablePIP={this.handleEnablePIP}
                    onDisablePIP={this.handleDisablePIP}
                    onPause={this.handlePause}
                    //onBuffer={() => console.log('onBuffer')}
                    //onPlaybackRateChange={this.handleOnPlaybackRateChange}
                    //onSeek={e => console.log('onSeek', e)}
                    onEnded={this.handleEnded}
                    onError={e => console.log('onError', e)}
                    onProgress={this.handleProgress}
                    onDuration={this.handleDuration}
                />
            </div>
        );
    }
}

export default VideoPlayer
