import React, {useState} from 'react';
import Tabs from '../../../components/UI/Tabs/Tabs';
import MatchesDetailsVideoDataEvents from './MatchesDetailsVideoDataEvents';
import MatchesDetailsTtpMatrix from './MatchesDetailsTtpMatrix';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';
import CanAccess from 'components/Navigation/CanAccess/CanAccess';
import classes from './MatchesDetailsTtp.module.scss';
import {naviDataVar} from 'apollo/cashe';
import {useReactiveVar} from '@apollo/client';

const MatchesDetailsTtp = (props) => {
  const navState = useReactiveVar(naviDataVar);

  const [ttpTab, setTtpTab] = useState("all");

  const onSettings = () => {
    naviDataVar({
      ...navState,
      settings: {
        open: true,
        chapter: 'video'
      }
    });
  };

  return (
      <div>
        <div className={classes.header}>
          <Tabs items={props.ttpTabs} selected={ttpTab} onClick={(x) => setTtpTab(x)} />
          <span></span>
          <CanAccess users={[SystemRoles.Coach,SystemRoles.ClubAdmin,SystemRoles.SysAdmin]}><button onClick={onSettings}><i className="icon-Setting" /></button></CanAccess>
        </div>

        {ttpTab !== "activities" && <MatchesDetailsTtpMatrix data={props.data} refetch={props.refetch} />}
        {ttpTab === "activities" && <MatchesDetailsVideoDataEvents data={props.data} refetch={props.refetch} />}
      </div>
  );
};

MatchesDetailsTtp.propTypes = {};
MatchesDetailsTtp.defaultProps = {
  data: {},
  refetch: () => {},
  ttpTabs: [
    {id: "all", name: "All"},
    {id: "1", name: "1 half-time"},
    {id: "2", name: "2 half-time"},
  ]
};

export default MatchesDetailsTtp;
