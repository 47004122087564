import React, {useEffect, useRef, useState} from 'react';
import {notHaveErrors, validateForm} from '../../../../utils/form';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from '../../../../apollo/cashe';
import classes from './TestForm.module.scss';
import {useSaveMetric} from '../../../../apollo/queries/ExerciseQueries';
import Submenu from '../../../../components/Navigation/Submenu/Submenu';
import TestPageOne from './TestPageOne';
import TestPageTwo from './TestPageTwo';
import {toastMessage} from '../../../../components/UI/Toast/Toast';
import {createGuid} from '../../../../utils/utils';
import {fileUploader} from 'utils/upload';
import {useAuth} from 'react-oidc-context';

const TestForm = (props) => {
    const {settings, defaultValues, pages, pageNames, setModal, setLoading, onComplete} = props;

    const start = 4;
    const end = 19;
    const ages = [...Array(end - start + 1).keys()].map(x => x + start);

    const navState = useReactiveVar(naviDataVar);
    const {t} = useTranslation();
    const auth = useAuth();

    const [errors, setErrors] = useState({});
    const [values, setValues] = useState(defaultValues ?? {
        name: "",
        order: 0,
        isSystem: false,
        logoFile: undefined
    });

    const [saveMetric] = useSaveMetric();

    useEffect(() => {
        const newValues = {...values};

        const norms = values.norms ?? [];
        norms.forEach(x => {
            newValues[`value${x.age}`] = x.value;
            newValues[`valueWell${x.age}`] = x.valueWell;
            newValues[`valueVeryWell${x.age}`] = x.valueVeryWell;
            newValues[`altValue${x.age}`] = x.altValue;
            newValues[`altValueWell${x.age}`] = x.altValueWell;
            newValues[`altValueVeryWell${x.age}`] = x.altValueVeryWell;
        });
        setValues(newValues);
    }, []);

    const fieldChanged = (event) => {
        const newValues = { ...values };
        newValues[event.target.name] = event.target.value

        if (event.target.file !== undefined) {
            newValues[event.target.name+"File"] = event.target.file;
        }

        setValues(newValues);
    }

    const pageOneValidationRules = {
        metricCategoryId: yup.string().required(t("required")),
        name: yup.string().required(t("required")),
        unitOfMeasure: yup.string().required(t('required')),
        systemOfMeasurement: yup.string().required(t('required')),
        attempts: yup.string().required(t("required")),
        order: yup.number().required(t("required")),
    }

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors
    };

    const formSubmitted = async (e) => {
        e.preventDefault();
        setLoading(true);

        new Promise(function(resolve, reject) {
            //Page 1
            validateForm(values, pageOneValidationRules, (errors) => {
                notHaveErrors(errors) ? resolve(1) : reject({page: 1, errors});
            });
        }).then(function(result) {
            //Page 2
            return new Promise(function(resolve, reject) {
                resolve(1);
            });
        }).then(function(result) {
            saveData();
        }).catch(e => {
            setLoading(false);
            setErrors(e.errors);
            onPageChanged(e.page);
        });
    };

    const saveData = async () => {
        const variables = {
            input: {
                metricId: values.metricId,
                clubId: navState.clubId,
                name: values.name,
                description: values.description,
                unitOfMeasure: values.unitOfMeasure,
                systemOfMeasurement: values.systemOfMeasurement,
                attempts: values.attempts,
                metricCategoryId: values.metricCategoryId,
                showInClub: values.showInClub,
                order: +values.order,
                norms: []
            }
        }

        ages.forEach(x => {
            const norm = {
                age: x,
                value: +values[`value${x}`],
                valueWell: +values[`valueWell${x}`],
                valueVeryWell: +values[`valueVeryWell${x}`],
                altValue: +values[`altValue${x}`],
                altValueWell: +values[`altValueWell${x}`],
                altValueVeryWell: +values[`altValueVeryWell${x}`],
            };
            variables.input.norms.push(norm);
        });

        if (values.logoFile !== undefined) {

            const logoId = values.logoId ?? createGuid();

            const fileData = {
                clubId: navState.clubId,
                fileId: logoId
            }

            if (await fileUploader(values.logoFile, fileData, auth)) {
                variables.input.logoId = logoId;
                variables.input.logoUploadDate = new Date().toISOString();
            }
        }

        await saveMetric({variables: variables});
        props.onComplete();
    };

    const onPageChanged = (newPage) => {
        const page = newPage - 1;
        setModal({...props, pages: { ...props.pages, page: page}});
    }

    const pageProps = {settings, values, setValues, errors, setErrors, regField, ages};

    return (
        <>
            <Submenu default={pages.page + 1} data={pageNames} onFilter={onPageChanged} className={classes.submenu} />

            <form id={props.id} onSubmit={formSubmitted}>


                {pages.page === 0 ? <TestPageOne {...pageProps} /> : undefined }
                {pages.page === 1 ? <TestPageTwo {...pageProps} /> : undefined }
            </form>
        </>
    );
};

TestForm.defaultProps = {
    pageNames: {
        1: "General info",
        2: "Norms",
    },
    defaultValues: {},
    onComplete: () => {
    },
};

export default TestForm;
