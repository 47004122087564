import React, {useEffect, useState} from 'react';
import Input from '../../../../components/UI/Input/Input';
import classes from '../Matches.module.scss';
import Select from '../../../../components/UI/Select/Select';
import DatePicker from '../../../../components/UI/DatePicker/DatePicker';
import SelectWithAdd from '../../../../components/UI/Select/SelectWithAdd';
import Switch from '../../../../components/UI/Switch/Switch';
import Textarea from '../../../../components/UI/Textarea/Textarea';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from '../../../../apollo/cashe';
import SwitchComponents from '../../../../components/System/SwitchComponents';
import ModalWindow2, {ModalState} from '../../../../components/UI/ModalWindow/ModalWindow2';
import CompetitionForm from '../../Competitions/forms/CompetitionForm';
import OpponentForm from '../../Opponents/forms/OpponentForm';
import {MatchType} from '../../MatchesDetails/Settings';
import VenueForm from '../../../Settings/Venues/forms/VenueForm';

const MatchFormOne = (props) => {
    const {values, regField, data, refetch} = props;

    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const [competitions, setCompetitions] = useState([]);
    const [opponents, setOpponents] = useState([]);
    const [venues, setVenues] = useState([]);
    const [countries, setCountries] = useState([]);

    const [modalData, setModalData, initState] = ModalState();

    useEffect(() => {
        if (!data)
            return;

        const {competitions, opponents, venues, otherSettings} = data;

        const competitionList = competitions.items.map(x => ({label: `${x.name} (${x.season})`, value: x.championshipId }));
        const opponentList = opponents.items.map(x => ({label: x.name, value: x.id}));
        const venueList = venues.items.map(x => ({label: x.name, value: x.venueId}));
        const countryList = otherSettings.map(x => ({label: x.value, value: x.key}));

        setCompetitions(competitionList);
        setOpponents(opponentList);
        setVenues(venueList);
        setCountries(countryList);

    }, [data]);

    const onAddCompetition = () => {
        const _data = {
            ...initState,
            title: t("Add new competition"),
            styles: {
                maxWidth: "650px",
            },
            form: "competition",
            queryVariables: {
               teamId:  navState.teamId
            },
            countries: countries,
            onComplete: onComplete,
            open: true
        }

        setModalData(_data);
    }

    const onAddOpponent = () => {
        const _data = {
            ...initState,
            title: t("Add new opponent"),
            form: "opponent",
            queryVariables: {
                teamId: navState.teamId
            },
            onComplete: onComplete,
            open: true
        }

        setModalData(_data);
    }

    const onAddVenue = () => {
        const _data = {
            ...initState,
            title: t("Add new venue"),
            form: "venue",
            queryVariables: {
                teamId: navState.teamId
            },
            onComplete: onComplete,
            open: true
        }

        setModalData(_data);
    }

    const onComplete = () => {
        setModalData(initState);
        refetch();
    }

    return (
        <>
            <Input
                name="name"
                label={t('Our team name')}
                {...regField}
            />

            <div className={classes.columns11}>
                <Select
                    name="scope"
                    label={t('Match type')}
                    options={MatchType()}
                    {...regField} />

                <DatePicker
                    name="date"
                    time={true}
                    onChange={() => {}}
                    label={t('Date')}
                    {...regField}
                />
            </div>

            {values.scope === "COMPETITION" &&
                <SelectWithAdd
                    name="competition"
                    label={t('Competition')}
                    options={competitions}
                    onAddClick={onAddCompetition}
                    {...regField} />
            }

            <SelectWithAdd
                name="opponent"
                label={t('Opponent')}
                options={opponents}
                onAddClick={onAddOpponent}
                {...regField} />

            <SelectWithAdd
                name="venue"
                label={t('Venue')}
                options={venues}
                onAddClick={onAddVenue}
                {...regField} />


            <div className={classes.columns11}>
                <Input
                    name="duration"
                    type="number"
                    label={t('Duration (min)')}
                    {...regField}
                />

                <Input
                    name="periodCount"
                    type="number"
                    label={t('Amount of periods')}
                    {...regField}
                />
            </div>

            <div className={classes.spaces}>
                <Switch
                    {...regField}
                    fullSize={true}
                    name="awayMatch"
                    label={t('Away game')}
                    {...regField}
                />
            </div>
            <Textarea
                name="matchNotes"
                label={t('Notes')}
                {...regField}
            />

            <ModalWindow2 {...modalData}>
                <SwitchComponents active={modalData.form}>
                    <CompetitionForm {...modalData} name="competition" />
                    <OpponentForm {...modalData} name="opponent" />
                    <VenueForm {...modalData} name="venue" />
                </SwitchComponents>
            </ModalWindow2>
        </>
    );
};

MatchFormOne.defaultProps = {
};

export default MatchFormOne;
