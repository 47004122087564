import React from 'react'
import {useTranslation} from 'react-i18next';
import Button from '../Button/Button';
import classes from './Empty.module.scss';

const Empty = (props) => {
  const { t } = useTranslation();
  return (
      <div className={classes.center}>
        <div className={classes.logo}>
          <img src="/assets/images/emptyLogo.svg" width="200" height="200" alt="YoPlayDo" title="YoPlayDo" />
        </div>
        <div className={classes.emptyTitle}>{t('Empty page')}</div>
        <div className={classes.emptyDescription}>{t('Looks like you not add any data.')}<br />
          {props.onAddClick ? <React.Fragment>{t('Let\'s fix it!')}</React.Fragment> : null }
        </div>
        {props.onAddClick
            ? <Button style={{marginRight: 0}} type="primary" onClick={props.onAddClick}>{t('Add')}</Button>
            : null
        }
      </div>
  );
};

export default Empty;
