export const metricSettings = {
    unitOfMeasure: [
        {label: "Centimeter", value: "CENTIMETER", group: "Metric"},
        {label: "Meter", value: "METER", group: "Metric"},
        {label: "Kg", value: "KG", group: "Metric"},

        {label: "Inch", value: "Inch", group: "Imperial"},
        {label: "Foot", value: "METER", group: "Imperial"},
        {label: "Pound", value: "KG", group: "Imperial"},

        {label: "Quantity", value: "QUANTITY", group: "All"},
        {label: "Time", value: "TIME", group: "All"},
        {label: "TimeMS", value: "TIMEMS", group: "All"},
        {label: "Percent", value: "PERCENT", group: "All"},
        {label: "Score", value: "SCORE", group: "All"},
        {label: "Text", value: "TEXT", group: "All"}
    ],
    systemOfMeasurement: [
        {label: "None", value: "NONE"},
        {label: "More is better", value: "MORE_BETTER"},
        {label: "Less is better", value: "LESS_BETTER"},
    ]
};
