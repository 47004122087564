import {gql, useLazyQuery, useMutation, useQuery} from '@apollo/client';

export const COACH_FIELDS = gql`
    fragment CoachFields on TrainerType {
        trainerId
        firstName
        lastName
        middleName
        fullName
        dateOfBirth
        position
        careerStartDate
        certification
        certificationEndDate
        phoneNumber
        email
        addressLine
        photo
        photoUploadDate
        passportNumber
        passportDateOfIssue
        passportDateOfExpiry
        countryOfBirth
        cityOfBirth
        citizenship
        personalCode
        isAdministrator
        isTrainer
        contactStatus
    }
`;

export const GET_CLUB_COACHES = gql`
    ${COACH_FIELDS}
    query ClubCouches($clubId: String!, $search: String, $skip: Int!, $take: Int!) {
        trainers(
            clubId: $clubId
            where: {
                fullName: { contains: $search }
            }
            take: $take
            skip: $skip
        ) {
            totalCount
            items {
                ...CoachFields
                teams(where: {endDate: {eq: null}}) {
                    type
                    team {
                        name
                    }
                }
            }
        }
    }`;
export const useClubCoaches = (variables) => useQuery(GET_CLUB_COACHES, {variables});


export const GET_CLUB_STAFF = gql`
    ${COACH_FIELDS}
    query ClubStaff($clubId: String!, $trainerId: Uuid!) {
        staff(
            clubId: $clubId
            where: {
                trainerId: { eq: $trainerId }
            }
        ) {
            ...CoachFields
        }
    }`;
export const useClubCoachLazy = () => useLazyQuery(GET_CLUB_STAFF, {
    fetchPolicy: `network-only`,
});


export const GET_CLUB_ADMINS = gql`
    ${COACH_FIELDS}
    query ClubAdmins($clubId: String!, $search: String, $skip: Int!, $take: Int!) {
        admins (
            clubId: $clubId
            where: {
                fullName: { contains: $search }
            }
            take: $take
            skip: $skip
        ) {
            totalCount
            items {
                ...CoachFields
            }
        }
    }`;
export const useClubAdmins = (variables) => useQuery(GET_CLUB_ADMINS, {variables});

export const GET_TEAM_COACHES = gql`
    ${COACH_FIELDS}
    query TeamCouches($clubId: String!, $teamId: Uuid!, $search: String, $skip: Int!, $take: Int!) {
        trainers(
            clubId: $clubId
            where: {
                teams: { some: { team: { teamId: { eq: $teamId } } } }
                fullName: { contains: $search }
            }
            take: $take
            skip: $skip
        ) {
            totalCount
            items {
                ...CoachFields
                teams (where: { team: {teamId: { eq: $teamId } }}) {
                    teamTrainerId
                    type
                    startDate
                    endDate
                    team {
                        teamId
                    }
                    canDelete
                }
            }
        }
    }`;
export const useTeamCoaches = (variables) => useQuery(GET_TEAM_COACHES, {variables});

export const SAVE_TRAINER = gql`
    mutation SaveTrainer($input: TrainerInput!) {
        saveTrainer(input: $input) {
            trainerId
        }
    }`;
export const useSaveTrainer = () => useMutation(SAVE_TRAINER);


const ARCHIVE_TRAINER = gql`
    mutation ArchiveTrainer($input: ArchiveInput!) {
        archiveTrainer(input:$input)
    }`;
export const useArchiveTrainer = () => useMutation(ARCHIVE_TRAINER);


const DELETE_TRAINER = gql`
    mutation DeleteTrainer($input: DeleteInput!) {
        deleteTrainer(input:$input)
    }`;
export const useDeleteTrainer = () => useMutation(DELETE_TRAINER);


export const GET_ARCHIVED_TRAINERS = gql`
    ${COACH_FIELDS}
    query GetArchivedTrainers($clubId: String!, $skip: Int) {
        archivedTrainers(clubId: $clubId, order: { archive: DESC }, take: 100, skip: $skip) {
            totalCount
            items {
                ...CoachFields
                archive
            }
        }
    }`;
export const useArchivedTrainers = (variables) => useQuery(GET_ARCHIVED_TRAINERS, {variables});


export const RESTORE_TRAINER = gql`
    mutation RestoreTrainer($input: RestoreTrainerInput!) {
        restoreTrainer(input: $input)
    }`;
export const useRestoreTrainer = () => useMutation(RESTORE_TRAINER);


const ASSIGN_TRAINER_TO_TEAMS = gql`
    mutation AssignTrainerToTeams($input: AssignTrainerInput!) {
        assignTrainerToTeams(input:$input)
    }`;
export const useAssignTrainerToTeams = () => useMutation(ASSIGN_TRAINER_TO_TEAMS);


const ASSIGN_TRAINERS_TO_TEAM = gql`
    mutation AssignTrainersToTeam($input: AssignTrainersInput!) {
        assignTrainersToTeam(input:$input)
    }`;
export const useAssignTrainersToTeam = () => useMutation(ASSIGN_TRAINERS_TO_TEAM);


const EDIT_TRAINER_TEAM_LINK = gql`
    mutation EditTrainerTeamLink($input: TrainerTeamLinkInput!) {
        editTrainerTeamLink(input:$input)
    }`;
export const useEditTrainerTeamLink = () => useMutation(EDIT_TRAINER_TEAM_LINK);


const DELETE_TRAINER_TEAM_LINK = gql`
    mutation DeleteTrainerTeamLink($input: DeleteInput!) {
        deleteTrainerTeamLink(input:$input)
    }`;
export const useDeleteTrainerTeamLink = () => useMutation(DELETE_TRAINER_TEAM_LINK);
