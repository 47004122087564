import {gql, useMutation} from '@apollo/client';

const UPLOAD_LANGUAGE_FILE = gql`
    mutation UploadLanguage ($file: Upload!, $meta: FileMetaInput!) {
        uploadLanguage(file: $file, meta: $meta)
    }`;

export const useUploadLanguage = () => useMutation(UPLOAD_LANGUAGE_FILE);


const VALIDATE_UPLOAD_TEAM_FILE = gql`
    mutation ValidateUploadTeam ($file: Upload!, $clubId: String!, $teamId: Uuid!) {
        validateUploadTeam(file: $file, clubId: $clubId, teamId: $teamId) {
            firstName
            middleName
            lastName
            gender
            personalCode
            dateOfBirth
            phoneNumber
            email
            addressLine
            countryOfBirth
            cityOfBirth
            citizenship
            licenceNr
            group
            registrationDate
            teamNumber
            position
            footed
            status
        }
    }`;

export const useValidateUploadTeam = () => useMutation(VALIDATE_UPLOAD_TEAM_FILE);
