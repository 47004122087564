import React, {useEffect, useRef, useState} from 'react';
import Panel from 'components/UI/Panel/Panel';
import Table from 'components/UI/Table/Table';
import DateTime from 'components/UI/Table/DateTime/DateTime';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import Button from 'components/UI/Button/Button';
import classes from './MatchesDetails.module.scss';
import VideoEditor from './editor/VideoEditor';
import Tabs from 'components/UI/Tabs/Tabs';
import VideoMatrix from './VideoMatrix';
import {useAddGameVideo, useDeleteGameVideo} from 'apollo/queries/GameQueries';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import DropdownButton from 'components/UI/DropdownButton/DropdownButton';
import YouTubeForm from './forms/YouTubeForm';
import Subtext from 'components/UI/Table/Subtext/Subtext';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import SwitchComponents from 'components/System/SwitchComponents';
import VideoList from './VideoList';
import HeaderWithSettings from 'components/UI/HeaderWithSettings/HederWithSettings';
import FileUpload2 from 'components/UI/FileUpload/FileUpload2';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';
import CanAccess from 'components/Navigation/CanAccess/CanAccess';

const MatchesDetailsVideo = (props) => {
    const {t} = useTranslation();
    const {gameId} = useParams();
    const fileUploadRef = useRef();
    const navState = useReactiveVar(naviDataVar);

    const [selectedRows, setSelectedRows] = useState([]);
    const [videos, setVideos] = useState([]);

    const [deleteGameVideo] = useDeleteGameVideo();
    const [addGameVideo] = useAddGameVideo();

    const meta = {
        type: 'MATCH_VIDEO',
    }

    const [modalData, setModalData, initState] = ModalState();

    const [matrixTab, setMatrixTab] = useState("list");

    const onAddFile = (store) => {
        if (store.value === "yoplaydo" && fileUploadRef.current) {
            fileUploadRef.current.onOpen();
        } else {
            const data = {
                ...initState,
                form: "youtube",
                title: t('Add video from streaming service'),
                store: store.value,
                onComplete: onComplete,
                open: true
            };

            setModalData(data);
        }
    };

    const filesActions = () => (
      <CanAccess users={[SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin]}>
        <DropdownButton type="primary" options={props.options} onClick={onAddFile}>
            <span><i className="icon-Plus-Square"></i> {t('Add video')}</span>
        </DropdownButton>

        {selectedRows.length > 0 && <Button type="secondary" style={{margin: 0}} onClick={onDeleteClick}>
            <span><i className="icon-Plus-Square"></i> {t('Delete')}</span>
        </Button>}
      </CanAccess>
    );

    const onUpload = async (result, meta) => {
        const variables = {
            input : {
                gameVideoId: result.fileId,
                gameId: gameId,
                name: meta.name,
                contentType: meta.type,
                size: meta.size,
                type: 0,
            }
        };

        await addGameVideo({variables});
        props.refetch();
    };

    useEffect(() => {
        setVideos(props.data.videos);
    }, [props.data.videos]);

    const onVideoClick = (data) => {
        const _data = {
            ...initState,
            title: t("Analise video"),
            form: "video",
            styles: {
                theme: "dark",
                maxWidth: "95%",
                minHeight: "calc(100vh - 200px)",
            },
            buttons: {
                hideSaveButton: true
            },
            video: data,
            onComplete: onComplete,
            open: true
        }

        setModalData(_data);
    }


    const onComplete = () => {
        props.refetch();
    }

    const onDeleteClick = () => {
        selectedRows.forEach(row => {
            const variables = {
                input: {
                    id: row.videoId,
                    clubId: navState.clubId,
                    teamId: navState.teamId,
                }
            };

            const list = videos.filter(video => video.videoId !== row.videoId);
            setVideos(list);

            deleteGameVideo({variables: variables}).then((result) => {
                if (result) props.refetch();
            });
        });
    }

    const onSettings = () => {
        naviDataVar({
            ...navState,
            settings: {
                open: true,
                chapter: 'video'
            }
        });
    }

    const getStore = (store) => {
        switch (store) {
            case "YOPLAYDO":
                return "YoPlayDo";
            case "YOUTUBE":
                return "YouTube";
            case "FACEBOOK":
                return "Facebook";
            case "VIMEO":
                return "Vimeo";
            case "TWITCH":
                return "Twitch";
            case "STREAMABLE":
                return "Streamable";
            case "WISTIA":
                return "Wistia";
            case "DAILYMOTION":
                return "DailyMotion";
            case "VIDYARD":
                return "Vidyard";
            default:
                return store;
        }
    }

    const highlightActions = () => (
        <Tabs items={props.matrixTabs} selected={matrixTab} onClick={(x) => setMatrixTab(x)} />
    );

    return (
        <div>
            <Panel border={false} action={filesActions}>
                <br/>
            </Panel>

            <CanAccess users={[SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin]}>
                <FileUpload2 ref={fileUploadRef} meta={meta} onUpload={onUpload} mode="video" />
            </CanAccess>

            <br/>
            <Table
                data={videos}
                center="true"
                access={[SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin]}
                columns={[
                    {
                        Header: t('Uploaded'),
                        accessor: 'createdTime',
                        Cell: cellData => <DateTime data={cellData.value}/>
                    },
                    {
                        Header: t('File'),
                        accessor: 'name',
                        Cell: cellData => <div className={classes.fileName} onClick={() => onVideoClick(cellData.row.original)}><span className="icon-videoPlay"></span><span>{cellData.value}</span></div>
                    },
                    {
                        Header: t('Store'),
                        accessor: 'store',
                        Cell: cellData => <div>
                            {getStore(cellData.value)}
                            {cellData.value === "YOPLAYDO" && <Subtext>{cellData.row.original.size}</Subtext>}
                        </div>
                    },
                    {
                        Header: t('User'),
                        accessor: 'userName',
                    }
                ]}
                onSelectClick={(x) => setSelectedRows(x)}
            />

            <br />
            <HeaderWithSettings title={t("Highlights")} onSettings={onSettings} />

            <Panel border={false} action={highlightActions}>
                <br/>
            </Panel>

            {matrixTab === "list" && <VideoList data={{items: props.data.eventRecords.filter(x => x.eventType !== "NOTE")}} />}
            {matrixTab === "matrix" && <VideoMatrix data={props.data} />}

            <ModalWindow2 {...modalData}>
                <SwitchComponents active={modalData.form}>
                    <VideoEditor {...modalData}  data={props.data} name="video" />
                    <YouTubeForm {...modalData} name="youtube" />
                </SwitchComponents>
            </ModalWindow2>
        </div>
    );
};

MatchesDetailsVideo.propTypes = {};
MatchesDetailsVideo.defaultProps = {
    data: {},
    refetch: () => {},
    matrixTabs: [
        {id: "list", name: "List"},
        {id: "matrix", name: "Matrix"},
    ],
    options: [
        {label: "Upload video", value: "yoplaydo"},
        {label: "From YouTube", value: "youtube"},
        {label: "From Facebook", value: "facebook"},
        {label: "From Vimeo", value: "vimeo"},
        {label: "From Twitch", value: "twitch"},
        {label: "From Streamable", value: "streamable"},
        {label: "From Wistia", value: "wistia"},
        {label: "From DailyMotion", value: "dailymotion"},
        {label: "From Vidyard", value: "vidyard"},
    ]
};

export default MatchesDetailsVideo;
