import React, {useState} from 'react';
import Input from '../../../../components/UI/Input/Input';
import {useTranslation} from 'react-i18next';
import {useRemoveUserAccess} from '../../../../apollo/queries/PermissionsQueries';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from '../../../../apollo/cashe';

const RemoveAccessForm = (props) => {
    const {id, defaultValues, onComplete} = props;

    console.log(props);

    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const [values] = useState(defaultValues);

    const [removeUserAccess] = useRemoveUserAccess();

    const formSubmitted = (e) => {
        e.preventDefault();
        saveData();
    }

    const saveData = () => {
        const variables = {
            clubId: navState.clubId,
            users: [{
                id: defaultValues.id,
                email: values.email,
                roles: values.roles
            }]
        };

        if (values.groupId !== undefined) {
            variables.clubId = values.groupId;
        }

        removeUserAccess({variables}).then((result) => {
            onComplete(result.data.removeUserAccess);
        });
    }

    return (
        <>
            <div className="message">{t("You remove this user access.")}</div>

            <form id={id} onSubmit={formSubmitted}>
                <Input
                    name="name"
                    placeholder={t('Name')}
                    value={defaultValues}
                    disabled={true}
                />

                <Input
                    name="email"
                    placeholder={t('E-mail')}
                    value={defaultValues}
                    disabled={true}
                    />
            </form>
        </>
    );
};

RemoveAccessForm.defaultProps = {};

export default RemoveAccessForm;
