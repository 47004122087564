import React from 'react';
import cn from 'classnames';
import classes from 'containers/Club/Archive/TeamArchive/TeamArchive.module.scss';
import Submenu from 'components/Navigation/Submenu/Submenu';
import Panel from 'components/UI/Panel/Panel';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import Table from 'components/UI/Table/Table';
import {useArchivedTeams, useRestoreTeam} from 'apollo/queries/TeamQueries';
import DateTime from 'components/UI/Table/DateTime/DateTime';
import Button from 'components/UI/Button/Button';


const TeamArchive = (props) => {
    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const {loading, data, error, refetch} = useArchivedTeams({clubId: navState.clubId});
    const [restoreTeam] = useRestoreTeam({clubId: navState.clubId, search: "", take: 50, skip: 0});

    const onRowClick = (data) => {
        // const teamId = UuidTOGuid(data.teamId);
        // history.push(`/Team/TeamDetails/${teamId}#team-player-list-panel`);
    }

    const onRestore = (data) => {
        const variables = {
            input: {
                clubId: navState.clubId,
                teamId: data.teamId
            }
        }

        restoreTeam({variables: variables}).then(() => {
            refetch();
        });
    }

    return (
        <>
            <div className={cn('page-header', classes.teamArchive)}>
                <div className={classes.name}>{navState.clubName}</div>
                <h2>{t('Team archive')}</h2>
                <Submenu/>
            </div>

            <div className="page-content">
                <Panel border={false} />

                {loading && <>Loading...</>}
                {error && <>Error...</>}
                {data && <Table
                    data={data.archivedTeams.items}
                    center="true"
                    columns={[
                        {
                            Header: t('Team'),
                            accessor: 'name',
                        },
                        {
                            Header: t('Gender'),
                            accessor: 'gender',
                            Cell: cellData => <div>{props.gender[cellData.row.original.gender]}</div>
                        },
                        {
                            Header: t('Archived'),
                            accessor: 'archive',
                            Cell: cellData => <DateTime data={cellData} showTime={false} />
                        },
                        {
                            Header: t('Action'),
                            accessor: 'restore',
                            Cell: cellData => <div><Button onClick={() => onRestore(cellData.row.original)} type="primary" style={{margin: "0"}}>Restore</Button></div>
                        },
                    ]}
                    onRowClick={onRowClick}/>
                }
            </div>
        </>
    );
};

TeamArchive.defaultProps = {
    gender: {
        NONE: "None",
        MALE: "Men",
        FEMALE: "Women",
        MIXED: "Mixed",
    }
};

export default TeamArchive;
