import React, {useState} from 'react';
import classes from './ExerciseForm.module.scss';
import ImageUpload from '../../../../../components/UI/ImageUpload/ImageUpload';
import {useTranslation} from 'react-i18next';
import ModalWindow2, {ModalState} from '../../../../../components/UI/ModalWindow/ModalWindow2';
import AddVideoLink from './AddVideoLink';
import SwitchComponents from '../../../../../components/System/SwitchComponents';
import ExerciseEditor from '../../editor/ExerciseEditor';
import cn from 'classnames';

const PageThree = (props) => {
    const {values, setValues, errors, onImageChange} = props;

    const {t} = useTranslation();

    const [modalData, setModalData, resetModal] = ModalState();

    const onCreateSchemeComplete = (data) => {
        const newValues = {...values, ...data};
        setValues(newValues);
        setModalData(resetModal);
    }

    const CreateScheme = () => {
        const data = {
            ...resetModal,
            form: "editor",
            title: t("Edit scheme"),
            styles: {
                maxWidth: "95%",
                noPadding: true
            },
            defaultValues: {
                ...values.scheme
            },
            onComplete: onCreateSchemeComplete,
            open: true
        }

        setModalData(data);
    }

    const onAddVideoComplete = (data) => {
        const newValues = {...values, ...data};
        setValues(newValues);
        setModalData(resetModal);
    }

    const onVideoDelete = () => {
        const newValues = {...values, video: undefined};
        setValues(newValues);
        setModalData(resetModal);
    }

    const onDelete = values.video ? onVideoDelete : undefined;

    const AddVideo = () => {
        const data = {
            ...resetModal,
            form: "link",
            title: t("Add video from streaming service"),
            defaultValues: {
                video: values.video
            },
            onComplete: onAddVideoComplete,
            onDelete: onDelete,
            open: true
        }

        setModalData(data);
    }

    return (
        <>
            <div className="message">{t("Add exercise visual scheme or video.")}</div>
            <ul className={classes.bigList}>
                <li className={classes.bigItem}>
                    <ImageUpload
                        name="image"
                        text="Upload scheme"
                        value={values}
                        onChange={onImageChange}
                        error={errors}
                    />
                </li>
                <li className={cn(classes.bigItem, {[classes.selectedScheme]: values.scheme?.image || values.scheme?.schemeData})} onClick={CreateScheme}>
                    <div>
                        <span className="icon-edit"></span>
                    </div>
                    {t("Create scheme")}
                </li>
                <li className={cn(classes.bigItem, {[classes.selectedScheme]: values.video})} onClick={AddVideo}>
                    <div>
                        <span className="icon-Camera"></span>
                    </div>
                    {t("YouTube video")}
                </li>
            </ul>

            <ModalWindow2 {...modalData}>
                <SwitchComponents active={modalData.form}>
                    <AddVideoLink {...modalData} name="link" />
                    <ExerciseEditor {...modalData} name="editor" />
                </SwitchComponents>
            </ModalWindow2>
        </>
    );
};

PageThree.defaultProps = {};

export default PageThree;
