import React from 'react';
import {useReactiveVar} from '@apollo/client';
import {systemDataVar} from 'apollo/cashe';
import {showDate, showDateHours} from 'utils/date';

const ShowDateTime = (props) => {
	const {date} = props;
	const systemData = useReactiveVar(systemDataVar);

  return (
      <>{showDateHours(date, systemData.settings.general)}</>
  );
};

ShowDateTime.defaultProps = {
	date: new Date()
};

export default ShowDateTime;
