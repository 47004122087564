import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {notHaveErrors, validateFields, validateForm} from 'utils/form';
import * as yup from 'yup';
import Input from 'components/UI/Input/Input';
import DatePicker from 'components/UI/DatePicker/DatePicker';
import classes from './AnthropometricForm.module.scss';
import {useSavePlayerAnthropometry} from 'apollo/queries/PlayerQueries';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import {dateAsTimestamp} from 'utils/date';

const AnthropometricForm = (props) => {
    const {defaultValues, onComplete} = props;

    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const [values, setValues] = useState({});
    const [errors, setErrors] = useState({});

    const [savePlayerAnthropometry] = useSavePlayerAnthropometry()

    const fieldChanged = (event) => {
        const newValues = { ...values };
        newValues[event.target.name] = event.target.value

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });

        setValues(newValues);
    }

    const formSubmitted = (e) => {
        e.preventDefault();
        validateForm(values, validationRules, (errors) => {
            if (notHaveErrors(errors))
                saveData();
            else
                setErrors(errors);
        });
    }

    const validationRules = {
        date: yup.string().required(t("required")),
    }

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors
    };

    const saveData = () => {
        const variables = {
            input: {
                clubId: navState.clubId,
                playerId: defaultValues.playerId,
                dateVal: dateAsTimestamp(values.date),
                metrics: []
            }
        }

        if (values.height) {
            variables.input.metrics.push({ metricId: 1, value: +values.height })
        }

        if (values.weight) {
            variables.input.metrics.push({ metricId: 2, value: +values.weight })
        }

        if (values.legLength) {
            variables.input.metrics.push({ metricId: 4, value: +values.legLength })
        }

        if (values.footSize) {
            variables.input.metrics.push({ metricId: 3, value: +values.footSize })
        }

        if (values.bodyLength) {
            variables.input.metrics.push({ metricId: 5, value: +values.bodyLength })
        }

        if (values.bodyFat) {
            variables.input.metrics.push({ metricId: 6, value: +values.bodyFat })
        }

        savePlayerAnthropometry({variables: variables}).then(result => {
            onComplete();
        });
    }

    return (
        <form id={props.id} onSubmit={formSubmitted}>
            <DatePicker
                name="date"
                label={t('Date')}
                {...regField}
            />

            <div className={classes.row11}>
                <Input
                    name="height"
                    type="number"
                    label={t('Height')}
                    {...regField}
                />

                <Input
                    name="weight"
                    type="number"
                    label={t('Weight')}
                    {...regField}
                />
            </div>

            <div className={classes.row11}>
                <Input
                    name="legLength"
                    type="number"
                    label={t('Leg length')}
                    {...regField}
                />

                <Input
                    name="footSize"
                    type="number"
                    label={t('Foot size')}
                    {...regField}
                />
            </div>

            <div className={classes.row11}>
                <Input
                    name="bodyLength"
                    ype="number"
                    label={t('Body length')}
                    {...regField}
                />

                <Input
                    name="bodyFat"
                    type="number"
                    label={t('Body fat percent')}
                    {...regField}
                />
            </div>
        </form>
    );
};

AnthropometricForm.defaultProps = {};

export default AnthropometricForm;
