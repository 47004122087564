import React from 'react';
import classes from './Tabs.module.scss';
import {useTranslation} from 'react-i18next';

const Tabs = (props) => {
    const {t} = useTranslation();

    return <ul className={classes.tabs}>
        {props.items.map(i => {
          const selected = props.selected === i.id ? classes.active : null;
          return <li key={i.id} className={selected} onClick={() => props.onClick(i.id)}>{t(i.name)}</li>
        })}
      </ul>
}

Tabs.defaultProps = {
    onClick: () => {}
}

export default Tabs;
