import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import {notHaveErrors, validateForm} from 'utils/form';
import * as yup from 'yup';
import classes from '../Matches.module.scss';
import Submenu from 'components/Navigation/Submenu/Submenu';
import MatchFormOne from './MatchFormOne';
import MatchFormTwo from './MatchFormTwo';
import {useCopyGame, useGameOptions, useSaveGame} from 'apollo/queries/GameQueries';
import {createGuid} from 'utils/utils';


const MatchForm = (props) => {
    const {defaultValues, setModal, pages, setLoading, onComplete, copy} = props;

    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const [values, setValues] = useState(defaultValues);
    const [errors, setErrors] = useState({});
    const [mainTeam, setMainTeam] = useState([]);
    const [reserveTeam, setReserveTeam] = useState([]);

    const [saveGame] = useSaveGame();
    const [copyGame] = useCopyGame();
    const [getGameOptions, gameOptionsData] = useGameOptions();

    useEffect(() => {
        getGameOptions({variables: {
                clubId: navState.clubId,
                teamId: navState.teamId,
                take: 200,
            }});
    }, []);

    const fieldChanged = (event) => {
        const newValues = { ...values };
        newValues[event.target.name] = event.target.value

        setValues(newValues);
    }

    const pageOneValidationRules = {
        scope: yup.string().required(t("Require")),
        date: yup.string().required(t("Require")),
        opponent: yup.string().required(t("Require")),
        venue: yup.string().required(t("Require")),
        duration: yup.string().required(t("Require")),
        periodCount: yup.string().required(t("Require")),
    }

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors
    };

    const formSubmitted = async (e) => {
        e.preventDefault();
        setLoading(true);

        new Promise(function(resolve, reject) {
            //Page 1
            validateForm(values, pageOneValidationRules, (errors) => {
                notHaveErrors(errors) ? resolve(1) : reject({page: 1, errors});
            });
        }).then(function(result) {
            //Page 2
            return new Promise(function(resolve, reject) {
                resolve(1);
            });
        }).then(function(result) {
            saveData();
        }).catch(e => {
            setLoading(false);
            setErrors(e.errors);
            onPageChanged(e.page);
            console.log(e);
        });
    };

    const saveData = async () => {
        const competition  = gameOptionsData.data.competitions.items.find(x => x.championshipId === values.competition);
        const opponent  = gameOptionsData.data.opponents.items.find(x => x.id === values.opponent);

        const gameId = values?.gameId ?? createGuid();

        const main = preparePlayers(mainTeam, gameId, "main");
        const reserve = preparePlayers(reserveTeam, gameId, "reserve");
        const playerList = [...main, ...reserve];

        const variables = {
            input: {
                gameId: gameId,
                clubId: navState.clubId,
                teamId: navState.teamId,
                scope: values.scope,
                date: values.date,
                awayMatch: values.awayMatch === true,
                ownerTeamId: navState.teamId,
                ownerTeamName: values.name,
                otherTeamId: opponent.id,
                otherTeamName: opponent.name,
                location: values.venue,
                duration: +values.duration,
                periodCount: +values.periodCount,
                matchNotes: values.matchNotes,
                tacticalScheme: values.tacticalScheme,
                players: playerList
            }
        };

        if (values.scope === "COMPETITION") {
            variables.input.championshipId = competition.championshipId;
            variables.input.championshipName = competition.name;
        }

        copy
          ? await copyGame({variables})
          : await saveGame({variables});

        onComplete();
    }

    const preparePlayers = (team, gameId, type) => {
        return team.map(p => {
            delete p.playerName;
            return {
                ...p,
                playerGameStatId: p.PlayerGameStatId ?? createGuid(),
                teamNumber: +p.teamNumber,
                gameId: gameId,
                clubId: navState.clubId,
                teamId: navState.teamId,
                mainTeam: type === "main"
            };
        });
    }

    const onPageChanged = (newPage) => {
        const page = newPage - 1;
        setModal({...props, pages: { ...props.pages, page: page}});
    }

    const pageProps = {values, setValues, errors, setErrors,
        mainTeam, setMainTeam, reserveTeam, setReserveTeam,
        regField, data: gameOptionsData.data, refetch: gameOptionsData.refetch };

    return (
        <>
            {pages.maxPages > 1 ?  <Submenu default={pages.page + 1} data={props.pageNames} onFilter={onPageChanged} className={classes.submenu} /> : undefined}

            {pages.page === 0 ? <MatchFormOne {...pageProps} /> : undefined }
            {pages.page === 1 ? <MatchFormTwo {...pageProps} /> : undefined }

            <form id={props.id} onSubmit={formSubmitted}></form>
        </>
    );
};

MatchForm.defaultProps = {
    page: 0,
    pageNames: {
        1: "Match",
        2: "Team",
    },
};

export default MatchForm;
