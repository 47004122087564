import React from 'react';
import classes from './Birthday.module.scss';
import {useTranslation} from 'react-i18next';
import {difDatesYears, isValidDate} from 'utils/date';
import cn from 'classnames';
import ShowDate from 'components/UI/DatePicker/ShowDate';

const Birthday = (props) => {
  const {t} = useTranslation();

  if (!isValidDate(props.data)) return (
      <div className={cn(classes.date, props.className)}>
        <span></span>
        <span className={classes.time}></span>}
      </div>
  );

  const years = difDatesYears(props.data, new Date());

  return (
      <div className={cn(classes.date, props.className)}>
        <span><ShowDate date={props.data} />{props.coma ? ", " : ""}</span>
        <span className={classes.time}>{years} {t("years")}</span>
      </div>
  );
};

Birthday.defaultProps = {
    coma: false,
};

export default Birthday;
