import React, {useState} from 'react';
import Panel from 'components/UI/Panel/Panel';
import Button from 'components/UI/Button/Button';
import CoachEvaluation from 'containers/Club/SportDirector/CoachEvaluationList/CoachEvaluation/CoachEvaluation';
import CoachEvaluationTable from 'containers/Club/SportDirector/CoachEvaluationList/CoachEvaluationTable/CoachEvaluationTable';
import CoachTable from 'containers/Club/SportDirector/CoachEvaluationList/CoachTable/CoachTable';
import {useTranslation} from "react-i18next";
import PanelItem from 'components/UI/PanelItem/PanelItem';
import Submenu from 'components/Navigation/Submenu/Submenu';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';
import CanAccess from 'components/Navigation/CanAccess/CanAccess';
import {useNavigate} from 'react-router-dom';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import ExerciseLibrary from 'containers/Club/ExerciseLibrary/ExerciseLibrary';
import Tabs from 'components/UI/Tabs/Tabs';

const CoachEvaluationList = (props) => {
    const { t } = useTranslation();
    const history = useNavigate();
    const navState = useReactiveVar(naviDataVar);

    const [selectedTrainerName, setSelectedTrainerName] = useState("");
    const [selectedTrainerId, setSelectedTrainerId] = useState(null);
    const [showEvaluation, setShowEvaluation] = useState(false);
    const [evaluationData, setEvaluationData] = useState(null);

    const [mode, setMode] = useState("coaches");

    const [evaluationCount, setEvaluationCount] = useState('-');
    const [clubEvaluation, setClubEvaluation] = useState('-');
    const [couchesEvaluation, setCouchesEvaluation] = useState('-');

    const onDataLoaded = (data) => {
        setEvaluationCount(data.count);
        setClubEvaluation(data.club);
        setCouchesEvaluation(data.couches);
    };

    const onSelectCoach = (data) => {
        // setSelectedTrainerId(data.trainerId);
        // setSelectedTrainerName(data.firstName + " " + data.lastName);
        history(`/club-${navState.clubId}/evaluation/coach-${data.trainerId}`);
    }

    const onAddEvaluation = (data) => {
        setEvaluationData();
        setShowEvaluation(true);
    }

    const onEditEvaluation = (data) => {
        const json = { id: data.id, ...JSON.parse(data.document)};
        setEvaluationData(json);
        setShowEvaluation(true);
    }

    const onSaveEvaluation = () => {
        setShowEvaluation(false);
    }

    const onCloseEvaluation = () => {
        setShowEvaluation(false);
    }


    // if (selectedTrainerId !== null && showEvaluation === false)
    //     return (
    //         <>
    //             <div className="page-header">
    //                 <h2>{selectedTrainerName}</h2>
    //                 <Submenu />
    //             </div>
    //             <div className="page-content">
    //                 <CanAccess users={[SystemRoles.GroupMember, SystemRoles.GroupManger, SystemRoles.SysAdmin]}>
    //                     <React.Fragment>
    //                         <Panel border="false">
    //                             <Button onClick={onAddEvaluation}>{t('Add evaluation')}</Button>
    //                         </Panel>
    //                         <CoachEvaluationTable
    //                             selectedTrainerId={selectedTrainerId}
    //                             showEvaluation={showEvaluation}
    //                             onAddEvaluation={onAddEvaluation}
    //                             onEditEvaluation={onEditEvaluation}/>
    //                     </React.Fragment>
    //                 </CanAccess>
    //
    //                 <CanAccess users={[SystemRoles.ClubAdmin]}>
    //                     <React.Fragment>
    //                         <Panel border="false" />
    //                         <CoachEvaluationTable
    //                             selectedTrainerId={selectedTrainerId}
    //                             showEvaluation={showEvaluation}
    //                             onEditEvaluation={onEditEvaluation}/>
    //                     </React.Fragment>
    //                 </CanAccess>
    //             }
    //             </div>
    //         </>
    //     );
    //
    // if (showEvaluation === true)
    //     return (
    //         <CoachEvaluation selectedCouch={selectedTrainerId} couchName={selectedTrainerName} defaultValues={evaluationData} onSaveEvaluation={onSaveEvaluation} onCloseEvaluation={onCloseEvaluation}/>
    //     );

    console.log(props.tabs);

    return (
        <>
            <div className="page-header">
                <h2>{t('Club evaluation')}</h2>
                <Submenu />
            </div>
            <div className="page-content">
                <Panel>
                    <PanelItem name={t('Evaluates')} value={evaluationCount} />
                    <PanelItem name={t('Club')} value={clubEvaluation} />
                    <PanelItem name={t('Caches')} value={couchesEvaluation} />
                </Panel>

                <br/>

                <Tabs onClick={(val) => setMode(val)} selected={mode} items={props.tabs} />

                <br/><br/>

                <CoachTable onDataLoaded={onDataLoaded} onSelectCoach={onSelectCoach} />
            </div>
        </>
    );
}

CoachEvaluationList.defaultProps = {
    tabs: [
        { id: 'coaches', name: 'Coaches'},
        { id: 'games', name: 'Games'},
    ],
};

export default CoachEvaluationList
