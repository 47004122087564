import React, {useEffect, useRef, useState} from 'react';
import {Tree, TreeNode} from 'react-organizational-chart';
import {PanZoom} from 'react-easy-panzoom';
import classes from './OrgChart.module.scss';

const OrgChart = ({root, onAddClick, onEditClick}) => {
  const hasChildren = root && root['children'] !== undefined && root.children.length > 0;
  const [move, setMove] = useState(false);
  const panZoom = useRef(null);

  useEffect(() => {
    panZoom.current.autoCenter(0.8, true);
  }, [root]);

  const onPanStart = () => { setMove(true); }
  const onPanEnd = () => {setMove(false); }

  const Card = ({id, name, position, description}) => {
    return (
        <div onClick={(e) => onEditClick(id, e)} style={{textAlign: 'center'}}>
          <div className={classes.card}>
            <b>{name}</b>
            <p>{position}</p>
            {description && <p className={classes.description}>{description}</p>}
            <button  className={classes.button} onClick={(e) => onAddClick(id, e)}>+</button>
          </div>
        </div>
    );
  };

  const TreeCard = (data) => {
    const hasChildren = data['children'] !== undefined && data.children.length > 0;

    return (
        <>
          <TreeNode label={<Card {...data} />}>
            {
              hasChildren
                  ? data.children.map((item) => (
                      <TreeCard key={item.id} {...item} />
                  ))
                  : null
            }
          </TreeNode>
        </>
    );
  };

  return (
      <div className={classes.OrgChart}>
        <PanZoom ref={panZoom}
                 style={{overflow: 'hidden', minHeight: '600px'}}
                 onPanStart={onPanStart}
                 onPanEnd={onPanEnd}
                 disableDoubleClickZoom="true">
          <Tree label={<Card {...root} />} style={{width: 'calc(100vh - 32px)'}} lineColor={classes.lineColor} disabled={move}>
            {
              hasChildren
                  ? root.children.map((item) => (
                      <TreeCard key={item.id} {...item} />
                  ))
                  : null
            }
          </Tree>
        </PanZoom>
      </div>
  );
};

export default OrgChart;
