import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import {notHaveErrors, validateFields, validateForm} from 'utils/form';
import * as yup from 'yup';
import Input from 'components/UI/Input/Input';
import Select from 'components/UI/Select/Select';
import DatePicker from 'components/UI/DatePicker/DatePicker';
import classes from '../Competitions.module.scss';
import ImageUpload from 'components/UI/ImageUpload/ImageUpload';
import {competitionSettings} from '../Settings';
import {useSaveCompetition} from 'apollo/queries/GameQueries';
import {createGuid} from 'utils/utils';
import {toastMessage} from 'components/UI/Toast/Toast';
import {useAuth} from 'react-oidc-context';
import {fileUploader} from 'utils/upload';

const CompetitionForm = (props) => {
    const {t} = useTranslation();
    const auth = useAuth();
    const navState = useReactiveVar(naviDataVar);

    const [values, setValues] = useState(props.defaultValues);
    const [errors, setErrors] = useState({});
    const [season, setSeason] = useState([]);
    const [image, setImage] = useState(null);

    const [saveCompetition] = useSaveCompetition(props.queryVariables);

    useEffect(() => {
        const from = new Date().getFullYear() + 1;
        const till = 1970;
        const years = Array.from({length: (from - till)}, (v, k) => from - k);
        const list = [].concat(...years.map(x => ([{label: x + "/"+(x+1), value: x + "/"+(x+1)}, {label: x, value: x}])));
        setSeason(list);
    }, []);

    const fieldChanged = (event) => {
        const newValues = { ...values };
        newValues[event.target.name] = event.target.value

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });

        if (event.target.file !== undefined) {
            newValues[event.target.name+"File"] = event.target.file;
        }

        setValues(newValues);
    }

    const validationRules = {
        name: yup.string().required(t("Require")),
        shortName: yup.string().required(t("Require")),
        competitionType: yup.string().required(t("Require")),
        season: yup.string().required(t("Require")),
        country: yup.string().required(t("Require")),
    }

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors
    };

    const formSubmitted = async (e) => {
        e.preventDefault();
        validateForm(values, validationRules, (errors) => {
            if (notHaveErrors(errors))
                saveData();
            else
                setErrors(errors);
        });
    }

    const saveData = async () => {
        const variables = {
            input: {
                championshipId: values?.id ?? createGuid(),
                clubId: navState.clubId,
                teamId: navState.teamId,
                name: values.name,
                shortName: values.shortName,
                competitionType: values.competitionType,
                season: '' + values.season,
                country: values.country,
                startDate: values.startDate,
                endDate: values.endDate,
                achievement: values.achievement,
            }
        };

        if (values.logoFile !== undefined) {
            const fileData = {
                clubId: navState.clubId,
                fileId: variables.input.championshipId
            }

            if (await fileUploader(values.logoFile, fileData, auth)) {
                variables.input.logoUploadDate = new Date().toISOString();
            }
        }

        await saveCompetition({variables: variables});
        props.onComplete();
    }

    const onImageChanged = (e) => {
        setImage(e);
    }

    useEffect(() => {
        if (!image) return;
        fieldChanged(image);
    }, [image]);

    return (
        <>
            <form id={props.id} onSubmit={formSubmitted}>
                <div className={classes.columns21}>
                    <div>
                        <div className={classes.columns21}>
                            <Input
                                name="name"
                                label={t('Name')}
                                {...regField}
                            />

                            <Input
                                name="shortName"
                                label={t('Short name')}
                                {...regField}
                            />

                        </div>

                        <div className={classes.columns21}>
                            <Select
                                name="competitionType"
                                label={t('Competition type')}
                                options={competitionSettings.type}
                                {...regField} />

                            <Select
                                name="season"
                                label={t('Season')}
                                options={season}
                                {...regField} />
                        </div>


                        <Select
                            name="country"
                            label={t('Country')}
                            options={props.countries}
                            {...regField} />

                        <div className={classes.columns11}>
                            <DatePicker
                                name="startDate"
                                label={t('Start date')}
                                {...regField}
                            />

                            <DatePicker
                                name="endDate"
                                label={t('End date')}
                                {...regField}
                            />
                        </div>

                        <Select
                            name="achievement"
                            label={t('Achievement')}
                            options={competitionSettings.achievements}
                            {...regField} />
                    </div>

                    <div>
                        <ImageUpload
                            name="logo"
                            label={t('Competition logo')}
                            text="Add competition logo"
                            {...regField}
                            onChange={onImageChanged}
                        />
                    </div>

                </div>
            </form>
        </>
    );
};

CompetitionForm.defaultProps = {
    countries: [],
};

export default CompetitionForm;
