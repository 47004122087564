import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {notHaveErrors, validateFields, validateForm} from 'utils/form';
import * as yup from 'yup';
import Input from 'components/UI/Input/Input';
import DatePicker from 'components/UI/DatePicker/DatePicker';
import Select from 'components/UI/Select/Select';
import classes from './TrainingForm.module.scss';
import Switch from 'components/UI/Switch/Switch';
import WeakSelect from 'components/UI/WeakSelect/WeakSelect';
import SelectWithAdd from 'components/UI/Select/SelectWithAdd';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import {createGuid} from 'utils/utils';
import {useCopyTraining, useSaveTraining, useTrainingVenues} from 'apollo/queries/TrainingQueries';
import {validDate} from 'utils/date';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import VenueForm from 'containers/Settings/Venues/forms/VenueForm';

const TrainingForm = (props) => {
    const {defaultValues, groups, setLoading, onComplete, copy} = props;

    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const [values, setValues] = useState(defaultValues ?? {group: "ALL"});
    const [errors, setErrors] = useState({});
    const [venues, setVenues] = useState([]);

    const [modalData, setModalData, initState] = ModalState();

    const [saveTraining] = useSaveTraining();
    const [copyTraining] = useCopyTraining();
    const getVenues = useTrainingVenues({
        clubId: navState.clubId,
        take: 200
    });

    useEffect(() => {
        if (!getVenues.data) return;
        const items = getVenues.data.venues.items.map(x => ({label: x.name, value: x.venueId}));
        setVenues(items);
    }, [getVenues.data]);

    const fieldChanged = (event) => {
        const newValues = {...values};
        newValues[event.target.name] = event.target.value;

        validateFields(newValues, validationRules, (errors) => {
            setErrors(errors);
        });

        setValues(newValues);
    };

    const validationRules = {
        name: yup.string().required(t("Require")),
        start: yup.string().required(t("Require")),
        duration: yup.number().required(t("Require")),
        venueId: yup.string().required(t("Require")),
    };

    const regField = {
        value: values,
        onChange: fieldChanged,
        error: errors
    };

    const formSubmitted = (e) => {
        e.preventDefault();
        setLoading(true);
        validateForm(values, validationRules, (errors) => {
          if (notHaveErrors(errors)) {
              saveData();
          } else {
              setLoading(false);
              setErrors(errors);
          }
        });
    };

    const saveData = async () => {
        const variables = {
            input: {
                trainingId: values?.trainingId ?? createGuid(),
                teamId: navState.teamId,
                name: values.name,
                block: values.block,
                trainingNumber: values.trainingNumber,
                start: values.start,
                duration: +values.duration,
                venueId: values.venueId,
                group: values.group,

                repeat: values.repeat,
                weakDays: values.weakDays,
                end: validDate(values.end),

                isQuickEdited: values?.trainingId !== undefined,
            }
        };

        copy
          ? await copyTraining({variables})
          : await saveTraining({variables});

        onComplete();
    }

    const onAddVenue = () => {
        const _data = {
            ...initState,
            title: t("Add new venue"),
            form: "venue",
            onComplete: refetchVenue,
            open: true,
            queryVariables: {
                clubId: navState.clubId,
                take: 200,
                skip: 0
            }
        }

        setModalData(_data);
    }

    const refetchVenue = () => {
        setModalData(initState);
        getVenues.refetch();
    }

    return (
        <>
            <form id={props.id} onSubmit={formSubmitted}>
                <Input
                    name="name"
                    label={t('Name')}
                    {...regField}
                />

                <div className={classes.row21}>
                    <Input
                        name="block"
                        label={t('Block name')}
                        {...regField}
                    />

                    <Input
                        name="trainingNumber"
                        label={t('Training number')}
                        {...regField}
                    />
                </div>

                <div className={classes.row11}>
                    <DatePicker
                        name="start"
                        time={true}
                        label={t('Date')}
                        {...regField}
                    />

                    <Input
                        name="duration"
                        type="number"
                        label={t('Duration (min)')}
                        {...regField}
                    />

                    <SelectWithAdd
                        name="venueId"
                        label={t('Venue')}
                        options={venues}
                        onAddClick={onAddVenue}
                        {...regField} />

                    <Select
                        name="group"
                        label={t('Group')}
                        options={groups}
                        {...regField}
                    />
                </div>

                {values?.trainingId === undefined ? <>
                <br/>

                <Switch
                    name="repeat"
                    label={t('Repeat')}
                    {...regField}
                />

                <br/>

                {values.repeat && <div className={classes.row11}>
                    <WeakSelect
                        name="weakDays"
                        label={t('Repeat days')}
                        items={props.weakDays}
                        {...regField}
                        dissabled={values.repeat === undefined || values.repeat === false}
                    />

                    <DatePicker
                        name="end"
                        label={t('Repeat till date')}
                        {...regField}
                        dissabled={values.repeat === undefined || values.repeat === false}
                    />
                </div>}
                </> : undefined}


            </form>
            <ModalWindow2 {...modalData}>
                <VenueForm {...modalData} />
            </ModalWindow2>
        </>
    );
};

TrainingForm.propTypes = {};
TrainingForm.defaultProps = {
    weakDays: [false,false,false,false,false,false,false],
    groups: [
        {label: "All groups", value: "ALL"},
        {label: "1 group", value: "DEFAULT"},
        {label: "2 group", value: "SECOND"},
        {label: "3 group", value: "THIRD"},
        {label: "4 group", value: "FOURTH"},
        {label: "5 group", value: "FIFTH"},
        {label: "6 group", value: "SIXTH"},
        {label: "7 group", value: "SEVENTH"},
    ]
};

export default TrainingForm;
