import React from 'react';
import classes from './BasicExerciseView.module.scss';
import {useTranslation} from 'react-i18next';
import Slider from 'components/UI/Slider/Slider';


const BasicExerciseView = (props) => {
    const {defaultData} = props;
    const {image, scheme, exerciseId, name, duration, repeat, rest, rhythm, coefficient, level, description, tools} = defaultData;

    const {t} = useTranslation();

    const tinySettings = {
        lazyload: true,
        nav: false,
        mouseDrag: true,
        items: 1,
        controls: false,
        responsive: {
            420: {
                items: 1
            }
        }
    };

    const getSchemeImage = () => {
        if (scheme?.image) {
            return (<div key={`scheme-${exerciseId}`} style={{ position: "relative" }} dangerouslySetInnerHTML={{__html: `<img className="${classes.image}" src="${scheme?.image}" style="background-image: url(${scheme?.background}) !important; background-size: cover !important;" alt=""/>`}}></div>);
        }
    }

    const getImage = () => {
        if (image) {
            return (<div key={`image-${exerciseId}`} style={{ position: "relative" }}><img src={image} alt="" /></div>);
        }
    }

    const getSliderImages = () => {
        if (image && scheme?.image) {
            return (
                <>
                    {getSchemeImage()}
                    {getImage()}
                </>
            );
        }

        else if (scheme?.image) {
            return getSchemeImage();
        }

        else if (image) {
            return getImage();
        }

        return (<div key={`no-image-${exerciseId}`} style={{ position: "relative" }}><img src="/assets/images/exercises/no-image.jpg" alt="" /></div>);
    }

    return (
        <div>
            <div className={classes.basicView}>
                <div className={classes.left}>
                    <Slider>{getSliderImages()}</Slider>
                </div>
                <div className={classes.right}>
                    <h4>{name}</h4>
                    <div className={classes.info}>
                        <div>
                            <span>{t("Duration")}</span>: {duration ? duration : "-"}
                        </div>
                        <div>
                            <span>{t("Repeat")}</span>: {repeat ? repeat : "-"}
                        </div>
                        <div>
                            <span>{t("Rest")}</span>: {rest ? rest : "-"}
                        </div>
                        <div>
                            <span>{t("Rhythm")}</span>: {rhythm ? rhythm : "-"}
                        </div>
                        <div>
                            <span>{t("Coefficient")}</span>: {coefficient ? coefficient : "-"}
                        </div>
                        <div>
                            <span>{t("Level")}</span>: {level ? level : "-"}
                        </div>
                    </div>

                    <h5>{t('Description')}</h5>
                    {description ?? t("No description.")}

                     <h5>{t('Items and tools')}</h5>
                    {tools ?? t("No tools needed.")}

                </div>
            </div>
        </div>
    );
};

BasicExerciseView.defaultProps = {};

export default BasicExerciseView;
