import React from 'react';
import classes from './Variants.module.scss';
import Button from 'components/UI/Button/Button';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {regStateVar} from 'apollo/variables/registration';

const Variants = () => {
  const {t} = useTranslation();
  const regState = useReactiveVar(regStateVar);

  const onGoTo = (goto, e) => {
    e.preventDefault();

    regStateVar({
      ...regState,
      main: goto
    });
  };

  const onLoginClick = (e) => {
    e.preventDefault();
    window.location.href = process.env.REACT_APP_POST_LOGIN_URI;
  }

  const onPasswordRestoreClick = (e) => {
    e.preventDefault();
    window.location.href = process.env.REACT_APP_POST_PASSWORD_RESET_URI;
  }

  return (
      <div>

        {regState.invited
            ?
            <React.Fragment>
              <Button onClick={e => onGoTo('UserEmail', e)}
                      type="third">  &#8592; {t('goBack')}</Button>

              <h1>{t('alreadyRegistered')}</h1>

              <Button onClick={onLoginClick} style={{width: '100%'}}> {t('goToLogin')}</Button>
              <Button onClick={onPasswordRestoreClick} style={{width: '100%'}}>{t('goToPasswordRestore')}</Button>
            </React.Fragment>
            :
            <React.Fragment>
              <h1>{t('haveRegistred')}</h1>
              <div className={classes.description}>
                {t('haveRegistredMessage')}
              </div>

              {regState.userRole <= 2 && <div className={classes.other}><span>{t('or')}</span></div> }
            </React.Fragment>
        }

        {regState.role <= 2 &&
        <React.Fragment>
          <Button onClick={e => onGoTo('WriteToSupport', e)} style={{width: '100%'}}>
            {t('goToSupport')}
          </Button>

          <Button onClick={e => onGoTo('CreateNewClub', e)} style={{width: '100%'}}>
            {t('goToCreateNewClub')}
          </Button>
        </React.Fragment>
        }
      </div>
  );
};

export default Variants;
