import React, {useState} from 'react';
import Input from "components/UI/Input/Input";
import Textarea from "components/UI/Textarea/Textarea";
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import {notHaveErrors, validateFields, validateForm} from "utils/form";

const CardForm = ({ id, defaultValues, onComplete}) => {
  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState({});
  const {t} = useTranslation();

  const fieldChanged = (event) => {
    const newValues = { ...values };
    newValues[event.target.name] = event.target.value

    validateFields(newValues, validationRules, (errors) => {
      setErrors(errors);
    });

    setValues(newValues);
  }

  const formSubmitted = (e) => {
    e.preventDefault();
    validateForm(values, validationRules, (errors) => {
      if (notHaveErrors(errors))
        onComplete(values);
      else
        setErrors(errors);
    });
  }

  const validationRules = {
    name: yup.string().required(t("Require")),
    position: yup.string().required(t("Require"))
  }

  return (
      <form id={id} onSubmit={formSubmitted}>
        <Input
            name="name"
            placeholder={t('Name, Lastname')}
            value={defaultValues}
            onChange={(e) => fieldChanged(e)}
            error={errors}/>

        <Input
            name="position"
            placeholder={t('Position')}
            value={defaultValues}
            onChange={(e) => fieldChanged(e)}
            error={errors}/>

        <Textarea
            name="description"
            placeholder={t('Description')}
            value={defaultValues}
            onChange={(e) => fieldChanged(e)}
            error={errors}/>
      </form>
  )
}

export default CardForm

