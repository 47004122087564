import React, {useEffect, useState} from 'react';
import PlayerHeader from '../Player/PlayerHeader';
import Submenu from 'components/Navigation/Submenu/Submenu';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import {firstDayOfMonth, lastDayOfMonth} from 'utils/date';
import Button from 'components/UI/Button/Button';
import DatePicker from 'components/UI/DatePicker/DatePicker';
import Panel from 'components/UI/Panel/Panel';
import Table from 'components/UI/Table/Table';
import DateTime from 'components/UI/Table/DateTime/DateTime';
import Subtext from 'components/UI/Table/Subtext/Subtext';
import {GuidTOUuid} from 'utils/utils';
import Dropdown from 'components/UI/Table/DropDown/Dropdown';
import {useGamesAttendance} from 'apollo/queries/GameQueries';

const PlayerAttendanceGames = (props) => {
    const {attendance, health} = props;

    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);

    const [playerId, setPlayerId] = useState(null);
    const [values, setValues] = useState({
        startDate: firstDayOfMonth(),
        endDate: lastDayOfMonth()
    });

    const [getGamesAttendance, gamesAttendanceData] = useGamesAttendance();

    useEffect(() => {
        if (!navState.clubId) return;

        getGamesAttendance({ variables: {
                "clubId": navState.clubId,
                "teamId": navState.teamId,
                "where": {
                    "and": [
                        {"date": {"gte": values.startDate}},
                        {"date": {"lte": values.endDate}}
                    ]
                }
            }});

        setPlayerId(GuidTOUuid(navState.playerId));

    }, [navState, values]);

    const actions = () => {
        return (
            <Button type="primary" style={{margin: 0}}>
                <span><i className="icon-Send"></i> {t("Share")}</span>
            </Button>
        )
    }

    const onFilter = (e) => {
        const newFilters = {...values};
        newFilters[e.target.name] = e.target.value;
        setValues(newFilters);
    }

    const filters = () => {
        return (
            <>
                <DatePicker
                    name="startDate"
                    onChange={onFilter}
                    placeholder={t("Date from")}
                    value={values}
                />

                <DatePicker
                    name="endDate"
                    onChange={onFilter}
                    placeholder={t("Date till")}
                    value={values}
                />
            </>
        )
    }

    return (
        <>
            <div className="page-header">
                <PlayerHeader />
                <Submenu/>
            </div>
            <div className="page-content">
                <Panel border={false} action={actions} filters={filters} />

                {gamesAttendanceData.loading && <>Loading...</>}
                {gamesAttendanceData.error && <>Error...</>}
                {gamesAttendanceData.data && <Table
                    data={gamesAttendanceData.data.games.items}
                    center="true"
                    columns={[
                        {
                            Header: t('Date'),
                            accessor: 'date',
                            Cell: cellData => <DateTime data={cellData.value} />
                        },
                        {
                            Header: t('Name'),
                            accessor: 'name',
                            Cell: cellData => {
                                const {ownerTeamName, otherTeamName} = cellData.row.original;
                                console.log(cellData.row.origina);
                                return (<>
                                    {ownerTeamName} vs {otherTeamName}
                                    <Subtext>{cellData.value}</Subtext>
                                </>);
                            }
                        },
                        {
                            Header: t('Attendance'),
                            accessor: 'attendance',
                            Cell: cellData => {
                                const {players} = cellData.row.original;
                                const player = players.find(x => x.playerId == playerId);
                                return (<>
                                    {player ? <Dropdown name="status"
                                                        onChange={() => {}}
                                                        options={attendance} value={player?.status} /> : "-"}
                                </>);
                            }
                        },
                        {
                            Header: t('Rating'),
                            accessor: 'rating',
                            Cell: cellData => {
                                const {players} = cellData.row.original;
                                const player = players.find(x => x.playerId == playerId);
                                return (<>
                                    {player ? player?.rating : undefined}
                                </>);
                            }
                        }
                    ]}
                    onRowClick={() => {}} />}
            </div>
        </>
    );
};

PlayerAttendanceGames.defaultProps = {
    attendance: [
        {label: "Present", icon: "<i class=\"icon-Check-Square icon-green\"></i>", value: "PRESENT"},
        {label: "Was late", icon: "<i class=\"icon-Time-Square icon-yellow\"></i>", value: "WAS_LATE"},
        {label: "Ill", icon: "<i class=\"icon-medicine icon-blue\"></i>", value: "ILL"},
        {label: "Excused", icon: "<i class=\"icon-Document icon-blue\"></i>", value: "EXCUSED"},
        {label: "Unexcused", icon: "<i class=\"icon-Close-Square icon-red\"></i>", value: "UNEXCUSED"},
    ],
    health: [
        {label: "Good", icon: "<span class=\"icon-smHappy\"><span class=\"path1\"></span><span class=\"path2\"></span><span class=\"path3\"></span><span class=\"path4\"></span><span class=\"path5\"></span><span class=\"path6\"></span></span>", value: "GOOD"},
        {label: "Average", icon: "<span class=\"icon-smConfused\"><span class=\"path1\"></span><span class=\"path2\"></span><span class=\"path3\"></span><span class=\"path4\"></span><span class=\"path5\"></span><span class=\"path6\"></span></span>", value: "AVERAGE"},
        {label: "Bad", icon: "<span class=\"icon-smSad\"><span class=\"path1\"></span><span class=\"path2\"></span><span class=\"path3\"></span><span class=\"path4\"></span><span class=\"path5\"></span><span class=\"path6\"></span><span class=\"path7\"></span><span class=\"path8\"></span><span class=\"path9\"></span></span>", value: "BAD"},
    ],
};

export default PlayerAttendanceGames;
