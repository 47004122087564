import React, {useEffect, useState} from 'react';
import Select from 'components/UI/Select/Select';
import {useTranslation} from 'react-i18next';
import {useClubTeamsAndPlayers} from 'apollo/queries/ClubQueries';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import ScrollList from 'components/UI/ScrollList/ScrollList';

const AddPlayerForm = (props) => {
  const {setLoading} = props;

  const {t} = useTranslation();
  const navState = useReactiveVar(naviDataVar);

  const [teams, setTeams] = useState([]);
  const [values, setValues] = useState({team: navState.teamId.replaceAll("-", "")});
  const [addPlayers, setAddPlayers] = useState([]);
  const [filter, setFilter] = useState({ teams: { some: { teamId: { eq: values.team } } } } );

  const {data, error, loading} = useClubTeamsAndPlayers({
    clubId: navState.clubId ,
    playerWhere: filter
  });

  useEffect(() => {
    if (!data)
      return;

    const teamList = data.teams.items.filter(f => f.playerCount > 0).map(x => ({label: x.name, value: x.teamId}));
    teamList.push({label: t('Without team'), value: 'none'});
    setTeams(teamList);
  }, [data]);

  useEffect(() => {
      if (values.team === 'none') {
        setFilter({ teams: {any:false}});
      } else {
        setFilter({ teams: { some: { teamId: { eq: values.team } } } });
      }
  }, [values.team]);


  const formSubmitted = (e) => {
    e.preventDefault();
    setLoading(true);
    props.onAddPlayers(addPlayers);
  }

  const fieldChanged = (event) => {
    const newValues = { ...values };
    newValues[event.target.name] = event.target.value
    setValues(newValues);
  }

  const regField = {
    value: values,
    onChange: fieldChanged,
  };

  const onPlayerChange = (player) => {
    const players = [...addPlayers];
    const index = players.findIndex(f => f.playerId === player.playerId);
    index === -1 ? players.push(player) : players.splice(index,1);
    setAddPlayers(players);
  }

  return (
      <>
        <form id={props.id} onSubmit={formSubmitted}>
          <Select
              name="team"
              {...regField}
              options={teams}
              label={t('Team')}
          />

          <ScrollList>
            {loading && (<li>Loading...</li>)}
            {error && (<li>Error...</li>)}
            {data?.players?.items && data.players.items.map((player) =>
                <li key={player.playerId}>
                  <input type="checkbox" onChange={() => onPlayerChange(player)}
                         checked={props.existedPlayersIds.indexOf(player.playerId) !== -1 || addPlayers.find(f => f.playerId === player.playerId)  !== undefined}
                         disabled={props.existedPlayersIds.indexOf(player.playerId) !== -1}
                         style={{position: 'relative', top: '1px', marginRight: '5px' }} />{player.fullName}
                </li>)}
          </ScrollList>
        </form>
      </>
  );
};

AddPlayerForm.propTypes = {};
AddPlayerForm.defaultProps = {
  existedPlayersIds: [],
  onAddPlayers: () => {}
};

export default AddPlayerForm;
