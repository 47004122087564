import React from 'react';
import {default as ReactSwitch} from 'react-switch';
import classes from './Switch.module.scss';
import cn from 'classnames';
import {haveDefaultValue} from '../../../utils/form';

const Switch = (props) => {
    const htmlFor = props.id || `switch-${Math.random()}`;

    const material = {
        offColor: "#B2B8CB",
        onColor: "#A3B9F5",
        onHandleColor: "#fff",
        handleDiameter: 12,
        uncheckedIcon: false,
        checkedIcon: false,
        boxShadow: undefined,
        activeBoxShadow: "0px 0px 1px 10px rgba(0, 0, 0, 0.2)",
        height: 16,
        width: 26
    }

    const view = props.default ? material : {};
    const defaultValue = haveDefaultValue(props);

    const onChange = (value) => {
        props.onChange({
            target: {
                name:  props.name,
                value: value
            }
        });
    }

    return (
        <div className={classes.Switch}>
            {(props.label) && <label htmlFor={htmlFor}>{props.label}</label>}
            <ReactSwitch
                id={htmlFor}
                className={cn({[classes.fullSize]: props.fullSize})}
                disabled={props.disabled}
                onChange={onChange}
                checked={defaultValue ?? props.checked}
                {...view}
            />
        </div>
    );
};

Switch.propTypes = {};
Switch.defaultProps = {
    onChange: () => {
    },
    checked: false,
    default: true,
    fullSize: false
};

export default Switch;