import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import {useClubPlayers} from 'apollo/queries/ClubQueries';
import cn from 'classnames';
import classes from 'containers/Club/ClubMedicine/ClubMedicine.module.scss';
import Submenu from 'components/Navigation/Submenu/Submenu';
import Panel from 'components/UI/Panel/Panel';
import Table from 'components/UI/Table/Table';
import Subtext from 'components/UI/Table/Subtext/Subtext';
import Birthday from 'components/UI/Table/Birthday/Birthday';
import Pagination from 'components/UI/Pagination/Pagination';
import SwitchComponents from 'components/System/SwitchComponents';
import HealthCheckForm from 'containers/Club/ClubMedicine/form/HealthCheckForm';
import HealthConditionForm from 'containers/Club/ClubMedicine/form/HealthConditionForm';
import AnthropometricForm from 'containers/Club/ClubMedicine/form/AnthropometricForm';
import Search from 'components/UI/Panel/Search/Search';
import Select from 'components/UI/Select/Select';
import {useNavigate} from 'react-router-dom';

const ClubHealthCondition = (props) => {
    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);
    const history = useNavigate();

    const onPage = 20;

    const [skip, setSkip] = useState(0);
    const [where, setWhere] = useState({fullName: {contains: ""}, healthCondition: {neq: "GOOD"}});
    const [check, setCheck] = useState({check: "all"});

    const [modalData, setModalData, initState] = ModalState();

    const {data, loading, error, refetch}  = useClubPlayers({
        clubId: navState.clubId ,
        where: where,
        skip: skip * onPage,
        take: onPage,
        order: [{healthCheckNextDate:"ASC"}, {fullName: "ASC"}]
    });

    useEffect(() => {
        const newWhere = {...where};
        if (props.accessType === "team") {
            newWhere.teams =  {
                some: {
                    teamId: {
                        eq: navState.teamId}
                }
            };

            setWhere(newWhere);
        }
    }, []);

    const onFilter = (e) => {
        const newWhere = {...where};

        if (e.target.value === "all") {
            newWhere.healthCondition = {neq: "GOOD"};
        } else {
            newWhere.healthCondition = {eq: e.target.value};
        }

        setWhere(newWhere);
        setCheck({check: e.target.value});
        setSkip(0);
    }

    const onSearch = (e) => {
        setSkip(0);
        const newWhere = {...where, fullName: {contains: e.target.value}};
        setWhere(newWhere);
    }

    const panelSearch = () => (
        <Search onSearch={onSearch} placeholder={t("Search player...")} />
    );

    const panelFilters = () => (
        <>
            <Select
                small="small"
                name="check"
                value={check}
                onChange={onFilter}
                options={props.check}
                placeholder={t("Condition")} />
        </>
    );

    const onClick = (data) => {
        history(`/club-${navState.clubId}/team-${navState.teamId ?? "none"}/player-${data.playerId}`);
    };

    const onHealthCheck = (data) => {
        const modalData = {
            ...initState,
            form: 'healthCheck',
            title: t('Add health check'),
            defaultValues: {
                playerId: data.playerId,
                fullName: data.fullName,
            },
            onComplete: onComplete,
            open: true,
        };

        setModalData(modalData);
    };

    const onHealthCondition = (data) => {
        const modalData = {
            ...initState,
            form: 'healthCondition',
            title: t('Update health condition'),
            styles: {
                maxWidth: '700px',
            },
            defaultValues: {
                playerId: data.playerId,
                fullName: data.fullName,
                gender: data.gender,
            },
            onComplete: onComplete,
            open: true,
        };

        setModalData(modalData);
    };

    const onAnthropometric = (data) => {
        const modalData = {
            ...initState,
            form: 'anthropometric',
            title: t('Add anthropometric data'),
            defaultValues: {
                playerId: data.playerId,
            },
            onComplete: onComplete,
            open: true,
        };

        setModalData(modalData);
    };

    const onComplete = () => {
        setModalData(initState);
        refetch();
    };

    const onSettings = () => {
        return [
            {label: 'Health check', id: '1', action: onHealthCheck},
            {label: 'Health condition', id: '2', action: onHealthCondition},
            {label: 'Anthropometric measurements', id: '3', action: onAnthropometric}
        ];
    };

    return (
        <>
            <div className={cn('page-header', classes.medicine)}>
                <div className={classes.name}>{navState.clubName}</div>
                <h2>{t('Health condition')}</h2>
                <Submenu />
            </div>
            <div className="page-content">
                <Panel border={false}  filters={panelFilters} search={panelSearch}>
                    <br />
                </Panel>

                {loading && <>Loading...</>}
                {error && <>Error...</>}

                {data &&
                    <>
                        <Table
                            data={data.players.items}
                            columns={[
                                {
                                    Header: t('User name'),
                                    accessor: 'fullName',
                                    Cell: (cellData) => <div onClick={() => onClick(cellData.row.original)} style={{cursor: 'pointer'}}>
                                        {cellData.value}
                                        <Subtext>{t("Birth")}: { cellData.row.original.dateOfBirth ?
                                            <Birthday className={classes.birthday} coma={true} data={cellData.row.original.dateOfBirth} /> : "-" }</Subtext>
                                    </div>
                                },
                                {
                                    Header: t('E-mail'),
                                    className: classes.hide,
                                    accessor: 'email',
                                    Cell: (cellData) => <div>
                                        <a href="mailto:{cellData.value}">{cellData.value}</a>
                                        {cellData.row.original.phoneNumber && <Subtext>{t("Phone")}: <a href="phone:{cellData.row.original.phoneNumber}">
                                            {cellData.row.original.phoneNumber}</a></Subtext>}
                                    </div>
                                },
                                {
                                    Header: t('Teams'),
                                    accessor: 'teams',
                                    className: classes.hide,
                                    Cell: (cellData) => {
                                        const {trainerId} = cellData.row.original;
                                        return (cellData.row.original.teams.map((x, index) =>
                                                <span key={`team-${trainerId}-${x.team.name}`}>
                                            {(index ? ', ' : '')} {x.team.name} ({x.teamNumber})
                                        </span>
                                        ));
                                    }
                                },
                                {
                                    Header: t('Health condition'),
                                    accessor: 'healthCondition',
                                    className: classes.healthCondition,
                                    Cell: (cellData) => t(cellData.value.toLowerCase())
                                }
                            ]}
                            onSettings={onSettings}
                            onRowClick={() => {}} />

                        <Pagination page={skip} onPage={onPage} count={data.players.totalCount}
                                    onPageChange={(p) => setSkip(p.selected)}/>
                    </>}
            </div>

            <ModalWindow2 {...modalData}>
                <SwitchComponents active={modalData.form}>
                    <HealthCheckForm {...modalData} name="healthCheck" />
                    <HealthConditionForm {...modalData} name="healthCondition" />
                    <AnthropometricForm {...modalData} name="anthropometric" />
                </SwitchComponents>
            </ModalWindow2>
        </>
    );
};

ClubHealthCondition.defaultProps = {
    check: [
        {label: "All", value: "all" },
        {label: "Illness", value: "ILLNESS" },
        {label: "Injury", value: "INJURY" },
    ]
};

export default ClubHealthCondition;
