import React from 'react';
import classes from './Exercise.module.scss';
import Button from 'components/UI/Button/Button';
import {useTranslation} from 'react-i18next';
import VideoPlayer from 'components/UI/VideoPlayer/VideoPlayer';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';
import CanAccess from 'components/Navigation/CanAccess/CanAccess';

const Exercise = (props) => {
    const {defaultValues, onEditExercise, onComplete} = props;
    const {name, duration, image, numberOfPlayers, repeat, rest, rhythm, coefficient, ageGroup,
        level, description, scheme, tools, categories, tags, video, accessType} = defaultValues;

    const {t} = useTranslation();

    const canEdit = ["MY", "CLUB"]

    const onEdit = () => {
        onEditExercise(defaultValues);
    }

    const onCopy = () => {

    }

    const formSubmitted = (e) => {
        e.preventDefault();
        onComplete(defaultValues);
    }

    const Header = () => {
        const exerciseImage = image ? image : "/assets/images/exercises/no-image.jpg";
        return (
            <div className={classes.exercise}>
                <div className={classes.image}><img src={exerciseImage} alt={name} /></div>
                <div>
                    <h2>{name}</h2>
                    <ul className={classes.actions}>
                        <li>
                            <CanAccess users={[SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin]}>
                                <Button type="third" onClick={onEdit}><i className="icon-Setting" />{t("Edit exercise")}</Button>
                            </CanAccess>
                        </li>
                        {/*<li>*/}
                        {/*    <Button type="third" onClick={onCopy}><i className="icon-dollar" /> {t("Copy exercise")}</Button>*/}
                        {/*</li>*/}
                    </ul>

                    <div className={classes.panel}>
                        <div><span>{t("Duration")}: </span> <span>{duration ? duration : "-"}</span></div>
                        <div><span>{t("Players")}: </span> <span>{numberOfPlayers ? numberOfPlayers : "-"}</span></div>
                        <div><span>{t("Repeat")}: </span> <span>{repeat ? repeat : "-"}</span></div>
                        <div><span>{t("Rest")}: </span> <span>{rest ? rest : "-"}</span></div>
                        <div><span>{t("Rhythm")}: </span> <span>{rhythm ? rhythm : "-"}</span></div>
                        <div><span>{t("Coefficient")}: </span> <span>{coefficient ? coefficient : "-"}</span></div>
                        <div><span>{t("Age group")}: </span> <span>{ageGroup ? ageGroup : "-"}</span></div>
                        <div><span>{t("Level")}: </span> <span>{level ? level : "-"}</span></div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div>
            <Header />

            <div className={classes.body}>
                <div className={classes.group}>
                    <div className={classes.title}>{t("Exercise description")}</div>
                    {description ? description : t("No description added.")}
                </div>

                {scheme.image && <div className={classes.group}>
                    <div className={classes.scheme} style={{backgroundImage: `url(${scheme.background})`}}>
                        <img src={scheme.image} alt={name} />
                    </div>
                </div>}

                <div className={classes.group}>
                    <div className={classes.title}>{t("Required items and tools")}</div>
                    {tools ? tools : t("No items or tools needed.")}
                </div>

                {video && <div className={classes.group}>
                    <VideoPlayer url={video} play={false} />
                </div>}


                {categories.length > 0 && <div className={classes.group}>
                    <div className={classes.title}>{t("Categories")}</div>
                    {categories.map(x => <span key={x} className={classes.tag}>{x}</span>)}
                </div>}

                {tags.length > 0 && <div className={classes.group}>
                    <div className={classes.title}>{t("Tags")}</div>
                    {tags.map(x => <span key={x} className={classes.tag}>{x}</span>)}
                </div>}
            </div>

            <form id={props.id} onSubmit={formSubmitted}></form>

        </div>
    );
};

Exercise.defaultProps = {};

export default Exercise;
