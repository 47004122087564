import React, {useState} from 'react';
import Panel from '../../../components/UI/Panel/Panel';
import Button from '../../../components/UI/Button/Button';
import {useTranslation} from 'react-i18next';
import Table from '../../../components/UI/Table/Table';
import Subtext from '../../../components/UI/Table/Subtext/Subtext';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from '../../../apollo/cashe';
import {useDeleteVideoSubaction} from '../../../apollo/queries/GameQueries';
import ModalWindow from '../../../components/UI/ModalWindow/ModalWindow';
import CategoryForm from './forms/CategoryForm';
import classes from '../../Settings/Settings.module.scss';

const MatchesDetailsVideoDataCategories = (props) => {
    const {t} = useTranslation();

    const [modalData, setModalData] = useState({
        open: false,
        formId: `form-${Math.random()}`,
        title: t('Add category'),
    });

    const navState = useReactiveVar(naviDataVar);
    const [deleteVideoSubaction] = useDeleteVideoSubaction();

    const addActivity = () => (
        <Button type="primary" style={{margin: 0}} onClick={onAddSubactivity}>
            <span><i className="icon-Plus-Square"></i> {t('Add category')}</span>
        </Button>
    );

    const onAddSubactivity = () => {
        setModalData({...modalData, open: true, title: t('Add category'), defaultValues: null});
    };

    const onSubactivityClick = (data) => {
        setModalData({...modalData, open: true, title: t('Update category'), defaultValues: data});
    };

    const onComplete = () => {
        props.refetch();
        setModalData({...modalData, open: false});
    };

    const onDelete = () => {
        const variables = {
            input: {
                id: modalData.defaultValues?.id,
                clubId: navState.clubId,
                teamId: navState.teamId,
            }
        };

        deleteVideoSubaction({variables: variables}).then((result) => {
            if (result) props.refetch();
            setModalData({...modalData, open: false});
        });
    };



    return (
        <>
            <div onClick={props.onBack} className={[classes.title, classes.link].join(" ")}>
                <i className={["icon-Arrow---Up", classes.backButton, "backArrow"].join(" ")}></i>
                {t("Video categories")}
            </div>

            <Panel border={false} action={addActivity}>
                <br/>
            </Panel>

            <Table
                data={props.data.dataEventCategories}
                center="true"
                columns={[
                    {
                        Header: t('Category'),
                        accessor: 'name',
                        Cell: cellData =>
                            <div>{t(cellData.value)}<Subtext>{t('Type')}: {cellData.row.original.isSystem ? t('system') : t('editable')}</Subtext>
                            </div>
                    },
                    {
                        Header: t('Order'),
                        accessor: 'order',
                        Cell: cellData => <div>{cellData.value}</div>
                    },
                ]}
                onRowClick={onSubactivityClick}/>

            {modalData.open && <ModalWindow
                title={modalData.title}
                formSubmitId={modalData.formId}
                onCloseClick={() => setModalData({...modalData, open: false})}
                onDeleteClick={modalData.defaultValues?.canDelete === true ? onDelete : undefined}
            >
                <CategoryForm formId={modalData.formId} defaultValues={modalData.defaultValues} onComplete={onComplete} />
            </ModalWindow>}
        </>
    );
};

MatchesDetailsVideoDataCategories.defaultProps = {
    refetch: () => {},
    data: {
        eventCategories: []
    }
};

export default MatchesDetailsVideoDataCategories;
