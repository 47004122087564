import React, {useRef} from 'react';
import classes from './TestForm.module.scss';
import Select from '../../../../components/UI/Select/Select';
import Input from '../../../../components/UI/Input/Input';
import Textarea from '../../../../components/UI/Textarea/Textarea';
import {metricSettings} from '../Settings';
import Switch from '../../../../components/UI/Switch/Switch';
import ImageUpload from '../../../../components/UI/ImageUpload/ImageUpload';

import {useTranslation} from 'react-i18next';

const TestPageOne = (props) => {
    const {settings, values, setValues, regField} = props;

    const {t} = useTranslation();
    const logoUploadRef = useRef();

    return (
        <>
            <div className={classes.columns21}>
                <div>
                    <Select
                        name="metricCategoryId"
                        label={t('Category')}
                        options={settings.categories}
                        disabled={values.isSystem}
                        {...regField} />

                    <Input
                        name="name"
                        label={t('Name')}
                        disabled={values.isSystem}
                        {...regField}
                    />

                    <Textarea
                        name="description"
                        type="number"
                        label={t('Description')}
                        disabled={values.isSystem}
                        {...regField}
                    />

                    <div className={classes.columns21}>
                        <Select
                            name="unitOfMeasure"
                            label={t('Measurement unit')}
                            options={metricSettings.unitOfMeasure}
                            disabled={values.isSystem}
                            {...regField} />

                        <Select
                            name="systemOfMeasurement"
                            label={t('Measurement system')}
                            options={metricSettings.systemOfMeasurement}
                            disabled={values.isSystem}
                            {...regField} />
                    </div>

                    <Input
                        name="attempts"
                        type="number"
                        label={t('Attempts')}
                        disabled={values.isSystem}
                        {...regField}
                    />

                    <Switch
                        fullSize={true}
                        name="showInClub"
                        label={t('Show in club')}
                        {...regField}
                    />

                    <Input
                        name="order"
                        type="number"
                        label={t('Order')}
                        {...regField}
                    />


                </div>
                <div>
                    <ImageUpload
                        name="logo"
                        ref={logoUploadRef}
                        label={t('Test scheme')}
                        text="Add test scheme"
                        disabled={values.isSystem}
                        {...regField}
                    />
                </div>
            </div>
        </>
    );
};

TestPageOne.defaultProps = {};

export default TestPageOne;
