import React from 'react';
import {Route, Routes as AppRoutes} from 'react-router-dom';
import AfterLogin from 'containers/AfterLogin/AfterLogin';
import RegistrationLayout from 'components/Layouts/RegistrationLayout/RegistrationLayout';
import SelectProfile from 'containers/SysAdmin/SelectProfile/SelectProfile';
import MainLayout from 'components/Layouts/MainLayout/MainLayout';
import TeamCoaches from 'containers/Team/TeamCoaches/TeamCoaches';
import AttendanceTrainings from 'containers/Team/Attendance/AttendanceTrainings';
import AttendanceMatches from 'containers/Team/Attendance/AttendanceMatches';
import AttendanceStatistic from 'containers/Team/Attendance/AttendanceStatistic';
import Trainings from 'containers/Team/Trainings/Trainings';
import TrainingDetails from 'containers/Team/TrainingsDetails/TrainingDetails';
import Matches from 'containers/Team/Matches/Matches';
import MatchesDetails from 'containers/Team/MatchesDetails/MatchesDetails';
import TeamStat from 'containers/Team/TeamStat/TeamStat';
import MatchesVideo from 'containers/Team/MatchesVideo/MatchesVideo';
import Competitions from 'containers/Team/Competitions/Competitions';
import Opponents from 'containers/Team/Opponents/Opponents';
import Team from 'containers/Team/Team/Team';
import ClubCoaches from 'containers/Club/ClubCoaches/ClubCoaches';
import ClubOrgChart from 'containers/Club/SportDirector/ClubOrgChart/ClubOrgChart';
import ClubTimeline from 'containers/Club/SportDirector/ClubTimeline/ClubTimeline';
import ExerciseLibrary from 'containers/Club/ExerciseLibrary/ExerciseLibrary';
import Normatives from 'containers/Club/Normatives/Normatives';
import ClubAdmins from 'containers/Club/ClubAdmins/ClubAdmins';
import PlayerAccess from 'containers/Club/Access/PlayerAccess';
import ParentAccess from 'containers/Club/Access/ParentAccess';
import StaffAccess from 'containers/Club/Access/StaffAccess';
import TeamArchive from 'containers/Club/Archive/TeamArchive/TeamArchive';
import PlayerArchive from 'containers/Club/Archive/PlayerArchive/PlayerArchive';
import StaffArchive from 'containers/Club/Archive/StaffArchive/StaffArchive';
import TeamList from 'containers/Club/TeamList/TeamList';
import ClubList from 'containers/SysAdmin/ClubList/ClubList';
import Localization from 'containers/SysAdmin/Localization/Localization';
import NotFound from 'containers/SysAdmin/NotFound/NotFound';
import Player from 'containers/Player/Player/Player';
import PlayerSkills from 'containers/Player/PlayerSkills/PlayerSkills';
import PlayerCareer from 'containers/Player/PlayerCareer/PlayerCareer';
import Communication from 'containers/Player/Communication/Communication';
import PlayerAttendanceTrainings from 'containers/Player/PlayerAttendance/PlayerAttendanceTrainings';
import PlayerAttendanceGames from 'containers/Player/PlayerAttendance/PlayerAttendanceGames';
import PlayerVideoMatches from 'containers/Player/PlayerVideo/PlayerVideoMatches';
import Dashboard from 'containers/SysAdmin/Dashboard/Dashboard';
import SuggestedTranslates from 'containers/SysAdmin/Localization/SuggestedTranslates';
import Registration from 'containers/SysAdmin/Registration/Registration/Registration';
import ConfirmEmail from 'containers/SysAdmin/ConfirmEmail/ConfirmEmail';
import ClubPlayers from 'containers/Club/ClubPlayers/ClubPlayers';
import ClubMedicine from 'containers/Club/ClubMedicine/ClubMedicine';
import ClubHealthCondition from 'containers/Club/ClubMedicine/ClubHealthCondition';
import ClubAnthropometric from 'containers/Club/ClubMedicine/ClubAnthropometric';
import Users from 'containers/SysAdmin/Users/Users';
import ClubGroup from 'containers/SysAdmin/ClubGroups/ClubGroup';
import ClubGroupList from 'containers/SysAdmin/ClubGroups/ClubGroupList';
import CoachEvaluationList from 'containers/Club/SportDirector/CoachEvaluationList/CoachEvaluationList';
import CoachEvaluationTable
    from 'containers/Club/SportDirector/CoachEvaluationList/CoachEvaluationTable/CoachEvaluationTable';
import ClubEvaluation from 'containers/Club/SportDirector/ClubEvaluation/ClubEvaluation';

const Routes = (props) => {
    return (
        <AppRoutes>
            <Route path="/Account/ExternalLoginCallback" element={<AfterLogin />} />

            <Route element={<RegistrationLayout />} >
                <Route path="/select-profile" element={<SelectProfile />} />
                <Route path="/registration" exact element={<Registration />} />
                <Route path="/confirm-email" exact element={<ConfirmEmail />} />
            </Route>

            <Route element={<MainLayout />} >
                <Route path="/club-:clubId/team-:teamId/player-:playerId/skills" element={<PlayerSkills />} />
                <Route path="/club-:clubId/team-:teamId/player-:playerId/career" element={<PlayerCareer />} />
                <Route path="/club-:clubId/team-:teamId/player-:playerId/communication" element={<Communication />} />
                <Route path="/club-:clubId/team-:teamId/player-:playerId/attendance-trainings" element={<PlayerAttendanceTrainings />} />
                <Route path="/club-:clubId/team-:teamId/player-:playerId/attendance-matches" element={<PlayerAttendanceGames />} />
                <Route path="/club-:clubId/team-:teamId/player-:playerId/video-matches" element={<PlayerVideoMatches />} />
                <Route path="/club-:clubId/team-:teamId/player-:playerId"  element={<Player />} />

                <Route path="/club-:clubId/team-:teamId/coaches" element={<TeamCoaches />} />
                <Route path="/club-:clubId/team-:teamId/attendance-trainings" element={<AttendanceTrainings />} />
                <Route path="/club-:clubId/team-:teamId/attendance-matches" element={<AttendanceMatches />} />
                <Route path="/club-:clubId/team-:teamId/attendance-statistic" element={<AttendanceStatistic />} />
                <Route path="/club-:clubId/team-:teamId/trainings" element={<Trainings />} />
                <Route path="/club-:clubId/team-:teamId/training-:trainingId" element={<TrainingDetails />} />
                <Route path="/club-:clubId/team-:teamId/matches" element={<Matches />} />
                <Route path="/club-:clubId/team-:teamId/match-:gameId" element={<MatchesDetails />} />
                <Route path="/club-:clubId/team-:teamId/matches-statistic" element={<TeamStat />} />
                <Route path="/club-:clubId/team-:teamId/matches-video" element={<MatchesVideo />} />
                <Route path="/club-:clubId/team-:teamId/competitions" element={<Competitions />} />
                <Route path="/club-:clubId/team-:teamId/opponents" element={<Opponents />} />

                <Route path="/club-:clubId/team-:teamId/health" exact element={<ClubMedicine accessType="team" />} />
                <Route path="/club-:clubId/team-:teamId/condition" exact element={<ClubHealthCondition accessType="team" />} />
                <Route path="/club-:clubId/team-:teamId/anthropometric" exact element={<ClubAnthropometric accessType="team" />} />

                <Route path="/club-:clubId/team-:teamId/access-players" element={<PlayerAccess {...props} accessType="team"  />} />
                <Route path="/club-:clubId/team-:teamId/access-parents" element={<ParentAccess {...props} accessType="team"  />} />
                <Route path="/club-:clubId/team-:teamId/access-staff" element={<StaffAccess {...props} accessType="team"  />} />
                <Route path="/club-:clubId/team-:teamId" element={<Team />} />


                <Route path="/club-:clubId/coaches" exact element={<ClubCoaches />} />
                <Route path="/club-:clubId/players" exact element={<ClubPlayers />} />
                <Route path="/club-:clubId/chart" exact element={<ClubOrgChart />} />
                <Route path="/club-:clubId/timeline" exact element={<ClubTimeline />} />
                <Route path="/club-:clubId/evaluation" exact element={<ClubEvaluation />} />
                <Route path="/club-:clubId/evaluation/coach-:trainerId" exact element={<CoachEvaluationTable />} />
                <Route path="/club-:clubId/exercises" exact element={<ExerciseLibrary />} />
                <Route path="/club-:clubId/tests" exact element={<Normatives />} />
                <Route path="/club-:clubId/medicine" exact element={<ClubMedicine />} />
                <Route path="/club-:clubId/medicine/health-condition" exact element={<ClubHealthCondition />} />
                <Route path="/club-:clubId/medicine/anthropometric" exact element={<ClubAnthropometric />} />
                <Route path="/club-:clubId/staff-admins" exact element={<ClubAdmins />} />
                <Route path="/club-:clubId/staff-coaches" exact element={<ClubCoaches />} />
                <Route path="/club-:clubId/access-players" exact element={<PlayerAccess accessType="club" />} />
                <Route path="/club-:clubId/access-parents" exact element={<ParentAccess accessType="club" />} />
                <Route path="/club-:clubId/access-staff" exact element={<StaffAccess accessType="club" />} />
                <Route path="/club-:clubId/archive-teams" exact element={<TeamArchive />} />
                <Route path="/club-:clubId/archive-players" exact element={<PlayerArchive />} />
                <Route path="/club-:clubId/archive-staff" exact element={<StaffArchive />} />
                <Route path="/club-:clubId" element={<TeamList />} />

                <Route path="/group-list" exact element={<ClubGroupList />} />
                <Route path="/group-:groupId" element={<ClubGroup />} />

                <Route path="/system/club-list" element={<ClubList />} />
                <Route path="/system/club-groups" element={<ClubGroupList />} />
                <Route path="/system/club-group-:groupId" element={<ClubGroup />} />
                <Route path="/system/users" element={<Users />} />
                <Route path="/system/translates" element={<Localization />} />
                <Route path="/system/suggested" element={<SuggestedTranslates />} />

                <Route path="/" exact element={<Dashboard />} />
                <Route path="*" element={<NotFound />} />
            </Route>
        </AppRoutes>
    );
};

Routes.defaultProps = {};

export default Routes;
