import React from 'react'
import classes from './Subtext.module.scss'
import cn from 'classnames';


const Subtext = (props) => {
  return (
      <div className={cn(classes.subtext, props.className)}>{props.children}</div>
  );
};


export default Subtext;