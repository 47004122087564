import React from 'react';
import classes from './Group.module.scss';
import cn from 'classnames';
import {useTranslation} from 'react-i18next';

const Group = (props) => {
    const {t} = useTranslation();

    return (
        <div className={cn(classes.group, props.className)}>
            <div className={classes.title}>
                {props.translate ? t(props.title) : props.title}
            </div>
            <div>
                {props.children}
            </div>
        </div>
    );
};

Group.defaultProps = {
    title: "Title",
    translate: true,
};

export default Group;
