import React, {useEffect, useState} from 'react';
import PlayerHeader from 'containers/Player/Player/PlayerHeader';
import Submenu from 'components/Navigation/Submenu/Submenu';
import classes from './PlayerSkills.module.scss';
import Group from 'components/UI/Group/Group';
import SkillChart from './charts/SkillChart';
import Tabs from 'components/UI/Tabs/Tabs';
import ChartTable from '../Player/chart/ChartTable';
import {usePlayerSkills} from 'apollo/queries/PlayerQueries';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar, systemDataVar} from 'apollo/cashe';
import {useTranslation} from 'react-i18next';
import Table from 'components/UI/Table/Table';
import Panel from 'components/UI/Panel/Panel';
import Button from 'components/UI/Button/Button';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import CommentForm from './forms/CommentForm';
import {timestampAsDate, timestampAsUtc} from 'utils/date';
import CanAccess from 'components/Navigation/CanAccess/CanAccess';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';

const PlayerSkills = (props) => {
    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);
    const systemData = useReactiveVar(systemDataVar);

    const [tabs, setTabs] = useState({});

    const {loading, error, data, refetch} = usePlayerSkills({
        clubId: navState.clubId,
        playerId: navState.playerId
    });

    const [modalData, setModalData, initState] = ModalState();

    useEffect(() => {
        if (!data) {
            return;
        }

        const _tabs = data.playerSkillCategories.map(x => x.id).reduce((a, v) => ({ ...a, [v]: 'chart'}), {});
        setTabs({..._tabs});
    }, [data]);

    const onTabChange = (name, val) => {
        const newTabs = {...tabs, [name]:val};
        setTabs(newTabs);
    };

    const printChartGroup = (group) => {
        const _data = {
            labels: group.skills.map(x => x.isSystem ? t(x.name) : x.name),
            data: group.skills.map(x => x.lastValue),
        }
        return (
            <Group key={group.id} translate={group.isSystem} title={group.name}>
                <div className={classes.tabs}>
                    <Tabs onClick={(val) => onTabChange(group.id, val)}
                          selected={group.id} items={props.tabs} />
                </div>

                <div className={classes.chart}>
                    {tabs[group.id] === "chart"
                        ? <SkillChart data={_data} />
                        : <ChartTable data={group.skills} title={group.name} refetch={refetch} />}
                </div>
            </Group>
        );
    }

    const actions = (group) => (
        <Button type="second" style={{margin: 0}} onClick={() => onAddRecord(group)}>
            <span><i className="icon-Plus-Square"></i> {t('Add record')}</span>
        </Button>
    );

    const onAddRecord = (group) => {
        const _data = {
            ...initState,
            title: t("Add record"),
            onComplete: onComplete,
            skills: group.skills,
            open: true
        }

        setModalData(_data);
    };

    const onRecordClick = (group, data) => {
        if ([SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin].includes(systemData.user.userRole.roleId) == false) {
            return;
        }

        const {skillId, date, comment} = data;

        const _data = {
            ...initState,
            title: t("Edit record"),
            defaultValues: {
                skillId,
                date: timestampAsUtc(date),
                comment
            },
            onComplete: onComplete,
            onDelete: () => onDelete(skillId),
            skills: group.skills,
            open: true
        }

        setModalData(_data);
    }

    const onComplete = () => {
        setModalData(initState);
        refetch();
    }

    const onDelete = (id) => {

    }

    const printTextGroup = (group) => {
        return (
            <Group key={group.id} title={group.name} className={classes.fullRow}>
                <div className={classes.text}>
                    <CanAccess users={[SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin]}>
                        <Panel border={false} action={() => actions(group)} />
                    </CanAccess>
                    {group.skills.map(skill => (
                        <div key={skill.skillId}>
                            <b className={classes.subtitle}>{skill.isSystem ? t(skill.name) : skill.name}</b>
                            <Table
                                data={skill.values}
                                center="true"
                                columns={[
                                    {
                                        Header: t('Date'),
                                        accessor: 'date',
                                        className: classes.dateColumn,
                                        Cell: cellData => <div>{timestampAsDate(cellData.value)}</div>
                                    },
                                    {
                                        Header: t('Record'),
                                        accessor: 'comment',
                                        Cell: cellData => <div>{cellData.value}</div>
                                    }
                                ]}
                                onRowClick={(data) => onRecordClick(group, data)}/>
                        </div>
                    ))}
                </div>
            </Group>
        );
    }

    return (
        <>
            <div className="page-header">
                <PlayerHeader />
                <Submenu/>
            </div>
            <div className="page-content">
                {loading ? <div>{t("Loading")}</div> : undefined}
                {error ? <div>{t("Error")}</div> : undefined}

                <div className={classes.columns11}>
                    {data ? data.playerSkillCategories.map(x =>
                            x.isText ? printTextGroup(x) : printChartGroup(x))
                        : undefined}
                </div>
            </div>

            <ModalWindow2 {...modalData}>
                <CommentForm {...modalData} />
            </ModalWindow2>
        </>
    );
};

PlayerSkills.defaultProps = {
    tabs: [
        { id: 'chart', name: 'Chart'},
        { id: 'table', name: 'Table'},
    ],
};

export default PlayerSkills;
