import React, {useEffect, useState} from 'react';
import classes from './PreviewUploadTeamForm.module.scss';
import Input from 'components/UI/Input/Input';
import * as yup from 'yup';
import {useTranslation} from 'react-i18next';
import Select from 'components/UI/Select/Select';
import {
	Countries,
	Foot,
	Gender,
	Groups,
	NamedGroups,
	PlayerPositions,
	PlayerStatus
} from 'containers/Team/MatchesDetails/Settings';
import DatePicker from 'components/UI/DatePicker/DatePicker';
import cn from 'classnames';
import {getDate, importDate} from 'utils/date';
import {notHaveErrors, validateFields, validateForm} from 'utils/form';
import {useSavePlayer} from 'apollo/queries/PlayerQueries';
import {createGuid} from 'utils/utils';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';

const PreviewUploadTeamForm = (props) => {
	const {defaultValues, setLoading, onComplete} = props;

	const {t} = useTranslation();
	const navState = useReactiveVar(naviDataVar);
	const importRows = [...Array(50)];

	const [values, setValues] = useState(defaultValues ?? {});
	const [errors, setErrors] = useState({});

	const [savePlayer] = useSavePlayer();

	useEffect(() => {
		validateFields(values, validationRules(), (errors) => {
			setErrors(errors);
		});
	}, []);
	const fieldChanged = (event) => {
		const newValues = { ...values };
		newValues[event.target.name] = event.target.value
		setValues(newValues);
	}

	const validationRules = () => {
		const rules = {};
		importRows.forEach((row, index) => {
			if(values[`firstName-${index}`] || values[`lastName-${index}`] || values[`dateOfBirth-${index}`] || values[`gender-${index}`]) {
				rules[`firstName-${index}`] = yup.string().required(t("required")).nullable();
				rules[`lastName-${index}`] = yup.string(t("required")).required(t("required")).nullable();
				rules[`dateOfBirth-${index}`] = yup.string().required(t("required")).nullable();
				rules[`gender-${index}`] = yup.string().required(t("required")).nullable();
			}
		});

		return rules;
	}

	const regField = {
		value: values,
		onChange: fieldChanged,
		error: errors
	};

	const formSubmitted = async (e) => {
		e.preventDefault();
		setLoading(true);
		validateForm(values, validationRules(), (errors) => {
			if (notHaveErrors(errors)) {
				saveData();
			} else {
				setLoading(false);
				setErrors(errors);
			}
		});
	};

	const saveData = () => {
		importRows.forEach(async (row, index) => {
			if(values[`firstName-${index}`] || values[`lastName-${index}`] || values[`dateOfBirth-${index}`] || values[`gender-${index}`]) {
				const variables = {
					input: {
						playerId: createGuid(),
						teamId: navState.teamId,
						clubId: navState.clubId,
						firstName: values[`firstName-${index}`],
						lastName: values[`lastName-${index}`],
						dateOfBirth: values[`dateOfBirth-${index}`],
						gender: values[`gender-${index}`],
					}
				};

				if (values[`middleName-${index}`]) variables.input.middleName =  values[`middleName-${index}`];
				if (values[`licenceNr-${index}`]) variables.input.licenceNr =  values[`licenceNr-${index}`];
				if (values[`footed-${index}`]) variables.input.footed =  values[`footed-${index}`];
				if (values[`position-${index}`]) variables.input.position =  values[`position-${index}`];
				if (values[`phoneNumber-${index}`]) variables.input.phoneNumber =  values[`phoneNumber-${index}`];
				if (values[`email-${index}`]) variables.input.email =  values[`email-${index}`];
				if (values[`addressLine-${index}`]) variables.input.addressLine =  values[`addressLine-${index}`];
				if (values[`status-${index}`]) variables.input.status =  values[`status-${index}`];
				if (values[`registrationDate-${index}`]) variables.input.registrationDate =  values[`registrationDate-${index}`];
				if (values[`countryOfBirth-${index}`]) variables.input.countryOfBirth =  values[`countryOfBirth-${index}`];
				if (values[`cityOfBirth-${index}`]) variables.input.cityOfBirth =  values[`cityOfBirth-${index}`];
				if (values[`citizenship-${index}`]) variables.input.citizenship =  values[`citizenship-${index}`];
				if (values[`personalCode-${index}`]) variables.input.personalCode =  values[`personalCode-${index}`];

				await savePlayer({variables: variables});

			}});

		setTimeout(() => {
			onComplete();
		}, 9000);
	};

    return (
        <>
	        <form id={props.id} onSubmit={formSubmitted}>
	        <div className={cn(classes.row, classes.header)}>
		        <div>{t("Nr")}</div>
		        <div>{t("First name")}</div>
		        <div>{t("Middle name")}</div>
		        <div>{t("Last name")}</div>
		        <div>{t("Gender")}</div>
		        <div>{t("Personal code")}</div>
		        <div>{t("Date of birth")}</div>
		        <div>{t("Phone")}</div>
		        <div>{t("Email")}</div>
		        <div>{t("Address")}</div>
		        <div>{t("Country of birth")}</div>
		        <div>{t("City of birth")}</div>
		        <div>{t("Citizenship")}</div>
		        <div>{t("Licence")}</div>
		        <div>{t("Group")}</div>
		        <div>{t("Joined team")}</div>
		        <div>{t("Number")}</div>
		        <div>{t("Position")}</div>
		        <div>{t("Prefer foot")}</div>
		        <div>{t("Status")}</div>
	        </div>


	        {importRows.map((x, index) => (
		        <div key={`row-${index}`} className={classes.row}>
			        <span>{index + 1}</span>

			        <Input
				        name={`firstName-${index}`}
				        {...regField}
			        />

			        <Input
				        name={`middleName-${index}`}
				        {...regField}
			        />

			        <Input
				        name={`lastName-${index}`}
				        {...regField}
			        />

			        <Select
				        name={`gender-${index}`}
				        options={Gender()}
				        {...regField} />

			        <Input
				        name={`personalCode-${index}`}
				        {...regField}
			        />

			        <DatePicker
				        name={`dateOfBirth-${index}`}
				        {...regField}
			        />

			        <Input
				        name={`phoneNumber-${index}`}
				        type="number"
				        {...regField}
			        />

			        <Input
				        name={`email-${index}`}
				        type="email"
				        {...regField}
			        />

			        <Input
				        name={`addressLine-${index}`}
				        {...regField}
			        />

			        <Select
				        name={`countryOfBirth-${index}`}
				        options={Countries()}
				        {...regField} />

			        <Input
				        name={`cityOfBirth-${index}`}
				        {...regField}
			        />

			        <Select
				        name={`citizenship-${index}`}
				        options={Countries()}
				        {...regField} />

			        <Input
				        name={`licenceNr-${index}`}
				        {...regField}
			        />

			        <Select
				        name={`group-${index}`}
				        options={NamedGroups()}
				        {...regField} />

			        <DatePicker
				        name={`registrationDate-${index}`}
				        {...regField}
			        />

			        <Input
				        name={`teamNumber-${index}`}
				        type="number"
				        min="0"
				        {...regField}
			        />

			        <Select
				        name={`position-${index}`}
				        options={PlayerPositions()}
				        {...regField} />

			        <Select
				        name={`footed-${index}`}
				        options={Foot()}
				        {...regField} />

			        <Select
				        name={`status-${index}`}
				        options={PlayerStatus()}
				        {...regField} />

		        </div>
	        ))}
	        </form>
        </>
    );
};

PreviewUploadTeamForm.defaultProps = {};

export default PreviewUploadTeamForm;
