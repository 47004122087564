import React from 'react';
import {useTranslation} from 'react-i18next';
import Input from '../../../../../components/UI/Input/Input';
import classes from './ExerciseForm.module.scss';
import Select from '../../../../../components/UI/Select/Select';
import {filterOptions} from '../../Settings';
import Textarea from '../../../../../components/UI/Textarea/Textarea';
import * as yup from 'yup';

const PageOne = (props) => {
    const {regField} = props;

    const {t} = useTranslation();

    return (
        <>
            <Input
                name="name"
                label={t('Exercise name')}
                placeholder={t('Ex. attack start')}
                {...regField} />

            <div className={classes.filters}>
                <Input
                    name="duration"
                    type="number"
                    {...regField}
                    label={t('Duration')}
                />

                <Input
                    name="repeat"
                    type="number"
                    {...regField}
                    label={t('Repeat')}
                />

                <Input
                    name="rest"
                    type="number"
                    {...regField}
                    label={t('Rest')}
                />

                <Input
                    name="rhythm"
                    {...regField}
                    type="number"
                    label={t('Rhythm')}
                />

                <Input
                    name="coefficient"
                    {...regField}
                    type="number"
                    label={t('Coefficient')}
                />

                <Input
                    name="ageGroup"
                    {...regField}
                    type="number"
                    label={t('Age group')}
                />

                <Select
                    name="level"
                    {...regField}
                    options={filterOptions.level}
                    label={t('Level')}
                />

                <Input
                    name="numberOfPlayers"
                    type="number"
                    {...regField}
                    label={t('Number of players')}
                />

                <Select
                    name="language"
                    {...regField}
                    options={filterOptions.language}
                    label={t('Language')}
                />

                <Select
                    name="accessType"
                    {...regField}
                    options={filterOptions.accessType}
                    label={t('Access type')}
                />
            </div>

            <Textarea
                name="description"
                label={t('Description')}
                placeholder={t('Description of exercise')}
                {...regField} />

            <Textarea
                name="tools"
                label={t('Items and tools')}
                placeholder={t('E.g 3 balls, 6 vests')}
                {...regField} />

        </>
    );
};

PageOne.defaultProps = {};

export default PageOne;
