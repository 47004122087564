import React, {useState, useEffect} from 'react';
import Input from 'components/UI/Input/Input';
import Button from 'components/UI/Button/Button';
import {useTranslation} from 'react-i18next';
import {notHaveErrors, validateFields, validateForm} from 'utils/form';
import * as yup from 'yup';
import Submenu from 'components/Navigation/Submenu/Submenu';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar, systemDataVar} from 'apollo/cashe';
import {useAddCouchEvaluation, useEditCouchEvaluation} from 'apollo/queries/ClubQueries';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';

const CoachEvaluation = ({selectedCouch, couchName, defaultValues, onSaveEvaluation, onCloseEvaluation}) => {
  const {t} = useTranslation();
  const navState = useReactiveVar(naviDataVar);
  const systemData = useReactiveVar(systemDataVar);

  const readOnly = ![SystemRoles.GroupManger, SystemRoles.GroupMember].includes(systemData.user.userRole.roleId);

  const [initValues, setInitValues] = useState(defaultValues);
  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState({});
  const [clubTab, setClubTab] = useState(true);

  const [addEvaluation] = useAddCouchEvaluation(navState.clubId);
  const [editEvaluation] = useEditCouchEvaluation(navState.clubId);

  useEffect(() => {
    const newValues = {...values};
    recalculate(newValues);
    setValues(newValues);
  }, []);

  const fieldChanged = (event) => {
    const newValues = {...values};
    newValues[event.target.name] = event.target.value;

    validateFields(newValues, validationRules, (errors) => {
      setErrors(errors);
    });

    recalculate(newValues);
    setValues(newValues);
  };

  const validationRulesFormOne = {
    size: yup.number().min(0, t("Minimum value") + " 0").max(1, t("Maximum value") + " 1").typeError(t("Require")).required(t('Required')),
    state: yup.number().min(0, t("Minimum value") + " 0").max(1, t("Maximum value") + " 1").typeError(t("Require")).required(t('Required')),
    safety: yup.number().min(0, t("Minimum value") + " 0").max(1, t("Maximum value") + " 1").typeError(t("Require")).required(t('Required')),
    equipmentMinimum: yup.number().min(0, t("Minimum value") + " 0").max(1.5, t("Maximum value") + " 1.5").typeError(t("Require")).required(t('Required')),
    equipmentUsing: yup.number().min(0, t("Minimum value") + " 0").max(1.5, t("Maximum value") + " 1.5").typeError(t("Require")).required(t('Required')),
    headCouch: yup.number().min(0, t("Minimum value") + " 0").max(2, t("Maximum value") + " 2").typeError(t("Require")).required(t('Required')),
    assistant: yup.number().min(0, t("Minimum value") + " 0").max(2, t("Maximum value") + " 2").typeError(t("Require")).required(t('Required')),
    language: yup.number().min(0, t("Minimum value") + " 0").max(2, t("Maximum value") + " 2").typeError(t("Require")).required(t('Required')),
    attendance80: yup.number().min(0, t("Minimum value") + " 0").max(2, t("Maximum value") + " 2").typeError(t("Require")).required(t('Required')),
    discipline: yup.number().min(0, t("Minimum value") + " 0").max(1, t("Maximum value") + " 1").typeError(t("Require")).required(t('Required')),
  }

  const validationRulesFormTwo = {
    age: yup.number().min(0, t("Minimum value") + " 0").max(1, t("Maximum value") + " 1").typeError(t("Require")).required(t('Required')),
    process: yup.number().min(0, t("Minimum value") + " 0").max(1, t("Maximum value") + " 1").typeError(t("Require")).required(t('Required')),
    conspectus: yup.number().min(0, t("Minimum value") + " 0").max(1, t("Maximum value") + " 1").typeError(t("Require")).required(t('Required')),
    voice: yup.number().min(0, t("Minimum value") + " 0").max(1, t("Maximum value") + " 1").typeError(t("Require")).required(t('Required')),
    recommendation: yup.number().min(0, t("Minimum value") + " 0").max(1, t("Maximum value") + " 1").typeError(t("Require")).required(t('Required')),
    emotion: yup.number().min(0, t("Minimum value") + " 0").max(1, t("Maximum value") + " 1").typeError(t("Require")).required(t('Required')),
    equipment: yup.number().min(0, t("Minimum value") + " 0").max(1.5, t("Maximum value") + " 1.5").typeError(t("Require")).required(t('Required')),
    tasks: yup.number().min(0, t("Minimum value") + " 0").max(1.5, t("Maximum value") + " 1.5").typeError(t("Require")).required(t('Required')),
    showing: yup.number().min(0, t("Minimum value") + " 0").max(3, t("Maximum value") + " 3").typeError(t("Require")).required(t('Required')),
    withTrainers: yup.number().min(0, t("Minimum value") + " 0").max(1.5, t("Maximum value") + " 1.5").typeError(t("Require")).required(t('Required')),
    withAthletes: yup.number().min(0, t("Minimum value") + " 0").max(1.5, t("Maximum value") + " ").typeError(t("Require")).required(t('Required')),
  }

  const validationRules = { ...validationRulesFormOne, ...validationRulesFormTwo};

  const recalculate = (values) => {
    values['infrastructure'] = sum(values, ['size', 'state', 'safety']);
    values['inventory'] = sum(values, ['equipmentMinimum', 'equipmentUsing']);
    values['coaches'] = sum(values, ['headCouch', 'assistant']);
    values['languages'] = sum(values, ['language']);
    values['athletes'] = sum(values, ['attendance80', 'discipline']);
    values['totalClub'] = sum(values, ['infrastructure', 'inventory', 'coaches', 'languages', 'athletes']);
    values['plan'] = sum(values, ['age', 'process', 'conspectus']);
    values['training'] = sum(values, ['voice', 'recommendation', 'emotion']);
    values['explanation'] = sum(values, ['equipment', 'tasks']);
    values['show'] = sum(values, ['showing']);
    values['communication'] = sum(values, ['withTrainers', 'withAthletes']);
    values['totalCouch'] = sum(values, ['plan', 'training', 'explanation', 'show', 'communication']);
  };

  const sum = (newValues, items) => {
    let result = 0;
    items.forEach((item) => {
      result += newValues[item] !== undefined
          ? Number(newValues[item]) : 0;
    });
    return result;
  };

  const showSum = (name) => (values && values[name] !== undefined ? values[name] : 0);

  const formSubmitted = (e) => {
    e.preventDefault();
    validateForm(values, validationRules, (errors) => {
      if (notHaveErrors(errors))
        onSubmit();
      else
        setErrors(errors);
    });
  };

  const onSubmit = () => {
    const document = {...values};
    const id = document.id;

    delete document.id;

    if (id === undefined) {
      const variables = {
        'clubId': navState.clubId,
        'keyItem': selectedCouch,
        'document': JSON.stringify(document),
        'title': couchName + ' - trenera darbs novērtējums',
        'clubEvaluation': document.totalClub,
        'couchEvaluation': document.totalCouch,
      };
      addEvaluation({variables});
    } else {
      const variables = {
        'id': id,
        'document': JSON.stringify(document),
        'clubEvaluation': document.totalClub,
        'couchEvaluation': document.totalCouch,
      };
      editEvaluation({variables});
    }

    onSaveEvaluation();
  };

  const regField = {
    type: 'number',
    min: 0,
    step: 0.5,
    value: initValues,
    onChange: fieldChanged,
    error: errors
  };

  const onNext = (e) => {
    e.preventDefault();
    validateForm(values, validationRulesFormOne, (errors) => {
      if (notHaveErrors(errors)) {
        setInitValues(values);
        setClubTab(false);
      } else {
        setErrors(errors);
      }
    });
  }

  const Buttons = () => {
    if (readOnly && clubTab)
      return <>
        <Button onClick={onNext} type="primary">{t('Next')}</Button>
        <Button onClick={onCloseEvaluation}>{t('Close')}</Button>
      </>

    if (readOnly && !clubTab)
      return <>
        <Button onClick={() => setClubTab(true)}>{t('Prev')}</Button>
        <Button type="primary" onClick={onCloseEvaluation}>{t('Close')}</Button>
      </>

    if (clubTab)
      return <>
        <Button onClick={onNext} type="primary">{t('Next')}</Button>
        <Button onClick={onCloseEvaluation}>{t('Cancel')}</Button>
      </>;
    else return <>
      <Button onClick={() => setClubTab(true)}>{t('Prev')}</Button>
      <Button type="primary">{t('Save')}</Button>
      <Button onClick={onCloseEvaluation}>{t('Cancel')}</Button>
    </>;
  };

  return (
      <>
      <div className="page-header">
        <h2>{couchName} - trenera darbs novērtējums</h2>
        <Submenu />
      </div>
      <div className="page-content">
      <div className="row">
        <div className="col-md-12">

          <form className="paper" onSubmit={formSubmitted}>
            <fieldset disabled={readOnly}>

              {clubTab
                  ? <div>
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <h3>Infrastruktūra</h3>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8 form-group">
                        Izmērs
                      </div>
                      <div className="col-md-4 form-group">
                        <Input name="size" key="k1" {...regField} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8 form-group">
                        Laukuma gattavība
                      </div>
                      <div className="col-md-4 form-group">
                        <Input name="state" key="k2" {...regField} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8 form-group">
                        Drošība
                      </div>
                      <div className="col-md-4 form-group">
                        <Input name="safety" key="k3" {...regField} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8 form-group">
                      </div>
                      <div className="col-md-4 form-group">
                        {showSum('infrastructure')} punkti
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <h3>Inventārs</h3>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8 form-group">
                        Minimālais nepieciešamā inventāra esamība
                      </div>
                      <div className="col-md-4 form-group">
                        <Input name="equipmentMinimum" key="k4" {...regField} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8 form-group">
                        Izmantošana
                      </div>
                      <div className="col-md-4 form-group">
                        <Input name="equipmentUsing" key="k5" {...regField} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8 form-group">
                      </div>
                      <div className="col-md-4 form-group">
                        {showSum('inventory')} punkti
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <h3>Treneri</h3>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8 form-group">
                        Galvenais treneris
                      </div>
                      <div className="col-md-4 form-group">
                        <Input name="headCouch" key="k6" {...regField} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8 form-group">
                        Asistents un papildus speciālisti.
                      </div>
                      <div className="col-md-4 form-group">
                        <Input name="assistant" key="k7" {...regField} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8 form-group">
                      </div>
                      <div className="col-md-4 form-group">
                        {showSum('coaches')} punkti
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <h3>Valodas</h3>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8 form-group">
                        Valoda
                      </div>
                      <div className="col-md-4 form-group">
                        <Input name="language" key="k8" {...regField} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8 form-group">
                      </div>
                      <div className="col-md-4 form-group">
                        {showSum('languages')} punkti
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <h3>Futbolisti</h3>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8 form-group">
                        Klātesamības procents no max audzēkņu skaita grupā<br />
                        <small>80% un vairāk = 2 pukti, 50% un vairāk = 1 pukts</small>
                      </div>
                      <div className="col-md-4 form-group">
                        <Input name="attendance80" key="k9" {...regField} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8 form-group">
                        Futbolistu ekipējums,disciplīna
                      </div>
                      <div className="col-md-4 form-group">
                        <Input name="discipline" key="k11" {...regField} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8 form-group">
                      </div>
                      <div className="col-md-4 form-group">
                        {showSum('athletes')} punkti
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <hr/>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8 form-group">
                        Kopa:
                      </div>
                      <div className="col-md-4 form-group">
                        {showSum('totalClub')} punkti
                      </div>
                    </div>
                  </div>
                  : <div>
                    <div className="row">
                      <div className="col-md-8 form-group">
                        Atbilstība vecuma grupai
                      </div>
                      <div className="col-md-4 form-group">
                        <Input name="age" key="k21" {...regField} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8 form-group">
                        Atbilstība treniņprocesa metodikai
                      </div>
                      <div className="col-md-4 form-group">
                        <Input name="process" key="k22" {...regField} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8 form-group">
                        Konspekts
                      </div>
                      <div className="col-md-4 form-group">
                        <Input name="conspectus" key="k22-1" {...regField} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8 form-group">
                      </div>
                      <div className="col-md-4 form-group">
                        {showSum('plan')} punkti
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <h3>Treniņa vadīšana</h3>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8 form-group">
                        Balss
                      </div>
                      <div className="col-md-4 form-group">
                        <Input name="voice" key="k23" {...regField} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8 form-group">
                        Konstruktīvas rekomendācijas
                      </div>
                      <div className="col-md-4 form-group">
                        <Input name="recommendation" key="k24" {...regField} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8 form-group">
                        Aktivitāte,emocionilitāte
                      </div>
                      <div className="col-md-4 form-group">
                        <Input name="emotion" key="k25" {...regField} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8 form-group">
                      </div>
                      <div className="col-md-4 form-group">
                        {showSum('training')} punkti
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <h3>Izskaidrošana</h3>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8 form-group">
                        Treniņa vielas
                      </div>
                      <div className="col-md-4 form-group">
                        <Input name="equipment" key="k26" {...regField} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8 form-group">
                        Uzdevumu
                      </div>
                      <div className="col-md-4 form-group">
                        <Input name="tasks" key="k27" {...regField} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8 form-group">
                      </div>
                      <div className="col-md-4 form-group">
                        {showSum('explanation')} punkti
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <h3>Demonstrēšana</h3>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8 form-group">
                        Demonstrēšana
                      </div>
                      <div className="col-md-4 form-group">
                        <Input name="showing" key="k28" {...regField} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8 form-group">
                      </div>
                      <div className="col-md-4 form-group">
                        {showSum('show')} punkti
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <h3>Komunikācija</h3>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8 form-group">
                        Ar treneru štābu
                      </div>
                      <div className="col-md-4 form-group">
                        <Input name="withTrainers" key="k29" {...regField} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8 form-group">
                        Ar spēlētājiem
                      </div>
                      <div className="col-md-4 form-group">
                        <Input name="withAthletes" key="k30" {...regField} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8 form-group">
                      </div>
                      <div className="col-md-4 form-group">
                        {showSum('communication')} punkti
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <hr/>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8 form-group">
                        Kopa:
                      </div>
                      <div className="col-md-4 form-group">
                        {showSum('totalCouch')} punkti
                      </div>
                    </div>
                  </div>
              }
            </fieldset>
            <div className="row">
              <div className="col-md-12 form-group text-center">
                <Buttons/>
              </div>
            </div>
          </form>
        </div>
      </div>
      </div>
    </>
  );
};

CoachEvaluation.defaultProps = {
  defaultValues: {}
};

export default CoachEvaluation;
