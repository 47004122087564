import React from 'react';
import classes from './ExerciseForm.module.scss';
import GroupedCategories from '../../GroupedCategories';
import {useTranslation} from 'react-i18next';

const PageTwo = (props) => {
    const {values, setValues} = props;

    const {t} = useTranslation();

    const onCategorySelect = (selected) => {
        const newCategories = [...values.categoryList];
        const group = newCategories.find(c => c.items.find(x => x.id === selected.id));
        const category = group.items.find(x => x.id === selected.id);
        category.selected = !category.selected;
        setValues({...values, categoryList: newCategories});
    }

    return (
        <>
            <div className="message">{t("Select one or more categories.")}</div>
            <div className={classes.scroll}>
                <GroupedCategories data={values.categoryList} onFilter={onCategorySelect} classes={classes} />
            </div>
        </>
    );
};

PageTwo.defaultProps = {};

export default PageTwo;
