import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend'; // primary use cache
import HttpApi from 'i18next-http-backend'; // fallback http load
import Cookie from 'cookie-universal';
import axios from 'axios';

const cookies = Cookie();
const language = cookies.get('locale') ?? process.env.REACT_APP_DEFAULT_LANGUAGE;

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(Backend)
    .init({
        lng: language,
        debug: false,
        saveMissing: true,
        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        },
        backend: {
            backends: [
                LocalStorageBackend,  // primary
                HttpApi               // fallback
            ],
            backendOptions: [{
                expirationTime: 7 * 24 * 60 * 60 * 1000,
                defaultVersion: 1
            }, {
                loadPath: `${process.env.REACT_APP_TRANSLATES_URL}/{{lng}}`,
                addPath: `${process.env.REACT_APP_TRANSLATES_URL}/add/{{lng}}`,
            }]
        },
        react: {
            useSuspense: false
        }
    });

export default i18n;

if (sessionStorage.getItem('languages') === null) {
    axios.get(`${process.env.REACT_APP_TRANSLATES_URL}/languages`)
        .then(res => {
            sessionStorage.setItem('languages', JSON.stringify(res.data));
        });
}

