import React, { PropTypes } from 'react'
import classes from '../Settings.module.scss';
import {useTranslation} from 'react-i18next';

const Integrations = (props) => {
  const {t} = useTranslation();
  return (
      <>
        <div onClick={props.onBack} className={[classes.title, classes.link].join(" ")}>
          <i className={["icon-Arrow---Up", classes.backButton, "backArrow"].join(" ")}></i>
          {t("Integrations")}
        </div>
      </>
  );
};

Integrations.propTypes = {};
Integrations.defaultProps = {};

export default Integrations;