import React from 'react';
import classes from './PlayVideo.module.scss';
import VideoPlayer from 'components/UI/VideoPlayer/VideoPlayer';
import {useTranslation} from 'react-i18next';

const PlayVideo = (props) => {

    const {video, time} = props.defaultValues;
    const {t} = useTranslation();

    if (!video)
        return (<div>{t("Video not found.")}</div>);

    return (
        <div className={classes.video}>
            <VideoPlayer
                url={video}
                time={time}
                />
        </div>
    );
};

PlayVideo.defaultProps = {};

export default PlayVideo;
