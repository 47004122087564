import React, {useRef} from 'react';
import Panel from 'components/UI/Panel/Panel';
import Button from 'components/UI/Button/Button';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import Table from 'components/UI/Table/Table';
import DateTime from 'components/UI/Table/DateTime/DateTime';
import classes from './TrainingDetails.module.scss';
import FileUpload2 from 'components/UI/FileUpload/FileUpload2';
import {useAddTrainingFile} from 'apollo/queries/TrainingQueries';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';
import CanAccess from 'components/Navigation/CanAccess/CanAccess';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';

const TrainingDetailsFiles = (props) => {
	const {data, refetch} = props;

	const {t} = useTranslation();
	const navState = useReactiveVar(naviDataVar);

	const {trainingId} = useParams();
	const fileUploadRef = useRef();

	const [addTrainingFile] = useAddTrainingFile();

	const meta = {
		type: 'MATCH_FILE',
	};

	const onAddFile = () => {
		if (fileUploadRef.current) {
			fileUploadRef.current.onOpen();
		}
	};

	const filesActions = () => (
		<CanAccess users={[SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin]}>
			<Button type="primary" style={{margin: 0}} onClick={onAddFile}>
				<span><i className="icon-Plus-Square"></i> {t('Add file')}</span>
			</Button>
		</CanAccess>
	);

	const onUpload = async (result) => {
		const variables = {
			input: {
				fileId: result.fileId,
				teamId: navState.teamId,
				clubId: navState.clubId,
				trainingId: trainingId,
			}
		};

		await addTrainingFile({variables});
		refetch();
	};

	return (
		<>
			<Panel border={false} action={filesActions}>
				<br/>
			</Panel>

			<CanAccess users={[SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin]}>
				<FileUpload2 ref={fileUploadRef} meta={meta} onUpload={onUpload} mode="all"/>
			</CanAccess>

			<br/>
			<Table
				data={data.trainings.items[0].files}
        access={[SystemRoles.Coach, SystemRoles.ClubAdmin, SystemRoles.SysAdmin]}
				columns={[
					{
						Header: t('File'),
						accessor: 'name',
						Cell: cellData => <>
							<a href={cellData.row.original.link} target="_blank">{cellData.value}</a>
							<a href={`${cellData.row.original.link}&download=true`} className={classes.download} target="_blank"
							   download><span className="icon-Download-1"></span></a>
						</>
					},
					{
						Header: t('Size'),
						accessor: 'size',
						Cell: cellData => <a href={cellData.row.original.link} target="_blank" download>
							{cellData.value}</a>
					},
					{
						Header: t('Uploaded'),
						accessor: 'uploaded',
						Cell: cellData => <a href={cellData.row.original.link} target="_blank" download>
							<DateTime data={cellData.value}/></a>
					},
					{
						Header: t('User'),
						accessor: 'userName',
						Cell: cellData => <a href={cellData.row.original.link} target="_blank" download>
							{cellData.value}</a>
					},
				]}
				onRowClick={() => {
				}}/>
		</>
	);
};

TrainingDetailsFiles.propTypes = {};
TrainingDetailsFiles.defaultProps = {
	onRefresh: () => {
	}
};

export default TrainingDetailsFiles;
