import React, {useState} from 'react';
import Input from 'components/UI/Input/Input';
import Textarea from 'components/UI/Textarea/Textarea';
import Button from 'components/UI/Button/Button';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {regStateVar} from 'apollo/variables/registration';
import {notHaveErrors, validateFields, validateForm} from 'utils/form';
import * as yup from 'yup';
import axios from 'axios';
import ErrorMessage from 'components/UI/ErrorMessage/ErrorMessage';
import Spinner from 'components/UI/Spinner/Spinner';

const WriteToSupport = () => {
  const {t} = useTranslation();
  const regState = useReactiveVar(regStateVar);

  const [values, setValues] = useState(regState);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [hasNetError, setNetError] = useState(false);

  const fieldChanged = (event) => {
    const newValues = {...values};

    newValues[event.target.name] = event.target.value;

    validateFields(newValues, validationRules, (errors) => {
      setErrors(errors);
    });
    setValues(newValues);
  };

  const regField = {
    value: values,
    onChange: fieldChanged,
    error: errors
  };

  const validationRules = {
    userName: yup.string().required(t('Required')),
    userQuestion: yup.string().required(t('Required'))
  };

  const onGoTo = (goto, e) => {
    e.preventDefault();

    regStateVar({
      ...regState,
      main: goto,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    validateForm(values, validationRules, async (errors) => {
      if (notHaveErrors(errors))
        await sendMessage();
      else
        setErrors(errors);
    });
  };

  const sendMessage = async () => {
    try {
      setLoading(true);
      setNetError(false);

      const params = new URLSearchParams();
      params.append('email', values.email);
      params.append('name', values.userName);
      params.append('question', values.userQuestion);

      const response = await axios.post(process.env.REACT_APP_REGISTRATION_URL + '/support', params);

      if (response.status === 201) {
        setLoading(false);

        regStateVar({
          ...regState,
          ...values,
          main: 'MessageSent',
        });
      } else {
        notSend();
      }
    } catch (e) {
      notSend();
    }
  };

  const notSend = () => {
    setLoading(false);
    setNetError(true);
  };

  return (
      <div>
        <Button onClick={e => onGoTo('Variants', e)}
                type="third">  &#8592; {t('goBack')}</Button>

        <h1>{t('goToSupport')}</h1>

        {hasNetError && <ErrorMessage message={t('error404')}/>}

        <fieldset disabled={loading}>
          <div>
            <Input
                name="userName"
                {...regField}
                placeholder={t('yourName')}/>

            <Textarea
                name="userQuestion"
                {...regField}
                placeholder={t('yourQuestion')}/>
          </div>

          <Button onClick={onSubmit} type="primary">
            {loading ? <Spinner/> : t('Send')}
          </Button>

        </fieldset>
      </div>
  );
};

export default WriteToSupport;
