import React, {useState} from 'react';
import classes from './RecommendUs.module.scss';
import Input from 'components/UI/Input/Input';
import Button from 'components/UI/Button/Button';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {regStateVar} from 'apollo/variables/registration';
import {notHaveErrors, validateFields, validateForm} from 'utils/form';
import * as yup from 'yup';
import axios from 'axios'
import ErrorMessage from 'components/UI/ErrorMessage/ErrorMessage';
import Spinner from 'components/UI/Spinner/Spinner';

const RecommendUs = () => {
  const {t} = useTranslation();
  const regState = useReactiveVar(regStateVar);
  const rolesWhoCanCreateNewClub = [1, 2];

  const [values, setValues] = useState(regState);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [hasNetError, setNetError] =useState(false);

  const fieldChanged = (event) => {
    const newValues = {...values};

    newValues[event.target.name] = event.target.value;

    validateFields(newValues, validationRules, (errors) => {
      setErrors(errors);
    });
    setValues(newValues);
  };

  const regField = {
    value: values,
    onChange: fieldChanged,
    error: errors
  };

  const validationRules = {
    userName: yup.string().required(t('Required')),
    ownerName: yup.string().required(t('Required')),
    ownerContact: yup.string().required(t('Required')),
    clubName: yup.string().required(t('Required')),
  };

  const onGoTo = (goto, e) => {
    e.preventDefault();

    const role = +regState.role;
    if (goto === 'IsClubRegistered' && rolesWhoCanCreateNewClub.includes(role))
      goto = 'CreateOrRecommend';

    regStateVar({
      ...regState,
      main: goto,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    validateForm(values, validationRules, async (errors) => {
      if (notHaveErrors(errors))
        await sendMessage()
      else
        setErrors(errors);
    });
  }

  const sendMessage = async () => {
    try {
      setLoading(true);
      setNetError(false);

      const params = new URLSearchParams();
      params.append('name', values.userName);
      params.append('ovner', values.ownerName);
      params.append('contact', values.ownerContact);
      params.append('club', values.clubName);

      const response = await axios.post(process.env.REACT_APP_REGISTRATION_URL  + '/recommendation', params);

      if (response.status === 201) {
        setLoading(false);

        regStateVar({
          ...regState,
          ...values,
          main: 'ThankYou',
        });
      } else {
        notSend();
      }
    } catch (e) {
      notSend();
    }
  }

  const notSend = () => {
    setLoading(false);
    setNetError(true);
  }

  const onGoMain = (e) => {
    e.preventDefault();
    window.location.href = process.env.REACT_APP_REVIEW_URL;
  };

  return (
      <div>
        <Button onClick={e => onGoTo('IsClubRegistered', e)} type="third">  &#8592; {t('goBack')}</Button>
        <h1>{t('tellAboutUsMessage')}</h1>

        <div className={classes.description}>
          {t('tellAboutUsDescription')}
        </div>

        { hasNetError && <ErrorMessage message={t('error404')} /> }

        <fieldset disabled={loading}>
          <div>
            <Input
                name="userName"
                {...regField}
                placeholder={t('yourName')}/>

            <Input
                name="ownerName"
                {...regField}
                placeholder={t('ovnerName')}/>

            <Input
                name="ownerContact"
                {...regField}
                placeholder={t('ovnerContact')}/>

            <Input
                name="clubName"
                {...regField}
                placeholder={t('schoolName')}/>
          </div>

          <Button onClick={onSubmit} type="primary">
            {loading ? <Spinner /> : t('Send')}
          </Button>

          <Button onClick={onGoMain} type="third" style={{marginRight: '0'}}>{t('goMain')}</Button>
        </fieldset>
      </div>
  );
};

export default RecommendUs;
