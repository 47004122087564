import React from 'react';
import classes from './Matrix.module.scss';

const Matrix = (props) => {
    const {data, columns} = props;

    const rows = data[0]?.cells.length ? data[0]?.cells.length - 1 : 1;
    const gridTemplateColumns = columns ?? `170px repeat(${rows}, 90px) 1fr`;

    const Header = ({data}) => {
        return (
            <div className={classes.header} style={{gridTemplateColumns: gridTemplateColumns}}>
                {data.cells.length > 0
                    ? <RowHeader data={data.cells[0]} />
                    : undefined
                }

                {data.cells.length > 1
                    ? data.cells.slice(1).map(column => <Cell key={`cell-${data.rowId}-${column.columnId}`} data={column} />)
                    : undefined
                }
            </div>
        );
    }

    const Row = ({data}) => {
        const rows = data.cells.length - 1;
        return (
            <div className={classes.row} style={{gridTemplateColumns: gridTemplateColumns}}>
                {data.cells.length > 0
                    ? <RowHeader data={data.cells[0]} />
                    : undefined
                }

                {data.cells.length > 1
                    ? data.cells.slice(1).map(column => <Cell key={`cell-${data.rowId}-${column.columnId}`} data={column} />)
                    : undefined
                }
            </div>
        );
    }

    const RowHeader = ({data}) => {
        return (
            <div className={classes.rowHeader}>
                <div>{data.cellName}</div>
            </div>
        );
    }

    const Cell = ({data}) => {
        return (
            <div className={classes.cell}>
                {data.cellName}
            </div>
        );
    }

    return (
        <div  className={classes.matrix2}>
            <div className={classes.table}>
                {data.length > 0
                    ? <Header data={data[0]} />
                    : undefined
                }

                {data.length > 1
                    ? data.slice(1).map(row => <Row key={`row-${row.rowId}`} data={row} />)
                    : undefined
                }
            </div>

        </div>
    );
};

Matrix.defaultProps = {};

export default Matrix;
