import React, {useEffect, useState} from 'react';
import cn from 'classnames';
import Submenu from 'components/Navigation/Submenu/Submenu';
import Panel from 'components/UI/Panel/Panel';
import {useTranslation} from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {naviDataVar} from 'apollo/cashe';
import Button from 'components/UI/Button/Button';
import Search from 'components/UI/Panel/Search/Search';
import classes from './ClubPlayers.module.scss';
import {useClubPlayers} from 'apollo/queries/ClubQueries';
import Table from 'components/UI/Table/Table';
import Subtext from 'components/UI/Table/Subtext/Subtext';
import Pagination from 'components/UI/Pagination/Pagination';
import Birthday from 'components/UI/Table/Birthday/Birthday';
import {useArchivePlayer, usePlayerLazy} from 'apollo/queries/PlayerQueries';
import SwitchComponents from 'components/System/SwitchComponents';
import NewPlayerForm from 'containers/Team/Team/forms/NewPlayerForm';
import ModalWindow2, {ModalState} from 'components/UI/ModalWindow/ModalWindow2';
import {toastMessage} from 'components/UI/Toast/Toast';
import Select from 'components/UI/Select/Select';
import {SystemRoles} from 'components/Navigation/CanAccess/roles';
import CanAccess from 'components/Navigation/CanAccess/CanAccess';

const ClubPlayers = (props) => {
    const {t} = useTranslation();
    const navState = useReactiveVar(naviDataVar);
    const onPage = 20;

    const [skip, setSkip] = useState(0);
    const [selectedRows, setSelectedRows] = useState([]);
    const [where, setWhere] = useState({fullName: {contains: ""}} );

    const [modalData, setModalData, initState] = ModalState();

    const [archivePlayer] = useArchivePlayer();
    const {data, loading, error, refetch}  = useClubPlayers({
        clubId: navState.clubId ,
        where: where,
        skip: skip * onPage,
        take: onPage,
        order: [{fullName: "ASC"}]
    });
    const [getPlayerLazy, playerData] = usePlayerLazy();

    const actions = () => (
      <CanAccess users={[SystemRoles.SysAdmin, SystemRoles.ClubAdmin]}>
        <Button type="primary" style={{margin: 0}} onClick={onAddClick}>
            <span><i className="icon-Plus-Square"></i> {t('Add player')}</span>
        </Button>

        {selectedRows.length > 0 &&
            <Button type="secondary" style={{margin: 0}} onClick={onArchiveClick}>
                <span>{t('Archive')}</span>
            </Button>
        }
      </CanAccess>
    );

    const onFilter = (e) => {
        const newWhere = {...where};

        if (e.target.value === "all") {
            delete newWhere.teams;
        }

        if (e.target.value !== "all") {
            newWhere.teams = {any: e.target.value === "withTeam"};
        }

        setWhere(newWhere);
        setSkip(0);
    }

    const panelFilters = () => (
        <>
            <Select
                small="small"
                name="team"
                value={{team: "all"}}
                onChange={onFilter}
                options={props.team}
                placeholder={t("Team")} />
        </>
    );

    const onSearch = (e) => {
        setSkip(0);
        const newWhere = {...where, fullName: {contains: e.target.value}};
        setWhere(newWhere);
    }

    const panelSearch = () => (
        <Search onSearch={onSearch} placeholder={t("Search player...")} />
    );

    const onAddClick = () => {
        const data = {
            ...initState,
            title: t("Add new player"),
            form: "player",
            pages: {
                page: 0,
                maxPages: 3,
            },
            styles: {
                maxWidth: "700px",
            },
            onComplete: onComplete,
            open: true
        };

        setModalData(data);
    }

    useEffect(() => {
        if(!playerData.data) {
            return;
        }

        const _data = {
            ...initState,
            title: t("Edit player"),
            form: "player",
            access: [SystemRoles.SysAdmin, SystemRoles.ClubAdmin],
            pages: {
                page: 0,
                maxPages: 3,
            },
            styles: {
                maxWidth: "700px",
            },
            defaultValues: {
                ...playerData.data.players.items[0]
            },
            onComplete: onComplete,
            onDeleteClick: () => {},
            open: true
        };

        setModalData(_data);
    }, [playerData]);

    const onEditPlayer = (data) => {
        getPlayerLazy({
            variables: {
                clubId: navState.clubId,
                playerId: data.playerId,
            }
        });
    }

    const onComplete = (data) => {
        refetch();
        setModalData(initState);
        toastMessage(data.result, data.message);
    };

    const onArchiveClick = () => {
        selectedRows.forEach(row => {
            const variables = {
                input: {
                    id: row.playerId,
                    clubId: navState.clubId,
                }
            };

            archivePlayer({variables: variables}).then((result) => {
                if (result) refetch();
            });
        });
    }

    return (
        <>
            <div className={cn('page-header', classes.players)}>
                <div className={classes.name}>{navState.clubName}</div>
                <h2>{t('Players')}</h2>
                <Submenu />
            </div>
            <div className="page-content">
                <Panel border={false} action={actions} filters={panelFilters} search={panelSearch}>
                    <br />
                </Panel>

                {loading && <>Loading...</>}
                {error && <>Error...</>}

                {data &&
                    <>
                        <Table
                            data={data.players.items}
                            access={[SystemRoles.SysAdmin, SystemRoles.ClubAdmin]}
                            columns={[
                        {
                            Header: t('User name'),
                            accessor: 'fullName',
                            Cell: (cellData) => <div onClick={() => onEditPlayer(cellData.row.original)} style={{cursor: 'pointer'}}>
                                {cellData.value}
                                <Subtext>{t("Birth")}: { cellData.row.original.dateOfBirth ?
                                    <Birthday className={classes.birthday} coma={true} data={cellData.row.original.dateOfBirth} /> : "-" }</Subtext>
                            </div>
                        },
                        {
                            Header: t('E-mail'),
                            className: classes.hide,
                            accessor: 'email',
                            Cell: (cellData) => <div>
                                <a href="mailto:{cellData.value}">{cellData.value}</a>
                                {cellData.row.original.phoneNumber && <Subtext>{t("Phone")}: <a href="phone:{cellData.row.original.phoneNumber}">
                                {cellData.row.original.phoneNumber}</a></Subtext>}
                            </div>
                        },
                            {
                                Header: t('Teams'),
                                accessor: 'teams',
                                Cell: (cellData) => {
                                    const {playerId} = cellData.row.original;
                                    const teams = cellData.row.original.teams;
                                    return (teams.map((x, index) =>
                                        <span key={`team-${playerId}-${x.team.name}`}>
                                            {(index ? ', ' : '')} {x.team.name} ({x.teamNumber})
                                        </span>
                                    ));
                                }
                            }
                        ]}
                        onSelectClick={(x) => setSelectedRows(x)}
                        onRowClick={() => {}} />

                        <Pagination page={skip} onPage={onPage} count={data.players.totalCount}
                                onPageChange={(p) => setSkip(p.selected)}/>
                    </>}
            </div>

            <ModalWindow2 {...modalData}>
                <SwitchComponents active={modalData.form}>
                    <NewPlayerForm {...modalData} name="player" />
                </SwitchComponents>
            </ModalWindow2>
        </>
    );
};

ClubPlayers.defaultProps = {
    team: [
        {label: "All", value: "all" },
        {label: "With team", value: "withTeam" },
        {label: "Without team", value: "withoutTeam" },
    ],
};

export default ClubPlayers;
