export const orgChartDefaultData = {
  tree: {
    root: {
      id: "qd1d",
      name: "Your Name",
      position: "Sport director",
      description: "Some text...",
      children: [{
        id: "df353",
        name: "Other Name",
        position: "Couch",
        description: "Some text...",
      }, {
        id: "a322w",
        name: "Other Name",
        position: "Medicine",
        description: "Some text...",
      }]
    }
  }
};